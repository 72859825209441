import { breakpoints, color, colors, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  showThreadsNtf?: boolean;
}

export default createUseStyles({
  section: {
    height: '100%',
    paddingTop: 0,
    paddingBottom: getRem(16),
    '& .grid__col': {
      paddingTop: getRem(40),
      height: `calc(100vh - ${getRem(128)})`,
      position: 'relative',
      '&:first-child': {
        paddingRight: getRem(16),
        [`@media screen and (max-width: ${breakpoints.l}px)`]: {
          paddingRight: 0,
        },
      },
      '&:last-child': {
        borderLeft: `1px solid ${color('grey', 100)}`,
        paddingLeft: 0,
      },
      [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
        height: 'auto',
        '&:last-child': {
          borderLeft: 'none',
        },
      },
    },
    '& .tab-list__list': {
      overflowX: 'auto',
      paddingBottom: getRem(5),
      '&::-webkit-scrollbar': {
        display: 'block',
      },
    },
    '& .container': {
      position: 'relative',
    },
    [`@media screen and (max-width: 1625px)`]: {
      padding: `0 ${getRem(48)}`,
    },
    [`@media screen and (max-width: 1350px)`]: {
      padding: `0 ${getRem(32)}`,
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      padding: 0,
    },
  },
  button: {
    marginBottom: 0,
    display: 'flex',
    '& svg': {
      marginRight: getRem(12),
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  newMessageButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: getRem(32),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  threads: ({ showThreadsNtf }: StylesProps) => {
    const ntfHeight = showThreadsNtf ? 57 : 0;
    return {
      marginTop: getRem(24),
      position: 'relative',
      maxHeight: `calc(100vh - ${getRem(400 + ntfHeight)})`,
      overflowY: 'auto',
      [`@media screen and (min-width: ${breakpoints.m}px)`]: {
        paddingRight: getRem(16),
      },
      [`@media screen and (max-width: ${breakpoints.s}px) and (max-height: 700px)`]:
        {
          maxHeight: `calc(100vh - ${getRem(310 + ntfHeight)})`,
        },
      [`@media screen and (max-width: ${breakpoints.s}px) and (min-height: 701px)`]:
        {
          maxHeight: `calc(100vh - ${getRem(280 + ntfHeight)})`,
        },
      [`@media screen and (min-height: 1570px) and (min-width: ${breakpoints.xl}px)`]:
        {
          maxHeight: getRem(1120 + ntfHeight),
        },
    };
  },
  threadsRemoveBar: {
    maxHeight: `calc(100vh - ${getRem(480)}) !important`,
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      maxHeight: `calc(100vh - ${getRem(432)}) !important`,
    },
  },
  thread: {
    position: 'relative',
    display: 'block',
    borderRadius: getRem(5),
    color: color('black'),
    padding: getRem(16),
    cursor: 'pointer',
    '& > b': {
      fontSize: getRem(18),
      fontWeight: 500,
    },
    '& > div': {
      marginLeft: getRem(32),
      position: 'relative',
      marginTop: getRem(2),
    },
    '&:hover': {
      background: color('grey', 50),
    },
    '&.active': {
      background: color('grey', 100),
    },
    '&.unread:not(.active)': {
      color: color('secondary', 500),
    },
    '& .Tag': {
      background: `rgba(103, 58, 183, 0.5)`,
      color: 'white',
      border: 'none',
      position: 'absolute',
      right: 0,
      margin: 0,
      fontSize: getRem(14),
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      borderRadius: 0,
      '& .Tag': {
        right: getRem(-8),
      },
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      padding: `${getRem(16)} ${getRem(24)} ${getRem(16)} ${getRem(8)}`,
      '& .Tag': {
        right: getRem(0),
      },
    },
  },
  threadDate: {
    position: 'absolute',
    top: getRem(16),
    right: getRem(16),
    fontSize: getRem(14),
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      right: getRem(24),
    },
  },
  threadCheckbox: {
    position: 'absolute !important',
    width: getRem(32),
    display: 'inline-block',
    verticalAlign: 'middle',
    marginTop: `${getRem(-2)} !important`,
    marginLeft: `0 !important`,
    '& .form-control': {
      marginBottom: 0,
    },
  },
  threadNewDot: {
    width: getRem(8),
    height: getRem(8),
    borderRadius: getRem(100),
    background: color('secondary', 500),
    display: 'inline-block',
    marginLeft: getRem(8),
  },
  unreadRedDot: {
    width: getRem(8),
    height: getRem(8),
    borderRadius: getRem(100),
    background: color('error', 400),
    display: 'inline-block',
    marginRight: getRem(8),
  },
  removeThreads: {
    borderTop: `1px solid ${color('grey', 100)}`,
    position: 'absolute',
    bottom: 0,
    left: getRem(-32),
    padding: `0 ${getRem(16)} 0 ${getRem(48)}`,
    width: `calc(100% + ${getRem(32)})`,
    zIndex: 2,
    background: 'white',
    '& > div': {
      padding: getRem(16),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .form-control': {
        margin: 0,
      },
      '& button': {
        // TODO: global svg
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          marginRight: getRem(8),
        },
      },
    },
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      left: getRem(0),
      bottom: getRem(-92),
      padding: `0 ${getRem(16)}`,
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      width: `100%`,
      padding: `0 ${getRem(32)} 0 ${getRem(16)}`,
      '& > div': {
        paddingRight: getRem(16),
      },
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      padding: `0 ${getRem(6)}`,
      '& > div': {
        padding: `${getRem(8)} ${getRem(16)}`,
      },
    },
  },
  threadDetailMessages: {
    overflowY: 'auto',
    paddingRight: getRem(8),
    '&': {
      [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
        paddingLeft: getRem(3),
      },
    },
  },
  messages: {
    '& > div': {
      '&:not(:last-child)': {
        borderBottom: `1px solid ${color('grey', 100)}`,
      },
    },
  },
  threadDetailTitle: {
    paddingLeft: getRem(32),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      cursor: 'pointer',
      height: getRem(24),
      marginLeft: getRem(12),
    },
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      paddingLeft: getRem(0),
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      paddingLeft: getRem(24),
      '& button': {
        marginRight: getRem(8),
      },
    },
  },
  message: {
    paddingBottom: getRem(16),
    paddingLeft: getRem(32),
    '&:not(:last-child)': {
      marginBottom: getRem(16),
    },
    '& button': {
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        marginRight: getRem(8),
      },
    },
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      paddingLeft: getRem(0),
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      paddingLeft: getRem(24),
    },
  },
  messageHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: getRem(16),
    '& > div:last-child': {
      marginLeft: getRem(16),
      '& span': {
        display: 'block',
        fontSize: getRem(14),
        color: color('grey', 500),
      },
    },
  },
  replyElement: {
    margin: `${getRem(8)} 0 ${getRem(8)} ${getRem(32)}`,
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      margin: `${getRem(8)} 0 ${getRem(8)} ${getRem(0)}`,
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      margin: `${getRem(8)} 0 ${getRem(8)} ${getRem(24)}`,
    },
  },
  replyForm: {
    paddingLeft: getRem(32),
    '& textarea': {
      height: `${getRem(120)} !important`,
      overflow: 'auto',
    },
    '& .form-label label': {
      width: '100%',
    },
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      paddingLeft: getRem(0),
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      paddingLeft: getRem(24),
    },
  },
  newMessageForm: {
    '& textarea': {
      height: `${getRem(240)} !important`,
      overflow: 'auto',
    },
    '& .form-label label': {
      width: '100%',
    },
    '& .choices__list--dropdown': {
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      width: '100%',
      '& .choices__item': {
        whiteSpace: 'unset',
      },
    },
  },
  newMessageModal: {
    maxWidth: getRem(656),
    overflow: 'unset', // workaround pre rozmazane select options vo formulari
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    marginTop: getRem(48),
    '& img': {
      width: getRem(258),
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      marginTop: getRem(12),
    },
  },
  topWrapper: {
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      marginLeft: getRem(24),
      marginRight: getRem(32),
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      marginLeft: getRem(16),
      marginRight: getRem(24),
    },
  },
  mobileDetailWrapper: {
    position: 'fixed',
    paddingTop: getRem(24),
    top: getRem(56),
    width: `calc(100% - ${getRem(394)})`,
    background: 'white',
    left: '120%',
    transition: 'left .5s',
    height: `100%`,
    '&.active': {
      left: getRem(372),
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      position: 'fixed',
      paddingTop: getRem(70),
      zIndex: 199,
      height: `100vh`,
      left: '100%',
      width: '100%',
      top: 0,
      '&.active': {
        left: 0,
      },
    },
  },
  navigateToList: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: getRem(16),
    marginBottom: getRem(8),
    '& svg': {
      transform: 'rotate(180deg)',
    },
    '& span': {
      marginLeft: getRem(12),
      textDecoration: 'underline',
      fontWeight: 500,
    },
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      marginLeft: getRem(0),
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      marginLeft: getRem(16),
    },
  },
  badgeArchiv: {
    '& .Tag': {
      background: `rgba(0, 0, 0, 0.5)`,
    },
  },
  archivNtfWrapper: {
    marginTop: getRem(16),
    marginBottom: getRem(8),
    '& > .notification': {
      margin: 0,
      '& button': {
        minHeight: 0,
      },
    },
  },
  requiredAttachment: {
    background: colors.secondary[100],
    borderRadius: getRem(8),
    padding: `${getRem(16)} ${getRem(24)}`,
    '& button': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: `${getRem(10)} !important`,
      },
      '&:hover, &:focus, &': {
        color: `${colors.secondary[600]} !important`,
      },
    },
  },
});
