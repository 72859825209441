import { createUseStyles } from 'react-jss';
import { getRem } from '@dovera/design-system';

export default createUseStyles({
  root: {},
  spravaPristupovRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& > div': {
      width: '75%',
      '&:last-child': {
        width: '25%',
        textAlign: 'right',
      },
    },
  },
  spravaPristupovRowAuth: {
    '& > div': {
      width: '40%',
      '&:last-child': {
        width: '60%',
        textAlign: 'right',
        '& button': {
          minHeight: 0,
          paddingTop: getRem(4),
          paddingBottom: getRem(4),
          margin: 0,
        },
      },
    },
  },
  zmluvyRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button.btn--link': {
      minHeight: 'auto',
      marginTop: -3,
    },
    '& > div': {
      width: '40%',
      paddingRight: 5,
      '& span': {
        display: 'block',
      },
      '&:last-child': {
        width: '20%',
        textAlign: 'right',
      },
      [`@media screen and (max-width: 1300px)`]: {
        width: '36%',
        '&:last-child': {
          width: '28%',
        },
      },
    },
  },
  buttons: {
    justifyContent: 'right',
  },
  kapitacieRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > div:last-child': {
      display: 'flex',
      flexDirection: 'row',
      '& button': {
        padding: 0,
        margin: `0 0 0 ${getRem(32)}`,
        minHeight: 0,
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          marginLeft: getRem(8),
        },
      },
    },
  },
});
