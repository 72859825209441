import { removeKey } from './object.utils';

// @ts-ignore
const dataLayer: any[] = window.dataLayer || [];

export const sendDataLayer = (eventName: string, obj: any = {}) => {
  const o = { ...obj };
  const actualLayer = JSON.stringify({ event: eventName, ...o });
  let lastIndex = dataLayer.reverse().filter((d) => d?.event === eventName)[0];
  if (lastIndex)
    lastIndex = JSON.stringify(removeKey('gtm.uniqueEventId', lastIndex));
  if (lastIndex !== actualLayer) {
    return dataLayer.push({ event: eventName, ...obj });
  }
  return null;
};
