import { Modal } from '@dovera/design-system';
import { stopVideos } from '../../utils/app.utils';
import YoutubeEmbed from './YoutubeEmbed';

interface Props {
  embedId: string;
  id: string;
  isVisible: boolean;
  onHide: () => void;
  title?: string;
}

const YoutubeModal = ({ embedId, id, isVisible, onHide, title }: Props) => (
  <Modal
    closeOnOverlayClick={false}
    data-modal-initial-focus
    header={title || ''}
    id={`${id}--youtube-modal`}
    isVisible={isVisible}
    onHide={() => {
      stopVideos();
      onHide();
    }}
  >
    <YoutubeEmbed embedId={embedId} />
  </Modal>
);

export default YoutubeModal;
