import { useContext, useEffect } from 'react';
import { useQuery } from '../../../hooks/useQuery';
import { FilterContext } from '../../../providers/FilterProvider';
import { FILTER_PREFIX } from '../constants';
import { Select2Type } from '../types';
import { Select2 } from '../../Select2';

const Select2Filter = ({ ...props }: Select2Type) => {
  const { id, isPrivate = true, onChange, ...restProps } = props;

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    if (!isPrivate) {
      const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
      if (queryValue) {
        handleChange(queryValue);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (value) => {
    if (value) {
      searchParams.set(`${FILTER_PREFIX}_${id}`, isPrivate ? true : value);
    } else if (searchParams.get(`${FILTER_PREFIX}_${id}`)) {
      searchParams.delete(`${FILTER_PREFIX}_${id}`);
    }
    setSearchParams(searchParams);
    if (onChange) onChange(value);
  };

  return <Select2 id={id} onChange={handleChange} {...restProps} />;
};

export default Select2Filter;
