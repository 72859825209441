interface Props {
  color?: string;
  id: string;
}

const IconBankCard = ({ color, id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
      stroke={color || '#1F57A5'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      stroke={color || '#1F57A5'}
      strokeWidth="2"
      x1="3"
      x2="22"
      y1="15"
      y2="15"
    />
    <line
      stroke={color || '#1F57A5'}
      strokeLinecap="round"
      strokeWidth="2"
      x1="14"
      x2="19"
      y1="10"
      y2="10"
    />
  </svg>
);

export default IconBankCard;
