import { onlyText } from '../../utils/strings.utils';
import useStyles from './EmptyState.styles';
import { Notification } from '@dovera/design-system';

interface Props {
  reason: '404' | '500' | '404-app';
  type: 'warning' | 'error';
}

const EmptyState = ({ reason, type }: Props) => {
  const classes = useStyles();
  const getMsgText = (): string => {
    if (reason === '404') return onlyText(`emptyStates.notAllowed`);
    if (reason === '500') return onlyText(`emptyStates.technicalError`);
    return onlyText(`emptyStates.app404`);
  };
  return (
    <div className={classes.notificationContainer}>
      <Notification message={getMsgText()} variant={type} />
    </div>
  );
};

export default EmptyState;
