import { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Loader, Select, color } from '@dovera/design-system';
import { PeriodProps } from './types.d';
import useStyles from './styles';
import IconCalendar from './icons/IconCalendar';
import {
  createPeriods,
  getInputValue,
  getMonths,
  getYearSwitcher,
  getYears,
} from './utils';
import { cx } from '../../utils/exports';
import IconArrowLeft from './icons/IconArrowLeft';
import IconArrowRight from './icons/IconArrowRight';
import useOuterClick from './hooks/useOuterClick';

const PeriodPicker = ({
  allPeriods,
  capitalizeValue,
  className,
  error,
  id,
  isLoading,
  label,
  maxPeriod,
  minPeriod,
  onChoose,
  value,
  warning,
}: PeriodProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const periods = useMemo(() => {
    if (allPeriods) return allPeriods;
    if (minPeriod) return createPeriods(minPeriod, maxPeriod);
    return [];
  }, [maxPeriod, minPeriod, allPeriods]);

  const periodRef = useOuterClick<HTMLDivElement>((e) => {
    const formControl = e.target.closest('div.form-control');
    if (
      isOpen &&
      e.target.id !== id &&
      (!formControl || !formControl.querySelector(`input#${id}`))
    )
      setIsOpen(false);
  });

  const [yearSwitcher, setYearSwitcher] = useState(
    getYearSwitcher({ periods }),
  );

  useEffect(() => {
    setYearSwitcher(getYearSwitcher({ periods }));
  }, [periods]);

  const changeYear = useCallback(
    ({ changeTo, year }: { changeTo?: 'next' | 'prev'; year?: number }) => {
      setYearSwitcher(
        getYearSwitcher({
          actualYear: yearSwitcher.value,
          clickedNext: changeTo === 'next',
          clickedPrev: changeTo === 'prev',
          choosedYear: year,
          periods,
        }),
      );
    },
    [periods, yearSwitcher.value],
  );
  const renderInput = (
    <div>
      <Input
        addonsInside
        className={classes.input}
        crossOrigin
        error={error}
        id={id}
        isReadonly
        label={label}
        onFocus={() => setIsOpen(true)}
        rightAddons={isLoading ? <Loader size={24} /> : <IconCalendar />}
        value={getInputValue(periods, Number(value), capitalizeValue)}
        warning={warning}
      />
    </div>
  );
  const renderYearSwitcher = (
    <div className={classes.yearSwitcher}>
      <button
        disabled={!yearSwitcher.hasPrev}
        onClick={() => changeYear({ changeTo: 'prev' })}
        type="button"
      >
        <IconArrowLeft
          color={!yearSwitcher.hasPrev ? color('grey', 300) : undefined}
        />
      </button>
      <Select
        id={`${id}--select-years`}
        onChange={(val) => changeYear({ year: Number(val) })}
        options={getYears(periods)
          .reverse()
          .map((y) => ({
            label: y.toString(),
            value: y.toString(),
          }))}
        type="inline"
        value={yearSwitcher.value.toString()}
      />
      <button
        disabled={!yearSwitcher.hasNext}
        onClick={() => changeYear({ changeTo: 'next' })}
        type="button"
      >
        <IconArrowRight
          color={!yearSwitcher.hasNext ? color('grey', 300) : undefined}
        />
      </button>
    </div>
  );
  const renderOptions = (
    <div
      ref={periodRef}
      className={cx(classes.pickerOptions, isOpen && 'is-active')}
      data-testid="period-picker--options"
    >
      {renderYearSwitcher}
      <div className={classes.months}>
        {getMonths().map((m) => {
          const year = Number(`${yearSwitcher.value}${m.key}`);
          return (
            <button
              key={`index--${m.key}`}
              className={cx(Number(value) === year && 'is-active')}
              disabled={!periods.some((o) => o.datum === year.toString())}
              onClick={() => {
                onChoose(year);
                setIsOpen(false);
              }}
              type="button"
            >
              {m.value}
            </button>
          );
        })}
      </div>
    </div>
  );
  if (!periods.length) return <span />;
  return (
    <div
      className={cx(className, classes.periodWrapper)}
      data-testid="period-picker"
    >
      {renderInput}
      {renderOptions}
    </div>
  );
};
export default PeriodPicker;
