import { useCallback, useEffect, useState } from 'react';
import {
  ButtonSecondary,
  Container,
  Loader,
  PageSection,
} from '@dovera/design-system';
import StaticMenuLayout from '../../layouts/StaticMenuLayout';
import useStyles from './Cookies.styles';
import strings from '../../constants/strings';
import useInterval from '../../hooks/useInterval';

const staticTexts = strings.cookies;

const Cookies = () => {
  const classes = useStyles();
  const [isReady, setIsReady] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  /* eslint-disable */
  const showContentCallback = useCallback(() => {
    // @ts-ignore
    if (typeof OneTrust !== 'undefined') {
      setIsReady(true);
      setTimeout(() => {
        // @ts-ignore
        OneTrust.initializeCookiePolicyHtml();
        setTimeout(() => {
          setIsButtonVisible(true);
        }, 200);
      }, 100);
    }
  }, []);
  const settingsCallback = useCallback(() => {
    // @ts-ignore
    OneTrust?.ToggleInfoDisplay();
  }, []);
  /* eslint-enable */
  useEffect(() => {
    showContentCallback();
  }, [showContentCallback]);
  useInterval(() => {
    if (!isReady) showContentCallback();
  }, 2000);
  const renderSettingButton = isButtonVisible && (
    <ButtonSecondary onClick={settingsCallback}>
      {staticTexts.settingsButton}
    </ButtonSecondary>
  );
  return (
    <StaticMenuLayout backgroundWhite>
      <PageSection className="no-pad mb-large">
        <Container>
          {isReady ? (
            <div>
              <div className={classes.root} id="ot-sdk-cookie-policy" />
              {renderSettingButton}
            </div>
          ) : (
            <Loader size={24} />
          )}
        </Container>
      </PageSection>
    </StaticMenuLayout>
  );
};

export default Cookies;
