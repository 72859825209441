import {
  ButtonLayout,
  ButtonSecondary,
  Modal,
  TableApp,
} from '@dovera/design-system';
import strings from '../../../../constants/strings';
import { Odbornost } from '../../../../types/models/Odbornost';
import { arraySort } from '../../../../utils/array.utils';
import { cx } from '../../../../utils/exports';
import useStyles from '../../Profil.styles';

interface Props {
  expertises: Odbornost[];
  isVisible: boolean;
  onHide: () => void;
}

const texts = strings.profile.modals.expertises;

const OdbornostiModal = ({ expertises, isVisible, onHide }: Props) => {
  const classes = useStyles();
  const renderFooter = (
    <ButtonLayout className="align-items-center" direction="horizontal">
      <ButtonSecondary onClick={() => onHide()}>{texts.close}</ButtonSecondary>
    </ButtonLayout>
  );
  return (
    <Modal
      closeOnOverlayClick={false}
      data-modal-initial-focus
      footer={renderFooter}
      header={texts.title}
      id="profil-odbornosti-modal"
      isVisible={isVisible}
      onHide={() => onHide()}
    >
      <TableApp
        caption=""
        className={cx(classes.table, 'table-caption--hide')}
        columns={[
          {
            accessor: 'col1',
            Header: texts.labels.code,
          },
          {
            accessor: 'col2',
            Header: texts.labels.expertise,
          },
        ]}
        data={arraySort(
          expertises.filter((e) => e.kodOdbNz),
          'kodOdbNz',
        ).map((e) => ({
          col1: e.kodOdbNz?.trim(),
          col2: e.nazovOdbNz?.trim(),
        }))}
      />
    </Modal>
  );
};

export default OdbornostiModal;
