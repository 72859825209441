import { Icon } from '@dovera/design-system';
import { CustomTooltip } from '../../../../components/CustomTooltip';
import useStyles from '../../styles/detail.styles';

interface Props {
  title: string;
  tooltip: string;
  value: string;
}

const AdditionalText = ({ title, tooltip, value }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.graphInfo}>
      {title} <span className="text-color-black">{value}</span>
      <CustomTooltip
        dialog={tooltip}
        id={`tooltip--detailHP--value-${value}--${title}`}
      >
        <Icon name="info" />
      </CustomTooltip>
    </div>
  );
};

export default AdditionalText;
