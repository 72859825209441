import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    '& #header-user-profile': {
      display: 'none',
    },
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      margin: '0 auto',
    },
  },
  card: {
    marginTop: 35,
    padding: 48,
    fontSize: 18,
    '& a.btn': {
      color: '#FFFFFF',
      textDecoration: 'none',
    },
    '& .step': {
      '&:first-child .step__content': {
        marginTop: 24,
      },
      '& button.btn--link': {
        fontSize: 18,
        minHeight: 'auto',
        marginTop: -5,
      },
    },
  },
});
