import axios from 'axios';
import { CMSContent, CMSPopUpContent } from '../types/cms.types';

const API_URL = `${window.env?.config?.apiPath}/CMS/`;

export async function getCMSContentApi(
  payload: string,
): Promise<{ CMS: CMSContent[]; PopUp: CMSPopUpContent[] }> {
  const url = `${API_URL}GetContent`;
  const { data } = await axios.post(url, { cesta: payload });
  return data;
}

export async function savePopUpSeenApi(payload: {
  idPopUp: string;
}): Promise<{ vysledok: boolean }> {
  const url = `${API_URL}UlozZobrazeniePopUp`;
  const { data } = await axios.post(url, payload);
  return data;
}
