import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {},

  cobrowse: {
    padding: '15px 32px 24px 32px',
    // height: 'calc(100vh - 350px)',
    overflowY: 'auto',
    '& .form-control--textarea': {
      marginBottom: 12,
    },
    '& textarea': {
      height: 140,
    },
    '&.expanded-cobrowse': {
      height: 'calc(100vh - 233px)',
      paddingBottom: 50,
    },
    '& label': {
      width: '100%',
    },
  },
});
