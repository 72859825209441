import ContentHeader from '../components/ContentHeader/ContentHeader';
import { Container, Skeleton } from '@dovera/design-system';

const SystemStatusLoader = () => (
  <ContentHeader fixed padBottom>
    <Container>
      <div className="mb">
        <Skeleton width="60%" />
      </div>
      <h2 className="no-mrg-bottom">
        <Skeleton width="30%" />
      </h2>
    </Container>
  </ContentHeader>
);

export default SystemStatusLoader;
