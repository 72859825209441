import {
  ButtonLink,
  Grid,
  GridCol,
  Notification,
  Skeleton,
  breakpoints,
} from '@dovera/design-system';
import { useNavigate } from 'react-router';
import IconArrowRightLong from '../../../components/CustomIcons/IconArrowRightLong';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import strings from '../../../constants/strings';
import { useAppDispatch } from '../../../hooks/useStore';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { feedbackTrigger } from '../../../slices/feedback.slices';
import { createViewUrl, userHasSection } from '../../../utils/app.utils';
import { getUserId } from '../../../utils/auth.utils';
import { endOf, formatDate } from '../../../utils/date.utils';
import useStyles from './WidgetCurrentPrices.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { LoadingState } from '../../../types';
import { getPZS } from '../../../slices/auth.slice';
import routes from '../../../routes';
import { useEffect } from 'react';
import { getCurrentPrices } from '../../../api/parameters';
import ExportXlsxReport from '../../parameters/common/exports/xlsx/ExportXlsxReport';

const texts = strings.parameters.index.currentPrices;

const WidgetCurrentPrices = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const isSmall: boolean =
    (windowSize.width < 1325 && windowSize.width > breakpoints.xl) ||
    windowSize.width < breakpoints.s;
  const path = createViewUrl(routes.parameters, { obdobie: 'uzavrete' });
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const {
    parameters: {
      currentHpPrices: { data, dataState },
    },
    poskytovatel: {
      idHodnotenyPZS,
      kodTypZs,
      odbornosti,
      poslednaZmenaOdbornosti,
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (userHasSection('PZSHPKEI') && odbornosti?.length)
      dispatch(
        getCurrentPrices({
          idEvaluatedHCP: Number(idHodnotenyPZS),
          expertiseCode: poslednaZmenaOdbornosti,
          healthCareCode: kodTypZs,
        }),
      );
  }, [
    dispatch,
    idHodnotenyPZS,
    kodTypZs,
    odbornosti?.length,
    poslednaZmenaOdbornosti,
  ]);

  const renderOutlierNtf = data?.isOutlier && (
    <div className="notification-container mb-small">
      <Notification
        message={
          <>
            <b className="d-block">{texts.outlierNtf.text}</b>
            <ButtonLink className="inline-btn" onClick={() => navigate(path)}>
              {texts.outlierNtf.link}
            </ButtonLink>
          </>
        }
        variant="warning"
      />
    </div>
  );

  if (
    !userHasSection('PZSHPKEI') ||
    !odbornosti?.length ||
    (!data && dataState !== LoadingState.fetching)
  )
    return <span />;
  if (userHasSection('PZSHPKEI') && dataState === LoadingState.fetching)
    return (
      <div className={classes.root}>
        <h3 className="mb-small">
          <Skeleton width="100%" />
        </h3>
        <Skeleton width="70%" />
        <h4 className="mb-small">
          <Skeleton width="50%" />
        </h4>
        <Skeleton width="70%" />
      </div>
    );

  return (
    <div className={classes.root}>
      <h3 className="mb-small">
        {texts.title(
          Number(data?.values?.length) === 1
            ? data?.values[0].valueName
            : undefined,
        )}
      </h3>
      {Number(data?.values?.length) === 1 && (
        <h4 className="mb-small">{data?.values[0].value}</h4>
      )}
      {renderOutlierNtf}
      <SafeHtml
        className="mb-small"
        html={texts.expertise(
          odbornosti.find(
            (o) =>
              o.kodTypZS === kodTypZs && o.kodOdbNz === poslednaZmenaOdbornosti,
          )?.nazovOdbNz || '',
        )}
        wrapper="div"
      />
      <SafeHtml
        className="mb-small"
        html={texts.description(
          formatDate(`${data?.periodFrom}01`),
          formatDate(`${data?.periodTo}${endOf(data?.periodTo.toString())}`),
        )}
        wrapper="div"
      />
      {Number(data?.values.length) > 1 && (
        <Grid>
          {data?.values.map((d) => (
            <GridCol
              key={`widget-price-col--${d.value}-${d.valueName}`}
              size={data.values.length > 1 ? 6 : 12}
            >
              <SafeHtml className={classes.priceType} html={d.valueName} />
              <h4 className="mb-small">{d.value}</h4>
            </GridCol>
          ))}
        </Grid>
      )}
      <ExportXlsxReport
        buttonTitle={isSmall ? texts.downloadBtnSmall : texts.downloadBtn}
        idCyklus={Number(data?.id)}
        isRelative
        plnenie="vyhodnotene plnenie"
      />
      <ButtonLink
        className="no-mrg-bottom no-pad text-left"
        onClick={() => {
          dispatch(
            feedbackTrigger({
              delay: 15,
              path,
              userId: getUserId(pzs.userId),
            }),
          );
          navigate(path);
        }}
      >
        {isSmall ? texts.buttonSmall : texts.button}
        <IconArrowRightLong id="profile-card-arrow-icon" />
      </ButtonLink>
    </div>
  );
};

export default WidgetCurrentPrices;
