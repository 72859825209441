import { createUseStyles } from 'react-jss';
import { getRem } from '@dovera/design-system';

export default createUseStyles({
  filter: {
    display: 'flex',
    '& > div': {
      width: getRem(250),
      marginRight: getRem(32),
    },
  },
});
