/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ns } from '../../utils/object.utils';
import useStyles from './UploadForm.styles';
import { useDropzone } from 'react-dropzone';
import { Loader, Notification } from '@dovera/design-system';
import { cx } from '../../utils/exports';
import { isFileSizeLimit } from '../../utils/file.utils';
import strings from '../../constants/strings';
import SafeHtml from '../SafeHtml/SafeHtml';
import { Attachment, Wrapper } from '../Attachment';
import {
  FileErrorTypes,
  systemConstants,
} from '../../constants/systemConstants';
import { inlineCondition, modalScroll } from '../../utils/app.utils';
import { CustomFileErrorMessage } from '../../types/app.types';
import { convertExtensionsToMime } from '../../constants/mimeTypes';
import { translateErrorCode } from '../../utils/validation.utils';

interface Props {
  allowedExtensions: string[];
  className?: any;
  customErrorMessages?: CustomFileErrorMessage;
  files:
    | {
        name: string;
        size: number;
        type?: string;
      }[]
    | null;
  forbiddenExtensions?: Set<string>;
  forbiddenUpload?: {
    onUploadTry: () => void;
    value: boolean;
  };
  formError?: string | null;
  hideUploadedFiles?: boolean;
  inspectMimeTypes?: boolean;
  isUploading: boolean;
  maxAllFilesSize?: number;
  maxFileSize?: number;
  multiple?: boolean;
  onResetForm?: (
    file: { name: string; size: number },
    index: number,
    uploadError?: boolean,
  ) => void;
  onUpload: (file: File[], uploadError?: boolean) => void;
  parentError?: string;
  uploadError?: (isError: boolean) => void;
  uploadFormLabelText: ReactNode;
  validationError?: string;
}

const texts = strings.fileUploadForm;
const FORBIDDEN_EXTENSIONS = new Set(['exe', 'zip', 'rar']);
const MAX_FILE_SIZE = systemConstants.MAX_UPLOAD_SIZE; // 20MB
const MAX_ALL_FILES_SIZE = systemConstants.MAX_UPLOAD_SIZE_SUM; // 20MB

const UploadForm = ({
  allowedExtensions,
  className,
  customErrorMessages,
  files,
  forbiddenExtensions = FORBIDDEN_EXTENSIONS,
  forbiddenUpload,
  formError,
  hideUploadedFiles,
  inspectMimeTypes,
  isUploading,
  maxAllFilesSize = MAX_ALL_FILES_SIZE,
  maxFileSize = MAX_FILE_SIZE,
  multiple,
  onResetForm,
  onUpload,
  parentError,
  uploadError,
  uploadFormLabelText,
  validationError,
}: Props) => {
  const uploadInputRef = useRef(null);
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [error, setError] = useState<string | ReactNode>('');
  const [visibleUploadZone, setVisibleUploadZone] = useState(true);
  const filesSize: number =
    files?.reduce((acc, f) => acc + (f.size || 0), 0) || 0;
  const checkForbiddenUpload = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      if (forbiddenUpload?.value) {
        event?.stopPropagation();
        forbiddenUpload.onUploadTry();
      }
    },
    [forbiddenUpload],
  );
  useEffect(() => {
    setError('');
    setVisibleUploadZone(true);
    if (isFileSizeLimit(filesSize, maxAllFilesSize)) {
      setError(
        <SafeHtml
          html={
            texts.errors.maxUploadSize(maxAllFilesSize, true) ??
            customErrorMessages?.[FileErrorTypes.SingleFileSizeExceeds]?.(
              maxFileSize,
            )
          }
        />,
      );
      if (!hideUploadedFiles) setVisibleUploadZone(false);
    }
    if (uploadError) uploadError(isFileSizeLimit(filesSize, maxAllFilesSize));
    // eslint-disable-next-line
  }, [filesSize, maxAllFilesSize]);
  const onDrop = useCallback(
    (fs) => {
      if (forbiddenUpload?.value) {
        checkForbiddenUpload();
        return;
      }
      setError('');
      let errorMsg: any = null;
      fs.forEach((f: File) => {
        const fileExtension = ns(() =>
          f.name.split('.').pop()?.toLocaleLowerCase(),
        );
        const mimeType: string = f.type || 'text/plain';
        const fileTypeError = texts.errors.allowedFormats(
          <>
            {allowedExtensions.map((a, key) => (
              <span key={`file-ext--${a}`}>
                {a.toUpperCase()}
                {`${
                  key !== allowedExtensions.length - 1 &&
                  key !== allowedExtensions.length - 2
                    ? ', '
                    : inlineCondition(
                        key === allowedExtensions.length - 2,
                        ' alebo ',
                        '',
                      )
                }`}
              </span>
            ))}
          </>,
        );

        if (
          !convertExtensionsToMime(allowedExtensions).includes(mimeType) &&
          !inspectMimeTypes &&
          (f.type ||
            (!f.type &&
              !allowedExtensions.some((e) => e.toLowerCase().includes('txt'))))
        )
          errorMsg = fileTypeError;
        if (
          inspectMimeTypes &&
          !systemConstants.ALLOWED_MIME_TYPES.includes(mimeType)
        ) {
          errorMsg = fileTypeError;
        }
        if (forbiddenExtensions.has(fileExtension))
          errorMsg =
            customErrorMessages?.[FileErrorTypes.UnsupportedFileType]?.(
              fileExtension,
            ) ?? 'Nepodporovaný formát súboru';
        if (isFileSizeLimit(fs[0]?.size, maxFileSize)) {
          errorMsg = (
            <SafeHtml
              html={
                customErrorMessages?.[FileErrorTypes.SingleFileSizeExceeds]?.(
                  maxFileSize,
                ) ?? texts.errors.maxFileSize(maxFileSize)
              }
            />
          );
        }
      });

      if (errorMsg) {
        setError(errorMsg);
        modalScroll('bottom');
        return;
      }
      onUpload(fs, !!errorMsg);
    },
    [
      forbiddenUpload?.value,
      onUpload,
      checkForbiddenUpload,
      allowedExtensions,
      inspectMimeTypes,
      forbiddenExtensions,
      customErrorMessages,
      maxFileSize,
    ],
  );
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple || false,
  });
  const handleUploadEvent = useCallback(
    (event) => {
      onUpload(ns(() => event.target.files));
      setValue('');
    },
    [onUpload],
  );
  const renderUploadZone = (
    <div
      data-test-id="upload-zone"
      {...getRootProps({
        // @ts-ignore
        onClick: (e) => checkForbiddenUpload(e),
      })}
      className={cx(classes.uploadZone, 'mb-small', className, {
        [classes.uploadZoneActive]: isDragActive,
        'file-uploader--error': validationError,
        [classes.uploadZoneError]: !!formError,
      })}
    >
      {isUploading ? <Loader size={24} /> : uploadFormLabelText}
    </div>
  );
  const renderAttachedFile = !hideUploadedFiles && (
    <Wrapper className="mb-small">
      {files?.map((f, key) => (
        <Attachment
          key={`attachment-single--${f.name}-${f.size}-${f?.type}`}
          canBeDeleted
          id={`upload-attachment--${f.size}`}
          name={f.name}
          onRemove={() => {
            if (onResetForm) onResetForm(f, key, !!error);
          }}
          size={f.size}
          type={f.type || ''}
        />
      ))}
    </Wrapper>
  );
  const renderMultipleAttachedFiles = files && !hideUploadedFiles && (
    <Wrapper className="mb-small">
      {files.map((f, index) => (
        <Attachment
          key={`attachment--${f.name}-${f.size}`}
          canBeDeleted
          id={`upload-attachment--${f.size}`}
          name={f.name}
          onRemove={() => {
            if (onResetForm) onResetForm(f, index, !!error);
          }}
          size={f.size}
          type={f.type || ''}
        />
      ))}
    </Wrapper>
  );
  return (
    <div className="file-uploader">
      <div
        className={cx(
          'form-control',
          'form-control--file',
          validationError && 'is-error',
        )}
      >
        {validationError && (
          <p className="form-message form-message--error is-error">
            {validationError}
          </p>
        )}

        <input
          ref={uploadInputRef}
          className={classes.fileInput}
          id={`upload-form`}
          onChange={handleUploadEvent}
          type="file"
          value={value}
          {...getInputProps()}
        />
        {files && multiple && renderMultipleAttachedFiles}
        {formError && <div className={classes.labelError}>{formError}</div>}
        {(!files || multiple) && visibleUploadZone && renderUploadZone}
        {files && !multiple && renderAttachedFile}
        {(error || parentError) && (
          <div className="fit-content">
            <Notification
              message={
                error || (
                  <SafeHtml
                    html={translateErrorCode(
                      parentError,
                      allowedExtensions.map((e) => e.toUpperCase()),
                    )}
                  />
                )
              }
              variant="error"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadForm;
