import axios, { AxiosResponse } from 'axios';
import {
  DajAktualizacieZmluvyPayload,
  DajAktualneDokumentyPayload,
  DajAktualneDokumentyResponse,
  DajDetailDodatkuPayload,
  DajDetailZmluvyPayload,
  DajDovodyPripomienkovaniaPayload,
  DajZoznamZmluvPayload,
  UlozAkciuZmluvyPayload,
  ZmluvyPayload,
} from '../types/zmluvy.types';
import { createAsyncThunk } from '@reduxjs/toolkit';

const API_URL = `${window.env?.config?.apiPath}/PrehladZmluv/`;

export async function dajZoznamKampaniPreWidgetApi(payload: ZmluvyPayload) {
  const url = `${API_URL}DajZoznamKampaniPreWidget`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajZoznamKampaniApi(payload: ZmluvyPayload) {
  const url = `${API_URL}DajZoznamKampani`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajZoznamZmluvApi(payload: DajZoznamZmluvPayload) {
  const url = `${API_URL}DajZoznamZmluv`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function ulozAkciuZmluvyApi(payload: UlozAkciuZmluvyPayload) {
  const url = `${API_URL}UlozAkciuZmluvy`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajDetailZmluvyApi(payload: DajDetailZmluvyPayload) {
  const url = `${API_URL}DajDetailZmluvy`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajDetailDodatkuApi(payload: DajDetailDodatkuPayload) {
  const url = `${API_URL}DajDetailDodatku`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajDovodyPripomienkovaniaApi(
  payload: DajDovodyPripomienkovaniaPayload,
) {
  const url = `${API_URL}DajDovodyPripomienkovania`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajAktualizacieZmluvyApi(
  payload: DajAktualizacieZmluvyPayload,
) {
  const url = `${API_URL}DajAktualizacieZmluvy`;
  const { data } = await axios.post(url, payload);
  return data;
}

export const fetchActualDocuments = createAsyncThunk(
  'prehladZmluv/dajAktualneDokumenty',
  async (req: DajAktualneDokumentyPayload) => {
    const url = `${API_URL}DajAktualneDokumenty`;
    const { data }: AxiosResponse<DajAktualneDokumentyResponse> =
      await axios.post(url, req);
    return data;
  },
);
