interface Props {
  color?: string;
  height?: number;
  id: string;
  width?: number;
}

const IconWarningCircle = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 33}
    id={id}
    viewBox="0 0 32 33"
    width={width || 32}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16.6602" fill={color || '#FF9800'} r="16" />
    <path
      clipRule="evenodd"
      d="M16.5 8.66016C17.3284 8.66016 18 9.40635 18 10.3268V17.9935C18 18.914 17.3284 19.6602 16.5 19.6602C15.6716 19.6602 15 18.914 15 17.9935V10.3268C15 9.40635 15.6716 8.66016 16.5 8.66016Z"
      fill="white"
      fillRule="evenodd"
    />
    <circle cx="16.5" cy="23.1602" fill="white" r="1.5" />
  </svg>
);

export default IconWarningCircle;
