import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OvereniaPrehladyState } from '../types/poskytovatel.types';
import { LoadingState } from '../types';
import { uniqueArray } from '../utils/array.utils';
import {
  DajDetailAmbulancieResponse,
  DajZoznamAmbulanciiPZSResponse,
  DajZoznamUvazkovLekarovPZSResponse,
} from '../types/api/poskytovatel';
import {
  fetchAmbulanceDetail,
  fetchAmbulances,
  fetchDoctors,
} from '../api/poskytovatel';
import { TABLE_PAGINATION } from '../constants/misc';
import _ from 'lodash';
import { formatNameStr } from '../utils/strings.utils';

const initialState: OvereniaPrehladyState = {
  overeniaPrehlady: {
    ambulances: {
      data: null,
      dataState: LoadingState.none,
      detail: {
        address: [],
        detailState: LoadingState.none,
        workingHours: [],
        doctors: [],
      },
      filterOptions: {
        ambulances: [],
        expertises: [],
      },
    },
    doctors: {
      data: null,
      dataState: LoadingState.none,
      filterOptions: {
        doctors: [],
        expertises: [],
      },
    },
  },
};

const pzsSlize = createSlice({
  name: 'overenia-prehlady',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAmbulances.pending.toString(), (state) => {
        state.overeniaPrehlady.ambulances.data = null;
        state.overeniaPrehlady.ambulances.dataState = LoadingState.fetching;
        state.overeniaPrehlady.ambulances.isError = false;
        state.overeniaPrehlady.ambulances.error = null;
        state.overeniaPrehlady.ambulances.detail =
          initialState.overeniaPrehlady.ambulances.detail;
        state.overeniaPrehlady.doctors = initialState.overeniaPrehlady.doctors;
      })
      .addCase(
        fetchAmbulances.fulfilled.toString(),
        (state, action: PayloadAction<DajZoznamAmbulanciiPZSResponse>) => {
          const fetchedData = _.sortBy(action.payload.ambulancie, 'nazov');
          state.overeniaPrehlady.ambulances.data = fetchedData;
          state.overeniaPrehlady.ambulances.dataState = LoadingState.filled;
          state.overeniaPrehlady.ambulances.filterOptions = {
            ambulances: uniqueArray(
              fetchedData?.map((z) => `${z.kod} - ${z.nazov}`) || [],
            ),
            expertises: uniqueArray(
              _.sortBy(fetchedData, 'odbornostKod')?.map((z) => ({
                label: `${z.odbornostKod} - ${z.odbornostNazov}`,
                value: z.odbornostKod,
                text: `${z.odbornostKod} - ${z.odbornostNazov}`,
              })) || [],
            ),
          };
        },
      )
      .addCase(
        fetchAmbulances.rejected.toString(),
        (state, action: PayloadAction<any>) => {
          state.overeniaPrehlady.ambulances.dataState = LoadingState.error;
          state.overeniaPrehlady.ambulances.isError = true;
          state.overeniaPrehlady.ambulances.error = action?.payload?.error;
        },
      )
      .addCase(fetchAmbulanceDetail.pending.toString(), (state) => {
        state.overeniaPrehlady.ambulances.detail = {
          ...initialState.overeniaPrehlady.ambulances.detail,
          detailState: LoadingState.fetching,
        };
      })
      .addCase(
        fetchAmbulanceDetail.fulfilled.toString(),
        (state, action: PayloadAction<DajDetailAmbulancieResponse>) => {
          const sorted = _.sortBy(action.payload.uvazok, 'lekarMeno');
          state.overeniaPrehlady.ambulances.detail = {
            ...state.overeniaPrehlady.ambulances.detail,
            address: action.payload.adresa,
            detailState: LoadingState.filled,
            workingHours: action.payload.ordinacneHodiny,
            doctors: _.chunk(sorted, TABLE_PAGINATION),
          };
        },
      )
      .addCase(
        fetchAmbulanceDetail.rejected.toString(),
        (state, action: PayloadAction<any>) => {
          state.overeniaPrehlady.ambulances.detail = {
            ...state.overeniaPrehlady.ambulances.detail,
            address: [],
            detailState: LoadingState.error,
            error: action?.payload?.error,
            isError: true,
            workingHours: [],
            doctors: [],
          };
        },
      )
      .addCase(fetchDoctors.pending.toString(), (state) => {
        state.overeniaPrehlady.doctors = {
          ...state.overeniaPrehlady.doctors,
          data: null,
          dataState: LoadingState.fetching,
          isError: false,
          error: null,
          filterOptions: initialState.overeniaPrehlady.doctors.filterOptions,
        };
        state.overeniaPrehlady.ambulances =
          initialState.overeniaPrehlady.ambulances;
      })
      .addCase(
        fetchDoctors.fulfilled.toString(),
        (state, action: PayloadAction<DajZoznamUvazkovLekarovPZSResponse>) => {
          const fetchedData = _.sortBy(action.payload.uvazky, 'lekarMeno');
          state.overeniaPrehlady.doctors.data = fetchedData;
          state.overeniaPrehlady.doctors.dataState = LoadingState.filled;
          state.overeniaPrehlady.doctors.filterOptions = {
            doctors: uniqueArray(
              fetchedData?.map(
                (z) => `${z.lekarKod} - ${formatNameStr(z.lekarMeno).trim()}`,
              ) || [],
            ),
            expertises: uniqueArray(
              _.sortBy(fetchedData, 'odbLekKod')?.map((z) => ({
                label: `${z.odbLekKod} - ${z.odbLekNazov}`,
                value: z.odbLekKod,
              })) || [],
            ),
          };
        },
      )
      .addCase(
        fetchDoctors.rejected.toString(),
        (state, action: PayloadAction<any>) => {
          state.overeniaPrehlady.doctors.dataState = LoadingState.error;
          state.overeniaPrehlady.doctors.isError = true;
          state.overeniaPrehlady.doctors.error = action?.payload?.error;
        },
      );
  },
});

export default pzsSlize.reducer;
