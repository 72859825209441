import { TypPacientov } from '../../../../types/pacienti.types';

export const typyPacientovMoznosti: TypPacientov[] = [
  {
    text: 'Hospitalizovaní pacienti',
    textFilter: 'Hospitalizovaní',
    hodnota: 'ZS_HOSP',
    isChecked: false,
  },
  {
    text: 'Najdrahší pacienti',
    textFilter: 'Najdrahší',
    hodnota: 'POC_NAROC',
    specifickyVyber: true,
    obdobie: true,
    isChecked: false,
  },
  {
    text: 'Nezaočkovaní proti covidu-19',
    hodnota: 'ZS_NEOCK',
    isChecked: false,
    specifickyVyber: true,
  },
  {
    text: 'Pacienti vhodní na podanie monoklonálnych protilátok',
    textFilter: 'Vhodní na podanie monoklonálnych protilátok',
    hodnota: 'ZS_POS_COV',
    isChecked: false,
  },
  {
    text: 'Prehľad pacientov s diagnostikovanou hypertenziou',
    hodnota: 'CHR_HYP',
    isChecked: false,
  },
  {
    text: 'Kedy sa starať o pacienta s hypertenziou',
    hodnota: 'CHR_HYP_STAR',
    isChecked: false,
  },
  {
    text: 'Rozdelenie starostlivosti o hypertonikov vo Vašom kmeni',
    hodnota: 'CHR_HYP_NEVYS',
    isChecked: false,
  },
  {
    text: 'Prehľad pacientov s diagnostikovanym diabetom',
    hodnota: 'CHR_DIA',
    specifickyVyber: true,
    edukacia: true,
    isChecked: false,
  },
  {
    text: 'Prehľad dispenzarizovaných pacientov',
    hodnota: 'CHR_DISP',
    specifickyVyber: true,
    isChecked: false,
  },
  {
    text: 'Gestačné diabetičky',
    hodnota: 'CHR_GDM',
    specifickyVyber: false,
    isChecked: false,
  },
  {
    text: 'Pacienti s  klinickým posudkom',
    hodnota: 'FARM_POSUD',
    isChecked: false,
  },
  {
    text: 'Pacienti s medikačným problémom',
    hodnota: 'FARM_PROB01',
    isChecked: false,
  },
  {
    text: 'Hypertonici s neštandardnou farmakoterapiou',
    hodnota: 'FARM_HYP_NESP',
    specifickyVyber: true,
    isChecked: false,
  },
  {
    text: 'Hypertonici s možnou nevhodnou preskripciou liekov',
    hodnota: 'FARM_HYP_NEVH',
    specifickyVyber: true,
    isChecked: false,
  },
  {
    text: 'Pacienti s väčším množstvom užívaných liekov',
    hodnota: 'FARM_POLY',
    specifickyVyber: true,
    isChecked: false,
  },
  {
    text: 'Nevybrané lieky',
    hodnota: 'FARM_NEVYB',
    isChecked: false,
  },
  {
    text: 'Zamenený liek v lekárni',
    hodnota: 'FARM_ZAM',
    isChecked: false,
  },
];
