import { breakpoints } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  env: any;
}

export default createUseStyles({
  badgeVersion: ({ env }: StylesProps) => ({
    backgroundColor: env === 'TEST' ? '#4CAF50' : '#EA4747',
    color: 'white',
    borderRadius: '0 0 5px 5px',
    padding: '2px 8px',
    marginRight: 40,
    position: 'absolute',
    top: 0,
    left: 220,
    width: 90,
    textAlign: 'center',
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      width: 50,
      left: 60,
      '& span': {
        display: 'none',
      },
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
      left: 250,
    },
  }),
  table: {},
});
