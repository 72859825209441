import { color } from '@dovera/design-system';
import { IconDashedRing, IconError, IconSuccess } from '../../../../components';
import IconWarningCircle from '../../../../components/CustomIcons/IconWarningCircle';
import { Status } from '../../../../types/parameters.types';

interface Props {
  iconSize?: number;
  status: Status;
}

const StatusIcon = ({ iconSize = 22, status }: Props) => {
  switch (status) {
    case 'success':
      return (
        <IconSuccess
          color={color('success')}
          height={iconSize}
          id="icon-success-value"
          width={iconSize}
        />
      );
    case 'warning':
      return (
        <IconWarningCircle
          height={iconSize}
          id="icon-warning-value"
          width={iconSize}
        />
      );
    case 'error':
      return (
        <IconError height={iconSize} id="icon-error-value" width={iconSize} />
      );
    default:
      return (
        <IconDashedRing
          height={iconSize}
          id="icon-dashed-value"
          width={iconSize}
        />
      );
  }
};

export default StatusIcon;
