import { color, contentSpacing, getRem, spaces } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  variablesCount?: number;
}

const animationDelayMixin = (start: number, end: number) => {
  const styles = {};
  for (let i = start; i <= end; i++) {
    const delay = (i - 1) * 0.1;
    styles[`&:nth-child(${i})`] = { animationDelay: `${delay}s` };
  }
  return styles;
};

export default createUseStyles({
  wrapper: {
    textAlign: 'center',
    marginBottom: contentSpacing,
    '& > svg': {
      display: 'flex',
      margin: '0 auto',
    },
    '& > div': {
      marginBottom: spaces.xsmall,
      marginTop: spaces.xsmall,
    },
    '& > div:first-child': {
      marginTop: 0,
    },
    '& > div:last-child': {
      marginBottom: 0,
    },
    '& > h4': {
      textAlign: 'left',
    },
  },
  formulaWrapper: ({ variablesCount }: StylesProps) => ({
    color: 'white',
    display: 'flex',
    flexDirection: Number(variablesCount) > 3 ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  formulaResult: {
    backgroundColor: color('secondary', 600),
    maxWidth: getRem(150),
  },
  formulaItem: {
    backgroundColor: color('primary', 100),
  },
  commonItem: ({ variablesCount }: StylesProps) => ({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    maxWidth: Number(variablesCount) < 5 ? getRem(150) : 'auto',
    minHeight: getRem(60),
    textAlign: 'center',
    padding: `${getRem(3)} ${getRem(8)}`,
    borderRadius: getRem(23),
    '& small': {
      lineHeight: getRem(17.5),
      color: 'inherit',
      display: 'block',
      fontWeight: '400',
    },
  }),
  formulaPartWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: getRem(16),
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      animation: 'fadeIn 1s ease-in both',
      '& h5': {
        color: color('primary', 600),
        '&.is-result': {
          color: color('secondary', 600),
        },
        marginBottom: getRem(5),
      },
      ...animationDelayMixin(2, 20),
    },
  },
  svgSymbol: {
    margin: `${getRem(32)} ${getRem(8)} 0`,
  },
});
