import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    minHeight: 0,
    fontSize: 18,
    fontWeight: 100,
    padding: 5,
    '& svg': {
      verticalAlign: 'middle',
      marginTop: -2,
      marginRight: 8,
    },
  },
});
