import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  searchBar: {
    '& .form-control': {
      marginBottom: getRem(16),
    },
    '& input': {
      '&.focus-visible': {
        outline: 'none !important',
      },
    },
    '& svg': {
      verticalAlign: 'middle',
      marginTop: -3,
      cursor: 'pointer',
    },
  },
});
