interface Props {
  active?: boolean;
  id: string;
}

const IconTarget = ({ active, id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="16"
      cy="16"
      fill={active ? '#42D848' : '#C4C4C4'}
      rx="16"
      ry="16"
    />
    <path
      d="M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C11.3404 6 7.42515 9.18693 6.31504 13.5C6.10938 14.299 6 15.1368 6 16C6 21.5228 10.4772 26 16 26Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M11 19.3178C12.0749 20.9345 13.913 22 16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C13.7792 10 11.8401 11.2066 10.8027 13"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconTarget;
