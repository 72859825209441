interface Props {
  color?: string;
  id: string;
}

const IconWarningOutlined = ({ color, id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM12 0.999999C5.92487 0.999999 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 0.999999Z"
      fill={color || '#FF9800'}
      fillRule="evenodd"
    />
    <rect
      fill={color || '#FF9800'}
      height="6"
      rx="1"
      transform="rotate(-180 13 13)"
      width="2"
      x="13"
      y="13"
    />
    <rect
      fill={color || '#FF9800'}
      height="2"
      rx="1"
      transform="rotate(-180 13 17)"
      width="2"
      x="13"
      y="17"
    />
  </svg>
);

export default IconWarningOutlined;
