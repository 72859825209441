import mime from 'mime';

const mimeTypeToExtension = {
  'application/rtf': '.rtf',
  'application/txt': '.txt',
  'application/pdf': '.pdf',
  'application/zip': '.zip',
  'application/vnd.ms-excel': '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
};

/**
 * Kniznica "mime" dokaze vratit iba single mime-type zo vstupnej pripony suboru
 * a nepozna mime typy ako napr "applfication/x-zip-compressed", ktory pouziva Google Chrome
 * pri drag&drop uploade. Preto bolo nutne vytvorit vlastny zoznam mime typov pre subory s koncovkou .zip
 * @date 9. 11. 2023 - 11:00:16
 *
 * */
const getZipMimeTypes = (extensions: string[]): string[] =>
  extensions.includes('zip')
    ? [
        'application/zip',
        'application/x-zip',
        'application/octet-stream',
        'application/x-zip-compressed',
      ]
    : [];

export const convertExtensionsToMime = (extensions: string[]): string[] => [
  ...new Set([
    // @ts-ignore
    ...extensions.map((e) => mime.getType(e.toLowerCase()) || ''),
    ...getZipMimeTypes(extensions),
  ]),
];

export function getExtension(mimeType: string) {
  if (mimeTypeToExtension[mimeType]) {
    return mimeTypeToExtension[mimeType];
  }
  if (mimeType.includes('application/'))
    return `.${mimeType.split('application/')[1]}`;
  return '.txt';
}
