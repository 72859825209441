import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { ButtonLink, Icon } from '@dovera/design-system';
import {
  DATE_TIME_FORMAT,
  formatDate,
  getMoment,
} from '../../../../utils/date.utils';
import strings from '../../../../constants/strings';
import { formatNumberToString } from '../../../../utils/strings.utils';
import useStyles from './Export.styles';
import {
  AmbulanciaPZS,
  UvazokLekaraPZS,
} from '../../../../types/poskytovatel.types';
import { UvazokTyp } from '../../../../types/api/poskytovatel';

const FILE_NAME = `export-overeni-prehladov-${getMoment().format(
  DATE_TIME_FORMAT,
)}.xlsx`;
const blobType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const texts = strings.profile.overeniaPrehlady;

interface Props {
  ambulances: AmbulanciaPZS[] | null;
  doctors: UvazokLekaraPZS[] | null;
}

const Export = ({ ambulances, doctors }: Props) => {
  const classes = useStyles();
  const title = `${texts.exportTitle(
    ambulances !== null,
  )}, ${getMoment().format(DATE_TIME_FORMAT)}`;
  const getColumnWidth = (columnNumber: number): number => {
    if (columnNumber === 0) return 10;
    if (columnNumber === 1 || columnNumber === 4) return 32;
    return 20;
  };
  const makeExport = async () => {
    const workbook = new Excel.Workbook();
    workbook.creator = 'DÔVERA - zdravotná poisťovňa';
    workbook.lastModifiedBy = 'DÔVERA - zdravotná poisťovňa';
    workbook.created = getMoment().toDate();
    workbook.modified = getMoment().toDate();
    workbook.lastPrinted = getMoment().toDate();
    const worksheet = workbook.addWorksheet('Export overení a prehľadov');
    worksheet.getCell(`A1`).value = title;
    worksheet.mergeCells('A1:M1');
    worksheet.getCell(`A1`).alignment = { horizontal: 'center' };
    worksheet.getCell(`A1`).font = { size: 16, bold: true };

    worksheet.getRow(3).values =
      ambulances !== null
        ? [
            '#',
            'Kód/názov ambulancie',
            'Kód/názov odbornosti',
            'Platná od - do',
            'Zmluvná',
          ]
        : [
            '#',
            'Kód/meno lekára',
            'Kód/názov ambulancie',
            'Kód/názov odbornosti',
            'Typ úväzku',
            'Platný',
          ];
    worksheet.addRows(
      ambulances
        ? _.orderBy(ambulances, 'nazov').map((a, index) => [
            `${index + 1}.`,
            `${a.kod} - ${a.nazov}`,
            `${a.odbornostKod} - ${a.odbornostNazov}`,
            `${formatDate(a.datumOd)} - ${
              a.datumDo ? formatDate(a.datumDo) : ''
            }`,
            a.jeZmluvny ? 'Áno' : 'Nie',
          ])
        : doctors
          ? _.orderBy(doctors, 'lekarMeno').map((d, index) => [
              `${index + 1}.`,
              `${d.lekarKod} - ${d.lekarMeno}`,
              `${d.ambulanciaKod} - ${d.ambulanciaNazov}`,
              `${d.odbLekKod} - ${d.odbLekNazov}`,
              d.uvazokHodnota
                ? `${UvazokTyp[d.uvazokTyp]}: ${formatNumberToString(
                    d.uvazokHodnota,
                  )}`
                : '-',
              `Od: ${formatDate(d.uvazokDatumOd)}  ${
                d.uvazokDatumDo ? `Do: ${formatDate(d.uvazokDatumDo)}` : ''
              }`,
            ])
          : [],
    );
    worksheet.eachRow({ includeEmpty: true }, (row, i) => {
      if (i === 3) {
        row.eachCell((cell) => {
          cell.font = { bold: true };
        });
      }
    });
    worksheet.columns.forEach((column, columnNumber) => {
      column.width = getColumnWidth(columnNumber);
      column.alignment = { horizontal: 'left' };
    });

    // save under export.xlsx
    await workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: blobType });
      saveAs(blob, FILE_NAME);
    });
  };
  return (
    <div className={classes.exportWrapper}>
      <ButtonLink className="no-pad" onClick={makeExport}>
        <Icon name="download" size="medium" />
        <span>{texts.export}</span>
      </ButtonLink>
    </div>
  );
};

export default Export;
