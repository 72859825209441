import { StringMap } from '../types';

/**
 * Get null safe value from object. If any of the values in the path
 * is undefined it will return a fallback value instead of throwing an error.
 *
 * Example: ns(() => user.params.value, 'fallback value')
 * this will return value if user params and value are defined or fallback if
 * any of the objects are null
 *
 * @param fn The function to get a value from object
 * @param fallback The fallback value if null safe fails
 */
export function ns(fn, fallback: any = null) {
  try {
    return fn() || fallback;
  } catch (e) {
    return fallback;
  }
}

export function isElement(o) {
  return typeof HTMLElement === 'object'
    ? o instanceof HTMLElement
    : o &&
        typeof o === 'object' &&
        o.nodeType === 1 &&
        typeof o.nodeName === 'string';
}

export function objectLength<T>(obj?: T): number {
  if (!obj) {
    return 0;
  }

  return Object.keys(obj).length;
}

// eslint-disable-next-line
export const removeKey = (key, { [key]: _, ...rest }) => rest;

/**
 * Iterate over { key, value } map
 * @param obj @nullable object
 * @param forEach function that will be called with (value, key, index) params
 */
export function iterateObject<T>(
  obj: StringMap<T>,
  forEach: (o: T, key: string, index: number) => void,
): void {
  if (!obj || Object.keys(obj).length === 0) {
    return;
  }

  Object.keys(obj)
    // .sort((a: any, b: any) => parseInt(a, 10) - parseInt(b, 10))
    .forEach((key, index) => {
      forEach(obj[key], key, index);
    });
}

export const isJSON = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getNestedPropValue = (
  sourceObject: any,
  dotNotationPath: string,
): string => {
  let returnData = sourceObject;

  dotNotationPath.split('.').forEach((subPath) => {
    returnData = returnData[subPath] || '';
  });

  return returnData;
};

export const sameOjects = (obj1: any, obj2: any): boolean =>
  JSON.stringify(obj1) === JSON.stringify(obj2);
