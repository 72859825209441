import { createUseStyles } from 'react-jss';
import { color, getRem } from '@dovera/design-system';

export default createUseStyles({
  actuality: {
    display: 'block',
    textDecoration: 'none',
    color: 'inherit',
  },
  date: {
    color: color('grey', 500),
    display: 'block',
    fontSize: getRem(14),
    marginBottom: getRem(8),
  },
  root: {
    padding: getRem(32),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& a': {
      fontSize: getRem(16),
      fontWeight: 500,
      '& svg': {
        marginLeft: getRem(8),
        verticalAlign: 'middle',
        marginTop: getRem(-3),
      },
    },
  },
});
