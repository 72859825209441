interface Props {
  color?: string;
  id: string;
}

const IconMail = ({ color, id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4 3C2.34315 3 1 4.34315 1 6V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V6C23 4.34315 21.6569 3 20 3H4ZM3.10522 5.553C3.26934 5.22511 3.60838 5 4 5H20C20.3916 5 20.7307 5.22511 20.8948 5.553L12 11.7793L3.10522 5.553ZM3 7.92066V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V7.92066L12.5735 13.8192C12.2291 14.0603 11.7709 14.0603 11.4265 13.8192L3 7.92066Z"
      fill={color || '#6A7070'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconMail;
