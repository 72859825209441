import * as React from 'react';
import { useSelector } from 'react-redux';
import useStyles from './ContentHeader.styles';
import cx from 'classnames';
import { ButtonLink, Icon } from '@dovera/design-system';
import { RootState } from '../../../rootReducer';

interface Props {
  centerTitle?: boolean;
  contentTitle: string;
  expanded?: boolean;
  onCloseClick: () => void;
  onExpandClick: () => void;
  onMinimizeClick: () => void;
  searchField?: React.ReactNode;
}

const ContentHeader = ({
  centerTitle,
  contentTitle,
  expanded,
  onCloseClick,
  onExpandClick,
  onMinimizeClick,
  searchField,
}: Props) => {
  const classes = useStyles({ expanded });

  const scrollingPomocnik = useSelector(
    (state: RootState) => state.pomocnik.scrollingDown,
  );
  return (
    <div className={`${classes.root} ${classes.borderRadius}`}>
      <div
        className={cx(
          'd-flex-row align-items-right',
          classes.contentHeaderActions,
        )}
      >
        <ButtonLink
          className="d-flex no-pad-right no-pad-top align-self-stretch no-mrg-bottom no-pad-bottom"
          onClick={onMinimizeClick}
        >
          <div className="text-color-white">_</div>
          {/* <Icon color="white" name="minimize" /> */}
        </ButtonLink>
        <ButtonLink
          className="align-self-stretch no-pad-top no-mrg-bottom no-pad-bottom"
          onClick={onExpandClick}
        >
          <Icon className="text-color-white" name="maximize" />
        </ButtonLink>
        <ButtonLink
          className="no-pad-left align-self-stretch no-pad-top no-mrg-bottom no-pad-bottom"
          onClick={onCloseClick}
        >
          <Icon className="text-color-white" name="close" />
        </ButtonLink>
      </div>
      <h3
        className={cx(
          'text-color-white no-mrg-bottom',
          centerTitle ? 'text-center no-pad-left' : '',
          scrollingPomocnik && contentTitle.includes('pomôcť') && 'hide',
        )}
      >
        {contentTitle}
      </h3>
      {searchField}
    </div>
  );
};

export default ContentHeader;
