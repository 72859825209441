import { Nullable } from '../../../types';
import { FormulaValue, Sign } from '../types';
import { Formula, FormulaChild, SignIcon, TextWithTooltip } from '.';

interface Props {
  activeAccordion: Nullable<string>;
  formula?: string;
  formulaValues: FormulaValue[];
  isColored: boolean;
  isFirstDepth?: boolean;
  nextSign: Nullable<Sign>;
  text: string;
  tooltip: string | undefined;
  updateActiveAccordion: (v: Nullable<string>) => void;
  value: string;
  variable: string;
}

const RecursiveFormula = ({
  activeAccordion,
  formula,
  formulaValues,
  isColored,
  isFirstDepth,
  nextSign,
  text,
  tooltip,
  updateActiveAccordion,
  value,
  variable,
}: Props) => {
  const isPrimary = !isColored && !isFirstDepth;

  return (
    <>
      <Formula
        {...(!isFirstDepth && formulaValues.length > 0
          ? {
              accordionItem: {
                id: variable,
                isActive: activeAccordion === variable,
                child: (
                  <FormulaChild
                    activeAccordion={activeAccordion}
                    formula={formula}
                    formulaValues={formulaValues}
                    isColored={isColored}
                    text=""
                    updateActiveAccordion={updateActiveAccordion}
                    value={value}
                  />
                ),
              },
              hodnota: value,
              nazov: tooltip ? (
                <TextWithTooltip text={text} tooltip={tooltip} />
              ) : (
                text
              ),
              clickable: true,
              onFormulaClick: (id) => {
                updateActiveAccordion(activeAccordion === id ? null : id);
              },
            }
          : {})}
        {...((!isFirstDepth || formulaValues.length === 0) && {
          hodnota: value,
          nazov: tooltip ? (
            <TextWithTooltip text={text} tooltip={tooltip} />
          ) : (
            text
          ),
        })}
        withoutFill={isColored}
      >
        {isFirstDepth && formulaValues.length > 0 && (
          <FormulaChild
            activeAccordion={activeAccordion}
            formula={formula}
            formulaValues={formulaValues}
            isColored={isColored}
            text={text}
            updateActiveAccordion={updateActiveAccordion}
            value={value}
          />
        )}
      </Formula>
      <div style={{ textAlign: 'center' }}>
        {nextSign && <SignIcon isPrimary={isPrimary} sign={nextSign} />}
      </div>
    </>
  );
};

export default RecursiveFormula;
