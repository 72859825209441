import { color } from '@dovera/design-system';
import { Status } from '../../../types/parameters.types';
import { IconError, IconSuccess } from '../../CustomIcons';
import IconWarningCircle from '../../CustomIcons/IconWarningCircle';
import useStyles from '../CompareChart.styles';

interface Props {
  status?: Status;
  text?: string;
}

const ValueStatus = ({ status, text }: Props) => {
  const classes = useStyles({});
  let icon = (
    <IconError height={16} id={`compare-chart--icon-error`} width={16} />
  );
  if (status === 'success')
    icon = (
      <IconSuccess
        color={color('success')}
        height={16}
        id={`compare-chart--icon-success`}
        width={16}
      />
    );
  if (status === 'warning')
    icon = (
      <IconWarningCircle
        height={16}
        id={`compare-chart--icon-warning`}
        width={16}
      />
    );

  return (
    <span className={classes.valueLabel}>
      {icon}
      {` ${text}`}
    </span>
  );
};

export default ValueStatus;
