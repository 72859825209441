import { SelectOption } from '../types/dds.types';

export const DEFAULT_SELECT_VALUE = 'all';

export const getDefaultSelectOption = (label: string): SelectOption => ({
  label,
  text: label,
  value: DEFAULT_SELECT_VALUE,
});

export const getSelectValue = (val?: string): string =>
  val === 'all' ? '' : val || '';
