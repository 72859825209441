import { useCallback, useEffect } from 'react';
import { ButtonLink } from '@dovera/design-system';
import useStyles from './BackButton.styles';
import { cx } from '../../utils/exports';
import { zmenTypDetailuZmluvy } from '../../slices/zmluvy.slice';
import IconArrowLeftLong from '../CustomIcons/IconArrowLeftLong';
import { useAppDispatch } from '../../hooks/useStore';
import { useNavigate, useNavigationType } from 'react-router';

interface Props {
  classNames?: any;
  route?: string;
  text?: string;
}

const BackButton = ({ classNames, route, text }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const dispatch = useAppDispatch();
  const goBack = useCallback(() => {
    if (route) {
      if (route.includes('zmluvy-dodatky/')) {
        dispatch(zmenTypDetailuZmluvy({ typ: 'ZOZNAM_DODATKOV' }));
      }
      navigate(route, { replace: true });
    } else {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [route]);
  useEffect(() => {
    if (navigationType === 'POP') {
      if (route) {
        if (route.includes('zmluvy-dodatky/')) {
          dispatch(zmenTypDetailuZmluvy({ typ: 'ZOZNAM_DODATKOV' }));
          navigate(route);
        }
      }
    }
  }, [dispatch, navigate, navigationType, route]);
  return (
    <ButtonLink
      className={cx(classes.root, classNames || 'no-mrg no-pad-left')}
      onClick={goBack}
      size="s"
    >
      <IconArrowLeftLong id="icon-backButton" />
      {text || ''}
    </ButtonLink>
  );
};

export default BackButton;
