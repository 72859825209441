interface Props {
  id: string;
}

const IconEuro = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 3H15C10.0294 3 6 7.02944 6 12V12C6 16.9706 10.0294 21 15 21H20"
      stroke="#A7A9AC"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <line
      stroke="#A7A9AC"
      strokeLinecap="round"
      strokeWidth="2"
      x1="3"
      x2="16"
      y1="9"
      y2="9"
    />
    <line
      stroke="#A7A9AC"
      strokeLinecap="round"
      strokeWidth="2"
      x1="3"
      x2="16"
      y1="15"
      y2="15"
    />
  </svg>
);

export default IconEuro;
