interface Props {
  className?: string;
  id: string;
}

const IconSearch = ({ className, id, ...rest }: Props) => (
  <svg
    className={className || ''}
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line
    {...rest}
  >
    <path
      clipRule="evenodd"
      d="M5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5ZM10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.06 18 13.5087 17.5237 14.7087 16.7087L18.9497 20.9497C19.3403 21.3403 19.9734 21.3403 20.364 20.9497C20.7545 20.5592 20.7545 19.9261 20.364 19.5355L16.2014 15.373C17.3228 14.0622 18 12.3602 18 10.5C18 6.35786 14.6421 3 10.5 3Z"
      fill="#2B2B2B"
      fillRule="evenodd"
    />
  </svg>
);

export default IconSearch;
