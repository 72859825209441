import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchBanners, hideBanner } from '../api/baner';
import strings from '../constants/strings';
import {
  BannerState,
  BannerType,
  FetchBannersResponse,
} from '../types/baner.types';
import { arraySort } from '../utils/array.utils';

export const initialState: BannerState = {
  bannerToShow: null,
  banners: null,
  error: null,
  isLoading: false,
};

const bannersSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    resetBanners() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending.toString(), (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(
        fetchBanners.fulfilled.toString(),
        (state, action: PayloadAction<FetchBannersResponse>) => {
          const idBannerToShow: number =
            arraySort(
              action.payload.zoznamBanerov?.filter(
                (b) => b.zobrazitPouzivatelovi,
              ),
              'priorita',
            )[0]?.id || 0;
          const choosedBanner: BannerType | null =
            action.payload.zoznamBanerov?.filter(
              (b) => b.id === idBannerToShow,
            )?.[0] || null;
          if (!choosedBanner) return state;
          return {
            ...state,
            bannerToShow: {
              idBaner: idBannerToShow,
              path: `${choosedBanner.baseUrl}${choosedBanner.url || ''}`,
              theme: choosedBanner.nazov,
              visible: {
                banner:
                  choosedBanner.zobrazitPouzivatelovi &&
                  choosedBanner.odmietnutePouzivatelom < 3,
                card:
                  choosedBanner.zobrazitPouzivatelovi &&
                  choosedBanner.odmietnutePouzivatelom > 3,
                popup:
                  choosedBanner.zobrazitPouzivatelovi &&
                  choosedBanner.odmietnutePouzivatelom === 3,
              },
            },
            banners: action.payload.zoznamBanerov,
            isLoading: false,
          };
        },
      )
      .addCase(fetchBanners.rejected.toString(), () => ({
        ...initialState,
        error: strings.defaultError,
      }))
      .addCase(hideBanner.pending.toString(), (state) => {
        if (state.bannerToShow)
          return {
            ...state,
            bannerToShow: {
              ...state.bannerToShow,
              visible: {
                banner: false,
                card: false,
                popup: false,
              },
            },
          };
        return state;
      });
  },
});

export const { resetBanners } = bannersSlice.actions;

export default bannersSlice.reducer;
