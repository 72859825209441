import { Button, ButtonLink } from '@dovera/design-system';
import SolveRow from '../../../../components/SolveRow/SolveRow';
import { getSolveWidgetData } from '../../../../utils/dashboard.utils';
import { cx } from '../../../../utils/exports';
import { formatNameStr } from '../../../../utils/strings.utils';
import { useAppDispatch } from '../../../../hooks/useStore';
import { resetAuth } from '../../../../slices/pzsAuth.slice';
import routes from '../../../../routes';
import { useState } from 'react';
import ModalApproval from '../../../profil/SchvaleniePristupu/ModalApproval/ModalApproval';
import { fetchDashboardSolveWidget } from '../../../../api/poskytovatel';
import { useNavigate } from 'react-router';
import useStyles from '../NaRiesenieWidget.styles';

interface Props {
  idHz: number;
  isASOC: boolean;
  isSUPR: boolean;
  pouzivatelId: number;
  vztahId: number;
}

const SpravaPristupov = ({
  idHz,
  isASOC,
  isSUPR,
  pouzivatelId,
  vztahId,
}: Props) => {
  const data = getSolveWidgetData('správa prístupov');
  const dispatch = useAppDispatch();
  const [authUserId, setAuthUserId] = useState(0);
  const [isVisibleApprovalModal, setIsVisibleApprovalModal] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const renderSolveRow = data && (
    <SolveRow
      countToSolve={data.pocetNaRiesenie}
      description={data.popis}
      showAll={null}
      state={data.typIkona}
      testId={`solveRow--sprava-pristupov`}
      title={data.nadpis}
    >
      {data.polozkyNaRiesenie.map((p, key) => {
        const isAuth = p.hodnota1 && !p.typAkcie && isSUPR;
        return (
          (key < 3 || isAuth) && (
            <div
              key={`naRiesenie-${p.nadpis}-${p.naRiesenieId}`}
              className={cx('child-row', isAuth ? 'child-row--auth' : '')}
            >
              <div
                className={cx(
                  classes.spravaPristupovRow,
                  isAuth ? classes.spravaPristupovRowAuth : '',
                )}
              >
                <div>
                  <div>
                    <b
                      className={`text-color-${
                        isAuth ? 'black' : data.typIkona
                      }`}
                      data-hj-masked
                    >
                      {!p.typAkcie ? formatNameStr(p.nadpis) : p.nadpis}
                    </b>
                  </div>

                  <div>
                    {p.popis ||
                      p.text1
                        ?.split(',')
                        .map((t) => <div key={`key-${t}`}>{t}</div>)}
                  </div>
                </div>
                <div>
                  {!isAuth && (
                    <ButtonLink
                      className="inline-btn"
                      href={p.hodnota1?.includes('http') && p.hodnota1}
                      onClick={() =>
                        p.hodnota1 &&
                        !p.hodnota1.includes('http') &&
                        navigate(p.hodnota1)
                      }
                      target={p.hodnota1?.includes('http') ? '_blank' : '_self'}
                    >
                      {p.typAkcie}
                    </ButtonLink>
                  )}
                  {isAuth && (
                    <div className={cx(classes.buttons, 'd-flex')}>
                      <ButtonLink
                        onClick={() => {
                          dispatch(resetAuth());
                          setTimeout(() => {
                            navigate(
                              `${routes.schvaleniePristupu}/${p.hodnota1}`,
                            );
                          }, 100);
                        }}
                      >
                        Pozrieť detail
                      </ButtonLink>
                      <Button
                        onClick={() => {
                          setAuthUserId(Number(p.hodnota1));
                          setIsVisibleApprovalModal(true);
                        }}
                        size="xs"
                      >
                        Schváliť teraz
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        );
      })}
    </SolveRow>
  );
  if (!data) return <span />;
  return (
    <>
      {renderSolveRow}
      <ModalApproval
        isVisible={isVisibleApprovalModal}
        onHide={() => setIsVisibleApprovalModal(false)}
        onSubmit={() =>
          dispatch(
            fetchDashboardSolveWidget({
              idHz,
              idPouzivatel: pouzivatelId || 0,
              idVZ: vztahId || 0,
              isAsoc: isASOC,
            }),
          )
        }
        userId={authUserId}
      />
    </>
  );
};

export default SpravaPristupov;
