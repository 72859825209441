import { createUseStyles } from 'react-jss';
import { color } from '../../constants';
import { getRem } from '@dovera/design-system';

export default createUseStyles({
  box: {
    '&.with-border': {
      '&--left': {
        borderLeft: `1px solid ${color('grey', 500)}`,
        paddingLeft: getRem(40),
      },
    },
    '& svg': {
      verticalAlign: 'middle',
      marginTop: getRem(-5),
    },
  },
});
