import IconWarning from '../CustomIcons/IconWarning';
import strings from '../../constants/strings';
import useStyles from './DetectCapsLock.styles';
import { cx } from '../../utils/exports';

interface Props {
  capsLockOn: boolean;
}

const DetectCapsLock = ({ capsLockOn }: Props) => {
  const classes = useStyles();
  if (!capsLockOn) return <span />;
  return (
    <div className={cx(classes.root, 'mb-small')}>
      <IconWarning id="capsLock-warning--icon" />
      {strings.capsLockWarning}
    </div>
  );
};

export default DetectCapsLock;
