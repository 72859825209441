import { Button, Notification } from '@dovera/design-system';
import { useCallback, useState } from 'react';
import { obnovVlakno } from '../../../api/schrankaSprav';
import IconRestart from '../../../components/CustomIcons/IconRestart';
import strings from '../../../constants/strings';
import { useAppDispatch } from '../../../hooks/useStore';
import { resetSchranky, zobrazitToast } from '../../../slices/spravy.slice';
import { cx } from '../../../utils/exports';
import useStyles from '../Spravy.styles';

interface Props {
  threadId: number;
}

const texts = strings.schrankaSprav.thread.detail;

const RestoreThread = ({ threadId }: Props) => {
  const classes = useStyles({});
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const recoverThread = useCallback(() => {
    setLoading(true);
    setError('');
    obnovVlakno(threadId)
      .then(() => {
        dispatch(zobrazitToast({ typ: 'obnovenie' }));
        dispatch(resetSchranky());
      })
      .catch(() => {
        setError(strings.defaultError);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, threadId]);
  const renderError = error && <Notification message={error} variant="error" />;
  const renderButton = (
    <Button
      className={cx(classes.replyElement, classes.button)}
      isDisabled={loading}
      isLoading={loading}
      onClick={recoverThread}
    >
      {!loading && <IconRestart id={`message-restart--${threadId}`} />}
      {texts.buttons.refresh}
    </Button>
  );
  return (
    <>
      {renderError}
      {renderButton}
    </>
  );
};

export default RestoreThread;
