import { Choice } from 'choices.js';
import removeAccents from 'remove-accents';

export const normalizeIndex = (index: number, length: number) =>
  (length + index) % length;

const doesMatch = (
  option: Choice,
  value: string,
  fromStart: boolean = true,
) => {
  const normalizedTitle = normalizeString(option.label);
  const normalizedValue = normalizeString(value);
  return fromStart
    ? normalizedTitle.startsWith(normalizedValue)
    : normalizedTitle.includes(normalizedValue);
};

const normalizeHints = (options: Choice[]): Choice[] => {
  const duplicates: string[] = [];
  const titles = options.map((o) => o.label);

  titles.forEach((t, i) => {
    if (!duplicates.includes(t) && titles.lastIndexOf(t) !== i) {
      duplicates.push(t);
    }
  });

  return options.map((o) => ({
    ...o,
    hint:
      duplicates.indexOf(o.label) !== -1
        ? o?.customProperties?.hint
        : undefined,
  }));
};

export const normalizeAccents = (s: string) => removeAccents(s);
export const normalizeCase = (s: string) => s.toLocaleLowerCase();
export const normalizeString = (s: string) =>
  normalizeCase(normalizeAccents(s));

export const optionComparator = (value: string) => {
  const vv = normalizeCase(value);
  const vvv = normalizeAccents(value);

  return (a: Choice, b: Choice) => {
    const aa = normalizeCase(a.label);
    const bb = normalizeCase(b.label);
    const aaa = normalizeAccents(aa);
    const bbb = normalizeAccents(bb);

    // case-normalized score
    const ss = aa.indexOf(vv) - bb.indexOf(vv);

    // case-and-accent normalized score
    const sss = aaa.indexOf(vvv) - bbb.indexOf(vvv);

    // alphabetical score
    const abc = aaa < bbb ? -1 : 1;

    // length score
    const l = a.label.length < b.label.length ? -1 : 1;

    // weighted score
    return 4 * ss + 3 * sss + 2 * abc + l;
  };
};

export const filterOptions = (
  options: Choice[],
  value: string,
  fromStart?: boolean,
) =>
  normalizeHints(
    options.filter((option) => doesMatch(option, value, fromStart)).concat(), // clone
  );

/**
 * Effect for dynamically resize textarea field
 * @date 2. 5. 2023 - 14:18:45
 *
 * @param {string} id
 * @param {string} value
 */
export const setTextareaHeight = (id: string, value: string) => {
  if (value?.length >= 35) {
    const element = document.getElementById(id);
    if (element) {
      if (parseInt(element.style.height, 10) > 75)
        element.style.height = 'auto';
      element.style.height = element?.scrollHeight
        ? `${element?.scrollHeight}px`
        : 'auto';
    }
  }
};
