import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    '& div, & p': {
      fontSize: '1rem !important',
      lineHeight: '1.5 !important',
    },
  },
});
