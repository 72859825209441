import { Children, ReactNode, cloneElement } from 'react';
import { cx } from '../../utils/exports';
import useStyles from './Stepper.styles';

export interface StepperState {
  active?: boolean;
  children?: ReactNode;
  completed?: boolean;
  disabled?: boolean;
  index?: number;
  lastStep?: boolean;
}

interface Props {
  // The active step, starts at 0
  activeStep: number;

  children: ReactNode;

  // Class name of the root container
  className?: string;

  // For registration
  isHorizontal?: boolean;

  // Step header callback
  onHeaderClick?: (index?: number) => void;
}

const Stepper = ({
  activeStep = 0,
  children,
  className,
  isHorizontal,
  onHeaderClick,
}: Props) => {
  const classes = useStyles();
  const stepArray = Children.toArray(children);

  const steps = stepArray.map((step, index, array) => {
    const stateProps: StepperState = {
      index,
      active: false,
      completed: false,
      disabled: false,
      lastStep: index === array.length - 1,
    };

    if (activeStep === index) {
      stateProps.active = true;
    } else if (activeStep > index) {
      stateProps.completed = true;
    } else if (activeStep < index) {
      stateProps.disabled = true;
    }

    // @ts-ignore
    return cloneElement(step, {
      ...stateProps,
      // @ts-ignore
      ...step.props,
      onHeaderClick,
    });
  });

  return (
    <div
      className={cx(
        classes.root,
        className,
        isHorizontal && classes.horizontal,
      )}
    >
      {steps}
    </div>
  );
};

export default Stepper;
