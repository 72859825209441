import cx from 'classnames';

export interface IconCalendarProps {
  // Other props
  [key: string]: any;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

const IconCalendar = ({
  className,
  size = 'medium',
  type,
  ...other
}: IconCalendarProps) => (
  <svg
    className={cx(
      'icon',
      {
        [`icon--${size}`]: size,
      },
      className,
    )}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    {type === 'full' ? (
      <path
        clipRule="evenodd"
        d="M16 1C16.5523 1 17 1.44772 17 2V3H19C20.6569 3 22 4.34315 22 6V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V6C2 4.34315 3.34315 3 5 3H7V2C7 1.44772 7.44772 1 8 1C8.55228 1 9 1.44772 9 2V3H15V2C15 1.44772 15.4477 1 16 1ZM4 9H20V19.1538C20 20.1735 19.1046 21 18 21H6C4.89543 21 4 20.1734 4 19.1538V9ZM15 11C13.8954 11 13 11.8954 13 13V14C13 15.1046 13.8954 16 15 16H16C17.1046 16 18 15.1046 18 14V13C18 11.8954 17.1046 11 16 11H15Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    ) : (
      <path
        clipRule="evenodd"
        d="M17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2V3H9V2C9 1.44772 8.55228 1 8 1C7.44772 1 7 1.44772 7 2V3H5C3.34315 3 2 4.34315 2 6V10V20C2 21.6569 3.34315 23 5 23H19C20.6569 23 22 21.6569 22 20V10V6C22 4.34315 20.6569 3 19 3H17V2ZM20 9V6C20 5.44772 19.5523 5 19 5H17V6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6V5H9V6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6V5H5C4.44772 5 4 5.44772 4 6V9H20ZM4 11H20V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V11Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    )}
  </svg>
);

export default IconCalendar;
