import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DajDataAutentifikaciePayload,
  DajDataAutentifikacieResponse,
  DajDataLekaraPayload,
  DajDataLekaraResponse,
  DajDataWidgetuNaRieseniePayload,
  DajDetailAmbulanciePayload,
  DajDetailAmbulancieResponse,
  DajOdbornostiQueries,
  DajOdbornostiResponse,
  DajSekciePristupuPayload,
  DajSekciePristupuResponse,
  DajSystemoveParametrePayload,
  DajSystemoveParametreResponse,
  DajZoznamAmbulanciiPZSPayload,
  DajZoznamAmbulanciiPZSResponse,
  DajZoznamUvazkovLekarovPZSPayload,
  DajZoznamUvazkovLekarovPZSResponse,
  NajdiStatutarovPayload,
  NajdiStatutarovResponse,
  OverDostupnostAutentifikaciePayload,
  OverDostupnostAutentifikacieResponse,
  UlozAutentifikaciuPayload,
  UlozAutentifikaciueResponse,
  UlozOdmietnutieAutentifikaciePayload,
  UlozOdmietnutieAutentifikacieResponse,
  UlozSpatnuVazbuPayload,
  UlozSpatnuVazbuResponse,
  ZmenUdajeAutentifikaciePayload,
  ZmenUdajeAutentifikacieResponse,
} from '../types/api/poskytovatel';
import { DajDataWidgetuNaRiesenieResponse } from '../types/poskytovatel.types';
import { createQueryParams } from '../utils/api.utils';

const API_URL = `${window.env?.config?.apiPath}/Poskytovatel/`;

export async function dajProfilHodnotenehoApi(idVz: string) {
  const url = `${API_URL}DajProfilHodnoteneho`;

  const { data } = await axios.post(url, { idVz });

  return data;
}

export async function dajDataVyssejZlozkyApi(
  idVZ: number,
  idPouzivatel: number,
  preLekara: boolean,
) {
  const url = `${API_URL}DajDataVyssejZlozky`;

  const { data } = await axios.post(url, { idVZ, idPouzivatel, preLekara });

  return data;
}

export async function dajAktualityNovinkyApi(
  idVZ: number,
  idPouzivatel: number,
) {
  const url = `${API_URL}DajAktualityNovinky`;
  const { data } = await axios.post(url, { idVZ, idPouzivatel });
  return data;
}

export async function aktualizujAktualityNovinkyApi(idVZ, id, typ) {
  const url = `${API_URL}AktualizujAktualityNovinky`;
  const { data } = await axios.post(url, { idVZ, id, typ });
  return data;
}

export async function ulozPouzivatelskeNastaveniaApi(
  idVZ,
  idPouzivatel,
  kodOdbNz,
  kodTypZs,
) {
  const url = `${API_URL}UlozPouzivatelskeNastavenia`;
  const { data } = await axios.post(url, {
    idVZ,
    idPouzivatel,
    kodOdbNz,
    kodTypZs,
  });
  return data;
}

export async function dajDataStatutaraApi(idPouzivatel) {
  const url = `${API_URL}DajDataStatutara`;
  const { data } = await axios.post(url, { idPouzivatel });
  return data;
}

export const fetchDashboardSolveWidget = createAsyncThunk(
  'poskytovatel/fetchDashboardSolveWidget',
  async (req: DajDataWidgetuNaRieseniePayload) => {
    const url = `${API_URL}DajDataWidgetuNaRiesenie`;
    const { data }: AxiosResponse<DajDataWidgetuNaRiesenieResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const fetchAmbulances = createAsyncThunk(
  'poskytovatel/fetchAmbulances',
  async (req: DajZoznamAmbulanciiPZSPayload) => {
    const url = `${API_URL}DajZoznamAmbulanciiPZS`;
    const { data }: AxiosResponse<DajZoznamAmbulanciiPZSResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const fetchDoctors = createAsyncThunk(
  'poskytovatel/fetchDoctors',
  async (req: DajZoznamUvazkovLekarovPZSPayload) => {
    const url = `${API_URL}DajZoznamUvazkovLekarovPZS`;
    const { data }: AxiosResponse<DajZoznamUvazkovLekarovPZSResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const fetchAmbulanceDetail = createAsyncThunk(
  'poskytovatel/fetchAmbulanceDetail',
  async (req: DajDetailAmbulanciePayload) => {
    const url = `${API_URL}DajDetailAmbulancie`;
    const { data }: AxiosResponse<DajDetailAmbulancieResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const sendFeedback = createAsyncThunk(
  'poskytovatel/sendFeedback',
  async (req: UlozSpatnuVazbuPayload) => {
    const url = `${API_URL}UlozSpatnuVazbu`;
    const { data }: AxiosResponse<UlozSpatnuVazbuResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const fetchDataAutentifikacie = createAsyncThunk(
  'poskytovatel/fetchDataAutentifikacie',
  async (req: DajDataAutentifikaciePayload) => {
    const url = `${API_URL}DajDataAutentifikacie`;
    const { data }: AxiosResponse<DajDataAutentifikacieResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const fetchDataOsobyLekaraPZS = createAsyncThunk(
  'poskytovatel/fetchDataOsobyLekaraPZS',
  async (req: DajDataLekaraPayload) => {
    const url = `${API_URL}DajDataLekara`;
    const { data }: AxiosResponse<DajDataLekaraResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const fetchNajdiStatutarov = createAsyncThunk(
  'poskytovatel/fetchNajdiStatutarov',
  async (req: NajdiStatutarovPayload) => {
    const url = `${API_URL}NajdiStatutarov`;
    const { data }: AxiosResponse<NajdiStatutarovResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const saveAuthData = createAsyncThunk(
  'poskytovatel/ulozAutentifikaciu',
  async (req: UlozAutentifikaciuPayload) => {
    const url = `${API_URL}UlozAutentifikaciu`;
    const { config, data }: AxiosResponse<UlozAutentifikaciueResponse> =
      await axios.post(url, req);
    return { config, data };
  },
);

export const fetchSections = createAsyncThunk(
  'poskytovatel/dajSekciePristupu',
  async (req: DajSekciePristupuPayload) => {
    const url = `${API_URL}DajSekciePristupu`;
    const { data }: AxiosResponse<DajSekciePristupuResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const updateAuthData = createAsyncThunk(
  'poskytovatel/zmenUdajeAutentifikacie',
  async (req: ZmenUdajeAutentifikaciePayload) => {
    const url = `${API_URL}ZmenUdajeAutentifikacie`;
    const { data }: AxiosResponse<ZmenUdajeAutentifikacieResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const saveAuthRejection = createAsyncThunk(
  'poskytovatel/ulozOdmietnutieAutentifikacie',
  async (req: UlozOdmietnutieAutentifikaciePayload) => {
    const url = `${API_URL}UlozOdmietnutieAutentifikacie`;
    const { data }: AxiosResponse<UlozOdmietnutieAutentifikacieResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const checkAuthAvailability = createAsyncThunk(
  'poskytovatel/overDostupnostAutentifikacie',
  async (req: OverDostupnostAutentifikaciePayload) => {
    const url = `${API_URL}OverDostupnostAutentifikacie`;
    const { data }: AxiosResponse<OverDostupnostAutentifikacieResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const dajSystemoveParametre = createAsyncThunk(
  'poskytovatel/dajSystemoveParametre',
  async (req: DajSystemoveParametrePayload) => {
    const { data }: AxiosResponse<DajSystemoveParametreResponse> =
      await axios.get(
        `${API_URL}DajSystemoveParametre${createQueryParams({
          withoutEmpty: true,
          queryParams: {
            ...req,
          },
        })}`,
      );
    return data;
  },
);

export const getExpertises = createAsyncThunk(
  'poskytovatel/dajOdbornosti',
  async (queries: DajOdbornostiQueries) => {
    const { data }: AxiosResponse<DajOdbornostiResponse> = await axios.get(
      `${API_URL}DajOdbornosti${createQueryParams({ withoutEmpty: true, queryParams: { ...queries } })}`,
    );
    return data;
  },
);
