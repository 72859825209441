import React from 'react';
import useStyles from './PasswordIndicator.styles';
import IconCheck from '../CustomIcons/IconCheck';
import strings from '../../constants/strings';
import IconWarning from '../CustomIcons/IconWarning';
import { Bar, BarItem, color as ddsColor } from '@dovera/design-system';
import IconErrorNotification from '../CustomIcons/IconErrorNotification';
import { cx } from '../../utils/exports';

const { strongLabel, weekLabel } = strings.passwordIndicator;

type Props = {
  color?: string;
  score: number;
} & React.JSX.IntrinsicElements['div'];

const PasswordIndicator = ({ color, score, ...other }: Props) => {
  const classes = useStyles({ color: color || '', score });
  return (
    // eslint-disable-next-line
    <div className={classes.root} role="presentation" {...other}>
      <div className={classes.bar} />
    </div>
  );
};

const PasswordStrengthBar = ({ score, ...other }: Props) => {
  let icon;
  let label;
  let color;

  icon = (
    <IconCheck
      color={ddsColor('success')}
      id="password-strength-bar--success"
    />
  );
  label = strongLabel;
  color = ddsColor('success');

  if (score < 80) {
    icon = <IconWarning id="password-strength-bar--warning" />;
    label = weekLabel;
    color = ddsColor('warning');
  }

  if (score <= 20) {
    icon = <IconErrorNotification id="password-strength-bar--error" />;
    label = weekLabel;
    color = ddsColor('error');
  }
  const classes = useStyles({ color, score });
  return (
    // eslint-disable-next-line
    <div {...other}>
      <Bar className={cx(classes.strengthBar, 'mb-small')} space="xxsmall">
        <BarItem>{icon}</BarItem>
        <BarItem>{label}</BarItem>
        <BarItem isFilling>
          <PasswordIndicator color={color} score={score} />
        </BarItem>
      </Bar>
    </div>
  );
};

export default PasswordStrengthBar;
