import { useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useAppDispatch } from '../../hooks/useStore';

export default () => {
  const authContext = useAuthContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    authContext
      .getUserManager()
      .signinSilentCallback()
      .catch((e) => {
        // eslint-disable-next-line
        console.log(e);
      });
  }, [authContext, dispatch]);

  return null;
};
