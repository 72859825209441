import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 70,
    '& h3': {
      marginTop: 30,
    },
    '& div': {
      fontSize: 16,
    },
    '& a': {
      marginTop: 30,
    },
  },
});
