import { ReactNode, RefObject, useCallback } from 'react';
// eslint-disable-next-line no-unused-vars
import { ButtonLink, Modal } from '@dovera/design-system';
import useDynamicRefs from 'use-dynamic-refs';
import useStyles from './PEK.styles';
import PEKContentSkeleton from './PEKContentSkeleton';
import { useWindowSize } from '../../hooks/useWindowSize';

interface Props {
  content: string | ReactNode;
  height?: number;
  intro: string | ReactNode;
  modalRef: RefObject<Modal['ref'] | undefined>;
  scrollToBottom: () => void;
  sections: { content: string | ReactNode; title: string }[];
}

const PekContent = ({
  content,
  height = 670,
  intro,
  modalRef,
  scrollToBottom,
  sections,
}: Props) => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const [getRef, setRef] = useDynamicRefs();
  const handleClick = useCallback(
    (ref: any) => {
      // eslint-disable-next-line
      modalRef?.current?.modal?.current?.element?.children[1].children[1].children[0].scrollTo(
        {
          top: ref.current.offsetTop - 100,
          behavior: 'smooth',
        },
      );
    },
    [modalRef],
  );
  const onScroll = useCallback(() => {
    // @ts-ignore
    const { scrollHeight, scrollTop } = document.querySelector('#pek-content');
    const zoom = window.devicePixelRatio > 1 ? 1 : window.devicePixelRatio;
    if (scrollHeight && scrollTop && height && scrollToBottom)
      if (scrollTop + height > scrollHeight * zoom - height / 1.5)
        scrollToBottom();
  }, [height, scrollToBottom]);
  return (
    <div
      className={classes.pekContent}
      id="pek-content"
      onScroll={onScroll}
      style={{
        // vypocet max vysky pre scroll (10% padding (top + bottom), 96px = height header && footer)
        maxHeight: windowSize.height - windowSize.height / 10 - 96 - 96,
      }}
    >
      {sections.length === 0 ? (
        <PEKContentSkeleton />
      ) : (
        <div>
          {intro}
          {sections.map((s) => (
            <p key={`section-${s.title}`} className="mb-xsmall">
              <ButtonLink
                className="no-pad no-mrg"
                onClick={() => handleClick(getRef(s.title))}
                size="s"
              >
                {s.title}
              </ButtonLink>
            </p>
          ))}
          <div className="mb-large" />
          {content}
          <div className="mb-large" />
          {sections.map((s) => (
            <div
              key={`pek-section-${s.title}`}
              // @ts-ignore
              ref={setRef(s.title)}
            >
              {s.content}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PekContent;
