import { useEffect } from 'react';

const useOutsideClick = (ref: any, callback: (e: Event) => void) => {
  const handleClick = (e: Event) => {
    if (
      window.innerWidth > 768 ||
      (ref.current && !ref.current.contains(e.target))
    )
      callback(e);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
