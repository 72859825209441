import React from 'react';

const IconDashedRing = ({ ...other }: React.JSX.IntrinsicElements['svg']) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      clipRule="evenodd"
      d="M9.15994 15.9165C8.78124 15.9715 8.39394 16 8 16C7.60606 16 7.21876 15.9715 6.84006 15.9165L7.12753 13.9373C7.41133 13.9785 7.70256 14 8 14C8.29744 14 8.58867 13.9785 8.87247 13.9373L9.15994 15.9165ZM5.04541 15.4367L5.78441 13.5782C5.23992 13.3617 4.73358 13.0672 4.27946 12.7076L3.03796 14.2756C3.64243 14.7542 4.31767 15.1473 5.04541 15.4367ZM1.72438 12.962L3.2924 11.7205C2.93285 11.2664 2.63827 10.7601 2.42176 10.2156L0.563296 10.9546C0.852678 11.6823 1.24579 12.3576 1.72438 12.962ZM0.0834776 9.15994L2.06271 8.87247C2.02149 8.58867 2 8.29744 2 8C2 7.70256 2.02149 7.41133 2.06271 7.12753L0.0834778 6.84006C0.0284745 7.21876 0 7.60606 0 8C0 8.39394 0.0284745 8.78124 0.0834776 9.15994ZM0.563297 5.04541L2.42176 5.78441C2.63827 5.23992 2.93285 4.73358 3.29241 4.27946L1.72438 3.03796C1.24579 3.64243 0.852679 4.31767 0.563297 5.04541ZM3.03797 1.72438L4.27946 3.2924C4.73358 2.93285 5.23992 2.63827 5.78441 2.42176L5.04541 0.563296C4.31767 0.852678 3.64243 1.24579 3.03797 1.72438ZM6.84006 0.0834776C7.21876 0.0284745 7.60606 0 8 0C8.39394 0 8.78124 0.0284745 9.15994 0.0834777L8.87247 2.06271C8.58867 2.02149 8.29744 2 8 2C7.70256 2 7.41133 2.02149 7.12753 2.06271L6.84006 0.0834776ZM10.9546 0.563297L10.2156 2.42176C10.7601 2.63827 11.2664 2.93285 11.7205 3.29241L12.962 1.72438C12.3576 1.24579 11.6823 0.852679 10.9546 0.563297ZM14.2756 3.03797L12.7076 4.27946C13.0672 4.73358 13.3617 5.23992 13.5782 5.78441L15.4367 5.04541C15.1473 4.31767 14.7542 3.64243 14.2756 3.03797ZM15.9165 6.84006L13.9373 7.12753C13.9785 7.41133 14 7.70256 14 8C14 8.29744 13.9785 8.58867 13.9373 8.87247L15.9165 9.15994C15.9715 8.78124 16 8.39394 16 8C16 7.60606 15.9715 7.21876 15.9165 6.84006ZM15.4367 10.9546L13.5782 10.2156C13.3617 10.7601 13.0672 11.2664 12.7076 11.7205L14.2756 12.962C14.7542 12.3576 15.1473 11.6823 15.4367 10.9546ZM12.962 14.2756L11.7205 12.7076C11.2664 13.0672 10.7601 13.3617 10.2156 13.5782L10.9546 15.4367C11.6823 15.1473 12.3576 14.7542 12.962 14.2756Z"
      fill="#C5C6C8"
      fillRule="evenodd"
    />
  </svg>
);

export default IconDashedRing;
