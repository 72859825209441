/* eslint-disable */
import { createContext, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FILTER_PREFIX } from '../components/Filter/constants';

interface FilterContextType {
  isExistsQueryFilter: boolean;
  removeParams: (keys: string[]) => void;
  searchParams: URLSearchParams | any;
  setSearchParams: (searchParam: any) => any;
}

const defaultFilterContext: FilterContextType = {
  isExistsQueryFilter: false,
  removeParams: (keys: string[]) => {},
  searchParams: {
    get: () => {},
    delete: (key: string) => {},
    set: (key: string) => {},
  },
  setSearchParams: () => {},
};

export const FilterContext = createContext(defaultFilterContext);

export function FilterProvider({ children }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isExistsQueryFilter = !!searchParams
    .keys()
    .next()
    .value?.includes(FILTER_PREFIX);

  const removeParams = useCallback(
    (keys: string[]) => {
      keys.forEach((key) => {
        searchParams.delete(`${FILTER_PREFIX}_${key}`);
      });
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const params = useMemo(
    () => ({
      isExistsQueryFilter,
      removeParams,
      searchParams,
      setSearchParams,
    }),
    [isExistsQueryFilter, removeParams, searchParams, setSearchParams],
  );

  return (
    <FilterContext.Provider value={params}>{children}</FilterContext.Provider>
  );
}
