import { useCarousel } from 'nuka-carousel';
import useStyles from '../styles';
import { cx } from '../../../../utils/exports';
import { Icon, colors } from '@dovera/design-system';

const Arrows = () => {
  const { currentPage, goBack, goForward, totalPages } = useCarousel();
  const classes = useStyles();

  const enablePrevNavButton = currentPage > 0;
  const enableNextNavButton = currentPage < totalPages - 1;

  const prevNavClassName = cx(
    classes.arrow,
    !enablePrevNavButton && 'disabled',
  );

  const nextNavClassName = cx(
    classes.arrow,
    !enableNextNavButton && 'disabled',
  );

  return (
    <div className={classes.arrowsWrapper}>
      <button
        className={prevNavClassName}
        onClick={() => enablePrevNavButton && goBack()}
        type="button"
      >
        <Icon
          color={enablePrevNavButton ? colors.black : colors.grey[100]}
          name="arrow-left"
          size="medium"
        />
      </button>
      <button
        className={nextNavClassName}
        onClick={() => enableNextNavButton && goForward()}
        type="button"
      >
        <Icon
          color={enableNextNavButton ? colors.black : colors.grey[100]}
          name="arrow-right"
          size="medium"
        />
      </button>
    </div>
  );
};

export default Arrows;
