import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import useStyles from './FloatingButton.styles';
import { RootState } from '../../rootReducer';
import { scrollingDown } from '../../slices/pomocnik.slice';

interface OwnProps {
  // contentTitle: string;
  contentBody: React.ReactNode;
  floatingButtonIcon: React.ReactNode;
  floatingButtonTitle: string;
  menu: React.ReactNode;
  onPomocnikClick: () => void;
}

type Props = OwnProps;

const FloatingButton = ({
  contentBody,
  floatingButtonIcon,
  floatingButtonTitle,
  menu,
  onPomocnikClick,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rootRef = React.useRef<any>();

  const [scrollTop, setScrollTop] = React.useState(0);

  const {
    expandedContent,
    isOpen,
    openedMenu,
    openedMenuItem,
    scrollingPomocnik,
  } = useSelector((state: RootState) => ({
    expandedContent: state.pomocnik.expanded,
    isOpen: state.pomocnik.open,
    openedMenu: state.pomocnik.openedMenu,
    openedMenuItem: state.pomocnik.openedItem,
    scrollingPomocnik: state.pomocnik.scrollingDown,
  }));

  const scrollPomocnikCallback = React.useCallback(
    (e) => {
      const element = e.target as HTMLElement;
      if (element.scrollTop > scrollTop) {
        if (!scrollingPomocnik)
          dispatch(scrollingDown({ scrollingDown: true }));
      } else if (scrollingPomocnik)
        dispatch(scrollingDown({ scrollingDown: false }));
      setScrollTop(element.scrollTop);
    },
    [dispatch, scrollTop, scrollingPomocnik],
  );

  return (
    <div
      ref={rootRef}
      className={expandedContent ? cx(classes.expandedRoot) : cx(classes.root)}
      onScroll={(e) => scrollPomocnikCallback(e)}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={
          expandedContent ? 'hide' : cx(classes.floatingButton, 'no-mrg-bottom')
        }
        onClick={() => {
          onPomocnikClick();
        }}
      >
        <p
          className={cx(
            classes.floatingButtonTitle,
            'd-flex-row  align-items-middle',
          )}
        >
          {floatingButtonTitle}
          {floatingButtonIcon}
        </p>
      </div>
      {openedMenu && !openedMenuItem && isOpen && <div>{menu}</div>}
      {openedMenuItem && openedMenu && isOpen && (
        <div
          className={
            expandedContent ? classes.expandedContent : classes.content
          }
        >
          <div
            className={
              expandedContent
                ? classes.extendedContentBody
                : classes.contentBody
            }
          >
            {contentBody}
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingButton;
