import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { dajVerzieAplikacieApi } from '../api';

type AppVersions = {
  apm: string;
  database: string;
  etl: string;
  services: string;
};

export const initialState: AppVersions = {
  apm: '',
  database: '',
  etl: '',
  services: '',
};

const versionSlice = createSlice({
  name: 'versions',
  initialState,
  reducers: {
    dajVerzieAplikacie(state, action: PayloadAction<AppVersions>) {
      return action.payload;
    },
  },
});

export const { dajVerzieAplikacie } = versionSlice.actions;
export default versionSlice.reducer;

export const fetchVerzieAplikacie = (): AppThunk => async (dispatch) => {
  try {
    const data = await dajVerzieAplikacieApi();
    dispatch(dajVerzieAplikacie(data));
  } catch (err) {
    // eslint-disable-next-line
    console.error('Chyba pri získavaní verzie aplikácie.');
  }
};
