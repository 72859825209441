import { filterOptions, normalizeIndex } from './utils';
import createStore from './createStore';
import { Choice } from 'choices.js';

interface AutocompleState {
  isLoading: boolean;
  options: Choice[];
  selectedOption: Choice | null;
  showNoMath: boolean;
  suggestionFocusIndex: number;
  suggestions: Choice[];
  value: string;
}

const autocompleteActions = {
  init: () => ({
    value: '',
    options: [],
    showNoMath: false,
    suggestions: [],
    suggestionFocusIndex: -1,
    isLoading: false,
    selectedOption: null,
  }),
  valueChanged: (_: AutocompleState, { value }: { value: string }) => ({
    value,
  }),
  input: (_: AutocompleState, { value }: { value: string }) => ({
    value,
    options: [],
    suggestions: [],
    suggestionFocusIndex: -1,
    selectedOption: null,
    showNoMath: false,
  }),
  startQuery: () => ({
    isLoading: true,
  }),
  finishQuery: (
    { value }: AutocompleState,
    {
      notFilterOptions,
      options,
      query,
    }: { notFilterOptions: boolean; options: Choice[]; query: string },
  ) =>
    value === query
      ? {
          options,
          showNoMath: true,
          suggestions: notFilterOptions
            ? options
            : filterOptions(options, value, false),
          isLoading: false,
        }
      : {
          showNoMath: true,
        },
  focusSuggestion: (
    state: AutocompleState,
    { indexDelta }: { indexDelta: number },
  ) => ({
    suggestionFocusIndex: normalizeIndex(
      state.suggestionFocusIndex + indexDelta,
      state.suggestions.length,
    ),
  }),
  select: (_: AutocompleState, { option }: { option: Choice }) => ({
    value: option?.label,
    selectedOption: option,
  }),
  cancel: () => ({}),
};

const useAtocompleteState = createStore<
  AutocompleState,
  typeof autocompleteActions
>(autocompleteActions);

export default useAtocompleteState;
