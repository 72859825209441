import strings from '../../../constants/strings';
import { Button, ButtonLayout, ButtonSecondary } from '@dovera/design-system';

interface Props {
  hide?: boolean;
  onCancel?: () => void;
  onContinue: () => void;
  showCancel?: boolean;
}

const texts = strings.proposals.new.buttons;

const StepButtons = ({ hide, onCancel, onContinue, showCancel }: Props) => {
  if (hide) return <span />;
  if (!showCancel)
    return (
      <Button className="no-mrg" onClick={onContinue} submit>
        {texts.continue}
      </Button>
    );
  return (
    <ButtonLayout direction="horizontal">
      <Button className="no-mrg" onClick={onCancel}>
        {texts.cancel}
      </Button>
      <ButtonSecondary
        className="text-space-left no-mrg-bottom"
        onClick={onContinue}
        submit
      >
        {texts.continue}
      </ButtonSecondary>
    </ButtonLayout>
  );
};

export default StepButtons;
