import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import {
  aktualizujAktualityNovinkyApi,
  dajAktualityNovinkyApi,
  dajDataStatutaraApi,
  dajDataVyssejZlozkyApi,
  getExpertises,
  ulozPouzivatelskeNastaveniaApi,
} from '../api/poskytovatel';
import { logPEKAPI } from '../api';
import { AppThunk } from '../store';
import {
  Aktualita,
  DashboardState,
  DataVZResponse,
  Novinka,
  PoskytovatelState,
} from '../types/poskytovatel.types';
import {
  changeActiveDefaultExpertise,
  changeActiveExpertise,
} from '../utils/poskytovatel.utils';
import { resetProfile } from './profil.slice';
import { arraySort } from '../utils/array.utils';
import { DajOdbornostiResponse } from '../types/api/poskytovatel';
import { LoadingState } from '../types';

export const initialState: PoskytovatelState = {
  autentifikovaneOsoby: null,
  celyNazovZobrazPZS: '',
  dashboard: {
    aktuality: [],
    error: null,
    errorType: null,
    isLoading: false,
    isLoaded: false,
    novinky: [],
  },
  dostupneOdbornostiHP: [],
  dostupneOdbornostiPP: [],
  idHodnotenyPZS: 0,
  ico: undefined,
  idHz: 0,
  idLekarOsoba: 0,
  jeStatutarNaPristupe: false,
  chybaKonatel: false,
  kodPZS: '',
  kodTypZs: '',
  kodZdruzenie: undefined,
  maZmluvnePristroje: false,
  menoZobrazLekar: undefined,
  nazovZobrazPzs: undefined,
  personalizedDataReady: false,
  poslednaZmenaOdbornosti: '',
  povolenyVstupHK: false,
  povolenyVstupPP: false,
  preLekara: true,
  odbornosti: [],
  odbornostiState: LoadingState.none,
  odbornostiEmptyState: false,
  email: null,
  error: null,
  errorType: null,
  isLoading: false,
  isLoaded: false,
  statutar: {
    data: null,
    error: null,
    isLoading: false,
  },
  odbornostPublic: [],
  zobrazPEK: false,
  pekChyba: null,
  pekLoading: false,
  temy: [],
  usePersonalizedData: false,
  zmenenaOdbornost: false,
};

const dashboardSlice = createSlice({
  name: 'poskytovatel',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getExpertises.pending.toString(), (state) => ({
        ...state,
        isLoading: true,
        odbornostiState: LoadingState.fetching,
      }))
      .addCase(
        getExpertises.fulfilled.toString(),
        (state, action: PayloadAction<DajOdbornostiResponse>) => ({
          ...state,
          isLoading: false,
          kodTypZs: !state.kodTypZs
            ? action.payload?.odbornosti?.some(
                (p) => p.kodTypZS && p.predvolenaNZ === '1',
              )
              ? action.payload.odbornosti.filter(
                  (p) => p.kodTypZS && p.predvolenaNZ === '1',
                )[0]?.kodTypZS
              : action.payload.odbornosti.filter((o) => o.kodOdbNz)[0]?.kodTypZS
            : state.kodTypZs,
          odbornosti: action.payload.odbornosti.map((o, index) => ({
            ...o,
            aktivna: o.predvolenaNZ === '1' || index === 0,
          })),
          odbornostiState: LoadingState.filled,
          poslednaZmenaOdbornosti: !state.poslednaZmenaOdbornosti
            ? action.payload.odbornosti.some(
                (p) => p.kodOdbNz && p.predvolenaNZ === '1',
              )
              ? action.payload.odbornosti.filter(
                  (p) => p.kodOdbNz && p.predvolenaNZ === '1',
                )[0]?.kodOdbNz
              : action.payload.odbornosti.filter((o) => o.kodOdbNz)[0]?.kodOdbNz
            : state.poslednaZmenaOdbornosti,
        }),
      )
      .addCase(
        getExpertises.rejected.toString(),
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.errorType = 'technical';
          state.isLoading = false;
          state.isLoaded = true;
          state.odbornostiState = LoadingState.error;
        },
      );
  },
  reducers: {
    dajDataVyssejZlozkyStart(state) {
      state.error = null;
      state.errorType = null;
      state.isLoading = true;
    },
    dajDataVyssejZlozky(state, action: PayloadAction<DataVZResponse>) {
      state = {
        ...state,
        ...action.payload,
        celyNazovZobrazPZS: action.payload.celyNazovZobrazPZS || '',
        email: action.payload.email,
        ico: action.payload.ico,
        idHz: action.payload.idHz || initialState.idHz,
        isLoading: false,
        kodZdruzenie: action.payload.kodZdruzenie || '',
        nazovZobrazPzs: action.payload.nazovZobrazPzs || '',
        isLoaded: true,
        personalizedDataReady: true,
        temy: action.payload.temy || [],
        usePersonalizedData: false,
      };
      return state;
    },
    dajDataVyssejZlozkyChyba(state, action) {
      state.error = action.payload;
      state.errorType = 'technical';
      state.isLoading = false;
      state.isLoaded = true;
    },
    zmenQueryOdbornost(
      state,
      action: PayloadAction<{ kodTypZs?: string | null; odbornost: string }>,
    ) {
      const odb = state.odbornosti.filter(
        (o) =>
          o.kodOdbNz === action.payload.odbornost &&
          (!o.kodTypZS || o.kodTypZS === action.payload.kodTypZs),
      )?.[0];
      state.poslednaZmenaOdbornosti = action.payload
        ? action.payload.odbornost
        : '';
      state.kodTypZs = action.payload
        ? action.payload.kodTypZs || ''
        : odb?.kodTypZS || '';
      state.odbornosti = changeActiveExpertise(
        odb?.nazovOdbNz || '',
        odb?.kodTypZS || '',
        state.odbornosti,
      );
    },
    zmenOdbornost(
      state,
      action: PayloadAction<{
        kodTypZS: string;
        userAction?: boolean;
        value: string;
      }>,
    ) {
      if (
        (state.zmenenaOdbornost && action.payload.userAction) ||
        !state.zmenenaOdbornost
      ) {
        if (action.payload.userAction) state.zmenenaOdbornost = true;
        state.poslednaZmenaOdbornosti = action.payload
          ? action.payload.value
          : '';
        state.kodTypZs = action.payload.kodTypZS;
        state.odbornosti = changeActiveDefaultExpertise(
          action.payload.value,
          action.payload.kodTypZS,
          state.odbornosti,
        );
      }
      state.isLoading = false;
    },
    autoChangeExpertise(
      state,
      action: PayloadAction<{ kodOdbornosti: string; kodTypZS: string }>,
    ) {
      state.poslednaZmenaOdbornosti = action.payload
        ? action.payload.kodOdbornosti
        : '';
      state.kodTypZs = action.payload.kodTypZS;
      state.odbornosti = changeActiveDefaultExpertise(
        action.payload.kodOdbornosti,
        action.payload.kodTypZS,
        state.odbornosti,
      );
    },
    nepovolenyPristup(state, action: PayloadAction<boolean>) {
      state.errorType = action.payload ? 'not-allowed' : null;
    },
    dajAktualityNovinkyStart(state) {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          isLoading: true,
        },
      };
    },
    dajAktualityNovinky(
      state,
      action: PayloadAction<{
        aktuality: Aktualita[];
        novinky: Novinka[];
      }>,
    ) {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          isLoaded: true,
          isLoading: false,
          aktuality: action.payload.aktuality,
          novinky: action.payload.novinky,
        },
      };
    },
    dajAktualityNovinkyChyba(state, action) {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          isLoaded: true,
          isLoading: false,
          error: action.payload,
          errorType: 'technical',
        },
      };
    },
    dajDataStatutaraStart(state) {
      return {
        ...state,
        statutar: {
          ...initialState.statutar,
          isLoading: true,
        },
      };
    },
    dajDataStatutara(state, action) {
      return {
        ...state,
        statutar: {
          data: action.payload || 'success',
          error: null,
          isLoading: false,
        },
      };
    },
    dajDataStatutaraChyba(state, action) {
      return {
        ...state,
        statutar: {
          data: null,
          error: action.payload,
          isLoading: false,
        },
      };
    },
    publicOdbornost(
      state,
      action: PayloadAction<{ kodOdbAmb: string; kodOdbAmbNazov: string }[]>,
    ) {
      return {
        ...state,
        odbornostPublic: action.payload,
      };
    },
    logPEKStart(state) {
      return {
        ...state,
        pekLoading: true,
      };
    },
    logPEK(state) {
      return {
        ...state,
        pekLoading: false,
        zobrazPEK: false,
      };
    },
    logPEKChyba(state, action: PayloadAction<any>) {
      return {
        ...state,
        pekLoading: false,
        pekChyba: action.payload,
      };
    },
    manualnaZmenaOdbornosti(state) {
      return {
        ...state,
        zmenenaOdbornost: true,
        poslednaZmenaOdbornosti: state.odbornosti.some(
          (d) => d.aktivna && d.kodOdbNz,
        )
          ? state.odbornosti.filter((d) => d.aktivna)?.[0]?.kodOdbNz
          : state.odbornosti[0]?.kodOdbNz,
      };
    },
    changeDataPersonalization(state, action) {
      return {
        ...state,
        usePersonalizedData: action.payload.personalized,
      };
    },
    switchDataPzsDoctor(state, action: PayloadAction<{ forPZS: boolean }>) {
      return {
        ...state,
        personalizedDataReady: false,
        preLekara: !action.payload.forPZS,
      };
    },
    zmenEmptyStateOdbornosti(
      state,
      action: PayloadAction<{ emptyState: boolean }>,
    ) {
      return {
        ...state,
        odbornostiEmptyState: action.payload.emptyState,
      };
    },
    resetActuality(state) {
      return {
        ...state,
        dashboard: initialState.dashboard,
      };
    },
  },
});

export const {
  autoChangeExpertise,
  changeDataPersonalization,
  dajAktualityNovinky,
  dajAktualityNovinkyChyba,
  dajAktualityNovinkyStart,
  dajDataStatutara,
  dajDataStatutaraChyba,
  dajDataStatutaraStart,
  dajDataVyssejZlozky,
  dajDataVyssejZlozkyChyba,
  dajDataVyssejZlozkyStart,
  logPEK,
  logPEKChyba,
  logPEKStart,
  manualnaZmenaOdbornosti,
  nepovolenyPristup,
  publicOdbornost,
  resetActuality,
  switchDataPzsDoctor,
  zmenEmptyStateOdbornosti,
  zmenOdbornost,
  zmenQueryOdbornost,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const fetchDataVZ =
  (idVZ: number, idPouzivatel, preLekara: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(resetProfile());
      dispatch(dajDataVyssejZlozkyStart());
      const poskytovatel = await dajDataVyssejZlozkyApi(
        idVZ,
        idPouzivatel,
        preLekara,
      );
      dispatch(dajDataVyssejZlozky(poskytovatel));
      dispatch(getExpertises({}));
    } catch (err) {
      dispatch(dajDataVyssejZlozkyChyba(err));
    }
  };

export const fetchAktualityNovinky =
  (idVZ: number, idPouzivatel?: number | null): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajAktualityNovinkyStart());
      const response = await dajAktualityNovinkyApi(idVZ, idPouzivatel || 0);
      dispatch(dajAktualityNovinky(response));
    } catch (err) {
      dispatch(dajAktualityNovinkyChyba(err));
    }
  };

export const aktualizujAktualityNovinky =
  (
    idVZ: number,
    id: number,
    typ: string,
    idPouzivatel?: number | null,
  ): AppThunk =>
  async (dispatch) => {
    try {
      await aktualizujAktualityNovinkyApi(idVZ, id, typ);
      const response = await dajAktualityNovinkyApi(idVZ, idPouzivatel || 0);
      dispatch(dajAktualityNovinky(response));
    } catch (err) {
      // console.error('Chyba pri aktualizovaní novinky.');
    }
  };

export const ulozPouzivatelskeNastavenia =
  (
    idVZ: number,
    idPouzivatel: number,
    kodOdbNz: string,
    kodTypZs: string,
  ): AppThunk =>
  async () => {
    try {
      await ulozPouzivatelskeNastaveniaApi(
        idVZ,
        idPouzivatel,
        kodOdbNz,
        kodTypZs,
      );
    } catch (err) {
      // console.error('Chyba pri ukladaní používateľských nastavení.');
    }
  };

export const fetchDataStatutara =
  (idPouzivatel: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajDataStatutaraStart());
      const response = await dajDataStatutaraApi(idPouzivatel);
      dispatch(dajDataStatutara(response));
    } catch (err) {
      dispatch(dajDataStatutaraChyba(err));
    }
  };

export const fetchLogPEK =
  (idVZ: number, idPouzivatel: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(logPEKStart());
      await logPEKAPI(idVZ, idPouzivatel);
      dispatch(logPEK());
    } catch (err) {
      dispatch(logPEKChyba(err));
    }
  };

const getNovinky = (state: DashboardState) => state.novinky;
const getAktuality = (state: DashboardState) => state.aktuality;

export const selectHPActualities = createSelector(
  [getNovinky, getAktuality],
  (novinky, aktuality) => {
    let arr = [
      ...((aktuality && aktuality.filter((a) => !!a.kodAktualita)) || []),
      ...(novinky || []),
    ];
    if (!arr.length) return [];
    arr = arraySort(arr, 'datum', false, true);
    return arraySort(arr, 'precitane');
  },
);

export const selectUnreadActualities = createSelector(
  [getNovinky, getAktuality],
  (novinky, aktuality) =>
    (novinky?.filter((n) => !n.precitane)?.length || 0) +
    (aktuality?.filter((a) => a.kodAktualita && !a.precitane)?.length || 0),
);
