import { ReactNode } from 'react';
import useStyles from './GridTable.styles';

interface Props {
  children: string | ReactNode;
  spaceLeft?: boolean | number;
  spaceRight?: boolean | number;
}

const GridColumn = ({ children, spaceLeft, spaceRight }: Props) => {
  const classes = useStyles({ spaceLeft, spaceRight });
  return <td className={classes.td}>{children}</td>;
};

export default GridColumn;
