import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DajDetailParametraHodnoteniaPayload,
  DajDetailParametraHodnoteniaResponse,
  GetActualPricesQueries,
  GetBannersPayload,
  GetBannersResponse,
  GetExchangeParametersQueries,
  GetExchangeParametersResponse,
  GetExperisePeriods,
  GetParameterDetailQueries,
  GetParameterDetailResponse,
  GetParametersResponse,
  GetPeriodsForExpertiseQueries,
  GetReportDataPayload,
  GetReportDataResponse,
  SaveSelectedParametersPayload,
  SaveSelectedParametersResponse,
  UlozParameterHodnoteniaPayload,
  UlozParameterHodnoteniaResponse,
} from '../types/parameters.types';
import { createQueryParams } from '../utils/api.utils';
import {
  GetActualPricesSK,
  GetExchangeParametersSK,
  GetExpertisePeriodsSK,
  GetParameterDetailSK,
  GetParametersQueries,
  GetParametersSK,
} from '../translations/apiTypes/parameters.types';
import {
  convertGetActualPrices,
  convertGetExchangeParameters,
  convertGetExpertisePeriods,
  convertGetParameterDetail,
  convertGetParameters,
  convertSaveParametersSelection,
} from '../translations/apiResponses/parameters';
import { convertPayloadToSK } from '../translations/utils';

const API_URL = `${window.env?.config?.apiPath}/HodnotiaceParametre/`;

export const fetchHPDetail = createAsyncThunk(
  'hp/fetchHPDetail',
  async (req: DajDetailParametraHodnoteniaPayload) => {
    const url = `${API_URL}DajDetailParametraHodnotenia`;
    const { data }: AxiosResponse<DajDetailParametraHodnoteniaResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const saveHPExchange = createAsyncThunk(
  'hp/saveHPExchange',
  async (req: UlozParameterHodnoteniaPayload) => {
    const url = `${API_URL}UlozParameterHodnotenia`;
    const { data }: AxiosResponse<UlozParameterHodnoteniaResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const getReportData = async (
  payload: GetReportDataPayload,
): Promise<GetReportDataResponse> => {
  const url = `${API_URL}DajReportData`;
  const { data } = await axios.post(url, payload);
  return data || null;
};

export const getCurrentPrices = createAsyncThunk(
  'hp/getCurrentHPPrices',
  async (queries: GetActualPricesQueries) => {
    const { data }: AxiosResponse<GetActualPricesSK> = await axios.get(
      `${API_URL}DajAktualneCenyParametrov${createQueryParams({
        withoutEmpty: false,
        queryParams: {
          ...convertPayloadToSK(queries),
        },
      })}`,
    );
    return convertGetActualPrices(data);
  },
);

export const getPeriodsForExpertise = createAsyncThunk(
  'hp/getPeriodsForExpertise',
  async (
    queries: GetPeriodsForExpertiseQueries,
  ): Promise<GetExperisePeriods> => {
    const url = `${API_URL}DajObdobiaPreOdbornost`;

    const { data }: AxiosResponse<GetExpertisePeriodsSK> = await axios.get(
      `${url}${createQueryParams({ withoutEmpty: false, queryParams: { ...queries } })}`,
    );
    return convertGetExpertisePeriods(data);
  },
);

export const getBanners = createAsyncThunk(
  'hp/getBanners',
  async (req: GetBannersPayload): Promise<GetBannersResponse> => {
    const url = `${API_URL}DajBannery`;
    const { data }: AxiosResponse<GetBannersResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const getParameters = createAsyncThunk(
  'hp/getParameters',
  async (queries: GetParametersQueries): Promise<GetParametersResponse> => {
    const url = `${API_URL}DajHodnotiaceParametre`;
    const { data }: AxiosResponse<GetParametersSK> = await axios.get(
      `${url}${createQueryParams({ withoutEmpty: false, queryParams: { ...convertPayloadToSK(queries) } })}`,
    );
    return convertGetParameters(data);
  },
);

export const getExchangeParameters = createAsyncThunk(
  'hp/getExchangeParameters',
  async (
    queries: GetExchangeParametersQueries,
  ): Promise<GetExchangeParametersResponse> => {
    const URL = `${API_URL}DajParametrePreVymenu`;

    const { data }: AxiosResponse<GetExchangeParametersSK> = await axios.get(
      `${URL}${createQueryParams({
        withoutEmpty: false,
        queryParams: {
          ...convertPayloadToSK(queries),
        },
      })}`,
    );
    return convertGetExchangeParameters(data);
  },
);

export const getParameterDetail = createAsyncThunk(
  'hp/getParameterDetail',
  async (
    queries: GetParameterDetailQueries,
  ): Promise<GetParameterDetailResponse> => {
    const url = `${API_URL}DajDetailParametraHodnotenia`;
    const { data }: AxiosResponse<GetParameterDetailSK> = await axios.get(
      `${url}${createQueryParams({
        withoutEmpty: false,
        queryParams: {
          ...convertPayloadToSK(queries),
        },
      })}`,
    );
    return convertGetParameterDetail(data);
  },
);

export const saveSelectedParameters = createAsyncThunk(
  'hp/saveSelectedParameters',
  async (
    payload: SaveSelectedParametersPayload,
  ): Promise<SaveSelectedParametersResponse> => {
    const url = `${API_URL}UlozParametreHodnotenia`;
    const { data }: AxiosResponse<UlozParameterHodnoteniaResponse> =
      await axios.post(url, convertPayloadToSK(payload));
    return convertSaveParametersSelection(data);
  },
);
