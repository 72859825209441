import { breakpoints, color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  isPercentual?: boolean;
}

export default createUseStyles({
  chartWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    position: 'relative',
    marginBottom: getRem(100),
    minHeight: getRem(140),
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-end',
      '&.centered': {
        justifyContent: 'center',
      },
      '&.lefted': {
        justifyContent: 'flex-start',
      },
      alignItems: 'flex-end',
    },
  },
  chartPointer: {
    position: 'relative',
    width: getRem(4),
    '& svg': {
      position: 'absolute',
      bottom: getRem(-29),
      left: getRem(-4),
    },
  },
  chartPointerValue: {
    left: getRem(-5),
  },
  startValue: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'green',
    borderRadius: '20%',
  },
  borderValue: {},
  endValue: {},
  label: {
    position: 'absolute',
    width: getRem(150),
    bottom: getRem(-130),
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: getRem(100),
    fontWeight: 700,
    fontSize: getRem(14),
    color: color('grey', 500),
    left: 0,
    [`@media screen and (min-width: 1450px)`]: {
      marginLeft: getRem(-50),
    },
    '&.success': {
      color: color('success'),
    },
    '&.error': {
      color: color('error'),
      maxWidth: getRem(120),
    },
    '&.align-left': {
      left: 0,
      marginLeft: 0,
    },
    '&.align-right': {
      left: 'unset',
      right: 0,
      marginLeft: 0,
    },
    '&.label-top': {
      bottom: 'unset',
      minHeight: getRem(120),
    },
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      display: 'none',
    },
    '&.last-stick': {
      textAlign: 'right',
    },
    [`@media screen and (min-width: 1480px)`]: {
      maxWidth: getRem(130),
    },
  },
  labelRight: {
    // bottom: `${getRem(-108)} !important`,
    marginRight: getRem(16),
  },
  labelLeft: {
    // bottom: `${getRem(-108)} !important`,
    marginLeft: getRem(16),
  },
  valuePoint: {
    display: 'flex',
    position: 'relative',
  },
  valueLabel: ({ isPercentual }: StylesProps) => ({
    position: 'absolute',
    background: color('secondary'),
    color: 'white',
    padding: `${getRem(2)} ${getRem(6)}`,
    borderRadius: getRem(60),
    fontSize: getRem(14),
    display: 'flex',
    alignItems: 'center',
    marginTop: getRem(-8),
    marginLeft: getRem(isPercentual ? -95 : -75),
    minWidth: getRem(isPercentual ? 190 : 160),
    zIndex: 1,
    top: 0,
    left: '50%',
    '& svg': {
      marginRight: getRem(8),
    },
    '&.success': {
      background: color('primary', 100),
      color: color('primary', 600),
      minWidth: getRem(140),
      marginLeft: getRem(-70),
      zIndex: 99,
      textAlign: 'center',
      fontWeight: 'bold',
      top: getRem(-14),
    },
    '&.error': {
      background: color('error', 100),
      color: color('error', 600),
      minWidth: getRem(140),
      marginLeft: getRem(-70),
      zIndex: 99,
      textAlign: 'center',
      fontWeight: 'bold',
      top: getRem(-14),
    },
  }),
  line: {
    display: 'flex',
    position: 'relative',
    '&.hovered': {
      height: getRem(124),
      '& svg': {
        height: getRem(124),
      },
    },
  },
  pzsValue: {
    display: 'flex',
    alignItems: 'center',
    marginTop: getRem(16),
    '& svg': {
      marginTop: -2,
      marginRight: getRem(8),
    },
  },
  labelBottomAuto: {
    bottom: 'auto',
    textAlign: 'left',
    width: getRem(130),
    margin: 0,
    height: getRem(40),
  },
  labelTopAuto: {
    paddingTop: getRem(16),
  },
});
