import { errorCodes } from '../constants/errorCodes';
import regex from '../constants/regex';
import strings from '../constants/strings';
import { validation } from '../constants/validation';
import { VALIDATION } from '../types/validation.types';
import { validationMsg } from './form.utils';

export const translateErrorCode = (
  code?: string | number,
  prop?: string[],
): string => {
  if (code && errorCodes[code] === VALIDATION.ForbiddenFileType)
    return validation?.[errorCodes[code]](prop);
  if (!code || !errorCodes?.[code]) return strings.defaultError;
  return validation?.[errorCodes[code]] || strings.defaultError;
};

export const validateVS = (value?: string, forbiddenVS?: string[]): string => {
  const errors: any = {};
  if (!value) errors.vs = validationMsg(VALIDATION.RequiredVs);
  if (value && forbiddenVS && forbiddenVS.includes(value))
    errors.vs = validationMsg(VALIDATION.NepovolenyVariabilnySymbol);
  if (value && (!regex.NUMBERS_ONLY.test(value) || value.length > 10)) {
    errors.vs = validationMsg(VALIDATION.NumbersOnly);
  }

  return errors;
};
