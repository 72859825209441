interface Props {
  id: string;
}

const IconCheckboxCircle = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5444_176504)">
      <path
        clipRule="evenodd"
        d="M10.0166 3.21738C11.9096 2.78967 13.8902 2.98535 15.663 3.77525C16.1675 4.00003 16.7587 3.77329 16.9834 3.26882C17.2082 2.76435 16.9815 2.17317 16.477 1.94839C14.3103 0.982961 11.8896 0.743791 9.57581 1.26655C7.26206 1.78931 5.17929 3.04599 3.63811 4.84917C2.09693 6.65235 1.17992 8.90541 1.02384 11.2723C0.86777 13.6393 1.48099 15.9932 2.77206 17.9832C4.06312 19.9731 5.96285 21.4924 8.18792 22.3144C10.413 23.1365 12.8442 23.2172 15.1189 22.5447C17.3936 21.8721 19.39 20.4823 20.8103 18.5824C22.2306 16.6825 22.9986 14.3745 23 12.0024V12.0018V11.0718C23 10.5195 22.5523 10.0718 22 10.0718C21.4477 10.0718 21 10.5195 21 11.0718V12.0012C20.9989 13.942 20.3705 15.8305 19.2084 17.3849C18.0464 18.9393 16.413 20.0765 14.5518 20.6268C12.6907 21.177 10.7015 21.111 8.88103 20.4384C7.06051 19.7658 5.50619 18.5227 4.44987 16.8946C3.39354 15.2665 2.89181 13.3405 3.01951 11.4039C3.14721 9.46735 3.89749 7.62394 5.15845 6.14861C6.41942 4.67328 8.12351 3.64509 10.0166 3.21738ZM23.7071 3.70711C24.0976 3.31658 24.0976 2.68342 23.7071 2.29289C23.3166 1.90237 22.6834 1.90237 22.2929 2.29289L12 12.5858L9.70711 10.2929C9.31658 9.90237 8.68342 9.90237 8.29289 10.2929C7.90237 10.6834 7.90237 11.3166 8.29289 11.7071L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L23.7071 3.70711Z"
        fill="#2B2B2B"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_5444_176504">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCheckboxCircle;
