import { useCallback } from 'react';
import { ButtonLink, color } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useWindowSize } from '../../hooks/useWindowSize';
import { RootState } from '../../rootReducer';
import routes from '../../routes';
import IconMail from '../CustomIcons/IconMail';
import useStyles from './MessagesCount.styles';
import { useAppDispatch } from '../../hooks/useStore';
import { refresh } from '../../slices/spravy.slice';

const MessagesCount = () => {
  const dispatch = useAppDispatch();
  const { pocet } = useSelector(
    (state: RootState) => state.spravy.neprecitaneVlakna,
  );
  const classes = useStyles({ countMessages: pocet });
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const callback = useCallback(() => {
    dispatch(refresh());
    navigate(routes.spravy);
  }, [dispatch, navigate]);
  return (
    <div className={classes.messages}>
      <ButtonLink className="no-pad no-mrg" onClick={callback}>
        <IconMail color={color('black')} id="icon-messages-header" />
        {windowSize.width < 1240 ? '' : 'Správy'}
      </ButtonLink>
      <div className={classes.messagesCount}>{pocet}</div>
    </div>
  );
};

export default MessagesCount;
