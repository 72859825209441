interface Props {
  isInner?: boolean;
}

const LeftRoundBracket = ({ isInner }: Props) => {
  if (isInner)
    return (
      <svg
        fill="none"
        height="92"
        style={{ marginLeft: -7 }}
        viewBox="0 0 21 92"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 1H16.5C8.21573 1 1.5 7.71573 1.5 16V76C1.5 84.2843 8.21573 91 16.5 91H19.5"
          stroke="#DBEBDC"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </svg>
    );
  return (
    <svg
      fill="none"
      height="104"
      style={{ marginLeft: -12 }}
      viewBox="0 0 19 104"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 1H16.5C8.21573 1 1.5 7.71573 1.5 16V88C1.5 96.2843 8.21573 103 16.5 103H17.5"
        stroke="#50A028"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default LeftRoundBracket;
