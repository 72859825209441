import { color } from '../../constants';

interface Props {
  id: string;
  primaryColor?: boolean;
}

const IconBlueMinus = ({ id, primaryColor }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill={primaryColor ? color('primary') : color('secondary', 500)}
    />
    <path d="M7.58984 11.3516H16.4098V12.6476H7.58984V11.3516Z" fill="white" />
  </svg>
);

export default IconBlueMinus;
