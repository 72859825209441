// @ts-nocheck
import SafeHtml from '../SafeHtml/SafeHtml';
import useStyles from './EmptyState.styles';
import Illustration from '../../assets/illustrations/notFound2.png';
import { cx } from '../../utils/exports';
// @ts-check

const EmptyStateExpertises = () => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, classes.centeredContent)}>
      <img alt="Illustration" src={Illustration} width={400} />
      <SafeHtml
        className="mb-small"
        html="Dáta pre zvolenú odbornosť neexistujú"
        wrapper="h4"
      />
      <SafeHtml
        className="mb-small"
        html="Prosím, vyberte si dostupnú odbornosť pre danú sekciu a pokračujte."
        wrapper="div"
      />
    </div>
  );
};

export default EmptyStateExpertises;
