// @ts-nocheck
import useStyles from '../Spravy.styles';
import Illustration from '../../../assets/illustrations/no-messages.png';
import {
  Button,
  ButtonLayout,
  ButtonSecondary,
  color,
} from '@dovera/design-system';
import { useNavigate } from 'react-router';
import routes from '../../../routes';
import { IconPlus } from '../../../components';
import { IconMenuDashboard } from '../../../components/CustomIcons';
import strings from '../../../constants/strings';
// @ts-check

interface Props {
  onNewMessageClick: () => void;
}

const texts = strings.schrankaSprav.emptyStates.noMessages;

const NoMessages = ({ onNewMessageClick }: Props) => {
  const classes = useStyles({});
  const navigate = useNavigate();
  return (
    <div className={classes.emptyState}>
      <img alt="Žiadne správy" className="mb" src={Illustration} />
      <h4>{texts.title}</h4>
      <ButtonLayout direction="horizontal">
        <ButtonSecondary
          className={classes.button}
          onClick={() => navigate(routes.dashboard)}
        >
          <IconMenuDashboard
            color={color('primary', 600)}
            id="home--no-messages"
          />
          {texts.buttons.home}
        </ButtonSecondary>
        <Button className={classes.button} onClick={onNewMessageClick}>
          <IconPlus color="white" id="new-message--no-messages" />
          {texts.buttons.loadAgain}
        </Button>
      </ButtonLayout>
    </div>
  );
};

export default NoMessages;
