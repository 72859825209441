import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { ArchivujPoistencaPayload } from '../types/pacienti.types';

const API_URL = `${window.env?.config?.apiPath}/PrehladPacientov/`;

export const archivujPoistenca = createAsyncThunk(
  'pacienti/archivujPoistenca',
  async (req: ArchivujPoistencaPayload) => {
    const url = `${API_URL}ArchivujPoistenca`;
    const { data }: AxiosResponse<{ usepsne: boolean }> = await axios.post(
      url,
      req,
    );
    return data;
  },
);
