import { useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';

function LoginSSO() {
  const authContext = useAuthContext();

  useEffect(() => {
    authContext
      .getUserManager()
      .clearStaleState()
      .then(() => authContext.login());
  }, [authContext]);

  return <span />;
}

export const PureComponent = LoginSSO;

export default LoginSSO;
