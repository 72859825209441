import LeftRoundBracket from './symbols/LeftRoundBracket';
import Minus from './symbols/Minus';
import Multiplication from './symbols/Multiplication';
import Plus from './symbols/Plus';
import RightRoundBracket from './symbols/RightRoundBracket.tsx';

interface Props {
  className?: any;
  isInner?: boolean;
  symbol: string;
}

const SvgSymbol = ({ className, isInner, symbol }: Props) => {
  switch (symbol) {
    case '(':
      return <LeftRoundBracket isInner={isInner} />;
    case ')':
      return <RightRoundBracket isInner={isInner} />;
    case '+':
      return <Plus className={className} />;
    case '-':
      return <Minus className={className} />;
    case '*':
      return <Multiplication className={className} />;
    default:
      return <span />;
  }
};

export default SvgSymbol;
