import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import useStylesApp from '../../App.styles';
import { Pagination, TableApp } from '@dovera/design-system';
import { cx } from '../../utils/exports';
import SortableColumn from './SortableColumn';
import { TABLE_PAGINATION } from '../../constants/misc';
import { arraySort } from '../../utils/array.utils';
import TablePreloaderSkeleton from '../TablePreloaderSkeleton/TablePreloaderSkeleton';
import { useAppDispatch } from '../../hooks/useStore';
import { setTableOrder } from '../../slices/table.slice';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { inlineStrCondition } from '../../utils/app.utils';

type SortableColType = {
  index: number;
  isSortable?: boolean;
  name: ReactNode | string;
  sortType?: 'alphabetical' | 'alphabetical-desc' | 'date' | 'sum' | string;
};
type SortableRowType = {
  [index: string]: {
    sortableValue?: string;
    value: ReactNode | string;
  };
};
interface Props {
  className?: any;
  cols: SortableColType[];
  isLoading?: boolean;
  noPagination?: boolean;
  noRerender?: boolean;
  rows: SortableRowType[];
  storeInRedux?: boolean;
}

const SortableTable = ({
  className,
  cols,
  isLoading,
  noPagination,
  noRerender,
  rows,
  storeInRedux = true,
}: Props) => {
  const classesApp = useStylesApp();
  const dispatch = useAppDispatch();
  const rowsMemo = useMemo(() => rows, [rows]);
  const pagination = noPagination ? rowsMemo.length : TABLE_PAGINATION;
  const ref = useRef(null);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [activeKey, setActiveKey] = useState('');
  const [actualPage, setActualPage] = useState(0);
  const [sortedData, setSortedData] = useState(_.chunk(rowsMemo, pagination));
  const { colOrder } = useSelector((state: RootState) => state.table);
  const resetOrderCallback = useCallback(() => {
    // remove order
    ref.current
      // @ts-ignore
      ?.querySelector('table thead tr')
      ?.querySelectorAll('button')
      ?.forEach((b) => {
        b?.classList?.remove('asc');
        b?.classList?.remove('desc');
      });
  }, []);
  const sortCallback = useCallback(
    (
      order: 'asc' | 'desc',
      sortByKey: string,
      sortType?: 'alphabetical' | 'alphabetical-desc' | 'date' | 'sum' | string,
    ) => {
      if (order !== colOrder.order || sortByKey !== colOrder.key) {
        resetOrderCallback();
        setActualPage(0);
      }
      setActiveKey(sortByKey);
      setSortedData(
        _.chunk(
          sortType?.includes('alphabetical')
            ? arraySort(rowsMemo, `${sortByKey}.sortableValue`, order)
            : arraySort(
                rowsMemo,
                `${sortByKey}.sortableValue`,
                order === 'asc',
                sortType !== 'sum',
                sortType === 'sum',
              ),
          pagination,
        ),
      );
      if (storeInRedux) {
        dispatch(setTableOrder({ key: sortByKey, order, type: sortType }));
      }
    },
    [
      colOrder.key,
      colOrder.order,
      dispatch,
      pagination,
      resetOrderCallback,
      rowsMemo,
      storeInRedux,
    ],
  );
  const tableData = useMemo(
    () =>
      sortedData[actualPage]?.map((d) => {
        const obj = {};
        Object.keys(d).forEach((key) => {
          _.assign(obj, { [`${key}`]: d[`${key}`]?.value || '' });
        });
        return obj;
      }),
    [actualPage, sortedData],
  );
  useEffect(() => {
    if (!colOrder.key) {
      setActualPage(0);
      resetOrderCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetOrderCallback, rowsMemo]);
  useEffect(() => {
    if (!noRerender) {
      setIsTableLoading(true);
    } else {
      setIsTableLoading(false);
    }
    setSortedData(_.chunk(rowsMemo, pagination));
    sortCallback(colOrder.order, colOrder.key, colOrder.type);
    if (!noRerender) {
      setTimeout(() => {
        setIsTableLoading(false);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [rowsMemo]);
  if (isTableLoading || isLoading)
    return <TablePreloaderSkeleton columns={cols.length} rows={rows.length} />;
  return (
    <div ref={ref}>
      <TableApp
        caption=""
        className={cx(classesApp.appTable, 'table-caption--hide', className)}
        columns={cols.map((c) => ({
          key: `col${c.index}`,
          accessor: `col${c.index}`,
          Header: c.isSortable ? (
            <SortableColumn
              activeOrder={inlineStrCondition(
                colOrder.key === `col${c.index}`,
                colOrder.order,
                '',
              )}
              onClick={(order) =>
                sortCallback(order, `col${c.index}`, c.sortType)
              }
              resetOrder={activeKey !== `col${c.index}`}
              sortType={c.sortType}
            >
              {c.name}
            </SortableColumn>
          ) : (
            c.name
          ),
        }))}
        data={
          tableData.map((t, index) => ({ ...t, key: `tableRow--${index}` })) ||
          []
        }
      />
      <div className={classesApp.pagination}>
        <Pagination
          currentPage={actualPage + 1}
          onChange={(pageNumber) => setActualPage(pageNumber - 1)}
          totalPages={sortedData.length || 0}
        />
      </div>
    </div>
  );
};

export default SortableTable;
