import { createUseStyles } from 'react-jss';

export default createUseStyles({
  notificationContainer: {
    maxWidth: '60%',
    margin: '30px auto',
  },
  root: {
    margin: '24px 0',
  },
  centeredContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});
