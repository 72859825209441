import { createUseStyles } from 'react-jss';
import { color as ddsColor } from '@dovera/design-system';

interface StylesProps {
  color: string;
  score: number;
}

export default createUseStyles({
  root: {
    backgroundColor: ddsColor('grey', 100),
    height: '8px',
    borderRadius: '8px',
  },
  bar: {
    backgroundColor: ({ color }: StylesProps) => color,
    height: '100%',
    width: ({ score }: StylesProps) => `${score}%`,
    borderRadius: '8px',
  },
  strengthBar: {
    color: ({ color }: StylesProps) => color,
    '& svg': {
      verticalAlign: 'middle',
      marginTop: -2,
    },
    '& .bar__item': {
      marginTop: 0,
    },
  },
});
