/* eslint-disable no-unused-vars */
import {
  AmbulanciaPZS,
  DataAutentifikacie,
  DataOsobyLekaraPZS,
  OdbornostHodnoteneho,
  OrdinacneHodiny,
  SekciaPristupu,
  StatutarRPO,
  UvazokLekaraAmbPZS,
  UvazokLekaraPZS,
} from '../poskytovatel.types';
import { Adresa } from '../models/Adresa';

export interface DajZoznamUvazkovLekarovPZSResponse {
  uvazky: UvazokLekaraPZS[] | null;
}

export interface DajZoznamAmbulanciiPZSResponse {
  ambulancie: AmbulanciaPZS[];
}

export interface DajZoznamUvazkovLekarovPZSPayload {
  datumDo: string;
  idLekarOsoba: number | null;
  idVZ: number;
}

export interface DajZoznamAmbulanciiPZSPayload {
  datumDo: string;
  idLekarOsoba: number | null;
}

export interface DajDetailAmbulanciePayload {
  idLekarOsoba: number | null;
  idNZ: number;
  idVZ: number;
}

export interface DajDetailAmbulancieResponse {
  adresa: Adresa[];
  detail: {
    id: number;
    kodOdbAmb: string;
    nazov: string;
    nazovOdbAmb: string;
  };
  ordinacneHodiny: OrdinacneHodiny[];
  uvazok: UvazokLekaraAmbPZS[];
}

export interface UlozSpatnuVazbuPayload {
  email: string | null;
  hodnotenie: number;
  idPouzivatel: number;
  idVztahu: number;
  sprava: string | null;
  typyHodnotenia: string[];
  url: string;
}

export interface UlozSpatnuVazbuResponse {
  ulozene: boolean;
}

export interface DajDataAutentifikaciePayload {
  idPouzivatel: number;
  idVZ: number;
  jeHlavnaKarta: boolean;
}

export interface DajDataAutentifikacieResponse {
  dataAutentifikacie: DataAutentifikacie[];
}

export interface DajDataLekaraPayload {
  ico: string;
  idPouzivatel: number;
  idStatutar?: number | null;
  idVZ: number;
  kodLekara: string;
}

export interface DajDataLekaraResponse {
  chyba: string | null;
  idPersonalizovanehoUctuLekara: number | null;
  jeLekarStatutarJednaFO: boolean | null;
  lekarOsoba: DataOsobyLekaraPZS;
  vytvorNovyPristup: boolean | null;
}

export interface NajdiStatutarovPayload {
  ico: string;
  idPouzivatel: number;
  nazov: string;
  pouzitSluzbuRPO?: boolean;
}

export interface NajdiStatutarovResponse {
  chyba: string;
  hodnoty: StatutarRPO[];
}

export interface UlozAutentifikaciuPayload {
  email: string;
  heslo: string | null;
  ico: string;
  jeHlavnaKarta: boolean;
  login: string | null;
  meno: string;
  mobil: string | null;
  pevnaLinka: string | null;
  priezvisko: string;
  stav: 'E' | 'P' | 'O' | 'U';
  titulPred: string | null;
  titulZa: string | null;
  typAutentifikacie: 'J' | 'V' | string;
  udajeAutentifikacie: {
    idOsoby: number | null;
    idStatutar: number | null;
    kategoriaRole: string | null;
    kodLekar: string | null;
    nazovRole: string | null;
    typRole: 'S' | 'L' | string;
  }[];
  vytvorNovyPristup: boolean;
}

export interface UlozAutentifikaciueResponse {
  chyba: string;
  kontaktHK: string;
  stavSpracovania: boolean;
}

export interface DajSekciePristupuPayload {
  idPouzivatel: number;
  idVZ: number;
  zoznamRoli: string[];
}

export interface DajSekciePristupuResponse {
  sekciePristupu: SekciaPristupu[];
}

export interface ZmenUdajeAutentifikaciePayload {
  dovodZamietnutia: string | null;
  email: string;
  idPouzivatel: number;
  ids: number[];
  jeAktivnyPristup: boolean;
  kodLekar: string | null;
  pocetOdmietnuti: number;
  sekcie: SekciaPristupu[];
  stav: string;
  stavPouzivatela: boolean;
  zmenilPouzivatel: number;
}

export interface ZmenUdajeAutentifikacieResponse {
  chyba: string | null;
  stavSpracovania: boolean;
}

export interface DajDataWidgetuNaRieseniePayload {
  idHz: number;
  idPouzivatel: number;
  idVZ: number;
  isAsoc: boolean;
}

export interface UlozOdmietnutieAutentifikaciePayload {
  idPouzivatel: number;
}

export interface UlozOdmietnutieAutentifikacieResponse {
  chyba: string;
  pocetOdmietnutiAutentifikacie: number;
}

export interface OverDostupnostAutentifikaciePayload {
  ico: string;
  idPouzivatel: number;
  idVZ: number;
}

export interface OverDostupnostAutentifikacieResponse {
  chyba: string;
  pocetOdmietnutiAutentifikacie: number;
  pocetVolnychLekarov: number;
  pocetVolnychStatutarov: number;
  vyzadovatAutentifikaciu: boolean;
}

/* eslint-disable */
export enum UvazokTyp {
  D = 'Dohoda',
  E = 'Evidenčný úväzok',
  I = 'Iný',
  U = 'Úväzok',
}
/* eslint-enable */

export enum SystemoveParametreNazovConfigu {
  cakacky = 'CakacieListinyConfig',
  eRecept = 'EreceptConfig',
  nvarhy = 'NavrhyConfig',
  zuctovanie = 'ZuctovanieZSConfig',
}
export interface DajSystemoveParametrePayload {
  nazovSekcie: SystemoveParametreNazovConfigu;
}
export interface DajSystemoveParametreResponse {
  systemoveParametre: {
    hodnota: string;
    nazov: string;
  }[];
}

export interface DajOdbornostiQueries {
  lekarId?: number;
}

export interface DajOdbornostiResponse {
  odbornosti: OdbornostHodnoteneho[];
}
