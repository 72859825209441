import { ReactNode } from 'react';
import useStyles from './GridTable.styles';

interface Props {
  children: ReactNode;
}

const GridRow = ({ children }: Props) => {
  const classes = useStyles({ spaceLeft: null, spaceRight: null });
  return <tr className={classes.tr}>{children}</tr>;
};

export default GridRow;
