import { BreadcrumbItem } from '../components/Breadcrumbs/types';
import routes from '../routes';
import parameters from './strings/parameters';

const texts = parameters;

export const breadcrumbItemsDashboard = [
  {
    name: texts.parametersOverview,
  },
];

export const breadcrumbItemsDetail = (
  currentDetailName: string,
): BreadcrumbItem[] => [
  {
    name: texts.parametersOverview,
    route: routes.parameters,
  },
  {
    name: currentDetailName,
  },
];

export const breadcrumbItemsSelection = [
  {
    name: texts.parametersOverview,
  },
  {
    name: texts.parametersSelection,
  },
];

export const exchangeableParams: string[] = [
  'podiel-fix-hyp',
  'vakc-hpv',
  'farm-sz',
  'prev-gyn-cyt',
  'alerg-imt',
  'std-nefro',
  'liecba-po-cmp',
  'vys-deti',
  'vys-novi',
  'atb-orl',
  'tele',
  'polyp',
];

export const paramsForNewGraph: string[] = [
  ...exchangeableParams,
  ...['bolest-chrb'],
];
