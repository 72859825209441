import { ParameterDetail } from '../../../../types/parameters.types';
import CMSText from '../../../../components/CMSText/CMSText';
import Graph from './Graph';
import { Formula } from '../../../../components/FormulaVisualization';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import { Parametrization } from '../../../../components/JsonParametrization';

interface Props {
  data: ParameterDetail[];
}

const Content = ({ data }: Props) => (
  <>
    {data?.map((item) => {
      switch (item.type) {
        case 'text':
          return item.attributes.text;
        case 'cms':
          return (
            <CMSText
              params={item.attributes.params}
              path={item.attributes.id}
            />
          );
        case 'graph':
          return <Graph attributes={item.attributes} />;
        case 'formula':
          return <Formula attributes={item.attributes} />;
        case 'html':
          return <SafeHtml html={item.attributes.text} wrapper="div" />;
        case 'value':
          return item.attributes.value;
        case 'structure':
          return <Parametrization structure={item} />;
        default:
          return null;
      }
    })}
    <div className="mb-large" />
  </>
);

export default Content;
