interface Props {
  color?: string;
  id: string;
}

const IconMenuDashboard = ({ color, id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 23 24"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M2 9L11 2L20 9V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V9Z"
        stroke={color || '#BED2F4'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M8 22V12H14V22"
        stroke={color || '#BED2F4'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default IconMenuDashboard;
