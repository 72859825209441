import { LoadingState } from '.';

export type RatingOption = {
  checked: boolean;
  index: number;
  key: string;
  value: string;
};

/* eslint-disable */
export enum RATING_BAD_OPTIONS {
  NarocnaOrientacia = 'Náročná orientácia na stránke',
  Parametre = 'Nesprávne vyhodnotené parametre',
  Text = 'Zložitý text',
  Ine = 'Iné',
}
export enum RATING_GOOD_OPTIONS {
  Prehladnost = 'Prehľadná stránka',
  Informacie = 'Prínosné informácie',
  ESluzby = 'Jednoduché elektronické služby',
  Ine = 'Iné',
}

export enum RATING_TRIGGER_OPTIONS {
  Zmluvy = 'Zmluvy a dodatky',
  Parametre = 'Hodnotiace parametre',
  Navrhy = 'Návrhy',
  Pacienti = 'Pacienti',
  Diagnozy = 'Prehľad diagnóz',
  Spravy = 'Správy',
  Zuctovanie = 'Zúčtovanie dávok',
  Listiny = 'Čakacie listiny',
  Pcp = 'Prehľad cenových podmienok',
  Ine = 'Iné',
}
/* eslint-enable */

export type FeedbackState = {
  dataState: LoadingState;
  error: string | null;
  open: boolean;
  rating: number;
  ratingOptions: RatingOption[];
  text: string;
  trigger: {
    delay: number;
    show: boolean;
    url: string;
  };
};
