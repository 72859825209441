import { createContext, useMemo } from 'react';
import { AuthService } from '../services/AuthService';
import { User, UserManager } from 'oidc-client-dzp';

interface AuthContextType {
  getUser: () => Promise<User | null>;
  getUserManager: () => UserManager;
  login: () => Promise<void>;
  logout: (url?: string) => Promise<void>;
  renewToken: () => Promise<User>;
  revokeToken: () => Promise<void>;
}

const defaultAuthContext: AuthContextType = {
  // @ts-ignore
  getUserManager: () => null,
  getUser: async () => null,
  login: async () => {},
  logout: async () => {},
  // @ts-ignore
  renewToken: async () => new User({}),
  revokeToken: async () => {},
};

export const AuthContext = createContext(defaultAuthContext);

export function AuthProvider({ children }) {
  const authService = useMemo(() => new AuthService(), []);

  return (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
  );
}
