import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getCurrentPrices,
  getExchangeParameters,
  getParameterDetail,
  getParameters,
  getPeriodsForExpertise,
  saveSelectedParameters,
} from '../api/parameters';
import { LoadingState } from '../types';
import {
  ExpertisePeriod,
  GetActualPricesResponse,
  GetExchangeParametersResponse,
  GetExperisePeriods,
  GetParameterDetailResponse,
  GetParametersResponse,
  ParametersState,
  SaveSelectedParametersResponse,
  SelectedParameters,
} from '../types/parameters.types';
import { arraySort } from '../utils/array.utils';
import { updateDataForExchange } from '../utils/parameters.utils';

const initialState: ParametersState = {
  currentHpPrices: {
    data: null,
    dataState: LoadingState.none,
  },
  evaluationParameters: {
    data: null,
    dataState: LoadingState.none,
  },
  exchangeParameters: {
    data: null,
    dataState: LoadingState.none,
    isStatic: true,
    save: {
      dataState: LoadingState.none,
      isSuccess: null,
    },
  },
  menuParameters: {
    groups: [],
  },
  parameterDetailOld: {
    data: null,
    dataState: LoadingState.none,
  },
  parameterDetail: {
    data: null,
    dataState: LoadingState.none,
  },
  periodsForExpertise: {
    currentPeriod: null,
    data: null,
    dataState: LoadingState.none,
  },
  selectedPeriod: null,
};

const parametersSlice = createSlice({
  name: 'parameters',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentPrices.pending, (state) => {
        state.currentHpPrices.dataState = LoadingState.fetching;
      })
      .addCase(
        getCurrentPrices.fulfilled,
        (state, action: PayloadAction<GetActualPricesResponse>) => {
          state.currentHpPrices.data = action.payload;
          state.currentHpPrices.dataState = LoadingState.filled;
        },
      )
      .addCase(getCurrentPrices.rejected, (state) => {
        state.currentHpPrices.dataState = LoadingState.error;
      })
      .addCase(getPeriodsForExpertise.pending, (state) => {
        state.periodsForExpertise = {
          ...initialState.periodsForExpertise,
          dataState: LoadingState.fetching,
        };
      })
      .addCase(
        getPeriodsForExpertise.fulfilled,
        (state, action: PayloadAction<GetExperisePeriods>) => {
          state.periodsForExpertise.data =
            arraySort(action.payload?.periods, 'order', 'desc') || null;
          state.periodsForExpertise.dataState = LoadingState.filled;
          state.selectedPeriod = state.periodsForExpertise.data?.[0] || null;
        },
      )
      .addCase(getPeriodsForExpertise.rejected, (state) => {
        state.periodsForExpertise.dataState = LoadingState.error;
      })
      .addCase(getParameters.pending, (state) => {
        state.evaluationParameters.dataState = LoadingState.fetching;
      })
      .addCase(
        getParameters.fulfilled,
        (state, action: PayloadAction<GetParametersResponse>) => {
          state.evaluationParameters.data = {
            ...action.payload,
            evaluationDetail: arraySort(
              action.payload.evaluationDetail,
              'order',
            ),
            parameterGroups: arraySort(action.payload.parameterGroups, 'order')
              .filter((g) => !!g.parameters?.length)
              .map((group) => ({
                ...group,
                parameters: arraySort(group.parameters, 'order'),
              })),
          };
          state.evaluationParameters.dataState = LoadingState.filled;
          state.menuParameters.groups = arraySort(
            action.payload.parameterGroups,
            'order',
          ).map((g) => ({
            code: g.groupType,
            parameters: arraySort(
              g.parameters.filter((p) => p.visibleInMenu),
              'order',
            ).map((p) => ({
              code: p.cardContent.parameterCode,
              name: p.cardContent.name,
            })),
          }));
        },
      )
      .addCase(getParameters.rejected, (state) => {
        state.evaluationParameters.dataState = LoadingState.error;
      })
      .addCase(getExchangeParameters.pending, (state) => {
        state.exchangeParameters.dataState = LoadingState.fetching;
      })
      .addCase(
        getExchangeParameters.fulfilled,
        (state, action: PayloadAction<GetExchangeParametersResponse>) => {
          state.exchangeParameters.data = {
            ...action.payload,
            parameterGroups: arraySort(
              action.payload.parameterGroups,
              'order',
            ).map((pg) => ({
              ...pg,
              parameters: arraySort(
                pg.parameters.map((p) => ({
                  ...p,
                  dataForExchange: p.dataForExchange
                    ? {
                        ...p.dataForExchange,
                        selected:
                          !action.payload.hasSelected ||
                          (p.dataForExchange?.exchangeType === 'RN' &&
                            !pg.parameters.some(
                              (par) =>
                                par.dataForExchange?.exchangeGroupId ===
                                  p.dataForExchange?.exchangeGroupId &&
                                par.dataForExchange?.selected,
                            ))
                            ? p.dataForExchange.default
                            : p.dataForExchange.selected,
                      }
                    : p.dataForExchange,
                })),
                'order',
              ),
            })),
          };
          state.exchangeParameters.dataState = LoadingState.filled;
          state.exchangeParameters.isStatic = action.payload.hasSelected;
        },
      )
      .addCase(getExchangeParameters.rejected, (state) => {
        state.exchangeParameters.dataState = LoadingState.error;
      })
      .addCase(getParameterDetail.pending, (state) => {
        state.parameterDetail.dataState = LoadingState.fetching;
      })
      .addCase(
        getParameterDetail.fulfilled,
        (state, action: PayloadAction<GetParameterDetailResponse>) => {
          state.parameterDetail = {
            ...action.payload,
            data: {
              detail: arraySort(action.payload.detail, 'order'),
            },
            dataState: LoadingState.filled,
          };
        },
      )
      .addCase(getParameterDetail.rejected, (state) => {
        state.parameterDetail.dataState = LoadingState.error;
      })
      .addCase(saveSelectedParameters.pending, (state) => {
        state.exchangeParameters.save.dataState = LoadingState.fetching;
      })
      .addCase(
        saveSelectedParameters.fulfilled,
        (state, action: PayloadAction<SaveSelectedParametersResponse>) => {
          state.exchangeParameters.save.dataState = LoadingState.filled;
          state.exchangeParameters.save.isSuccess = !!action.payload.result;
        },
      )
      .addCase(saveSelectedParameters.rejected, (state) => {
        state.exchangeParameters.save.dataState = LoadingState.error;
      });
  },
  reducers: {
    resetParameters(
      state,
      action: PayloadAction<{
        section?: 'dashboard' | 'selection' | 'detail';
      }>,
    ) {
      if (action?.payload?.section === 'dashboard')
        return {
          ...state,
          evaluationParameters: initialState.evaluationParameters,
          exchangeParameters: initialState.exchangeParameters,
        };
      if (action?.payload?.section === 'selection')
        return {
          ...state,
          exchangeParameters: initialState.exchangeParameters,
        };
      if (action?.payload?.section === 'detail')
        return {
          ...state,
          parameterDetail: initialState.parameterDetail,
        };
      return initialState;
    },
    changeSelectedPeriod(
      state,
      action: PayloadAction<ExpertisePeriod | undefined>,
    ) {
      if (action.payload) state.selectedPeriod = action.payload;
    },
    changeParameterSelection(state, action: PayloadAction<SelectedParameters>) {
      const { group, groupId, isRadio, isSelected, parameterId } =
        action.payload;

      if (!state.exchangeParameters.data?.parameterGroups?.length) {
        return state;
      }

      if (!isRadio) {
        return {
          ...state,
          exchangeParameters: {
            ...state.exchangeParameters,
            data: {
              ...state.exchangeParameters.data,
              parameterGroups:
                state.exchangeParameters.data.parameterGroups.map((g) => ({
                  ...g,
                  parameters: g.parameters.map((p) => ({
                    ...p,
                    dataForExchange:
                      p.dataForExchange?.exchangeGroupId === groupId &&
                      p.parameterId === parameterId &&
                      p.dataForExchange
                        ? { ...p.dataForExchange, selected: isSelected }
                        : p.dataForExchange,
                  })),
                })),
            },
          },
        };
      }

      if (!isSelected) return state;

      return {
        ...state,
        exchangeParameters: {
          ...state.exchangeParameters,
          data: {
            ...state.exchangeParameters.data,
            parameterGroups: state.exchangeParameters.data.parameterGroups.map(
              (g) => ({
                ...g,
                parameters: g.parameters.map((p) => ({
                  ...p,
                  dataForExchange: updateDataForExchange(
                    p,
                    groupId,
                    parameterId,
                    isSelected,
                    g.groupType,
                    group,
                  ),
                })),
              }),
            ),
          },
        },
      };
    },
    changeParameterRadioGroupSelection(
      state,
      action: PayloadAction<{
        group: string;
        groupId: number;
        remove?: boolean;
      }>,
    ) {
      const { group, groupId, remove } = action.payload;
      if (state.exchangeParameters.data?.parameterGroups?.length)
        state.exchangeParameters.data.parameterGroups =
          state.exchangeParameters.data.parameterGroups.map((g) => ({
            ...g,
            parameters: g.parameters.map((p) => ({
              ...p,
              dataForExchange: p.dataForExchange
                ? {
                    ...p.dataForExchange,
                    selected:
                      p.dataForExchange.exchangeGroupId === groupId &&
                      g.groupType === group
                        ? !remove && !p.dataForExchange?.selected
                        : !!p.dataForExchange?.selected,
                  }
                : p.dataForExchange,
            })),
          }));
    },
    changeStaticExchangeParameters(state) {
      state.exchangeParameters.isStatic = !state.exchangeParameters.isStatic;
    },
  },
});

export const {
  changeParameterRadioGroupSelection,
  changeParameterSelection,
  changeSelectedPeriod,
  changeStaticExchangeParameters,
  resetParameters,
} = parametersSlice.actions;

export default parametersSlice.reducer;
