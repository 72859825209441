import { createUseStyles } from 'react-jss';

export default createUseStyles({
  structureWrapper: {
    '&.horizontal': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      margin: 'auto',
      gap: 8,
      '& h2, & h4': {
        marginBottom: `0 !important`, // fix this in data
      },
      '& svg': {
        display: 'flex',
        marginTop: 0,
      },
    },
  },
});
