import { Button, ButtonLayout } from '@dovera/design-system';

interface Props {
  isRadio: boolean;
  isSelected: boolean;
  onCancel: () => void;
  onClick: () => void;
  shouldDisplayButtons: boolean;
  shouldDisplayCancelBtn: boolean;
  texts: {
    btnCancel: string;
    btnSelect: string;
    btnUnselect: string;
  };
}

const Footer = ({
  isRadio,
  isSelected,
  onCancel,
  onClick,
  shouldDisplayButtons,
  shouldDisplayCancelBtn,
  texts,
}: Props) => {
  const renderButton = isSelected ? (
    !isRadio && (
      <Button className="no-mrg-bottom" onClick={onClick} type="destructive">
        {texts?.btnUnselect}
      </Button>
    )
  ) : (
    <Button className="no-mrg-bottom" onClick={onClick}>
      {texts?.btnSelect}
    </Button>
  );

  return (
    <ButtonLayout className="align-items-center" direction="horizontal">
      {shouldDisplayButtons && renderButton}

      {shouldDisplayCancelBtn && (
        <Button className="no-mrg-bottom" onClick={onCancel} type="secondary">
          {texts?.btnCancel}
        </Button>
      )}
    </ButtonLayout>
  );
};

export default Footer;
