import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonSecondary,
  Checkbox,
  CheckboxGroup,
  Grid,
  GridCol,
  Input,
  Loader,
  Notification,
} from '@dovera/design-system';
import { useField, useForm } from 'react-final-form-hooks';
import { RoleType, SekciaPristupu } from '../../../../types/poskytovatel.types';
import useStyles from './Form.styles';
import { dlekSections } from '../../../../constants/systemConstants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { validationMsg } from '../../../../utils/form.utils';
import { VALIDATION } from '../../../../types/validation.types';
import regex from '../../../../constants/regex';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import strings from '../../../../constants/strings';

const validateForm = (values: { [x: string]: any }) => {
  const errors: any = {};
  if (!values.email) errors.email = validationMsg(VALIDATION.RequiredEmpty);
  if (values.email) {
    // check if email field is correctly filled
    if (!regex.EMAIL.test(values.email))
      errors.email = validationMsg(VALIDATION.EmailIncorrect);
  }
  return errors;
};

const localPositions: string[] = ['L', 'S'];

const texts = strings.auth.page.approvalAccess;

interface Props {
  defaultPosition?: string;
  email: string;
  mobile: string;
  onDisapproval: () => void;
  onSubmitForm: (values: any, activeSections: string[]) => void;
  phone: string;
  positions: string[];
  sections: {
    data: SekciaPristupu[];
    isLoading: boolean;
  };
}

const Form = ({
  defaultPosition,
  email,
  mobile,
  onDisapproval,
  onSubmitForm,
  phone,
  positions,
  sections,
}: Props) => {
  const classes = useStyles();
  const [selectedSections, setSelectedSections] = useState(
    sections.data.filter((d) => d.zapnuta).map((d) => d.kodSekcie),
  );
  const {
    authData,
    stepper: { stepResult },
  } = useSelector((state: RootState) => state.pzsAuth);
  useEffect(() => {
    if (sections.data)
      setSelectedSections(
        sections.data.filter((d) => d.zapnuta).map((d) => d.kodSekcie),
      );
  }, [sections.data]);
  const { form, handleSubmit } = useForm({
    onSubmit: (values: any) => onSubmitForm(values, selectedSections),
    initialValues: {
      email,
      phone,
      mobile,
    },
    validate: (valuesToValid) => validateForm(valuesToValid),
  });
  const emailForm = useField('email', form);
  const selectSectionCallback = useCallback(
    (section) => {
      setSelectedSections(
        selectedSections.some((s) => s === section)
          ? selectedSections.filter((s) => s !== section)
          : [...selectedSections, section],
      );
    },
    [selectedSections],
  );
  const renderInputs = (
    <Grid>
      <GridCol size={{ l: 4, m: 12 }}>
        <Input
          {...emailForm.input}
          crossOrigin
          error={
            emailForm.meta.touched &&
            emailForm.meta.error && (
              <SafeHtml html={emailForm.meta.error} wrapper="span" />
            )
          }
          id="auth--email"
          isRequired
          label="E-mail"
          role="textbox"
        />
      </GridCol>
    </Grid>
  );
  const renderPositions = (
    <div>
      <h5>Pozícia</h5>
      <CheckboxGroup className={classes.checkboxGroup}>
        <>
          {localPositions.map((p) => (
            <Checkbox
              key={`auth--position-checkbox-${p}`}
              data-checked={positions.some((pos) => pos === p)}
              id={`auth--position-checkbox-${p}`}
              isDisabled
              // eslint-disable-next-line
              isChecked={positions.some((pos) => pos === p)}
              role="checkbox"
              value={p}
            >
              {RoleType[p]}
            </Checkbox>
          ))}
        </>
        {defaultPosition ? (
          <Checkbox
            key={`auth--position-checkbox-I`}
            data-checked="true"
            id={`auth--position-checkbox-I`}
            isDisabled
            // eslint-disable-next-line
            isChecked
            value="I"
          >
            {`${RoleType.I} (${defaultPosition})`}
          </Checkbox>
        ) : (
          <span />
        )}
      </CheckboxGroup>
    </div>
  );
  const renderSectionsEP = (
    <div>
      <h5>{texts.subtitles.sectionsEP}</h5>
      <CheckboxGroup>
        {sections.data
          .filter(
            (s) =>
              !dlekSections.includes(s.kodSekcie) &&
              /** Nezobrazujeme sekcie "Vseobecna funkcionalita" a "Vseobecna funkcionalita - rozsirena" */
              !['PZSP', 'PZSR'].includes(s.kodSekcie),
          )
          .map(
            (s) =>
              dlekSections.filter((d) => d !== s.kodSekcie) && (
                <Checkbox
                  key={`auth--position-sectionEP-checkbox-${s.kodSekcie}`}
                  className="mb"
                  data-checked={selectedSections.some((e) => e === s.kodSekcie)}
                  id={`auth--position-sectionEP-checkbox-${s.kodSekcie}`}
                  isChecked={selectedSections.some((e) => e === s.kodSekcie)}
                  onChange={() => selectSectionCallback(s.kodSekcie)}
                  value={s.kodSekcie}
                >
                  {s.nazovSekcie}
                </Checkbox>
              ),
          )}
      </CheckboxGroup>
    </div>
  );
  const renderSectionsDLek = (
    <div>
      <h5>{texts.subtitles.sectionsDLek}</h5>
      <CheckboxGroup>
        {sections.data
          .filter((s) => dlekSections.includes(s.kodSekcie))
          .map((s) => (
            <Checkbox
              key={`auth--position-sectionDLek-checkbox-${s.kodSekcie}`}
              className="mb"
              data-checked={selectedSections.some((e) => e === s.kodSekcie)}
              id={`auth--position-sectionDLek-checkbox-${s.kodSekcie}`}
              isDisabled={s.kodSekcie === 'STAT' || s.kodSekcie === 'ZMLUVY'}
              // eslint-disable-next-line
              isChecked={selectedSections.some((e) => e === s.kodSekcie)}
              onChange={() => selectSectionCallback(s.kodSekcie)}
              value={s.kodSekcie}
            >
              {s.nazovSekcie}
            </Checkbox>
          ))}
      </CheckboxGroup>
    </div>
  );
  const renderButtons = (
    <div className={classes.buttons}>
      <Button
        isDisabled={stepResult.isLoading}
        isLoading={stepResult.isLoading}
        submit
      >
        Schváliť prístup
      </Button>
      <ButtonSecondary onClick={onDisapproval}>Neschváliť</ButtonSecondary>
    </div>
  );
  const renderNotification = (
    <div className={classes.notification}>
      <Notification message={texts.notifications.info} variant="info" />
    </div>
  );
  return (
    <form
      data-testid="auth--approval-form"
      id="auth--approval-form"
      onSubmit={handleSubmit}
    >
      {renderInputs}
      {renderPositions}
      {authData.data?.some((d) => d.typRole === 'S') ? (
        <p>
          <b>Funkcia štatutára: </b>
          {authData.data?.filter((d) => d.typRole === 'S')[0].kategoriaRole}
        </p>
      ) : (
        ''
      )}
      {!sections.isLoading ? (
        <Grid>
          <GridCol size={{ l: 5, m: 12, xl: 5 }}>{renderSectionsEP}</GridCol>
          <GridCol offset={{ l: 1, xl: 0 }} size={{ l: 5, m: 12, xl: 5 }}>
            {renderSectionsDLek}
          </GridCol>
        </Grid>
      ) : (
        <div className="mb">
          <Loader size={22} />
        </div>
      )}
      {renderNotification}
      {renderButtons}
    </form>
  );
};

export default Form;
