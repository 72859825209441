import { createUseStyles } from 'react-jss';
import { color } from '@dovera/design-system';

export default createUseStyles({
  root: {
    color: color('warning'),
    display: 'flex',
    '& svg': {
      marginRight: 8,
    },
  },
});
