import React, { useEffect } from 'react';
import { FieldReturnType, FieldType } from '../types';
import { useQuery } from '../../../hooks/useQuery';
import { FILTER_PREFIX } from '../constants';

type Props = {
  fields: FieldType[];
  onInit: (data: FieldReturnType) => void;
} & React.JSX.IntrinsicElements['div'];

/**
 * It wraps Filter components which indicates API call after change
 */
const FilterAPIWrapper = ({ fields, onInit, ...props }: Props) => {
  const query = useQuery();
  useEffect(() => {
    const queryData: FieldReturnType = fields.reduce((obj, field) => {
      const queryValue = query.get(`${FILTER_PREFIX}_${field.id}`) || '';
      obj[field.name] = queryValue;
      return obj;
    }, {});
    onInit(queryData);
    // eslint-disable-next-line
  }, []);
  return props.children;
};

export default FilterAPIWrapper;
