import { createUseStyles } from 'react-jss';
import { color } from '../../../constants';
import { breakpoints, getRem } from '@dovera/design-system';

export default createUseStyles({
  authBanner: {
    display: 'flex',
    alignItems: 'flex-end',
    '& img': {
      height: 275,
      objectFit: 'cover',
      borderBottomLeftRadius: 5,
    },
    '& > div': {
      padding: '48px 32px 48px 50px',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
      '& ul': {
        marginBottom: 0,
      },
    },
    [`@media screen and (max-width: 1400px)`]: {
      '& img': {
        height: 245,
      },
    },
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      '& img': {
        display: 'none',
      },
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      '& > div': {
        padding: '12px 15px 24px',
        '& h2': {
          fontSize: 26,
          maxWidth: '85%',
        },
      },
    },
    '& ul': {
      padding: '0 0 0 28px',
      '& li': {
        listStyle: 'none',
        listStyleImage: 'url(/icons/check.svg)',
        paddingLeft: 7,
        '&.icon-security': {
          listStyleImage: 'url(/icons/shield.svg)',
        },
        '&.icon-user': {
          listStyleImage: 'url(/icons/user-check.svg)',
        },
        '&.icon-edit': {
          listStyleImage: 'url(/icons/edit.svg)',
        },
        '&.icon-hearth': {
          listStyleImage: 'url(/icons/health.svg)',
        },
        '&.icon-sign': {
          listStyleImage: 'url(/icons/sign.svg)',
        },
        '&.icon-statistics': {
          listStyleImage: 'url(/icons/statistics.svg)',
        },
        '&.icon-key': {
          listStyleImage: 'url(/icons/key.svg)',
        },
      },
    },
    '& .auth-banner--extra-content ul': {
      marginTop: getRem(16),
    },
  },
  button: {
    '& svg': {
      marginLeft: 14,
    },
    '&.--angle': {
      '& svg': {
        transform: 'rotate(90deg)',
        verticalAlign: 'middle',
        marginTop: -5,
      },
    },
  },
  dashboardBanner: {
    display: 'flex',
    alignItems: 'flex-end',
    '& img': {
      height: 275,
      objectFit: 'cover',
    },
    '& > div': {
      padding: '48px 32px 48px 50px',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
      '& ul': {
        marginBottom: 0,
      },
    },
    [`@media screen and (max-width: 1400px)`]: {
      '& img': {
        height: 245,
      },
    },
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      '& img': {
        display: 'none',
      },
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      '& > div': {
        padding: '12px 15px 24px',
        '& h2': {
          fontSize: 26,
          maxWidth: '85%',
        },
      },
    },
  },
  close: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: 'absolute',
    top: 24,
    right: 24,
    borderRadius: 100,
    width: 32,
    height: 32,
    '&:not(.--withoutHover)': {
      '&:hover': {
        backgroundColor: color('primary', 100),
      },
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      top: 12,
      right: 12,
    },
  },
  card: {
    padding: '32px 28px',
    width: '100%',
    zIndex: 1,
    position: 'relative',
    borderRadius: 8,
    backgroundColor: '#E8F2FE',
    '& > div': {
      fontSize: 18,
    },
    '& img': {
      position: 'absolute',
      bottom: 0,
      right: 15,
      zIndex: -1,
      height: 125,
      [`@media screen and (max-width: 1450px)`]: {
        right: 0,
      },
      [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
        display: 'none',
      },
    },
  },
  authModal: {
    width: 1171,
    backgroundColor: '#E8F2FE',
    '& .modal__content': {
      padding: 0,
    },
    '& .modal__header': {
      padding: 0,
      '& .modal__close-button': {
        position: 'absolute',
        right: 24,
        top: 24,
        zIndex: 5,
      },
    },
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      width: 900,
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      width: 700,
    },
  },
  inputWrapper: {
    maxWidth: 388,
  },
  modal: {
    width: 900,
    '& .modal__header h2': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  form: {
    '& .choices__item': {
      '&[data-value=""]': {
        display: 'none',
      },
    },
  },
  personForm: {
    '& label': {
      '& > span:first-child': {
        marginRight: 12,
      },
      '& svg': {
        verticalAlign: 'middle',
        marginTop: -5,
      },
    },
    '& div.notification': {
      marginTop: getRem(12),
      marginBottom: 0,
    },
  },
  showMoreBtn: {
    padding: 0,
    marginLeft: getRem(34),
    marginTop: getRem(8),
    '& svg': {
      marginLeft: `${getRem(8)} !important`,
    },
  },
});
