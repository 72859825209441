// @ts-ignore
// eslint-disable-next-line
import CobrowseIO from 'cobrowse-sdk-js';
// @ts-ignore
import { Consent } from '../components/Cobrowse/Consent';
import { SessionControl } from '../components/Cobrowse/Controls';
import strings from '../constants/strings';

const texts = strings.cobrowse;

CobrowseIO.confirmSession = function ConfirmSession() {
  return new Consent().show(texts.consentTitle, texts.consentText);
};

CobrowseIO.confirmRemoteControl = function ConfirmRemoteControl() {
  return new Consent().show(texts.remoteConsentTitle, texts.remoteConsentText);
};

const endButton = SessionControl(CobrowseIO);

CobrowseIO.showSessionControls = function ShowSessionControls() {
  document.body.appendChild(endButton);
};

CobrowseIO.hideSessionControls = function HideSessionControls() {
  if (endButton.parentNode) endButton.parentNode.removeChild(endButton);
};

// create a code a display it to the user using your own UI
// ONLY GENERATE CODE WHEN NEEDED. DO NOT GENERATE CODE ON PAGE LOAD.
export const getCode = () => CobrowseIO.createSessionCode();
