import { Container, Grid, GridCol } from '@dovera/design-system';
import useStyles from './FooterLite.styles';
import { useLocation } from 'react-router';
import routes from '../../routes';

const mapaStrankyHref = 'https://www.dovera.sk/sitemap.xml';
const osobneUdajeHref =
  'https://www.dovera.sk/o-nas/uradna-tabula/a321/informacie-o-spracuvani-osobnych-udajov';
export const kontaktHref = 'https://www.dovera.sk/lekarom';
const aktualityHref = 'https://www.dovera.sk/lekar/aktuality';

interface Props {
  visible: boolean;
}

const routesWithDividerIncludes = [
  `${routes.parameters}/`,
  routes.zmluvyDodatky,
];
const routesWithDividerSpecific = [routes.pp];

const FooterLite = ({ visible }: Props) => {
  const location = useLocation();
  const withDivider =
    location.pathname.includes(routesWithDividerIncludes[0]) ||
    location.pathname.includes(routesWithDividerIncludes[1]) ||
    routesWithDividerSpecific.some((r) => r === location.pathname);
  const classes = useStyles({ withDivider });
  const copyright = `© ${new Date().getFullYear()} Dôvera`;
  return (
    <div className={visible ? classes.footer : 'hide'}>
      <Container>
        <Grid className="no-mrg" style={{ width: '100%' }}>
          <GridCol
            className="no-pad-left"
            size={{
              s: 12,
              m: 6,
            }}
          >
            {copyright}
          </GridCol>
          <GridCol
            className="align-items-right--m text-right--m no-pad-right"
            size={{
              s: 12,
              m: 6,
            }}
          >
            <a
              className={classes.link}
              href={aktualityHref}
              rel="noopener noreferrer"
              target="_blank"
            >
              Aktuality
            </a>
            <a
              className={classes.link}
              href={kontaktHref}
              rel="noopener noreferrer"
              target="_blank"
            >
              Kontakt
            </a>
            <a
              className={classes.link}
              href={mapaStrankyHref}
              rel="noopener noreferrer"
              target="_blank"
            >
              Mapa stránky
            </a>
            <a
              className={classes.link}
              href={osobneUdajeHref}
              rel="noopener noreferrer"
              target="_blank"
            >
              Ochrana súkromia
            </a>
          </GridCol>
        </Grid>
      </Container>
    </div>
  );
};

export default FooterLite;
