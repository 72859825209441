import { useEffect, useMemo, useState } from 'react';
import useStyles from '../../Profil.styles';
import useStylesApp from '../../../../App.styles';
import { Icon } from '@dovera/design-system';
import strings from '../../../../constants/strings';
import {
  DATE_INTERNATIONAL,
  formatDate,
  getMoment,
} from '../../../../utils/date.utils';
import { useDebounce } from '../../../../hooks/useDebounce';
import { getDefaultSelectOption } from '../../../../utils/dds.utils';
import { cx } from '../../../../utils/exports';
import { InputPreloader } from '../../../../components/Preloader';
import {
  AutocompleteFilter,
  DatepickerFilter,
  SelectFilter,
} from '../../../../components/Filter';

const { labels } = strings.profile.overeniaPrehlady.filter.ambulances;

const contractualOptions = [
  getDefaultSelectOption('Všetky'),
  ...[
    {
      label: 'Áno',
      value: 'áno',
    },
    {
      label: 'Nie',
      value: 'nie',
    },
  ],
];

interface Props {
  ambulances: string[];
  expertises: {
    label: string;
    text: string;
    value: string;
  }[];
  /* eslint-disable */
  onChange: (
    ambulanceName: string,
    contractual: string,
    date: string,
    expertise: string,
  ) => void;
  /* eslint-enable */
}

const FilterAmbulances = ({ ambulances, expertises, onChange }: Props) => {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const [isLoaded, setIsLoaded] = useState(false);
  const [ambulanceName, setAmbulanceName] = useState('');
  const [expertise, setExpertise] = useState('');
  const [contractual, setContractual] = useState('áno');
  const [date, setDate] = useState(getMoment().format(DATE_INTERNATIONAL));
  const debouncedAmbulanceName = useDebounce(ambulanceName, 1000);
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, []);
  useEffect(() => {
    onChange(debouncedAmbulanceName, contractual, date, expertise);
    // eslint-disable-next-line
  }, [debouncedAmbulanceName, contractual, date, expertise]);
  const renderDoctorName = useMemo(
    () => (
      <AutocompleteFilter
        key={expertise + contractual + date}
        addonsInside
        id="ambulancie-vyhladavanie"
        label={labels.name}
        onChange={(value) => setAmbulanceName(value)}
        options={ambulances}
        rightAddons={
          <Icon id={`icon-filter-name-search`} name="search" size="medium" />
        }
        value={ambulanceName}
      />
    ),
    [ambulances, expertise, contractual, ambulanceName, date],
  );
  const renderExpertise = (
    <div className="ambulances-filter-expertise-wrapper">
      <SelectFilter
        id="ambulancie-odbornost"
        label={labels.expertise}
        onChange={(value) => setExpertise(value)}
        options={[getDefaultSelectOption('Všetky odbornosti'), ...expertises]}
        value={expertise}
      />
    </div>
  );
  const renderContractual = (
    <div className="ambulances-filter-contractual-wrapper">
      <SelectFilter
        id="ambulancie-zmluvna"
        label={labels.contractual}
        onChange={(value) => setContractual(value)}
        // @ts-ignore
        options={contractualOptions.map((c) => ({
          label: c.label,
          value: c.value,
          selected: c.value === contractual,
        }))}
      />
    </div>
  );
  const renderDate = (
    <div className="ambulances-filter-date-wrapper">
      <DatepickerFilter
        id="ambulancie-datum"
        label={labels.date}
        onChange={(e) => setDate(getMoment(e).format(DATE_INTERNATIONAL))}
        value={formatDate(date)}
      />
    </div>
  );
  if (!isLoaded)
    return (
      <div
        className={cx(classesApp.appForm, classesApp.filterWrapper, 'mb-large')}
      >
        <InputPreloader />
        <InputPreloader />
        <InputPreloader />
        <InputPreloader />
      </div>
    );
  return (
    <div className={classes.filter} data-testid="filter-ambulances--wrapper">
      {renderDoctorName}
      {renderExpertise}
      {renderContractual}
      {renderDate}
    </div>
  );
};

export default FilterAmbulances;
