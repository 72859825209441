import { createUseStyles } from 'react-jss';
import { breakpoints } from '@dovera/design-system';

export default createUseStyles({
  dashboard: {
    width: '100% !important',
    // paddingLeft: 24,
    // paddingRight: 24,
    paddingTop: 0,
    paddingBottom: '2rem',
    '& .card': {
      marginBottom: 0,
    },
    '& .card__section': {
      padding: 0,
      height: '100%',
      '& h3': {
        padding: '32px 32px 16px',
        margin: 0,
      },
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
  },
  contracts: {
    '& .card__section': {
      padding: 32,
      '& p': {
        marginTop: 4,
        marginLeft: 48,
        color: '#6D7175',
        fontSize: 16,
        fontWeight: 100,
      },
      '& a': {
        marginLeft: 48,
        '& svg': {
          verticalAlign: 'middle',
          marginTop: -3,
          marginLeft: 8,
        },
      },
    },
  },
  contactsInfo: {
    display: 'flex',
    alignItems: 'center',
    '& h6': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 0,
      marginLeft: 16,
    },
  },
  showAll: {
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
    color: '#6D7175',
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  sideCard: {
    padding: 32,
    '& hr': {
      margin: '21px 0',
    },
    '& a': {
      fontSize: 16,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 500,
      '&.text-center': {
        justifyContent: 'center',
      },
      '& svg': {
        marginLeft: 11,
      },
      '&:not(:last-child)': {
        marginBottom: 16,
      },
    },
    marginBottom: '25px !important',
  },
});
