import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const GridTable = ({ children, className }: Props) => (
  <table cellPadding="0" cellSpacing="0" className={className || ''}>
    <tbody>{children}</tbody>
  </table>
);

export default GridTable;
