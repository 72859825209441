import { ReactNode } from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import { cx } from '../../../utils/exports';
// eslint-disable-next-line no-unused-vars
import { StepperState } from '../Stepper';
import useStyles from './StepContent.styles';

interface Props extends StepperState {
  [x: string]: any;
  children: ReactNode;
  className?: string;
}

const StepContent = ({
  active,
  children,
  className: classNameProp,
  lastStep,
  ...rest
}: Props) => {
  const classes = useStyles({ active, lastStep });
  const className = cx(
    classes.root,
    classNameProp,
    active && classes.activeContent,
  );

  return (
    <div className={className} {...rest}>
      <SmoothCollapse eagerRender expanded={active !== false}>
        {children}
      </SmoothCollapse>
    </div>
  );
};

export default StepContent;
