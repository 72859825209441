import { ChangeEvent } from 'react';

export const createInputEvent = (
  name: string,
  value: string,
): ChangeEvent<HTMLTextAreaElement> =>
  ({
    target: {
      name,
      value,
    } as HTMLTextAreaElement,
  }) as ChangeEvent<HTMLTextAreaElement>;

export const createSelectEvent = (
  name: string,
  value: string,
): ChangeEvent<HTMLSelectElement> =>
  ({
    target: {
      name,
      value,
    } as HTMLSelectElement,
  }) as ChangeEvent<HTMLSelectElement>;
