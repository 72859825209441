import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { User } from 'oidc-client-dzp';
import { AppThunk } from '../store';
import { AUTH_KEY } from '../constants/misc';
import { getRefreshTokenInterval, getTokenState } from '../utils/auth.utils';
import { EPSection } from '../types';
import { jwtDecode } from 'jwt-decode';

export interface AuthState {
  // Hlavna karta
  guid: string;
  hasHPKEI: boolean;
  hasPZSNAKL: boolean;
  isASOC: boolean;
  isImpersonalization: boolean;
  isSTAT: boolean;
  isSUPR: boolean;
  isSubjectAccess: boolean;
  pouzivatelId: number;
  refreshTokenInterval: number;
  sekciaZmluvy: boolean;
  token: string | null;
  vztahId: number;
}

interface SinginState {
  idAsociacie: number;
  idPlatitela: number;
  impersonalizacia: number;
  pouzivatelId: number;
  rola: string;
  sekcie: string;
  token: string;
  vztahId: number;
}

interface AuthCookieState {
  pouzivatelId: number;
  token: string;
  vztahId: number;
}

export const initialState: AuthState = {
  isASOC: false,
  isSUPR: false,
  isSTAT: false,
  isImpersonalization: false,
  isSubjectAccess: false,
  hasHPKEI: false,
  hasPZSNAKL: false,
  guid: '',
  pouzivatelId: 0,
  refreshTokenInterval: 0,
  sekciaZmluvy: false,
  vztahId: 0,
  token: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInRedirect(state, action: PayloadAction<SinginState>) {
      const {
        idAsociacie,
        idPlatitela,
        impersonalizacia,
        pouzivatelId,
        token,
        vztahId,
      } = action.payload;
      const newState: AuthCookieState = {
        token,
        pouzivatelId,
        vztahId,
      };
      // @ts-ignore
      const sections = jwtDecode(token)?.dzp_user_role_section || '';
      setAuthStorage(newState.token);
      state.isASOC = idAsociacie !== 0;
      state.isImpersonalization = impersonalizacia === 1;
      state.pouzivatelId = pouzivatelId;
      state.isSubjectAccess = idPlatitela !== 0;
      state.vztahId = vztahId;
      state.token = token;
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      state.isSTAT = sections.includes('STAT');
      state.isSUPR = sections.includes('SUPR');
      state.hasHPKEI = sections.includes('PZSHPKEI');
      state.hasPZSNAKL = sections.includes('PZSNAKL');
      state.sekciaZmluvy = sections.includes('ZMLUVY');
      state.refreshTokenInterval = getRefreshTokenInterval(token);
    },
    getStateFromStorage(
      state,
      action: PayloadAction<{ token: string | null }>,
    ) {
      return {
        ...state,
        ...getTokenState(action.payload.token || '', state),
      };
    },
    setGuid(state, action: PayloadAction<string>) {
      state.guid = action.payload;
    },
    setSections(state, action: PayloadAction<{ sections: EPSection[] }>) {
      const { sections } = action.payload;
      return {
        ...state,
        isSTAT: sections.includes('STAT'),
        isSUPR: sections.includes('SUPR'),
        hasHPKEI: sections.includes('PZSHPKEI'),
        hasPZSNAKL: sections.includes('PZSNAKL'),
        sekciaZmluvy: sections.includes('ZMLUVY'),
      };
    },
    setRefreshTokenInterval(state) {
      return {
        ...state,
        refreshTokenInterval: getRefreshTokenInterval(),
      };
    },
  },
});

export const {
  getStateFromStorage,
  setGuid,
  setRefreshTokenInterval,
  setSections,
  signInRedirect,
} = authSlice.actions;
export default authSlice.reducer;

const auth = (state: AuthState) => state;

export const getPZS = createSelector([auth, () => {}], (auth) => ({
  userId: auth.pouzivatelId,
  vzId: auth.vztahId,
}));

export const signinRedirect =
  (res: User): AppThunk =>
  (dispatch) => {
    dispatch(
      signInRedirect({
        idAsociacie: Number(res.profile.dzp_association || ''),
        idPlatitela: Number(res.profile.dzp_payer_id || ''),
        impersonalizacia: Number(res.profile.dzp_impersonalization || ''),
        rola: res.profile.dzp_initial_role,
        sekcie: res.profile.dzp_user_role_section,
        pouzivatelId: Number(res.profile.dzp_user_id),
        token: res.access_token,
        vztahId: Number(res.profile.dzp_provider_id),
      }),
    );
  };

export const getAuthFromStorage = (): string | null => {
  const storageAuth = window.sessionStorage.getItem(AUTH_KEY);
  const authState = storageAuth || null;
  return authState;
};

export const setAuthStorage = (token: string) => {
  window.sessionStorage.setItem(AUTH_KEY, token);
};
