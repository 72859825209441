import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  horizontal: {
    flexDirection: 'row !important',
    textAlign: 'center',
    marginBottom: 12,
    justifyContent: 'space-between',
    position: 'relative',
    '& > div': {
      flex: 1,
      '& > div': {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
      },
      '&:not(:last-child)': {
        '& > div': {
          '&:after': {
            content: '""',
            backgroundColor: 'rgba(31, 87, 165, 0.3)',
            width: '100%',
            height: 2,
            position: 'absolute',
            left: '50%',
            marginTop: 24,
            zIndex: 0,
          },
        },
      },
    },
    '& h5, & svg': {
      display: 'inline-block',
      position: 'relative',
      zIndex: 1,
    },
    '& h5': {
      fontSize: '18px !important',
      lineHeight: '20px !important',
      fontWeight: 'bold',
      marginTop: 10,
    },
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
});
