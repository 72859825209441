import { LoadingState } from '.';
import { Dokument } from './models/Dokument';

export type MessageTabType = {
  count: number;
  label: string;
  value: string;
};

export const enum KategoriaSprav {
  Archiv = 'A',
  Notifikacie = 'N',
  Spravy = 'S',
  Vsetky = 'all',
  Vymazane = 'V',
}

export type KategoriaSpravType =
  | KategoriaSprav.Notifikacie
  | KategoriaSprav.Spravy
  | KategoriaSprav.Vsetky
  | KategoriaSprav.Vymazane;

/**
 * Nepovinne parametre su lokalne (povinne prichadzaju zo sluzby)
 * @date 21. 3. 2023 - 10:23:25
 *
 * @export
 * @typedef {Vlakno}
 */
export type Vlakno = {
  aktivne?: boolean;
  datumPoslednejSpravy: string;
  id: number;
  jeAutorPzs: boolean;
  moznostOdpovedat: boolean;
  nadpis: string;
  neprecitane: boolean;
  obsahPoslednejSpravy: string;
  oznacenePreVymazanie?: boolean;
  typBadge: string;
  typVlakna: KategoriaSprav.Notifikacie | KategoriaSprav.Vymazane | string;
  zdroj: string;
};

export type Sprava = {
  datumVytvorenia: string;
  id: number;
  idTema: number;
  jeAutorPzs: boolean;
  obsah: string;
  prilohy: Dokument[] | null;
};

type PodtemaSpravy = {
  dokumentUrl: string | null;
  id: number;
  nazov: string;
  podtypy?: PodtemaSpravy[];
  povinnostPrilohy: boolean;
};

export type TemaSpravy = {
  id: number;
  nazov: string;
  podtypy: PodtemaSpravy[];
};

export type FormRequest = {
  files: File[] | null;
  message: string;
  subtheme?: string;
  theme?: string;
  threadId?: number;
};

export interface DajPocetNeprecitanychVlakienResponse {
  pocetNepracitanychVlakien: number;
}

export interface DajZoznamVlakienQueries {
  limit?: number;
  offset?: number;
  typVlakna?: string;
  vyhladavanie?: string;
}

export interface DajZoznamVlakienResponse {
  pocetVlakien: {
    pocet: number;
    pocetNeprecitanych: number;
    typVlakna:
      | KategoriaSprav.Notifikacie
      | KategoriaSprav.Spravy
      | KategoriaSprav.Vymazane;
  }[];
  vlakna: Vlakno[];
}

export interface DajZoznamTemPZSResponse {
  typy: TemaSpravy[];
}

export interface DajZoznamSpravVlaknaPayload {
  id: number;
  scrollToBottom?: boolean;
}

export interface DajZoznamSpravVlaknaResponse {
  email?: string | null;
  jeMoznostOdpovedat: boolean;
  nadpis: string;
  spravy: Sprava[];
  typObmedzenia: string;
}

export interface UlozSpravuRequest {
  email?: string | null;
  obsahSpravy: string;
  prilohy: Dokument[] | null;
  temaId: number | null;
  vlaknoId: number;
}

export interface UlozSpravuResponse {
  chyba: string | null;
  error: {
    detail: string;
    kod: string;
    text: string;
  } | null;
  vysledok: boolean;
}

export interface ZmazVlaknoRequest {
  idVlakno: number[];
}

export interface ZmazVlaknoResponse {}

export interface SpravyState {
  detail: {
    dataState: LoadingState;
    emailVlakna?: string | null;
    id: number;
    idTema: number;
    jeMoznostOdpovedat: boolean;
    nadpis: string;
    scroll: 'top' | 'bottom' | null;
    spravy: Sprava[];
    typObmedzenia:
      | 'Sucet_sprav_odoslane'
      | 'Sucet_sprav_vsetky'
      | 'Automaticky_mail'
      | string
      | null;
    vymazane: boolean;
    zobrazit: boolean;
  };
  kategoria: KategoriaSpravType | string;
  neprecitaneVlakna: {
    dataState: LoadingState;
    neprecitaneVymazane: boolean;
    pocet: number;
  };
  refresh: boolean;
  temy: {
    data: TemaSpravy[];
    dataState: LoadingState;
  };
  toast: {
    obnovenie: boolean;
    odoslanie: boolean;
    vymazanie: boolean;
  };
  vlakna: {
    data: Vlakno[];
    dataState: LoadingState;
    firstId: number;
    offset: number;
    pocty: {
      [KategoriaSprav.Vsetky]: number;
      [KategoriaSprav.Spravy]: number;
      [KategoriaSprav.Notifikacie]: number;
      [KategoriaSprav.Vymazane]: number;
    };
    vyhladavanie: string;
    ziadneVlakna: boolean;
    zobrazNtf: boolean;
  };
}
