import { useEffect, useMemo, useState } from 'react';
import useStyles from '../../Profil.styles';
import useStylesApp from '../../../../App.styles';
import { Icon } from '@dovera/design-system';
import strings from '../../../../constants/strings';
import { useDebounce } from '../../../../hooks/useDebounce';
import { UvazokTyp } from '../../../../types/api/poskytovatel';
import { getDefaultSelectOption } from '../../../../utils/dds.utils';
import { InputPreloader } from '../../../../components/Preloader';
import { cx } from '../../../../utils/exports';
import {
  AutocompleteFilter,
  SelectFilter,
} from '../../../../components/Filter';

const { labels } = strings.profile.overeniaPrehlady.filter.doctors;

interface Props {
  doctors: string[];
  expertises: {
    label: string;
    value: string;
  }[];
  /* eslint-disable */
  onChange: (
    doctorName: string,
    expertise: string,
    workingTime: string,
  ) => void;
  /* eslint-enable */
}

const FilterDoctors = ({ doctors, expertises, onChange }: Props) => {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const [isLoaded, setIsLoaded] = useState(false);
  const [doctorName, setDoctorName] = useState('');
  const [expertise, setExpertise] = useState('');
  const [workingTime, setWorkingTime] = useState('');
  const debouncedDoctorName = useDebounce(doctorName, 1000);
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, []);
  useEffect(() => {
    onChange(debouncedDoctorName, expertise, workingTime);
    // eslint-disable-next-line
  }, [debouncedDoctorName, expertise, workingTime]);
  const renderDoctorName = useMemo(
    () => (
      <AutocompleteFilter
        key={expertise + workingTime}
        addonsInside
        id="lekari-vyhladavanie"
        isPrivate
        label={labels.name}
        onChange={(value) => setDoctorName(value)}
        options={doctors}
        rightAddons={
          <Icon id={`icon-filter-name-search`} name="search" size="medium" />
        }
        value={doctorName}
      />
    ),
    [doctors, expertise, workingTime, doctorName],
  );
  /** TODO, zmenit na Autocomplete, ked bude fixed za DDS */
  const renderExpertise = (
    <div className="doctors-filter-expertise-wrapper">
      <SelectFilter
        id="lekari-odbornost"
        label={labels.expertise}
        onChange={(value) => setExpertise(value)}
        options={[getDefaultSelectOption('Všetky odbornosti'), ...expertises]}
      />
    </div>
  );
  const renderWorkingTime = (
    <div className="doctors-filter-workingTime-wrapper">
      <SelectFilter
        id="lekari-uvazok"
        label={labels.workingTime}
        onChange={(value) => setWorkingTime(value)}
        options={[
          getDefaultSelectOption('Všetky'),
          {
            label: UvazokTyp.U,
            value: 'U',
          },
          {
            label: UvazokTyp.E,
            value: 'E',
          },
          {
            label: UvazokTyp.D,
            value: 'D',
          },
          {
            label: UvazokTyp.I,
            value: 'I',
          },
        ]}
      />
    </div>
  );
  if (!isLoaded)
    return (
      <div
        className={cx(classesApp.appForm, classesApp.filterWrapper, 'mb-large')}
      >
        <InputPreloader />
        <InputPreloader />
        <InputPreloader />
      </div>
    );
  return (
    <div className={classes.filter} data-testid="filter-doctors--wrapper">
      {renderDoctorName}
      {renderExpertise}
      {renderWorkingTime}
    </div>
  );
};

export default FilterDoctors;
