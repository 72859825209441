/* eslint-disable no-constant-condition */
import { Log, User, UserManager } from 'oidc-client-dzp';
import { logoutIDP } from '../api/auth';
import { navigateAfterLogout, removeToken } from '../utils/auth.utils';

export class AuthService {
  public userManager: UserManager;

  constructor() {
    const config = window?.env?.authConfig;

    this.userManager = new UserManager(config);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public getUserManager(): UserManager {
    return this.userManager;
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(url?: string): Promise<void> {
    return logoutIDP()
      .then(() => navigateAfterLogout(url))
      .catch(() => navigateAfterLogout())
      .finally(() => {
        setTimeout(() => {
          removeToken();
          this.userManager.removeUser();
        }, 100);
      });
  }

  public revokeToken(): Promise<void> {
    return this.userManager.revokeAccessToken();
  }
}
