interface Props {
  color?: string;
  id: string;
  isBold?: boolean;
}

const IconStethoscope = ({ color, id, isBold }: Props) => {
  if (isBold)
    return (
      <svg
        fill="none"
        height="16"
        id={id}
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M6.99992 2C6.99992 1.44772 7.44763 1 7.99992 1H9.33325C9.88554 1 10.3333 1.44772 10.3333 2C10.3333 2.55228 9.88554 3 9.33325 3H8.99992V4.66667C8.99992 5.95533 10.0446 7 11.3333 7C12.6219 7 13.6666 5.95533 13.6666 4.66667V3H13.3333C12.781 3 12.3333 2.55228 12.3333 2C12.3333 1.44772 12.781 1 13.3333 1H14.6666C15.2189 1 15.6666 1.44772 15.6666 2V4.66667C15.6666 6.71572 14.2444 8.4326 12.3333 8.88405V10C12.3333 11.1479 12.0491 12.3707 11.3523 13.3355C10.6276 14.3388 9.49617 15 7.99992 15C5.87204 15 4.51069 13.6946 4.05124 12.3162C3.87659 11.7923 4.15975 11.226 4.68369 11.0513C5.20763 10.8767 5.77395 11.1598 5.9486 11.6838C6.15581 12.3054 6.79446 13 7.99992 13C8.837 13 9.37222 12.6612 9.73091 12.1645C10.1174 11.6293 10.3333 10.8521 10.3333 10V8.88405C8.42212 8.4326 6.99992 6.71572 6.99992 4.66667V2ZM2.33325 8.33333C2.33325 7.96514 2.63173 7.66667 2.99992 7.66667C3.36811 7.66667 3.66658 7.96514 3.66658 8.33333C3.66658 8.70152 3.36811 9 2.99992 9C2.63173 9 2.33325 8.70152 2.33325 8.33333ZM2.99992 5.66667C1.52716 5.66667 0.333252 6.86057 0.333252 8.33333C0.333252 9.80609 1.52716 11 2.99992 11C4.47268 11 5.66658 9.80609 5.66658 8.33333C5.66658 6.86057 4.47268 5.66667 2.99992 5.66667Z"
          fill={color || '#1F57A5'}
          fillRule="evenodd"
        />
      </svg>
    );
  return (
    <svg
      fill="none"
      height="24"
      id={id}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4583 13.6445C12.5711 13.1675 10.369 10.6593 10.369 7.63691V2.2262H13.5515V4.2262H12.369V7.63691C12.369 9.89536 14.1999 11.7262 16.4583 11.7262C18.7168 11.7262 20.5476 9.89536 20.5476 7.63691V4.2262H19.3417V2.2262H22.5476V7.63691C22.5476 10.6593 20.3456 13.1675 17.4583 13.6445V15.8646C17.4583 19.2277 14.7321 21.9539 11.369 21.9539V19.9539L11.3702 21.9539L11.369 19.9539C13.6275 19.9539 15.4583 18.1231 15.4583 15.8646V13.6445ZM11.3702 21.9539H11.0298C8.99859 21.9539 7.21765 20.5812 6.37202 18.5133C6.16298 18.0021 6.40792 17.4182 6.91912 17.2092C7.43031 17.0001 8.01418 17.2451 8.22322 17.7562C8.77883 19.115 9.86728 19.9539 11.0298 19.9539H11.3702V21.9539ZM4.71429 16.4286C2.66294 16.4286 1 14.7656 1 12.7143C1 10.6629 2.66294 9.00001 4.71429 9.00001C6.76563 9.00001 8.42857 10.6629 8.42857 12.7143C8.42857 14.7656 6.76563 16.4286 4.71429 16.4286ZM4.71429 14.4286C5.66106 14.4286 6.42857 13.6611 6.42857 12.7143C6.42857 11.7675 5.66106 11 4.71429 11C3.76751 11 3 11.7675 3 12.7143C3 13.6611 3.76751 14.4286 4.71429 14.4286ZM4.67857 13.3571C4.30381 13.3571 4 13.0533 4 12.6786C4 12.3038 4.30381 12 4.67857 12C5.05334 12 5.35714 12.3038 5.35714 12.6786C5.35714 13.0533 5.05334 13.3571 4.67857 13.3571Z"
        fill={color || '#1F57A5'}
      />
    </svg>
  );
};

export default IconStethoscope;
