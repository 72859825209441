import { color } from '@dovera/design-system';
import IconEfectivityMenu from '../../CustomIcons/IconEfectivityMenu';
import IconInovationMenu from '../../CustomIcons/IconInovationMenu';
import IconQualityMenu from '../../CustomIcons/IconQualityMenu';
import { IconDashedRing, IconError, IconSuccess } from '../../CustomIcons';
import IconWarningCircle from '../../CustomIcons/IconWarningCircle';
import IconParameterQuality from '../../CustomIcons/IconParameterQuality';
import IconParameterEffectivity from '../../CustomIcons/IconParameterEffectivity';
import IconParameterInovations from '../../CustomIcons/IconParameterInovations';

interface Props {
  iconKey: string | null;
}

const Icon = ({ iconKey }: Props) => {
  switch (iconKey) {
    case 'quality':
      return <IconParameterQuality color="#EA5DD1" />;
    case 'effectivity':
      return <IconParameterEffectivity color="#42D848" />;
    case 'innovations':
      return <IconParameterInovations color="#3EAEFF" />;
    case 'quality_black':
      return <IconQualityMenu color={color('black')} id="quality_black" />;
    case 'effectivity_black':
      return (
        <IconEfectivityMenu color={color('black')} id="effectivity_black" />
      );
    case 'innovations_black':
      return (
        <IconInovationMenu color={color('black')} id="innovations_black" />
      );
    case 'success':
      return (
        <IconSuccess
          color={color('success')}
          height={16}
          id="icon-success-value"
          width={16}
        />
      );
    case 'warning':
      return (
        <IconWarningCircle height={16} id="icon-warning-value" width={16} />
      );
    case 'error':
      return <IconError height={16} id="icon-error-value" width={16} />;
    case null:
      return <IconDashedRing height={16} id="icon-dashed-value" width={16} />;
    default:
      return <span />;
  }
};

export default Icon;
