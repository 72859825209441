import { jwtDecode } from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';

const useTokenExpiration = (token?: string) => {
  const [expiredToken, setExpiredToken] = useState(false);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode<any>(token);
      const expirationTimestamp = Number(decodedToken?.exp) * 1000;

      intervalRef.current = window.setInterval(() => {
        if (expirationTimestamp < Date.now()) {
          setExpiredToken(true);
        }
      }, 3000);
    }

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [token]);

  return expiredToken;
};

export default useTokenExpiration;
