import { ReactNode, useEffect, useState } from 'react';
import useStyles from './RemainingChars.styles';
import { systemConstants } from '../../constants/systemConstants';
import { inlineStrCondition } from '../../utils/app.utils';

interface Props {
  formHelper?: string | ReactNode;
  formLabel?: string | ReactNode;
  isRequired?: boolean;
  maxLength?: number;
  valueLength: number;
}

/**
 * Komponent pre zobrazenie zostavajuceho poctu znakov v textovom poli.
 * @date 7. 3. 2023 - 10:35:35
 *
 * @param {Props} {
  formHelper, // pouzivat, ak textove pole obsahuje help property
  formLabel, // pouzivat, iba ak textove pole neobsahuje help property
  maxLength,
  valueLength,
}
 * @returns {*}
 */

const RemainingChars = ({
  formHelper,
  formLabel,
  isRequired,
  maxLength,
  valueLength,
}: Props) => {
  const classes = useStyles();
  const max = maxLength || systemConstants.MAX_TEXTAREA_LETTERS;
  const [remaininigChars, setRemainingChars] = useState<number>(max);
  const [remainingStr, setRemainingStr] = useState<string>(`${max} znakov`);
  useEffect(() => {
    const remaining = max - valueLength;
    setRemainingChars(remaining);
    setRemainingStr(
      `${remaining} ${
        remaining === 1
          ? 'znak'
          : inlineStrCondition(
              remaining > 1 && remaining < 5,
              'znaky',
              'znakov',
            )
      }`,
    );
  }, [max, valueLength]);
  if (formHelper)
    return (
      <div className={classes.helper}>
        {formHelper}
        <div className="text-right">
          <span
            className={`no-mrg-bottom text-color-${
              remaininigChars === 0 ? 'error' : 'black'
            }`}
          >
            {remainingStr}
          </span>
        </div>
      </div>
    );
  return (
    <div className={classes.label}>
      <span>
        {formLabel || ''}{' '}
        {isRequired && <span className="text-color-error">*</span>}
      </span>
      <span
        className={`no-mrg-bottom ${
          remaininigChars === 0 && 'text-color-error'
        }`}
      >
        {remainingStr}
      </span>
    </div>
  );
};

export default RemainingChars;
