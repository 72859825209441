interface Props {
  id: string;
}

const IconBlueMultiplication = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#1F57A5"
    />
    <path
      d="M12.9361 12.0005L17.9401 17.0045L17.0041 17.9405L12.0001 12.9365L6.99606 17.9405L6.06006 17.0045L11.0641 12.0005L6.06006 6.99655L6.99606 6.06055L12.0001 11.0645L17.0041 6.06055L17.9401 6.99655L12.9361 12.0005Z"
      fill="white"
    />
  </svg>
);

export default IconBlueMultiplication;
