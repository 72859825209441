import { createUseStyles } from 'react-jss';
import { breakpoints, color, colors, getRem } from '@dovera/design-system';

export default createUseStyles({
  section: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: '2rem',
    '@media(min-width: 1300px)': {
      paddingLeft: 48,
    },
  },
  profileCard: {
    backgroundColor: `#F4F9FF`,
    borderRadius: 8,
    padding: 32,
    marginBottom: 16,
    '& h5': {
      display: 'flex',
      alignItems: 'top',
      '&.with-illustration': {
        alignItems: 'center',
      },
      '& svg': {
        marginRight: 12,
        marginTop: 3,
      },
      '& img': {
        width: 'auto',
        height: getRem(44),
        marginRight: getRem(12),
      },
    },
    '& hr': {
      margin: `${getRem(12)} 0`,
      backgroundColor: color('secondary', 100),
    },
  },
  infoOldEP: {
    backgroundColor: colors.secondary[100],
    borderRadius: 8,
    padding: 16,
    marginTop: 32,
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      padding: '10px 0',
      '& svg': {
        flex: 1,
      },
      '& span': {
        flex: 5,
        paddingRight: 20,
      },
    },
  },
  infoOldEPButton: {
    justifyContent: 'center',
    '& .btn': {
      flex: 'auto',
    },
  },
  profileLinks: {},
  link: {
    color: colors.secondary?.[500],
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    padding: '29px 23px 29px 38px',
    position: 'relative',
    transition: 'all 0.2s ease 0s',
    borderBottom: `1px solid ${colors.grey?.['100']}`,
    '& svg': {
      '&:first-child': {
        marginRight: 24,
      },
      '&:last-child': {
        position: 'absolute',
        right: 23,
      },
    },
    '&:hover': {
      backgroundColor: 'rgb(242, 245, 250)',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    '& svg': {
      marginRight: 12,
    },
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 12,
      '&#oh-tooltip': {
        marginLeft: 12,
        verticalAlign: 'middle',
        marginTop: -3,
      },
      '&:last-child': {
        marginLeft: 8,
      },
    },
    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  description: {
    '& p': {
      fontSize: 16,
    },
  },
  table: {
    '& table': {
      border: 'none',
    },
    '& th': {
      backgroundColor: 'transparent',
      paddingBottom: 12,
    },
    '& tbody tr': {
      '&:nth-child(odd)': {
        '& td': {
          backgroundColor: '#F2F5FA',
        },
      },
      '& td': {
        border: 'none',
        verticalAlign: 'middle',
        '&:after': {
          display: 'none',
        },
        '& u': {
          fontWeight: 500,
        },
        '& button': {
          minHeight: 0,
          verticalAlign: 'middle',
          marginTop: -5,
          marginBottom: 0,
        },
        '& svg': {
          verticalAlign: 'middle',
        },
      },
    },
  },
  iconCheckTable: {
    '@media (min-width: 1200px)': {
      paddingLeft: 100,
    },
  },
  buttonLink: {
    minHeight: 0,
    margin: '-1px 0 0',
    '& svg': {
      marginRight: 12,
    },
  },
  buttonLinkRight: {
    minHeight: 0,
    margin: '-1px 0 0',
    '& svg': {
      marginLeft: 12,
    },
  },
  horizontalFilter: {
    display: 'flex',
    '& .radiocheck': {
      marginRight: 32,
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      display: 'block',
    },
  },
  filter: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: getRem(16),
    '& #doctors-filter-name, #ambulances-filter-name': {
      width: 195,
    },
    '& .doctors-filter-expertise-wrapper, .ambulances-filter-expertise-wrapper':
      {
        width: 308,
      },
    '& .doctors-filter-workingTime-wrapper': {
      width: 211,
    },
    '& .ambulances-filter-date-wrapper': {
      width: 190,
    },
    '& .form-control': {
      marginRight: 24,
      marginBottom: 0,
    },
    '& ul.autocomplete__menu': {
      minWidth: '22rem',
      '& li': {
        wordBreak: 'normal',
      },
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      display: 'block',
      '& > div': {
        display: 'block',
        '& input': {
          width: 'auto !important',
        },
      },
      '& #ambulances-filter-date': {
        marginRight: 0,
      },
    },
  },
  filterExportWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      minHeight: 0,
      '& span': {
        marginLeft: 12,
      },
    },
  },
  form: {
    '& .form-control': {
      width: '100%',
      maxWidth: 403,
    },
    '& ul': {
      fontSize: 'inherit',
      paddingLeft: 24,
    },
    '& [id*="password"]': {
      '& .is-error': {
        '& .input-addons__item': {
          backgroundColor: '#fde6e9',
        },
        '& .input__icon': {
          display: 'none',
        },
      },
    },
  },
  notification: {
    maxWidth: 'fit-content',
  },
  revealPasswordBtn: {},
  pagination: {
    maxWidth: 727,
    margin: '0 auto',
  },
  infoCard: {
    width: 'fit-content',
    minWidth: 543,
    maxWidth: '100%',
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      minWidth: 'auto',
      width: '100%',
    },
  },
  officeHoursButtons: {
    paddingLeft: 12,
    '& button, & a': {
      fontWeight: 500,
      minHeight: 0,
      margin: 0,
      padding: 0,
      '&:first-child': {
        marginRight: 19,
      },
      '& svg': {
        textDecoration: 'none',
        marginRight: '12px !important',
      },
      [`@media screen and (max-width: ${breakpoints.l}px)`]: {
        display: 'inline-block',
        marginLeft: 0,
        marginBottom: 12,
      },
    },
    '& svg': {
      verticalAlign: 'middle',
      '&#oh-tooltip-absence-info': {
        marginLeft: 4,
      },
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      display: 'inline-block',
      paddingLeft: 0,
      '& svg': {
        verticalAlign: 'middle',
        '&#oh-tooltip-absence-info': {
          marginTop: -12,
        },
      },
    },
  },
  subtitleWithButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      display: 'block',
    },
  },
  gridTableWrapper: {
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      overflowX: 'scroll',
      '& table': {
        whiteSpace: 'no-wrap',
        tableLayout: 'fixed',
      },
    },
  },
  gridTableOH: {
    '& td': {
      '&:first-child': {
        width: 'auto !important',
      },
      minWidth: getRem(40),
      paddingRight: getRem(24),
      '& span': {
        '& > b, & > span': {
          width: getRem(24),
          display: 'inline-block',
        },
      },
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      marginTop: 24,
    },
  },
});
