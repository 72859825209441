import { createUseStyles } from 'react-jss';

export default createUseStyles({
  label: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  helper: {
    display: 'block',
  },
});
