interface Props {
  id: string;
}

const IconEServices = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#BED2F4" height="6" rx="1" width="6" x="4" y="4" />
    <rect fill="#BED2F4" height="6" rx="1" width="6" x="14" y="4" />
    <rect fill="#BED2F4" height="6" rx="1" width="6" x="14" y="14" />
    <rect fill="#BED2F4" height="6" rx="1" width="6" x="4" y="14" />
  </svg>
);

export default IconEServices;
