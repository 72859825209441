import { ButtonSecondary, Modal, Textarea } from '@dovera/design-system';
import { useField, useForm } from 'react-final-form-hooks';
import useStyles from './ModalDisapproval.styles';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import strings from '../../../../constants/strings';

const texts = strings.auth.page.approvalAccess;

const validateForm = (values: { [x: string]: any }) => {
  const errors: any = {};
  if (!values.reason) errors.reason = texts.modals.accessDisapprove.error;
  return errors;
};

interface Props {
  isLoadingSubmit: boolean;
  isVisible: boolean;
  onHide: () => void;
  // eslint-disable-next-line
  onSubmit: (reason: string) => void;
}

const ModalDisapproval = ({
  isLoadingSubmit,
  isVisible,
  onHide,
  onSubmit,
}: Props) => {
  const classes = useStyles();
  const { form, handleSubmit } = useForm({
    onSubmit: (values: any) => onSubmit(values.reason),
    validate: (valuesToValid) => validateForm(valuesToValid),
  });
  const reason = useField('reason', form);
  const renderButtons = (
    <div className={classes.buttons}>
      <ButtonSecondary onClick={() => onHide()}>
        {texts.buttons.cancel}
      </ButtonSecondary>
      <ButtonSecondary
        className={classes.disapprovalBtn}
        isDisabled={isLoadingSubmit}
        isLoading={isLoadingSubmit}
        role="button"
        submit
      >
        {texts.buttons.disapprove}
      </ButtonSecondary>
    </div>
  );
  return (
    <Modal
      className={classes.modal}
      closeOnOverlayClick={false}
      header="Prístup nebude schválený"
      id={`solveWidgets--disapproval-modal`}
      isVisible={isVisible}
    >
      <form
        data-testid="auth--disapproval-form"
        id="auth--disapproval-form"
        onSubmit={handleSubmit}
      >
        <Textarea
          {...reason.input}
          className={classes.textarea}
          error={
            reason.meta.touched &&
            reason.meta.error && (
              <SafeHtml html={reason.meta.error} wrapper="span" />
            )
          }
          help={texts.modals.accessDisapprove.help}
          label={texts.modals.accessDisapprove.label}
          maxLength={750}
        />
        {renderButtons}
      </form>
    </Modal>
  );
};

export default ModalDisapproval;
