const IconCircleDashed = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      height="14"
      rx="7"
      stroke="#C5C6C8"
      strokeDasharray="2.5 2"
      strokeWidth="2"
      width="14"
      x="1"
      y="1"
    />
  </svg>
);

export default IconCircleDashed;
