export default {
  title: 'Prehľad cenových podmienok',
  description:
    'Poskytuje prehľad Vašich cenových podmienok dohodnutých so zdravotnou poisťovňou Dôvera. Nájdete tu podrobné informácie o výške kapitácií, cenách bodov, výkonov a o objemoch a hraniciach.',
  btnDownloadExport: 'Stiahnuť prehľad pre všetky odbornosti',
  btnContracts: 'Prehľad zmlúv',
  sections: {
    K: {
      title: 'Kapitácia',
      subtitle: 'Základná kapitácia',
      dodKapSubtitle: 'Dodatková kapitácia',
      doplnKapSubtitle: 'Doplnková kapitácia',
      values: {
        dodKap: 'Vaša dodatková kapitácia',
        doplnKap: 'Výška príplatku',
      },
      tableCols: {
        col1: 'Vek',
        col2: 'Kapitácia',
        col3: (
          <>
            Počet <br className="show-l-only" />
            poistencov
          </>
        ),
      },
      emptyState:
        'Pre vybraný filter sme nenašli dáta kapitácie. Prosím, zmeňte filter.',
    },
    C: {
      title: 'Ceny výkonov a body',
      tableCols: {
        col1: 'Kategória',
        col2: 'Kód výkonu',
        col3: '',
        col4: 'Počet bodov',
        col5: 'Cena bodu',
        col6: 'Cena za výkon',
      },
      emptyState:
        'Pre vybraný filter sme nenašli žiadne ceny výkonov a body. Prosím, zmeňte filter.',
    },
    O: {
      emptyState:
        'Pre vybraný filter sme nenašli žiadne dáta pre objemy a hranice. Prosím, zmeňte filter.',
    },
    filter: {
      month: 'Za mesiac',
    },
  },
  notifications: {
    newPrices: (count: number, str: string) =>
      `Máte <b>${count} ${str}</b> v hodnotách výkonov a bodov. Pozrieť si ich môžete cez filter <b>„Nová cena”</b> a <b>„Nový výkon”</b>`,
  },
};
