interface Props {
  color?: string;
  id?: string;
}

const IconEdit = ({ color = '#3F7C22', id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id || ''}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 11L21 8L16 3L13 6M18 11L8 21H3V16L13 6M18 11L13 6"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconEdit;
