import { useContext, useEffect } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import { Checkbox } from '@dovera/design-system';
import { useQuery } from '../../../hooks/useQuery';
import { FILTER_PREFIX } from '../constants';
import { RadioCheckType } from '../types';

const CheckboxFilter = ({ ...props }: RadioCheckType) => {
  const { children, id, isChecked, onChange, ...restProps } = props;

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
    if (queryValue && (queryValue === 'true') !== isChecked) {
      handleChange(queryValue === 'true', null);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (value, event) => {
    searchParams.set(`${FILTER_PREFIX}_${id}`, value);
    setSearchParams(searchParams);
    if (onChange) onChange(value, event);
  };

  return (
    <Checkbox
      id={id}
      isChecked={isChecked}
      onChange={(val, e) => handleChange(val, e)}
      {...restProps}
    >
      {children}
    </Checkbox>
  );
};

export default CheckboxFilter;
