import { Path, Svg } from '@react-pdf/renderer';

const ErrorIcon = () => (
  // @ts-ignore
  <Svg style={{ width: 14, height: 14, marginTop: 1 }}>
    <Path
      d="M0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6Z"
      fill="#E60523"
    />
    <Path
      d="M3.75 3.75L8.25 8.25"
      stroke="white"
      // @ts-ignore
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <Path
      d="M8.25 3.75L3.75 8.25"
      stroke="white"
      // @ts-ignore
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </Svg>
);

export default ErrorIcon;
