import { useState } from 'react';
import { arraySort } from '../../utils/array.utils';
import useStyles from './CompareChart.styles';
import LineStatus from './components/LineStatus';
import Line from './components/Line';
import { getLineColorNew } from './utils';
import { AttributesDataStick } from '../../types/parameters.types';
import Pointer from './components/Pointer';
import { color } from '@dovera/design-system';
import ValueStatus from './components/ValueStatus';
import MarkLabel from './components/MarkLabel';

interface Props {
  data: AttributesDataStick[];
}

const STICK_MAX_HEIGHT = 124;

const CompareChart = ({ data }: Props) => {
  const classes = useStyles({});
  const [hoveredLineIndex, setHoveredLineIndex] = useState(-1);
  return (
    <div className={classes.chartWrapper}>
      {arraySort(data, 'x').map((d, index) => {
        if (d.type === 'stick')
          return (
            <span
              className={classes.line}
              onFocus={() => setHoveredLineIndex(index)}
              onMouseLeave={() => setHoveredLineIndex(-1)}
              onMouseOver={() => setHoveredLineIndex(index)}
            >
              {hoveredLineIndex === index && (
                <LineStatus status={d.status} text={d.text} />
              )}
              <Line
                color={getLineColorNew({
                  isHovered: hoveredLineIndex === index,
                  status: d.status,
                })}
                height={
                  hoveredLineIndex === index
                    ? STICK_MAX_HEIGHT + 10
                    : STICK_MAX_HEIGHT * Number(d.y)
                }
                width={4}
              />
            </span>
          );
        if (d.type === 'mark')
          return (
            <span className={classes.chartPointer}>
              <Pointer
                color={getLineColorNew({ isHovered: true, status: d.status })}
                lineHeight={STICK_MAX_HEIGHT * Number(d.y) + 10}
                text={d.text || ''}
                width={4}
              />
              <MarkLabel
                countPoints={data.length}
                index={index}
                secondBorderInRow={
                  index !== 0 &&
                  ['mark', 'flag'].includes(
                    arraySort(data, 'x')[index - 1].type,
                  )
                }
                status={d.status}
                text={d.text}
              />
            </span>
          );
        if (d.type === 'flag')
          return (
            <span className={classes.valuePoint}>
              <ValueStatus status={d.status} text={d.text} />
              <Line
                color={color('secondary')}
                height={STICK_MAX_HEIGHT + 24}
                width={4}
              />
            </span>
          );
        return <span />;
      })}
    </div>
  );
};

export default CompareChart;
