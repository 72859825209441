import { createUseStyles } from 'react-jss';

interface StylesProps {
  expanded?: boolean;
}

export default createUseStyles({
  root: {
    backgroundColor: '#1F57A5',
    paddingBottom: 24,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflowY: 'hidden',
    '& h3': {
      paddingLeft: 32,
      fontWeight: 500,
    },
  },
  borderRadius: ({ expanded }: StylesProps) => ({
    borderTopLeftRadius: expanded ? 5 : 0,
    borderTopRightRadius: expanded ? 5 : 0,
  }),
  contentHeaderActions: {
    paddingTop: 23,
    paddingBottom: 8,
    '& button': {
      minHeight: 'auto',
      '&:first-child': {
        lineHeight: 0,
        display: 'block',
      },
    },
  },
});
