interface Props {
  color?: string;
}

const IconArrowRight = ({ color }: Props) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8.29289 1.29289C7.90237 1.68342 7.90237 2.31658 8.29289 2.70711L12.5858 7H0.833333C0.373096 7 0 7.44772 0 8C0 8.55228 0.373096 9 0.833333 9H12.5858L8.29289 13.2929C7.90237 13.6834 7.90237 14.3166 8.29289 14.7071C8.68342 15.0976 9.31658 15.0976 9.70711 14.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L9.70711 1.29289C9.31658 0.902369 8.68342 0.902369 8.29289 1.29289Z"
      fill={color || '#50A028'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconArrowRight;
