import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  dajPocetNeprecitanychVlakien,
  dajZoznamSpravVlakna,
  dajZoznamTemPZS,
  dajZoznamVlakien,
} from '../api/schrankaSprav';
import { LoadingState } from '../types';
import {
  DajPocetNeprecitanychVlakienResponse,
  DajZoznamSpravVlaknaResponse,
  DajZoznamTemPZSResponse,
  DajZoznamVlakienResponse,
  KategoriaSprav,
  KategoriaSpravType,
  SpravyState,
} from '../types/spravy.types';
import { hideTooltips } from '../utils/app.utils';

export const initialState: SpravyState = {
  detail: {
    dataState: LoadingState.none,
    emailVlakna: undefined,
    id: 0,
    idTema: 0,
    jeMoznostOdpovedat: false,
    nadpis: '',
    scroll: null,
    spravy: [],
    typObmedzenia: null,
    vymazane: false,
    zobrazit: false,
  },
  kategoria: KategoriaSprav.Vsetky,
  neprecitaneVlakna: {
    dataState: LoadingState.none,
    neprecitaneVymazane: false,
    pocet: 0,
  },
  refresh: false,
  temy: {
    data: [],
    dataState: LoadingState.none,
  },
  toast: {
    obnovenie: false,
    odoslanie: false,
    vymazanie: false,
  },
  vlakna: {
    dataState: LoadingState.none,
    data: [],
    firstId: 0,
    offset: 0,
    pocty: {
      [KategoriaSprav.Vsetky]: 0,
      [KategoriaSprav.Spravy]: 0,
      [KategoriaSprav.Notifikacie]: 0,
      [KategoriaSprav.Vymazane]: 0,
    },
    vyhladavanie: '',
    ziadneVlakna: false,
    zobrazNtf: true,
  },
};

export const spravySlice = createSlice({
  name: 'spravy',
  initialState,
  reducers: {
    oznacitPreVymazanie(
      state,
      action: PayloadAction<{
        id?: number;
        oznacene?: boolean;
        oznacitVsetko?: boolean;
      }>,
    ) {
      const { id, oznacene, oznacitVsetko } = action.payload;
      return {
        ...state,
        vlakna: {
          ...state.vlakna,
          data: state.vlakna.data.map((d) => ({
            ...d,
            oznacenePreVymazanie:
              d.id === id || oznacitVsetko
                ? oznacitVsetko || oznacene
                : oznacitVsetko === false
                  ? false
                  : d.oznacenePreVymazanie,
          })),
        },
      };
    },
    resetTem(state) {
      return {
        ...state,
        temy: initialState.temy,
      };
    },
    refresh(state) {
      return {
        ...state,
        detail: initialState.detail,
        kategoria: KategoriaSprav.Vsetky,
        refresh: true,
        vlakna: {
          ...state.vlakna,
          firstId: 0,
        },
      };
    },
    resetSchranky(state) {
      return {
        ...state,
        detail: initialState.detail,
        kategoria: KategoriaSprav.Vsetky,
        refresh: true,
        vlakna: {
          ...initialState.vlakna,
          zobrazNtf: state.vlakna.zobrazNtf,
        },
      };
    },
    scrollDetail(
      state,
      action: PayloadAction<{ to: 'top' | 'bottom' | null }>,
    ) {
      return {
        ...state,
        detail: {
          ...state.detail,
          scroll: action.payload.to,
        },
      };
    },
    zobrazDetail(state, action: PayloadAction<{ otvorit: boolean }>) {
      return {
        ...state,
        detail: {
          ...state.detail,
          zobrazit: action.payload.otvorit,
        },
      };
    },
    vyberKategorieVlakien(
      state,
      action: PayloadAction<{ kategoria: KategoriaSpravType | string }>,
    ) {
      if (state.kategoria === action.payload.kategoria) return state;
      return {
        ...state,
        kategoria: action.payload.kategoria,
        vlakna: {
          ...state.vlakna,
          data: [],
          dataState: LoadingState.reset,
          firstId: 0,
          offset: 0,
        },
      };
    },
    zmenaOffsetuVlakien(state, action: PayloadAction<{ offset: number }>) {
      return {
        ...state,
        vlakna: {
          ...state.vlakna,
          offset: action.payload.offset,
        },
      };
    },
    zobrazitToast(
      state,
      action: PayloadAction<{
        typ: 'odoslanie' | 'vymazanie' | 'obnovenie' | '';
      }>,
    ) {
      const { typ } = action.payload;
      if (!typ)
        return {
          ...state,
          toast: initialState.toast,
          vlakna: {
            ...state.vlakna,
            firstId: 0,
          },
        };
      return {
        ...state,
        detail: initialState.detail,
        toast: {
          obnovenie: typ === 'obnovenie',
          odoslanie: typ === 'odoslanie',
          vymazanie: typ === 'vymazanie',
        },
      };
    },
    zatvorNtf(state) {
      return {
        ...state,
        vlakna: {
          ...state.vlakna,
          zobrazNtf: false,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        dajPocetNeprecitanychVlakien.fulfilled.toString(),
        (
          state,
          action: PayloadAction<DajPocetNeprecitanychVlakienResponse>,
        ) => {
          const { pocetNepracitanychVlakien } = action.payload;
          state.neprecitaneVlakna = {
            dataState: LoadingState.filled,
            neprecitaneVymazane: state.neprecitaneVlakna.neprecitaneVymazane,
            pocet: pocetNepracitanychVlakien || 0,
          };
        },
      )
      .addCase(dajZoznamVlakien.pending.toString(), (state, action: any) => {
        const isSearching = !!action?.meta?.arg?.vyhladavanie;
        const isInitialOffset = !action?.meta?.arg?.offset;
        state.vlakna.dataState = LoadingState.fetching;
        state.vlakna.ziadneVlakna = false;
        state.refresh = false;
        state.vlakna.vyhladavanie = action?.meta?.arg?.vyhladavanie || '';
        if (isSearching || isInitialOffset) state.vlakna.firstId = 0;
      })
      .addCase(
        dajZoznamVlakien.fulfilled.toString(),
        (state, action: PayloadAction<DajZoznamVlakienResponse>) => {
          const { pocetVlakien, vlakna } = action.payload;

          if (!state.vlakna.offset) {
            state.detail = initialState.detail;
            state.vlakna.firstId = vlakna?.[0]?.id || 0;
          }

          const threads =
            state.kategoria !== KategoriaSprav.Vymazane
              ? vlakna.filter((v) => v.typVlakna !== KategoriaSprav.Vymazane)
              : vlakna;

          const pocetSprav =
            pocetVlakien.find((v) => v.typVlakna === KategoriaSprav.Spravy)
              ?.pocet || 0;
          const pocetNtf =
            pocetVlakien.find((v) => v.typVlakna === KategoriaSprav.Notifikacie)
              ?.pocet || 0;
          const pocetVymazane =
            pocetVlakien.find((v) => v.typVlakna === KategoriaSprav.Vymazane)
              ?.pocet || 0;
          const pocetVsetky = pocetSprav + pocetNtf;

          state.vlakna.data = state.vlakna.offset
            ? [...state.vlakna.data, ...threads]
            : threads;
          state.vlakna.pocty = {
            [KategoriaSprav.Vsetky]: pocetVsetky,
            [KategoriaSprav.Spravy]: pocetSprav,
            [KategoriaSprav.Notifikacie]: pocetNtf,
            [KategoriaSprav.Vymazane]: pocetVymazane,
          };
          state.vlakna.ziadneVlakna =
            (!vlakna.length || !vlakna) && !pocetVymazane && !pocetVsetky;
          state.neprecitaneVlakna.neprecitaneVymazane = !!pocetVlakien.find(
            (v) => v.typVlakna === KategoriaSprav.Vymazane,
          )?.pocetNeprecitanych;
          state.vlakna.dataState = LoadingState.filled;
        },
      )
      .addCase(dajZoznamVlakien.rejected.toString(), (state) => ({
        ...initialState,
        vlakna: {
          ...initialState.vlakna,
          dataState: LoadingState.error,
          zobrazNtf: state.vlakna.zobrazNtf,
        },
      }))
      .addCase(dajZoznamTemPZS.pending.toString(), (state) => ({
        ...state,
        temy: {
          ...initialState.temy,
          dataState: LoadingState.fetching,
        },
      }))
      .addCase(
        dajZoznamTemPZS.fulfilled.toString(),
        (state, action: PayloadAction<DajZoznamTemPZSResponse>) => ({
          ...state,
          temy: {
            ...state.temy,
            data: action.payload.typy,
            dataState: LoadingState.filled,
          },
        }),
      )
      .addCase(dajZoznamTemPZS.rejected.toString(), (state) => ({
        ...state,
        temy: {
          ...initialState.temy,
          dataState: LoadingState.error,
        },
      }))
      .addCase(
        dajZoznamSpravVlakna.pending.toString(),
        (state, action: any) => {
          hideTooltips();
          return {
            ...state,
            detail: {
              ...state.detail,
              dataState: LoadingState.fetching,
              id: Number(action?.meta?.arg?.id),
              zobrazit: true,
            },
          };
        },
      )
      .addCase(
        dajZoznamSpravVlakna.fulfilled.toString(),
        (state, action: PayloadAction<DajZoznamSpravVlaknaResponse>) => {
          const { email, jeMoznostOdpovedat, nadpis, spravy, typObmedzenia } =
            action.payload;
          const jeNeprecitane: boolean = !!state.vlakna.data.some(
            (d) => d.id === state.detail.id && d.neprecitane,
          );
          const neprecitaneVymazane: boolean =
            state.kategoria === KategoriaSprav.Vymazane &&
            state.vlakna.data.some(
              (d) => d.id === state.detail.id && d.neprecitane,
            )
              ? false
              : state.neprecitaneVlakna.neprecitaneVymazane;
          return {
            ...state,
            detail: {
              ...state.detail,
              dataState: LoadingState.filled,
              emailVlakna: email,
              idTema: spravy?.[0]?.idTema || 0,
              jeMoznostOdpovedat,
              nadpis,
              scroll: jeNeprecitane ? 'bottom' : state.detail.scroll,
              spravy,
              typObmedzenia,
            },
            neprecitaneVlakna: {
              ...state.neprecitaneVlakna,
              neprecitaneVymazane,
              pocet: jeNeprecitane
                ? state.neprecitaneVlakna.pocet - 1
                : state.neprecitaneVlakna.pocet,
            },
            vlakna: {
              ...state.vlakna,
              data: state.vlakna.data.map((d) =>
                d.id === state.detail.id ? { ...d, neprecitane: false } : d,
              ),
            },
          };
        },
      )
      .addCase(dajZoznamSpravVlakna.rejected.toString(), (state) => ({
        ...state,
        detail: {
          ...initialState.detail,
          dataState: LoadingState.error,
          id: state.detail.id,
        },
      }));
  },
});

export const {
  oznacitPreVymazanie,
  refresh,
  resetSchranky,
  resetTem,
  scrollDetail,
  vyberKategorieVlakien,
  zatvorNtf,
  zmenaOffsetuVlakien,
  zobrazDetail,
  zobrazitToast,
} = spravySlice.actions;
export default spravySlice.reducer;
