import { SearchOption, SuggestionsPayload } from './types';

const textNormalize = (val: string): string =>
  val
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

export const getSuggestions = ({
  input,
  values,
}: SuggestionsPayload): SearchOption[] =>
  values
    .filter((v) => textNormalize(v.text).includes(textNormalize(input)))
    .map((v) => v);
