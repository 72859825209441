interface Props {
  id: string;
}

const IconMenuParameters = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C7.3404 2 3.42515 5.18693 2.31504 9.5C2.10938 10.299 2 11.1368 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="#BED2F4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M7.00004 15.3178C8.07496 16.9345 9.91307 18 12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C9.77919 6 7.84016 7.2066 6.80273 9"
      stroke="#BED2F4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10"
      stroke="#BED2F4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconMenuParameters;
