import { createUseStyles } from 'react-jss';

import { borderRadius, color, fontWeight, spaces } from '../../constants';

interface StylesProps {
  hasAccordionItem: boolean;
  hasChildren: boolean;
  isFilled?: boolean;
  medzisucet?: boolean;
  vnorenyVzorec?: boolean;
  withoutFill?: boolean;
}

const getBgColor = ({ isFilled, medzisucet }: StylesProps): string => {
  if (isFilled) return color('secondary');
  if (medzisucet) return 'transparent';
  return 'rgba(210, 221, 237, .3)';
};

const getBarPadding = ({ hasChildren, medzisucet }: StylesProps): string => {
  if (hasChildren) return '1rem';
  if (medzisucet) return '0.75rem 1rem';
  return '0.85rem 1rem';
};

export default createUseStyles({
  formula: ({
    hasAccordionItem,
    hasChildren,
    isFilled,
    medzisucet,
  }: StylesProps) => ({
    borderRadius: `${borderRadius}px`,
    background: getBgColor({
      hasChildren,
      hasAccordionItem,
      isFilled,
      medzisucet,
    }),
    color: isFilled ? color('white') : 'inherit',
    textAlign: 'center',
    '& > div': {
      textAlign: 'left',
    },
    '&.withoutFill': {
      background: '#FFFFFF !important',
    },
    // border:
    //   !medzisucet &&
    //   `${vnorenyVzorec ? 1 : 2}px solid ${
    //     (accordionItem && accordionItem.isActive) || vnorenyVzorec
    //       ? color('primary')
    //       : color('secondary')
    //   }`,
    padding: hasAccordionItem ? '0 1rem' : 'auto',
    '& > .bar': {
      padding: getBarPadding({
        hasChildren,
        hasAccordionItem,
        isFilled,
        medzisucet,
      }),
      marginTop: 0,
    },
    '& .bar__item': {
      marginTop: 0,
    },
    '& > svg': {
      display: 'flex',
      margin: '0 auto',
    },
    '& $formula': {
      marginBottom: spaces.xsmall,
      marginTop: spaces.xsmall,
    },
    '& $formula:first-child': {
      marginTop: 0,
    },
    '& $formula:last-child': {
      marginBottom: 0,
    },
    fontWeight: medzisucet ? fontWeight.bold : 'normal',
    '&.clickable': {
      transition: '0.2s ease',
      // '&:hover': {
      //   border: !medzisucet && `2px solid ${color('primary')}`,
      //   color: color('primary'),
      // },
    },
    cursor: hasAccordionItem && 'pointer',
  }),
  accordionFormula: {
    cursor: 'pointer',
    padding: '0.75rem 0 !important',
    '&.active': {
      fontWeight: 'bold',
    },
  },
  formulaAccordionIcon: {
    '& svg': {
      transition: '0.2s ease',
    },
    '&.active': {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  formulaAccordionContent: {
    maxHeight: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    '&.active': {
      padding: 0,
      maxHeight: 380,
      transition: '.4s ease-in',
      visibility: 'visible',
    },
  },
  hodnota: {
    fontWeight: fontWeight.bold,
  },
  formulaWithChildren: {
    padding: '1rem !important',
  },
});
