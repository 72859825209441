import { getNestedPropValue } from './object.utils';

export const swapPositions = (array, a, b) => {
  [array[a], array[b]] = [array[b], array[a]];
};

/**
 * Metoda pre zoradenie objektov pola podla specifickeho kluca json objektu
 * @date 20. 2. 2023 - 9:18:03
 *
 * @export
 * @template T
 * @param {T[]} array
 * @param {?string} [sortBy]
 * @param {?(boolean | 'desc')} [alhpabetically]
 * @param {?boolean} [sortByDateDesc]
 * @param {?boolean} [sum]
 * @returns {*}
 */
export function arraySort<T>(
  array: T[],
  sortBy?: string,
  alhpabetically?: boolean | 'asc' | 'desc',
  sortByDateDesc?: boolean,
  sum?: boolean,
): T[] {
  if (!array) return [];
  if (!sortBy) return defaultSort(array);
  if (sortBy && sum) return sortBySum(array, sortBy, alhpabetically);
  if (sortBy && sortByDateDesc)
    return sortByDate(array, sortBy, alhpabetically);
  if (alhpabetically && alhpabetically !== 'desc' && sortBy)
    return sortAlphabetically(array, sortBy, 'asc');
  if (alhpabetically === 'desc' && sortBy)
    return sortAlphabetically(array, sortBy, 'desc');
  return array
    .filter((a) => !a[sortBy])
    .concat(
      array.filter((a) => a[sortBy]).sort((a, b) => a[sortBy] - b[sortBy]),
    );
}

function defaultSort<T>(array: T[]): T[] {
  // @ts-ignore
  return [...array].sort((a, b) => a - b);
}

function sortBySum<T>(
  array: T[],
  sortBy: string,
  alhpabetically?: boolean | 'asc' | 'desc',
): T[] {
  return array
    .map((a) => ({ ...a, [sortBy]: Number(a[sortBy]) }))
    .sort((a, b) => {
      if (alhpabetically)
        return (
          Number(getNestedPropValue(a, sortBy)) -
          Number(getNestedPropValue(b, sortBy))
        );
      return (
        Number(getNestedPropValue(b, sortBy)) -
        Number(getNestedPropValue(a, sortBy))
      );
    });
}

function sortByDate<T>(
  array: T[],
  sortBy: string,
  alhpabetically?: boolean | 'asc' | 'desc',
): T[] {
  return array.sort(
    (a, b) =>
      Date.parse(getNestedPropValue(alhpabetically ? a : b, sortBy)) -
      Date.parse(getNestedPropValue(alhpabetically ? b : a, sortBy)),
  );
}

function sortAlphabetically<T>(
  array: T[],
  sortBy: string,
  order: 'asc' | 'desc' = 'asc',
): T[] {
  return array.sort((a, b) => {
    const aValue = getNestedPropValue(a, sortBy);
    const bValue = getNestedPropValue(b, sortBy);
    if (aValue < bValue) {
      return order === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });
}

export function toHashMap<T>(
  array: T[],
  key: string,
  objToArr: boolean = false,
) {
  if (!array || !array.length || !key) {
    return {};
  }

  const result = {};

  array.forEach((obj) => {
    if (obj[key]) {
      result[obj[key]] = objToArr ? [obj] : obj;
    }
  });

  return result;
}

/**
 *  Converts array with multiple values into a single array with all items:
 */
export const mergeMultidimensionalArray = (arrays: any[]): any =>
  /* eslint-disable-next-line */
  [].concat.apply([], arrays);

export function uniqueArray<T>(
  arr: T[],
  ignoreProps?: string[],
  encoder = JSON.stringify,
  decoder = JSON.parse,
): T[] {
  let arrRes: any[] = arr;
  if (ignoreProps)
    ignoreProps.forEach((p) => {
      arrRes = arr.map((a) => ({
        ...a,
        [`${p}`]: undefined,
      }));
    });
  return [...new Set(arrRes?.map((item) => encoder(item)))]?.map((item) =>
    decoder(item),
  );
}

export function groupByItem<T>(array: T[] | null, key: string): T[] {
  if (!array || !array.length) return [];
  const resp: T[] = [];
  array.forEach((item) => {
    if (!resp.some((r) => r[key] === item[key])) resp.push(item);
  });
  return resp;
}
