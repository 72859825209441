import { Icon, Input } from '@dovera/design-system';
import { ComponentProps, SearchOption } from './types';
import { useCallback, useRef, useState } from 'react';
import useStyles from './styles';
import { getSuggestions } from './utils';
import { useDebounce } from '../../hooks/useDebounce';
import useOuterClick from '../PeriodPicker/hooks/useOuterClick';
import { cx } from '../../utils/exports';

/** TODO pouzitie nahradit Autocomplete DDS, ak bude obsahovat pouzitie suggestions bez diakritiky
 * @date 20. 12. 2023 - 13:47:07
 *
 * */
const LocalSearch = ({ ...props }: ComponentProps) => {
  const inputRef = useRef(null);
  const classes = useStyles();
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 300);
  const [visible, setVisible] = useState(false);
  const onChoose = useCallback(
    (o: SearchOption) => {
      setValue(o.text);
      setVisible(false);
      props.onChoose(o.value);
    },
    [props],
  );
  const wrapperRef = useOuterClick<HTMLDivElement>(() => {
    setVisible(false);
  });
  const renderInput = (
    <Input
      {...props}
      ref={inputRef}
      addonsInside
      autoComplete="off"
      className=""
      crossOrigin
      onClick={() => {
        setVisible(true);
        // @ts-ignore
        inputRef.current?.select();
      }}
      // eslint-disable-next-line
      onChange={(e) => {
        if (e.target.value?.length > 0 && !visible) setVisible(true);
        setValue(e.target.value);
        props.resetChoice();
      }}
      rightAddons={
        <Icon
          id={`icon-filter-name-search--${props.id}`}
          name="search"
          size="medium"
        />
      }
      value={value}
    />
  );
  const renderOptions = () => {
    if (!!debouncedValue && visible) {
      const suggestions = getSuggestions({
        input: debouncedValue,
        values: props.options,
      });
      return (
        <ul className={classes.options} role="listbox">
          {suggestions?.length ? (
            suggestions.map((val) => (
              <li
                key={`${val.value}--option--${val.text}`}
                onClick={() => onChoose(val)}
                role="presentation"
              >
                {val.text}
              </li>
            ))
          ) : (
            <b className={classes.emptyResult}>Nenašli sa žiadne výsledky.</b>
          )}
        </ul>
      );
    }
    return <span />;
  };
  return (
    <div
      ref={wrapperRef}
      className={cx(classes.localSearchWrapper, props.className)}
    >
      {renderInput}
      {renderOptions()}
    </div>
  );
};

export default LocalSearch;
