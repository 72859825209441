interface Props {
  active: boolean;
  id: string;
}

const IconEuroInvoice = ({ active, id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="16"
      cy="16"
      fill={active ? '#A8A2C0' : '#C4C4C4'}
      rx="16"
      ry="16"
    />
    <path
      clipRule="evenodd"
      d="M16.9623 9C14.1528 9 11.7316 10.6364 10.6028 13H17.7391C18.2913 13 18.7391 13.4477 18.7391 14C18.7391 14.5523 18.2913 15 17.7391 15H9.99675C9.94976 15.3266 9.92544 15.6605 9.92544 16C9.92544 16.3395 9.94976 16.6734 9.99675 17H17.7391C18.2913 17 18.7391 17.4477 18.7391 18C18.7391 18.5523 18.2913 19 17.7391 19H10.6028C11.7316 21.3636 14.1528 23 16.9623 23C19.0708 23 20.9608 22.0789 22.2513 20.6174C22.6169 20.2034 23.2488 20.1642 23.6628 20.5297C24.0768 20.8953 24.1161 21.5273 23.7505 21.9412C22.0952 23.8157 19.6666 25 16.9623 25C13.0304 25 9.68059 22.4975 8.43951 19H6C5.44772 19 5 18.5523 5 18C5 17.4477 5.44772 17 6 17H7.98057C7.94414 16.6717 7.92544 16.338 7.92544 16C7.92544 15.662 7.94415 15.3283 7.98057 15H6C5.44772 15 5 14.5523 5 14C5 13.4477 5.44772 13 6 13H8.43951C9.68059 9.50255 13.0304 7 16.9623 7C19.6666 7 22.0952 8.18429 23.7505 10.0588C24.1161 10.4727 24.0768 11.1047 23.6628 11.4703C23.2488 11.8358 22.6169 11.7966 22.2513 11.3826C20.9608 9.9211 19.0708 9 16.9623 9Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default IconEuroInvoice;
