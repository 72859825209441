import { Bar, BarItem, Card, CardSection } from '@dovera/design-system';
import { Skeleton } from '../Skeleton';

const PEKContentSkeleton = () => (
  <Card>
    <CardSection isCondensed>
      <Bar align="top" space="small">
        <BarItem isFilling>
          <h3 className="mb-small">
            <Skeleton width="60%" />
          </h3>
          <div className="mb">
            <Skeleton height="100px" width="100%" />
          </div>
          <br />
          <h3 className="mb-small">
            <Skeleton width="60%" />
          </h3>
          <div className="mb">
            <Skeleton height="100px" width="100%" />
          </div>
          <br />
          <h3 className="mb-small">
            <Skeleton width="60%" />
          </h3>
          <div className="mb">
            <Skeleton height="100px" width="100%" />
          </div>
        </BarItem>
      </Bar>
    </CardSection>
  </Card>
);

export default PEKContentSkeleton;
