import { createUseStyles } from 'react-jss';
import { breakpoints, getRem } from '@dovera/design-system';

interface StylesProps {
  fixed?: boolean;
  flex?: boolean;
  padBottom?: boolean;
}

const getImgProps = (isFlex: boolean) => {
  if (isFlex)
    return {
      position: 'relative',
      maxWidth: getRem(175),
      maxHeight: getRem(175),
      marginBottom: getRem(24),
      height: 'auto',
      marginLeft: getRem(16),
    };
  return {
    position: 'absolute',
    maxWidth: 'auto',
    maxHeight: 'auto',
    marginBottom: 0,
    height: 'auto',
    marginLeft: getRem(16),
  };
};

export default createUseStyles({
  root: {
    backgroundColor: '#e8f2fe',
    position: 'relative',
    padding: `${getRem(27)} 0 0`,
    '& .container': {
      position: 'relative',
      margin: 0,
      maxWidth: '100%',
    },
    '& h3': {
      marginTop: getRem(8),
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        marginRight: getRem(12),
      },
    },
    '& > img': {
      right: 0,
      bottom: 0,
      width: getRem(300),
      [`@media screen and (max-width: 1480px)`]: {
        top: 'auto',
        bottom: 0,
        height: 'auto',
        width: getRem(250),
      },
      [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
        display: 'none',
      },
    },
    '& h4': {
      '& .form-control--select': {
        marginLeft: getRem(8),
        '& .choices': {
          fontSize: getRem(24),
        },
      },
    },
    '& .choices__list--dropdown': {
      minWidth: getRem(160),
      fontWeight: 400,
    },
    '& > div': {
      '&:not(.grid) &:not(.container)': {
        maxWidth: getRem(750),
      },
    },
  },
  rootExtra: ({ fixed, flex, padBottom }: StylesProps) => ({
    paddingBottom: padBottom ? `${getRem(32)} !important` : 0,
    display: `${flex ? 'flex' : 'block'} !important`,
    position: fixed ? 'fixed' : 'relative',
    top: fixed ? getRem(56) : '',
    width: fixed ? '100%' : 'auto',
  }),
  media: {
    [`@media screen and (min-width: 1350px)`]: {
      paddingTop: `${getRem(27)} !important`,
      paddingLeft: `${getRem(84)} !important`,
      paddingRight: `${getRem(84)} !important`,
    },
    [`@media screen and (max-width: 1349px)`]: {
      paddingTop: `${getRem(27)} !important`,
      paddingLeft: `${getRem(48)} !important`,
      paddingRight: `${getRem(48)} !important`,
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      padding: `${getRem(16)} !important`,
    },
  },
  illustration: ({ flex }: StylesProps) => ({
    ...getImgProps(!!flex),
  }),
});
