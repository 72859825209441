import { Button, Checkbox } from '@dovera/design-system';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconTrash from '../../../components/CustomIcons/IconTrash';
import { RootState } from '../../../rootReducer';
import { oznacitPreVymazanie } from '../../../slices/spravy.slice';
import { LoadingState } from '../../../types';
import useStyles from '../Spravy.styles';

interface Props {
  onRemove: () => void;
}

const RemoveThreads = ({ onRemove }: Props) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const { vlakna } = useSelector((state: RootState) => state.spravy);
  const changeCallback = useCallback(
    (isChecked) => {
      setIsChecked(isChecked);
      dispatch(oznacitPreVymazanie({ oznacitVsetko: isChecked }));
    },
    [dispatch],
  );
  useEffect(() => {
    if (vlakna.data.some((v) => !v.oznacenePreVymazanie) && isChecked)
      setIsChecked(false);
    if (!vlakna.data.some((v) => !v.oznacenePreVymazanie) && !isChecked)
      setIsChecked(true);
    // eslint-disable-next-line
  }, [vlakna.data]);
  const count = vlakna.data.filter((v) => v.oznacenePreVymazanie).length;
  if (
    !vlakna.data.some((v) => v.oznacenePreVymazanie) ||
    vlakna.dataState !== LoadingState.filled
  )
    return <span />;
  return (
    <div className={classes.removeThreads}>
      <div>
        {vlakna.data.length > 1 ? (
          <Checkbox
            className="no-mrg"
            data-checked={isChecked}
            id="choose-all-threads"
            isChecked={isChecked}
            onChange={changeCallback}
          >
            Označiť {vlakna.data.length} správ
            {vlakna.data.length < 5 ? 'y' : ''}
          </Checkbox>
        ) : (
          <span />
        )}
        <Button className="no-mrg" onClick={onRemove} type="destructive">
          <IconTrash id="remove-all-threads-icon" />
          Vymazať{!!count && ` (${count})`}
        </Button>
      </div>
    </div>
  );
};

export default RemoveThreads;
