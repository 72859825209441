import Autocomplete from '../../../../../components/Autocomplete';
import useStyles from '../../../Proposals.styles';
import strings from '../../../../../constants/strings';
import { storeStep3 } from '../../../../../slices/spaProposals.slice';
import { Choice } from 'choices.js';
import IconSearch from '../../../../../components/CustomIcons/IconSearch';
import {
  getDiagnosesList,
  getIndicatorGroups,
} from '../../../../../api/spaProposals';
import {
  ProposalDiagnose,
  ProposalIndicatorGroup,
} from '../../../../../types/spaProposals.types';
import { Link, color } from '@dovera/design-system';
import IconExternalLink from '../../../../../components/CustomIcons/IconExternalLink';
import { useAppDispatch } from '../../../../../hooks/useStore';

interface Props {
  diagnose: ProposalDiagnose | null;
  diagnoseField: any;
  expertiseAmbulance: string;
  expertiseDoctor: string;
  indicatorGroup: ProposalIndicatorGroup | null;
  indicatorGroupField: any;
}

const texts = strings.proposals.new;

const IsDg = ({
  diagnose,
  diagnoseField,
  expertiseAmbulance,
  expertiseDoctor,
  indicatorGroup,
  indicatorGroupField,
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const renderIS = (
    <div className={classes.autocompleteWrapper}>
      <Autocomplete
        {...indicatorGroupField.input}
        addonsInside
        delay={200}
        error={
          indicatorGroupField.meta.touched &&
          !indicatorGroupField.meta.active &&
          indicatorGroupField.meta.error &&
          indicatorGroupField.meta.error
        }
        id="proposals--indicator-group"
        isRequired
        label={texts.labels.indicatorGroup}
        minLength={2}
        noMarginBottom
        notFilterOptions
        onChange={(value) => {
          indicatorGroupField.input.onChange(value);
          // reset redux state
          if (indicatorGroup) dispatch(storeStep3({ indicatorGroup: null }));
        }}
        onSelect={(option: Choice | any) => {
          if (option) {
            indicatorGroupField.input.onChange(option.label);
            dispatch(
              storeStep3({
                indicatorGroup: {
                  nazov: option.label.split(`${option.value} - `)?.[1],
                  kod: option.value,
                },
              }),
            );
          }
        }}
        rightAddons={
          <IconSearch
            className="clickable"
            id="proposals--indicator-group--search"
          />
        }
        source={(query) =>
          getIndicatorGroups({
            hladanaIndikacnaSkupina: query,
            kodDiagnoza: diagnose?.kod || null,
            odbornostLek: expertiseDoctor,
            odbornostNZ: expertiseAmbulance,
          })
        }
      />
    </div>
  );
  const renderDG = (
    <div className={classes.autocompleteWrapper}>
      <Autocomplete
        {...diagnoseField.input}
        addonsInside
        delay={200}
        error={
          diagnoseField.meta.touched &&
          !diagnoseField.meta.active &&
          diagnoseField.meta.error &&
          diagnoseField.meta.error
        }
        id="proposal--step3--diagnose"
        isRequired
        label={texts.labels.diagnose}
        minLength={2}
        noMarginBottom
        notFilterOptions
        onChange={(value) => {
          diagnoseField.input.onChange(value);
          // reset redux state
          if (diagnose) dispatch(storeStep3({ diagnose: null }));
        }}
        onSelect={(option: Choice | any) => {
          if (option) {
            diagnoseField.input.onChange(option.label);
            dispatch(
              storeStep3({
                diagnose: {
                  kod: option.value,
                  nazov: option.label.split(`${option.value} - `)?.[1],
                },
              }),
            );
          }
        }}
        rightAddons={
          <IconSearch className="clickable" id="proposals--diagnose--search" />
        }
        source={(query) =>
          getDiagnosesList({
            hladanaDiagnoza: query,
            kodIndikacnaSkupina: indicatorGroup?.kod || null,
            odbornostLek: expertiseDoctor,
            odbornostNZ: expertiseAmbulance,
          })
        }
      />
    </div>
  );
  const renderIndicatorListLink = (
    <Link
      href="https://www.dovera.sk/media/a542/file/item/sk/0000/novy_iz_platny_od_01_11_2017.Tur4.pdf"
      placeholder
      target="_blank"
      type="secondary"
    >
      {texts.buttons.showIndicatorsList}
      <IconExternalLink color={color('black')} id="indicatorList--external" />
    </Link>
  );
  return (
    <>
      <div className={classes.indicatorGroupWrapper}>
        {renderIS}
        {renderIndicatorListLink}
      </div>
      {renderDG}
    </>
  );
};

export default IsDg;
