import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Icon,
  Input,
  Loader,
} from '@dovera/design-system';
import { MultiProps } from './types.d';
import useStyles from './styles';
import { createPeriods, getTextFormatPeriod } from './utils';
import _ from 'lodash';
import { cx } from '../../utils/exports';
import { inlineCondition } from '../../utils/app.utils';
import useOutsideClick from '../../hooks/useOutsideClick';

const MultiPicker = ({
  allPeriods,
  error,
  id,
  isLoading,
  label,
  maxChoose,
  maxPeriod,
  minPeriod,
  onChoose,
  values,
  warning,
}: MultiProps) => {
  const classes = useStyles();
  const multiPeriodRef = createRef<HTMLDivElement>();
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [inputValue, setInputValue] = useState(
    values ? _.capitalize(getTextFormatPeriod(values[0])) : '',
  );
  const [localValues, setLocalValues] = useState<number[]>(values || []);
  const periods = useMemo(() => {
    if (allPeriods) return allPeriods;
    if (minPeriod) return createPeriods(minPeriod, maxPeriod).reverse();
    return [];
  }, [maxPeriod, minPeriod, allPeriods]);
  useOutsideClick(multiPeriodRef, (e) => {
    const element = e.target as HTMLElement;
    const formControl = element.closest('div.form-control');
    if (
      isDropdownActive &&
      !element.id.includes(id) &&
      (!formControl || !formControl.querySelector(`.input-addons`))
    )
      setIsDropdownActive(false);
  });
  const changeInputCallback = useCallback(
    (changedValues?: number[]) => {
      const vals: number[] | null | undefined = changedValues || values;
      if (!vals?.length) setInputValue('');
      if (vals?.length)
        setInputValue(
          vals?.length === 1
            ? _.capitalize(getTextFormatPeriod(vals[0]))
            : inlineCondition(
                vals?.length < 5,
                `Vybrané ${vals?.length} mesiace`,
                `Vybraných ${vals?.length} mesiacov`,
              )?.toString() || '',
        );
    },
    [values],
  );
  const changeCallback = useCallback(
    (value: number) => {
      const changedValues = localValues?.includes(value)
        ? localValues.filter((v) => v !== value)
        : [...localValues, value];
      if (changedValues?.length === 1) onChoose(changedValues);
      if (!changedValues?.length) onChoose([]);
      changeInputCallback(changedValues);
      setLocalValues(changedValues);
    },
    [changeInputCallback, localValues, onChoose],
  );
  useEffect(() => {
    setLocalValues(values || []);
    changeInputCallback();
  }, [changeInputCallback, values]);
  const renderInput = (
    /* eslint-disable-next-line */
    <div
      className="clickable"
      onClick={() => setIsDropdownActive(!isDropdownActive)}
    >
      <Input
        addonsInside
        className={cx(classes.multipickerInput, 'clickable')}
        crossOrigin
        error={error}
        id={`${id}--input`}
        isReadonly
        label={label}
        rightAddons={
          isLoading ? (
            <Loader size={24} />
          ) : (
            <div
              className={cx(classes.inputChevron)}
              onClick={() => setIsDropdownActive(true)}
              onKeyDown={() => setIsDropdownActive(true)}
              role="button"
              tabIndex={0}
            >
              <Icon name="chevron-down" />
            </div>
          )
        }
        value={inputValue}
        warning={warning}
      />
    </div>
  );
  const renderButton = localValues?.length > 1 && (
    <div className={classes.optionsBtnWrapper}>
      <Button
        className="no-mrg"
        onClick={() => {
          onChoose(localValues);
          setIsDropdownActive(false);
        }}
      >
        Potvrdiť
      </Button>
    </div>
  );
  const renderOptions = (
    <div
      className={cx(
        classes.multiPickerOptions,
        isDropdownActive && 'is-active',
      )}
    >
      <CheckboxGroup
        onChange={(e) =>
          // @ts-ignore
          changeCallback(Number(e.target?.value))
        }
      >
        {periods.map((p, key) => (
          <Checkbox
            key={`period-${p.datum}--${p.text}`}
            id={`${id}--checkbox--${key}`}
            isDisabled={
              isLoading ||
              (localValues?.length === maxChoose &&
                !localValues?.includes(Number(p.datum)))
            }
            // eslint-disable-next-line
            isChecked={localValues.some((v) => v === Number(p.datum))}
            value={p.datum}
          >
            {_.capitalize(p.text)}
          </Checkbox>
        ))}
      </CheckboxGroup>
      {renderButton}
    </div>
  );
  return (
    <div
      ref={multiPeriodRef}
      className={cx(
        classes.multiPickerWrapper,
        isDropdownActive && 'is-active',
      )}
      data-testid="multiperiod-picker"
    >
      {renderInput}
      {renderOptions}
    </div>
  );
};

export default MultiPicker;
