import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import strings from '../../../../../constants/strings';
import { hotjarMasking } from '../../../../../utils/strings.utils';

const texts = strings.proposals.new;

const Summary = () => {
  const { step1 } = useSelector(
    (state: RootState) => state.spaProposals.new.stepper,
  );
  return (
    <>
      <div className="mb-small">
        <b>{`${texts.labels.doctor}: `}</b>
        {hotjarMasking(`${step1.doctor.name} (${step1.doctor.code})`, 'b')}
      </div>
      <div className="mb-small">
        <b>{`${texts.labels.ambulance}: `}</b>
        {step1.ambulance.code}
        {step1.ambulance.name && ` (${step1.ambulance.name})`}
      </div>
      <div className="no-mrg">
        <b>{`${texts.labels.email}: `}</b>
        {hotjarMasking(step1.doctor.email || strings.undefined)}
      </div>
    </>
  );
};

export default Summary;
