const IconBlueInfo = () => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" fill="#1F57A5" r="16" />
    <rect fill="white" height="11" rx="1.5" width="3" x="15" y="13" />
    <circle cx="16.5" cy="9.5" fill="white" r="1.5" />
  </svg>
);

export default IconBlueInfo;
