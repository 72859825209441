import { Nullable } from '../../../types';
import { FormulaValue, Sign } from '../types';
import { getNextFormulaSign } from '../utils';
import { Formula, RecursiveFormula } from '.';

interface Props {
  activeAccordion: Nullable<string>;
  formula?: string;
  formulaValues: FormulaValue[];
  isColored: boolean;
  text: string;
  updateActiveAccordion: (v: Nullable<string>) => void;
  value: string;
}

const FormulaChild = ({
  activeAccordion,
  formula,
  formulaValues,
  isColored,
  text,
  updateActiveAccordion,
  value,
}: Props) => (
  <>
    {formulaValues?.map((fv) => {
      let nextSign: Nullable<Sign> = null;
      if (formula) {
        nextSign = getNextFormulaSign(formula, fv.variable);
      }
      return (
        <RecursiveFormula
          key={fv.variable}
          activeAccordion={activeAccordion}
          formula={fv.formula}
          formulaValues={fv.formulaValues ? fv.formulaValues : []}
          isColored={!isColored}
          nextSign={nextSign}
          text={fv.text}
          tooltip={fv?.tooltip && fv.tooltip}
          updateActiveAccordion={updateActiveAccordion}
          value={fv.value}
          variable={fv.variable}
        />
      );
    })}
    <Formula hodnota={value} medzisucet nazov={text} />
  </>
);

export default FormulaChild;
