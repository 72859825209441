interface Props {
  color?: string;
  height?: number;
  id: string;
  width?: number;
}

const IconReply = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 24}
    id={id}
    viewBox={`0 0 ${width || 24} ${height || 24}`}
    width={width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8.70711 6.70711C9.09763 6.31658 9.09763 5.68342 8.70711 5.29289C8.31658 4.90237 7.68342 4.90237 7.29289 5.29289L2.29289 10.2929C1.90237 10.6834 1.90237 11.3166 2.29289 11.7071L7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071C9.09763 16.3166 9.09763 15.6834 8.70711 15.2929L5.41421 12H14C16.2091 12 18 13.7909 18 16V17C18 17.5523 18.4477 18 19 18C19.5523 18 20 17.5523 20 17V16C20 12.6863 17.3137 10 14 10H5.41421L8.70711 6.70711Z"
      fill={color || 'white'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconReply;
