import { useEffect, useState } from 'react';
import useStyles from './Header.styles';
import {
  Button,
  Header as DDLHeader,
  HeaderLogo,
  breakpoints,
} from '@dovera/design-system';
import { Link, useLocation } from 'react-router-dom';

import routes from '../../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { toggleMenu } from '../../slices/menu.slice';
import { IconHamburger } from '../CustomIcons';
import { ns } from '../../utils/object.utils';
import { useWindowSize } from '../../hooks/useWindowSize';
import { isLessThen10SecondsFromLogin, safeNode } from '../../utils/app.utils';
import SwitchRole from '../SwitchRole/SwitchRole';
import { useAppDispatch } from '../../hooks/useStore';
import MessagesCount from '../MessagesCount/MessagesCount';

const Header = ({ ...other }) => {
  const env = ns(() => window.env.ENV);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const menuState = useSelector((state: RootState) => state.menu);
  const { isASOC, isImpersonalization, isSubjectAccess, token, vztahId } =
    useSelector((state: RootState) => state.auth);
  const { isLoaded } = useSelector((state: RootState) => state.poskytovatel);
  const windowSize = useWindowSize();
  const [canShowSubjectAccess, setCanShowSubjectAccess] = useState(false);
  const classes = useStyles({
    env,
    isStatic: menuState.isStatic,
    isSubjectAccess:
      (isSubjectAccess || isImpersonalization) && canShowSubjectAccess,
  });

  useEffect(() => {
    if (
      token &&
      (isSubjectAccess || isImpersonalization) &&
      isLessThen10SecondsFromLogin(token)
    ) {
      setTimeout(() => {
        setCanShowSubjectAccess(true);
      }, 8000);
    } else if (token && isSubjectAccess) setCanShowSubjectAccess(true);
  }, [isImpersonalization, isSubjectAccess, token]);

  const hamburger = !menuState.isStatic && isLoaded && (
    <button
      className={classes.hamburger}
      onClick={() => dispatch(toggleMenu())}
      type="button"
    >
      <IconHamburger id="icon-hamburgerMenu" />
    </button>
  );

  if (windowSize.width < breakpoints.l) other = [];

  const logoArea = ({ isMobile }: { isMobile?: boolean }) => (
    <>
      {isMobile ? (
        <HeaderLogo
          href={<Link to={routes.dashboard} />}
          src="/logo-small.svg"
        />
      ) : (
        <HeaderLogo href={<Link to={routes.dashboard} />} src="/logo.svg" />
      )}
      {env !== 'PROD' && (isLoaded || location.pathname === routes.testIDP) && (
        <div className={classes.badgeVersion}>
          {`${env}`}
          <span> verzia</span>
        </div>
      )}
      {safeNode(
        <SwitchRole
          impersonalization={isImpersonalization}
          show={canShowSubjectAccess}
          subjectAccess={isSubjectAccess}
          token={token}
        />,
        !!(windowSize.width > 991 && isLoaded),
      )}
      {safeNode(
        <a
          className={classes.primaryButton}
          href={ns(
            () => window?.env?.epLinkPure,
            'https://ep.dovera.sk/Login.aspx?ReturnUrl=%2f',
          )}
          id="header-oldEP-button"
          rel="noopener noreferrer"
          target="_self"
        >
          <Button className="no-mrg" size="xs" type="ep">
            {(isSubjectAccess || isImpersonalization) && windowSize.width < 1420
              ? 'Prejsť do EP'
              : 'Prejsť do elektronickej pobočky'}
          </Button>
        </a>,
        !!(!isASOC && windowSize.width > 991 && isLoaded),
      )}
      {safeNode(
        <MessagesCount />,
        !!(windowSize.width <= breakpoints.l && vztahId && isLoaded),
      )}
      {hamburger}
    </>
  );

  return (
    <DDLHeader
      className={classes.dlekHeader}
      logoArea={logoArea}
      {...other}
      /* eslint-enable */
    />
  );
};

export default Header;
