import { useEffect, useRef, useState } from 'react';
import { ButtonLink, Input } from '@dovera/design-system';
import { cx } from '../../utils/exports';
import { isElement } from '../../utils/object.utils';
import IconAngle from '../CustomIcons/IconAngle';
import useStyles from './SelectOdbornosti.styles';
import { useQuery } from '../../hooks/useQuery';
import { OdbornostHodnoteneho } from '../../types/poskytovatel.types';
import { CustomTooltip } from '../CustomTooltip';

interface Props {
  data: {
    kodOdbNz: string;
    kodTypZS: string;
    nazov: string;
    predvolena: boolean;
  }[];
  defaultValue?: string;
  expertise: OdbornostHodnoteneho;
  labelText: string;
  onChange: (
    newValue: string,
    kodOdbNz: string,
    kodTypZS: string,
    userAction: boolean,
    autoChange?: boolean,
  ) => void;
  placeholder?: string;
}

const SelectOdbornosti = ({
  data,
  defaultValue,
  expertise,
  labelText,
  onChange,
  placeholder,
}: Props) => {
  const selectRef = useRef(null);
  const [activeChoices, setActiveChoices] = useState(false);
  const [selected, setSelected] = useState<{
    kodOdbNz: string;
    kodTypZS: string;
    nazov: string;
  }>({ kodOdbNz: '', kodTypZS: '', nazov: '' });
  const classes = useStyles();
  const query = useQuery();

  const nz = query.get('nz');
  const typZS = query.get('kodtypzs');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectRef.current &&
        // @ts-ignore
        !selectRef.current.contains(event.target)
      ) {
        setActiveChoices(false);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  useEffect(() => {
    if (expertise) {
      setSelected({
        kodOdbNz: expertise.kodOdbNz,
        kodTypZS: expertise.kodTypZS,
        nazov: `${expertise.nazovOdbNz}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertise]);

  useEffect(() => {
    if (nz && typZS) {
      const expertise = data.find(
        (d) => d.kodOdbNz === nz && (!typZS || typZS === d.kodTypZS),
      );
      if (expertise) {
        setSelected({
          kodOdbNz: expertise.kodOdbNz,
          kodTypZS: expertise.kodTypZS,
          nazov: expertise.nazov,
        });
        onChange(
          expertise.nazov,
          expertise.kodOdbNz,
          expertise.kodTypZS,
          false,
          true,
        );
      }
    }
    // eslint-disable-next-line
  }, [data, nz, typZS]);

  const inputWidth =
    // @ts-ignore
    (isElement(selectRef.current) &&
      parseInt(
        // @ts-ignore
        window.getComputedStyle(selectRef.current).width?.toString(),
        10,
      )) ||
    300;
  // 9 is leterPixels
  const maxLetters = Math.round((inputWidth - 20) / 9);

  const changeInputValues = (selectedItem: string): string =>
    selectedItem && selectedItem.length > maxLetters
      ? `${selectedItem.substr(0, maxLetters)}...`
      : selectedItem;

  const renderChoices = (
    <div className={cx(classes.choices, activeChoices && 'activeArea')}>
      {data.map((item) => (
        <div key={`${item.nazov}-${item.kodOdbNz}-${item.kodTypZS}`}>
          <ButtonLink
            className="no-mrg text-left"
            onClick={() => {
              setTimeout(() => {
                setSelected({
                  kodOdbNz: item.kodOdbNz,
                  kodTypZS: item.kodTypZS,
                  nazov: item.nazov,
                });
                onChange(item.nazov, item.kodOdbNz, item.kodTypZS, true);
                setActiveChoices(false);
              }, 50);
            }}
            size="s"
          >
            {item.nazov}
          </ButtonLink>
        </div>
      ))}
    </div>
  );

  const renderInput = (
    <Input
      addonsInside
      className="unselectable"
      crossOrigin
      isReadonly
      label={labelText}
      placeholder={placeholder}
      rightAddons={<IconAngle id="icon-select-expertise" />}
      value={defaultValue || changeInputValues(selected.nazov)}
    />
  );

  const renderSelected =
    selected?.nazov?.length > maxLetters ? (
      <CustomTooltip dialog={selected.nazov} id="expertise-tooltip">
        {renderInput}
      </CustomTooltip>
    ) : (
      renderInput
    );

  return (
    <div
      ref={selectRef}
      className={cx(classes.root, activeChoices && classes.activeChoices)}
      id="select-expertise"
    >
      <span
        onClick={() => setActiveChoices(!activeChoices)}
        onKeyPress={() => setActiveChoices(!activeChoices)}
        role="button"
        tabIndex={0}
      >
        {renderSelected}
      </span>
      {renderChoices}
    </div>
  );
};

export default SelectOdbornosti;
