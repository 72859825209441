/* eslint-disable */
import React from 'react';
import { cx } from '../../utils/exports';
import { Icon } from '@dovera/design-system';

interface IconProps {
  alt?: string;
  pathFill?: string;
  size?: Icon['size'];
}

type Props = IconProps & React.JSX.IntrinsicElements['svg'];

export const IconRatingStarEmptyPNG = ({
  alt = '',
  className,
  size,
}: Props) => (
  <img
    alt={alt ? 'icon-star-empty' : ''}
    className={cx('icon', { [`icon--${size}`]: size }, className)}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAEDklEQVRYhe2WTWxUVRTHf+e+KdMKyIcfoRVKE8ICCn4kFlZITATsSrbK3qTSZ9+jzLRqiDWRUNppZ+rQlFSjxi1x4QZrNMGPuGgbohglmhSDFdsElRZqpR/v3eNiprVKZ6ad6sZ4Vi/3/s///M+595z74H9bgR31m6qP+k3VK+GIrMTZiPVQUeDZYjmkWEfXfeE+IsEPAASRren0qZ+L4THFClBn9jmgDCgjEtQVy1NUBVzXjRIpvQpsyi5dn1i3euvbLS1Ty+UqrgJO6RFgE8IgwiBw/9rxyWeKoSpOgNAAIKqdWJLZtWMUUdFlC3jejz0JPKjw09TkrXfvWb/6HDAMVNc3NB381wUo+ACCdPX29s62tLQEqnQDiBP6y+XLOwd8398Yhk6FNbpFMOUI24ADoL8FUeeNOVxY6rwemQ5OoHLQ9WMnUa4odtRY+dFxwpFkMnkjVwwB8DxvfSglMYFKK1IpquXAZjJttojpmXQy4S5ccf3jaZD6HHFuA9dUZNSoDisMOzrbnkqlxiMAqVRq3PXiF1X0mKiWLnC8BVwDRlBGEK6BjBpbcu7vEYxd9ao1wXeg5SibESqAimwidwPbRXW7whQqR1Kp1Ph8Bf7MIrYPeA/YoNAfRp3antbWsVzlW4rVNTdvcKbD9wX2AmPAU+lk+2dz+3e0jdvYuBNr+oAtwGVjTW1X1+nhYoLXx+MVMmvPgzwEMopIbbrz9KWFmEX7NuOofcBulBGjWtvVlfhqOcE9L74jFO0DKoFvjTWHFktk0TY809Y2EkSd/cDnCBXWyMfZ41mSNTQc3xuKfgpUIgwSRB7LVcWcc6CntXUs6ughhfPABuCDpYhw/dg+a+QCcK/C+ajRx/O9lHkHUSKRmJyZvHkYdAgoE2FtIQFZTBno0MzkzcOJRGIyH36Jk1AeALRE7EAhZDgd6QcUZPNSmAsKKLlr3W4yGV3p6Oj4pRC+u/vUryjfA6XRNWt2FcIX/CUTkT2ZhGRw4Xq2XTMvobF+uqPj8vymYQBlm2pkL/DFigQY7B5FUKUfMoMlMh00YcUHVgFgzaV6P/Zm4OiJs4nEddABkKdFtAY4mzfBQgJcP/Y1UG2t3W8ceRiVl4GNQKBCD4AoddlkbiD6ig31S2PMJ8A36WR73mPIKyAej6+9PavjZO7KVaAqG/BDdaw3V3a3sXGnhCalwoGs6xzWlpXI+ra2tolcMfIewe8ztkZE5i5qFeiQiryYTrb/5THKCjno+sefUOQ1gR3ZLTMV8ChwIVeMvF0gxtRkPycQaSaY3nWms/2Ol3BeSDLxkQRTjyDSDEwAKOzJFyNvBUS1BvQto8FLqWRqNB92XkQ6PQ2c9jzvHSuRkyg1BZ1ymec1VxXt/A9y/LftD1Lmh+ERRDzjAAAAAElFTkSuQmCC"
  />
);

export const IconRaitingStarFullPNG = ({
  alt = '',
  className,
  size,
}: Props) => (
  <img
    alt={alt ? 'icon-star-full' : ''}
    className={cx('icon', { [`icon--${size}`]: size }, className)}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAC1ElEQVRYhb2Wz0sUcRjGP+/sagiam7sbrPYTKpK8FBYYhVZumwgelA5BIXkJ+yu6dvLSSYigiwc7BEGbu3sxgqhLl6AsJCl/hONvg7Jx9+2wa2ztzGwza74wDDPf932eZ5535v0OVBA6nziiK5cPV4IRrKQYyT1ikxWgwzeE30JduHQajNcFmGaJpN77wTH8CgBjoEjODb8ovhzQL2011NTOAqHCrRnCoYMio1mvWP4cqKntKyIHaMJcSfiB8tuCm6VIMmCTVzY8t0CXrxwim52kVPxPrOA+iSVNL3jeHdjMDjjUVVOdve4VzpMA1TsGQr9Lguc2eHNg6UUcOOCS0aILiTNeIEUXOpuBJsQIQa4ONepAa4HdCCFU6kAL9+Q4ECuDOQ18AFmH3Lf8mVWQ1d/Xousoq+QCU6Jm3ESIeFG9baF8MiBwDnRi59llkoD0GBJ9NkFV4CyQ3jlunmMF2qQh9dYAkPqxJcKhLuDu/yfXYRqWOrfmRckgUjN+DeE+ULPN1BuIDko48+APPXaZOt95EkMe4/7JeYlZkF6JpF79vWA7B2Rv5g1WsBUYr5xbXqJGqx25owAAiSVNloMJhBH/3IywHLgg0bE5pxTXSShHkxuArz+dQrwrYDjGP4xi7axAQNla1+1YzZ465PsiUOVTgIUEIxJOrjkllHHgR3sF5ABV5KzzbgnuAqQi+7coXDHKvQNuxWugt/MHjhYjGncjcHwH1Iw3Ikzb5ghPER2UhsznfG4ihuTuAb32LMH9Ek5O2y05OyAStyGfB+2XcLp7ixxAomNzEkn3odIDzJQ+jXXRicalBSX9H6XaOiGRzENHzdHUEwJWC6LDgBatOLbSfi9QhMX4DPm/nymEWxJOp5zF2mCY8XaEYeAYyFfCqUaRYlH5sHdgpaMeaEB1iE1p8UoOINH0OJtyCtUh0D2sdofKVxU/wceuXV5JHbH0arXT2i/miNa9nHKK5wAAAABJRU5ErkJggg=="
  />
);

export const IconRatingStarEmpty = ({ className, size, ...other }: Props) => (
  <svg
    className={cx({ [`icon--${size}`]: size }, className)}
    fill="none"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      d="M14.0427 1.86748L17.265 9.19911L18.1805 8.79675L17.265 9.19912C17.5654 9.88244 18.2212 10.3417 18.966 10.3902L26.9576 10.9106L20.9806 16.2407C20.4235 16.7375 20.1894 17.5032 20.3734 18.2266L22.348 25.9878L15.4317 21.9504C14.7871 21.5741 13.9866 21.5881 13.3555 21.9866L6.58429 26.263L8.28683 18.4375C8.44551 17.7082 8.18484 16.9512 7.61077 16.4741L1.45134 11.3558L9.41988 10.5568C10.1626 10.4823 10.802 10.0005 11.0783 9.3071L14.0427 1.86748Z"
      stroke="#6D7175"
      strokeWidth="2"
    />
  </svg>
);

export const IconRatingStarFull = ({
  className,
  pathFill = 'none',
  size,
  ...other
}: Props) => (
  <svg
    className={cx('icon', { [`icon--${size}`]: size }, className)}
    fill="none"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      d="M13.1138 1.49732C13.4421 0.673223 14.6013 0.65299 14.9582 1.46512L18.1805 8.79675C18.3307 9.13842 18.6586 9.36803 19.031 9.39228L27.0226 9.91268C27.9078 9.97033 28.2853 11.0665 27.6232 11.6569L21.6461 16.9871C21.3676 17.2355 21.2505 17.6183 21.3426 17.98L23.3172 25.7412C23.5359 26.601 22.61 27.2987 21.8439 26.8514L14.9276 22.814C14.6053 22.6259 14.205 22.6329 13.8895 22.8321L7.11826 27.1085C6.36821 27.5822 5.41856 26.9172 5.60715 26.0504L7.30969 18.2249C7.38903 17.8603 7.25869 17.4818 6.97166 17.2432L0.812227 12.1249C0.12994 11.5579 0.468889 10.4493 1.35157 10.3608L9.32011 9.56178C9.69145 9.52455 10.0112 9.28363 10.1493 8.93694L13.1138 1.49732Z"
      fill={pathFill}
    />
  </svg>
);
