import { createUseStyles } from 'react-jss';

interface StylesProps {
  lastStep?: boolean;
}

export default createUseStyles({
  root: {
    marginLeft: 25,
    paddingLeft: 42,
    paddingTop: 8,
    borderLeft: ({ lastStep }: StylesProps) =>
      lastStep ? 'none' : `2px solid rgba(31, 87, 165, 0.3)`,
  },
});
