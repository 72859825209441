interface Props {
  id: string;
}

const IconErrorNotification = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M10.5282 2.28309C10.9775 2.03014 11.4844 1.89725 12 1.89725C12.5156 1.89725 13.0225 2.03014 13.4718 2.28309C13.9211 2.53604 14.2976 2.90052 14.565 3.34136L14.5679 3.34611L23.0379 17.4861L23.046 17.4999C23.308 17.9536 23.4466 18.4679 23.448 18.9917C23.4495 19.5155 23.3138 20.0306 23.0544 20.4857C22.795 20.9408 22.4209 21.3201 21.9694 21.5857C21.518 21.8514 21.0048 21.9942 20.481 21.9999L20.47 22.0001L3.51903 22C2.99522 21.9942 2.48206 21.8514 2.03059 21.5857C1.57912 21.3201 1.20507 20.9408 0.94567 20.4857C0.686267 20.0306 0.550547 19.5155 0.552014 18.9917C0.55348 18.4679 0.692083 17.9536 0.95403 17.4999L0.962153 17.4861L9.43215 3.34612L10.29 3.85998L9.43502 3.34136C9.70243 2.90052 10.0789 2.53604 10.5282 2.28309ZM11.1462 4.37663L2.68271 18.5058C2.59753 18.6556 2.55249 18.8249 2.55201 18.9973C2.55152 19.1719 2.59676 19.3436 2.68322 19.4953C2.76969 19.647 2.89438 19.7734 3.04487 19.862C3.19404 19.9498 3.36342 19.9973 3.53642 20H20.4636C20.6366 19.9973 20.806 19.9498 20.9552 19.862C21.1057 19.7734 21.2303 19.647 21.3168 19.4953C21.4033 19.3436 21.4485 19.1719 21.448 18.9973C21.4476 18.8249 21.4025 18.6557 21.3174 18.5059L12.855 4.37862C12.8546 4.37796 12.8542 4.37729 12.8538 4.37663C12.7648 4.23058 12.6397 4.1098 12.4906 4.02586C12.3409 3.94154 12.1719 3.89725 12 3.89725C11.8282 3.89725 11.6592 3.94154 11.5094 4.02586C11.3603 4.1098 11.2353 4.23058 11.1462 4.37663Z"
      fill="#FF0000"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8Z"
      fill="#FF0000"
      fillRule="evenodd"
    />
    <circle cx="12" cy="17" fill="#FF0000" r="1" />
  </svg>
);

export default IconErrorNotification;
