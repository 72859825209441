interface Props {
  color?: string;
  height?: number;
  id?: string;
  width?: number;
}

const IconTrash = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 24}
    id={id || 'icon-trash'}
    viewBox={`0 0 ${width || 24} ${height || 24}`}
    width={width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V5H9V4C9 3.73478 9.10536 3.48043 9.29289 3.29289ZM7 5V4C7 3.20435 7.31607 2.44129 7.87868 1.87868C8.44129 1.31607 9.20435 1 10 1H14C14.7956 1 15.5587 1.31607 16.1213 1.87868C16.6839 2.44129 17 3.20435 17 4V5H19H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H19.9311L18.9997 20.0393C18.9895 20.8207 18.6747 21.568 18.1213 22.1213C17.5587 22.6839 16.7957 23 16 23H8C7.20435 23 6.44129 22.6839 5.87868 22.1213C5.32533 21.568 5.01047 20.8207 5.00026 20.0393L4.06888 7H3C2.44772 7 2 6.55228 2 6C2 5.44772 2.44772 5 3 5H5H7ZM6.07398 7L6.99746 19.9288C6.99915 19.9525 7 19.9762 7 20C7 20.2652 7.10536 20.5196 7.29289 20.7071C7.48043 20.8946 7.73478 21 8 21H16C16.2652 21 16.5196 20.8946 16.7071 20.7071C16.8946 20.5196 17 20.2652 17 20C17 19.9762 17.0008 19.9525 17.0025 19.9288L17.926 7H16H8H6.07398ZM14.9493 11.1718C15.3398 11.5623 15.3398 12.1955 14.9493 12.586L13.5353 14L14.9493 15.414C15.3398 15.8045 15.3398 16.4377 14.9493 16.8282C14.5588 17.2187 13.9256 17.2187 13.5351 16.8282L12.1211 15.4142L10.7067 16.8287C10.3161 17.2192 9.68296 17.2192 9.29244 16.8287C8.90192 16.4381 8.90192 15.805 9.29244 15.4144L10.7069 14L9.29244 12.5856C8.90192 12.195 8.90192 11.5619 9.29244 11.1713C9.68296 10.7808 10.3161 10.7808 10.7067 11.1713L12.1211 12.5858L13.5351 11.1718C13.9256 10.7813 14.5588 10.7813 14.9493 11.1718Z"
      fill={color || 'white'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconTrash;
