import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DajDataWidgetuNaRiesenieResponse,
  NaRiesenieInterface,
} from '../types/poskytovatel.types';
import { fetchDashboardSolveWidget } from '../api/poskytovatel';
import strings from '../constants/strings';

interface WidgetNaRiesenieState {
  error: string | null;
  isLoading: boolean;
  naRiesenie: NaRiesenieInterface[] | null;
  reset: boolean;
  widget: {
    nadpis: string;
    pocetNaRiesenie: number;
  } | null;
}

const initialState: WidgetNaRiesenieState = {
  error: null,
  isLoading: false,
  naRiesenie: null,
  reset: false,
  widget: null,
};

const widgetNaRiesenieSlice = createSlice({
  name: 'widgetNaRiesenie',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardSolveWidget.pending.toString(), () => ({
        ...initialState,
        isLoading: true,
      }))
      .addCase(
        fetchDashboardSolveWidget.fulfilled.toString(),
        (state, action: PayloadAction<DajDataWidgetuNaRiesenieResponse>) => ({
          isLoading: false,
          error: null,
          naRiesenie: action.payload.naRiesenie,
          reset: false,
          widget: action.payload.widget,
        }),
      )
      .addCase(fetchDashboardSolveWidget.rejected.toString(), () => ({
        ...initialState,
        error: strings.defaultError,
      }));
  },
  reducers: {
    resetWidgetNaRiesenie() {
      return {
        ...initialState,
        reset: true,
      };
    },
  },
});
export const { resetWidgetNaRiesenie } = widgetNaRiesenieSlice.actions;
export default widgetNaRiesenieSlice.reducer;
