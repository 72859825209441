import { createUseStyles } from 'react-jss';
import { color } from '../../constants';
import { breakpoints, getRem } from '@dovera/design-system';

interface StylesProps {
  guid: string;
  pzsCharLength: number;
}

export default createUseStyles({
  odbornosti: {
    display: 'inline-block',
    width: 251,
    '& input': {
      backgroundColor: '#f7f7f7 !important',
      cursor: 'pointer',
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      marginRight: getRem(90),
    },
  },
  loggedUser: ({ pzsCharLength }: StylesProps) => ({
    color: color('grey', 500),
    textAlign: 'left',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      display: 'block',
      fontWeight: 400,
      lineHeight: 1,
      fontSize: getRem(14),
      marginLeft: getRem(8),
      [`@media screen and (max-width: ${breakpoints.s}px)`]: {
        display: 'none',
      },
    },
    '& > div:last-child': {
      maxWidth: getRem(pzsCharLength > 45 ? 210 : 190),
      '&': {
        [`@media screen and (max-width: 1300px)`]: {
          display: 'none',
        },
      },
    },
  }),
  pzsProfile: ({ guid }: StylesProps) => ({
    '& .plate': {
      '& .bar__item': {
        flex: '1 1 auto',
      },
    },
    '& > button': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 0,
      '& svg': {
        color: color('grey', 500),
        marginTop: '-1px !important',
        display: guid && 'none',
      },
      '&:hover, &:focus': {
        textDecoration: 'none',
      },
    },
  }),
  userPlate: {
    backgroundColor: color('warning', 100),
    borderRadius: 100,
    width: getRem(48),
    height: getRem(48),
    display: 'flex',
    alignItems: 'center',
    color: color('warning', 600),
    wordBreak: 'break-word',
    textAlign: 'center',
    fontSize: getRem(16),
    marginRight: getRem(8),
    '& span': {
      display: 'block',
      padding: getRem(4),
      lineHeight: 1,
      textAlign: 'center',
      width: '100%',
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    '&.smaller': {
      width: getRem(32),
      height: getRem(32),
    },
  },
  pzsSwitcher: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${color('grey', 100)}`,
    padding: `0 ${getRem(16)} ${getRem(16)}`,
    '& button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left',
      maxWidth: getRem(320),
      margin: `0`,
      padding: getRem(12),
      cursor: 'pointer',
      '&:first-child': {
        marginTop: `${getRem(16)} !important`,
        marginBottom: `${getRem(4)} !important`,
      },
      '& > span': {
        width: `calc(100% - 32px)`,
        wordBreak: 'normal',
        fontWeight: 100,
        '& span': {
          fontWeight: 100,
          marginLeft: getRem(4),
        },
      },
      '& div span': {
        width: '100%',
      },
      '&:hover, &.active-option': {
        border: `1px solid ${color('success', 600)}`,
        borderRadius: 8,
        padding: getRem(11),
        '& > span': {
          '-webkitTextStroke': `0.5px ${color('black')}`,
          '& span': {
            '-webkitTextStroke': 0,
          },
        },
      },
    },
  },
  logoutButton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: getRem(12),
    width: '100%',
    marginTop: getRem(12),
    marginBottom: getRem(12),
  },
});
