interface Props {
  id?: string;
}

const IconErrorInfo = ({ id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" fill="#E91C0D" r="16" />
    <path
      clipRule="evenodd"
      d="M16.5 8C17.3284 8 18 8.74619 18 9.66667V17.3333C18 18.2538 17.3284 19 16.5 19C15.6716 19 15 18.2538 15 17.3333V9.66667C15 8.74619 15.6716 8 16.5 8Z"
      fill="white"
      fillRule="evenodd"
    />
    <circle cx="16.5" cy="22.5" fill="white" r="1.5" />
  </svg>
);

export default IconErrorInfo;
