import { UvazokLekaraAmbPZS } from '../../../../../types/poskytovatel.types';
import strings from '../../../../../constants/strings';
import { TableApp } from '@dovera/design-system';
import {
  firstCharToUpper,
  formatNumberToString,
  hotjarMasking,
  nbsp,
} from '../../../../../utils/strings.utils';
import { UvazokTyp } from '../../../../../types/api/poskytovatel';
import { formatDate } from '../../../../../utils/date.utils';

interface Props {
  data: UvazokLekaraAmbPZS[];
}

const texts = strings.profile.overeniaPrehlady.ambulanceDetail.table;

const TableAmbDoctors = ({ data }: Props) => (
  <TableApp
    caption=""
    className="table-caption--hide"
    columns={[
      { accessor: 'col1', Header: texts.labels.name },
      { accessor: 'col2', Header: texts.labels.expertise },
      { accessor: 'col3', Header: texts.labels.workingTime },
      { accessor: 'col4', Header: texts.labels.validity },
    ]}
    data={data.map((d) => ({
      col1: (
        <>
          {hotjarMasking(d.lekarKod, 'span', 'd-block')}
          {hotjarMasking(firstCharToUpper(d.lekarMeno, true), 'b')}
        </>
      ),
      col2: `${d.odbornostLekKod} - ${d.odbornostLekNazov}`,
      col3: d.uvazokHodnota
        ? nbsp(
            `${UvazokTyp[d.uvazokTyp]}: ${formatNumberToString(
              d.uvazokHodnota,
            )}`,
          )
        : '0',
      col4: (
        <>
          {d.uvazokDatumOd && (
            <span className="d-block">{`Od: ${formatDate(
              d.uvazokDatumOd,
            )}`}</span>
          )}
          {d.uvazokDatumDo && (
            <span className="d-block">{`Do: ${formatDate(
              d.uvazokDatumDo,
            )}`}</span>
          )}
        </>
      ),
    }))}
    data-testid="table-amb-doctors"
  />
);

export default TableAmbDoctors;
