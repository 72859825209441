import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  dajDetailPrehladuDiagnozApi,
  dajSkupinyDiagnozPreFilterApi,
  fetchDiagnoses,
} from '../api/diagnozy';
import { AppThunk } from '../store';
import {
  DajDetailPrehladuDiagnozPayload,
  DajPrehladDiagnozPayload,
  DajSkupinyDiagnozPreFilterPayload,
  DetailPrehladuDiagnoz,
  DiagnozyState,
  PrehladDiagnozDetail,
  SkupinaDiagnoz,
} from '../types/diagnozy.types';
import strings from '../constants/strings';

export const initialState: DiagnozyState = {
  detail: {
    data: null,
    error: null,
    isLoading: false,
  },
  prehladDiagnoz: {
    data: null,
    error: null,
    filterPrehladu: {
      idLekarOsoba: null,
      idVZ: 0,
      kodObdobie: 'R',
      kodOdbNz: '020',
      kodPodsegmentZs: null,
      kodSKupinaDiagnoz: null,
      kodSegmentZs: null,
      kodTypVykonavatelZs: null,
      kodTypZs: '',
      vyhladavanie: '',
    },
    isLoading: false,
    oblastFilter: 'SR',
    povolenyFilterNakladov: {
      L: false,
      S: false,
      V: false,
      Z: false,
    },
  },
  skupinyDiagnoz: {
    error: null,
    filter: null,
    isLoading: false,
  },
};

const diagnozySlice = createSlice({
  name: 'prehladPacientov',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiagnoses.pending.toString(), (state, action: any) => {
        const reqData = action?.meta?.arg;
        return {
          ...state,
          prehladDiagnoz: {
            ...state.prehladDiagnoz,
            data: null,
            error: null,
            filterPrehladu: reqData,
            isLoading: true,
            oblastFilter: 'SR',
          },
        };
      })
      .addCase(
        fetchDiagnoses.fulfilled.toString(),
        (
          state,
          action: PayloadAction<{ prehladDiagnoz: PrehladDiagnozDetail[] }>,
        ) => ({
          ...state,
          prehladDiagnoz: {
            ...state.prehladDiagnoz,
            data: action.payload.prehladDiagnoz,
            isLoading: false,
            povolenyFilterNakladov: !state.prehladDiagnoz.filterPrehladu
              .kodSegmentZs
              ? {
                  L: action.payload.prehladDiagnoz.some(
                    (p) => p.kodSegmentZs === 'L',
                  ),
                  S: action.payload.prehladDiagnoz.some(
                    (p) => p.kodSegmentZs === 'S',
                  ),
                  V: action.payload.prehladDiagnoz.some(
                    (p) => p.kodSegmentZs === 'V',
                  ),
                  Z: action.payload.prehladDiagnoz.some(
                    (p) => p.kodSegmentZs === 'Z',
                  ),
                }
              : state.prehladDiagnoz.povolenyFilterNakladov,
          },
        }),
      )
      .addCase(fetchDiagnoses.rejected.toString(), (state) => ({
        ...state,
        prehladDiagnoz: {
          ...state.prehladDiagnoz,
          data: null,
          error: strings.defaultError,
          isLoading: false,
        },
      }));
  },
  reducers: {
    dajSkupinyDiagnozPreFilterStart(state) {
      return {
        ...state,
        skupinyDiagnoz: {
          ...state.skupinyDiagnoz,
          error: null,
          filter: null,
          isLoading: true,
        },
      };
    },
    dajSkupinyDiagnozPreFilter(
      state,
      action: PayloadAction<{ skupinyDiagnozPreFilter: SkupinaDiagnoz[] }>,
    ) {
      return {
        ...state,
        skupinyDiagnoz: {
          ...state.skupinyDiagnoz,
          filter: action.payload.skupinyDiagnozPreFilter,
          isLoading: false,
        },
      };
    },
    dajSkupinyDiagnozPreFilterChyba(state, action: PayloadAction<any>) {
      return {
        ...state,
        skupinyDiagnoz: {
          ...state.skupinyDiagnoz,
          error: action.payload,
          filter: null,
          isLoading: false,
        },
      };
    },
    dajDetailPrehladuDiagnozStart(state) {
      return {
        ...state,
        detail: {
          data: null,
          error: null,
          isLoading: true,
        },
      };
    },
    dajDetailPrehladuDiagnoz(
      state,
      action: PayloadAction<{ detailPrehladuDiagnoz: DetailPrehladuDiagnoz[] }>,
    ) {
      return {
        ...state,
        detail: {
          ...state.detail,
          data: action.payload.detailPrehladuDiagnoz
            ? action.payload.detailPrehladuDiagnoz
            : [],
          isLoading: false,
        },
      };
    },
    dajDetailPrehladuDiagnozChyba(state, action: PayloadAction<any>) {
      return {
        ...state,
        detail: {
          data: null,
          error: action.payload,
          isLoading: false,
        },
      };
    },
    zmenOblastFilter(state, action: PayloadAction<'SR' | 'VUC' | string>) {
      return {
        ...state,
        prehladDiagnoz: {
          ...state.prehladDiagnoz,
          oblastFilter: action.payload,
        },
      };
    },
    zmenFilter(state, action: PayloadAction<DajPrehladDiagnozPayload>) {
      return {
        ...state,
        prehladDiagnoz: {
          ...state.prehladDiagnoz,
          filterPrehladu: {
            ...action.payload,
            kodTypVykonavatelZs: action.payload.kodTypVykonavatelZs,
            kodTypZs: action.payload.kodTypZs,
            vyhladavanie: action.payload.vyhladavanie || '',
          },
        },
      };
    },
    odstranDetail(state) {
      return {
        ...state,
        detail: {
          ...state.detail,
          data: null,
        },
      };
    },
    dajInitStavDiagnoz() {
      return initialState;
    },
  },
});

export const {
  dajDetailPrehladuDiagnoz,
  dajDetailPrehladuDiagnozChyba,
  dajDetailPrehladuDiagnozStart,
  dajInitStavDiagnoz,
  dajSkupinyDiagnozPreFilter,
  dajSkupinyDiagnozPreFilterChyba,
  dajSkupinyDiagnozPreFilterStart,
  odstranDetail,
  zmenFilter,
  zmenOblastFilter,
} = diagnozySlice.actions;
export default diagnozySlice.reducer;

export const fetchSkupinyDiagnozPreFilter =
  (payload: DajSkupinyDiagnozPreFilterPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajSkupinyDiagnozPreFilterStart());
      const response = await dajSkupinyDiagnozPreFilterApi(payload);
      dispatch(dajSkupinyDiagnozPreFilter(response));
    } catch (err) {
      dispatch(dajSkupinyDiagnozPreFilterChyba(err));
    }
  };

export const fetchDetailPrehladuDiagnoz =
  (payload: DajDetailPrehladuDiagnozPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajDetailPrehladuDiagnozStart());
      const response = await dajDetailPrehladuDiagnozApi(payload);
      dispatch(dajDetailPrehladuDiagnoz(response));
    } catch (err) {
      dispatch(dajDetailPrehladuDiagnozChyba(err));
    }
  };
