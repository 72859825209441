import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: getRem(24),
    height: 'calc(100vh - 56px)',
    '& img': {
      width: '100%',
      maxWidth: getRem(320),
    },
    '& .card': {
      height: '100%',
      justifyContent: 'center',
    },
    '& h4': {
      marginTop: getRem(50),
    },
    '& button': {
      width: getRem(162),
    },
  },
});
