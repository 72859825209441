import { color } from '@dovera/design-system';
import { cx } from '../../../utils/exports';
import { IconError, IconSuccess } from '../../CustomIcons';
import useStyles from '../CompareChart.styles';
import { Status } from '../../../types/parameters.types';

interface Props {
  status?: Status;
  text?: string;
}

const LineStatus = ({ status, text }: Props) => {
  const classes = useStyles({});
  let icon = (
    <IconError height={16} id={`compare-chart--icon-error`} width={16} />
  );
  if (status === 'success' || status === 'warning')
    icon = (
      <IconSuccess
        color={color('success')}
        height={16}
        id={`compare-chart--icon-success`}
        width={16}
      />
    );
  return (
    <span
      className={cx(
        classes.valueLabel,
        status === 'warning' ? 'success' : status,
      )}
    >
      {icon}
      {` ${text}`}
    </span>
  );
};

export default LineStatus;
