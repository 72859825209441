import {
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import _, { debounce } from 'lodash';
import { Input, Textarea } from '@dovera/design-system';

import useAutocompleteState from './useAtocompleteState';
import SuggestionItem from './SuggestionItem';
import SuggestionsMenu from './SuggestionsMenu';
import useStyles from './styles';
import { useCombinedRefs } from '../../utils/ref.utils';
import {
  useFocus,
  useKeyboard,
  useLazyCallback,
} from '../../utils/event.utils';
import { Choice } from 'choices.js';
import { useDebounce } from '../../hooks/useDebounce';
import { setTextareaHeight } from './utils';
import { getAutocompleteAddon } from './nodes';
import { removeLineBreaks } from '../../utils/strings.utils';

export type AutocompleteProps = {
  _ref?: Ref<HTMLInputElement>;
  delay?: number;
  id: string;
  inputClass?: any;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  minLength?: number;
  noMarginBottom?: boolean;
  notFilterOptions?: boolean;
  /* eslint-disable */
  onBlur?: () => void;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onSelect: (option: Choice | null) => void;
  outerRef?: any;
  source: (query: string) => Promise<Choice[]>;
  suggestionTemplate?: (val: any) => ReactNode;
  /* eslint-enable */
} & Input;

const Autocomplete = ({
  _ref,
  className,
  delay = 0,
  error,
  id,
  inputClass,
  isDisabled,
  label,
  minLength = 2,
  noMarginBottom,
  notFilterOptions = false,
  onBlur,
  onChange,
  onFocus,
  onSelect,
  source = async () => [],
  suggestionTemplate,
  value,
  ...other
}: AutocompleteProps) => {
  const classes = useStyles({ noMarginBottom });
  const [isFocused, setIsFocused] = useState(false);
  const innerRef = useRef<HTMLInputElement>(null);
  const inputRef = useCombinedRefs<HTMLInputElement>(innerRef, _ref);
  const [suggestionsPresented, setSuggestionsPresented] =
    useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const [state, actions] = useAutocompleteState();

  const debouncedInput = useDebounce(state.value, delay);

  const hasValidInput = debouncedInput?.length >= minLength;
  const hasSelectedOption = state.selectedOption !== null;

  const hasFocus = useFocus(containerRef, onFocus, onBlur);

  const sourceLazy = useLazyCallback(source);

  const debouncedSource = useMemo(
    () =>
      debounce(sourceLazy, delay, {
        leading: true,
        trailing: true,
      }),
    [delay, sourceLazy],
  );

  const performQuery = useCallback(
    async (query: string) => {
      actions.startQuery();
      const src = Promise.resolve(debouncedSource(query));
      await src.then((options) => {
        actions.finishQuery({ options, query, notFilterOptions });
      });
    },
    [actions, debouncedSource, notFilterOptions],
  );

  useEffect(() => {
    setIsFocused(!hasSelectedOption && hasFocus);
  }, [hasSelectedOption, hasFocus]);

  useEffect(() => {
    if (isFocused && hasValidInput) {
      performQuery(debouncedInput);
    }
  }, [performQuery, hasValidInput, isFocused, debouncedInput]);

  useEffect(() => {
    if (isFocused) {
      onChange(state.value);
    }
  }, [onChange, state.value, isFocused]);

  const onSelectLazy = useLazyCallback(onSelect);

  useEffect(() => {
    onSelectLazy(state.selectedOption);
  }, [onSelectLazy, state.selectedOption]);

  const selectHandler = useCallback(
    (option) => {
      actions.select({ option });
      inputRef.current?.focus();
    },
    [actions, inputRef],
  );

  const keydownHandler = useKeyboard({
    ArrowDown: () => actions.focusSuggestion({ indexDelta: +1 }),
    ArrowUp: () => actions.focusSuggestion({ indexDelta: -1 }),
    Escape: () => actions.cancel(),
    Enter: () => selectHandler(state.suggestions[state.suggestionFocusIndex]),
    Tab: () => true,
    default: () => {
      inputRef.current?.focus();
      return true;
    },
  });

  const noMatchHint = useMemo(
    () => (
      <SuggestionItem
        isDisabled
        option={{ label: 'Nenašli sa žiadne výsledky', value: '' }}
      />
    ),
    [],
  );

  const suggestions = useMemo(() => {
    if (!state.suggestions?.length && state.showNoMath) return noMatchHint;
    return state.suggestions?.map((option, index) => (
      <SuggestionItem
        key={`${option?.label}_${option.id}`}
        aria-posinset={index + 1}
        highlight={state.value}
        isFocused={index === state.suggestionFocusIndex}
        onSelect={(selectedOption) => {
          selectHandler(selectedOption);
          setSuggestionsPresented(false);
        }}
        option={option}
        template={suggestionTemplate}
      />
    ));
  }, [noMatchHint, state, selectHandler, suggestionTemplate]);

  useEffect(() => {
    setTimeout(() => {
      setSuggestionsPresented(
        !!(hasFocus && hasValidInput && !state.isLoading && !hasSelectedOption),
      );
    }, 100);
  }, [hasFocus, hasSelectedOption, hasValidInput, state.isLoading]);

  useEffect(() => {
    if (!value && state.value?.length > 1) actions.init();
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    setTextareaHeight(id, state.value);
  }, [id, state.value]);

  const loaderPresented = hasFocus && hasValidInput && state.isLoading;

  return (
    <div
      ref={containerRef}
      className={cx(className, classes.wrapper, {
        wrapperDisabled: isDisabled,
        'is-success': hasSelectedOption,
        'init-height': state.value?.length < 35,
      })}
      onKeyDownCapture={keydownHandler}
    >
      <Textarea
        //  @ts-ignore
        ref={inputRef}
        addonsInside
        aria-autocomplete="list"
        aria-expanded={!!suggestionsPresented}
        aria-owns={`${id}-results`}
        autoComplete="off"
        className={inputClass}
        error={error}
        id={id}
        isDisabled={isDisabled}
        // isSuccess={hasSelectedOption}
        label={label}
        onFocus={() => {
          if (_.isArray(suggestions) && suggestions?.length)
            setTimeout(() => setSuggestionsPresented(true), 100);
        }}
        // eslint-disable-next-line
        onChange={({ target }) =>
          actions.input({ value: removeLineBreaks(target.value) })
        }
        role="combobox"
        tabIndex={0}
        value={state.value || value}
        // eslint-disable-next-line
        {...other}
        rightAddons={getAutocompleteAddon(loaderPresented, other?.rightAddons)}
      />
      <SuggestionsMenu active={suggestionsPresented} hasError={!!error} id={id}>
        {suggestions}
      </SuggestionsMenu>
    </div>
  );
};

export default Autocomplete;
