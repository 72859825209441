import { useCallback, useEffect, useState } from 'react';
import AppRating from './AppRating/AppRating';
import RatingOptions from './RatingOptions/RatingOptions';
import strings from '../../constants/strings';
import useStyles from './Feedback.styles';
import IconClose from '../CustomIcons/IconClose';
import { useSelector } from 'react-redux';
import {
  resetFeedbackForm,
  setRating,
  setRatingOptions,
  setText,
  toggleFeedbackForm,
} from '../../slices/feedback.slices';
import { RootState } from '../../rootReducer';
import { Button, Notification } from '@dovera/design-system';
import { LoadingState } from '../../types';
import { UlozSpatnuVazbuPayload } from '../../types/api/poskytovatel';
import { sendFeedback } from '../../api/poskytovatel';
import { textWithAction } from '../../utils/strings.utils';
import { useAppDispatch } from '../../hooks/useStore';
import { getUserId } from '../../utils/auth.utils';
import {
  isFeedbackValid,
  isTextMissed,
  isTextareaVisible,
  setTriggerQuarantine,
} from '../../utils/feedback.utils';
import { safeNode } from '../../utils/app.utils';
import { getPZS } from '../../slices/auth.slice';

const texts = strings.feedbacker;

const Feedback = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const { dataState, error, rating, ratingOptions, text, trigger } =
    useSelector((state: RootState) => state.feedback);
  const key: 'standard' | 'trigger' = trigger.show ? 'trigger' : 'standard';
  const [feedbackError, setFeedbackError] = useState('');
  const [textError, setTextError] = useState('');
  const isSubmitted: boolean = ![
    LoadingState.none,
    LoadingState.fetching,
  ].includes(dataState);
  const submitBtnCallback = useCallback(() => {
    setFeedbackError('');
    setTextError('');
    if (!isFeedbackValid(rating, ratingOptions)) {
      setFeedbackError(texts.errors.validation);
      return null;
    }
    if (isTextMissed(rating, ratingOptions, text)) {
      setTextError(texts.errors.validationText);
      return null;
    }
    const payload: UlozSpatnuVazbuPayload = {
      email: null,
      hodnotenie: rating,
      idPouzivatel: pzs.userId,
      idVztahu: pzs.vzId,
      sprava: isTextareaVisible(ratingOptions, trigger.show) ? text : null,
      typyHodnotenia: ratingOptions
        .filter((r) => r.checked)
        .map((o) => o.value),
      url: trigger.show ? trigger.url : window.location.href,
    };
    return dispatch(sendFeedback(payload));
  }, [dispatch, pzs, rating, ratingOptions, text, trigger.show, trigger.url]);
  useEffect(() => {
    if (dataState === LoadingState.filled && trigger.show)
      setTriggerQuarantine(Number(pzs.userId), true);
  }, [dataState, pzs.userId, trigger.show]);
  const renderHeader = (
    <div className={classes.header}>
      <h6 className="no-mrg">{texts[key].feedbackButton}</h6>
      <button
        id="feedback-close-btn"
        onClick={() => dispatch(toggleFeedbackForm({}))}
        type="button"
      >
        <IconClose
          color="white"
          height={24}
          id="feedback-close-btn--icon"
          width={24}
        />
      </button>
    </div>
  );
  const renderButton = safeNode(
    <div className={classes.submitBtn}>
      <Button
        className="no-mrg"
        isLoading={dataState === LoadingState.fetching}
        onClick={submitBtnCallback}
      >
        {texts[key].secondStep.submit}
      </Button>
    </div>,
    ratingOptions?.length > 0,
  );
  const renderForm = safeNode(
    <>
      <AppRating
        onChange={(rate) => {
          dispatch(setRating({ rating: rate }));
        }}
        // eslint-disable-next-line
        onClose={(quarantine: boolean) =>
          dispatch(
            toggleFeedbackForm({
              setQuarantine: quarantine,
              userId: getUserId(pzs.userId),
            }),
          )
        }
        rating={rating}
        textKey={key}
      />
      <RatingOptions
        checkboxesError={feedbackError}
        onChangeOption={(index: number) => {
          dispatch(setRatingOptions({ index }));
          setFeedbackError('');
        }}
        onChangeText={(str: string) => {
          dispatch(setText({ text: str }));
          setTextError('');
        }}
        rating={rating}
        ratingOptions={ratingOptions}
        textError={textError}
        textKey={key}
      />
      {renderButton}
    </>,
    !isSubmitted,
  );
  const renderResult = safeNode(
    <div className={classes.result}>
      {error ? (
        <Notification
          className="no-mrg-bottom"
          message={textWithAction(
            texts[key].lastStep.error,
            () => dispatch(resetFeedbackForm()),
            'error',
          )}
          variant="error"
        />
      ) : (
        <h6 className="no-mrg">{texts[key].lastStep.success}</h6>
      )}
    </div>,
    isSubmitted,
  );
  const renderContent = (
    <div className={classes.content}>
      {renderForm}
      {renderResult}
    </div>
  );
  return (
    <div className={classes.feedback} data-testid="feedback-container">
      {renderHeader}
      {renderContent}
    </div>
  );
};

export default Feedback;
