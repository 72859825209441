/* eslint-disable */
import { Component } from 'react';
import AppError from '../../pages/app-error/AppError';
import AppErrorIE from '../../pages/app-error/AppErrorIE';
import { isIE } from '../../utils/app.utils';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to trigger fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error or send logging data to log management tool
    console.log(error, errorInfo);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // Render fallback UI
      if (isIE) {
        return <AppErrorIE />;
      }
      return <AppError />;
    }

    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
