import { VALIDATION } from '../types/validation.types';

export const errorCodes = {
  101: VALIDATION.ForbiddenFileType,
  301: VALIDATION.MaximalnaSumaFaktury,
  601: VALIDATION.ChybnaKombinaciaVstupnychUdajov,
  602: VALIDATION.ChybaPriZiskavaniDokumentuDms,
  603: VALIDATION.ChybaPriZiskavaniDokumentuTxs,
  604: VALIDATION.NepodporovanyTypDavky,
  605: VALIDATION.ChybnaSyntax,
  606: VALIDATION.OpravnaDavkakNeexistujucejDavke,
  607: VALIDATION.DuplicitnaDavka,
  608: VALIDATION.ChybnaSemantika,
  609: VALIDATION.NenajdeneDavkyPodlaIdSkupiny,
  610: VALIDATION.ChybaPriZiskavaniProtokoluTxs,
  611: VALIDATION.ChybaPriZiskavaniFakturApm,
  612: VALIDATION.DavkaNepatriPoskytovatelovi,
  613: VALIDATION.NenajdeneDavky,
  614: VALIDATION.NenajdeneZmluvy,
  615: VALIDATION.ChybaPriPotvrdzovaniFakturyApm,
  616: VALIDATION.MaxVelkostDavky,
  617: VALIDATION.PovoleneTypyPriloh,
  618: VALIDATION.MaximalnaVelkostPrilohSpolu,
  619: VALIDATION.ChybneDavky,
  621: VALIDATION.NepovolenyVariabilnySymbol,
  622: VALIDATION.NepripojenaFaktura,
  623: VALIDATION.NepripojenyDokumentOdovodnenia,
  624: VALIDATION.ChybneRozdelenie,
  625: VALIDATION.ChybneObdobie,
  626: VALIDATION.ChybaPriUkladaniDokumentovFaktury,
  627: VALIDATION.ChybaPriParovaniFaktury,
  628: VALIDATION.NesmieBytPripojenaFaktura,
  629: VALIDATION.NiesuVyplneneUdajeFaktury,
  630: VALIDATION.NenajdenyDokumentFaktury,
  631: VALIDATION.ChybaPriParovaniDavky,
  710: VALIDATION.NenajdeneDavkyCL,
  711: VALIDATION.ChybneIdOdoslaniaCL,
  750: VALIDATION.ChybnyTypDavkyCL,
  751: VALIDATION.ChybnaPrislusnostDavkyCL,
  752: VALIDATION.ChybnaChronologiaCL,
  753: VALIDATION.DuplicitaDavokCL,
  754: VALIDATION.FutureBatchCL,
  755: VALIDATION.NeplatneCisloUtvaruCL,
  760: VALIDATION.NespravnyCharakterCL,
  761: VALIDATION.NespravnyTypCL,
  762: VALIDATION.NespravnyFormatIcoCL,
  763: VALIDATION.NespravnyFormatDatumCL,
  764: VALIDATION.NespravnyFormatPoradieCL,
  765: VALIDATION.NespravnyFormatDokladyCL,
  766: VALIDATION.NespravnyKodZPCL,
  767: VALIDATION.NespravnyPocetPipeCL,
  768: VALIDATION.NevalidnySuborCL,
  769: VALIDATION.DavkuCLNiejeMozneVytvoritPred2024,
};
