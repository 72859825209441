interface Props {
  color?: string;
}

const IconParameterEffectivity = ({ color }: Props) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" fill={color || '#42D848'} r="16" />
    <path
      d="M24.2712 15.9407C24.2712 13.9337 23.5415 11.9951 22.2182 10.4861C20.8949 8.97716 19.0681 8.00073 17.0782 7.73876C15.0884 7.47679 13.0711 7.94715 11.4023 9.06219"
      stroke="white"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <path
      d="M24.9961 19.0639C24.5969 19.617 23.7734 19.617 23.3743 19.0639L21.1595 15.9949C20.6822 15.3336 21.1547 14.4097 21.9703 14.4097L26.4 14.4097C27.2156 14.4097 27.6882 15.3336 27.2109 15.9949L24.9961 19.0639Z"
      fill="white"
    />
    <path
      d="M7.72885 16.7273C7.72885 18.7343 8.45848 20.6729 9.78181 22.1818C11.1051 23.6908 12.9319 24.6672 14.9218 24.9292C16.9116 25.1912 18.9289 24.7208 20.5977 23.6058"
      stroke="white"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <path
      d="M7.00393 13.608C7.40307 13.0549 8.22657 13.0549 8.6257 13.608L10.8405 16.6769C11.3178 17.3383 10.8453 18.2622 10.0297 18.2622L5.59997 18.2622C4.78436 18.2622 4.31179 17.3383 4.78909 16.6769L7.00393 13.608Z"
      fill="white"
    />
  </svg>
);

export default IconParameterEffectivity;
