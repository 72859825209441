import { color } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  subjectAccess: {
    marginLeft: 130,
    '& .choices__inner': {
      border: 'none',
    },
    '&:after': {
      marginRight: -15,
    },
    '& .choices__list.choices__list--single .choices__item--selectable': {
      color: color('primary'),
      opacity: 1,
    },
    '& .choices__list--dropdown .choices__list': {
      '& > div:first-child': {
        display: 'none',
      },
    },
  },
});
