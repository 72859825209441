import { useCallback, useEffect, useState } from 'react';
import Filter from './Filter/Filter';
import {
  DajMojeFakturyQueries,
  Faktura,
  FilterInvoiceValues,
} from '../../../types/zuctovanie.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { dajMojeFaktury } from '../../../api/zuctovanie';
import { ErrorServiceResponse, LoadingState } from '../../../types';
import strings from '../../../constants/strings';
import SortableTable from '../../../components/SortableTable/SortableTable';
import { getInvoiceState } from '../../../utils/zuctovanie.utils';
import { getPriceFormat } from '../../../utils/number.utils';
import { ButtonLink, color } from '@dovera/design-system';
import IconAngleRight from '../../../components/CustomIcons/IconAngleRight';
import { useNavigate } from 'react-router';
import routes from '../../../routes';
import { NoDataIllustration } from '../../../components/EmptyState';
import TablePreloaderSkeleton from '../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import { useAppDispatch } from '../../../hooks/useStore';
import { setSelectedInvoice } from '../../../slices/zuctovanie.slice';
import { translateErrorCode } from '../../../utils/validation.utils';
import _ from 'lodash';
import { formatDate } from '../../../utils/date.utils';
import { resetTableOrder } from '../../../slices/table.slice';
import { groupByItem } from '../../../utils/array.utils';

interface InvoicesState {
  data: Faktura[] | null;
  dataState: LoadingState;
  error: string | null;
  filteredData: Faktura[];
}

const initialState: InvoicesState = {
  data: null,
  dataState: LoadingState.none,
  error: null,
  filteredData: [],
};

const texts = strings.zuctovanie.invoices.columns;

const Faktury = () => {
  const [state, setState] = useState<InvoicesState>(initialState);
  const { filter } = useSelector(
    (state: RootState) => state.zuctovanie.invoices,
  );
  const { prevRoute } = useSelector((state: RootState) => state.app);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const detailClick = useCallback(
    (invoice: Faktura) => {
      dispatch(setSelectedInvoice({ invoice }));
      navigate(`${routes.faktury}/${invoice.cisloFaktury}`);
    },
    [dispatch, navigate],
  );

  const filterInvoices = (invoices: Faktura[], vals: FilterInvoiceValues) =>
    invoices?.filter(
      (f) =>
        (!vals.search || f.vs?.includes(vals.search)) &&
        (!vals.state || f.stavFaktury?.includes(vals.state)) &&
        (!vals.zuc20 ||
          (f.zuctovanie2 && vals.zuc20 === 'Y') ||
          (!f.zuctovanie2 && vals.zuc20 === 'N')) &&
        (!vals.paperInvoice ||
          (f.papierovaFaktura && vals.paperInvoice === 'Y') ||
          (!f.papierovaFaktura && vals.paperInvoice === 'N')) &&
        (!vals.invoiceType || f.typFaktury === vals.invoiceType) &&
        (!vals.kodPracoviska || f.kodPracoviska === vals.kodPracoviska),
    );

  const filterData = useCallback(
    ({
      invoicesData,
      values,
    }: {
      invoicesData?: Faktura[];
      values?: FilterInvoiceValues;
    }) => {
      let filteredData: Faktura[] = [];
      const vals: FilterInvoiceValues = values || filter;
      const { data } = state;
      const invoices = invoicesData || data;
      if (invoicesData) filteredData = invoicesData;
      if (invoices?.length && values) {
        filteredData = filterInvoices(invoices, vals);
      }
      setState({
        ...initialState,
        data: invoices || [],
        dataState: LoadingState.filled,
        filteredData: groupByItem(filteredData, 'cisloFaktury'),
      });
    },
    [filter, state],
  );
  const getInvoices = useCallback(
    (periods: number[]) => {
      setState({ ...initialState, dataState: LoadingState.fetching });
      const queries: DajMojeFakturyQueries = {
        obdobia: periods,
      };
      dajMojeFaktury(queries)
        .then((resp) => {
          dispatch(resetTableOrder());
          filterData({ invoicesData: resp.faktury, values: filter });
        })
        .catch((err: ErrorServiceResponse) => {
          setState({
            ...initialState,
            dataState: LoadingState.error,
            error: translateErrorCode(err?.response?.data.kod),
          });
        });
    },
    // eslint-disable-next-line
    [filter, prevRoute],
  );

  useEffect(() => {
    if (filter.periods) getInvoices(filter.periods);
    // eslint-disable-next-line
  }, [filter.periods]);

  const renderTable = state.dataState === LoadingState.filled &&
    !_.isEmpty(state.filteredData) && (
      <SortableTable
        cols={[
          {
            index: 1,
            name: texts.vs,
            isSortable: true,
            sortType: 'alphabetical',
          },
          {
            index: 2,
            name: texts.internalNumber,
            isSortable: true,
            sortType: 'alphabetical',
          },
          {
            index: 3,
            name: texts.period,
          },
          {
            index: 4,
            name: texts.state,
            isSortable: true,
            sortType: 'alphabetical',
          },
          {
            index: 5,
            name: texts.sum,
            isSortable: true,
            sortType: 'sum',
          },
          {
            index: 6,
            name: texts.delivery,
            isSortable: true,
            sortType: 'date',
          },
          {
            index: 7,
            name: '',
          },
        ]}
        noRerender
        rows={state.filteredData.map((f) => ({
          col1: {
            sortableValue: f.vs,
            value: f.vs || strings.undefined,
          },
          col2: {
            sortableValue: f.cisloFaktury,
            value: f.cisloFaktury,
          },
          col3: { value: f.obdobieFaktury.toString() },
          col4: {
            sortableValue: f.stavFaktury,
            value: getInvoiceState(f.stavFaktury, true),
          },
          col5: {
            sortableValue: f.fakturovanaSuma.toString(),
            value: getPriceFormat(f.fakturovanaSuma, true),
          },
          col6: {
            sortableValue: f.datumDorucenia,
            value: formatDate(f.datumDorucenia),
          },
          col7: {
            value: (
              <ButtonLink
                className="inline-btn d-flex"
                onClick={() => detailClick(f)}
              >
                Detail <IconAngleRight color={color('primary', 600)} />
              </ButtonLink>
            ),
          },
        }))}
        storeInRedux
      />
    );
  const renderContent = () => {
    if (state.dataState === LoadingState.error && state.error)
      return <NoDataIllustration title={state.error} />;
    if (
      state.dataState === LoadingState.filled &&
      _.isEmpty(state.filteredData)
    )
      return <NoDataIllustration title={strings.filterNoDataDefault} />;
    return [LoadingState.fetching, LoadingState.none].includes(
      state.dataState,
    ) ? (
      <TablePreloaderSkeleton columns={6} />
    ) : (
      renderTable
    );
  };
  return (
    <>
      <Filter onChange={(values) => filterData({ values })} />
      {renderContent()}
    </>
  );
};

export default Faktury;
