import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ErrorServiceResponse, LoadingState } from '../types';
import {
  CakackyState,
  DajStavKontrolCLResponse,
  DajZoznamDavokCLResponse,
  FilterBatchValues,
  SkontrolujDavkyCLResponse,
} from '../types/cakacky.types';
import {
  dajStavKontrolCL,
  dajZoznamCakaciek,
  skontrolujCLDavky,
} from '../api/cakacky';
import { translateErrorCode } from '../utils/validation.utils';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../utils/date.utils';
import { getBatchState } from '../utils/cakacky.utils';

export const initialState: CakackyState = {
  batchUpload: {
    dataState: LoadingState.none,
    error: null,
    groupId: '',
    uploadAttempts: 0,
    validated: {
      data: null,
      dataState: LoadingState.none,
      error: null,
      updatedAt: '',
    },
  },
  batches: {
    data: null,
    dataState: LoadingState.none,
    error: null,
    filter: {
      dateFrom: '',
      dateTo: '',
    },
    withoutReload: null,
    workplaces: null,
  },
};

export const cakackySlice = createSlice({
  name: 'cakacky',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(skontrolujCLDavky.pending.toString(), (state) => {
        state.batchUpload = {
          ...state.batchUpload,
          dataState: LoadingState.fetching,
          error: null,
        };
      })
      .addCase(
        skontrolujCLDavky.fulfilled.toString(),
        (state, action: PayloadAction<SkontrolujDavkyCLResponse>) => {
          state.batchUpload = {
            ...state.batchUpload,
            dataState: LoadingState.filled,
            groupId: action.payload.idSkupiny,
            uploadAttempts: state.batchUpload.uploadAttempts + 1,
          };
        },
      )
      .addCase(
        skontrolujCLDavky.rejected.toString(),
        (state, action: PayloadAction<ErrorServiceResponse>) => {
          state.batchUpload = {
            ...initialState.batchUpload,
            dataState: LoadingState.error,
            error: translateErrorCode(action.payload?.response?.data.kod),
          };
        },
      )
      .addCase(dajStavKontrolCL.pending.toString(), (state) => {
        state.batchUpload.validated.dataState = LoadingState.fetching;
      })
      .addCase(
        dajStavKontrolCL.fulfilled.toString(),
        (state, action: PayloadAction<DajStavKontrolCLResponse>) => {
          state.batchUpload = {
            ...state.batchUpload,
            validated: {
              data: action.payload.davky,
              dataState: LoadingState.filled,
              error: null,
              updatedAt: moment().format(DATE_TIME_FORMAT),
            },
          };
        },
      )
      .addCase(
        dajStavKontrolCL.rejected.toString(),
        (state, action: PayloadAction<ErrorServiceResponse>) => {
          state.batchUpload.validated = {
            ...state.batchUpload.validated,
            dataState: LoadingState.error,
            error: translateErrorCode(action.payload?.response?.data.kod),
          };
        },
      )
      .addCase(dajZoznamCakaciek.pending.toString(), (state) => {
        if (state.batches.withoutReload)
          return {
            ...state,
            batches: {
              ...state.batches,
            },
          };
        return {
          ...initialState,
          batches: {
            ...initialState.batches,
            dataState: LoadingState.fetching,
            filter: state.batches.filter,
          },
          batchUpload: {
            ...state.batchUpload,
          },
        };
      })
      .addCase(
        dajZoznamCakaciek.fulfilled.toString(),
        (state, action: PayloadAction<DajZoznamDavokCLResponse>) => {
          if (state.batches.withoutReload)
            return {
              ...state,
              batches: {
                ...state.batches,
                data:
                  action.payload.zoznamDavok?.map((d) => ({
                    ...d,
                    stav: getBatchState({
                      batch: d,
                      batches: action.payload.zoznamDavok,
                    }),
                  })) || [],
                dataState: LoadingState.filled,
                withoutReload: null,
                workplaces: action.payload.pracoviska,
              },
            };
          return {
            ...state,
            batches: {
              ...state.batches,
              data:
                action.payload.zoznamDavok?.map((d) => ({
                  ...d,
                  stav: getBatchState({
                    batch: d,
                    batches: action.payload.zoznamDavok,
                  }),
                })) || [],
              dataState: LoadingState.filled,
              workplaces: action.payload.pracoviska,
            },
          };
        },
      )
      .addCase(
        dajZoznamCakaciek.rejected.toString(),
        (state, action: PayloadAction<ErrorServiceResponse>) => {
          state.batches = {
            ...initialState.batches,
            dataState: LoadingState.error,
            error: translateErrorCode(action.payload?.response?.data.kod),
          };
        },
      );
  },
  reducers: {
    clChangeFilter(
      state,
      action: PayloadAction<{ values: FilterBatchValues }>,
    ) {
      const { dateFrom, dateTo } = action.payload.values;
      return {
        ...state,
        batches: {
          ...state.batches,
          filter: {
            dateFrom,
            dateTo,
          },
        },
      };
    },
    clSetWithoutResetPreview(
      state,
      action: PayloadAction<'detail' | 'preview'>,
    ) {
      return {
        ...state,
        batches: {
          ...state.batches,
          withoutReload: action.payload,
        },
      };
    },
    clReset() {
      return initialState;
    },
  },
});

export const { clChangeFilter, clReset, clSetWithoutResetPreview } =
  cakackySlice.actions;

export default cakackySlice.reducer;
