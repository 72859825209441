import { useEffect, useRef, useState } from 'react';
import { Input, Loader } from '@dovera/design-system';
import useStyles from './Select2.styles';
import { cx } from '../../utils/exports';
import { useDebounce } from '../../hooks/useDebounce';
import { IconMultiplication } from '../CustomIcons';
import { ns } from '../../utils/object.utils';
import { CustomTooltip } from '../CustomTooltip';
import { inlineCondition, safeNode } from '../../utils/app.utils';

export interface Select2Props {
  actualText?: string;
  data: string[];
  dataLoading: boolean;
  emptyStateText: string;
  id: string;
  label?: string;
  minimumChars: number;
  onChange?: (s: string) => void;
  onChoose?: (s: string) => void;
  onReset?: () => void;
  placeholder?: string;
  selected?: string;
  whiteBackground?: boolean;
}

const Select2 = ({
  actualText,
  data,
  dataLoading,
  emptyStateText,
  id,
  label,
  minimumChars,
  onChange,
  onChoose,
  onReset,
  placeholder,
  selected,
  whiteBackground,
}: Select2Props) => {
  const classes = useStyles({ whiteBackground });
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState(selected || '');
  const [activeChoices, setActiveChoices] = useState(false);
  const [filterData, setFilterData] = useState(data);
  const debouncedInput = useDebounce(inputValue, 1000);
  useEffect(() => {
    if (data)
      setFilterData(
        data.filter((d) => d.toLowerCase().includes(inputValue.toLowerCase())),
      );
  }, [data, inputValue]);
  useEffect(() => {
    if (actualText === '' && actualText !== inputValue) setInputValue('');
  }, [actualText, inputValue]);
  useEffect(() => {
    if (onChange) onChange(debouncedInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);
  const handleResetClick = () => {
    setInputValue('');
    setActiveChoices(false);
    if (onReset) onReset();
    if (onChange) onChange('');
    // @ts-ignore
    ns(() => inputRef.current.inputRef.current.DDL_Input.element.blur());
  };
  return (
    <div className={cx('mb-large', classes.root)} id={id}>
      <Input
        ref={inputRef}
        addonsInside
        autoComplete="off"
        className="no-mrg-bottom"
        crossOrigin
        id={`filter-patient-${id}`}
        label={label}
        onBlur={() => {
          setTimeout(() => {
            setActiveChoices(false);
          }, 500);
        }}
        onFocus={() => setActiveChoices(true)}
        // eslint-disable-next-line
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={placeholder}
        rightAddons={
          inputValue.length > 0 ? (
            <CustomTooltip dialog="Zrušiť filter" id={`tooltip-${id}`}>
              <IconMultiplication
                id="icon-select2-removeText"
                onClick={handleResetClick}
              />
            </CustomTooltip>
          ) : (
            <span />
          )
        }
        value={inputValue}
      />
      <div
        className={cx(classes.choices, activeChoices && 'active')}
        id={`filter-patient-activeChoices-${id}`}
      >
        {!filterData ||
        !filterData.length ||
        inputValue.length < minimumChars ? (
          <span>
            {safeNode(<Loader size={24} />, dataLoading)}
            {!dataLoading &&
              inlineCondition(
                inputValue.length < minimumChars,
                `Zadajte aspoň ${minimumChars} znaky.`,
                emptyStateText,
              )}
          </span>
        ) : (
          <div>
            {filterData.map((f, key) => (
              <button
                key={`select2Choice-${id}-${f}`}
                id={`filter-patient-activeChoices-${id}-choice-${key}`}
                onClick={() => {
                  setInputValue(f.includes('>') ? f.split('>')[1] : f);
                  if (onChoose && f.includes('>') && f.includes('<')) {
                    onChoose(f.split('<')[1].split('>')[0]);
                  }
                  setTimeout(() => {
                    setActiveChoices(false);
                  }, 50);
                }}
                type="button"
              >
                {f.includes('>') ? f.split('>')[1] : f}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Select2;
