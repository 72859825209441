import { useEffect, useState } from 'react';
import useStyles from '../Profil.styles';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import {
  Container,
  PageSection,
  Radio,
  RadioGroup,
} from '@dovera/design-system';
import BackButton from '../../../components/BackButton/BackButton';
import routes from '../../../routes';
import IconFlagBlue from '../../../components/CustomIcons/IconFlagBlue';
import { cx } from '../../../utils/exports';
import strings from '../../../constants/strings';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import { Outlet, useLocation, useNavigate } from 'react-router';

const texts = strings.profile;
const { baseFilter } = texts.overeniaPrehlady;

const OvereniaPrehlady = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [baseFilterValue, setBaseFilterValue] = useState(baseFilter[0].value);
  useEffect(() => {
    if (location.pathname.includes('ambulancie')) {
      setBaseFilterValue(baseFilter[1].value);
      navigate(`${routes.overeniaPrehlady}/ambulancie${location.search || ''}`);
    } else {
      navigate(`${routes.overeniaPrehlady}/lekari${location.search || ''}`);
    }
    // eslint-disable-next-line
  }, []);
  const renderTitle = (
    <h3 className={cx(classes.title, 'mb-small')}>
      <IconFlagBlue id="overenia-prehlady-title" />
      {texts.links.overeniaPrehlady}
    </h3>
  );
  const renderDescription = (
    <SafeHtml
      className="mb-large"
      html={texts.overeniaPrehlady.description}
      wrapper="div"
    />
  );
  const renderSubtitle = <h5>{texts.overeniaPrehlady.subtitle}</h5>;
  const renderBaseFilter = (
    <div className={classes.filterExportWrapper}>
      <RadioGroup
        className={classes.horizontalFilter}
        id="radio-group-overenia-prehlady"
        onChange={(e) => {
          const { value } = e.target as HTMLInputElement;
          setBaseFilterValue(value);
          navigate(
            `${routes.overeniaPrehlady}/${
              value === baseFilter[0].value ? 'lekari' : 'ambulancie'
            }`,
          );
        }}
      >
        {texts.overeniaPrehlady.baseFilter.map((b, key) => (
          <Radio
            key={`overenia-prehlady-baseFilter-${key}`}
            id={`overenia-prehlady-baseFilter-${key}`}
            isChecked={baseFilterValue === b.value}
            name="overenia-prehlady-baseFilter"
            value={b.value}
          >
            {b.text}
          </Radio>
        ))}
      </RadioGroup>
    </div>
  );
  return (
    <StaticMenuLayout backgroundWhite hideEPNotifications withoutPadding>
      <PageSection className={classes.section}>
        <Container>
          <BackButton route={routes.profil} text="Späť" />
          {renderTitle}
          {renderDescription}
          {renderSubtitle}
          {renderBaseFilter}
          <Outlet />
        </Container>
      </PageSection>
    </StaticMenuLayout>
  );
};

export default OvereniaPrehlady;
