interface Props {
  color?: string;
  height?: number;
  id: string;
  width?: number;
}

const IconBlueUsers = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 16}
    id={id}
    viewBox={`0 0 ${width || 16} ${height || 16}`}
    width={width || 16}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5444_173924)">
      <path
        clipRule="evenodd"
        d="M4.33366 4.66667C4.33366 3.74619 5.07985 3 6.00033 3C6.9208 3 7.66699 3.74619 7.66699 4.66667C7.66699 5.58714 6.9208 6.33333 6.00033 6.33333C5.07985 6.33333 4.33366 5.58714 4.33366 4.66667ZM6.00033 1C3.97528 1 2.33366 2.64162 2.33366 4.66667C2.33366 6.69171 3.97528 8.33333 6.00033 8.33333C8.02537 8.33333 9.66699 6.69171 9.66699 4.66667C9.66699 2.64162 8.02537 1 6.00033 1ZM3.33366 9C2.3612 9 1.42857 9.38631 0.740934 10.0739C0.0533008 10.7616 -0.333008 11.6942 -0.333008 12.6667V14C-0.333008 14.5523 0.114707 15 0.666992 15C1.21928 15 1.66699 14.5523 1.66699 14V12.6667C1.66699 12.2246 1.84259 11.8007 2.15515 11.4882C2.46771 11.1756 2.89163 11 3.33366 11H8.66699C9.10902 11 9.53294 11.1756 9.8455 11.4882C10.1581 11.8007 10.3337 12.2246 10.3337 12.6667V14C10.3337 14.5523 10.7814 15 11.3337 15C11.8859 15 12.3337 14.5523 12.3337 14V12.6667C12.3337 11.6942 11.9473 10.7616 11.2597 10.0739C10.5721 9.38631 9.63945 9 8.66699 9H3.33366ZM12.3654 9.83594C12.5035 9.30119 13.0489 8.97962 13.5837 9.11769C14.3703 9.3208 15.0672 9.77939 15.5649 10.4215C16.0627 11.0636 16.3331 11.8528 16.3337 12.6652L16.3337 12.6659L16.3337 13.9993C16.3337 14.5516 15.8859 14.9993 15.3337 14.9993C14.7814 14.9993 14.3337 14.5516 14.3337 13.9993V12.6663C14.3333 12.2971 14.2104 11.9386 13.9842 11.6468C13.758 11.355 13.4412 11.1465 13.0837 11.0542C12.5489 10.9161 12.2273 10.3707 12.3654 9.83594ZM10.915 1.11719C10.38 0.980199 9.83523 1.30287 9.69824 1.8379C9.56125 2.37292 9.88393 2.9177 10.419 3.05469C10.7775 3.14648 11.0952 3.35498 11.3221 3.64732C11.5491 3.93965 11.6722 4.2992 11.6722 4.66927C11.6722 5.03934 11.5491 5.39889 11.3221 5.69122C11.0952 5.98356 10.7775 6.19206 10.419 6.28385C9.88393 6.42084 9.56125 6.96562 9.69824 7.50064C9.83523 8.03567 10.38 8.35834 10.915 8.22135C11.7037 8.01941 12.4028 7.56071 12.902 6.91757C13.4013 6.27443 13.6722 5.48343 13.6722 4.66927C13.6722 3.85511 13.4013 3.06411 12.902 2.42097C12.4028 1.77783 11.7037 1.31913 10.915 1.11719Z"
        fill={color || '#1F57A5'}
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_5444_173924">
        <rect fill="white" height={height || 16} width={width || 16} />
      </clipPath>
    </defs>
  </svg>
);

export default IconBlueUsers;
