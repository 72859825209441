import { createUseStyles } from 'react-jss';
import { breakpoints, color, getRem } from '@dovera/design-system';

export default createUseStyles({
  buttonLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      minHeight: 0,
      marginBottom: 0,
      '&:first-child': {
        marginRight: getRem(24),
      },
    },
  },
  disapprovalBtn: {
    marginLeft: 32,
    color: color('error', 600),
    borderColor: color('error', 600),
    '&:hover, &:focus': {
      '&:not([disabled])': {
        color: color('error', 600),
        borderColor: color('error', 600),
        backgroundColor: color('error', 100),
      },
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      marginLeft: 0,
      marginTop: 16,
    },
  },
  modal: {
    '& .modal__header': {
      '& h2 svg': {
        verticalAlign: 'middle',
        marginTop: -7,
      },
    },
  },
});
