interface Props {
  color?: string;
  id: string;
}

const IconInovationMenu = ({ color, id }: Props) => (
  <svg
    fill="none"
    height="16"
    id={id}
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4.08203 5.9987C4.08203 3.83558 5.83558 2.08203 7.9987 2.08203C10.1618 2.08203 11.9154 3.83558 11.9154 5.9987C11.9154 7.08185 11.4767 8.06112 10.7655 8.77093C10.6791 8.8571 10.5821 8.94993 10.4792 9.04837L10.4792 9.04843L10.479 9.04854L10.479 9.04855C10.2098 9.30613 9.90045 9.60215 9.63695 9.91797H8.75V6.75H9.33464C9.74885 6.75 10.0846 6.41421 10.0846 6C10.0846 5.58579 9.74885 5.25 9.33464 5.25H8H6.66797C6.25376 5.25 5.91797 5.58579 5.91797 6C5.91797 6.41421 6.25376 6.75 6.66797 6.75H7.25V9.91797H6.36045C6.09697 9.60218 5.78763 9.30619 5.51843 9.04862L5.5184 9.04859L5.51837 9.04856L5.51836 9.04855L5.51835 9.04854L5.51833 9.04852C5.41539 8.95003 5.31832 8.85715 5.23193 8.77093C4.52072 8.06112 4.08203 7.08185 4.08203 5.9987ZM9.2487 11.418V11.9987H9.25V12.0013C9.25 12.6917 8.69036 13.2513 8 13.2513C7.30964 13.2513 6.75 12.6917 6.75 12.0013V11.418H9.2487ZM5.25 11.9987H5.2487V10.9277C5.04081 10.6689 4.81569 10.4536 4.55417 10.2035L4.55416 10.2035C4.43504 10.0896 4.30836 9.96841 4.17231 9.83263C3.1907 8.85294 2.58203 7.49619 2.58203 5.9987C2.58203 3.00716 5.00716 0.582031 7.9987 0.582031C10.9902 0.582031 13.4154 3.00716 13.4154 5.9987C13.4154 7.49619 12.8067 8.85294 11.8251 9.83263C11.689 9.96841 11.5624 10.0896 11.4432 10.2035L11.4432 10.2035C11.1822 10.4531 10.9575 10.668 10.75 10.9261V12.0013C10.75 13.0252 10.1905 13.9183 9.36051 14.3917C9.19057 14.9836 8.64521 15.4167 7.9987 15.4167C7.35154 15.4167 6.80573 14.9827 6.63638 14.3899C5.80813 13.9161 5.25 13.0239 5.25 12.0013V11.9987Z"
      fill={color || 'white'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconInovationMenu;
