import { createUseStyles } from 'react-jss';
import { breakpoints, color, getRem } from '@dovera/design-system';

export default createUseStyles({
  feedback: {
    width: 415,
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 1)',
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      width: '100%',
    },
  },
  header: {
    backgroundColor: color('secondary', 500),
    fontWeight: 'bold',
    padding: '17px 24px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      cursor: 'pointer',
      float: 'right',
    },
    '& h6': {
      color: 'white',
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      padding: '17px 24px 4px',
    },
  },
  content: {
    background: '#FEFEFE',
    color: color('black'),
    padding: 24,
    '& h6': {
      color: color('black'),
    },
    [`@media screen and (max-height: 820px)`]: {
      maxHeight: getRem(550),
      overflow: 'auto',
    },
    [`@media screen and (max-height: 690px)`]: {
      maxHeight: getRem(450),
      overflow: 'auto',
    },
    [`@media screen and (max-height: 610px)`]: {
      maxHeight: getRem(370),
      overflow: 'auto',
    },
  },
  rating: {
    '& > div': {
      alignItems: 'center',
      [`@media screen and (max-height: 820px)`]: {
        width: '102%',
      },
    },
  },
  iconRaiting: {
    padding: '0 3.5px',
  },
  options: {
    marginTop: 32,
    '& .form-control': {
      marginBottom: '1rem',
    },
    '& .radiocheck-group': {
      maxHeight: getRem(200),
      overflowY: 'auto',
    },
  },
  textarea: {
    '& label': {
      width: '100%',
    },
    '& .form-control': {
      marginBottom: '1rem',
    },
    '& textarea': {
      maxHeight: getRem(74),
      overflowY: 'auto',
    },
  },
  textareaLabel: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '&.withTrigger': {
      justifyContent: 'space-between',
    },
  },
  submitBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  result: {
    '& .notification': {
      marginBottom: 0,
    },
  },
  triggerCloseBtnWrapper: {
    marginTop: getRem(16),
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
