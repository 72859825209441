interface Props {
  active?: boolean;
  id: string;
}

const IconPatient = ({ active, id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="16"
      cy="16"
      fill={active ? '#F9C356' : '#C4C4C4'}
      rx="16"
      ry="16"
    />
    <path
      d="M22.6667 23.5V21.8333C22.6667 20.9493 22.3155 20.1014 21.6904 19.4763C21.0652 18.8512 20.2174 18.5 19.3333 18.5H12.6667C11.7826 18.5 10.9348 18.8512 10.3097 19.4763C9.68453 20.1014 9.33334 20.9493 9.33334 21.8333V23.5"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M16 15.1667C17.8409 15.1667 19.3333 13.6743 19.3333 11.8333C19.3333 9.99238 17.8409 8.5 16 8.5C14.159 8.5 12.6667 9.99238 12.6667 11.8333C12.6667 13.6743 14.159 15.1667 16 15.1667Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconPatient;
