import useStyles from '../../../Profil.styles';
import { Card, CardSection, Icon, Link, color } from '@dovera/design-system';
import { cx } from '../../../../../utils/exports';
import strings from '../../../../../constants/strings';
import IconMap from '../../../../../components/CustomIcons/IconMap';
import { OrdinacneHodiny } from '../../../../../types/poskytovatel.types';
import { Adresa } from '../../../../../types/models/Adresa';
import {
  addressLowerCases,
  hotjarMasking,
} from '../../../../../utils/strings.utils';
import {
  GridColTable,
  GridRowTable,
  GridTable,
} from '../../../../../components/GridTable';
import { formatHours } from '../../../../../utils/date.utils';
import IconClock from '../../../../../components/CustomIcons/IconClock';
import { CustomTooltip } from '../../../../../components/CustomTooltip';
import { IconInfo } from '../../../../../components';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import { arraySort } from '../../../../../utils/array.utils';
import { ReactNode } from 'react';

const texts = strings.profile.overeniaPrehlady.ambulanceDetail.card;

interface Props {
  addresses: Adresa[];
  workingHours: OrdinacneHodiny[];
}

const calculateWrokingHours = (
  day: string,
  dayInWeek: number,
  workingHours: OrdinacneHodiny[],
): ReactNode | string => {
  if (
    !dayInWeek ||
    !workingHours ||
    !workingHours.length ||
    !workingHours?.some((w) => w.denVTyzdni === dayInWeek)
  )
    return <SafeHtml html={`<b>${day}</b> -`} />;
  const days = arraySort(workingHours, 'typOrdHodin')?.filter(
    (w) => w.denVTyzdni === dayInWeek,
  );
  let resp = '';
  days.forEach((d, key) => {
    resp += key === 0 ? `<b>${day}</b> ` : `<br /><span></span>`;
    resp += ` ${formatHours(d?.casOd)} - ${formatHours(d?.casDo)}`;
    if (d.typOrdHodin) resp += ` (${d.typOrdHodin})`;
  });
  return <SafeHtml html={resp} />;
};

const AmbulanceCard = ({ addresses, workingHours }: Props) => {
  const classes = useStyles();
  const renderAddress = (
    <>
      <h5 className={cx(classes.subtitle, 'mb-small')}>
        <IconMap id="detail-ambulancie--address-icon" />
        {texts.titleAddress}
      </h5>
      {addresses && addresses.length ? (
        <>
          {addresses.map((a, key) => (
            <div key={`ambulance-address--${key}`} className="d-flex">
              {hotjarMasking(
                addressLowerCases(
                  `${a.nazovUlica || ''} ${a.orientacneCislo || ''}, ${
                    a.psc || ''
                  } ${a.nazovObce || ''}`,
                  true,
                ),
              )}
            </div>
          ))}
        </>
      ) : (
        '-'
      )}
    </>
  );
  const renderOfficeHours = (
    <>
      <div className="mb-large" />
      <div className={classes.subtitleWithButtons}>
        <h5 className={cx(classes.subtitle, 'mb clickable')}>
          <CustomTooltip
            dialog={<SafeHtml html={texts.tootlip} wrapper="span" />}
            id="oh-tooltip"
            width={253}
          >
            <span className="clickable">
              <IconClock id="detail-ambulancie--wh-icon" />
              {texts.titleOfficeHours}
              <IconInfo
                color={color('black')}
                height={18}
                id={`oh-tooltip-info`}
                width={18}
              />
            </span>
          </CustomTooltip>
        </h5>
        <CustomTooltip
          dialog={<SafeHtml html={texts.tooltipAbsence} wrapper="span" />}
          id="oh-tooltip-absence"
          width={253}
        >
          <div className={cx(classes.officeHoursButtons, 'clickable')}>
            <Link
              className="btn btn--link text-space-half-right"
              download
              href="/documents/oznamenie-o-nepritomnosti-v-ambulancii_vpis.pdf"
              placeholder
              target="_blank"
            >
              <Icon name="download" size="medium" />
              {texts.btnSetAbsence}
            </Link>

            <IconInfo
              color={color('black')}
              height={18}
              id={`oh-tooltip-absence-info`}
              width={18}
            />
          </div>
        </CustomTooltip>
      </div>

      <div className={classes.gridTableWrapper}>
        <GridTable className={classes.gridTableOH}>
          <GridRowTable>
            <GridColTable>
              {calculateWrokingHours('Po:', 1, workingHours)}
            </GridColTable>
            <GridColTable>
              {calculateWrokingHours('Ut:', 2, workingHours)}
            </GridColTable>
            <GridColTable>
              {calculateWrokingHours('St:', 3, workingHours)}
            </GridColTable>
            <GridColTable>
              {calculateWrokingHours('Št:', 4, workingHours)}
            </GridColTable>
            <GridColTable>
              {calculateWrokingHours('Pi:', 5, workingHours)}
            </GridColTable>
          </GridRowTable>
          <GridRowTable>
            <GridColTable>
              {calculateWrokingHours('So:', 6, workingHours)}
            </GridColTable>
            <GridColTable>
              {calculateWrokingHours('Ne:', 7, workingHours)}
            </GridColTable>
          </GridRowTable>
        </GridTable>
      </div>
    </>
  );
  return (
    <Card className={classes.infoCard} data-testid="ambulance-card">
      <CardSection bg="grey" isCondensed>
        {renderAddress}
        {renderOfficeHours}
      </CardSection>
    </Card>
  );
};

export default AmbulanceCard;
