interface Props {
  color?: string;
}

const IconParameterInovations = ({ color }: Props) => (
  <svg
    fill="none"
    height="33"
    viewBox="0 0 32 33"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16.9141" fill={color || '#3EAEFF'} r="16" />
    <path
      clipRule="evenodd"
      d="M8 13.9141C8 9.49578 11.5818 5.91406 16 5.91406C20.4182 5.91406 24 9.49578 24 13.9141C24 16.1745 23.0612 18.2175 21.5548 19.6712C21.2338 19.9809 21.0026 20.4461 20.8788 21.0001C20.7251 21.6877 20.4273 22.343 20 22.9139V23.9141C20 25.2981 19.0628 26.4632 17.7884 26.8094C17.6925 27.001 17.5662 27.1763 17.4142 27.3283C17.2285 27.514 17.008 27.6613 16.7654 27.7618C16.5227 27.8623 16.2626 27.9141 16 27.9141C15.7374 27.9141 15.4773 27.8623 15.2346 27.7618C14.992 27.6613 14.7715 27.514 14.5858 27.3283C14.4338 27.1763 14.3075 27.001 14.2116 26.8094C12.9372 26.4632 12 25.2981 12 23.9141V22.9138C11.5731 22.343 11.2758 21.6872 11.1219 21.0015C10.9975 20.4466 10.7659 19.9807 10.4446 19.6706C8.93856 18.2169 8 16.1742 8 13.9141ZM16 7.91406C12.6863 7.91406 10 10.6004 10 13.9141C10 15.6095 10.7019 17.1392 11.8336 18.2316C12.5177 18.8919 12.8921 19.7552 13.0735 20.5638C13.1001 20.6826 13.1338 20.7996 13.1742 20.9141H15L15 14.9141H14C13.4477 14.9141 13 14.4663 13 13.9141C13 13.3618 13.4477 12.9141 14 12.9141H18C18.5523 12.9141 19 13.3618 19 13.9141C19 14.4663 18.5523 14.9141 18 14.9141H17V20.9141H18.8264C18.8668 20.7996 18.9004 20.6826 18.9269 20.5638C19.1078 19.755 19.482 18.8921 20.166 18.232C21.2979 17.1396 22 15.6097 22 13.9141C22 10.6004 19.3137 7.91406 16 7.91406ZM16 22.9141H14V23.9141C14 24.4664 14.4477 24.9141 15 24.9141H17C17.5523 24.9141 18 24.4664 18 23.9141V22.9141H16Z"
      fill="#F7F7F7"
      fillRule="evenodd"
    />
  </svg>
);

export default IconParameterInovations;
