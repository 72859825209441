import SolveRow from '../../../../components/SolveRow/SolveRow';
import { getPriceFormat } from '../../../../utils/number.utils';
import { ButtonLink } from '@dovera/design-system';
import { useAppDispatch } from '../../../../hooks/useStore';
import { setSelectedInvoice } from '../../../../slices/zuctovanie.slice';
import { useNavigate } from 'react-router';
import routes from '../../../../routes';
import useStyles from '../NaRiesenieWidget.styles';
import { getSolveWidgetData } from '../../../../utils/dashboard.utils';

const Faktury = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const data = getSolveWidgetData('fakt');
  if (!data) return <span />;
  return (
    <SolveRow
      countToSolve={data.pocetNaRiesenie}
      description={data.popis}
      state={data.typIkona}
      title={data.nadpis}
    >
      {data.polozkyNaRiesenie.map((p) => (
        <div
          key={`naRiesenie-${p.nadpis}-${p.naRiesenieId}`}
          className="child-row"
        >
          <div className={classes.zmluvyRow}>
            <div>
              <b>Suma: {getPriceFormat(p.hodnota3, true)}</b>
              <span>
                <b>Obdobie: {p.hodnota4}</b>
              </span>
            </div>
            <div>
              <span>Faktúra: {p.hodnota2}</span>
              <span>VS: {p.hodnota5}</span>
            </div>
            <div>
              <ButtonLink
                className="no-pad no-mrg-bottom"
                onClick={() => {
                  dispatch(
                    setSelectedInvoice({
                      invoice: {
                        idFaktury: Number(p.hodnota1),
                        cisloFaktury: p.hodnota2 || '',
                        fakturovanaSuma: Number(p.hodnota3),
                        obdobieFaktury: Number(p.hodnota4),
                        vs: p.hodnota5 || '',
                        stavFaktury: 'C',
                      },
                    }),
                  );
                  navigate(`${routes.faktury}/${p.hodnota2}`);
                }}
              >
                {p.typAkcie}
              </ButtonLink>
            </div>
          </div>
        </div>
      ))}
    </SolveRow>
  );
};

export default Faktury;
