import { color as ddsColor } from '../../constants';

interface Props {
  color?: string;
  height?: number;
  id?: string;
  width?: number;
}

const IconArrowRightLong = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 14}
    id={id || ''}
    viewBox="0 0 18 14"
    width={width || 18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M10.2929 0.292893C9.90237 0.683418 9.90237 1.31658 10.2929 1.70711L14.5858 6H0.944444C0.422842 6 0 6.44772 0 7C0 7.55228 0.422842 8 0.944444 8H14.5858L10.2929 12.2929C9.90237 12.6834 9.90237 13.3166 10.2929 13.7071C10.6834 14.0976 11.3166 14.0976 11.7071 13.7071L17.7071 7.70711C18.0976 7.31658 18.0976 6.68342 17.7071 6.29289L11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893Z"
      fill={color || ddsColor('primary', 600)}
      fillRule="evenodd"
    />
  </svg>
);

export default IconArrowRightLong;
