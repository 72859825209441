// @ts-nocheck
import { ReactNode } from 'react';
import { Button } from '@dovera/design-system';
import Illustration from '../../assets/illustrations/notFound2.png';
import IllustrationSuccess from '../../assets/illustrations/success-mamog.png';
import IllustrationSuccessDocs from '../../assets/illustrations/zmluvy/documents-signed.png';
import IllustrationQuestion from '../../assets/illustrations/global/error_mamografia.png';
import useStyles from './NoDataIllustration.styles';
import SafeHtml from '../SafeHtml/SafeHtml';
import { IconSuccess } from '../CustomIcons';
// @ts-check

interface Props {
  button?: string;
  description?: string;
  illustration?: 'box' | 'woman' | 'woman-question';
  onButtonClick?: () => void;
  reloadOnClick?: boolean;
  success?: boolean;
  successDocs?: boolean;
  title: string | ReactNode;
}

const getIllustration = (illustration: string): ReactNode => {
  if (illustration === 'woman-question') return IllustrationQuestion;
  return Illustration;
};

const NoDataIllustration = ({
  button,
  description,
  illustration,
  onButtonClick,
  reloadOnClick,
  success,
  successDocs,
  title,
}: Props) => {
  const classes = useStyles();
  if (successDocs && title)
    return (
      <div className={classes.rootSuccessDocs}>
        <div className="d-flex">
          <IconSuccess height={32} id={`icon-success-${title}`} width={32} />
          <h6>{title}</h6>
        </div>
        <img alt="Illustration" src={IllustrationSuccessDocs} />
      </div>
    );
  if (success && title)
    return (
      <div className={classes.rootSuccess}>
        <div className="d-flex">
          <IconSuccess height={32} id={`icon-success-${title}`} width={32} />
          <h6>{title}</h6>
        </div>
        <img alt="Illustration" src={IllustrationSuccess} />
      </div>
    );
  return (
    <div className={classes.root}>
      <img alt="Illustration" src={getIllustration(illustration)} />
      <SafeHtml className="mb-small" html={title} wrapper="h4" />
      {description && (
        <SafeHtml className="mb-small" html={description} wrapper="div" />
      )}
      {button && reloadOnClick && (
        <Button onClick={() => window.location.reload()} size="s">
          {button}
        </Button>
      )}
      {button && onButtonClick && (
        <Button onClick={() => onButtonClick()} size="s">
          {button}
        </Button>
      )}
    </div>
  );
};

export default NoDataIllustration;
