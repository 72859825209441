// @ts-nocheck
import { useCallback, useState } from 'react';
import useStyles from '../Auth.styles';
import { cx } from '../../../../utils/exports';
import Illustration from '../../../../assets/illustrations/dlek-auth.png';
import strings from '../../../../constants/strings';
import { Button, ButtonLink, Icon } from '@dovera/design-system';
import IconArrowRightLong from '../../../../components/CustomIcons/IconArrowRightLong';
import { getCMSText } from '../../../../utils/cms.utils';
import { cmsPath } from '../../../../constants/cmsPath';
import { sendDataLayer } from '../../../../utils/gtm.utils';
import { useNavigate } from 'react-router';
import routes from '../../../../routes';
import AuthCard from '../AuthCard/AuthCard';
// @ts-check

const texts = strings.auth.banner;

interface Props {
  isCard: boolean;
  version: 'A' | 'B' | string;
}

const AuthBanner = ({ isCard, version }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const clickCallback = useCallback(() => {
    sendDataLayer(`auth_${version === 'A' ? '1' : '2'}_banner_hp_click`);
    navigate(routes.autentifikacia);
  }, [navigate, version]);
  const [showHiddenContent, setShowHiddenContent] = useState(false);
  const renderVersionA = (
    <>
      <img alt="Illustration" src={Illustration} />
      <div>
        {getCMSText(cmsPath.autentifikacia.banery.dashboardBanner.verziaA)}
        <Button
          className={cx(classes.button, 'no-mrg')}
          onClick={clickCallback}
        >
          {texts.button}
          <IconArrowRightLong
            color="white"
            height={12}
            id="auth-banner--continue-icon"
            width={16}
          />
        </Button>
      </div>
    </>
  );
  const renderVersionB = (
    <>
      <div>
        {getCMSText(
          cmsPath.autentifikacia.banery.dashboardBanner.verziaB,
          '',
          false,
          false,
          '',
          true,
        )}
        <div
          className={cx(
            !showHiddenContent && 'hide',
            'auth-banner--extra-content',
          )}
        >
          {getCMSText(
            cmsPath.autentifikacia.banery.dashboardBanner.verziaBHidden,
            '',
            false,
            false,
            '',
            true,
          )}
        </div>
        <ButtonLink
          className={cx(classes.showMoreBtn, 'mb-small')}
          onClick={() => setShowHiddenContent(!showHiddenContent)}
        >
          {showHiddenContent
            ? texts.buttonHideBenefits
            : texts.buttonShowBenefits}
          <Icon
            name={`chevron-${showHiddenContent ? 'up' : 'down'}`}
            size="medium"
          />
        </ButtonLink>
        <Button
          className={cx(classes.button, 'no-mrg')}
          onClick={clickCallback}
        >
          {texts.button}
          <IconArrowRightLong
            color="white"
            height={12}
            id="auth-banner--continue-icon"
            width={16}
          />
        </Button>
      </div>
      <img alt="Illustration" src={Illustration} />
    </>
  );
  const renderContent = version === 'A' ? renderVersionA : renderVersionB;
  if (isCard)
    return (
      <AuthCard
        onContinueClick={() => {
          sendDataLayer('aut_banner_compact_hp_click');
          navigate(routes.autentifikacia);
        }}
        show
      />
    );
  return <div className={classes.authBanner}>{renderContent}</div>;
};

export default AuthBanner;
