interface Props {
  id: string;
}

const IconAttachment = ({ id }: Props) => (
  <svg
    fill="none"
    height="16"
    id={id}
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0013 5.3335V11.3335C12.0013 12.6668 11.0013 14.6668 8.33464 14.6668C5.66797 14.6668 4.66797 12.6668 4.66797 11.3335V4.00016C4.66797 3.11127 5.2013 1.3335 7.33464 1.3335C9.46797 1.3335 10.0013 3.11127 10.0013 4.00016V10.3335C10.0013 10.8891 9.66797 12.0002 8.33464 12.0002C7.0013 12.0002 6.66797 10.7779 6.66797 10.3335V7.00016"
      stroke="#3F7C22"
      strokeLinecap="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default IconAttachment;
