import { createUseStyles } from 'react-jss';
import { getRem } from '@dovera/design-system';

export default createUseStyles({
  preloader: {
    padding: `${getRem(20)} ${getRem(32)} ${getRem(16)}`,
    display: 'flex',
    flexDirection: 'row',
    '& > div:first-child span': {
      borderRadius: 100,
    },
    '& > div:not(:first-child)': {
      width: '100%',
      marginLeft: getRem(12),
    },
  },
});
