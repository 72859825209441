import { Icon, Textarea } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { CustomTooltip } from '../../../../../../components/CustomTooltip';
import RemainingChars from '../../../../../../components/RemainingChars/RemainingChars';
import SafeHtml from '../../../../../../components/SafeHtml/SafeHtml';
import {
  MAX_LENGTH_TEXTAREA,
  MIN_LENGTH_EPICRISIS,
} from '../../../../../../constants/proposals';
import strings from '../../../../../../constants/strings';
import { RootState } from '../../../../../../rootReducer';
import useStyles from '../../../../Proposals.styles';

const texts = strings.proposals.new;

interface Props {
  epicrisisField: any;
}

const Reason = ({ epicrisisField }: Props) => {
  const classes = useStyles();
  const {
    formData: { data },
  } = useSelector((state: RootState) => state.spaProposals.new.data);

  return (
    <div className={classes.longTextarea}>
      <Textarea
        {...epicrisisField.input}
        error={
          epicrisisField.meta.touched &&
          epicrisisField.meta.error && (
            <SafeHtml html={epicrisisField.meta.error} />
          )
        }
        label={
          <RemainingChars
            formLabel={
              <>
                {texts.labels.reason}
                <span className="text-color-error text-space-half-right">
                  {' '}
                  *
                </span>
                <CustomTooltip
                  dialog={
                    data?.udajDetIndSkup?.epikriza || texts.tooltips.epicrisis
                  }
                  id={`epicrisis-tooltip`}
                >
                  <Icon name="16-info" />
                </CustomTooltip>
              </>
            }
            maxLength={MAX_LENGTH_TEXTAREA}
            valueLength={epicrisisField.input.value?.length}
          />
        }
        maxLength={MAX_LENGTH_TEXTAREA}
        minLength={MIN_LENGTH_EPICRISIS}
      />
    </div>
  );
};

export default Reason;
