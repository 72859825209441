import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  BankovyUcet,
  KontaktnaOsoba,
  ProfilState,
  ZmluvnyPristroj,
} from '../types/profil.types';
import {
  dajBankoveSpojeniaApi,
  dajKonaktneOsobyApi,
  dajZmluvnePristrojeApi,
  fetchProfile,
} from '../api/profil';
import { AppThunk } from '../store';
import { LoadingState } from '../types';
import { DajProfilResponse } from '../types/api/profil';
import { uniqueArray } from '../utils/array.utils';

export const initialState: ProfilState = {
  bankoveSpojenie: {
    data: null,
    error: null,
    isLoading: false,
  },
  kontaktneOsoby: {
    data: null,
    error: null,
    isLoading: false,
  },
  profil: {
    data: null,
    dataState: LoadingState.none,
  },
  zmluvnePristroje: {
    data: null,
    error: null,
    isLoading: false,
  },
};

const profilSlice = createSlice({
  name: 'profil',
  initialState,
  reducers: {
    dajKontaktneOsobyStart(state) {
      return {
        ...state,
        kontaktneOsoby: {
          ...initialState.kontaktneOsoby,
          isLoading: true,
        },
      };
    },
    dajKontaktneOsoby(
      state,
      action: PayloadAction<{ kontaktnaOsoba: KontaktnaOsoba[] }>,
    ) {
      return {
        ...state,
        kontaktneOsoby: {
          data: action.payload.kontaktnaOsoba,
          error: null,
          isLoading: false,
        },
      };
    },
    dajKontaktneOsobyChyba(state, action: PayloadAction<string>) {
      return {
        ...state,
        kontaktneOsoby: {
          ...state.kontaktneOsoby,
          data: null,
          error: action.payload,
          isLoading: false,
        },
      };
    },
    dajBankoveSpojeniaStart(state) {
      return {
        ...state,
        bankoveSpojenie: {
          ...initialState.bankoveSpojenie,
          isLoading: true,
        },
      };
    },
    dajBankoveSpojenia(
      state,
      action: PayloadAction<{ bankoveUcty: BankovyUcet[] }>,
    ) {
      return {
        ...state,
        bankoveSpojenie: {
          ...state.bankoveSpojenie,
          data: action.payload.bankoveUcty,
          error: null,
          isLoading: false,
        },
      };
    },
    dajBankoveSpojeniaChyba(state, action: PayloadAction<string>) {
      return {
        ...state,
        bankoveSpojenie: {
          ...state.bankoveSpojenie,
          data: null,
          error: action.payload,
          isLoading: false,
        },
      };
    },
    dajZmluvnePristrojeStart(state) {
      return {
        ...state,
        zmluvnePristroje: {
          ...initialState.zmluvnePristroje,
          isLoading: true,
        },
      };
    },
    dajZmluvnePristroje(
      state,
      action: PayloadAction<{ zmluvnyPristroj: ZmluvnyPristroj[] }>,
    ) {
      return {
        ...state,
        zmluvnePristroje: {
          ...state.zmluvnePristroje,
          data: action.payload.zmluvnyPristroj,
          error: null,
          isLoading: false,
        },
      };
    },
    dajZmluvnePristrojeChyba(state, action: PayloadAction<string>) {
      return {
        ...state,
        zmluvnePristroje: {
          ...state.zmluvnePristroje,
          data: null,
          error: action.payload,
          isLoading: false,
        },
      };
    },
    resetProfile() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending.toString(), (state) => {
        state.profil.data = null;
        state.profil.dataState = LoadingState.fetching;
        state.profil.isError = false;
        state.profil.error = null;
      })
      .addCase(
        fetchProfile.fulfilled.toString(),
        (state, action: PayloadAction<DajProfilResponse>) => {
          state.profil.data = {
            ...action.payload,
            odbornosti: action.payload.odbornosti
              ? uniqueArray(
                  action.payload.odbornosti.map((o) => ({
                    kodOdbNz: o.kodOdbNz,
                    nazovOdbNz: o.nazovOdbNz,
                  })),
                )
              : [],
          };
          state.profil.dataState = LoadingState.filled;
        },
      )
      .addCase(fetchProfile.rejected.toString(), (state, action: any) => {
        state.profil.dataState = LoadingState.error;
        state.profil.error = action?.error?.message;
        state.profil.isError = true;
      });
  },
});

const {
  dajBankoveSpojenia,
  dajBankoveSpojeniaChyba,
  dajBankoveSpojeniaStart,
  dajKontaktneOsoby,
  dajKontaktneOsobyChyba,
  dajKontaktneOsobyStart,
  dajZmluvnePristroje,
  dajZmluvnePristrojeChyba,
  dajZmluvnePristrojeStart,
} = profilSlice.actions;
export default profilSlice.reducer;

export const { resetProfile } = profilSlice.actions;

export const fetchKontaktneOsoby =
  (idPouzivatel: number, idHz: number, idVz: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajKontaktneOsobyStart());
      const response = await dajKonaktneOsobyApi(idPouzivatel, idHz, idVz);
      dispatch(dajKontaktneOsoby(response));
    } catch (err: any) {
      dispatch(dajKontaktneOsobyChyba(err?.toString()));
    }
  };

export const fetchBankoveSpojenia =
  (idPouzivatel: number, idHz: number, idVz: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajBankoveSpojeniaStart());
      const response = await dajBankoveSpojeniaApi(idPouzivatel, idHz, idVz);
      dispatch(dajBankoveSpojenia(response));
    } catch (err: any) {
      dispatch(dajBankoveSpojeniaChyba(err?.toString()));
    }
  };

export const fetchZmluvnePristroje =
  (idPouzivatel: number, idHz: number, idVz: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajZmluvnePristrojeStart());
      const response = await dajZmluvnePristrojeApi(idPouzivatel, idHz, idVz);
      dispatch(dajZmluvnePristroje(response));
    } catch (err: any) {
      dispatch(dajZmluvnePristrojeChyba(err?.toString()));
    }
  };
