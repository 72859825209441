import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 518,
    margin: '0 auto',
    '& img': {
      width: 309,
    },
    '& h4': {
      marginTop: 30,
    },
  },
  rootSuccess: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& img': {
      marginTop: -70,
      maxWidth: 299,
      marginLeft: 50,
      marginRight: 50,
    },
    '& div': {
      '& h6': {
        display: 'inline-flex',
        marginLeft: 11,
      },
      '& svg': {
        verticalAlign: 'middle',
        marginTop: -3,
      },
    },
  },
  rootSuccessDocs: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& img': {
      marginTop: -70,
      maxWidth: 299,
      marginLeft: 50,
      marginRight: 0,
    },
    '& div': {
      '& h6': {
        display: 'inline-flex',
        marginLeft: 11,
      },
      '& svg': {
        verticalAlign: 'middle',
        marginTop: -3,
      },
    },
  },
  wider: {
    maxWidth: '645px !important',
  },
  buttonLayout: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '& button': {
      marginBottom: 0,
      '&:first-child': {
        marginRight: 28,
      },
    },
  },
});
