interface Props {
  height?: number;
  id?: string;
  width?: number;
}

const IconWarning = ({ height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 24}
    id={id}
    viewBox={`0 0 ${width || 24} ${height || 24}`}
    width={width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M10.528 2.28309C10.9773 2.03014 11.4842 1.89725 11.9998 1.89725C12.5154 1.89725 13.0223 2.03014 13.4716 2.28309C13.9209 2.53604 14.2974 2.90052 14.5648 3.34136L14.5677 3.34611L23.0376 17.4861L23.0458 17.4999C23.3077 17.9536 23.4463 18.4679 23.4478 18.9917C23.4493 19.5155 23.3135 20.0306 23.0541 20.4857C22.7947 20.9408 22.4207 21.3201 21.9692 21.5857C21.5177 21.8514 21.0046 21.9942 20.4808 21.9999L20.4698 22.0001L3.51879 22C2.99498 21.9942 2.48182 21.8514 2.03035 21.5857C1.57887 21.3201 1.20483 20.9408 0.945426 20.4857C0.686022 20.0306 0.550303 19.5155 0.55177 18.9917C0.553236 18.4679 0.691839 17.9536 0.953786 17.4999L0.961909 17.4861L9.43191 3.34612L10.2898 3.85998L9.43478 3.34136C9.70218 2.90052 10.0787 2.53604 10.528 2.28309ZM11.146 4.37663L2.68246 18.5058C2.59729 18.6556 2.55224 18.8249 2.55176 18.9973C2.55127 19.1719 2.59651 19.3436 2.68298 19.4953C2.76945 19.647 2.89413 19.7734 3.04462 19.862C3.1938 19.9498 3.36317 19.9973 3.53617 20H20.4634C20.6364 19.9973 20.8058 19.9498 20.9549 19.862C21.1054 19.7734 21.2301 19.647 21.3166 19.4953C21.403 19.3436 21.4483 19.1719 21.4478 18.9973C21.4473 18.8249 21.4023 18.6557 21.3171 18.5059L12.8548 4.37862C12.8544 4.37796 12.854 4.37729 12.8536 4.37663C12.7645 4.23058 12.6395 4.1098 12.4904 4.02586C12.3406 3.94154 12.1716 3.89725 11.9998 3.89725C11.8279 3.89725 11.6589 3.94154 11.5092 4.02586C11.3601 4.1098 11.235 4.23058 11.146 4.37663Z"
      fill="#FF9800"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8Z"
      fill="#FF9800"
      fillRule="evenodd"
    />
    <circle cx="12" cy="17" fill="#FF9800" r="1" />
  </svg>
);

export default IconWarning;
