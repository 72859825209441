import React from 'react';
import { color as ddsColor } from '../../constants';

const IconSuccess = ({
  color,
  height,
  width,
  ...other
}: React.JSX.IntrinsicElements['svg']) => (
  <svg
    fill="none"
    height={height || 33}
    viewBox="0 0 32 33"
    width={width || 32}
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <g clipPath="url(#clip0_2463_44007)">
      <circle
        cx="16"
        cy="16.9922"
        fill={color || ddsColor('primary', 600)}
        r="16"
      />
    </g>
    <path
      clipRule="evenodd"
      d="M24.5692 11.7212C24.9951 12.1736 24.9735 12.8856 24.521 13.3114L14.9585 22.3114C14.5299 22.7148 13.8628 22.7196 13.4284 22.3225L9.49089 18.7225C9.03234 18.3032 9.00048 17.5916 9.41972 17.1331C9.83897 16.6745 10.5506 16.6427 11.0091 17.0619L14.1764 19.9577L22.979 11.673C23.4314 11.2471 24.1434 11.2687 24.5692 11.7212Z"
      fill="white"
      fillRule="evenodd"
    />
    <defs>
      <clipPath id="clip0_2463_44007">
        <rect
          fill="white"
          height="32"
          transform="translate(0 0.992188)"
          width="32"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconSuccess;
