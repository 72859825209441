import { color } from '@dovera/design-system';

interface Props {
  className?: any;
}

const Plus = ({ className }: Props) => (
  <svg
    className={className}
    fill="none"
    height="11"
    viewBox="0 0 11 11"
    width="11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6.728H7.16V10.592H4.496V6.728H0.632V4.064H4.496V0.223999H7.16V4.064H11V6.728Z"
      fill={color('primary', 600)}
    />
  </svg>
);

export default Plus;
