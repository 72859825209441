// @ts-nocheck
import { createUseStyles } from 'react-jss';
import PEKBackground from '../../assets/illustrations/global/pek-background.jpg';
// @ts-check

export default createUseStyles({
  pek: {
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${PEKBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  errorPEK: {
    paddingTop: 40,
    display: 'flex',
    paddingBottom: 30,
  },
  modal: {
    '& button.modal__close-button': {
      display: 'none',
    },
    '& .modal__content': {
      overflowY: 'hidden',
      paddingRight: 0,
    },
  },
  pekContent: {
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'scroll',
    paddingRight: '3rem',
    '& ol, & ul': {
      fontSize: 16,
    },
    '& button': {
      textAlign: 'left',
    },
    fontSize: 16,
    '& div, & p, & table td, & li': {
      fontSize: 16,
      lineHeight: 1.778,
    },
  },
  pekSubmitBtn: {
    '& div': {
      verticalAlign: 'middle',
      marginTop: -3,
      marginRight: 7,
    },
  },
});
