import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import useStyles from './CobrowseMenu.styles';
import ContentHeader from '../ContentHeader/ContentHeader';
import { RootState } from '../../../rootReducer';
import { getCode } from '../../../utils/cobrowse.utils';
import strings from '../../../constants/strings';
import {
  /* expandedPomocnik,  */ openPomocnik,
} from '../../../slices/pomocnik.slice';

const CobrowseMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cbCode, setCbCode] = React.useState('');

  const expandedContent = useSelector(
    (state: RootState) => state.pomocnik.expanded,
  );

  React.useEffect(() => {
    getCode().then((res) => setCbCode(res?.match(/.{1}/g).join(' ')));
  }, []);

  return (
    <div className={classes.root}>
      <ContentHeader
        contentTitle={strings.cobrowse.headerTitle}
        expanded={expandedContent}
        onCloseClick={() => {
          dispatch(openPomocnik());
        }}
        onExpandClick={() => {} /* TODO dispatch(expandedPomocnik()) */}
        onMinimizeClick={() => {
          dispatch(openPomocnik());
        }}
      />
      <div
        className={cx(expandedContent && 'expanded-cobrowse', classes.cobrowse)}
      >
        <div className="text-center">
          {strings.cobrowse.yourCode}
          <h2 className="text-color-primary mb-xxsmall">{cbCode}</h2>
        </div>
      </div>
    </div>
  );
};

export default CobrowseMenu;
