import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Icon, Input, color } from '@dovera/design-system';
import { CustomTooltip } from '../CustomTooltip';
import IconSearch from '../CustomIcons/IconSearch';
import useStyles from './SearchBar.styles';
import { IconCloseMenu } from '../CustomIcons';
import { cmsPath } from '../../constants/cmsPath';
import { getCMSText } from '../../utils/cms.utils';
import { useDebounce } from '../../hooks/useDebounce';

export interface SearchBarProps {
  debounce?: boolean;
  id?: string;
  initialValue?: string;
  label?: string;
  onChange?: (text: string) => void;
  onSearch: (text: string) => void;
  placeholder?: string;
  refresh?: boolean;
  tooltip?: string | ReactNode;
  withoutLabel?: boolean;
  withoutTooltip?: boolean;
}

const SearchBar = ({
  debounce,
  id,
  initialValue,
  label,
  onChange,
  onSearch,
  placeholder,
  refresh,
  tooltip,
  withoutLabel,
  withoutTooltip,
}: SearchBarProps) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 1000);
  const [isRemoveVisible, setIsRemoveVisible] = useState(false);
  const [isClickedOnRemove, setIsClickedOnRemove] = useState(false);
  const submitCallback = useCallback(
    (e) => {
      e.preventDefault();
      if (value) setIsRemoveVisible(true);
      onSearch(value);
    },
    [onSearch, value],
  );
  useEffect(() => {
    if (debounce && !isClickedOnRemove) {
      setIsRemoveVisible(!!debouncedValue);
      onSearch(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, debouncedValue, isClickedOnRemove /* , onSearch */]);
  useEffect(() => {
    if (refresh && value) setValue('');
    // eslint-disable-next-line
  }, [refresh]);
  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);
  return (
    <form className={classes.searchBar} onSubmit={submitCallback}>
      <Input
        addonsInside
        crossOrigin
        id={id}
        label={
          !withoutLabel && (
            <span>
              {`${label || 'Vyhľadávanie'} `}
              {!withoutTooltip && (
                <CustomTooltip
                  dialog={
                    tooltip ||
                    getCMSText(
                      cmsPath.zmluvy.ASOC.zoznamZmluv.tooltipy.vyhladavanie,
                      '',
                      true,
                    )
                  }
                  id="searchBar--tooltip"
                >
                  <Icon name="info" />
                </CustomTooltip>
              )}
            </span>
          )
        }
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
          setValue(e.target.value);
          if (isClickedOnRemove) setIsClickedOnRemove(false);
        }}
        placeholder={placeholder || ''}
        rightAddons={
          isRemoveVisible ? (
            <button
              onClick={() => {
                setIsClickedOnRemove(true);
                setValue('');
                setIsRemoveVisible(false);
                onSearch('');
              }}
              type="button"
            >
              <IconCloseMenu
                color={color('black')}
                height={24}
                id="searchBar--closeIcon"
                width={24}
              />
            </button>
          ) : (
            <button onClick={submitCallback} type="submit">
              <IconSearch id="searchBar--searchIcon" />
            </button>
          )
        }
        value={value}
      />
    </form>
  );
};

export default SearchBar;
