import { createUseStyles } from 'react-jss';

import { breakpoints } from '../../hooks/screen-width/useScreenWidth';

export default createUseStyles({
  default: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  [`@media screen and (min-width: ${breakpoints.s}px)`]: {
    default: {
      paddingTop: '1.5rem',
      paddingBottom: '1.5rem',
    },
  },
});
