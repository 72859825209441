import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

type Type = boolean | number | null;

interface StylesProps {
  spaceLeft?: Type;
  spaceRight?: Type;
}

const getPadding = (space?: boolean | number | null): number | string => {
  if (space && typeof space === 'boolean') return getRem(12);
  return space || 0;
};

export default createUseStyles({
  table: {},
  tr: {
    '& td:first-child': {
      // width: '40%',
    },
  },
  td: ({ spaceLeft, spaceRight }: StylesProps) => ({
    paddingLeft: getPadding(spaceLeft),
    paddingRight: getPadding(spaceRight),
    paddingBottom: 16,
    verticalAlign: 'top',
  }),
});
