import { Month, Period, YearSwitcher, YearSwitcherProps } from './types.d';
import moment from 'moment';
import _ from 'lodash';

/**
 * Ziskanie unikatnych rokov podla obdobi
 * @date 15. 5. 2023 - 11:05:32
 *
 * @param {Period[]} periods
 * @returns {number[]}
 */
export const getYears = (periods: Period[]): number[] => {
  const years = periods.map((p) => Number(p.datum.slice(0, 4)));
  return [...new Set(years)].sort((a, b) => a - b);
};

/**
 * Získanie prepínača rokov pre hlavičku na základe zadaných parametrov
 * @date 18. 5. 2023 - 16:44:24
 *
 * @param {YearSwitcherProps} {
  actualYear,
  clickedNext,
  clickedPrev,
  periods,
}
 * @returns {YearSwitcher}
 */
export const getYearSwitcher = ({
  actualYear,
  choosedYear,
  clickedNext,
  clickedPrev,
  periods,
}: YearSwitcherProps): YearSwitcher => {
  const years: number[] = getYears(periods);
  const minYear: number = Math.min(...years);
  const maxYear: number = Math.max(...years);
  if (choosedYear)
    return {
      hasNext: years.indexOf(choosedYear) < years.indexOf(maxYear),
      hasPrev: years.indexOf(minYear) < years.indexOf(choosedYear),
      value: choosedYear,
    };
  if (actualYear) {
    if (clickedNext)
      return {
        hasNext: years.indexOf(actualYear + 1) < years.indexOf(maxYear),
        hasPrev: true,
        value: years.includes(actualYear + 1) ? actualYear + 1 : actualYear,
      };
    if (clickedPrev)
      return {
        hasNext: true,
        hasPrev: years.indexOf(actualYear - 1) > years.indexOf(minYear),
        value: years.includes(actualYear - 1) ? actualYear - 1 : actualYear,
      };
  }
  return {
    hasNext: false,
    hasPrev: years.length > 1,
    value: maxYear,
  };
};

/**
 * Vráti hodnotu pre input v tvare MMMM YYYY (napr "marec 2023")
 * @date 18. 5. 2023 - 16:49:13
 *
 * @param {Period[]} periods
 * @param {?(number | null)} [value]
 * @returns {string}
 */
export const getInputValue = (
  periods: Period[],
  value?: number | null,
  capitalizeValue?: boolean,
): string => {
  if (
    !value ||
    !periods.length ||
    !periods.some((p) => Number(p.datum) === value)
  )
    return '';
  const textValue = periods.filter((p) => Number(p.datum) === value)[0].text;
  return capitalizeValue ? _.capitalize(textValue) : textValue.toLowerCase();
};

export const getMonths = (): Month[] => [
  {
    key: '01',
    value: 'Jan',
  },
  {
    key: '02',
    value: 'Feb',
  },
  {
    key: '03',
    value: 'Mar',
  },
  {
    key: '04',
    value: 'Apr',
  },
  {
    key: '05',
    value: 'Máj',
  },
  {
    key: '06',
    value: 'Jún',
  },
  {
    key: '07',
    value: 'Júl',
  },
  {
    key: '08',
    value: 'Aug',
  },
  {
    key: '09',
    value: 'Sep',
  },
  {
    key: '10',
    value: 'Okt',
  },
  {
    key: '11',
    value: 'Nov',
  },
  {
    key: '12',
    value: 'Dec',
  },
];

/**
 * Vytvorenie period. Vstupom su min a max vo formate YYYYMM
 * @date 18. 5. 2023 - 9:24:43
 *
 * @param {number} min
 * @param {?number} [max]
 * @returns {Period[]}
 */
export const createPeriods = (min: number, max?: number): Period[] => {
  moment.locale('sk');
  const periods: Period[] = [];
  const periodFrom = moment(min, 'YYYYMM');
  let periodTo = moment(max, 'YYYYMM');
  if (!periodTo.isValid()) periodTo = moment();
  while (
    periodTo > periodFrom ||
    periodFrom.format('M') === periodTo.format('M')
  ) {
    const period: Period = {
      text: periodFrom.format('MMMM YYYY'),
      pocetDni: periodFrom.daysInMonth(),
      datum: periodFrom.format('YYYYMM'),
    };
    periods.push(period);
    periodFrom.add(1, 'month');
  }
  return periods;
};

export const getTextFormatPeriod = (period: number): string =>
  moment(period, 'YYYYMM').format('MMMM YYYY');
