import { ReactNode, useEffect, useState } from 'react';
import useStyles from '../Profil.styles';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import {
  ButtonLink,
  Container,
  Grid,
  GridCol,
  Loader,
  Notification,
  PageSection,
  color,
} from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { IconInfo } from '../../../components';
import IconUserBlue from '../../../components/CustomIcons/IconUserBlue';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import IconAngleRight from '../../../components/CustomIcons/IconAngleRight';
import IconBankCard from '../../../components/CustomIcons/IconBankCard';
import { addressLowerCases, hotjarMasking } from '../../../utils/strings.utils';
import { CustomTooltip } from '../../../components/CustomTooltip';
import {
  GridColTable,
  GridRowTable,
  GridTable,
} from '../../../components/GridTable';
import { fetchProfile } from '../../../api/profil';
import { DajProfilPayload } from '../../../types/api/profil';
import { LoadingState } from '../../../types';
import { formatDate } from '../../../utils/date.utils';
import strings from '../../../constants/strings';
import { cx } from '../../../utils/exports';
import OdbornostiModal from './OdbornostiModal/OdbornostiModal';
import IconExternalLink from '../../../components/CustomIcons/IconExternalLink';
import IconFlagBlue from '../../../components/CustomIcons/IconFlagBlue';
import IconStethoscope from '../../../components/CustomIcons/IconStethoscope';
import IconKey from '../../../components/CustomIcons/IconKey';
import ProfileCardDoctor from './ProfileCard/ProfileCardDoctor';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { useAppDispatch } from '../../../hooks/useStore';
import { getPZS } from '../../../slices/auth.slice';

const texts = strings.profile;

const Profil = () => {
  const dispatch = useAppDispatch();
  const [isVisibleExpertisesModal, setIsVisibleExpertisesModal] =
    useState(false);
  const classes = useStyles();
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const { autentifikovaneOsoby, maZmluvnePristroje, preLekara } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  const { data, dataState, error } = useSelector(
    (state: RootState) => state.profil.profil,
  );
  useEffect(() => {
    if (
      pzs.userId &&
      pzs.vzId &&
      [LoadingState.none, LoadingState.reset].includes(dataState)
    ) {
      const payload: DajProfilPayload = {
        idPouzivatel: pzs.userId,
        idVz: pzs.vzId,
        idLekarOsoba: preLekara
          ? autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId || null
          : null,
      };
      dispatch(fetchProfile(payload));
    }
  }, [dispatch, autentifikovaneOsoby, pzs, dataState, preLekara]);
  const renderExpertises =
    data && data?.odbornosti?.filter((o) => o.kodOdbNz)?.length > 1 ? (
      <ButtonLink
        className={cx(classes.buttonLinkRight, 'd-flex no-pad')}
        onClick={() => setIsVisibleExpertisesModal(true)}
      >
        {texts.labels.countExpertises(
          data?.odbornosti?.filter((o) => o.kodOdbNz)?.length,
        )}
        <IconExternalLink />
      </ButtonLink>
    ) : (
      `${data?.odbornosti?.filter((o) => o.kodOdbNz)[0]?.kodOdbNz || ''} - ${
        data?.odbornosti?.filter((o) => o.kodOdbNz)[0]?.nazovOdbNz || ''
      }`.trim()
    );
  const renderAddress = (
    street: string,
    streetNumber: string,
    zip: string,
    city: string,
    country: string,
  ): ReactNode => (
    <>
      {street &&
        streetNumber &&
        hotjarMasking(
          `${addressLowerCases(street, false)} ${streetNumber}`,
          'span',
          'd-block',
        )}
      {hotjarMasking(
        `${zip} ${addressLowerCases(city, true)}`,
        'span',
        'd-block',
      )}
      <span className="d-block">{addressLowerCases(country, false)}</span>
    </>
  );
  const renderProfileCard = () => {
    const as = data?.adresy?.some((a) => a.typAdresy === 'AS')
      ? data?.adresy?.filter((a) => a.typAdresy === 'AS')[0]
      : null;
    const ka = data?.adresy?.some((a) => a.typAdresy === 'KA')
      ? data?.adresy?.filter((a) => a.typAdresy === 'KA')[0]
      : null;
    return (
      <div className={classes.profileCard}>
        {data && dataState === LoadingState.filled ? (
          <>
            <h5>
              <IconUserBlue id="icon-profile" /> {hotjarMasking(data.nazovPzs)}
            </h5>
            <GridTable>
              <GridRowTable>
                <GridColTable>
                  <b>{texts.labels.ico}</b>
                </GridColTable>
                <GridColTable spaceLeft>
                  {hotjarMasking(data?.ico)}
                </GridColTable>
              </GridRowTable>
              <GridRowTable>
                <GridColTable>
                  <b>{texts.labels.kodPZS}</b>
                </GridColTable>
                <GridColTable spaceLeft>
                  {hotjarMasking(data?.kodPzshz)}
                </GridColTable>
              </GridRowTable>
              <GridRowTable>
                <GridColTable>
                  <div className="d-flex">
                    <b className="text-space-right">{texts.labels.as}</b>
                    <CustomTooltip
                      dialog={texts.tooltips.as}
                      id="adresa-sidla-tooltip"
                      width={253}
                    >
                      <IconInfo
                        color={color('black')}
                        height={22}
                        id={`profile-as-icon-info`}
                        width={22}
                      />
                    </CustomTooltip>
                  </div>
                </GridColTable>
                <GridColTable spaceLeft>
                  {as
                    ? renderAddress(
                        as.nazovUlica || '',
                        as.orientacneCislo,
                        as.psc,
                        as.nazovObce,
                        as.nazovStat,
                      )
                    : '-'}
                </GridColTable>
              </GridRowTable>
              <GridRowTable>
                <GridColTable>
                  <div className="d-flex">
                    <b className="text-space-right">{texts.labels.ka}</b>
                    <CustomTooltip
                      dialog={texts.tooltips.ka}
                      id="ka-tooltip"
                      width={253}
                    >
                      <IconInfo
                        color={color('black')}
                        height={22}
                        id={`profile-ka-icon-info`}
                        width={22}
                      />
                    </CustomTooltip>
                  </div>
                </GridColTable>
                <GridColTable spaceLeft>
                  {ka
                    ? renderAddress(
                        ka.nazovUlica || '',
                        ka.orientacneCislo,
                        ka.psc,
                        ka.nazovObce,
                        ka.nazovStat,
                      )
                    : '-'}
                </GridColTable>
              </GridRowTable>
              <GridRowTable>
                <GridColTable>
                  <b>{texts.labels.asoc}</b>
                </GridColTable>
                <GridColTable spaceLeft>{data?.zdruzenie || '-'}</GridColTable>
              </GridRowTable>
              <GridRowTable>
                <GridColTable>
                  <b>{texts.labels.doveraPomaha}</b>
                </GridColTable>
                <GridColTable spaceLeft>
                  {data?.doveraPomaha ? 'áno' : 'nie'}
                </GridColTable>
              </GridRowTable>
              <GridRowTable>
                <GridColTable>
                  <b>{texts.labels.vztah}</b>
                </GridColTable>
                <GridColTable spaceLeft>
                  {data?.zmluvnyOd ? `od ${formatDate(data?.zmluvnyOd)}` : '-'}
                </GridColTable>
              </GridRowTable>
              <GridRowTable>
                <GridColTable>
                  <b>{texts.labels.odbornosti}</b>
                </GridColTable>
                <GridColTable spaceLeft>{renderExpertises}</GridColTable>
              </GridRowTable>
            </GridTable>
          </>
        ) : dataState === LoadingState.error ? (
          <Notification
            message={error || strings.technickaChyba}
            variant="error"
          />
        ) : (
          <Loader size={24} />
        )}
      </div>
    );
  };
  const renderProfileLinks = (
    <div className={classes.profileLinks}>
      <Link
        className={classes.link}
        id="link-kontaktneOsoby"
        to={routes.kontaktneOsoby}
      >
        <IconUserBlue id="icon-kontaktne-osoby" />
        {texts.links.kontaktneOsoby}
        <IconAngleRight id={`icon-angle-kontaktne-udaje`} />
      </Link>
      <Link
        className={classes.link}
        id="link-bankoveSpojenie"
        to={routes.bankoveSpojenie}
      >
        <IconBankCard
          color={color('secondary', 500)}
          id={`icon-bankove-spojenie`}
        />
        {texts.links.bankoveSpojenie}
        <IconAngleRight id={`icon-angle-bankove-spojenie`} />
      </Link>
      {maZmluvnePristroje && (
        <Link
          className={classes.link}
          id="link-zmluvnePristroje"
          to={routes.zmluvnePristroje}
        >
          <IconStethoscope id="icon-zmluvne-pristroje" />
          {texts.links.zmluvnePristroje}
          <IconAngleRight id={`icon-angle-zmluvne-pristroje`} />
        </Link>
      )}
      <Link
        className={classes.link}
        id="link-overenia-prehlady"
        to={routes.overeniaPrehlady}
      >
        <IconFlagBlue id="icon-overenia-prehlady" />
        {texts.links.overeniaPrehlady}
        <IconAngleRight id={`icon-angle-overenia-prehlady`} />
      </Link>
      <Link
        className={classes.link}
        id="link-prihlasovacie-udaje"
        to={routes.prihlasovacieUdaje}
      >
        <IconKey id="icon-prihlasovacie-udaje" />
        {texts.links.prihlasovacieUdaje}
        <IconAngleRight id={`icon-angle-prihlasovacie-udaje`} />
      </Link>
    </div>
  );
  return (
    <StaticMenuLayout backgroundWhite hideEPNotifications withoutPadding>
      <PageSection className={classes.section}>
        <Container>
          <Breadcrumbs
            items={[
              {
                name: 'Profil',
              },
            ]}
          />
          <h2>{texts.title}</h2>
          <Grid>
            <GridCol size={{ l: 5, m: 12 }}>
              {dataState === LoadingState.fetching ? (
                <Loader size={24} />
              ) : autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId &&
                preLekara ? (
                <ProfileCardDoctor />
              ) : (
                renderProfileCard()
              )}
            </GridCol>
            <GridCol size={{ l: 7, m: 12 }}>{renderProfileLinks}</GridCol>
          </Grid>
        </Container>
        {data && data?.odbornosti?.length > 1 && (
          <OdbornostiModal
            expertises={data?.odbornosti}
            isVisible={isVisibleExpertisesModal}
            onHide={() => setIsVisibleExpertisesModal(false)}
          />
        )}
      </PageSection>
    </StaticMenuLayout>
  );
};

export default Profil;
