import strings from '../../../../constants/strings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { formatNameStr } from '../../../../utils/strings.utils';
import {
  getFilteredControls,
  spaTxsCheckTypes,
} from '../../../../utils/spaProposals.utils';
import { sendDataLayer } from '../../../../utils/gtm.utils';
import {
  BlobPDF,
  TemplateNewProposal,
} from '../../../../components/PdfTemplate';

const texts = strings.proposals.new;

const Export2PDF = () => {
  const { step1, step2, step3, step4, step5 } = useSelector(
    (state: RootState) => state.spaProposals.new.stepper,
  );
  const eventType = useSelector(
    (state: RootState) =>
      state.spaProposals.new.data.formData.data?.udajParUdalIndSkup?.udalost,
  );
  const period = useSelector(
    (state: RootState) =>
      state.spaProposals.new.data.formData.data?.udajParUdalIndSkup?.perioda ||
      0,
  );
  const controls = useSelector(
    (state: RootState) => state.spaProposals.new.data.controls.data,
  );
  const pzsName = useSelector(
    (state: RootState) => state.poskytovatel.nazovZobrazPzs,
  );
  return (
    <BlobPDF
      buttonLabel={texts.buttons.savePDF}
      buttonType="secondary"
      iconPosition="right"
      iconType="download"
      onClick={() => sendDataLayer('kupele_stepper_download')}
      pdfContent={
        <TemplateNewProposal
          additionalDiagnoses={{
            additionalDiagnoses:
              step4.diagnoses.map((d) => `${d.kod} - ${d.nazov}`).join(', ') ||
              '',
            anamnese: step4.description || '',
            status: step4.stepStatus,
          }}
          additionalInfomation={{
            escort: step5.escort === 'Y',
            escortHelp: step5.helpingEscort.split(';').join(', ') || '',
            mobility: step5.mobility
              ? texts.options.mobilityType[`${step5.mobility}`]
              : '',
            spaType: step5.spaType
              ? texts.options.spaType(true)[`${step5.spaType}`]
              : '',
            status:
              step5.escort && step5.mobility && step5.spaType
                ? 'completed'
                : null,
          }}
          dgIg={{
            confirmation: step3.spaConfirmation,
            contraindications: step3.spaContraindications,
            date: step3.eventDate,
            diagnose: step3.diagnose
              ? `${step3.diagnose.kod} - ${step3.diagnose.nazov}`
              : '',
            epicrisis: step3.reasonDescription,
            eventType,
            examinations: step3.spaExaminations,
            iG: step3.indicatorGroup
              ? `${step3.indicatorGroup.kod} - ${step3.indicatorGroup.nazov}`
              : '',
            status: step3.stepStatus,
            svlz: step3.spaSVLZ,
            txsErrors:
              controls
                ?.filter(
                  (c) =>
                    c.result === 'NOK' &&
                    c.checkType !== 'Dlznik' &&
                    c.checkType !== 'DlznikVociCSParNeodklZS' &&
                    spaTxsCheckTypes.includes(c.checkType),
                )
                .map((c) =>
                  c.checkType === 'CerpanieKNsPeriod'
                    ? texts.notifications.errors.txsControls.CerpanieKNsPeriod(
                        period,
                      )
                    : texts.notifications.errors.txsControls[`${c.checkType}`],
                ) || [],
          }}
          doctor={{
            ambulance: step1.ambulance.code,
            email: step1.doctor.email,
            name: step1.doctor.name,
            status: step1.stepStatus,
          }}
          patient={{
            insureeId: step2.insureeId,
            isDebtor: getFilteredControls(controls).some((c) => c === 'Dlznik'),
            name: formatNameStr(
              `${step2.poiData?.firstName} ${step2.poiData?.lastName}`,
            ),
            status: step2.stepStatus,
          }}
          pzsName={pzsName}
        />
      }
    />
  );
};

export default Export2PDF;
