export const getCookie = (key: string): string =>
  new RegExp(`${key}=([^;]+])`).exec(document.cookie)?.[1] || '';

export const setCookie = (
  key: string,
  value: string,
  expireAtDays?: number,
  domain?: string,
) => {
  const date = new Date();
  // Default expire cookie is set to 7 days
  const expire = expireAtDays || 7;
  date.setTime(date.getTime() + expire * 24 * 60 * 60 * 1000);
  document.cookie = `${key}=${value}; expires=${date.toUTCString()}; path=/;domain=${domain || ''}`;
};

export const deleteCookie = (key: string) => {
  // Set expire in in -1 days
  setCookie(key, '', -1);
};
