import { color } from '@dovera/design-system';

interface Props {
  className?: any;
}

const Multiplication = ({ className }: Props) => (
  <svg
    className={className}
    fill="none"
    height="11"
    viewBox="0 0 13 11"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.192 11L6.528 7.472L3.72 11H0.192L4.68 5.384L0.672 0.296H4.2L6.408 3.176L8.712 0.296H12.24L8.28 5.264L12.72 11H9.192Z"
      fill={color('primary', 600)}
    />
  </svg>
);

export default Multiplication;
