import { color } from '@dovera/design-system';
import useStyles from './InsureeBar.styles';
import { cx } from '../../../../../utils/exports';
import { hotjarMasking } from '../../../../../utils/strings.utils';
import IconCheck from '../../../../../components/CustomIcons/IconCheck';
import strings from '../../../../../constants/strings';
import { getPatientIcon } from '../../../../../utils/pacienti.utils';

const texts = strings.proposals.new;

interface Props {
  address: string;
  dateOfBirth: string;
  mobile: string;
  name: string;
  sex: 'M' | 'Z' | string;
}

const InsureeBar = ({ address, dateOfBirth, mobile, name, sex }: Props) => {
  const classes = useStyles();
  const renderBar = (
    <div className={cx(classes.insureeBar)}>
      <div>{getPatientIcon(sex, dateOfBirth)}</div>
      <div>
        <span>
          {`${texts.labels.name}: `}
          {hotjarMasking(name, 'b')}
        </span>
        <span>
          {`${texts.labels.address}: `}
          {hotjarMasking(address, 'b')}
        </span>
        <span>
          {`${texts.labels.mobile}: `}
          {hotjarMasking(mobile, 'b')}
        </span>
      </div>
      <div className={classes.statusIcon}>
        <IconCheck color={color('success')} id="insureeBar--icon" />
      </div>
    </div>
  );
  return renderBar;
};

export default InsureeBar;
