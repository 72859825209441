import strings from '../../../../constants/strings';
import { GetProposalDetailResponse } from '../../../../types/spaProposals.types';
import { getPatientIcon } from '../../../../utils/pacienti.utils';
import {
  addressLowerCases,
  formatNameStr,
  formatPhone,
  hotjarMasking,
} from '../../../../utils/strings.utils';
import Section from './Section';

const texts = strings.proposals.detail;

interface Props {
  pacient: GetProposalDetailResponse['pacient'];
}

const Patient = ({ pacient }: Props) => (
  <Section
    rows={[
      {
        label: texts.labels.name,
        value: hotjarMasking(formatNameStr(pacient.meno)),
      },
      {
        label: texts.labels.insureeId,
        value: hotjarMasking(pacient.rodneCislo),
      },
      {
        label: texts.labels.address,
        value: hotjarMasking(addressLowerCases(pacient.adresa, true)),
      },
      {
        label: texts.labels.mobile,
        value: pacient.mobil
          ? formatPhone(pacient.mobil, true)
          : strings.undefined,
      },
    ]}
    title={texts.subtitles.patient}
    titleIcon={getPatientIcon(pacient.pohlavie, pacient.datumNarodenia)}
  />
);

export default Patient;
