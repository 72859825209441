import { useRef } from 'react';
import useStyles from './FeedbackButton.styles';
import { cx } from '../../utils/exports';
import strings from '../../constants/strings';
import Feedback from '../Feedback/Feedback';

interface Props {
  onClick: () => void;
  open: boolean;
}

const FeedbackButton = ({ onClick, open }: Props) => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const renderButton = (
    <button
      className={open ? 'hide' : cx(classes.feedbackButton, 'no-mrg-bottom')}
      data-testid="feedback-floating-button"
      onClick={onClick}
      type="button"
    >
      <span>{strings.feedbacker.standard.feedbackButton}</span>
    </button>
  );
  return (
    <div
      ref={rootRef}
      className={open ? classes.openedFeedback : classes.root}
      data-testid="feedback-floating-container"
    >
      {renderButton}
      {open && <Feedback />}
    </div>
  );
};

export default FeedbackButton;
