import React from 'react';
import cx from 'classnames';

import useStyles from './Skeleton.styles';

type Props = {
  height?: string | number;
  isCircle?: boolean;
  width?: string | number;
} & React.JSX.IntrinsicElements['span'];

const Skeleton = ({ className, height, isCircle, width, ...other }: Props) => {
  const classes = useStyles({ width, height, isCircle });

  return (
    <span className={cx(classes.skeleton, className)} {...other}>
      &zwnj;
    </span>
  );
};

export default Skeleton;
