import { Circle, Path, Svg } from '@react-pdf/renderer';

const WarningIcon = () => (
  // @ts-ignore
  <Svg style={{ width: 12, height: 12, marginTop: 0 }}>
    <Path
      d="M0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6Z"
      fill="#FF9800"
    />
    <Path
      d="M6 3.2998V6.2998"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
      strokeWidth="1.75"
    />
    <Circle cx="5.99961" cy="8.52012" fill="white" r="0.9" />
  </Svg>
);

export default WarningIcon;
