import { color } from '../../constants';

interface Props {
  colorProp?: string;
  height?: number;
  id: string;
  width?: number;
}

const IconDiagnose = ({ colorProp, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 24}
    id={id}
    viewBox={`0 0 ${width || 24} ${height || 24}`}
    width={width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 3V5H6V9C6 11.21 7.79 13 10 13C12.21 13 14 11.21 14 9V5H12V3H15C15.2652 3 15.5196 3.10536 15.7071 3.29289C15.8946 3.48043 16 3.73478 16 4V9C15.9998 10.418 15.4976 11.7901 14.5824 12.8732C13.6672 13.9562 12.3981 14.6803 11 14.917V16.5C10.9995 17.3196 11.2866 18.1134 11.8113 18.7431C12.336 19.3727 13.0651 19.7982 13.8714 19.9454C14.6776 20.0926 15.51 19.9522 16.2234 19.5486C16.9368 19.1451 17.4859 18.5039 17.775 17.737C17.1315 17.4484 16.6103 16.9424 16.3028 16.3078C15.9953 15.6732 15.9212 14.9505 16.0935 14.2666C16.2658 13.5828 16.6735 12.9815 17.245 12.5684C17.8165 12.1552 18.5153 11.9566 19.2187 12.0074C19.9221 12.0583 20.5851 12.3552 21.0913 12.8462C21.5975 13.3372 21.9145 13.9908 21.9868 14.6923C22.059 15.3938 21.8818 16.0983 21.4863 16.6822C21.0908 17.266 20.5023 17.6919 19.824 17.885C19.4881 19.1808 18.6911 20.3096 17.5825 21.0598C16.4739 21.81 15.1297 22.1302 13.8019 21.9603C12.4741 21.7904 11.2539 21.1421 10.3699 20.1369C9.4859 19.1317 8.99884 17.8386 9 16.5V14.917C7.60184 14.6805 6.33261 13.9565 5.41736 12.8734C4.50211 11.7902 3.99998 10.418 4 9V4C4 3.73478 4.10536 3.48043 4.29289 3.29289C4.48043 3.10536 4.73478 3 5 3H8ZM19 14C18.7348 14 18.4804 14.1054 18.2929 14.2929C18.1054 14.4804 18 14.7348 18 15C18 15.2652 18.1054 15.5196 18.2929 15.7071C18.4804 15.8946 18.7348 16 19 16C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15C20 14.7348 19.8946 14.4804 19.7071 14.2929C19.5196 14.1054 19.2652 14 19 14Z"
      fill={colorProp || color('primary', 500)}
    />
  </svg>
);

export default IconDiagnose;
