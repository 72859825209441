import { createUseStyles } from 'react-jss';
import { color } from '../../constants';
import { breakpoints, getRem } from '@dovera/design-system';

export default createUseStyles({
  navigationWrapper: {
    background: color('secondary', 500),
    position: 'fixed',
    width: 340,
    height: 'calc(100vh - 55px)',
    zIndex: 1,
    left: 0,
    transition: 'transform .5s ease-in-out',
    padding: `${getRem(24)} ${getRem(24)} ${getRem(24)} ${getRem(16)}`,
    overflow: 'auto',
    top: 56,
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      top: 0,
      height: '100vh',
      zIndex: 999999,
      left: 'unset',
      right: 0,
    },
  },
  navigation: {
    paddingBottom: 55,
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      paddingTop: 30,
    },
  },
  item: {
    width: '100%',
    listStyle: 'none',
    padding: 0,
    marginBottom: 0,
    '& li ul': {
      display: 'none',
    },
    '& a, & button': {
      cursor: 'pointer',
      borderRadius: 8,
      padding: '10px 23px',
      width: '100%',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      color: '#FFFFFF',
      '& > span': {
        display: 'flex',
        alignItems: 'center',
        '& svg:first-child': {
          marginRight: getRem(8),
          marginTop: -2,
        },
      },
      '&.hasIcon': {
        padding: `${getRem(10)} ${getRem(23)} ${getRem(10)} ${getRem(12)}`,
      },
      '&.inactive': {
        cursor: 'initial !important',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '& ~ ul': {
          display: 'block',
        },
      },
      '&.active': {
        backgroundColor: color('secondary', 600),
        '&:hover': {
          backgroundColor: color('secondary', 600),
        },
      },
      '&:hover': {
        backgroundColor: 'rgba(27,72,136,.5)',
      },
      [`@media screen and (max-width: ${breakpoints.l}px)`]: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    '&.navigation_order': {
      '&__order4': {
        '& a:first-child, & button:first-child': {
          fontSize: 16,
          fontWeight: 400,
          color: 'rgba(255, 255, 255, .7)',
          marginLeft: 32,
          width: 'calc(100% - 32px)',
          marginBottom: 6,
        },
      },
      '&__order3': {
        '& a:first-child, & button:first-child': {
          fontSize: 16,
          fontWeight: 400,
          color: 'rgba(255, 255, 255, .7)',
          marginLeft: 16,
          width: 'calc(100% - 16px)',
          marginBottom: 0,
        },
      },
      '&__order2': {
        '& a:first-child, & button:first-child': {
          fontSize: 16,
          fontWeight: 400,
          color: '#FFFFFF',
          paddingLeft: getRem(45),
          width: '100%',
          marginBottom: 0,
          '&.hasIcon': {
            paddingLeft: getRem(20),
          },
          '&.inactive': {
            paddingLeft: getRem(16),
            '& svg:first-child': {
              marginRight: getRem(12),
            },
          },
        },
        marginBottom: getRem(8),
      },
      '&__order1': {
        '& a:first-child, & button:first-child': {
          fontSize: 18,
          fontWeight: 500,
          color: '#FFFFFF',
          marginBottom: 12,
        },
        '& a.no-children': {
          padding: `${getRem(10)} ${getRem(12)}`,
        },
      },
      '&__open': {
        '& > li > ul': {
          display: 'block',
        },
      },
    },
  },
  angle: {
    position: 'absolute',
    right: 35,
    marginTop: 2,
    '&.angle_order': {
      right: 37,
    },
    '& svg': {
      marginRight: '0 !important',
    },
    '&.active': {
      transform: 'rotate(180deg)',
      marginTop: 0,
    },
  },
  closeMenu: {
    cursor: 'pointer',
    right: getRem(28),
    position: 'fixed',
    padding: 7,
    backgroundColor: 'rgba(31, 87, 165, .7)',
    borderRadius: 8,
    top: 12,
  },
  logout: {
    marginTop: getRem(20),
    borderTop: `1px solid rgba(210, 229, 255, 0.5)`,
    padding: `${getRem(28)} ${getRem(23)} ${getRem(8)} ${getRem(14)}`,
    width: '100%',
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'white',
    fontSize: getRem(18),
    display: 'flex',
    '& svg': {
      marginRight: getRem(8),
    },
  },
});
