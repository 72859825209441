import { color } from '@dovera/design-system';
import { ReactNode } from 'react';
import { IconError, IconSuccess } from '../CustomIcons';
import IconWarningCircle from '../CustomIcons/IconWarningCircle';
import { cx } from '../../utils/exports';
import {
  LineColor,
  LineStatus,
  LineWrapperClass,
  PointerColor,
  ValuePointIcon,
} from './types';
import { valueFormat } from '../../utils/parameters.utils';
import { Status } from '../../types/parameters.types';

const convertValue = (val: number): number => (val < 1 ? val * 100 : val);

export const getValuePointIcon = ({
  isSuccess,
  state,
}: ValuePointIcon): ReactNode => {
  if (isSuccess)
    return (
      <IconSuccess
        color={color('success')}
        height={16}
        id={`compare-chart--icon-success`}
        width={16}
      />
    );
  return state === 'warning' ? (
    <IconWarningCircle
      height={16}
      id={`compare-chart--icon-warning`}
      width={16}
    />
  ) : (
    <IconError height={16} id={`compare-chart--icon-error`} width={16} />
  );
};

export const getPointerColor = ({
  borderValueBottom,
  borderValueTop,
  groupIndex,
  isChartRotated,
  value,
}: PointerColor): string => {
  switch (true) {
    case groupIndex === 0 && !isChartRotated:
      return color('error');
    case groupIndex === 0 && isChartRotated:
      return '#4CAF50';
    case value ===
      Math.round(borderValueTop < 1 ? borderValueTop * 100 : borderValueTop):
    case borderValueBottom &&
      value ===
        Math.round(
          borderValueBottom < 1 ? borderValueBottom * 100 : borderValueBottom,
        ):
      return color('grey', 500);
    case isChartRotated:
      return color('error');
    default:
      return '#4CAF50';
  }
};

export const getLineStatus = ({
  classes,
  isBeforeBorder,
  isBetweenBorders,
  isChartRotated,
  realValue,
  valueType,
}: LineStatus): ReactNode => {
  let icon = (
    <IconError height={16} id={`compare-chart--icon-error`} width={16} />
  );
  if (
    (isBeforeBorder && isChartRotated) ||
    (!isBeforeBorder && !isChartRotated) ||
    isBetweenBorders
  )
    icon = (
      <IconSuccess
        color={color('success')}
        height={16}
        id={`compare-chart--icon-success`}
        width={16}
      />
    );
  return (
    <span
      className={cx(
        classes.valueLabel,
        (isBeforeBorder && isChartRotated) ||
          (!isBeforeBorder && !isChartRotated) ||
          isBetweenBorders
          ? 'success'
          : 'error',
      )}
    >
      {icon}
      Iný lekár: {valueFormat(realValue, undefined, valueType)}
    </span>
  );
};

export const getLineWrapperClass = ({
  borderValueBottom,
  borderValueTop,
  groupIndex,
  index,
  value,
}: LineWrapperClass): string => {
  if (groupIndex === 0 && index === 0) return 'lefted';
  if (
    index === 0 &&
    (value === Math.round(convertValue(borderValueTop)) ||
      (borderValueBottom &&
        value === Math.round(convertValue(borderValueBottom))))
  )
    return 'centered';
  return '';
};

export const getLineColor = ({
  isBeforeBorder,
  isBetweenBorders,
  isChartRotated,
  isHovered,
}: LineColor): string => {
  const opacity = isHovered ? 1 : 0.5;
  if (
    (isBeforeBorder && isChartRotated) ||
    (!isBeforeBorder && !isChartRotated)
  )
    return `rgba(76, 175, 80, ${isHovered ? 1 : 0.8})`;
  if (isBeforeBorder && !isBetweenBorders && !isChartRotated)
    return `rgba(230, 5, 35, ${isHovered ? 1 : 0.8})`;
  return isBetweenBorders
    ? `rgba(76, 175, 80, ${opacity})`
    : `rgba(230, 5, 35, ${opacity})`;
};

export const getLineColorNew = ({
  isHovered,
  status,
}: {
  isHovered: boolean;
  status?: Status;
}): string => {
  const opacity = isHovered ? 1 : 0.5;
  if (status === 'success') return `rgba(76, 175, 80, ${opacity})`;
  if (status === 'warning') return `rgba(183, 219, 162, ${opacity})`;
  if (status === 'error') return `rgba(230, 5, 35, ${opacity})`;
  return '#6D7175';
};
