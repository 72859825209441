import { useEffect } from 'react';
import useStyles from '../Profil.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { fetchBankoveSpojenia } from '../../../slices/profil.slice';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import {
  Container,
  Link,
  Loader,
  PageSection,
  TableApp,
} from '@dovera/design-system';
import BackButton from '../../../components/BackButton/BackButton';
import routes from '../../../routes';
import { epSekcie } from '../../../constants/epLinks';
import { cx } from '../../../utils/exports';
import { NoData } from '../../../components/EmptyState';
import IconBankCard from '../../../components/CustomIcons/IconBankCard';
import { formatDate } from '../../../utils/date.utils';
import { formatIBAN, hotjarMasking } from '../../../utils/strings.utils';
import { useAppDispatch } from '../../../hooks/useStore';
import { getPZS } from '../../../slices/auth.slice';

const BankoveSpojenie = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const idHz = useSelector((state: RootState) => state.poskytovatel.idHz);
  const { data, error, isLoading } = useSelector(
    (state: RootState) => state.profil.bankoveSpojenie,
  );
  useEffect(() => {
    if (idHz && !isLoading)
      dispatch(fetchBankoveSpojenia(pzs.userId || 0, idHz, pzs.vzId || 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, idHz]);
  const renderDescription = (
    <div className={classes.description}>
      <p className="no-mrg">Tieto bankové účty evidujeme v poisťovni.</p>
      <p className="no-mrg">
        Ak chcete pridať alebo upraviť účty, oznámte nám to cez{' '}
        <Link href={epSekcie.spravy} placeholder target="_blank">
          Schránku správ
        </Link>
        .
      </p>
    </div>
  );
  const renderTable = data && (
    <TableApp
      caption=""
      className="table-caption--hide"
      columns={[
        {
          accessor: 'col1',
          Header: 'IBAN',
        },
        {
          accessor: 'col2',
          Header: 'Platnosť od',
        },
        {
          accessor: 'col3',
          Header: 'Poznámka',
        },
      ]}
      data={data.map((d) => ({
        col1: <div>{hotjarMasking(formatIBAN(d.IBAN || '-'))}</div>,
        col2: <div>{formatDate(d.datumOd)}</div>,
        col3: <div>{d.poznamka ? d.poznamka : '-'}</div>,
      }))}
    />
  );
  return (
    <StaticMenuLayout backgroundWhite hideEPNotifications withoutPadding>
      <PageSection className={classes.section}>
        <Container>
          <BackButton route={routes.profil} text="Späť" />
          <h3 className={cx(classes.title, 'mb-small')}>
            <IconBankCard id="bankove-spojenie-title" /> Bankové spojenie
          </h3>
          {renderDescription}
          {isLoading && (
            <>
              <div className="mb" />
              <Loader size={24} />
            </>
          )}
          {error && <NoData dontOpenMenu illustration="woman" text={error} />}
          {data && !data.length && !isLoading && (
            <NoData
              dontOpenMenu
              illustration="woman-question"
              text="Neevidujeme žiadne bankové spojenie"
            />
          )}
          {data && data.length > 0 && renderTable}
        </Container>
      </PageSection>
    </StaticMenuLayout>
  );
};

export default BankoveSpojenie;
