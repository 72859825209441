import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import {
  FetchBannersPayload,
  FetchBannersResponse,
  HideBannerPayload,
  HideBannerResponse,
} from '../types/baner.types';

const API_URL = `${window.env?.config?.apiPath}/Baner/`;

export const fetchBanners = createAsyncThunk(
  'baner/fetchBanners',
  async (req: FetchBannersPayload) => {
    const url = `${API_URL}DajBanery`;
    const { data }: AxiosResponse<FetchBannersResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const hideBanner = createAsyncThunk(
  'baner/hideBanner',
  async (req: HideBannerPayload) => {
    const url = `${API_URL}SkryBaner`;
    const { data }: AxiosResponse<HideBannerResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);
