interface Props {
  id: string;
}

const IconArrowLeftLong = ({ id, ...rest }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line
    {...rest}
  >
    <path
      clipRule="evenodd"
      d="M10.7071 5.29289C11.0976 5.68342 11.0976 6.31658 10.7071 6.70711L6.41421 11H20.0556C20.5772 11 21 11.4477 21 12C21 12.5523 20.5772 13 20.0556 13H6.41421L10.7071 17.2929C11.0976 17.6834 11.0976 18.3166 10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929L9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289Z"
      fill="#3f7c22"
      fillRule="evenodd"
    />
  </svg>
);

export default IconArrowLeftLong;
