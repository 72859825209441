import { ReactNode } from 'react';
import strings from '../constants/strings';
import store from '../store';
import { NaRiesenieInterface } from '../types/poskytovatel.types';
import { onlyText, text } from './strings.utils';
import SafeHtml from '../components/SafeHtml/SafeHtml';
import { ActualityType } from '../types/dashboard.types';
import routes, { diagnozyRoutes } from '../routes';

/** Funkcia pre GYN - odbornost 009, nema kategoriu Inovacie, ma specialnu kategoriu - "Zaklad DodKap" */
export const isGYN009 = (
  catTitle: string,
  kodOdbAmb: string,
  kodTypZS: string,
): boolean =>
  catTitle.toLowerCase().includes('špeciál') &&
  kodOdbAmb === '009' &&
  kodTypZS !== '200';

export const cardTitle = (
  catTitle: string,
  kodOdbAmb: string,
  kodTypZS: string,
): string => {
  if (isGYN009(catTitle, kodOdbAmb, kodTypZS))
    return onlyText(`dashboard.zakladDodKap.title`);
  return catTitle;
};

export const getSolveWidgetData = (
  type: string,
): NaRiesenieInterface | null => {
  if (!store) return null;
  const { naRiesenie } = store.getState().widgetNaRiesenie;
  return (
    naRiesenie?.filter((r) =>
      r.nadpis.toLowerCase().includes(type.toLowerCase()),
    )?.[0] || null
  );
};

export const getActualityRowTitle = (
  code: ActualityType | null,
  title: string,
): string => {
  const staticTexts = strings.dashboard.notifications;
  switch (code) {
    case 'UPD_HK':
      return staticTexts.codeHP;
    case 'UPD_DLEKPOI_NAKL':
      return staticTexts.codeDiag;
    case 'UPD_DLEKPOI':
      return staticTexts.codePP;
    default:
      return title;
  }
};

export const gtActualityRowDescription = (
  code: ActualityType | null,
): ReactNode | null => {
  const staticTexts = strings.dashboard.news;
  if (code?.includes('UPD_DLEK_POI'))
    return (
      <div>
        {text(
          `dashboard.news.${
            code === 'UPD_DLEKPOI' ? 'textDlekPoi' : 'textDlekPoiDiag'
          }`,
        )}
      </div>
    );
  if (code === 'ZUC_CHYB_PROT')
    return <SafeHtml html={staticTexts.textDlekZucChybProt} />;

  return null;
};

export const getActualityRowUrl = (code: ActualityType | null): string => {
  switch (code) {
    case 'UPD_HK':
      return routes.parameters;
    case 'UPD_DLEKPOI':
      return `${routes.pp}/farmakoterapia?typPacientov=FARM_HYP_NESP`;
    case 'UPD_DLEKPOI_NAKL':
      return diagnozyRoutes.celkoveNakldy;
    case 'ZUC_CHYB_PROT':
      return routes.faktury;
    default:
      return routes.dashboard;
  }
};
