interface Props {
  color?: string;
}

const IconCircleCheck = ({ color }: Props) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_30605_50675)">
      <path
        d="M0.233398 8C0.233398 12.4183 3.81512 16 8.2334 16C12.6517 16 16.2334 12.4183 16.2334 8C16.2334 3.58172 12.6517 0 8.2334 0C3.81512 0 0.233398 3.58172 0.233398 8Z"
        fill={color || '#50A028'}
      />
      <path
        d="M4.2334 8L7.2334 11L12.2334 5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_30605_50675">
        <rect
          fill="white"
          height="16"
          transform="translate(0.233398)"
          width="16"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconCircleCheck;
