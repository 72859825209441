import { Notification } from '@dovera/design-system';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import SafeHtml from '../SafeHtml/SafeHtml';
import { formatNtfDate, getNtfMessage } from './utils';
import useStyles from './styles';
import { cx } from '../../utils/exports';
import { getSystemNotifications } from '../../api';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import { useAppDispatch } from '../../hooks/useStore';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import moment from 'moment';

interface NotificationProps {
  from: {
    date: string;
    time: string;
  };
  message: string;
  to: {
    date: string;
    time: string;
  };
}

interface Props {
  contentInsideHeader?: boolean;
  inPageContent?: boolean;
}

const EPNotifications = ({ contentInsideHeader, inPageContent }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const isVZLoaded = useSelector(
    (state: RootState) => state.poskytovatel.isLoaded,
  );
  const { data, lastLoaded } = useSelector(
    (state: RootState) => state.app.epNotifications,
  );
  const renderNotification = ({
    from,
    message,
    to,
  }: NotificationProps): ReactNode => {
    const body: ReactNode = (
      <SafeHtml
        html={getNtfMessage({
          dateFrom: formatNtfDate(from.date, from.time),
          dateTo: formatNtfDate(to.date, to.time),
          message,
          ntfCount: data.filter((d) => d.zobrazitOznam).length,
        })}
      />
    );
    return <Notification message={body} variant="warning" />;
  };
  const getNotifications = useCallback(() => {
    if (
      isVZLoaded &&
      !isLoaded &&
      (!lastLoaded || moment(lastLoaded) < moment().subtract(2, 'minutes'))
    ) {
      dispatch(getSystemNotifications());
      setIsLoaded(true);
    }
  }, [dispatch, isLoaded, isVZLoaded, lastLoaded]);
  useEffect(() => {
    getNotifications();
  }, [getNotifications]);
  if (!data?.length) return <span />;
  const renderNtfs = (
    <div
      className={cx(
        classes.epNotifications,
        inPageContent && classes.pageContent,
      )}
    >
      {data
        .filter((d) => d.zobrazitOznam)
        .map((d) =>
          renderNotification({
            from: {
              date: d.datumOd,
              time: d.casOd,
            },
            message: d.popis,
            to: {
              date: d.datumDo,
              time: d.casDo,
            },
          }),
        )}
    </div>
  );

  if (inPageContent)
    return (
      <ContentWrapper
        className={cx(contentInsideHeader && classes.blueSection)}
        id="ep-notifications"
      >
        {renderNtfs}
      </ContentWrapper>
    );

  return renderNtfs;
};

export default EPNotifications;
