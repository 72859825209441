import { createUseStyles } from 'react-jss';
import { borderRadius, color } from '../../constants/index';
import { getRem } from '@dovera/design-system';

interface StylesProps {
  noMarginBottom?: boolean;
}

export default createUseStyles({
  wrapper: ({ noMarginBottom }: StylesProps) => ({
    position: 'relative',
    '&.is-success .input-addons__item': {
      display: 'none',
    },
    '&.is-success .input-addons .input': {
      borderRadius: `${borderRadius}px`,
    },
    '& textarea': {
      height: 'auto',
      minHeight: getRem(48),
      paddingTop: getRem(12),
      overflow: 'hidden !important',
      maxHeight: '100% !important',
      '&.input': {
        border: 'none !important',
      },
    },
    '&.init-height': {
      '& textarea': {
        height: `${getRem(48)} !important`,
      },
    },
    '& .input-addons__item': {
      height: 'auto',
    },
    '& .form-control': {
      marginBottom: noMarginBottom ? '0' : 'auto',
    },
  }),
  wrapperDisabled: {
    pointerEvents: 'none',
  },
  loader: {
    color: color('grey', 500),
  },
  isDisabled: {
    color: color('grey', 300),
    backgroundColor: color('grey', 50),
    '&:hover': {
      color: color('grey', 300),
      backgroundColor: color('grey', 50),
      cursor: 'not-allowed',
    },
  },
});
