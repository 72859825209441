interface Props {
  id: string;
}

const IconKey = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4 10.5C4 8.567 5.567 7 7.5 7C9.433 7 11 8.567 11 10.5C11 12.433 9.433 14 7.5 14C5.567 14 4 12.433 4 10.5ZM7.5 5C4.46243 5 2 7.46243 2 10.5C2 13.5376 4.46243 16 7.5 16C10.0176 16 12.14 14.3085 12.793 12H16V14C16 14.5523 16.4477 15 17 15C17.5523 15 18 14.5523 18 14V12H20V15C20 15.5523 20.4477 16 21 16C21.5523 16 22 15.5523 22 15V11C22 10.4477 21.5523 10 21 10H17H12.9776C12.725 7.19675 10.369 5 7.5 5Z"
      fill="#1F57A5"
      fillRule="evenodd"
    />
  </svg>
);

export default IconKey;
