import { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import { Select } from '@dovera/design-system';
import { useQuery } from '../../../hooks/useQuery';
import { FILTER_PREFIX } from '../constants';
import { SelectType } from '../types';
import { createSelectEvent } from '../utils';

const SelectFilter = ({ ...props }: SelectType) => {
  const {
    id,
    initWithDelay,
    onChange = () => {},
    options,
    value,
    ...restProps
  } = props;

  const [filterValue, setFilterValue] = useState(value);

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
    if (queryValue && options?.length) {
      const timeout = initWithDelay ? 700 : 200;
      setTimeout(() => {
        handleSelect(queryValue);
      }, timeout);
    }
    // eslint-disable-next-line
  }, [initWithDelay]);

  const handleSelect = (val) => {
    searchParams.set(`${FILTER_PREFIX}_${id}`, val);
    setSearchParams(searchParams);
    if (
      typeof onChange === 'function' &&
      id &&
      document.querySelector(`#${id}`)
    ) {
      onChange(val.toString(), createSelectEvent(id, val.toString()));
    }
    setFilterValue(val);
  };

  return (
    <Select
      id={id || ''}
      onChange={(val) => handleSelect(val)}
      options={options}
      value={filterValue}
      {...restProps}
    />
  );
};

export default SelectFilter;
