interface Props {
  color?: string;
  id?: string;
}

const IconCalc = ({ color, id }: Props) => {
  const COLOR = color || '#BED2F4';
  const ID = id || '';
  return (
    <svg
      fill="none"
      height="24"
      id={ID}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2 4C2 1.79086 3.79086 0 6 0H18C20.2091 0 22 1.79086 22 4V8V20C22 22.2091 20.2091 24 18 24H6C3.79086 24 2 22.2091 2 20V4ZM6 2C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8V4C20 2.89543 19.1046 2 18 2H6ZM6 6C6 4.89543 6.89543 4 8 4H16C17.1046 4 18 4.89543 18 6V8C18 9.10457 17.1046 10 16 10H8C6.89543 10 6 9.10457 6 8V6ZM16 6H8V8H16V6ZM8 18C7.44772 18 7 18.4477 7 19C7 19.5523 7.44772 20 8 20H8.1C8.65228 20 9.1 19.5523 9.1 19C9.1 18.4477 8.65228 18 8.1 18H8ZM11 19C11 18.4477 11.4477 18 12 18H12.1C12.6523 18 13.1 18.4477 13.1 19C13.1 19.5523 12.6523 20 12.1 20H12C11.4477 20 11 19.5523 11 19ZM8 15C7.44772 15 7 15.4477 7 16C7 16.5523 7.44772 17 8 17H8.1C8.65228 17 9.1 16.5523 9.1 16C9.1 15.4477 8.65228 15 8.1 15H8ZM11 16C11 15.4477 11.4477 15 12 15H12.1C12.6523 15 13.1 15.4477 13.1 16C13.1 16.5523 12.6523 17 12.1 17H12C11.4477 17 11 16.5523 11 16ZM15 19C15 19.5523 15.4477 20 16 20C16.5523 20 17 19.5523 17 19V16C17 15.4477 16.5523 15 16 15C15.4477 15 15 15.4477 15 16V19Z"
        fill={COLOR}
        fillRule="evenodd"
      />
      <path
        d="M8 13H8.1"
        stroke={COLOR}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M12 13H12.1"
        stroke={COLOR}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M16 13H16.1"
        stroke={COLOR}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconCalc;
