interface Props {
  color?: string;
  height?: number;
  id?: string;
  width?: number;
}

function IconAngle({ color, height, id, width }: Props) {
  return (
    <svg
      fill="none"
      height={height || 6}
      id={id}
      viewBox="0 0 10 6"
      width={width || 10}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976305 1.31658 -0.0976305 0.683418 0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5 3.58579L8.29289 0.292893Z"
        fill={color || '#1F252A'}
      />
    </svg>
  );
}

export default IconAngle;
