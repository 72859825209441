import {
  AttributesDataThermometer,
  Status,
} from '../../types/parameters.types';
import { getPercentage } from '../../utils/number.utils';
import SafeHtml from '../SafeHtml/SafeHtml';
import useStyles from './LineChart.styles';

interface Props {
  data: AttributesDataThermometer[];
  status: Status;
}

const LineChart = ({ data, status }: Props) => {
  const firstMark = data.find((item) => item.type === 'mark');
  const secondMark = data.find(
    (item) => item.type === 'mark' && item.x !== firstMark?.x,
  );
  const fill = data.find((item) => item.type === 'fill')?.x;

  const classes = useStyles({
    percentage: fill ? getPercentage(fill) : 0,
    firstMarkValue: firstMark ? getPercentage(firstMark.x) : 0,
    secondMarkValue: secondMark ? getPercentage(secondMark.x) : 0,
    status,
  });

  return (
    <div className={classes.root}>
      <div className={classes.baseGraph}>
        <div className={classes.fillGraph} />
        <div className={classes.point1} />
        <div className={classes.point2} />
        {firstMark && (
          <div className={classes.label1}>
            <SafeHtml html={firstMark.text} wrapper="span" />
          </div>
        )}
        {secondMark && (
          <div className={classes.label2}>
            <SafeHtml html={secondMark.text} wrapper="span" />
          </div>
        )}
      </div>
    </div>
  );
};

export default LineChart;
