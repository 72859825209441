import { useMemo, useReducer } from 'react';

interface Action<ActionType> {
  payload?: any;
  type: ActionType;
}

type Dispatch<T> = (action: Action<T>) => void;

interface Reducers<State> {
  [key: string]: (state: State, payload: any) => Partial<State>;
  init(): State;
}

const createActions = <State, R extends Reducers<State>>(
  reducers: R,
  dispatch: Dispatch<string>,
) => {
  const actionTypes = Object.keys(reducers);
  return actionTypes.reduce(
    (accumulator, type) => ({
      [type]: (payload?: object): void => dispatch({ type, payload }),
      ...accumulator,
    }),
    // Having types for the payload would be wonderful.
    {} as Record<keyof R, (payload?: object) => void>,
  );
};

const createStore = <State, R extends Reducers<State>>(reducers: R) => {
  const dispatchReducer = (state: State, { payload, type }) => {
    const fn = reducers[type] ?? (() => ({}));
    const stateDelta = fn(state, payload);
    return { ...state, ...stateDelta };
  };

  const actions = (dispatch) => createActions<State, R>(reducers, dispatch);

  function useStore() {
    const [state, dispatch] = useReducer(dispatchReducer, reducers.init());
    const memoizedActions = useMemo(() => actions(dispatch), []);
    return useMemo(
      (): [State, typeof memoizedActions] => [state, memoizedActions],
      [state, memoizedActions],
    );
  }

  return useStore;
};

export default createStore;
