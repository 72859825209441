import { createSlice } from '@reduxjs/toolkit';

interface UserActivityState {
  showLogoutWarningModal: boolean;
}

const initialState: UserActivityState = {
  showLogoutWarningModal: false,
};

const activitySlice = createSlice({
  name: 'userActivity',
  initialState,
  reducers: {
    showLogoutWarning(state) {
      state.showLogoutWarningModal = true;
    },
    hideLogoutWarning(state) {
      state.showLogoutWarningModal = false;
    },
  },
});

export const { hideLogoutWarning, showLogoutWarning } = activitySlice.actions;

export default activitySlice.reducer;
