import { Notification } from '@dovera/design-system';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ulozSpravu } from '../../../api/schrankaSprav';
import useAppStyles from '../../../App.styles';
import { CancelActionModal } from '../../../components/CancelActionModal';
import regex from '../../../constants/regex';
import strings from '../../../constants/strings';
import { useFilterContext } from '../../../hooks/useFilterContext';
import usePreventAnchorClick from '../../../hooks/usePreventAnchorClick';
import { useAppDispatch } from '../../../hooks/useStore';
import { RootState } from '../../../rootReducer';
import { getPZS } from '../../../slices/auth.slice';
import { feedbackTrigger } from '../../../slices/feedback.slices';
import {
  resetSchranky,
  resetTem,
  zobrazitToast,
} from '../../../slices/spravy.slice';
import { ApiError } from '../../../types/api';
import { Dokument } from '../../../types/models/Dokument';
import { VALIDATION } from '../../../types/validation.types';
import { appActionsVisibility, createViewUrl } from '../../../utils/app.utils';
import { getUserId } from '../../../utils/auth.utils';
import { DEFAULT_SELECT_VALUE } from '../../../utils/dds.utils';
import { cx } from '../../../utils/exports';
import { validationMsg } from '../../../utils/form.utils';
import { callThreadDetail } from '../../../utils/spravy.utils';
import useStyles from '../Spravy.styles';
import UploadFiles from '../UploadFiles/UploadFiles';
import FormButtons from './FormButtons';
import FormFields from './FormFields';

interface Props {
  escapeModal?: boolean;
  hasPzsEmail?: boolean;
  isReply?: boolean;
  onCancel: (isCancelVisible?: boolean) => void;
  onContinueModal?: () => void;
  pzsEmail: string | null;
  threadId?: number;
}

const validate = (
  values: { [x: string]: any },
  hasPzsEmail?: boolean,
  isReply?: boolean,
) => {
  const errors: any = {};
  if (!hasPzsEmail && !values.email)
    errors.email = validationMsg(VALIDATION.RequiredEmpty);
  if (!hasPzsEmail && values.email && !regex.EMAIL.test(values.email))
    errors.email = validationMsg(VALIDATION.EmailIncorrect);
  if (!isReply && (!values.theme || values.theme === DEFAULT_SELECT_VALUE))
    errors.theme = validationMsg(VALIDATION.RequiredEmpty);
  if (
    !isReply &&
    values.theme &&
    (!values.subtheme || values.subtheme === DEFAULT_SELECT_VALUE)
  )
    errors.subtheme = validationMsg(VALIDATION.RequiredEmpty);
  if (!values.message) errors.message = validationMsg(VALIDATION.RequiredEmpty);
  return errors;
};

const texts = strings.schrankaSprav;

const MessageForm = ({
  escapeModal,
  hasPzsEmail,
  isReply,
  onCancel,
  onContinueModal,
  pzsEmail,
  threadId,
}: Props) => {
  const classes = useStyles({});
  const classesApp = useAppStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const filterContext = useFilterContext();
  const [requiredAttachmentUrl, setRequiredAttachmentUrl] = useState('');
  const [uploadErrorValidation, setUploadErrorValidation] = useState('');
  const [files, setFiles] = useState<Dokument[] | null>(null);
  const [resetFiles, setResetFiles] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [subthemeLoading, setSubthemeLoading] = useState(false);
  const [routeAfterCancel, setRouteAfterCancel] = useState('#');
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const {
    detail,
    temy: { data, dataState },
  } = useSelector((state: RootState) => state.spravy);
  const { form, handleSubmit } = useForm({
    onSubmit: (values) => {
      if (requiredAttachmentUrl && !files?.length) {
        setUploadErrorValidation('Nahrajte súbor.');
      } else {
        setError('');
        setLoading(true);
        let { email } = values;
        if (hasPzsEmail) email = detail.emailVlakna;
        filterContext.removeParams(['tema-spravy', 'podtema-spravy']);
        saveMessage(values, email);
      }
    },
    validate: (values: any) => validate(values, hasPzsEmail, isReply),
  });

  const saveMessage = (values, email) => {
    ulozSpravu({
      email: email || pzsEmail,
      obsahSpravy: values.message,
      prilohy: files,
      temaId: Number(values.subtheme || values.theme) || null,
      vlaknoId: threadId || 0,
    })
      .then((data) => {
        appActionsVisibility(true);
        if (data.chyba || !data.vysledok)
          setError(texts.sendMessage.error || strings.defaultError);
        if (!data.chyba && data.vysledok) {
          dispatch(zobrazitToast({ typ: 'odoslanie' }));
          if (!isReply) {
            dispatch(resetSchranky());
          } else {
            callThreadDetail({ id: threadId, scrollToBottom: true });
          }
          resetForm();
          setTimeout(() => {
            onCancel();
          });
        }
      })
      .catch((err: AxiosError<ApiError>) => {
        const { response } = err;
        setError(
          texts.sendMessage.error ||
            response?.data?.text ||
            strings.defaultError,
        );
      })
      .finally(() => {
        setLoading(false);
        dispatch(feedbackTrigger({ delay: 5, userId: getUserId(pzs.userId) }));
      });
  };

  const email = useField('email', form);
  const theme = useField('theme', form, (value) => {
    if (value !== theme.input.value) {
      setSubthemeLoading(true);
      if (data.some((d) => d.id === Number(value) && d.podtypy?.length))
        setTimeout(() => {
          setSubthemeLoading(false);
        }, 500);
    }
  });
  const subtheme = useField('subtheme', form);
  const message = useField('message', form);

  const resetForm = useCallback(
    (onlyFieldStates?: boolean) => {
      form.resetFieldState('email');
      form.resetFieldState('message');
      form.resetFieldState('theme');
      form.resetFieldState('subtheme');
      if (!onlyFieldStates) {
        dispatch(resetTem());
        appActionsVisibility(true);
        form.reset();
        setFiles(null);
        setResetFiles(true);
        setError('');
        setTimeout(() => {
          setResetFiles(false);
        }, 500);
      }
    },
    [dispatch, form],
  );

  const isFilledField: boolean =
    email.input.value ||
    theme.input.value ||
    message.input.value ||
    files?.length;

  useEffect(() => {
    appActionsVisibility(!isFilledField);
    if (onContinueModal) resetForm(true);
    // eslint-disable-next-line
  }, [onContinueModal, isFilledField]);

  useEffect(() => {
    if (escapeModal && !!isFilledField) setCancelModalVisible(true);
  }, [escapeModal, isFilledField]);

  useEffect(() => {
    const currentTheme =
      data?.find((d) => d.id.toString() === theme.input.value) || null;
    setRequiredAttachmentUrl(
      currentTheme?.podtypy?.find(
        (p) =>
          p.id.toString() === subtheme.input.value &&
          p.povinnostPrilohy &&
          p.dokumentUrl,
      )?.dokumentUrl || '',
    );
  }, [data, theme.input.value, subtheme.input.value]);

  useEffect(() => {
    if (!requiredAttachmentUrl) setUploadErrorValidation('');
  }, [requiredAttachmentUrl]);

  const onCancelClick = useCallback(() => {
    if (isFilledField) {
      setCancelModalVisible(true);
      if (onContinueModal) onCancel(true);
    } else {
      onCancel();
    }
  }, [isFilledField, onCancel, onContinueModal]);

  const cancelFormCallback = useCallback(() => {
    onCancel();
    if (!onContinueModal) navigate(createViewUrl(routeAfterCancel));
    resetForm();
    setCancelModalVisible(false);
    navigate(location.pathname);
  }, [
    onCancel,
    onContinueModal,
    navigate,
    routeAfterCancel,
    resetForm,
    location.pathname,
  ]);

  usePreventAnchorClick(!!isFilledField, (e) => {
    const t = e?.target;
    // @ts-ignore
    const href = !t?.href ? t?.closest('a')?.href : t?.href || '#';
    setRouteAfterCancel(href);
    onCancelClick();
    return false;
  });

  const renderUploadArea = (
    <UploadFiles
      onChange={(f) => {
        setUploadErrorValidation('');
        setFiles(f);
      }}
      onUploadError={(isErr) => setUploadError(isErr)}
      reset={resetFiles}
      validationError={uploadErrorValidation}
    />
  );
  const renderCancelModal = (
    <CancelActionModal
      isFormModal={!!onContinueModal}
      isVisible={cancelModalVisible}
      onCancel={cancelFormCallback}
      onContinue={() => {
        setCancelModalVisible(false);
        if (onContinueModal) onContinueModal();
      }}
      onHide={() => setCancelModalVisible(false)}
      texts={{
        btnCancel: texts.modals.cancelNewMessage.buttons.cancel,
        btnContinue: texts.modals.cancelNewMessage.buttons.continue,
        description: texts.modals.cancelNewMessage.description,
        title: texts.modals.cancelNewMessage.title,
      }}
    />
  );
  const renderError = error && <Notification message={error} variant="error" />;
  return (
    <form
      className={cx(
        isReply ? classes.replyForm : classes.newMessageForm,
        classesApp.appForm,
      )}
      data-testid="messageForm"
      onSubmit={handleSubmit}
    >
      <FormFields
        attachmentUrl={requiredAttachmentUrl}
        emailField={email}
        hasPzsEmail={hasPzsEmail}
        isReply={isReply}
        messageField={message}
        subthemeField={subtheme}
        subthemeLoading={subthemeLoading}
        themeField={theme}
        themes={{
          data,
          dataState,
        }}
      />
      {renderUploadArea}
      {renderError}
      <FormButtons
        isFilledField={isFilledField}
        isReply={isReply}
        loading={loading}
        onCancel={onCancel}
        onContinueModal={onContinueModal}
        updateCancelModalVisible={setCancelModalVisible}
        uploadError={uploadError}
      />
      {renderCancelModal}
    </form>
  );
};

export default MessageForm;
