import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  sortIcon: {
    position: 'absolute',
    right: getRem(-20),
    '&:after': {
      content: '"▲"',
      color: 'inherit',
      fontSize: '100%',
      display: 'none',
    },
  },
  sortableCol: {
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'left',
    display: 'flex',
    '&.asc, &.desc, &:hover': {
      '& span:last-child': {
        '&:after': {
          display: 'block',
        },
      },
    },
    '&.desc, &.initDesc:not(.asc):hover': {
      '& span:last-child': {
        '&:after': {
          transform: 'rotate(180deg)',
        },
      },
    },
  },
});
