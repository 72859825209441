import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { breakpoints } from '../../../hooks/screen-width/useScreenWidth';
import useOutsideMouseover from '../../../hooks/useOutsideMouseover';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { cx } from '../../../utils/exports';
import useStyles from '../Spravy.styles';
import MessagePreview from './MessagePreview';
import ThreadCheckbox from './ThreadCheckbox';

interface Props {
  badge: 'A' | 'N' | string | null;
  date: string;
  description: string;
  id: number;
  isActive: boolean;
  isChecked: boolean;
  isDeleted: boolean;
  onClick: () => void;
  sentByPZS: boolean;
  showRemoveCheckox: boolean;
  title: string;
  unread: boolean;
}

const Thread = ({
  badge,
  date,
  description,
  id,
  isActive,
  isChecked,
  isDeleted,
  onClick,
  sentByPZS,
  showRemoveCheckox,
  title,
  unread,
}: Props) => {
  const classes = useStyles({});
  const ref = useRef(null);
  const windowSize = useWindowSize();
  const [isVisibleCheckbox, setIsVisibleCheckbox] = useState(false);

  const clickCallback = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      // @ts-ignore
      const { nodeName } = e.target;
      if (!['INPUT', 'LABEL'].includes(nodeName)) onClick();
    },
    [onClick],
  );
  useEffect(() => {
    setIsVisibleCheckbox(
      windowSize.width <= breakpoints.xl || showRemoveCheckox,
    );
  }, [showRemoveCheckox, windowSize.width]);
  useEffect(() => {
    if (isDeleted) setIsVisibleCheckbox(false);
  }, [isDeleted]);

  const hideCheckbox = useCallback(() => {
    if (
      isVisibleCheckbox &&
      !showRemoveCheckox &&
      windowSize.width > breakpoints.xl
    )
      setIsVisibleCheckbox(false);
  }, [isVisibleCheckbox, showRemoveCheckox, windowSize.width]);

  useOutsideMouseover(ref, () => {
    hideCheckbox();
  });

  return (
    <div
      ref={ref}
      className={cx(classes.thread, unread && 'unread', isActive && 'active')}
      onBlur={() => !isDeleted && setIsVisibleCheckbox(true)}
      onClick={clickCallback}
      onFocus={() => !isDeleted && setIsVisibleCheckbox(true)}
      onKeyPress={onClick}
      onMouseLeave={hideCheckbox}
      onMouseOver={() => !isDeleted && setIsVisibleCheckbox(true)}
      role="button"
      tabIndex={0}
    >
      {(!isDeleted || unread) && (
        <ThreadCheckbox
          id={id}
          isDeleted={isDeleted}
          isSelected={isChecked}
          isVisibleCheckbox={isVisibleCheckbox}
          unread={unread}
        />
      )}
      <MessagePreview
        badge={badge}
        date={date}
        description={description}
        id={id}
        isDeleted={isDeleted}
        sentByPZS={sentByPZS}
        title={title}
        unread={unread}
      />
    </div>
  );
};

export default Thread;
