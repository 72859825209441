import { createUseStyles } from 'react-jss';

import { color } from '../../constants';

export default createUseStyles({
  tooltipIcon: {
    position: 'relative',
    color: color('grey', 300),
  },
});
