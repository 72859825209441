export const replaceKeysInText = (
  text: string,
  replacements: { [key: string]: string },
): string => {
  let result = text;
  Object.entries(replacements).forEach(([key, value]) => {
    const regex = new RegExp(key, 'g');
    result = result.replace(regex, value);
  });
  return result;
};
