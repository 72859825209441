import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import strings from '../../../../../constants/strings';
import { hotjarMasking } from '../../../../../utils/strings.utils';

const texts = strings.proposals.new;

const Summary = () => {
  const {
    step2: { insureeId, poiData },
  } = useSelector((state: RootState) => state.spaProposals.new.stepper);
  return (
    <>
      <div className="mb-small">
        <b>{`${texts.labels.insureeId}: `}</b>
        {hotjarMasking(insureeId)}
      </div>
      <div className="no-mrg">
        <b>{`${texts.labels.patient}: `}</b>
        <span>
          {hotjarMasking(`${poiData?.firstName} ${poiData?.lastName}`)}
        </span>
      </div>
    </>
  );
};

export default Summary;
