import { color } from '@dovera/design-system';
import { Nullable } from '../../types';
import { Sign } from './types';

export const splittedFormula = (formula: string): string[] => {
  const regex = /(\d+|[a-zA-Z]\w*|[+\-*/()]|\s+)/g;
  return formula.match(regex)?.filter((e) => e.trim()) || [];
};

export const isInnerBracket = (
  formula: string,
  index: number,
  symbol: string,
): boolean => {
  const substring = splittedFormula(formula).join('').substring(0, index);
  const openingBrackets = (substring.match(/\(/g) || []).length;
  const closingBrackets = (substring.match(/\)/g) || []).length;
  if (symbol === '(') return openingBrackets > closingBrackets;
  return openingBrackets > closingBrackets + 1;
};

export const getFormulaItemStyles = (formula: string, index: number): any => {
  const elements = splittedFormula(formula);
  const { closingBrackets, openingBrackets } = elements.reduce(
    (acc, current, i) => {
      if (i < index) {
        if (current === '(') acc.openingBrackets += 1;
        else if (current === ')') acc.closingBrackets += 1;
      }
      return acc;
    },
    { openingBrackets: 0, closingBrackets: 0 },
  );

  const extraStyles = formula[index - 1] === '(' ? { marginLeft: -12 } : {};

  if (index < elements.length) {
    if (openingBrackets === closingBrackets) {
      return {
        ...extraStyles,
        ...{ color: 'white', backgroundColor: color('primary', 600) },
      };
    }
    if (closingBrackets + 1 === openingBrackets) {
      return {
        ...extraStyles,
        ...{ color: 'white', backgroundColor: color('primary', 500) },
      };
    }
    return {
      ...extraStyles,
      ...{
        color: `${color('primary', 600)}`,
        backgroundColor: '#DBEBDC',
      },
    };
  }

  return {};
};

export const getFormulaVariables = (formula: string): string[] => {
  const regex = /(\d+|[a-zA-Z]\w*|[+\-*/()]|\s+)/g;
  return formula.match(regex)?.filter((e) => /[a-zA-Z]+/.test(e.trim())) || [];
};

export const isSign = (str: string): str is Sign =>
  ['+', '-', '*', '/'].includes(str);

export const hasSigns = (formula: string | null): boolean =>
  splittedFormula(formula || '').some((f) => isSign(f));

export const getNextFormulaSign = (
  formula: string | null,
  variable: string,
): Nullable<Sign> => {
  const splitFormula = splittedFormula(formula || '');
  const variableIndex = splitFormula.findIndex((f) => f === variable);

  if (variableIndex === -1 || !(variableIndex < splitFormula.length - 1))
    return null;

  const nextElement = splitFormula[variableIndex + 1];
  if (isSign(nextElement)) return nextElement as Sign;

  return null;
};
