import axios, { AxiosResponse } from 'axios';

export const logoutIDP = async () => {
  const url = window.env?.authConfig?.authority;
  const clientId = window.env?.authConfig?.client_id;
  if (!url || !clientId) return null;
  const { data }: AxiosResponse<any> = await axios.get(`${url}/logout`, {
    headers: {
      'Content-Type': 'application/json',
      'X-API-Key': clientId,
    },
  });
  return data;
};
