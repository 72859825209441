import { useCallback, useEffect, useState } from 'react';
import useStyles from '../../styles';
import useStylesApp from '../../../../App.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { useLocation, useNavigate } from 'react-router';
import routes from '../../../../routes';
import SpatneDavky from './SpatneDavky/SpatneDavky';
import ContentWrapper from '../../../../components/ContentWrapper/ContentWrapper';
import Dokumenty from './Dokumenty/Dokumenty';
import { Button } from '@dovera/design-system';
import IconPencilSignature from '../../../../components/CustomIcons/IconPencilSignature';
import DoplnujuceDokumenty from './DoplnujuceDokumenty/DoplnujuceDokumenty';
import { IconPlus } from '../../../../components';
import { cx } from '../../../../utils/exports';
import InvoiceActionModal from '../../components/Modals/InvoiceActionModal';
import { InvoiceActionType } from '../../../../types/zuctovanie.types';
import { hasSection } from '../../../../utils/poskytovatel.utils';
import { EPSections } from '../../../../constants/systemConstants';

const DetailFaktury = () => {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const location = useLocation();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [canShowBtn, setCanShowBtn] = useState(true);
  const invoiceNumber = location.pathname.split(`${routes.faktury}/`)?.[1];
  const { idHz } = useSelector((state: RootState) => state.poskytovatel);
  const { detail } = useSelector(
    (state: RootState) => state.zuctovanie.invoices,
  );
  const [invoiceId, setInvoiceId] = useState(detail?.idFaktury || 0);

  const refreshDocuments = useCallback(() => {
    setCanShowBtn(false);
    setInvoiceId(0);
    if (detail?.idFaktury)
      setTimeout(() => {
        setInvoiceId(detail.idFaktury);
      }, 100);
  }, [detail]);

  useEffect(() => {
    if (!detail?.idFaktury) navigate(routes.faktury);
    setInvoiceId(detail?.idFaktury || 0);
  }, [detail, navigate]);

  const renderDolozenieBtn = canShowBtn &&
    detail?.stavFaktury === 'C' &&
    detail.zuctovanie2 && (
      <Button
        className="no-mrg content-center-vert"
        onClick={() => setModalVisible(true)}
      >
        <IconPlus color="white" id="btn-plus-invoice--icon" /> Doloženie faktúry
      </Button>
    );

  const renderAcceptInvoiceBtn = canShowBtn &&
    detail?.stavFaktury === 'C' &&
    !detail.zuctovanie2 && (
      <Button
        className="no-mrg content-center-vert"
        onClick={() => setModalVisible(true)}
      >
        <IconPencilSignature color="white" id="btn-accept-invoice--icon" />{' '}
        Prejsť na potvrdenie
      </Button>
    );

  const renderContent =
    detail?.stavFaktury === 'R' && hasSection(EPSections.Fkt, 'Zuctovanie') ? (
      <DoplnujuceDokumenty
        invoiceId={invoiceId || 0}
        refreshDocuments={refreshDocuments}
      />
    ) : (
      <>
        <SpatneDavky
          invoiceId={invoiceId || 0}
          invoiceNumber={invoiceNumber || ''}
        />
        {detail?.stavFaktury === 'C' && (
          <InvoiceActionModal
            invoiceSum={detail.fakturovanaSuma}
            isVisible={modalVisible}
            onCancel={() => setModalVisible(false)}
            onConfirm={() => {
              setModalVisible(false);
              setTimeout(() => {
                navigate(routes.faktury);
              }, 300);
            }}
            refreshDocuments={refreshDocuments}
            type={
              detail.zuctovanie2
                ? InvoiceActionType.Dolozenie
                : InvoiceActionType.Potvrdenie
            }
          />
        )}
      </>
    );

  return (
    <>
      <div
        className={cx(
          classesApp.headerWrapper,
          classes.documentsInvoiceWrapper,
        )}
      >
        <Dokumenty
          idHZ={idHz}
          invoiceId={invoiceId}
          invoiceNumber={invoiceNumber}
          invoiceState={detail?.stavFaktury || ''}
        />
        {hasSection(EPSections.Fkt, 'Zuctovanie') && (
          <>
            {renderDolozenieBtn}
            {renderAcceptInvoiceBtn}
          </>
        )}
      </div>

      <ContentWrapper id="zuc-container">{renderContent}</ContentWrapper>
    </>
  );
};

export default DetailFaktury;
