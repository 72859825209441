/* eslint-disable */
import { useRef } from 'react';
import useStyles from './YoutubeEmbed.styles';

interface Props {
  embedId: string;
}

const YoutubeEmbed = ({ embedId }: Props) => {
  const classes = useStyles();
  const iframeRef = useRef(null);
  return (
    <div className={classes.videoResponsive}>
      <iframe
        id="video"
        ref={iframeRef}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        // @ts-ignore
        frameborder="0"
        height="480"
        src={`https://www.youtube-nocookie.com/embed/${embedId}?showinfo=0&autoplay=0&loop=1&rel=0`}
        title="Embedded youtube"
        width="853"
      />
    </div>
  );
};

export default YoutubeEmbed;
