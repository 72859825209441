interface Props {
  color?: string;
}

const IconQuestionMark = ({ color }: Props) => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 12 20"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.36753 5.59189H0.311561C0.215892 4.11513 0.717793 2.83241 1.6474 1.90113C2.63498 0.911782 4.12536 0.3 5.94643 0.3C9.36264 0.3 11.7 2.25102 11.7 4.86487C11.7 6.70084 10.5839 7.87538 9.43097 9.07228C9.41771 9.08605 9.40443 9.09983 9.39114 9.11362C8.3538 10.1902 7.26161 11.3238 7.36899 12.9703H4.22765C4.06441 10.9807 4.99646 9.76936 6.04464 8.70736C6.20611 8.54377 6.3737 8.38077 6.54135 8.21773C6.93167 7.83812 7.32226 7.45826 7.63632 7.07001C8.09583 6.50194 8.43725 5.86577 8.43725 5.08108C8.43725 3.78188 7.35388 2.78108 5.88878 2.78108C5.04455 2.78108 4.34654 3.10522 3.89699 3.68427C3.50909 4.18392 3.3271 4.84595 3.36753 5.59189ZM8.18316 17.5135C8.18316 18.7353 7.18293 19.7 5.83112 19.7C4.48152 19.7 3.47908 18.7374 3.47908 17.4865C3.47908 16.3208 4.50595 15.327 5.83112 15.327C7.1541 15.327 8.18316 16.3187 8.18316 17.5135Z"
      fill="white"
      stroke={color || '#59A51E'}
      strokeWidth="0.6"
    />
  </svg>
);

export default IconQuestionMark;
