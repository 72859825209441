interface Props {
  color?: string;
}

const IconCircleWarning = ({ color }: Props) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" fill={color || '#F08B37'} r="8" />
    <path
      d="M8 4L8 9"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle cx="8" cy="12" fill="white" r="1" />
  </svg>
);

export default IconCircleWarning;
