import { useCallback, useEffect, useState } from 'react';
import useStylesApp from '../../../App.styles';
import useStyles from '../Proposals.styles';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { useSelector } from 'react-redux';
import {
  getProposalDetail,
  getProposalDetailDocuments,
} from '../../../api/spaProposals';
import { RootState } from '../../../rootReducer';
import { NoDataIllustration } from '../../../components/EmptyState';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';
import { Container } from '@dovera/design-system';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { useLocation, useNavigate } from 'react-router';
import { navrhyRoutes } from '../../../routes';
import strings from '../../../constants/strings';
import Content from './Content/Content';
import { cx } from '../../../utils/exports';
import ProposalHeader from './ProposalHeader/ProposalHeader';
import ReservationDateModal from '../Modals/ReservationDateModal';
import { Toast } from '../../../components';
import SkeletonLoaderHeader from './SkeletonLoader/SkeletonLoaderHeader';
import SkeletonLoaderContent from './SkeletonLoader/SkeletonLoaderContent';
import { GetProposalDetailPayload } from '../../../types/spaProposals.types';
import { resetApprovedProposals } from '../../../slices/spaProposals.slice';
import { useAppDispatch } from '../../../hooks/useStore';
import {
  BlobPDF,
  TemplateProposalDetail,
} from '../../../components/PdfTemplate';
import { sendDataLayer } from '../../../utils/gtm.utils';

interface Props {
  id: number;
  pin?: string | null;
  proposalNumber: string;
}

const texts = strings.proposals.detail;

const ProposalDetail = ({ id, pin, proposalNumber }: Props) => {
  const classesApp = useStylesApp();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [reservationType, setReservationType] = useState<
    'create' | 'update' | 'delete' | null
  >(null);
  const { data, documents, error, isLoading } = useSelector(
    (state: RootState) => state.spaProposals.detail,
  );
  const pzsName = useSelector(
    (state: RootState) => state.poskytovatel.nazovZobrazPzs,
  );
  const showReservationCallback = useCallback((value) => {
    setReservationType(value);
    setIsModalVisible(true);
  }, []);
  const isApprovedProposal: boolean =
    navrhyRoutes.zoznamSchvalenychNavrhov.includes(location.pathname);
  useEffect(() => {
    if (id && proposalNumber && !isLoading) {
      const payload: GetProposalDetailPayload = {
        cisloNavrhu: proposalNumber,
        idNavrhNaZS: id,
      };
      dispatch(getProposalDetail(payload));
      setTimeout(() => {
        dispatch(getProposalDetailDocuments(payload));
      }, 500);
    }
    // eslint-disable-next-line
  }, [dispatch, id, proposalNumber]);
  const renderPrintBtn = data && (
    <BlobPDF
      buttonLabel={texts.buttons.printPdf}
      buttonType="secondary"
      iconPosition="right"
      iconType="download"
      onClick={() => sendDataLayer('kupele_detail_download')}
      pdfContent={
        <TemplateProposalDetail
          data={data}
          proposalNumber={proposalNumber}
          proposalPin={pin}
          pzsName={pzsName}
        />
      }
    />
  );
  if (!id || !proposalNumber) navigate(location.pathname);
  const renderHeader = (
    <ContentHeader padBottom={false}>
      <Container>
        <Breadcrumbs
          items={[
            {
              name: 'Návrhy',
              route: '#',
            },
            {
              name: isApprovedProposal
                ? 'Zoznam schválených návrhov'
                : 'Zoznam podaných návrhov',
              route: isApprovedProposal
                ? navrhyRoutes.zoznamSchvalenychNavrhov
                : navrhyRoutes.zoznamPodanychNavrhov,
            },
            {
              name: 'Detail',
            },
          ]}
        />
      </Container>
      <h2 className={cx('mb', classes.title)}>
        <span>{texts.title(proposalNumber)}</span>
        {renderPrintBtn}
      </h2>
      {data && !isLoading && (
        <ProposalHeader
          data={data}
          isApproved={isApprovedProposal}
          onReservationClick={(value: 'create' | 'update' | 'delete') =>
            showReservationCallback(value)
          }
          pin={pin}
        />
      )}
      {isLoading && <SkeletonLoaderHeader />}
    </ContentHeader>
  );
  const renderError = error && <NoDataIllustration title={error} />;
  return (
    <StaticMenuLayout
      backgroundWhite
      contentHeader={renderHeader}
      withoutPadding
    >
      <div className={cx(classesApp.pageLayout, classes.section)}>
        {renderError}
        {isLoading && <SkeletonLoaderContent />}
        {data && (
          <>
            <Content data={data} documents={documents} proposalId={id} />
            <ReservationDateModal
              id={data.detail.idNavrhNaZS || 0}
              isVisible={isModalVisible}
              onHide={(reload: boolean, toastMessage: string) => {
                if (toastMessage) {
                  setToastMessage(toastMessage);
                  dispatch(resetApprovedProposals());
                  setTimeout(() => {
                    setToastMessage('');
                    dispatch(
                      getProposalDetail({
                        cisloNavrhu: proposalNumber,
                        idNavrhNaZS: id,
                      }),
                    );
                  }, 3000);
                }
                setIsModalVisible(false);
              }}
              pin={pin}
              reservation={{
                from: data.detail.rezervaciaNavrhu.datumOd,
                id: data.detail.rezervaciaNavrhu.id,
                to: data.detail.rezervaciaNavrhu.datumDo,
              }}
              type={reservationType}
            />
            {toastMessage && (
              <Toast
                iconType={'success'}
                show={!!toastMessage}
                text={toastMessage}
              />
            )}
          </>
        )}
      </div>
    </StaticMenuLayout>
  );
};

export default ProposalDetail;
