import { createUseStyles } from 'react-jss';

import { color } from '../../constants';

export default createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  component: {
    paddingLeft: '1.5rem',
  },
  icon: {
    display: 'inline-flex',
    color: color('success'),
  },
  noPadding: {
    paddingLeft: '10px !important',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 40,
    marginTop: 3,
  },
});
