import { useContext, useEffect } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import { Input } from '@dovera/design-system';
import { useQuery } from '../../../hooks/useQuery';
import { FILTER_PREFIX } from '../constants';
import { createInputEvent } from '../utils';

const InputFilter = (props) => {
  const { id, isPrivate = true, onChange, value, ...restProps } = props;

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    if (!isPrivate) {
      const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
      if (queryValue) {
        handleChange(createInputEvent(id, queryValue));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    if (e.target.value) {
      searchParams.set(
        `${FILTER_PREFIX}_${id}`,
        isPrivate ? true : e.target.value,
      );
    } else if (searchParams.get(`${FILTER_PREFIX}_${id}`)) {
      searchParams.delete(`${FILTER_PREFIX}_${id}`);
    }
    setSearchParams(searchParams);
    onChange(e);
  };

  return (
    <Input
      id={id}
      onChange={(e) => handleChange(e)}
      value={value}
      {...restProps}
    />
  );
};

export default InputFilter;
