// @ts-nocheck
import Rating from 'react-rating';
import useStyles from '../Feedback.styles';
import strings from '../../../constants/strings';
import {
  IconRatingStarEmpty,
  IconRatingStarFull,
} from '../../RatingStar/RatingStar';
import { ratingFullSymbolColors } from '../rating';
import { ButtonSecondary, breakpoints } from '@dovera/design-system';
import { useWindowSize } from '../../../hooks/useWindowSize';

const texts = strings.feedbacker;

interface Props {
  onChange: (rate: number) => void;
  onClose: (quarantine: boolean) => void;
  rating: number;
  textKey: 'standard' | 'trigger';
}

const AppRating = ({ onChange, onClose, rating, textKey }: Props) => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const isMobile: boolean = windowSize.width < breakpoints.m;
  return (
    <div className={classes.rating} data-testid="feedback-rating">
      <h6>{texts[textKey].firstStep.label}</h6>
      <div className="d-flex">
        <span className="text-space-two-thirds">
          {texts[textKey].firstStep.rating}
        </span>
        <Rating
          emptySymbol={
            <div className={classes.iconRaiting}>
              <IconRatingStarEmpty size={isMobile ? 'medium' : 'large'} />
            </div>
          }
          fullSymbol={ratingFullSymbolColors.map((symbolColor) => (
            <div key={symbolColor} className={classes.iconRaiting}>
              <IconRatingStarFull
                pathFill={symbolColor}
                size={isMobile ? 'medium' : 'large'}
              />
            </div>
          ))}
          initialRating={rating}
          onChange={(rate) => onChange(rate)}
          stop={ratingFullSymbolColors.length}
        />
      </div>
      {textKey === 'trigger' && rating === 0 && (
        <div className={classes.triggerCloseBtnWrapper}>
          <ButtonSecondary
            className="no-mrg-bottom"
            onClick={() => onClose(true)}
            size="s"
          >
            {texts[textKey].firstStep.closeBtn}
          </ButtonSecondary>
        </div>
      )}
    </div>
  );
};

export default AppRating;
