/**
 * Custom History Object to allow navigation outside React components
 * @date 2/21/2024 - 9:30:33 AM
 *
 * @type {{ navigate: any; location: any; }}
 */
export const history: { location: any; navigate: any } = {
  location: {},
  navigate: {},
};
