import { createUseStyles } from 'react-jss';
import { color } from '../../constants';
import { breakpoints } from '../../hooks/screen-width/useScreenWidth';

interface StylesProps {
  withDivider: boolean;
}

export default createUseStyles({
  footer: ({ withDivider }: StylesProps) => ({
    height: 67,
    position: 'absolute',
    backgroundColor: color('white'),
    color: color('secondary', 500),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'normal',
    width: '100%',
    zIndex: 999,
    '& .container': {
      maxWidth: '100%',
    },
    '& p': {
      color: color('white'),
      fontSize: 14,
      margin: '0 0 0 32px',
    },
    borderTop: withDivider ? `1px solid ${color('grey', 100)}` : 'none',
  }),
  link: {
    color: color('secondary', 500),
    fontSize: 14,
    marginLeft: 32,
    transition: '0.2s ease',
    '&:first-child': {
      [`@media screen and (max-width: ${breakpoints.l}px)`]: {
        marginLeft: 5,
      },
    },
    '&:hover': {
      opacity: 1,
    },
  },
});
