import { createUseStyles } from 'react-jss';
import { space } from '../../../constants/index';

export default createUseStyles({
  dropdownMenu: {
    // marginTop: space('xxxsmall'),
    maxHeight: '12.5rem',
    overflowY: 'scroll',
  },
  dropdownMenuError: {
    marginLeft: space('small'),
    minWidth: `calc(100% - ${space('small')})`,
  },
  dropdownMenuHidden: {
    display: 'none',
  },
});
