// @ts-nocheck
import { useCallback } from 'react';
import useStyles from '../AuthPage.styles';
import { Container } from '@dovera/design-system';
import IllustrationSuccess from '../../../assets/illustrations/success-mamog.png';
import IllustrationError from '../../../assets/illustrations/error-default.png';
import IllustrationWarning from '../../../assets/illustrations/error-mamog.png';
import strings from '../../../constants/strings';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import BottomButtons from '../components/BottomButtons/BottomButtons';
import routes from '../../../routes';
import { useSelector } from 'react-redux';
import { fetchDataVZ } from '../../../slices/poskytovatel.slice';
import { RootState } from '../../../rootReducer';
import { getCMSText } from '../../../utils/cms.utils';
import { cmsPath } from '../../../constants/cmsPath';
import { replaceStringWithParams } from '../../../utils/strings.utils';
import { useAppDispatch } from '../../../hooks/useStore';
import { useNavigate } from 'react-router';
// @ts-check

const texts = strings.auth.page.stepResult;

interface Props {
  description?: string;
  email?: string;
  result?:
    | 'success'
    | 'error'
    | 'errorPersonalized'
    | 'warning'
    | 'warningHK'
    | null
    | string;
  title?: string;
  withoutButtons?: boolean;
}

const StepResult = ({
  description,
  email,
  result,
  title,
  withoutButtons,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pouzivatelId, vztahId } = useSelector(
    (state: RootState) => state.auth,
  );
  const preLekara = useSelector(
    (state: RootState) => state.poskytovatel.preLekara,
  );
  const classes = useStyles();
  let ill;
  let tit;
  let desc;
  let cms;

  switch (result) {
    case 'success':
      ill = IllustrationSuccess;
      tit = texts.success.title;
      desc = texts.success.description;
      cms = getCMSText(cmsPath.autentifikacia.hlasky.odoslanie.uspesne);
      break;
    case 'error':
      ill = IllustrationError;
      tit = texts.error.title;
      desc = texts.error.description;
      cms = getCMSText(cmsPath.autentifikacia.hlasky.odoslanie.neuspesne);
      break;
    case 'errorPersonalized':
      ill = IllustrationError;
      tit = texts.errorPersonalized.title;
      desc = texts.errorPersonalized.description;
      cms = getCMSText(
        cmsPath.autentifikacia.hlasky.odoslanie.neuspesnaPersonalizacia,
      );
      break;
    case 'warningHK':
      ill = IllustrationWarning;
      tit = texts.warningHK.title;
      desc = texts.warningHK.description;
      cms = getCMSText(cmsPath.autentifikacia.hlasky.odoslanie.upozornenieHK);
      break;
    default:
      ill = IllustrationWarning;
      tit = texts.warning.title;
      desc = texts.warning.description(email);
      cms = replaceStringWithParams(
        getCMSText(
          cmsPath.autentifikacia.hlasky.odoslanie.upozornenie,
          '',
          false,
          true,
        ),
        [
          {
            id: '[EMAIL]',
            value: email,
          },
        ],
      );
      break;
  }
  const onSuccessCallback = useCallback(() => {
    // call fetchDataVyssejZlozky after submit auth
    dispatch(fetchDataVZ(vztahId, pouzivatelId, preLekara));
    navigate(routes.dashboard);
  }, [dispatch, vztahId, pouzivatelId, preLekara, navigate]);
  const renderResult = () => {
    const errorOrResult = result === 'errorPersonalized' ? 'error' : result;
    const classResult = result === 'warningHK' ? 'warning' : errorOrResult;

    return (
      <div>
        <img alt="Auth Result" src={ill} />
        {cms && !title && !description ? (
          cms
        ) : (
          <>
            <h4 className={`mb-small text-color-${classResult}`}>
              {title || tit}
            </h4>
            <SafeHtml
              className="mb-small"
              html={description || desc}
              wrapper="p"
            />
          </>
        )}
      </div>
    );
  };
  return (
    <div data-testid="auth--step-result">
      <Container className={classes.stepResultWrapper}>
        {renderResult()}
        {!withoutButtons && (
          <BottomButtons
            mtSmall
            onlyHome={result !== 'error'}
            onSecondaryButtonClick={() => navigate(routes.dashboard)}
            onSubmit={() =>
              result === 'error'
                ? window.location.reload()
                : onSuccessCallback()
            }
            primaryButtonText={
              result === 'error' ? texts.buttons.tryAgain : texts.buttons.home
            }
            secondaryButtonText={texts.buttons.close}
          />
        )}
      </Container>
    </div>
  );
};

export default StepResult;
