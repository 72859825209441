import { useCallback, useEffect, useState } from 'react';
import { ButtonLink, Icon, Notification, color } from '@dovera/design-system';
import useStyles from '../../../styles';
import { cx } from '../../../../../utils/exports';
import strings from '../../../../../constants/strings';
import { formatDate } from '../../../../../utils/date.utils';
import { ErrorServiceResponse, LoadingState } from '../../../../../types';
import TablePreloaderSkeleton from '../../../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import {
  DajSpatneDavkyFakturyQueries,
  DajStavPripravySuborovSpatnychDavokQueries,
  DajSuborSpatnejDavkyQueries,
  DavkaFaktury,
} from '../../../../../types/zuctovanie.types';
import {
  dajSpatneDavkyFaktury,
  dajStavPripravySuborovSpatnychDavok,
  dajSuborSpatnejDavky,
} from '../../../../../api/zuctovanie';
import { saveBase64Document } from '../../../../../utils/file.utils';
import SortableTable from '../../../../../components/SortableTable/SortableTable';
import { translateErrorCode } from '../../../../../utils/validation.utils';
import { epSekcie } from '../../../../../constants/epLinks';
import { EPSections } from '../../../../../constants/systemConstants';
import IconAngleRight from '../../../../../components/CustomIcons/IconAngleRight';
import { hasSection } from '../../../../../utils/poskytovatel.utils';
import { createQueryParams } from '../../../../../utils/api.utils';
import useInterval from '../../../../../hooks/useInterval';

const texts = strings.zuctovanie.invoices.detail;
const { tableCols } = texts.spatneDavky;

interface Props {
  invoiceId: number;
  invoiceNumber: string;
}

interface LocalState {
  data: DavkaFaktury[];
  dataState: LoadingState;
  error: string | null;
}

const initialState: LocalState = {
  data: [],
  dataState: LoadingState.none,
  error: null,
};

const SpatneDavky = ({ invoiceId, invoiceNumber }: Props) => {
  const classes = useStyles();
  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [dokumentZipId, setDokumentZipId] = useState(0);
  const [state, setState] = useState<LocalState>(initialState);

  useEffect(() => {
    setState({ ...initialState, dataState: LoadingState.fetching });
    const queries: DajSpatneDavkyFakturyQueries = {
      idFaktura: invoiceId,
    };
    if (invoiceId)
      dajSpatneDavkyFaktury(queries)
        .then((resp) => {
          setState({
            ...initialState,
            data: resp.davky,
            dataState: LoadingState.filled,
          });
        })
        .catch((err: ErrorServiceResponse) => {
          setState({
            ...initialState,
            dataState: LoadingState.error,
            error: translateErrorCode(err?.response?.data.kod),
          });
        });
  }, [invoiceId]);

  useInterval(() => {
    if (loadingBtn && dokumentZipId !== 0) {
      const queries: DajStavPripravySuborovSpatnychDavokQueries = {
        idFaktury: invoiceId,
        idSubor: dokumentZipId,
      };
      dajStavPripravySuborovSpatnychDavok(queries).then((resp) => {
        if (resp.stav) {
          saveBase64Document(resp.suborDavky.obsah, resp.suborDavky.nazov);
          setLoadingBtn(false);
        }
      });
    }
  }, 15000);

  const downloadFile = useCallback(
    ({
      batchId,
      invoiceCode,
      zip,
    }: {
      batchId?: string;
      invoiceCode?: string;
      zip?: boolean;
    }) => {
      if (batchId) setLoadingIds([...loadingIds, batchId]);
      if (zip) setLoadingBtn(true);
      const queries: DajSuborSpatnejDavkyQueries = {
        guidDavky: zip ? undefined : batchId,
        idFaktury: invoiceId,
        kodTypDavky: invoiceCode || '',
        vsetkyAkoZIP: !!zip,
      };
      if (batchId) {
        dajSuborSpatnejDavky(queries)
          .then((resp) => resp.suborDavky)
          .then((data) => saveBase64Document(data.obsah, data.nazov))
          .finally(() => {
            if (batchId) setLoadingIds(loadingIds.filter((i) => i !== batchId));
            if (zip) setLoadingBtn(false);
          });
      }
      if (zip) {
        dajSuborSpatnejDavky(queries)
          .then((resp) => resp.suborDavky)
          .then((data) => {
            if (data.id) setDokumentZipId(data.id);
          });
      }
    },
    [invoiceId, loadingIds],
  );

  const renderTable = state.data?.length && (
    <SortableTable
      cols={[
        {
          index: 1,
          isSortable: true,
          name: tableCols.name,
          sortType: 'alphabetical',
        },
        {
          index: 2,
          isSortable: true,
          name: tableCols.type,
          sortType: 'alphabetical',
        },
        {
          index: 3,
          isSortable: true,
          name: tableCols.period,
          sortType: 'alphabetical',
        },
        { index: 4, name: tableCols.date },
      ]}
      noRerender
      rows={state.data.map((d) => ({
        col1: {
          sortableValue: d.nazov,
          value: (
            <ButtonLink
              className={cx(
                `inline-btn text-thin`,
                !loadingIds.includes(d.guidDavky) && 'text-color-black',
              )}
              isLoading={loadingIds.includes(d.guidDavky)}
              onClick={() =>
                downloadFile({
                  batchId: d.guidDavky,
                  invoiceCode: d.kodTypDavky,
                })
              }
            >
              {d.nazov}
            </ButtonLink>
          ),
        },
        col2: {
          sortableValue: d.kodTypDavky,
          value: d.kodTypDavky,
        },
        col3: {
          sortableValue: d.obdobie.toString(),
          value: d.obdobie.toString(),
        },
        col4: {
          value: formatDate(d.datumCasSpracovaniaGenerovania),
        },
      }))}
    />
  );

  const renderButton = (
    <ButtonLink
      className="inline-btn mb-small"
      isLoading={loadingBtn}
      onClick={() => downloadFile({ zip: true })}
    >
      <Icon
        className="text-space-half-right"
        color={color('primary', 600)}
        name="download"
        size="medium"
      />
      {texts.buttons.download}
    </ButtonLink>
  );

  const renderContent = () => {
    if (state.dataState === LoadingState.fetching)
      return <TablePreloaderSkeleton columns={4} />;
    if (state.dataState === LoadingState.error && state.error)
      return <Notification message={state.error} variant="error" />;
    return (
      <>
        {renderButton}
        {renderTable}
      </>
    );
  };

  const renderDispBtn = hasSection(EPSections.DispZaz, 'Zuctovanie') && (
    <button
      className={classes.invoiceDispBtn}
      onClick={() => {
        window.location.href = `${epSekcie['dispenzarizovane-zaznamy']}${createQueryParams({ queryParams: { cisloFA: invoiceNumber, idFA: invoiceId }, urlEncoded: true })}`;
      }}
      type="button"
    >
      <h5 className="no-mrg">{texts.buttons.dispZaz}</h5>
      <IconAngleRight color={color('black')} />
    </button>
  );

  if (!state.data?.length) return renderDispBtn;

  return (
    <div className={classes.zucTableWrapper}>
      <h3 className="mb-small">{texts.subtitle}</h3>
      {renderContent()}
      {renderDispBtn}
    </div>
  );
};

export default SpatneDavky;
