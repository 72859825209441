import { Checkbox } from '@dovera/design-system';
import { useCallback } from 'react';
import { useAppDispatch } from '../../../hooks/useStore';
import { oznacitPreVymazanie } from '../../../slices/spravy.slice';
import useStyles from '../Spravy.styles';

interface Props {
  id: number;
  isDeleted: boolean;
  isSelected: boolean;
  isVisibleCheckbox: boolean;
  unread: boolean;
}

const ThreadCheckbox = ({
  id,
  isDeleted,
  isSelected,
  isVisibleCheckbox,
  unread,
}: Props) => {
  const classes = useStyles({});
  const dispatch = useAppDispatch();

  const checkedCallback = useCallback(
    (isSelected) => {
      dispatch(oznacitPreVymazanie({ id, oznacene: isSelected }));
    },
    [dispatch, id],
  );

  const renderDotNewThread = unread && (
    <span className={classes.threadNewDot} />
  );

  const renderCheckbox = !isDeleted && isVisibleCheckbox && (
    <Checkbox
      className="no-mrg"
      data-checked={isSelected}
      // eslint-disable-next-line
      checked={isSelected}
      id={`${id}--checkbox`}
      isChecked={isSelected}
      onChange={checkedCallback}
    />
  );

  return (
    <div className={classes.threadCheckbox}>
      {renderCheckbox || renderDotNewThread}
    </div>
  );
};

export default ThreadCheckbox;
