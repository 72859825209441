import { KeyboardEvent } from 'react';
import {
  Button,
  ButtonLayout,
  ButtonSecondary,
  Modal,
} from '@dovera/design-system';
import { scrollPositionAfterModalVisible } from '../../utils/app.utils';
import SafeHtml from '../SafeHtml/SafeHtml';
import useStyles from './CancelActionModal.styles';

interface Props {
  isFormModal?: boolean;
  isVisible: boolean;
  onCancel: () => void;
  onContinue: () => void;
  onHide: () => void;
  texts: {
    btnCancel: string;
    btnContinue: string;
    description: string;
    title: string;
  };
}

const CancelActionModal = ({
  isFormModal,
  isVisible,
  onCancel,
  onContinue,
  onHide,
  texts,
}: Props) => {
  /**
   * Ak je formular umiestneny v modalnom okne, nezobrazujeme moznost zatvorit CancelActionModal klikom na (X)
   * Dovod: onHide refreshuje state, nespravne funguje navrat do povodneho modalneho okna (form)
   * @date 11. 4. 2023 - 11:58:44
   *
   * @type {*}
   */
  const classes = useStyles({ hideClose: !!isFormModal });
  const renderButtons = (
    <ButtonLayout className="align-items-center" direction="horizontal">
      <ButtonSecondary className="no-mrg-bottom" onClick={onContinue}>
        {texts.btnContinue}
      </ButtonSecondary>
      <Button className="no-mrg-bottom" onClick={onCancel} type="destructive">
        {texts.btnCancel}
      </Button>
    </ButtonLayout>
  );
  return (
    // eslint-disable-next-line
    <div
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Escape') onContinue();
      }}
    >
      <Modal
        className={classes.cancelModal}
        closeOnOverlayClick={false}
        data-keyboard="false"
        data-modal-initial-focus
        header={texts.title}
        id="cancel-action--modal"
        isVisible={isVisible}
        onHide={() => {
          onHide();
          scrollPositionAfterModalVisible();
        }}
      >
        <SafeHtml className="mb-small" html={texts.description} wrapper="div" />
        {renderButtons}
      </Modal>
    </div>
  );
};

export default CancelActionModal;
