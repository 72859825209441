// @ts-nocheck
import { createUseStyles } from 'react-jss';
import IllustrationWaiting from '../../../../../assets/illustrations/presypacie-hodiny.png';
import IllustrationSuccess from '../../../../../assets/illustrations/success-mamog.png';
import IllustrationError from '../../../../../assets/illustrations/error-mamog.png';
import { ReactNode, useState } from 'react';
import routes from '../../../../../routes';
import {
  Bar,
  BarItem,
  Button,
  ButtonLayout,
  ButtonLink,
  Modal,
  Notification,
  color,
} from '@dovera/design-system';
import { useNavigate } from 'react-router-dom';
import IconExternalLink from '../../../../../components/CustomIcons/IconExternalLink';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import {
  StavSpracovaniaCL,
  StavyOdoslaniaEnum,
} from '../../../../../types/cakacky.types';

const useStyles = createUseStyles({
  root: {
    textAlign: 'center',
    // maxWidth: 518,
    margin: '0 auto',
    '& img': {
      width: 264,
    },
    '& h4': {
      marginTop: 30,
    },
  },
  successTextColor: { color: '#4CAF50' },
  errorTextColor: { color: '#E60523' },
  errorLinkColor: { color: color('error', 600) },
});

export enum BatchSaveState {
  isError = 'isError',
  isLoading = 'isLoading',
  isSuccess = 'isSuccess',
  isTechnicalError = 'isTechnicalError',
}

interface Props {
  results?: StavSpracovaniaCL[];
  state?: BatchSaveState;
}

type PageData = {
  description: ReactNode | string;
  image: string;
  title: ReactNode | string;
};

const ResultState = ({ results, state }: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [modalVisible, setModalVisible] = useState(false);
  const [errModalTitle, setErrModalTitle] = useState('');
  const [errModalContent, setErrModalContent] = useState('');

  const getPageData: PageData = (batchState: BatchSaveState) => {
    const defaultPageData: PageData = {
      image: IllustrationWaiting,
      title: <h4>Dávky sa spracovávajú</h4>,
      description: (
        <div>
          Na výsledok môžete počkať, no môže to trvať niekoľko minút.
          <br />
          Keď budú spracované, nájdete ho v prehľade dávok.
        </div>
      ),
    };

    switch (batchState) {
      case BatchSaveState.isSuccess:
        return {
          image: IllustrationSuccess,
          title: (
            <h4 className={classes.successTextColor}>Vaše dávky sme prijali</h4>
          ),
          description: (
            <>
              <div className="mb-large">
                O výsledku spracovania sa dozviete v Prehľade dávok.
              </div>
              <ButtonLayout
                className="align-items-center"
                direction="horizontal"
              >
                <Button
                  className="mb-small"
                  onClick={() => navigate(routes.cakackyPrehladDavok)}
                >
                  Späť na prehľad
                </Button>
              </ButtonLayout>
            </>
          ),
        };
      case BatchSaveState.isError:
        return {
          image: IllustrationError,
          title: (
            <h4 className={classes.errorTextColor}>
              Mrzí nás to, odoslanie nebolo úspešné
            </h4>
          ),
          description: (
            <div>
              Neviete si s chybou poradiť? Kontaktujte nás na linke pre
              poskytovateľov <strong>0800 150 155.</strong>
            </div>
          ),
        };
      case BatchSaveState.isWarning:
        return {
          image: IllustrationError,
          title: (
            <h4 className={classes.errorTextColor}>
              Mrzí nás to, nastala technická chyba
            </h4>
          ),
          description: (
            <>
              <div className="mb-large">
                Prosím, skúste dávky poslať neskôr.
              </div>
              <ButtonLayout
                className="align-items-center"
                direction="horizontal"
              >
                <Button
                  className="mb-small"
                  onClick={() => navigate(routes.cakackyOdoslanieDavok)}
                >
                  Skúsiť znovu
                </Button>
              </ButtonLayout>
            </>
          ),
        };
      default:
        return defaultPageData;
    }
  };

  const renderResults = (list: StavSpracovaniaCL[]) =>
    list
      ?.filter((l) => !!l.chyboveHlasenieTxs)
      ?.map((r, index) => {
        let m = <SafeHtml html={r.popis} wrapper={'div'} />;
        const respStateData: {
          btnName: string;
          color: string;
          linkClass: string;
        } =
          r.vysledokSpracovaniaTxs === StavyOdoslaniaEnum.uspesne
            ? {
                btnName: 'Zobraziť výsledok',
                color: 'success',
                linkClass: 'text-color-primary',
              }
            : {
                btnName: 'Zobraziť chyby',
                color: 'error',
                linkClass: 'text-color-error',
              };
        m = (
          <Bar className="no-mrg-bottom">
            <BarItem>
              Chyba pri spracovaní dávky <b>{r.nazovDavky}</b>
            </BarItem>
            <BarItem>
              <ButtonLink
                className={`inline-btn icon-with-text ${respStateData.linkClass}`}
                onClick={() => {
                  setErrModalTitle(
                    `Výsledok spracovania dávky ${r.nazovDavky}`,
                  );
                  setErrModalContent(r.chyboveHlasenieTxs);
                  setModalVisible(true);
                }}
              >
                {respStateData.btnName}
                <IconExternalLink
                  color={color(respStateData.color, 600)}
                  id={`icon--${index}--show-errors`}
                />
              </ButtonLink>
            </BarItem>
          </Bar>
        );
        return (
          <Notification
            key={`result-ntf--${r.idDavky}-${r.nazovDavky}`}
            className="mb-xsmall"
            message={m}
            variant={
              r.vysledokSpracovaniaTxs === StavyOdoslaniaEnum.uspesne
                ? 'success'
                : 'error'
            }
          />
        );
      });

  return (
    <div className={classes.root}>
      <img alt="Illustration" src={getPageData(state).image} />
      <div className="mb">
        {getPageData(state).title}
        {getPageData(state).description}
      </div>

      <Modal
        closeOnOverlayClick={false}
        data-modal-initial-focus
        header={errModalTitle}
        id="vysledok-odoslania-cl-modal"
        isVisible={modalVisible}
        onHide={() => {
          setErrModalContent('');
          setErrModalTitle('');
          setModalVisible(false);
        }}
      >
        <SafeHtml html={errModalContent} withoutRemoveEmptyTd wrapper={'div'} />
      </Modal>

      <div className="mb-small content-center-horiz">
        <div className="fit-content">
          {state !== BatchSaveState.isLoading &&
            results?.some((s) => !!s.chyboveHlasenieTxs) &&
            renderResults(results)}
        </div>
      </div>
    </div>
  );
};

export default ResultState;
