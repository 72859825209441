import { useEffect, useState } from 'react';
import { breakpoints as DDLBreakpoints } from '@dovera/design-system';

export interface ScreenWidth {
  breakpoint: string;
  innerWidth: number;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  outerWidth: number;
}

export const breakpoints = {
  xs: DDLBreakpoints.xs,
  s: DDLBreakpoints.s,
  m: DDLBreakpoints.m,
  l: DDLBreakpoints.l,
  xl: DDLBreakpoints.xl,
};

export const getBreakpoint = (innerWidth: number): string => {
  if (innerWidth < breakpoints.s) {
    return 'xs';
  }

  if (innerWidth < breakpoints.m) {
    return 's';
  }

  if (innerWidth < breakpoints.l) {
    return 'm';
  }

  if (innerWidth < breakpoints.xl) {
    return 'l';
  }

  return 'xl';
};

export const getScreenWidth = (): ScreenWidth => {
  const { innerWidth, outerWidth } = window;
  const breakpoint = getBreakpoint(innerWidth);
  const isMobile = innerWidth < breakpoints.m;
  const isTablet = innerWidth >= breakpoints.m && innerWidth < breakpoints.l;
  const isDesktop = innerWidth >= breakpoints.l;

  return {
    innerWidth,
    outerWidth,
    breakpoint,
    isMobile,
    isTablet,
    isDesktop,
  };
};

const useScreenWidth = (): ScreenWidth => {
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(getScreenWidth());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return screenWidth;
};

export default useScreenWidth;
