import { LoadingState } from '.';
import { BaseDocument } from './file.types';
import { VstupnyDokumentFaktury } from './models/Dokument';

export type FilterBatchValues = {
  dateFrom: string;
  dateTo: string;
  search: string;
  state: string;
};

export type FilterInvoiceValues = {
  invoiceType?: string;
  kodPracoviska?: string;
  paperInvoice?: string;
  periods: number[];
  search?: string;
  state?: string;
  zuc20?: string;
};

export interface DajZoznamZuctovacichDavokQueries {
  datumDo: string;
  datumOd: string;
}

export interface DajOvereneZuctovacieDavkyQueries {}

export type SprievodnyList = {
  datumCasOdoslania: string;
  id: number;
  kodTypStavuSprievodnehoListu: 'V' | 'S' | 'U' | 'E';
};

export enum TYP_KONTROLY_DAVKY {
  DuplicitnaDavka = 'DuplicitnaDavka',
  JeOpravnaDavka = 'OpravnaDavka',
  Semantika = 'Semantika',
  Syntax = 'Syntax',
  TypDavky = 'TypDavky',
  TypSuboru = 'TypSuboru',
}

export enum STAV_DAVKY {
  Chybna = 'C',
  Nahrana = 'U',
  PrebiehaKontrola = 'P',
  Spravna = 'S',
}

export type Davka = {
  guidDavky: string;
  id: number;
  kodCharakterDavky: string;
  kodTypDavky: string;
  nazovTypDavky: string;
  obdobie: number;
  sprievodnyList: SprievodnyList;
};

export type Faktura = {
  cisloFaktury: string;
  datumDorucenia?: string;
  datumSplatnosti?: string;
  datumVystavenia?: string;
  fakturovanaSuma: number;
  idFaktury: number;
  kodPracoviska?: string;
  obdobieFaktury: number;
  papierovaFaktura?: boolean;
  stavFaktury: string;
  typFaktury?: string;
  vs: string;
  zuctovanie2?: boolean;
};

export type DavkaFaktury = {
  cislo: number;
  datumCasSpracovaniaGenerovania: string;
  guidDavky: string;
  kodCharakterDavky: string;
  kodTypDavky: string;
  nazov: string;
  nazovTypDavky: string;
  obdobie: number;
  prijata: boolean;
  stav: string;
};

export type UdajDavky = {
  datumPrijatia: string;
  id: number;
  nazovSuboruDavky: string;
  nazovTypDavky: string;
  selected?: boolean;
  stavOvereniaDavky: string;
  typDavky: string;
};

export interface DajZoznamZuctovacichDavokResponse {
  davky: Davka[];
}

export type UdajePZS = {
  druhPZS: string;
  ico: string;
  idHZ: number;
  idVZ: number;
  nazovVZ: string;
};

export interface DajOvereneZuctovacieDavkyResponse {
  udajeDavky: UdajDavky[];
}

export interface DajSpatneDavkyFakturyQueries {
  idFaktura: number;
}

export interface DajSpatneDavkyFakturyResponse {
  davky: DavkaFaktury[];
  idFaktura: number;
}

export interface DajSuborDavkyQueries {
  guidDavky: string;
  kodTypDavky: string;
}

export interface DajSuborDavkyResponse {
  guidDavky: string;
  idFaktura: number;
  suborDavky: {
    nazov: string;
    obsah: string;
  };
  vsetkyDavkyZIP: boolean;
}

export interface DajSuborSpatnejDavkyQueries {
  guidDavky?: string;
  idFaktury: number;
  kodTypDavky: string;
  vsetkyAkoZIP: boolean;
}

export interface DajStavPripravySuborovSpatnychDavokQueries {
  idFaktury: number;
  idSubor: number;
}

export interface DajStavPripravySuborovSpatnychDavokResponse {
  idDavka: number;
  idFaktura: number;
  stav: boolean;
  suborDavky: SuborDavky;
  vsetkyDavkyZIP: boolean;
}

export type SuborDavky = {
  id?: number;
  nazov: string;
  obsah: string;
};

export type InputInvoiceDocument = {
  dokumentGuid: string;
  nazov: string;
  obsah: string;
  typDokumentu: string;
};

export type OutputInvoiceDocument = {
  dokumentGuid: string;
  nazov: string;
  obsah: string;
  typDokumetu: string;
};

export interface DajSuborSpatnejDavkyResponse {
  idDavka: number;
  idFaktura: number;
  suborDavky: SuborDavky;
  vsetkyDavkyZIP: boolean;
}

export interface SkontrolujZuctovacieDavkyRequest {
  davky?: SuborDavky[] | null;
  groupId?: string;
  typKontroly: TYP_KONTROLY_DAVKY[];
}

export interface SkontrolujZuctovacieDavkyResponse {
  groupId: string;
}

export interface DajZoznamDokumentovFakturyQueries {
  idFaktura: number;
}

export interface DajZoznamDokumentovFakturyResponse {
  vstupneDokumenty: InputInvoiceDocument[];
  vystupneDokumenty: OutputInvoiceDocument[];
}

export interface DajMojeFakturyQueries {
  obdobia: number[];
}

export interface DajMojeFakturyResponse {
  faktury: Faktura[];
}

export interface DajChybuOvereniaQueries {
  interneIdDavka: number;
}

export interface DajChybuOvereniaResponse {
  kodChyby: number;
  protokol: string;
}

export interface DajProtokolQueries {
  idDavka: number;
  idSL: number;
  typProtokolu: number;
}

export interface DajProtokolResponse {
  nazov: string;
  obsah: string;
  typ: string;
}

export interface VymazDavkyQueries {
  idSkupiny: string;
  ids: number[];
}

export interface DajStavKontrolyZuctovacichDavokQueries {
  groupId: string;
  typKontroly: string;
}

export interface DajStavKontrolyZuctovacichDavokResponse {
  davky: {
    datumPrijatia: string;
    id: number;
    nazovSuboruDavky: string;
    stavOvereniaDavky: string;
  }[];
}

export interface PotvrdFakturuRequest {
  idFaktury: number;
  potvrdilMeno: string;
  variabilnySymbol: string;
}

export interface OdosliLekarenskuFakturuRequest {
  fakturaDokument: VstupnyDokumentFaktury;
  idFaktury: number;
  ostatneDokumenty: VstupnyDokumentFaktury[];
  suma: number;
  variabilnySymbol: string;
  vystavil: string;
}

export interface DajNepovoleneVSQueries {
  obdobie: number;
}

export interface DajNepovoleneVSResponse {
  nepovoleneVariabilneSymboly: {
    chybovyKod: number;
    nepovoleneVS: string[];
  };
}

export interface OdosliPrilohyFakturyRequest {
  dokumenty: VstupnyDokumentFaktury[];
  idFaktury: number;
}

export interface ZuctovanieState {
  invoices: {
    detail: Faktura | null;
    filter: {
      invoiceType: string;
      kodPracoviska: string;
      paperInvoice: string;
      periods: number[];
      search: string;
      state: string;
      zuc20: string;
    };
  };
  lists: {
    checked: {
      batchProcessing: boolean;
      data: UdajDavky[] | null;
      dataState: LoadingState;
      error: string | null;
      filteredData: UdajDavky[] | null;
    };
  };
  sendBatches: {
    checkedBatches: {
      data:
        | {
            datumPrijatia: string;
            id: number;
            nazovSuboruDavky: string;
            stavOvereniaDavky: 'C' | 'P' | 'S' | string;
          }[]
        | null;
      error: string | null;
      isCorrectiveBatchControl: boolean;
      selectedIds: number[];
      updatedAt: string;
    };
    dataState: LoadingState;
    error: string | null;
    groupId: string;
    isChunkedBatches: boolean;
    result: {
      countCalls: number;
      data: VysledokOdoslania | null;
      groupId: string | null;
      isError: boolean | null;
    };
    uploadAttempts: number;
  };
}

export type UdajeZmluvy = {
  cisloZmluvy?: string;
  druhPzsNaZmluve?: string;
  kodDruhPzsNaZmluve?: string;
  zmluvneNz?: {
    kodNZ?: string[];
  };
};
export type UdajeFaktury = {
  jeMoznePripojitFakturu: boolean;
  jeMozneSpojitDavkyOptikyDoJednejFA: boolean;
  maximalnaVyskaSumyNaFakture?: {
    chybovyKod?: string;
    maximalnaSuma?: string;
  };
  musiBytPripojenaFaktura: boolean;
  nepovoleneVariabilneSymboly?: {
    chybovaHlaska?: string;
    nepovoleneVS: string[];
    obdobie: string;
  }[];
  varujZeMozeBytPripojeneZdovodnenieOpravnejDavky: boolean;
};
export type UdajeDavky = {
  charakterDavky: string;
  idDavky: number;
  kodTypDavky: string;
  nazovSuboruDavky: string;
  nazovTypDavky: string;
  nzNaDavke: string;
  obdobie: number;
  stavOvereniaDavky?: string;
};

export interface UdajeRozdeleniaNaZalozky {
  idObdobie: number;
  idZalozka: number;
  poradieZobrazenia?: number;
  udajeDavky?: UdajeDavky[];
  udajeFaktury?: UdajeFaktury;
  udajeZmluvy?: UdajeZmluvy;
}
export interface RozdelenieDavok {
  groupId: string;
  udajePZS: UdajePZS;
  udajeRozdeleniaNaZalozky: UdajeRozdeleniaNaZalozky[];
}
enum TypPrilohyPreFakturu {
  faktura = 'FAKTURA',
  odvovodenie = 'ODVODNENIE',
  ostatne = 'OSTATNE',
}
export type UdajeFakturyNaOdoslanie = {
  VS?: number;
  cisloZmluvy?: string;
  prilohyFaktury: { obsahPrilohy: string; typPrilohy: TypPrilohyPreFakturu }[];
  suma?: number;
};

type ProtokolVysledkuOdoslania = {
  Komprimovany: boolean;
  NazovDokumentu?: string;
  Obsah?: string;
  Poznamka?: string;
  Typ?: string;
};

export type VysledokOdoslania = {
  idZalozka: number;
  kod?: string;
  popis?: string;
  protokol?: ProtokolVysledkuOdoslania;
};

export type VysledokStavuOdoslaniaDavok = {
  idOdoslania: number;
  popis?: string;
  protokol?: ProtokolVysledkuOdoslania;
  stav?: StavyOdoslaniaEnum;
};

export enum StavyOdoslaniaEnum {
  uspesne = 100,
  ciastocne = 150,
  neuspesne = 200,
}

export type UdajeRozdeleniaNaZalozkyForRequest = {
  fakturaDokument?: {};
  idZalozka: number;
  odovodnenieDokument?: {};
  ostatneDokumenty?: any[];
  udajeDavok: any[];
  udajeFaktury?: {};
};

export enum InvoiceActionType {
  Dolozenie = 'D',
  Potvrdenie = 'P',
}

type UdajeFakturyDokumentu = {
  cisloFaktury?: string;
  suma?: number;
  vs?: string;
};

export type OdosliDavkyForRequest = {
  fakturaDokument?: BaseDocument;
  idDavok?: number[];
  idZalozka?: number;
  odovodnenieDokument?: BaseDocument;
  ostatneDokumenty?: BaseDocument[];
  udajeFaktury?: UdajeFakturyDokumentu;
};
