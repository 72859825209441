import { color as ddsColor } from '../../constants';
import { inlineStrCondition } from '../../utils/app.utils';

interface Props {
  color?: string;
  grey?: boolean;
  id: string;
  secondaryColor?: boolean;
  style?: any;
}

const IconUsers = ({ color, grey, id, secondaryColor, style }: Props) => {
  const iconColor =
    color ||
    (grey
      ? ddsColor('grey', 300)
      : inlineStrCondition(
          !!secondaryColor,
          ddsColor('secondary', 500),
          ddsColor('primary', 500),
        ));
  return (
    <svg
      fill="none"
      height="24"
      id={id}
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6 7C6 5.34315 7.34315 4 9 4C10.6569 4 12 5.34315 12 7C12 8.65685 10.6569 10 9 10C7.34315 10 6 8.65685 6 7ZM9 2C6.23858 2 4 4.23858 4 7C4 9.76142 6.23858 12 9 12C11.7614 12 14 9.76142 14 7C14 4.23858 11.7614 2 9 2ZM5 14C3.67392 14 2.40215 14.5268 1.46447 15.4645C0.526784 16.4021 0 17.6739 0 19V21C0 21.5523 0.447715 22 1 22C1.55228 22 2 21.5523 2 21V19C2 18.2044 2.31607 17.4413 2.87868 16.8787C3.44129 16.3161 4.20435 16 5 16H13C13.7956 16 14.5587 16.3161 15.1213 16.8787C15.6839 17.4413 16 18.2043 16 19V21C16 21.5523 16.4477 22 17 22C17.5523 22 18 21.5523 18 21V19C18 17.6739 17.4732 16.4021 16.5355 15.4645C15.5979 14.5268 14.3261 14 13 14H5ZM19.0318 14.8789C19.1698 14.3442 19.7153 14.0226 20.25 14.1607C21.3227 14.4376 22.273 15.063 22.9517 15.9386C23.6304 16.8141 23.9992 17.8903 24 18.9982V18.9989V20.9989C24 21.5512 23.5523 21.9989 23 21.9989C22.4477 21.9989 22 21.5512 22 20.9989V18.9997V18.9994C21.9994 18.3348 21.7782 17.6892 21.371 17.1639C20.9638 16.6385 20.3936 16.2633 19.75 16.0972C19.2153 15.9591 18.8937 15.4137 19.0318 14.8789ZM16.248 2.16016C15.713 2.02317 15.1682 2.34584 15.0312 2.88087C14.8943 3.41589 15.2169 3.96067 15.752 4.09766C16.3973 4.26288 16.9692 4.63818 17.3777 5.16439C17.7861 5.6906 18.0078 6.33778 18.0078 7.00391C18.0078 7.67003 17.7861 8.31722 17.3777 8.84342C16.9692 9.36963 16.3973 9.74493 15.752 9.91016C15.2169 10.0471 14.8943 10.5919 15.0312 11.1269C15.1682 11.662 15.713 11.9846 16.248 11.8477C17.3236 11.5723 18.2768 10.9468 18.9576 10.0698C19.6383 9.19276 20.0078 8.11412 20.0078 7.00391C20.0078 5.89369 19.6383 4.81506 18.9576 3.93804C18.2768 3.06103 17.3236 2.43553 16.248 2.16016Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default IconUsers;
