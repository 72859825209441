import { ReactNode } from 'react';
import {
  Button,
  ButtonLink,
  ButtonSecondary,
  Modal,
  Notification,
} from '@dovera/design-system';
import useStyles from './PtovrdzujuciModal.styles';
import { hideTooltips } from '../../utils/app.utils';

interface Props {
  alignLeft?: boolean;
  buttonLinkText: string;
  errorText?: string | null;
  isDestructive?: boolean;
  isVisible: boolean;
  onHide: () => void;
  onPrimaryButtonClick: () => void;
  primaryButtonText: string;
  submitLoading?: boolean;
  text: string | ReactNode;
  title?: string;
  withBtnLink?: boolean;
}

const PotvrdzujuciModal = ({
  alignLeft,
  buttonLinkText,
  errorText,
  isDestructive,
  isVisible,
  onHide,
  onPrimaryButtonClick,
  primaryButtonText,
  submitLoading,
  text,
  title,
  withBtnLink,
}: Props) => {
  const classes = useStyles();
  if (isVisible) hideTooltips();
  return (
    <Modal
      className={classes.modal}
      closeOnOverlayClick={false}
      data-modal-initial-focus
      header={title || ''}
      id="potvrdzujuci-modal"
      isVisible={isVisible}
      onHide={() => onHide()}
    >
      <p className={`text-${alignLeft ? 'left' : 'center'}`}>{text}</p>
      <div className="d-flex-column align-items-center align-items-middle">
        {errorText && <Notification message={errorText} variant="error" />}
        <div className="align-items-center">
          {isDestructive && !withBtnLink ? (
            <ButtonSecondary
              className="no-mrg-bottom text-space-right"
              onClick={() => onHide()}
            >
              {buttonLinkText}
            </ButtonSecondary>
          ) : (
            <ButtonLink
              className="no-mrg-bottom text-space-right"
              onClick={() => onHide()}
            >
              {buttonLinkText}
            </ButtonLink>
          )}

          <Button
            className="no-mrg-bottom text-space-left"
            isDisabled={submitLoading}
            isLoading={submitLoading}
            onClick={() => onPrimaryButtonClick()}
            type={isDestructive ? 'destructive' : undefined}
          >
            {primaryButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PotvrdzujuciModal;
