// @ts-nocheck
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import useStyles from './NavigationPZSPanel.styles';
import CompanyImg from '../../assets/illustrations/pobocka-dlek.png';
import { Link, color } from '@dovera/design-system';
import { cx } from '../../utils/exports';
import IconChevronDown from '../CustomIcons/IconChevronDown';
import IconCheck from '../CustomIcons/IconCheck';
// @ts-check

const NavigationPZSPanel = () => {
  const classes = useStyles();
  const [activeSubject, setActiveSubject] = useState();
  const isSubjectAccess = useSelector(
    (state: RootState) => state.auth.isSubjectAccess,
  );
  const nazovZobrazPzs = useSelector(
    (state: RootState) => state.poskytovatel.nazovZobrazPzs,
  );
  return (
    <div className={classes.pzsPanelWrapper}>
      <button
        className={cx(classes.pzsPanel, activeSubject && 'open')}
        onClick={() => isSubjectAccess && setActiveSubject(!activeSubject)}
        type="button"
      >
        <img alt="PZS" src={CompanyImg} />
        <div>
          <b>{nazovZobrazPzs}</b>
          {isSubjectAccess && <IconChevronDown id="menu-pzs--angle" />}
        </div>
      </button>
      {isSubjectAccess && (
        <div
          className={cx(
            classes.pzsPanelSubjectAccess,
            activeSubject && 'active',
          )}
        >
          <h6>
            Poskytovateľ{' '}
            <IconCheck color={color('success', 500)} id="menu-pzs--iconCheck" />
          </h6>
          <Link href={window.env?.plaLink}>Platiteľ</Link>
        </div>
      )}
    </div>
  );
};

export default NavigationPZSPanel;
