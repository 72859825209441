interface Props {
  id: string;
}

const IconPill = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      stroke="#A7A9AC"
      strokeLinecap="round"
      strokeWidth="2"
      x1="11.4142"
      x2="15"
      y1="11.5"
      y2="15.0858"
    />
    <rect
      height="21"
      rx="4.5"
      stroke="#A7A9AC"
      strokeWidth="2"
      transform="rotate(45 16.2422 1.39453)"
      width="9"
      x="16.2422"
      y="1.39453"
    />
  </svg>
);

export default IconPill;
