import { Skeleton } from '@dovera/design-system';
import { cx } from '../../utils/exports';

interface Props {
  displayBlock?: boolean;
}

const InputPreloader = ({ displayBlock }: Props) => (
  <div>
    <Skeleton
      className={cx(displayBlock && 'd-block', 'mb-xxsmall')}
      height={16}
      width={150}
    />
    <Skeleton
      className={cx(displayBlock && 'd-block')}
      height={52}
      width={211}
    />
  </div>
);

export default InputPreloader;
