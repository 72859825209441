import { ReactNode } from 'react';
import cx from 'classnames';

import useStyles from './styles';

type SuggestionsMenuProps = {
  active: boolean;
  children: ReactNode;
  hasError?: boolean;
  id: string;
};

const SuggestionsMenu = ({
  active,
  children,
  hasError,
  id,
}: SuggestionsMenuProps) => {
  const classes = useStyles();

  return (
    <div
      key={id}
      className={cx([
        `form-control dropdown`,
        classes.dropdownMenu,
        {
          'is-active': active,
          [classes.dropdownMenuHidden]: !active,
          [classes.dropdownMenuError]: hasError,
        },
      ])}
      data-testid={`${id}-results`}
      id={`${id}-results`}
      role="listbox"
    >
      {children}
    </div>
  );
};

export default SuggestionsMenu;
