import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#1F57A5',
    borderRadius: 0,
    padding: '16px 32px',
  },
  menuItem: {
    margin: '8px 0',
    cursor: 'pointer',
    color: '#FFF',
    '& p, svg': {
      opacity: 0.6,
    },
    '&:hover p, svg:hover': {
      opacity: 1,
    },
  },
});
