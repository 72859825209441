import { Path, Svg } from '@react-pdf/renderer';

const InfoIcon = ({ ...props }) => (
  // @ts-ignore
  <Svg height="10" viewBox="0 0 10 10" width="10" {...props}>
    <Path
      d="M5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10ZM5 8.75C7.07107 8.75 8.75 7.07107 8.75 5C8.75 2.92893 7.07107 1.25 5 1.25C2.92893 1.25 1.25 2.92893 1.25 5C1.25 7.07107 2.92893 8.75 5 8.75ZM5 3.75C4.65482 3.75 4.375 3.47018 4.375 3.125C4.375 2.77982 4.65482 2.5 5 2.5C5.34518 2.5 5.625 2.77982 5.625 3.125C5.625 3.47018 5.34518 3.75 5 3.75ZM5 4.375C5.34518 4.375 5.625 4.65482 5.625 5V6.875C5.625 7.22018 5.34518 7.5 5 7.5C4.65482 7.5 4.375 7.22018 4.375 6.875V5C4.375 4.65482 4.65482 4.375 5 4.375Z"
      fill="#A7A9AC"
    />
  </Svg>
);

export default InfoIcon;
