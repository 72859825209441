import { Aktualita } from '../../../types/poskytovatel.types';
import { Grid, GridCol, Link, color } from '@dovera/design-system';
import { formatDate } from '../../../utils/date.utils';
import useStyles from './DoveraNews.styles';
import IconExternalLink from '../../../components/CustomIcons/IconExternalLink';
import strings from '../../../constants/strings';

const texts = strings.dashboard.news;

interface Props {
  news: Aktualita[];
}

const DoveraNews = ({ news }: Props) => {
  const classes = useStyles();
  const renderNews = news && (
    <Grid>
      {news
        .filter((n, key) => key < 2)
        .map((n) => (
          <GridCol key={`news-record--${n.id}`} size={{ m: 6, s: 12 }}>
            <Link
              className={classes.actuality}
              href={n.url}
              placeholder
              target="_blank"
            >
              <span className={classes.date}>{formatDate(n.datum)}</span>
              <h6>{n.nazov}</h6>
            </Link>
          </GridCol>
        ))}
    </Grid>
  );
  const renderShowAll = (
    <Link
      href="https://www.dovera.sk/lekar/aktuality"
      placeholder
      target="_blank"
      type="secondary"
    >
      {texts.showAll}
      <IconExternalLink
        color={color('black')}
        height={16}
        id={`icon-external--actuality`}
        width={16}
      />
    </Link>
  );
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>
        <span>{texts.title}</span>
        {renderShowAll}
      </h3>
      {renderNews}
    </div>
  );
};

export default DoveraNews;
