import { createUseStyles } from 'react-jss';

import { color, fontWeight } from '../../constants';

interface StylesProps {
  isActive?: boolean;
  isCompact?: boolean;
}

export default createUseStyles({
  wrapper: ({ isActive, isCompact }: StylesProps) => ({
    cursor: !isActive && !isCompact && 'pointer',
    textAlign: !isCompact && 'center',
  }),
  nazov: ({ isActive, isCompact }: StylesProps) => ({
    fontSize: isActive || isCompact ? '1.5rem' : '1.125rem',
    fontWeight: fontWeight.bold,
    color: isActive || isCompact ? color('secondary') : color('grey', 500),
    '& svg': {
      verticalAlign: 'middle',
      marginTop: isActive ? -9 : -7,
    },
  }),
  popis: ({ isActive, isCompact }: StylesProps) => ({
    fontSize: isActive || isCompact ? '1.125rem' : '0.875rem',
    color: isActive || isCompact ? color('secondary') : color('grey', 500),
    textTransform: 'capitalize',
  }),
  icon: () => ({
    color: color('grey', 300),
  }),
  noWrap: {
    whiteSpace: 'nowrap',
  },
  spanSvg: {
    '& > span': {
      padding: '10px 0',
    },
  },
  preloader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
