// A generic control

export function SessionControl(CobrowseIO) {
  const button = document.createElement('div');
  button.className = '__cbio_ignored';
  button.textContent = 'Ukončiť zdieľanie';
  button.style.fontFamily = 'sans-serif';
  button.style.padding = '10px 13px';
  button.style.fontSize = '13px';
  button.style.color = 'white';
  button.style.boxShadow = '0px 2px 5px #33333344';
  button.style.cursor = 'pointer';
  button.style.borderRadius = '30px';
  button.style.background = 'blue';
  button.style.position = 'fixed';
  button.style.zIndex = '2147483647';
  button.style.bottom = '20px';
  button.style.left = '50%';
  button.style.transform = 'translateX(-50%)';
  button.addEventListener('click', () => CobrowseIO.currentSession.end());
  return button;
}
