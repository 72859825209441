import { Button, ButtonLink, Icon, Loader, color } from '@dovera/design-system';
import ReactPDF, { BlobProvider } from '@react-pdf/renderer';
import useStyles from './BlobPdf.styles';
import {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import IconPrinter from '../../CustomIcons/IconPrinter';
import { isIE } from '../../../utils/app.utils';
import { CustomTooltip } from '../../CustomTooltip';
import { cx } from '../../../utils/exports';
import strings from '../../../constants/strings';
import { getButtonClass } from './utils';

interface Props {
  buttonLabel: string;
  buttonType: 'button' | 'link' | 'secondary';
  className?: any;
  iconPosition: 'left' | 'right';
  iconType: 'download' | 'print';
  onClick?: () => void;
  pdfContent: ReactElement<
    ReactPDF.DocumentProps,
    string | JSXElementConstructor<any>
  >;
}

const BlobPdf = ({
  buttonLabel,
  buttonType,
  className,
  iconPosition,
  iconType,
  onClick,
  pdfContent,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const clickCallback = useCallback(() => {
    setIsLoading(true);
    if (onClick) onClick();
  }, [onClick]);

  const renderIcon =
    iconType === 'print' ? (
      <IconPrinter id="icon-printer" />
    ) : (
      <Icon color={color('primary', 600)} name="download" />
    );

  const renderLoader = <Loader color="#a7a9ac" size={16} />;

  const blob = (
    <BlobProvider document={pdfContent}>
      {({ url }) => {
        if (url) {
          setIsLoading(false);
          fetch(url)
            .then((resp) => resp.arrayBuffer())
            .then((resp) => {
              const file = new Blob([resp], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL, '_blank')?.focus();
            });
        }
        return <span />;
      }}
    </BlobProvider>
  );

  if (isIE) {
    return (
      <div
        style={{
          position: 'relative',
          display: 'block',
          textAlign: 'right',
          paddingTop: 5,
        }}
      >
        <CustomTooltip
          dialog={strings.vseobecne.tlacitIETooltip}
          id="disabled-ie-print"
        >
          <ButtonLink className={cx(classes.btnIE)}>
            <span>
              {strings.vseobecne.tlacit}
              <IconPrinter id="icon-printer" />
              <span />
            </span>
          </ButtonLink>
        </CustomTooltip>
      </div>
    );
  }

  return (
    <Button
      className={cx(
        getButtonClass(buttonType),
        classes.btn,
        className || '',
        isLoading && 'btn--disabled',
        iconPosition === 'left' && classes.iconPositionLeft,
      )}
      isDisabled={isLoading}
      onClick={clickCallback}
    >
      {iconPosition === 'left' && (isLoading ? renderLoader : renderIcon)}
      {buttonLabel}
      {isLoading ? blob : <span />}
      {iconPosition === 'right' && (isLoading ? renderLoader : renderIcon)}
    </Button>
  );
};

export default BlobPdf;
