import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    background: '#E9F2FF',
    padding: getRem(32),
    marginBottom: getRem(24),
    borderRadius: 8,
    '& button': {
      minHeight: 0,
      '& svg': {
        marginLeft: `${getRem(12)} !important`,
      },
    },
  },
  priceType: {
    fontSize: getRem(14),
  },
});
