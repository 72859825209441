import { Loader, Notification } from '@dovera/design-system';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import { RootState } from '../../../../../../rootReducer';
import { storeStep3 } from '../../../../../../slices/spaProposals.slice';
import StepButtons from '../../../StepButtons';
import FormElements from './FormElements';

interface Props {
  confirmationField: any;
  contraindications?: string[];
  contraindicationsField: any;
  epicrisisField: any;
  eventDateField: any;
  examinations?: string[];
  examinationsField: any;
  onCancel: () => void;
  onContinue: () => void;
  svlzField: any;
}

const StepFields = ({
  confirmationField,
  contraindications,
  contraindicationsField,
  epicrisisField,
  eventDateField,
  examinations,
  examinationsField,
  onCancel,
  onContinue,
  svlzField,
}: Props) => {
  const dispatch = useAppDispatch();
  const { step3 } = useSelector(
    (state: RootState) => state.spaProposals.new.stepper,
  );
  const {
    formData: { data, error, isLoading },
  } = useSelector((state: RootState) => state.spaProposals.new.data);

  const onContinueCallback = useCallback(() => {
    if (step3.showCancelBtnAfter) {
      dispatch(storeStep3({ showCancelBtnAfter: null }));
    } else {
      onContinue();
    }
  }, [dispatch, onContinue, step3.showCancelBtnAfter]);

  if (isLoading) return <Loader size={24} />;
  if (!data || error)
    return error ? <Notification message={error} variant="error" /> : <span />;

  return (
    <>
      <FormElements
        confirmationField={confirmationField}
        contraindications={contraindications}
        contraindicationsField={contraindicationsField}
        epicrisisField={epicrisisField}
        eventDateField={eventDateField}
        examinations={examinations}
        examinationsField={examinationsField}
        svlzField={svlzField}
      />
      <StepButtons
        hide={(!step3.showCancelBtnAfter && !step3.spaSVLZ) || isLoading}
        onCancel={onCancel}
        onContinue={onContinueCallback}
        showCancel={!!step3.showCancelBtnAfter}
      />
    </>
  );
};

export default StepFields;
