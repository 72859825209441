import { useContext, useEffect } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import { PeriodPicker } from '../../PeriodPicker';
import { useQuery } from '../../../hooks/useQuery';
import { FILTER_PREFIX } from '../constants';
import { PeriodPickerType } from '../types';

const PeriodPickerFilter = ({ ...props }: PeriodPickerType) => {
  const { id, onChoose, value, ...restProps } = props;

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
    if (queryValue) {
      handleChoose(queryValue);
    }
    // eslint-disable-next-line
  }, []);

  const handleChoose = (val) => {
    searchParams.set(`${FILTER_PREFIX}_${id}`, val);
    setSearchParams(searchParams);
    onChoose(val);
  };

  return (
    <PeriodPicker
      id="pcp-period-filter"
      onChoose={(val) => handleChoose(val)}
      value={value}
      {...restProps}
    />
  );
};

export default PeriodPickerFilter;
