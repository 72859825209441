interface Props {
  id: string;
}

const IconCloseModal = ({ id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="15.5" stroke="#3F7C22" />
    <path
      d="M12.352 11.6449C12.1559 11.4505 11.8393 11.4519 11.6449 11.648C11.4505 11.8441 11.4519 12.1607 11.648 12.3551L12.352 11.6449ZM20.3551 12.352C20.5495 12.1559 20.5481 11.8393 20.352 11.6449C20.1559 11.4505 19.8393 11.4519 19.6449 11.648L20.3551 12.352ZM19.6464 20.3182C19.8417 20.5134 20.1583 20.5134 20.3536 20.3182C20.5488 20.1229 20.5488 19.8063 20.3536 19.611L19.6464 20.3182ZM11.6496 19.6079C11.4526 19.8015 11.4498 20.118 11.6433 20.315C11.8369 20.512 12.1534 20.5148 12.3504 20.3213L11.6496 19.6079ZM11.648 12.3551L15.6834 16.3551L16.3874 15.6449L12.352 11.6449L11.648 12.3551ZM16.3905 16.352L20.3551 12.352L19.6449 11.648L15.6803 15.648L16.3905 16.352ZM15.6818 16.3536L19.6464 20.3182L20.3536 19.611L16.389 15.6464L15.6818 16.3536ZM15.685 15.6433L11.6496 19.6079L12.3504 20.3213L16.3858 16.3567L15.685 15.6433Z"
      fill="#3F7C22"
    />
  </svg>
);

export default IconCloseModal;
