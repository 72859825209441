import { LoadingState } from '.';

export enum STAV_DAVKY {
  Chybna = 'C',
  PrebiehaKontrola = 'P',
  Spravna = 'S',
}

export enum StavyOdoslaniaEnum {
  ciastocne = '',
  neuspesne = 'NOK',
  uspesne = 'OK',
}

export type FilterBatchValues = {
  dateFrom: string;
  dateTo: string;
  state: string;
  workplace: string;
};

export type DavkaStavCL = {
  id: string;
  idSkupinyDavok: string;
  kodChyby: number;
  nazov: string;
  protokol: string;
  stavKontroly: string;
};
export interface CakackyState {
  batchUpload: {
    dataState: LoadingState;
    error: string | null;
    groupId: string;
    uploadAttempts: number;
    validated: {
      data: DavkaStavCL[] | null;
      dataState: LoadingState;
      error: string | null;
      updatedAt: string;
    };
  };
  batches: {
    data: DavkaCakacka[] | null;
    dataState: LoadingState;
    error: string | null;
    filter: {
      dateFrom: string;
      dateTo: string;
    };
    withoutReload: 'detail' | 'preview' | null;
    workplaces: PracoviskoT[] | null;
  };
}

export type SuborDavky = {
  nazov: string;
  obsah: string;
};

export enum TYP_KONTROLY_DAVKY {
  ChronologiaDavok = 'ChronologiaDavok',
  DatumDavky = 'DatumDavky',
  DuplicitaDavok = 'DuplicitaDavok',
  PrislusnostDavky = 'PrislusnostDavky',
  SyntaktikaDavok = 'SyntaktikaDavok',
  TypDavky = 'TypDavky',
  UtvaryDavky = 'UtvaryDavky',
}

export type StiahnutieDavkyCakacky = {
  datumStiahnutia: string;
  kanal: string;
  vykonal: string;
};

export type LogDavkyCL = {
  datumStiahnutia: string;
  typDavky: string;
  vykonal: string;
};

export type StavSpracovaniaCL = {
  chyboveHlasenieTxs: string | null;
  idDavky: string;
  nazovDavky: string;
  vysledokSpracovaniaTxs: string;
};

export type PracoviskoT = {
  cislo: number;
  datumDo?: string;
  datumOd?: string;
  nazov: string;
};
export interface SkontrolujDavkyCLRequest {
  datumPodania: string;
  davky?: SuborDavky[] | null;
  idSkupiny?: string;
  typKontroly: TYP_KONTROLY_DAVKY[];
}

export interface SkontrolujDavkyCLResponse {
  davky: any;
  idSkupiny: string;
  typKontroly: TYP_KONTROLY_DAVKY[];
}
export interface DajZoznamDavokCLRequest {
  dajPoslednu?: boolean;
  dajPracoviska?: boolean;
  datumDo?: string;
  datumOd?: string;
}

export type DavkaCakacka = {
  charakterDavky: CHARAKTER_DAVKY_CAKACKY;
  datumOdoslaniaDavky: string;
  datumVytvoreniaDavky: string;
  guidDavky: string;
  guidOdpovednejDavky: string | null;
  nazovDavky: string;
  poradieVRiadku: number;
  poradoveCisloDavky: number;
  pracovisko: number;
  riadok: number;
  stav: STAV_DAVKY_CAKACKY;
  stavPrevzatia: boolean;
  stiahnutiaDavok: StiahnutieDavkyCakacky[];
  typDavky: TYP_DAVKY_CAKACKY;
  vysledokSpracovaniaTXS: 'OK' | 'NOK';
};

export interface DajZoznamDavokCLResponse {
  pracoviska: PracoviskoT[] | null;
  zoznamDavok: DavkaCakacka[] | null;
}

export interface DajStavKontrolCLQueries {
  idSkupiny: string;
}

export interface DajStavKontrolCLResponse {
  davky: DavkaStavCL[];
}

export interface OdosliDavkyCLRequest {
  davky: string[];
  idSkupiny: string;
}

export interface OdosliDavkyCLResponse {
  idOdoslania: string;
}

export interface DajDavkuCLQueries {
  guidDavky: string;
}

export interface DajDavkuCLResponse {
  davka: {
    nazov: string;
    obsah: string;
    typ: string;
  };
}

export interface DajStavSpracovaniaCLQueries {
  idOdoslania: string;
}

export interface DajStavSpracovaniaCLResponse {
  stavySpracovania: StavSpracovaniaCL[];
}

export interface VymazDavkuCLQueries {
  idDavky: string;
}

export interface VymazDavkuCLResponse {
  error: {
    detail: string;
    kod: string;
    text: string;
  };
  vysledok: boolean;
}

export enum STAV_DAVKY_CAKACKY {
  NaStiahnutie = 'naStiahnutie',
  Spracovanie = 'spracovanie',
  Undefined = '',
  Vybavena = 'vybavena',
}
export enum TYP_DAVKY_CAKACKY {
  Aktualizacna = '998C',
  Nova = '998',
  Odpovedna = '998C',
}
export enum CHARAKTER_DAVKY_CAKACKY {
  Aktualizacna = 'O',
  Nova = 'N',
  Odpovedna = 'M',
}
