// @ts-nocheck
import { useEffect } from 'react';
import useStyles from './DashboardBanner.styles';
import { Modal, color } from '@dovera/design-system';
import useScreenWidth from '../../hooks/screen-width/useScreenWidth';
import { sendDataLayer } from '../../utils/gtm.utils';
import { cx } from '../../utils/exports';
import IconClose from '../CustomIcons/IconClose';
import IconCloseModal from '../CustomIcons/IconCloseModal';
import { BannerTheme } from '../../types/baner.types';
// @ts-check

interface Props {
  dataLayerEvent?: string;
  isCard?: boolean;
  isModal?: boolean;
  onClose?: () => void;
  show: boolean;
  theme?: BannerTheme;
}

const DashboardBanner = ({
  children,
  dataLayerEvent,
  isCard,
  isModal,
  onClose,
  show,
  theme,
}: Props) => {
  const classes = useStyles({ theme });
  const { isMobile } = useScreenWidth();
  useEffect(() => {
    if (show && dataLayerEvent) sendDataLayer(dataLayerEvent);
  }, [show, dataLayerEvent]);
  if (isModal)
    return (
      <Modal
        className={classes.modal}
        closeOnOverlayClick={false}
        data-modal-initial-focus
        header={<span />}
        id="dashboard-banner-modal"
        isVisible={show}
        onHide={onClose}
      >
        <div
          className={cx(classes.root, classes.dashboardBanner)}
          data-testid="dashboard-banner"
        >
          {children}
        </div>
      </Modal>
    );

  return (
    <div
      className={cx(classes.root, classes.dashboardBanner, 'mb')}
      data-testid="dashboard-banner"
    >
      {!isCard && (
        <button
          className={cx(classes.close, isMobile ? '--withoutHover' : '')}
          data-testid="dashboard-banner--close"
          onClick={() => onClose()}
          type="button"
        >
          {isMobile ? (
            <IconClose
              color={color('black')}
              id="dashboard-banner--close-icon"
            />
          ) : (
            <IconCloseModal id="dashboard-banner--close-icon" />
          )}
        </button>
      )}
      {children}
    </div>
  );
};

export default DashboardBanner;
