import { useEffect } from 'react';
import { Button, Notification } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import {
  changeStepStatus,
  storeStep1,
} from '../../../../slices/spaProposals.slice';
import StepWrapper from '../StepWrapper';
import strings from '../../../../constants/strings';
import { RootState } from '../../../../rootReducer';
import { getListOfAvailableDoctors } from '../../../../api/spaProposals';
import { useField, useForm } from 'react-final-form-hooks';
import { Form } from './form';
import { validationMsg } from '../../../../utils/form.utils';
import { VALIDATION } from '../../../../types/validation.types';
import { Summary } from './summary';
import { useAppDispatch } from '../../../../hooks/useStore';

const texts = strings.proposals.new;
interface Props {
  onContinue: () => void;
}

const validate = (values: { [x: string]: any }) => {
  const errors: any = {};

  if (!values.doctor) errors.doctor = validationMsg(VALIDATION.RequiredEmpty);

  if (!values.ambulance)
    errors.ambulance = validationMsg(VALIDATION.RequiredEmpty);

  return errors;
};

const Step1 = ({ onContinue }: Props) => {
  const dispatch = useAppDispatch();
  const { pouzivatelId, vztahId } = useSelector(
    (state: RootState) => state.auth,
  );
  const { data, error, isLoading } = useSelector(
    (state: RootState) => state.spaProposals.new.data.doctors,
  );
  const isReseted = useSelector(
    (state: RootState) => state.spaProposals.new.isReseted,
  );
  const { form, handleSubmit, values } = useForm({
    initialValues: {
      doctor: data?.length === 1 ? data[0].idLekarZdravPrac.toString() : '',
      ambulance:
        data?.length === 1 && data[0].ambulancie?.length === 1
          ? data[0].ambulancie[0].idNZ.toString()
          : '',
    },
    onSubmit: (values) => {
      dispatch(
        storeStep1({ ambulanceId: values.ambulance, doctorId: values.doctor }),
      );
      dispatch(changeStepStatus({ step: 1, status: 'completed' }));
      onContinue();
    },
    validate,
  });
  const doctorField = useField('doctor', form);
  const ambulanceField = useField('ambulance', form);
  useEffect(() => {
    if (!isLoading && !data)
      dispatch(
        getListOfAvailableDoctors({
          idPouzivatel: pouzivatelId || 0,
          idVZ: vztahId || 0,
        }),
      );
    // eslint-disable-next-line
  }, [dispatch, data, pouzivatelId, vztahId]);
  useEffect(() => {
    // if PZS has only 1 doctor with only 1 ambulance
    if (data?.length === 1 && data?.[0]?.ambulancie?.length === 1)
      handleSubmit();
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (isReseted) form.restart();
  }, [form, isReseted]);
  const renderButton = values.doctor && !error && (
    <>
      <div className="mb-small" />
      <Button className="no-mrg" onClick={() => handleSubmit()} submit>
        {texts.buttons.continue}
      </Button>
    </>
  );
  const renderError = error && <Notification message={error} variant="error" />;
  return (
    <StepWrapper
      content={
        <form onSubmit={handleSubmit}>
          {renderError}
          {!error && (
            <Form
              ambulanceField={ambulanceField}
              doctorData={data}
              doctorField={doctorField}
              form={form}
              isLoading={isLoading}
              values={values}
            />
          )}
          {renderButton}
        </form>
      }
      step={1}
      summary={<Summary />}
      title={texts.stepTitles.step1}
    />
  );
};

export default Step1;
