import { useCallback, useEffect, useState } from 'react';
import { cx } from '../../utils/exports';
import useStyles from './SortableTable.styles';

interface Props {
  activeOrder: 'asc' | 'desc' | '' | string;
  children: any;
  onClick: (order: 'asc' | 'desc') => void;
  resetOrder?: boolean;
  sortType?: 'alphabetical' | 'alphabetical-desc' | 'date' | string;
}

const SortableColumn = ({
  activeOrder,
  children,
  onClick,
  resetOrder,
  sortType,
}: Props) => {
  const classes = useStyles();
  const [order, setOrder] = useState<'asc' | 'desc' | string | null>(
    activeOrder || null,
  );
  const clickCallback = useCallback(() => {
    const possibleOrder =
      order === 'asc' ||
      (order !== 'desc' &&
        (sortType === 'date' || sortType === 'alphabetical-desc'))
        ? 'desc'
        : 'asc';
    setOrder(possibleOrder);
    onClick(possibleOrder);
  }, [onClick, order, sortType]);
  useEffect(() => {
    if (resetOrder) setOrder(null);
  }, [resetOrder]);
  useEffect(() => {
    if (activeOrder) setOrder(activeOrder);
  }, [activeOrder]);
  return (
    <button
      className={cx(
        classes.sortableCol,
        !!order && order,
        sortType !== 'alphabetical' && 'initDesc',
      )}
      onClick={clickCallback}
      type="button"
    >
      {children}
      <span className={classes.sortIcon} />
    </button>
  );
};

export default SortableColumn;
