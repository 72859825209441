import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import routes, { diagnozyRoutes } from '../../routes';
import { RootState } from '../../rootReducer';
import {
  getAuthFromStorage,
  getStateFromStorage,
  setRefreshTokenInterval,
  signinRedirect,
} from '../../slices/auth.slice';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useCallback, useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useStore';
import useTokenExpiration from '../../hooks/useTokenExpiration';
import useInterval from '../../hooks/useInterval';
import { userHasSection } from '../../utils/app.utils';
import ChybajuceSekcie from '../../pages/chyba-prihlasenia/ChybajuceSekcie';
import { isTokenExpiring } from '../../utils/auth.utils';
import { REDIRECT_LOGIN_URI } from '../../constants/misc';

function PrivateRoute() {
  const authContext = useAuthContext();
  const token = getAuthFromStorage();
  const expiredToken = useTokenExpiration(token || '');
  const dispatch = useAppDispatch();

  const { isASOC, refreshTokenInterval } = useSelector(
    (state: RootState) => state.auth,
  );
  const hasHPKEI = userHasSection('PZSHPKEI', token);
  const hasPZSNAKL = userHasSection('PZSNAKL', token);
  const sekciaZmluvy = userHasSection('ZMLUVY', token);
  const initialDataLoded = useSelector(
    ({ poskytovatel }: RootState) => poskytovatel.isLoaded,
  );

  const refreshToken = useCallback(() => {
    if (isTokenExpiring())
      authContext
        .renewToken()
        .then((res) => {
          dispatch(signinRedirect(res));
        })
        .catch(() => {
          if (expiredToken) authContext.logout();
        });
  }, [authContext, dispatch, expiredToken]);

  useInterval(() => {
    refreshToken();
  }, 30000);

  useEffect(() => {
    if (token && !expiredToken) dispatch(getStateFromStorage({ token }));
  }, [authContext, dispatch, expiredToken, token]);

  if (token && !refreshTokenInterval) dispatch(setRefreshTokenInterval());

  /** Ak nema povolenu ziadnu zo sekcii HP, Naklady, Zmluvy - redirect do starej EP */
  if (
    token &&
    !hasHPKEI &&
    !hasPZSNAKL &&
    !sekciaZmluvy &&
    !isASOC &&
    initialDataLoded &&
    !userHasSection('NAVZS', token) &&
    !userHasSection('NKUP', token) &&
    !userHasSection('KAP', token) &&
    !userHasSection('PZSP', token)
  )
    return <ChybajuceSekcie />;
  /** Ak nema povolenu sekciu HP a snazi sa dostat na endpoint /hodnotiace-parametre - redirect na dashboard */
  if (
    token &&
    !hasHPKEI &&
    window.location.pathname.includes(routes.parameters)
  ) {
    return <Navigate to={routes.dashboard} />;
  }

  /** Ak nema povolenu sekciu PZS Naklady a snazi sa dostat na endpoint s pacientmi - redirect na dashboard */
  if (
    token &&
    !hasPZSNAKL &&
    (window.location.pathname.includes(routes.pp) ||
      window.location.pathname.includes(routes.pdp) ||
      window.location.pathname.includes(diagnozyRoutes.index))
  )
    return <Navigate to={routes.dashboard} />;
  /** Ak nema povolenu sekciu Zmluvy a snazi sa dostat na endpoint so zmluvami - redirect na dashboard */
  if (
    token &&
    !sekciaZmluvy &&
    !isASOC &&
    window.location.pathname.includes(routes.zmluvyDodatky)
  )
    return <Navigate to={routes.dashboard} />;
  if (!token) {
    const path = window.location.pathname + window.location.search;
    if (![routes.login, routes.logout, routes.callback].includes(path))
      localStorage.setItem(REDIRECT_LOGIN_URI, path);
    return <Navigate to={routes.login} />;
  }
  if (initialDataLoded) return <Outlet />;

  return <Outlet />;
}

export const PureComponent = PrivateRoute;

export default PrivateRoute;
