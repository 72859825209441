interface Props {
  id?: string;
}

const IconBlueEquals = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#1F57A5"
    />
    <path
      d="M4.97998 14.952H19.02V16.248H4.97998V14.952ZM4.97998 7.75195H19.02V9.04795H4.97998V7.75195Z"
      fill="white"
    />
  </svg>
);

export default IconBlueEquals;
