import { ReactNode, useRef } from 'react';
import useStyles from '../Proposals.styles';
import StepHeader from '../../../components/Stepper/StepHeader/StepHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeActiveStep } from '../../../slices/spaProposals.slice';
import StepSummary from '../../../components/Stepper/StepSummary/StepSummary';
import StepContent from '../../../components/Stepper/StepContent/StepContent';
import { cx } from '../../../utils/exports';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import { useAppDispatch } from '../../../hooks/useStore';

interface Props {
  content: ReactNode;
  step: number;
  summary: ReactNode;
  title: string;
}

const StepWrapper = ({ content, step, summary, title }: Props) => {
  const classes = useStyles();
  const stepRef = useRef(null);
  const dispatch = useAppDispatch();
  const stepStatus = useSelector(
    (state: RootState) =>
      state.spaProposals.new.stepper[`step${step}`]?.stepStatus,
  );
  const { activeStep } = useSelector(
    (state: RootState) => state.spaProposals.new.stepper,
  );
  const active: boolean = activeStep === step - 1;
  return (
    <>
      <StepHeader
        active={active}
        completed={!active && stepStatus === 'completed'}
        disabled={!active && !['completed', 'error'].includes(stepStatus)}
        editText="Upraviť"
        error={!active && stepStatus === 'error'}
        icon={<SafeHtml html={`&nbsp;${step}.`} wrapper="tspan" />}
        onHeaderClick={() => {
          if (['completed', 'error'].includes(stepStatus))
            dispatch(changeActiveStep({ step: step - 1 }));
        }}
        stepRef={stepRef}
      >
        {title}
      </StepHeader>
      <StepSummary
        completed={!active && ['completed', 'error'].includes(stepStatus)}
      >
        {summary}
      </StepSummary>
      <StepContent
        active={active}
        className={cx(active && classes.stepContent)}
        lastStep={step === 5}
      >
        {content}
      </StepContent>
    </>
  );
};

export default StepWrapper;
