import { useEffect } from 'react';
import useStyles from '../Profil.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { fetchKontaktneOsoby } from '../../../slices/profil.slice';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import {
  Container,
  Link,
  Loader,
  PageSection,
  TableApp,
  colors,
} from '@dovera/design-system';
import IconUserBlue from '../../../components/CustomIcons/IconUserBlue';
import BackButton from '../../../components/BackButton/BackButton';
import routes from '../../../routes';
import { epSekcie } from '../../../constants/epLinks';
import { cx } from '../../../utils/exports';
import { NoData } from '../../../components/EmptyState';
import IconCheck from '../../../components/CustomIcons/IconCheck';
import { formatPhone, hotjarMasking } from '../../../utils/strings.utils';
import IconCross from '../../../components/CustomIcons/IconCross';
import { useAppDispatch } from '../../../hooks/useStore';
import { getPZS } from '../../../slices/auth.slice';

const KontaktneOsoby = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const idHz = useSelector((state: RootState) => state.poskytovatel.idHz);
  const { data, error, isLoading } = useSelector(
    (state: RootState) => state.profil.kontaktneOsoby,
  );
  useEffect(() => {
    if (idHz && !isLoading)
      dispatch(fetchKontaktneOsoby(pzs.userId || 0, idHz, pzs.vzId || 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, idHz]);
  const renderDescription = (
    <div className={classes.description}>
      <p className="no-mrg">
        Zoznam kontaktných osôb tak, ako ich evidujeme v poisťovni.
      </p>
      <p className="no-mrg">
        Ak chcete pridať alebo upraviť kontaktné osoby, oznámte nám to cez{' '}
        <Link href={epSekcie.spravy} placeholder target="_blank">
          Schránku správ
        </Link>
        .
      </p>
    </div>
  );
  const renderTable = data && (
    <TableApp
      caption=""
      className="table-caption--hide"
      columns={[
        {
          Header: 'Meno a rola',
          accessor: 'col1',
        },
        {
          Header: 'Telefón',
          accessor: 'col2',
        },
        {
          Header: 'E-mail',
          accessor: 'col3',
        },
        {
          Header: 'Splnomocnenie',
          accessor: 'col4',
        },
      ]}
      data={data.map((d) => ({
        col1: (
          <div>
            {hotjarMasking(d.menoKontaktnejOsoby || '-', 'b')}
            <br />
            {hotjarMasking(d.nazovTypOsoby)}
          </div>
        ),
        col2: (
          <div>
            {d.kontaktnyUdaj
              .filter(
                (u) =>
                  u.kodTypKontaktUdaj === 'PL' || u.kodTypKontaktUdaj === 'M',
              )
              .map((k, key) => (
                <>
                  {key > 0 && <br />}
                  <span key={`telefon-${key}`}>
                    {hotjarMasking(
                      formatPhone(k.kontakt, k.kodTypKontaktUdaj === 'M'),
                    )}
                  </span>
                </>
              ))}
            {!d.kontaktnyUdaj.some(
              (u) =>
                u.kodTypKontaktUdaj === 'PL' || u.kodTypKontaktUdaj === 'M',
            ) && '-'}
          </div>
        ),
        col3: (
          <div>
            {d.kontaktnyUdaj
              .filter((u) => u.kodTypKontaktUdaj === 'E')
              .map((k, key) => (
                <>
                  {key > 0 && <br />}
                  <span key={`email-${key}`}>{hotjarMasking(k.kontakt)}</span>
                </>
              ))}
            {!d.kontaktnyUdaj.some((u) => u.kodTypKontaktUdaj === 'E') && '-'}
          </div>
        ),
        col4: (
          <div className={classes.iconCheckTable}>
            {d.plnaMoc ? (
              <IconCheck
                color={colors.success[500]}
                id={`splnomocnenie-${d.idOsoby}`}
              />
            ) : (
              <IconCross id={`ambulance-icon-cross--${d.idOsoby}`} />
            )}
          </div>
        ),
      }))}
    />
  );
  return (
    <StaticMenuLayout backgroundWhite hideEPNotifications withoutPadding>
      <PageSection className={classes.section}>
        <Container>
          <BackButton route={routes.profil} text="Späť" />
          <h3 className={cx(classes.title, 'mb-small')}>
            <IconUserBlue id="kontaktne-osoby-title" /> Kontaktné osoby
          </h3>
          {renderDescription}
          {isLoading && (
            <>
              <div className="mb" />
              <Loader size={24} />
            </>
          )}
          {error && <NoData dontOpenMenu illustration="woman" text={error} />}
          {data && !data.length && !isLoading && (
            <NoData
              dontOpenMenu
              illustration="woman-question"
              text="Neevidujeme žiadnu kontaktnú osobu"
            />
          )}
          {data && data.length > 0 && renderTable}
        </Container>
      </PageSection>
    </StaticMenuLayout>
  );
};

export default KontaktneOsoby;
