import { ButtonLink, Icon, colors } from '@dovera/design-system';
import useStyles from '../Spravy.styles';
import { useCallback } from 'react';
import { getCMSText } from '../../../utils/cms.utils';
import { cmsPath } from '../../../constants/cmsPath';
import { cx } from '../../../utils/exports';

interface Props {
  url: string;
}

const RequiredAttachment = ({ url }: Props) => {
  const classes = useStyles({});
  const onClick = useCallback(() => {
    window.location.href = url;
  }, [url]);
  return (
    <div className={cx(classes.requiredAttachment, 'mb')}>
      {getCMSText(
        cmsPath.messages.requiredAttachment,
        'Prílohu si stiahnite a po vyplnení nahrajte.',
        false,
        false,
        'mb-xsmall',
      )}
      <ButtonLink className="inline-btn" onClick={onClick}>
        <Icon color={colors.secondary[600]} name="download" size="medium" />
        Stiahnuť
      </ButtonLink>
    </div>
  );
};

export default RequiredAttachment;
