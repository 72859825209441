import { createUseStyles } from 'react-jss';

export default createUseStyles({
  modal: {
    width: 580,
    '& p': {
      marginTop: 15,
      float: 'left',
    },
  },
  timeContainer: {
    width: 150,
    marginRight: 0,
    textAlign: 'right',
    float: 'right',
  },
});
