import { useContext, useEffect } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import { MultiPeriods } from '../../PeriodPicker';
import { useQuery } from '../../../hooks/useQuery';
import { FILTER_PREFIX } from '../constants';
import { MultiPeriodsType } from '../types';

const MultiPeriodsFilter = ({ ...props }: MultiPeriodsType) => {
  const { id, onChoose, values, ...restProps } = props;

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
    if (queryValue && queryValue !== values?.toString()) {
      handleChoose(queryValue?.split('-').map(Number), true);
    }
    // eslint-disable-next-line
  }, []);

  const handleChoose = (values, isInitialState) => {
    onChoose(values, isInitialState);
    searchParams.set(`${FILTER_PREFIX}_${id}`, values.join('-'));
    setSearchParams(searchParams);
  };

  return (
    <MultiPeriods
      id={id}
      onChoose={(values) => handleChoose(values, false)}
      values={values}
      {...restProps}
    />
  );
};

export default MultiPeriodsFilter;
