import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface TableState {
  colOrder: {
    key: string;
    order: 'asc' | 'desc';
    type?: 'alphabetical' | 'alphabetical-desc' | 'date' | string;
  };
}

const initialState: TableState = {
  colOrder: {
    key: '',
    order: 'asc',
    type: undefined,
  },
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setTableOrder(
      state,
      action: PayloadAction<{
        key: string;
        order: 'asc' | 'desc';
        type?: 'alphabetical' | 'alphabetical-desc' | 'date' | string;
      }>,
    ) {
      return {
        colOrder: {
          key: action.payload.key,
          order: action.payload.order,
          type: action.payload.type,
        },
      };
    },
    resetTableOrder() {
      return initialState;
    },
  },
});

export const { resetTableOrder, setTableOrder } = tableSlice.actions;
export default tableSlice.reducer;
