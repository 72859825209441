import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Choice } from 'choices.js';
import {
  CheckProposalDataPayload,
  CheckProposalDataResponse,
  GetApprovedProposalsResponse,
  GetDiagnosesListPayload,
  GetFormDataPayload,
  GetFormDataResponse,
  GetIndicatorGroupsPayload,
  GetListOfAvailableDoctorsPayload,
  GetListOfAvailableDoctorsResponse,
  GetProposalDetailDocumentsPayload,
  GetProposalDetailDocumentsResponse,
  GetProposalDetailPayload,
  GetProposalDetailResponse,
  GetSubmittedProposalsResponse,
  ProposalDiagnose,
  ProposalIndicatorGroup,
  SaveProposalPayload,
  SaveProposalResponse,
  SaveReservationCodePayload,
  SaveReservationCodeResponse,
  SaveReservationPayload,
  SaveReservationResponse,
} from '../types/spaProposals.types';
import store from '../store';

const API_CONTROLLER = `KupelneNavrhy`;

const API_URL = `${window.env?.config?.apiPath}/${API_CONTROLLER}/`;

export const getDiagnosesList = async (
  payload: GetDiagnosesListPayload,
): Promise<Choice[]> => {
  const url = `${API_URL}DajZoznamDiagnoz`;
  const { data } = await axios.post(url, payload);
  const response = data?.diagnozy?.map((diag: ProposalDiagnose) => ({
    label: `${diag.kod} - ${diag.nazov}`,
    value: diag.kod,
  }));
  return response || [];
};

export const getAnotherDiagnosesList = async (payload: {
  hladanaDiagnoza: string;
}): Promise<{ vedlajsieDiagnozy: ProposalDiagnose[] }> => {
  const url = `${API_URL}DajZoznamVedlajsichDiagnoz`;
  const { data } = await axios.post(url, payload);
  return data;
};

export const getIndicatorGroups = async (
  payload: GetIndicatorGroupsPayload,
): Promise<Choice[]> => {
  const url = `${API_URL}DajZoznamIndikacnychSkupin`;
  const { data } = await axios.post(url, payload);
  const response = data?.indikacneSkupiny?.map(
    (is: ProposalIndicatorGroup) => ({
      label: `${is.kod} - ${is.nazov}`,
      value: is.kod,
    }),
  );
  return response || [];
};

export const saveReservationCode = async (
  payload: SaveReservationCodePayload,
): Promise<SaveReservationCodeResponse> => {
  const url = `${API_URL}UlozRezervacnyKodNavrhu`;
  const { data } = await axios.post(url, payload);
  return data;
};

export const saveReservationDate = async (
  payload: SaveReservationPayload,
): Promise<SaveReservationResponse> => {
  const url = `${API_URL}UlozRezervaciuNavrhu`;
  const { data } = await axios.post(url, payload);
  return data;
};

export const getListOfAvailableDoctors = createAsyncThunk(
  'spaProposals/getListOfAvailableDoctors',
  async (req: GetListOfAvailableDoctorsPayload) => {
    const url = `${API_URL}DajZoznamDostupnychLekarov`;
    const { data }: AxiosResponse<GetListOfAvailableDoctorsResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const checkProposalData = createAsyncThunk(
  'spaProposals/checkProposalData',
  async (payload: CheckProposalDataPayload) => {
    // @ts-ignore
    const checkProposalPayload: CheckProposalDataPayload =
      store.getState()?.spaProposals?.new.data.controls.payload || {};
    const url = `${API_URL}SkontrolujUdajeKupelnehoNavrhu`;
    const req = {
      ...payload,
      ...checkProposalPayload,
      kontrolaTypy: payload.kontrolaTypy,
    };
    const { data }: AxiosResponse<CheckProposalDataResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const getFormData = createAsyncThunk(
  'spaProposals/getFormData',
  async (req: GetFormDataPayload) => {
    const url = `${API_URL}DajUdajePreFormularNavrhu`;
    const { data }: AxiosResponse<GetFormDataResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const saveProposal = createAsyncThunk(
  'spaProposals/saveProposal',
  async (req: SaveProposalPayload) => {
    const url = `${API_URL}UlozNavrh`;
    const { data }: AxiosResponse<SaveProposalResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const getSubmittedProposals = createAsyncThunk(
  'spaProposals/getSubmittedProposals',
  async () => {
    const url = `${API_URL}DajZoznamPodanychNavrhov`;
    const { data }: AxiosResponse<GetSubmittedProposalsResponse> =
      await axios.post(url);
    return data;
  },
);

export const getApprovedProposals = createAsyncThunk(
  'spaProposals/getApprovedProposals',
  async () => {
    const url = `${API_URL}DajZoznamSchvalenychNavrhov`;
    const { data }: AxiosResponse<GetApprovedProposalsResponse> =
      await axios.post(url);
    return data;
  },
);

export const getProposalDetail = createAsyncThunk(
  'spaProposals/getProposalDetail',
  async (req: GetProposalDetailPayload) => {
    const url = `${API_URL}DajDetailNavrhu`;
    const { data }: AxiosResponse<GetProposalDetailResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const getProposalDetailDocuments = createAsyncThunk(
  'spaProposals/getProposalDetailDocuments',
  async (req: GetProposalDetailDocumentsPayload) => {
    const url = `${API_URL}DajDokumentyDetailNavrhu`;
    const { data }: AxiosResponse<GetProposalDetailDocumentsResponse> =
      await axios.post(url, req);
    return data;
  },
);
