import { useCallback, useEffect, useRef } from 'react';
import { IconSpritePathProvider, Modal } from '@dovera/design-system';
import { useIdleTimer } from 'react-idle-timer';
import { showLogoutWarning } from './slices/userActivity.slice';
import AppRouter from './routers/AppRouter';
import { AuthProvider } from './providers/AuthProvider';
import { useSelector } from 'react-redux';
import Pek from './components/PEK/PEK';
import { storeIP } from './slices/app.slice';
import { getIP } from './utils/app.utils';
import { RootState } from './rootReducer';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import AutoLogoutModal from './components/AutoLogoutModal/AutoLogoutModal';
import { toggleFeedbackForm } from './slices/feedback.slices';
import { useAppDispatch } from './hooks/useStore';
import useInterval from './hooks/useInterval';
import { dajPocetNeprecitanychVlakien } from './api/schrankaSprav';
import moment from 'moment';
import 'moment/locale/sk';
import { menuItems } from './constants/menuItems';
import FloatingButton from './components/FloatingButton/FloatingButton';
import FloatingButtonMenu from './components/FloatingButton/FloatingButtonMenu';
import IconQuestionMark from './components/CustomIcons/IconQuestionMark';
import {
  openPomocnik,
  openPomocnikMenu,
  openPomocnikMenuItem,
  resetPomocnikState,
} from './slices/pomocnik.slice';

// @ts-ignore
// eslint-disable-next-line
import CobrowseIO from 'cobrowse-sdk-js';
import FeedbackButton from './components/FloatingButton/FeedbackButton';
import { getToken } from './utils/auth.utils';
import { ModalProvider } from './providers/ModalProvider';

CobrowseIO.license = window.env.COBROWSE_ID;
CobrowseIO.api = window.env.COBROWSE_API;
CobrowseIO.capabilities = ['drawing', 'select', 'laser', 'scroll'];

CobrowseIO.client().then(() => {
  CobrowseIO.trustedOrigins = window.env.COBROWSE_TRUSTED_ORIGINS || [];
  CobrowseIO.start();
});

moment.locale('sk');

/**
 * Typescript definicia pre pouzitie global window premennej
 * @date 22. 2. 2023 - 12:18:03
 */
declare global {
  // eslint-disable-next-line
  interface Window {
    env: any;
  }
}

// User inactivity threshold - Default set to 45 mins
const INACTIVITY_TIME_MS = 45 * 1000 * 60;

const App = () => {
  const dispatch = useAppDispatch();
  const pekRef = useRef<Modal['ref']>(null);
  const ip = useSelector((state: RootState) => state.app.ip);
  const { zobrazPEK } = useSelector((state: RootState) => state.poskytovatel);
  const { pouzivatelId } = useSelector((state: RootState) => state.auth);
  const openHelper = useSelector((state: RootState) => state.pomocnik.open);
  const { open, trigger } = useSelector((state: RootState) => state.feedback);
  const [openedMenuItem] = useSelector(
    (state: RootState) => state.pomocnik.openedItem,
  );
  const handleOnIdle = useCallback(() => {
    if (getToken()) dispatch(showLogoutWarning());
  }, [dispatch]);

  useIdleTimer({
    timeout: INACTIVITY_TIME_MS,
    onIdle: handleOnIdle,
    debounce: 1000,
    crossTab: true,
  });

  useInterval(() => {
    /* Get count of not read messages - every 60 seconds */
    if (pouzivatelId) dispatch(dajPocetNeprecitanychVlakien());
  }, 60000);
  // Init GA
  useEffect(() => {
    if (!ip)
      getIP()
        .then((data) => dispatch(storeIP(typeof data === 'string' ? data : '')))
        .catch(() => dispatch(storeIP('')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (pouzivatelId) {
      dispatch(dajPocetNeprecitanychVlakien());
      // eslint-disable-next-line
      // Get requested header data and client IP
      // getRequestHeaderData().then((data) => console.log(data));
    }
  }, [dispatch, pouzivatelId]);
  useEffect(() => {
    if (trigger.show) {
      setTimeout(() => {
        dispatch(toggleFeedbackForm({}));
      }, trigger.delay * 1000);
    }
  }, [dispatch, trigger]);
  return (
    <IconSpritePathProvider
      value={{
        spritePath: `${window?.env?.config?.baseURI}/sprite.svg`,
      }}
    >
      <AuthProvider>
        <ModalProvider>
          <AutoLogoutModal />
          {zobrazPEK ? (
            <Pek modalRef={pekRef} show />
          ) : (
            <ErrorBoundary>
              <AppRouter />
              {!!pouzivatelId && (
                <>
                  <FloatingButton
                    contentBody={
                      openedMenuItem && menuItems[openedMenuItem].component
                    }
                    floatingButtonIcon={<IconQuestionMark />}
                    floatingButtonTitle="Pomocník"
                    menu={
                      <FloatingButtonMenu
                        menuItems={Object.values(menuItems)}
                        onMenuItemClick={(menuItem) => {
                          if (menuItem.onClick) {
                            menuItem.onClick();
                          } else {
                            dispatch(openPomocnikMenuItem(menuItem.title));
                          }
                        }}
                      />
                    }
                    onPomocnikClick={() => {
                      dispatch(openPomocnik());
                      dispatch(openPomocnikMenu());
                      if (openHelper) {
                        dispatch(resetPomocnikState());
                      }
                    }}
                  />
                  <FeedbackButton
                    onClick={() => dispatch(toggleFeedbackForm({}))}
                    open={open}
                  />
                </>
              )}
            </ErrorBoundary>
          )}
        </ModalProvider>
      </AuthProvider>
    </IconSpritePathProvider>
  );
};

export default App;
