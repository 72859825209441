import { useCallback, useEffect, useState } from 'react';
import useStyles from '../Proposals.styles';
import strings from '../../../constants/strings';
import { Button, Modal, Notification } from '@dovera/design-system';
import DDSDatepicker from '../../../components/DDSDatepicker/DDSDatepicker';
import { validation } from '../../../constants/validation';
import { VALIDATION } from '../../../types/validation.types';
import {
  ApprovedProposals,
  SaveReservationPayload,
} from '../../../types/spaProposals.types';
import { DATE_INTERNATIONAL, getMoment } from '../../../utils/date.utils';
import { saveReservationDate } from '../../../api/spaProposals';
import { ErrorResponse } from '../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { scrollPositionAfterModalVisible } from '../../../utils/app.utils';
import { getPZS } from '../../../slices/auth.slice';

interface Props {
  id: number;
  isVisible: boolean;
  onHide: (refreshData: boolean, toastMessage: string) => void;
  pin?: string | null;
  reservation?: {
    from: string | null;
    id: number | null;
    to: string | null;
  };
  type?: 'create' | 'update' | 'delete' | null;
}

const texts = strings.proposals.modals.reservationDate;
const toastMessages = strings.proposals.modals.toasts;

const validateForm = (values: { end: Date | null; start: Date | null }) => {
  const errors: any = {};
  if (!values.start) errors.start = validation[VALIDATION.DateEmptyDefault];
  if (!values.end) errors.end = validation[VALIDATION.DateEmptyDefault];
  return errors;
};

const ReservationDateModal = ({
  id,
  isVisible,
  onHide,
  pin,
  reservation,
  type,
}: Props) => {
  const elIdStart = `proposal-reservation-${id}--start`;
  const elIdEnd = `proposal-reservation-${id}--end`;
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | null>(
    reservation?.from ? new Date(reservation.from) : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    reservation?.to ? new Date(reservation.to) : null,
  );
  const [errors, setErrors] = useState({ end: '', start: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [errorNtf, setErrorNtf] = useState('');
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const idHz = useSelector((state: RootState) => state.poskytovatel.idHz);
  const proposalData = useSelector(
    (state: RootState) => state.spaProposals.list.approvedProposals.data,
  );
  const proposal: ApprovedProposals = proposalData.filter(
    (p) => p.id === id,
  )[0];
  const reservationId = reservation?.id || proposal?.rezervacia?.id || null;
  useEffect(() => {
    if (isVisible && reservation) {
      setStartDate(reservation.from ? new Date(reservation.from) : null);
      setEndDate(reservation.to ? new Date(reservation.to) : null);
    } else if (isVisible && !reservation) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [isVisible, reservation]);
  const changeDate = useCallback(
    (type: 'start' | 'end', otherId: string, value: Date) => {
      if (type === 'end') {
        setErrors({ ...errors, end: '' });
        setEndDate(value);
      } else {
        setErrors({ ...errors, start: '' });
        setStartDate(value);
      }
      if ((!startDate && type === 'end') || (!endDate && type === 'start')) {
        // @ts-ignore
        document.querySelector(`#${otherId}`).value = '';
      }
    },
    [endDate, errors, startDate],
  );
  const onSubmit = useCallback(() => {
    const errors = validateForm({ end: endDate, start: startDate });
    setErrors(errors);
    if (!errors.start && !errors.end) {
      setIsLoading(true);
      const payload: SaveReservationPayload = {
        idNavrhNaZS: id,
        pin,
        rezervacia: {
          datumDo: getMoment(endDate).format(DATE_INTERNATIONAL),
          datumOd: getMoment(startDate).format(DATE_INTERNATIONAL),
          id: reservationId,
          idhz: idHz,
          idvz: pzs.vzId || 0,
        },
        ulozRezervaciuTyp:
          type === 'update'
            ? 'Upravit'
            : type === 'delete'
              ? 'Vymazat'
              : 'Vytvorit',
      };
      saveReservationDate(payload)
        .then((data) => {
          if (data.vysledok)
            onHide(
              false,
              type === 'update'
                ? toastMessages.reservationDateUpdated
                : type === 'delete'
                  ? toastMessages.reservationDateDeleted
                  : toastMessages.reservationDate,
            );
          if (!data.vysledok) setErrorNtf(strings.defaultError);
        })
        .catch((err: { response: { data: ErrorResponse } }) => {
          setErrorNtf(err.response.data.text);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    endDate,
    id,
    idHz,
    onHide,
    pin,
    reservationId,
    startDate,
    type,
    pzs.vzId,
  ]);
  const renderStart = (
    <DDSDatepicker
      error={errors.start}
      id={elIdStart}
      isDisabled={type === 'delete'}
      label={texts.labels.start}
      maxDate={
        endDate
          ? getMoment(endDate).subtract(1, 'day').toDate()
          : getMoment().add(2, 'years').toDate()
      }
      minDate={new Date()}
      onChange={(value) => changeDate('start', elIdEnd, value)}
      value={startDate}
    />
  );
  const renderEnd = (
    <div className="text-space-left">
      <DDSDatepicker
        error={errors.end}
        id={elIdEnd}
        isDisabled={type === 'delete'}
        label={texts.labels.end}
        maxDate={getMoment().add(2, 'years').toDate()}
        minDate={
          startDate ? getMoment(startDate).add(1, 'day').toDate() : new Date()
        }
        onChange={(value) => changeDate('end', elIdStart, value)}
        value={
          endDate ||
          (startDate ? getMoment(startDate).add(1, 'day').toDate() : undefined)
        }
      />
    </div>
  );
  const renderButton = (
    <Button
      className="mb-xxsmall"
      isDisabled={isLoading}
      isLoading={isLoading}
      onClick={onSubmit}
      type={type === 'delete' ? 'destructive' : undefined}
    >
      {type === 'update'
        ? texts.buttonUpdate
        : type === 'delete'
          ? texts.buttonDelete
          : texts.button}
    </Button>
  );
  const renderNotification = errorNtf && (
    <Notification message={errorNtf} variant="error" />
  );
  return (
    <Modal
      className={classes.modal}
      closeOnOverlayClick={false}
      data-modal-initial-focus
      header={
        type === 'update'
          ? texts.titleUpdate
          : type === 'delete'
            ? texts.titleDelete
            : texts.title
      }
      id="spa-proposals--reservation-modal"
      isVisible={isVisible}
      onHide={() => {
        setErrorNtf('');
        setErrors({ end: '', start: '' });
        setStartDate(new Date());
        setEndDate(getMoment().add(1, 'day').toDate());
        onHide(false, '');
        scrollPositionAfterModalVisible();
      }}
    >
      <div className="d-flex">
        {renderStart}
        {renderEnd}
      </div>
      {renderNotification}
      {renderButton}
    </Modal>
  );
};

export default ReservationDateModal;
