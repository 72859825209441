import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import useStyles from './MultiSelectAutocomplete.styles';
import {
  Button,
  ButtonLayout,
  ButtonSecondary,
  Checkbox,
  CheckboxGroup,
  Input,
  Loader,
  Tag,
} from '@dovera/design-system';
import IconSearch from '../CustomIcons/IconSearch';
import { useDebounce } from '../../hooks/useDebounce';
import useOutsideClick from '../../hooks/useOutsideClick';
import { Choice } from 'choices.js';

type Props = {
  isLoading: boolean;
  maxChoose?: number;
  noResults?: string;
  onChangeDebounced: (value: string) => void;
  onSelected: (values: Choice[]) => void;
  onSubmit?: (values: Choice[]) => void;
  options: Choice[];
  reset?: boolean;
} & Omit<Input, 'value'>;

const MultiSelectAutocomplete = ({
  help,
  id,
  isLoading,
  label,
  maxChoose,
  noResults,
  onChangeDebounced,
  onSelected,
  onSubmit,
  options,
  reset,
  ...other
}: Props) => {
  const inputRef = createRef<HTMLInputElement>();
  const wrapperRef = createRef<HTMLDivElement>();
  const selectedContainerRef = useRef(null);
  const [visibleOptions, setVisibleOptions] = useState(false);
  const [optionsTop, setOptionsTop] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchValueDebounced = useDebounce(searchValue, 500);
  const [selected, setSelected] = useState<
    { label: string; value: string }[] | null
  >(null);
  const classes = useStyles({ optionsTop });
  const onConfirm = useCallback(() => {
    if (onSubmit && selected) onSubmit(selected);
    setVisibleOptions(false);
  }, [onSubmit, selected]);
  const showOptions = useCallback(() => {
    const pageSize = window.innerHeight - 71;
    const elementPosition = wrapperRef.current?.getBoundingClientRect()?.y || 0;
    if (pageSize && !visibleOptions && pageSize - elementPosition < 280) {
      setOptionsTop(true);
    } else {
      setOptionsTop(false);
    }
    setVisibleOptions(true);
  }, [visibleOptions, wrapperRef]);
  useOutsideClick(selectedContainerRef, (e) => {
    const element = e.target as HTMLElement;
    if (!element.classList.toString().includes('radiocheck') && visibleOptions)
      setVisibleOptions(false);
  });
  useEffect(() => {
    if (selected) onSelected(selected);
    // eslint-disable-next-line
  }, [selected]);
  useEffect(() => {
    if (reset) {
      setSelected(null);
      setSearchValue('');
    }
  }, [reset]);
  useEffect(() => {
    if (searchValueDebounced) {
      onChangeDebounced(searchValueDebounced);
      showOptions();
    }
    // eslint-disable-next-line
  }, [searchValueDebounced]);

  const renderButtons = (
    <ButtonLayout className={classes.buttons} direction="horizontal">
      <Button isDisabled={!selected?.length} onClick={onConfirm}>
        Potvrdiť
      </Button>
      <ButtonSecondary onClick={() => setVisibleOptions(false)}>
        Zrušiť
      </ButtonSecondary>
    </ButtonLayout>
  );

  const renderOptions = visibleOptions &&
    !isLoading &&
    (!maxChoose || (selected && selected.length < maxChoose) || !selected) && (
      <div className={classes.optionsWrapper}>
        <div className={classes.options}>
          {options.length > 0 && !isLoading ? (
            <CheckboxGroup>
              {options.map((o, key) => (
                <Checkbox
                  key={`${id}-option--${o.value}--${o?.id}--${o.label}`}
                  data-checked={selected?.map((s) => s.value).includes(o.value)}
                  id={`${id}-option--${o.value}--${key}`}
                  isChecked={selected?.map((s) => s.value).includes(o.value)}
                  onChange={() =>
                    setSelected(
                      selected?.map((s) => s.value).includes(o.value)
                        ? selected?.filter((s) => s.value !== o.value)
                        : [
                            ...(selected || []),
                            {
                              label: o.label,
                              value: o.value,
                            },
                          ],
                    )
                  }
                  value={o.value}
                >
                  {o.label}
                </Checkbox>
              ))}
            </CheckboxGroup>
          ) : (
            !isLoading && noResults
          )}
        </div>
        {renderButtons}
      </div>
    );
  const renderSelected = (
    <div ref={selectedContainerRef} className={classes.selectedContainer}>
      {selected?.map((s) => (
        <Tag
          key={`${id}--${s.value}--${s.label}`}
          onClose={() =>
            setSelected(selected.filter((o) => o.value !== s.value))
          }
        >
          {s.label}
        </Tag>
      ))}
    </div>
  );
  return (
    <div ref={wrapperRef} className={classes.multiAutocomplete}>
      <Input
        {...other}
        // @ts-ignore
        ref={inputRef}
        addonsInside
        aria-autocomplete="none"
        autoComplete="off"
        crossOrigin
        help={help}
        id={id}
        isDisabled={!!(maxChoose && maxChoose === selected?.length)}
        label={label}
        onClick={() => {
          if (options.length > 0) showOptions();
        }}
        // eslint-disable-next-line
        onChange={(e) => setSearchValue(e.target.value)}
        rightAddons={
          isLoading ? (
            <Loader size={24} />
          ) : (
            <button
              className="clickable d-flex"
              onClick={() => onChangeDebounced(searchValue)}
              type="button"
            >
              <IconSearch id={`icon-search-multiselect--${id}`} />
            </button>
          )
        }
        value={searchValue}
      />
      {renderOptions}
      {renderSelected}
    </div>
  );
};

export default MultiSelectAutocomplete;
