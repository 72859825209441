import useStyles from '../../Proposals.styles';
import { Button, ButtonLink, Icon, color } from '@dovera/design-system';
import { formatDate } from '../../../../utils/date.utils';
import { navrhyRoutes } from '../../../../routes';
import { getProposalState } from '../../../../utils/spaProposals.utils';
import {
  formatNameStr,
  hotjarMasking,
  stringWithSpaces,
} from '../../../../utils/strings.utils';
import { ProposalDetailQueries } from '../../../../types/spaProposals.types';
import { useNavigate } from 'react-router';
import { createViewUrl } from '../../../../utils/app.utils';
import IconBlueUsers from '../../../../components/CustomIcons/IconBlueUsers';
import IconStethoscope from '../../../../components/CustomIcons/IconStethoscope';
import SortableTable from '../../../../components/SortableTable/SortableTable';

interface Props {
  data: {
    doctor: {
      code: string;
      firstName: string;
      lastName: string;
      titleAfter: string | null;
      titleBefore: string | null;
    };
    id: number;
    insuree: {
      firstName: string;
      insureeId: string;
      lastName: string;
    };
    proposalNumber: string;
    /**
     * N = Neschvaleny
     * D = Na doplnenie
     * E = Spracovava sa / Evidovany
     * S = Schvaleny
     */
    state: 'N' | 'D' | 'E' | string;
    validity: {
      from: string;
      to: string;
    };
  }[];
}

const TableList = ({ data }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <SortableTable
      cols={[
        {
          index: 1,
          name: 'Číslo návrhu',
          isSortable: true,
          sortType: 'alphabetical-desc',
        },
        {
          index: 2,
          name: (
            <span className="d-flex align-center">
              <IconBlueUsers height={16} id={`icon-pois-label`} width={16} />
              <span className="text-space-half-left">Pacient</span>
            </span>
          ),
          isSortable: true,
          sortType: 'alphabetical',
        },
        {
          index: 3,
          name: (
            <span className="d-flex align-center">
              <IconStethoscope id={`icon-diagnose-label`} isBold />
              <span className="text-space-half-left">Kód/meno lekára</span>
            </span>
          ),
          isSortable: true,
          sortType: 'alphabetical',
        },
        {
          index: 4,
          name: 'Platnosť návrhu',
          isSortable: true,
          sortType: 'date',
        },
        {
          index: 5,
          name: 'Stav návrhu',
          isSortable: true,
          sortType: 'alphabetical',
        },
        {
          index: 6,
          name: '',
        },
      ]}
      rows={data.map((d) => {
        const queries: ProposalDetailQueries = {
          cisloNavrhu: d.proposalNumber,
          id: d.id,
        };
        return {
          col1: {
            sortableValue: d.proposalNumber,
            value: (
              <Button
                className="text-normal no-mrg no-pad"
                onClick={() =>
                  navigate(
                    createViewUrl(navrhyRoutes.zoznamPodanychNavrhov, queries),
                  )
                }
                type="link-secondary"
              >
                {d.proposalNumber}
              </Button>
            ),
          },
          col2: {
            sortableValue: d.insuree.lastName,
            value: (
              <span data-hj-masked>
                {d.insuree.insureeId}
                {hotjarMasking(
                  formatNameStr(
                    stringWithSpaces([d.insuree.firstName, d.insuree.lastName]),
                  ),
                  'b',
                  'd-block',
                )}
              </span>
            ),
          },
          col3: {
            sortableValue: d.doctor.lastName,
            value: (
              <span data-hj-masked>
                {d.doctor.code}
                {hotjarMasking(
                  formatNameStr(
                    stringWithSpaces([
                      d.doctor.titleBefore || '',
                      d.doctor.firstName,
                      `${d.doctor.lastName}${d.doctor.titleAfter ? ',' : ''}`,
                      d.doctor.titleAfter || '',
                    ]),
                  ),
                  'b',
                  'd-block',
                )}
              </span>
            ),
          },
          col4: {
            sortableValue: d.validity.from,
            value: (
              <span>
                {formatDate(d.validity.from)}
                <br />
                {formatDate(d.validity.to)}
              </span>
            ),
          },
          col5: {
            sortableValue: d.state,
            value: getProposalState(d.state, classes),
          },
          col6: {
            value: (
              <ButtonLink
                className="no-mrg no-pad"
                onClick={() =>
                  navigate(
                    createViewUrl(navrhyRoutes.zoznamPodanychNavrhov, queries),
                  )
                }
              >
                Detail
                <Icon
                  className="text-space-half-left"
                  color={color('primary', 600)}
                  name="16-chevron-right"
                />
              </ButtonLink>
            ),
          },
        };
      })}
    />
  );
};

export default TableList;
