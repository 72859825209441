import { color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  modalNarrower: {
    display: 'flex',
    flexDirection: 'column',
    width: getRem(342),
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h4': {
      color: color('warning', 500),
    },
    '& img': {
      width: getRem(264),
      height: 'auto',
      maxHeight: getRem(264),
      marginBottom: getRem(24),
    },
  },
  btnLayout: {
    justifyContent: 'center',
  },
  success: {
    '& h4': {
      color: color('primary', 500),
    },
  },
  warning: {
    '& h4': {
      color: color('warning', 500),
    },
  },
  infoWrapper: {
    width: getRem(230),
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
  },
  sum: {
    display: 'inline',
    color: color('black'),
    marginBottom: 0,
    lineHeight: 1.3,
  },
});
