import { ReactNode } from 'react';
import { color } from '@dovera/design-system';
import IconRejected from '../components/CustomIcons/IconRejected';
import IconCheck from '../components/CustomIcons/IconCheck';
import IconHourglasses from '../components/CustomIcons/IconHourglasses';
import IconEdit from '../components/CustomIcons/IconEdit';
import { STAV_DAVKY } from '../types/zuctovanie.types';
import { STAV_DAVKY_CAKACKY } from '../types/cakacky.types';
import IconWarningOutlined from '../components/CustomIcons/IconWarningOutlined';
import IconWarning from '../components/CustomIcons/IconWarning';

export const MAX_RANGE_YEARS = 1;

export const FILTER_MIN_DATE = '2000-01-01';

export const BATCH_STATES: {
  icon: ReactNode;
  label: string | ReactNode;
  value: STAV_DAVKY;
}[] = [
  {
    icon: <IconCheck color={color('success')} />,
    label: 'Správna dávka',
    value: STAV_DAVKY.Spravna,
  },
  {
    icon: <IconRejected />,
    label: 'Chybná dávka',
    value: STAV_DAVKY.Chybna,
  },
  {
    icon: <IconHourglasses />,
    label: 'Prebieha overenie',
    value: STAV_DAVKY.PrebiehaKontrola,
  },
  {
    icon: <IconCheck color={color('success')} />,
    label: 'Nahraná dávka',
    value: STAV_DAVKY.Nahrana,
  },
];

export const BATCH_STATES_CAKACKY: {
  icon: ReactNode;
  label: string | ReactNode;
  value: STAV_DAVKY | STAV_DAVKY_CAKACKY;
}[] = [
  ...BATCH_STATES,
  ...[
    {
      icon: <IconCheck color={color('success')} />,
      label: 'Vybavená',
      value: STAV_DAVKY_CAKACKY.Vybavena,
    },
    {
      icon: <IconHourglasses />,
      label: 'Spracováva sa',
      value: STAV_DAVKY_CAKACKY.Spracovanie,
    },
    {
      icon: (
        <IconWarningOutlined color={color('error')} id="CakackyNaStiahnutie" />
      ),
      label: <span className="text-color-error">Na stiahnutie</span>,
      value: STAV_DAVKY_CAKACKY.NaStiahnutie,
    },
  ],
];

export const INVOICE_TYPES = ['SK', 'EU'];

export const INVOICE_STATES: {
  icon: ReactNode;
  label: string;
  value: string;
}[] = [
  {
    icon: <IconEdit color={color('warning')} />,
    label: 'Rozpracovaná',
    value: 'R',
  },
  {
    icon: <IconWarning />,
    label: 'Čaká na potvrdenie',
    value: 'C',
  },
  {
    icon: <IconCheck color={color('success')} />,
    label: 'Zaúčtovaná',
    value: 'Z',
  },
  {
    icon: <IconHourglasses />,
    label: 'Prebieha zaúčtovanie',
    value: 'P',
  },
];

export const DEFAULT_FILTER_STATES: { label: string; value: string }[] = [
  {
    label: 'Áno',
    value: 'Y',
  },
  {
    label: 'Nie',
    value: 'N',
  },
];
