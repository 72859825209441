interface Props {
  color?: string;
  height?: number;
  id: string;
  width?: number;
}

const IconPDF = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 24}
    id={id}
    viewBox={`0 0 ${width || 24} ${height || 24}`}
    width={width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4.875 0C4.09091 0 3.35309 0.332833 2.81964 0.901845C2.28824 1.46867 2 2.22443 2 3V21C2 21.7756 2.28824 22.5313 2.81964 23.0982C3.35309 23.6672 4.09091 24 4.875 24H19.125C19.9091 24 20.6469 23.6672 21.1804 23.0982C21.7118 22.5313 22 21.7756 22 21V7C22 6.73474 21.8967 6.4936 21.7282 6.3146L16.1045 0.316059C15.9155 0.114408 15.6514 0 15.375 0H15H4.875ZM14 2H4.875C4.66452 2 4.44852 2.08859 4.27871 2.26973C4.10685 2.45305 4 2.71471 4 3V21C4 21.2853 4.10685 21.547 4.27871 21.7303C4.44853 21.9114 4.66452 22 4.875 22H19.125C19.3355 22 19.5515 21.9114 19.7213 21.7303C19.8932 21.547 20 21.2853 20 21V8H15C14.4477 8 14 7.55228 14 7V2ZM18.6918 6H16V3.12878L18.6918 6Z"
      fill={color || '#3F7C22'}
      fillRule="evenodd"
    />
    <path
      d="M10.9179 8.00432C11.2468 7.94546 12.597 8.43807 11.6622 12.5948C10.7275 16.7516 9.23872 18.1793 8.68477 18.5769C8.13083 18.9745 7.4557 19.1372 7.12679 18.8661C6.79789 18.595 7.12679 17.6191 7.90578 17.0227C8.68477 16.4263 10.6236 15.7033 12.1643 15.3961C13.4468 15.1404 15.7303 15.2154 16.6132 15.3961C17.496 15.5768 18.1192 16.3178 17.9807 16.8058C17.8166 17.3841 16.8728 17.5649 15.2629 16.4805C13.653 15.3961 10.9656 11.6761 10.5717 10.408C10.1562 9.07062 10.312 8.11276 10.9179 8.00432Z"
      stroke={color || '#3F7C22'}
      strokeWidth="2"
    />
  </svg>
);

export default IconPDF;
