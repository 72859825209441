import { useCallback, useEffect, useState } from 'react';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import useStyles from '../Spravy.styles';
import { Dokument } from '../../../types/models/Dokument';
import MessageHeader from '../MessageHeader/MessageHeader';
import { Attachment, Wrapper } from '../../../components/Attachment';
import { useAppDispatch } from '../../../hooks/useStore';
import { fetchObsahDokumentu } from '../../../slices/dokumenty.slice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { Notification } from '@dovera/design-system';
import strings from '../../../constants/strings';

interface Props {
  attachments?: Dokument[] | null;
  content: string;
  date: string;
  fromDZP: boolean;
  id: number;
}

const Message = ({ attachments, content, date, fromDZP, id }: Props) => {
  const classes = useStyles({});
  const dispatch = useAppDispatch();
  const [error, setError] = useState('');
  const [lastId, setLastId] = useState(0);
  const { dokumenty } = useSelector((state: RootState) => state.dokumenty);
  const getDocument = useCallback(
    (id: number) => {
      setLastId(id);
      setError('');
      if (id)
        return dispatch(
          fetchObsahDokumentu({
            schrankaSprav: {
              id,
            },
          }),
        );
      return setError(strings.schrankaSprav.attachments.error);
    },
    [dispatch],
  );
  useEffect(() => {
    if (lastId && dokumenty && dokumenty[lastId]?.error)
      setError(strings.schrankaSprav.attachments.error);
  }, [dokumenty, lastId]);
  const errorFileDownload = error && (
    <div>
      <div className="mb-small" />
      <Notification message={error} variant="error" />
    </div>
  );
  return (
    <div className={classes.message}>
      <MessageHeader date={date} fromDZP={fromDZP} />
      <SafeHtml html={content} wrapper="div" />
      {!!attachments && (
        <Wrapper>
          {attachments.map((a) => (
            <Attachment
              key={`messsage-${id}--attachment-${a.id}`}
              id={a.id || 0}
              isLoading={dokumenty?.[a.id || '']?.isLoading}
              name={a.nazov || a.nazovDokumentu || ''}
              onClick={() => getDocument(a.id || 0)}
              size={a.velkost || 0}
              type={a.mimeTyp || a.typ || ''}
            />
          ))}
        </Wrapper>
      )}
      {errorFileDownload}
    </div>
  );
};

export default Message;
