import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { dajObsahDokumentuApi } from '../api/dokumenty';
import { AppThunk } from '../store';
import {
  DajObsahDokumentuPayload,
  DokumentyState,
} from '../types/dokumenty.types';
import { Dokument } from '../types/models/Dokument';
import { saveDocument } from '../utils/file.utils';

const initialState: DokumentyState = {
  dokumenty: {},
};

const dokumentySlice = createSlice({
  name: 'dokumenty',
  initialState,
  reducers: {
    dajObsahDokumentuStart(state, action: PayloadAction<string>) {
      return {
        ...state,
        dokumenty: {
          ...state.dokumenty,
          [action.payload]: {
            isLoading: true,
            dokument: null,
            error: null,
          },
        },
      };
    },
    dajObsahDokumentu(
      state,
      action: PayloadAction<{
        data: { chyba: string | null; dokument: Dokument };
        kluc: string;
      }>,
    ) {
      return {
        ...state,
        dokumenty: {
          ...state.dokumenty,
          [action.payload.kluc]: {
            isLoading: false,
            error: action.payload.data.chyba,
            dokument: action.payload.data.dokument,
          },
        },
      };
    },
    dajObsahDokumentuChyba(
      state,
      action: PayloadAction<{ error: string | any; kluc: string }>,
    ) {
      return {
        ...state,
        dokumenty: {
          ...state.dokumenty,
          [action.payload.kluc]: {
            isLoading: false,
            error: action.payload.error,
            dokument: null,
          },
        },
      };
    },
    resetDocuments() {
      return initialState;
    },
  },
});

export const {
  dajObsahDokumentu,
  dajObsahDokumentuChyba,
  dajObsahDokumentuStart,
  resetDocuments,
} = dokumentySlice.actions;

export default dokumentySlice.reducer;

export const fetchObsahDokumentu =
  (
    payload: DajObsahDokumentuPayload,
    openFile?: boolean,
    onlyPreview?: boolean,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(
        dajObsahDokumentuStart(
          payload.kluc ||
            payload.dms?.ciarovyKod ||
            payload.navrh?.overovaciKod ||
            payload.schrankaSprav?.id?.toString() ||
            payload.Zuctovanie?.id?.toString() ||
            '',
        ),
      );
      const data = await dajObsahDokumentuApi(payload);
      if (!onlyPreview) saveDocument(data.dokument, openFile);
      dispatch(
        dajObsahDokumentu({
          kluc:
            payload.kluc ||
            payload.dms?.ciarovyKod ||
            payload.navrh?.overovaciKod ||
            payload.schrankaSprav?.id?.toString() ||
            payload.Zuctovanie?.id?.toString() ||
            '',
          data,
        }),
      );
    } catch (err) {
      dispatch(
        dajObsahDokumentuChyba({
          kluc:
            payload.kluc ||
            payload.dms?.ciarovyKod ||
            payload.navrh?.overovaciKod ||
            payload.schrankaSprav?.id?.toString() ||
            payload.Zuctovanie?.id?.toString() ||
            '',
          error: err?.toString(),
        }),
      );
    }
  };
