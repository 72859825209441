import { createUseStyles } from 'react-jss';
import { breakpoints, getRem } from '@dovera/design-system';

export default createUseStyles({
  staticMenuLayoutWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  staticMenuLayout: {
    minHeight: 'calc(100vh - 123px)',
    width: '100%',
    '&.without-padding': {
      '& .container': {
        padding: 0,
      },
      '& > div': {
        padding: 0,
      },
    },
    '&.white': {
      backgroundColor: '#FFFFFF',
    },
    [`@media screen and (max-width: ${breakpoints.s}px) and (max-height: 700px)`]:
      {
        minHeight: '100vh',
      },
  },
  pageSection: {
    '&.with-menu': {
      marginLeft: 340,
    },
    paddingTop: '2rem',
    paddingBottom: 0,
    height: '100%',
    '& .card': {
      // marginBottom: 0,
    },
    '& .card__section': {
      height: '100%',
      '& h4': {
        margin: 0,
      },
    },
  },
  responsiveSection: {
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      width: '100%',
      marginLeft: '0 !important',
    },
  },
  notifications: {
    height: 'auto !important',
    width: 'auto !important',
  },
  headerDescription: {
    '& button': {
      marginTop: getRem(8),
      '& svg': {
        color: '#FF0000',
        marginRight: `${getRem(8)} !important`,
      },
    },
  },
});
