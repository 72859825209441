import axios from 'axios';
import { DajObsahDokumentuPayload } from '../types/dokumenty.types';

const API_URL = `${window.env?.config?.apiPath}/Dokument/`;

export async function dajObsahDokumentuApi(payload: DajObsahDokumentuPayload) {
  const url = `${API_URL}DajObsahDokumentu`;
  const { data } = await axios.post(url, payload);
  return data;
}
