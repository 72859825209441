// @ts-nocheck
import useStyles from '../Spravy.styles';
import Illustration from '../../../assets/illustrations/error-default.png';
import {
  Button,
  ButtonLayout,
  ButtonSecondary,
  color,
} from '@dovera/design-system';
import strings from '../../../constants/strings';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import { IconMenuDashboard } from '../../../components/CustomIcons';
import { useNavigate } from 'react-router';
import routes from '../../../routes';
// @ts-check

interface Props {
  onClickLoadAgain: () => void;
  single?: boolean;
}

const texts = strings.schrankaSprav.emptyStates.loadError;

const LoadError = ({ onClickLoadAgain, single }: Props) => {
  const classes = useStyles({});
  const navigate = useNavigate();
  return (
    <div className={classes.emptyState}>
      <img alt="Nepodarilo sa načítať" className="mb" src={Illustration} />
      <h4 className="text-color-warning mb-small">{texts.title(single)}</h4>
      <SafeHtml
        className="mb-large"
        html={texts.description(single)}
        wrapper="div"
      />
      <ButtonLayout direction="horizontal">
        {!single && (
          <ButtonSecondary
            className={classes.button}
            onClick={() => navigate(routes.dashboard)}
          >
            <IconMenuDashboard
              color={color('primary', 600)}
              id="home--no-messages"
            />
            {texts.buttons.home}
          </ButtonSecondary>
        )}
        <Button className={classes.button} onClick={onClickLoadAgain}>
          {texts.buttons.loadAgain}
        </Button>
      </ButtonLayout>
    </div>
  );
};

export default LoadError;
