import React from 'react';

const IconMultiplication = ({
  ...other
}: React.JSX.IntrinsicElements['svg']) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      d="M12.9444 11.7193L17.9484 16.7233L17.0124 17.6593L12.0084 12.6553L7.00436 17.6593L6.06836 16.7233L11.0724 11.7193L6.06836 6.7153L7.00436 5.7793L12.0084 10.7833L17.0124 5.7793L17.9484 6.7153L12.9444 11.7193Z"
      fill="#1F252A"
    />
  </svg>
);

export default IconMultiplication;
