import { cx } from '../../utils/exports';
import useStyles from './styles';
import Equals from './symbols/Equals';
import SvgSymbol from './SvgSymbol';
import {
  getFormulaItemStyles,
  getFormulaVariables,
  isInnerBracket,
  splittedFormula,
} from './utils';
import './animation.css';
import { DetailAttributesFormula } from './types';

interface Props {
  attributes: DetailAttributesFormula;
}

const HorizontalFormula = ({ attributes }: Props) => {
  const attrFormula = attributes.formula || '';
  const classes = useStyles({
    variablesCount: getFormulaVariables(attrFormula).length,
  });
  return (
    <div className={cx(classes.formulaWrapper, 'mb-large')}>
      <div className={classes.formulaPartWrapper}>
        <div>
          <h5 className="is-result">{attributes.value}</h5>
          <div className={cx(classes.formulaResult, classes.commonItem)}>
            <small>{attributes.text}</small>
          </div>
        </div>
      </div>
      <div className={classes.formulaPartWrapper}>
        <Equals
          className={
            getFormulaVariables(attrFormula).length < 4 && classes.svgSymbol
          }
        />
      </div>
      <div className={classes.formulaPartWrapper}>
        {splittedFormula(attrFormula)?.map((f, index) => {
          const formulaValue = attributes.formulaValues?.find(
            (v) => v.variable === f,
          );
          return formulaValue ? (
            <div key={`${f}--${index}`}>
              <h5>{formulaValue.value}</h5>
              <div
                className={cx(classes.formulaItem, classes.commonItem)}
                style={getFormulaItemStyles(attrFormula, index)}
              >
                <small>{formulaValue.text}</small>
              </div>
            </div>
          ) : (
            <div key={`${f}--${index}`}>
              <SvgSymbol
                className={!['(', ')'].includes(f) && classes.svgSymbol}
                isInner={isInnerBracket(attrFormula, index, f)}
                symbol={f}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HorizontalFormula;
