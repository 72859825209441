import { createUseStyles } from 'react-jss';

interface StylesProps {
  active?: boolean;
  lastStep?: boolean;
}

export default createUseStyles({
  root: {
    marginLeft: 25,
    paddingLeft: 42,
    paddingTop: 0,
    borderLeft: ({ lastStep }: StylesProps) =>
      lastStep ? 'none' : `2px solid rgba(31, 87, 165, 0.3)`,
    paddingBottom: '1.5rem',
  },
  activeContent: {
    '& > div': {
      overflow: 'visible !important',
      '& > div': {
        overflow: 'visible !important',
      },
    },
  },
});
