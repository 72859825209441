import {
  FocusEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export const useKeyboard = (keyMap: {
  [key: string]: (e?: SyntheticEvent) => boolean | undefined | void;
}) =>
  useCallback(
    (e) => {
      const callback = keyMap?.[e.key] ?? keyMap.default;
      if (callback !== undefined) {
        const preventDefault = !callback(e);
        if (preventDefault) {
          e.preventDefault();
        }
      }
    },
    [keyMap],
  );

export const useFocus = (ref, focusCallback, blurCallback) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    const container = ref.current;

    if (container === null) {
      return () => {};
    }

    const onFocus = () => {
      setState(true);
      focusCallback();
    };
    const onBlur = (e: FocusEvent) => {
      if (container) {
        const relatedTarget = e.relatedTarget || document.activeElement;
        const outside = !container.contains(relatedTarget);
        if (outside) {
          setState(false);
          blurCallback();
        }
      }
    };

    container.addEventListener('focus', onFocus, true);
    container.addEventListener('blur', onBlur, true);

    return () => {
      container.removeEventListener('focus', onFocus, true);
      container.removeEventListener('blur', onBlur, true);
    };
  }, [focusCallback, blurCallback, ref]);

  return state;
};

/*
    When callback passed via props is not stable, store/update it in a ref,
    and lazily call it when necessary. This is not recommended.
  */

export const useLazyCallback = (callback: (...args: any[]) => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return useCallback((...args) => callbackRef.current(...args), []);
};
