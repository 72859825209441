import { createUseStyles } from 'react-jss';
import { colors } from '@dovera/design-system';

interface StylesProps {
  countToSolve: number;
  isOpen: boolean;
}

export default createUseStyles({
  root: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.grey?.['100']}`,
    },
  },
  children: {
    '& .child-row': {
      padding: '20px 32px 24px 80px',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${colors.grey?.['100']}`,
      },
      '&:first-child': {
        paddingTop: 8,
      },
      '&--auth': {
        borderBottom: `1px solid ${colors.grey?.['100']}`,
      },
      '&:last-child': {
        borderBottom: 'none !important',
      },
    },
  },
  row: ({ countToSolve }: StylesProps) => ({
    padding: '20px 32px 16px 32px',
    position: 'relative',
    display: 'flex',
    cursor: countToSolve ? 'pointer' : 'normal',
    transition: 'all 0.2s ease 0s',
    alignItems: 'flex-start',
    marginBottom: 0,
    '& div span': {
      color: '#6D7175',
      fontSize: 14,
    },
    '&:hover': {
      backgroundColor: countToSolve && 'rgb(242, 245, 250)',
    },
    '&.bar--horizontal': {
      marginTop: 0,
    },
  }),
  angle: ({ isOpen }: StylesProps) => ({
    position: 'absolute',
    right: 37,
    transition: 'transform .2s ease-in-out',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  icon: {
    width: 32,
    height: 32,
    marginRight: 16,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  showAll: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 16,
    '& button': {
      minHeight: 'auto',
    },
  },
});
