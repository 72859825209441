import { useState } from 'react';
import { Nullable } from '../../types';
import { Formula, FormulaWrapper, RecursiveFormula } from './common';
import { getNextFormulaSign, hasSigns } from './utils';
import { DetailAttributesFormula } from './types';
import IconBlueEquals from '../CustomIcons/IconBlueEquals';

interface Props {
  attributes: DetailAttributesFormula;
}

const VerticalFormula = ({ attributes }: Props) => {
  const { formula, formulaValues, text, value } = attributes;
  const [activeAccordion, setActiveAccordion] =
    useState<Nullable<string>>(null);

  return (
    <FormulaWrapper>
      {formulaValues?.map((fv) => {
        const nextSign = getNextFormulaSign(formula, fv.variable);
        return (
          <RecursiveFormula
            key={fv.variable}
            activeAccordion={activeAccordion}
            formula={fv.formula}
            formulaValues={fv.formulaValues ? fv.formulaValues : []}
            isColored={false}
            isFirstDepth
            nextSign={nextSign}
            text={fv.text}
            tooltip={fv.tooltip}
            updateActiveAccordion={setActiveAccordion}
            value={fv.value}
            variable={fv.variable}
          />
        );
      })}

      {hasSigns(formula) && (
        <IconBlueEquals id="icon-equals-parameter-formula" />
      )}
      <Formula hodnota={value} isFilled nazov={text} />
    </FormulaWrapper>
  );
};

export default VerticalFormula;
