/* eslint-disable */
import { Statutar } from './models/Statutar';
import { EPSection, Loadable, LoadingState } from '.';
import { Adresa } from './models/Adresa';
import {
  DajDataLekaraResponse,
  OverDostupnostAutentifikacieResponse,
} from './api/poskytovatel';
import { EPSections } from '../constants/systemConstants';

export interface OdbornostHodnoteneho {
  aktivna?: boolean;
  kodOdbNz: string;
  kodTypZS: string;
  nazovOdbNz: string;
  povolenyVstupHK: boolean;
  povolenyVstupPCP: boolean;
  povolenyVstupPP: boolean;
  predvolenaNZ: string;
}

export interface DostupnaOdbornost extends OdbornostHodnoteneho {
  aktivna: boolean;
  zobrazovanyNazovOdb: string;
}

export interface ProfilHodnoteneho {
  email: string | undefined;
  ico: string | undefined;
  id: number | undefined;
  idHz: number | undefined;
  idSubjekt: number | undefined;
  idVz: number | undefined;
  kodZdruzenie: string | undefined;
  menoZobrazLekar: string | undefined;
  nazovZobrazPzs: string | undefined;
}

export type Aktualita = {
  data: string | null;
  datum: string;
  id: number;
  kodAktualita: string | null;
  nazov: string;
  precitane: boolean;
  text: string;
  url: string;
};

export type Novinka = {
  data?: string;
  datum: string;
  id: number;
  kodAktualita: string;
  nazov: string;
  precitane: boolean;
  text: string;
};

export type UvazokLekaraPZS = {
  ambulanciaKod?: string;
  ambulanciaNazov?: string;
  id: string | number;
  lekarKod: string;
  lekarMeno: string;
  odbLekKod: string;
  odbLekNazov: string;
  uvazokDatumDo?: string | null;
  uvazokDatumOd: string;
  uvazokHodnota: number;
  uvazokTyp: string;
};

export type UvazokLekaraAmbPZS = {
  ambulanciaKod?: string;
  ambulanciaNazov?: string;
  id: string | number;
  lekarKod: string;
  lekarMeno: string;
  odbornostLekKod: string;
  odbornostLekNazov: string;
  uvazokDatumDo?: string | null;
  uvazokDatumOd: string;
  uvazokHodnota: number;
  uvazokTyp: string;
};

export type AmbulanciaPZS = {
  datumDo?: string | null;
  datumOd: string;
  id: number;
  jeZmluvny: boolean;
  kod: string;
  nazov: string;
  odbornostKod: string;
  odbornostNazov: string;
};

export type OrdinacneHodiny = {
  casDo: string;
  casOd: string;
  denVTyzdni: number;
  id: number;
  typOrdHodin: string | null;
};

export type OdbornostUvazku = {
  id: string;
  odbornostAmbKod: string;
  odbornostAmbNazov: string;
};

export type UdajeLekarOsoba = {
  chyba: string | null;
  idVZ: number;
  kontaktHK: string | null;
  lekarKod: string;
  lekarOsobaId: number | null;
  meno: string;
  priezvisko: string;
  titulPred: string;
  titulZa: string;
  zoznamOdobornostiUvazkov: OdbornostUvazku[] | null;
};

export type StatutarOsoba = {
  id: number;
  meno: string;
  priezvisko: string;
  stavAutentifikacie: string;
  titulPred: string;
  titulZa: string;
};

export type UdajeAutentifikacie = {
  jeAutentifikovany: boolean;
  pocetOdmietnutiAutentifikacie: number;
  statutarOsoba: StatutarOsoba | null;
  stavAutentifikacie: string;
  typAutentifikacie: string;
  udajeLekarOsoba: UdajeLekarOsoba | null;
  vyzadovatAutentifikaciu: boolean;
};

export type AuthPersons = {
  statutarOsoba: StatutarOsoba | null;
  lekarOsoba: UdajeLekarOsoba | null;
};

export type AvailableSectionsByTheme = {
  nazov: 'Zuctovanie' | 'CakacieListiny' | 'Sekcia' | string;
  sekcie: EPSections[] & EPSection[];
};

export interface DataVZResponse {
  autentifikovaneOsoby: AuthPersons | null;
  celyNazovZobrazPZS: string | null;
  chybaKonatel: boolean;
  email: string | null;
  ico: string;
  idHodnotenyPZS: number | null;
  idHz: number;
  idLekarOsoba: number;
  jeStatutarNaPristupe: boolean;
  kodPZS: string;
  kodZdruzenie: string | null;
  maZmluvnePristroje: boolean;
  menoZobrazLekar: string | null;
  nazovZobrazPzs: string | null;
  temy: AvailableSectionsByTheme[] | null;
  udajeAutentifikacie: UdajeAutentifikacie;
  zobrazPEK: boolean;
}

interface PolozkaNaRiesenie {
  hodnota1: string | null;
  hodnota2: string | null;
  hodnota3: string | null;
  hodnota4: string | null;
  hodnota5: string | null;
  naRiesenieId: number;
  nadpis: string;
  popis: string | null;
  text1: string | null;
  text2: string | null;
  text3: string | null;
  typAkcie: string | null;
}
export interface NaRiesenieInterface {
  id: number;
  nadpis: string;
  pocetNaRiesenie: number;
  polozkyNaRiesenie: PolozkaNaRiesenie[];
  popis: string;
  typIkona: 'success' | 'warning' | 'error' | string;
}

export interface DajDataWidgetuNaRiesenieResponse {
  naRiesenie: NaRiesenieInterface[];
  widget: {
    nadpis: string;
    pocetNaRiesenie: number;
  };
}

export type DashboardState = {
  aktuality: Aktualita[];
  error: string | null;
  errorType: null | 'technical' | 'not-allowed';
  isLoaded: boolean;
  isLoading: boolean;
  novinky: Novinka[];
};

export interface PoskytovatelState {
  autentifikovaneOsoby: AuthPersons | null;
  celyNazovZobrazPZS: string;
  chybaKonatel: boolean;
  dashboard: DashboardState;
  dostupneOdbornostiHP: DostupnaOdbornost[];
  dostupneOdbornostiPP: DostupnaOdbornost[];
  email: string | null;
  error: string | null;
  errorType: null | 'technical' | 'not-allowed';
  ico: string | undefined;
  idHodnotenyPZS: number | null;
  idHz: number;
  idLekarOsoba: number;
  isLoaded: boolean;
  isLoading: boolean;
  jeStatutarNaPristupe: boolean;
  kodPZS: string;
  kodTypZs: string;
  kodZdruzenie: string | undefined;
  maZmluvnePristroje: boolean;
  menoZobrazLekar: string | undefined | null;
  nazovZobrazPzs: string | undefined;
  odbornostPublic: { kodOdbAmb: string; kodOdbAmbNazov: string }[];
  odbornosti: OdbornostHodnoteneho[];
  odbornostiState: LoadingState;
  odbornostiEmptyState: boolean;
  pekChyba: string | null;
  pekLoading: boolean;
  personalizedDataReady: boolean;
  poslednaZmenaOdbornosti: string;
  povolenyVstupHK: boolean;
  povolenyVstupPP: boolean;
  preLekara: boolean;
  statutar: {
    data: Statutar | null;
    error: string | null;
    isLoading: boolean;
  };
  temy: AvailableSectionsByTheme[] | null;
  usePersonalizedData: boolean;
  zmenenaOdbornost: boolean;
  zobrazPEK: boolean;
}

export type VASType = {
  isVLD: boolean;
  primaryVLD: boolean;
};

export type Sekcia = {
  id: number;
  kodSekcie: string;
  nazovSekcie: string;
  zapnuta: boolean;
};

export interface OvereniaPrehladyState {
  overeniaPrehlady: {
    ambulances: {
      data: AmbulanciaPZS[] | null;
      dataState: LoadingState;
      detail: {
        address: Adresa[];
        detailState: LoadingState;
        doctors: UvazokLekaraAmbPZS[][];
        workingHours: OrdinacneHodiny[];
      } & Loadable;
      filterOptions: {
        ambulances: string[];
        expertises: {
          label: string;
          value: string;
          text: string;
        }[];
      };
    } & Loadable;
    doctors: {
      data: UvazokLekaraPZS[] | null;
      dataState: LoadingState;
      filterOptions: {
        doctors: string[];
        expertises: {
          label: string;
          value: string;
        }[];
      };
    } & Loadable;
  };
}

export interface DataAutentifikacie {
  email: string;
  id: number;
  idOsoby: number;
  idPouzivatel: number;
  jeAktivnyPristup: boolean;
  kategoriaRole: string;
  kodLekara: string;
  meno: string;
  mobil: string;
  nazovRole: string;
  pevnaLinka: string;
  pocetOdmietnuti: number;
  priezvisko: string;
  stavAutentifikacie: string;
  titulPred: string;
  titulZa: string;
  typAutentifikacie: string;
  typRole: 'S' | 'L' | 'Z' | 'A' | 'I';
}

export interface DataOsobyLekaraPZS {
  chyba: string;
  idVZ: string;
  kontaktHK: string;
  lekarKod: string;
  lekarOsobaId: string;
  meno: string;
  priezvisko: string;
  titulPred: string;
  titulZa: string;
  zoznamOdbornostiUvazkov: OdbornostUvazku[];
}

export interface StatutarRPO {
  aktualnyPouzivatel: boolean;
  autentifikaciaPouzivatelId: number | null;
  funkcia: string;
  id: number;
  idPouzivatel: number;
  idStatutarOsoba: number;
  identifikacnyUdaj: string;
  jeAutentifikovany: boolean;
  meno: string;
  priezvisko: string;
  titulPred: string;
  titulZa: string;
}

export interface PzsAuthState {
  authAvailability: {
    data: OverDostupnostAutentifikacieResponse | null;
    error: string | null;
    isLoading: boolean;
  };
  authData: {
    data: DataAutentifikacie[] | null;
    error: string | null;
    isLoading: boolean;
  };
  doctorData: {
    data: DajDataLekaraResponse | null;
    error: string | null;
    isLoading: boolean;
  };
  isOpen: {
    banner: boolean;
    bannerVersion: 'A' | 'B' | string | null;
    dashboardCard: boolean;
    modal: boolean;
    patientCard: boolean;
  };
  sections: {
    data: SekciaPristupu[];
    error: string | null;
    isLoading: boolean;
  };
  statutories: {
    data: StatutarRPO[] | null;
    error: string | null;
    isLoading: boolean;
  };
  stepper: {
    step1: {
      createNewDoctorAccount: boolean;
      useMore: boolean;
    };
    step2: {
      different: string | null;
      positions: string[];
    };
    step3: {
      doctorCode: string | null;
      email: string;
      firstName: string | null;
      lastName: string | null;
      mobile: string | null;
      phone: string | null;
      statutory: StatutarRPO | null;
      titleAfter: string | null;
      titleBefore: string | null;
    };
    stepLogin: {
      login: string;
      password: string;
    };
    stepResult: {
      emailHK: string | null;
      error: string | null;
      isLoading: boolean;
      processingState: boolean;
      result: 'success' | 'warning' | 'warningHK' | 'error' | null | string;
    };
  };
}

export interface UdajAutentifikacie {
  email: string;
  heslo: string | null;
  idOsoby: number | null;
  idStatutar: number | null;
  jeHlavnaKarta: boolean;
  kategoriaRole: string | null;
  kodLekar: string | null;
  login: string | null;
  meno: string | null;
  mobil: string | null;
  pevnaLinka: string | null;
  priezvisko: string | null;
  titulPred: string | null;
  titulZa: string | null;
  typRole: 'S' | 'L' | 'Z' | 'A' | 'I' | string;
  vytvorNovyPristup: boolean;
}

export type SekciaPristupu = {
  idSekcie: number;
  idSekciePouzivatela: number;
  kodSekcie: string;
  nazovSekcie: string;
  zapnuta: boolean;
};

export enum RoleType {
  'A' = 'Administratívna pozícia',
  'I' = 'Iná pozícia',
  'L' = 'Lekár',
  'S' = 'Štatutár',
  'Z' = 'Zdravotná sestra',
}

export enum AuthStateType {
  'E' = 'nová nepotvrdená',
  'N' = 'neschválená - čaká na potvrdenie',
  'O' = 'odmietnutá',
  'P' = 'potvrdená',
  'U' = 'ukončenie autentifikácie',
}
