interface Props {
  color?: string;
  id: string;
}

const IconMoneyInsert = ({ color, id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M11.2929 1.29289C11.6834 0.902369 12.3166 0.902369 12.7071 1.29289L15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711C15.3166 6.09763 14.6834 6.09763 14.2929 5.70711L13 4.41421V10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10V4.41421L9.70711 5.70711C9.31658 6.09763 8.68342 6.09763 8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289L11.2929 1.29289ZM2 12C2 11.4477 2.44772 11 3 11H8C8.55228 11 9 10.5523 9 10C9 9.44771 8.55228 9 8 9H3C1.34315 9 0 10.3431 0 12V20C0 21.6569 1.34314 23 3 23H21C22.6569 23 24 21.6569 24 20V12C24 10.3431 22.6569 9 21 9H16C15.4477 9 15 9.44771 15 10C15 10.5523 15.4477 11 16 11H21C21.5523 11 22 11.4477 22 12V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12ZM12 13C10.3431 13 9 14.3431 9 16C9 17.6569 10.3431 19 12 19C13.6569 19 15 17.6569 15 16C15 14.3431 13.6569 13 12 13ZM19 19C19.5523 19 20 18.5523 20 18C20 17.4477 19.5523 17 19 17C18.4477 17 18 17.4477 18 18C18 18.5523 18.4477 19 19 19ZM6 14C6 14.5523 5.55228 15 5 15C4.44772 15 4 14.5523 4 14C4 13.4477 4.44772 13 5 13C5.55228 13 6 13.4477 6 14ZM11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16Z"
      fill={color || '#BED2F4'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconMoneyInsert;
