import convertor from './convertor';

export const convertPayloadToSK = (payload: any): any => {
  if (Array.isArray(payload))
    return payload.map((item) => convertPayloadToSK(item));
  if (payload !== null && typeof payload === 'object') {
    return Object.keys(payload).reduce(
      (acc, key) => {
        const mappedKey = convertor[key] || key;
        acc[mappedKey] = convertPayloadToSK(payload[key]);
        return acc;
      },
      {} as { [key: string]: any },
    );
  }
  return payload;
};
