/* eslint-disable */
import { ReactNode, Ref, useEffect } from 'react';
import { color } from '../../../constants';
import strings from '../../../constants/strings';
import { cx } from '../../../utils/exports';
import IconCheckCircle from '../../CustomIcons/IconCheckCircle';
import IconEdit from '../../CustomIcons/IconEdit';
import IconErrorCircle from '../../CustomIcons/IconErrorCircle';
import IconWarningCircleStepper from '../../CustomIcons/IconWarningCircleStepper';
import { CustomTooltip } from '../../CustomTooltip';
import useStyles from './StepHeader.styles';
import { scrollTo } from '../../../utils/app.utils';

interface Props {
  active?: boolean;
  [x: string]: any;
  className?: string;
  completed?: boolean;
  editText?: string;
  error?: boolean;
  icon: ReactNode;
  onHeaderClick?: (index?: number) => void;
  stepRef?: Ref<any>;
  warning?: boolean;
  withoutEdit?: boolean;
}

const StepHeader = ({
  active,
  className: classNameProp,
  completed,
  disabled,
  editText,
  error,
  icon,
  index,
  lastStep,
  onHeaderClick,
  stepRef,
  warning,
  withoutEdit,
  ...props
}: Props) => {
  const classes = useStyles({ active, completed, error, withoutEdit });
  const { children } = props;
  const rootClassName = cx(classes.root, {
    [classes.active]: active,
    [classes.completed]: completed && !warning,
    [classes.warning]: completed && warning,
    [classes.disabled]: disabled,
    [classes.error]: error,
  });

  useEffect(() => {
    if (active && stepRef) {
      // @ts-ignore
      const el = stepRef?.current;
      setTimeout(() => {
        const elPosition = el?.getBoundingClientRect()?.top || 0;
        if (elPosition) scrollTo(elPosition);
      }, 500);
    }
  }, [active, stepRef]);

  const iconTextProps = {
    fontSize: 20,
    textAnchor: 'middle',
    fill: disabled ? color('secondary', 500) : 'white',
    fontWeight: 'bold',
  };

  const handleHeaderClick = () => {
    if (!disabled && onHeaderClick) {
      onHeaderClick(index);
    }
  };

  let iconFillColor;

  if (error) {
    iconFillColor = color('error');
  } else if (!disabled) {
    iconFillColor = color('secondary', 500);
  } else {
    iconFillColor = '#FFFFFF';
  }

  let iconComponent = (
    <svg fill="none" height="52" viewBox="0 0 52 52" width="52">
      <rect
        fill={iconFillColor}
        height="50"
        rx="25"
        stroke="#1F57A5"
        strokeOpacity="0.3"
        strokeWidth="2"
        width="50"
        x="1"
        y="1"
      />

      <text x={24} y={32} {...iconTextProps}>
        {icon}
      </text>
    </svg>
  );

  if (error) {
    iconComponent = (
      <IconErrorCircle
        id={`stepper-icon-error-circle--${index}`}
        width={50}
        height={50}
      />
    );
  } else if (warning && completed) {
    iconComponent = (
      <IconWarningCircleStepper id={`stepper-icon-warning-circle--${index}`} />
    );
  } else if (completed) {
    iconComponent = (
      <IconCheckCircle id={`stepper-icon-check-circle--${index}`} />
    );
  }

  return (
    <div
      ref={stepRef}
      className={rootClassName}
      onClick={handleHeaderClick}
      {...props}
    >
      {iconComponent}
      <h5 className={classes.header}>{children}</h5>
      {(completed || error) && !active && !withoutEdit && !editText && (
        <CustomTooltip
          dialog={strings.vseobecne.upravit}
          id={`step-header--${index}`}
        >
          <IconEdit
            color={warning ? color('warning') : color('primary', 600)}
          />
        </CustomTooltip>
      )}
      {(completed || error) && !active && editText && (
        <div className={cx(editText && classes.editBtn)}>
          <IconEdit
            color={warning ? color('warning') : color('primary', 600)}
          />
          {editText && <span>{editText}</span>}
        </div>
      )}
    </div>
  );
};

export default StepHeader;
