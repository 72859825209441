import { useEffect, useState } from 'react';
import { ButtonLink, Icon } from '@dovera/design-system';
import strings from '../../../../constants/strings';
import IconSearch from '../../../../components/CustomIcons/IconSearch';
import useStyles from '../../Proposals.styles';
import { PROPOSAL_STATES } from '../../../../constants/proposals';
import { cx } from '../../../../utils/exports';
import { useDebounce } from '../../../../hooks/useDebounce';
import { DATE_INTERNATIONAL, getMoment } from '../../../../utils/date.utils';
import { SubmittedProposalsFilter } from '../../../../types/spaProposals.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import {
  DatepickerFilter,
  InputFilter,
  MultiSelectFilter,
} from '../../../../components/Filter';

const texts = strings.proposals.list.submittedProposals.filter;

interface Props {
  onFiltered: (values: SubmittedProposalsFilter, showAll: boolean) => void;
  propNumber?: string;
}

const Filter = ({ onFiltered, propNumber }: Props) => {
  const classes = useStyles();
  const { storedFilter } = useSelector(
    (state: RootState) => state.spaProposals.list,
  );
  const [proposalNumber, setProposalNumber] = useState(
    storedFilter?.proposalNumber || propNumber || '',
  );
  const [insuree, setInsuree] = useState(storedFilter?.insuree || '');
  const [proposalState, setProposalState] = useState(
    storedFilter?.proposalState || '',
  );
  const [doctorCode, setDoctorCode] = useState(storedFilter?.doctorCode || '');
  const [ambulance, setAmbulance] = useState(storedFilter?.ambulance || '');
  const [validityFrom, setValidityFrom] = useState<Date | null>(
    storedFilter?.validityFrom ? new Date(storedFilter?.validityFrom) : null,
  );
  const [validityTo, setValidityTo] = useState<Date | null>(
    storedFilter?.validityTo ? new Date(storedFilter?.validityTo) : null,
  );
  const [showAll, setShowAll] = useState(storedFilter?.showAllFilters || false);
  const debouncedProposalNumber = useDebounce(proposalNumber, 500);
  const debouncedInsuree = useDebounce(insuree, 500);
  const debouncedDoctorCode = useDebounce(doctorCode, 500);
  const debouncedAmbulance = useDebounce(ambulance, 500);
  useEffect(() => {
    onFiltered(
      {
        ambulance: debouncedAmbulance,
        doctorCode: debouncedDoctorCode,
        insuree: debouncedInsuree,
        proposalNumber: debouncedProposalNumber,
        state: proposalState,
        validityFrom: validityFrom
          ? getMoment(validityFrom).format(DATE_INTERNATIONAL)
          : '',
        validityTo: validityTo
          ? getMoment(validityTo).format(DATE_INTERNATIONAL)
          : '',
      },
      showAll,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedAmbulance,
    debouncedDoctorCode,
    debouncedInsuree,
    debouncedProposalNumber,
    onFiltered,
    proposalState,
    validityFrom,
    validityTo,
  ]);
  useEffect(() => {
    if (propNumber)
      setTimeout(() => {
        onFiltered(
          {
            ambulance: '',
            doctorCode: '',
            insuree: '',
            proposalNumber: propNumber,
            state: '',
            validityFrom: '',
            validityTo: '',
          },
          false,
        );
      }, 500);
  }, [onFiltered, propNumber]);
  const renderProposalNumber = (
    <InputFilter
      addonsInside
      crossOrigin
      id={`kn-vyhladavanieCislo`}
      label={texts.proposalNumber}
      onChange={(e) => setProposalNumber(e.target.value)}
      rightAddons={<IconSearch id={`icon-filter-proposalNumber`} />}
      value={proposalNumber}
    />
  );
  const renderInsuree = (
    <InputFilter
      addonsInside
      crossOrigin
      id={`kn-vyhladavaniePacient`}
      label={texts.insuree}
      onChange={(e) => setInsuree(e.target.value)}
      rightAddons={<IconSearch id={`icon-filter-insuree`} />}
      value={insuree}
    />
  );
  const renderProposalState = (
    <MultiSelectFilter
      defaultOption="Všetky"
      id={`kn-stavNavrhu`}
      label={texts.state}
      onSelected={(values) => setProposalState(values)}
      options={PROPOSAL_STATES}
      width={211}
    />
  );
  const renderDoctorCode = (
    <InputFilter
      addonsInside
      crossOrigin
      id={`kn-vyhladavanieLekar`}
      label={texts.doctorCode}
      onChange={(e) => setDoctorCode(e.target.value)}
      rightAddons={<IconSearch id={`icon-filter-doctorCode`} />}
      value={doctorCode}
    />
  );
  const renderAmbulance = (
    <InputFilter
      addonsInside
      crossOrigin
      id={`kn-vyhladavanieAmbulancia`}
      label={texts.ambulance}
      onChange={(e) => setAmbulance(e.target.value)}
      rightAddons={<IconSearch id={`icon-filter-ambulance`} />}
      value={ambulance}
    />
  );
  const renderValidityFrom = (
    <DatepickerFilter
      id={`kn-datumOd`}
      label={texts.validityFrom}
      maxDate={validityTo ? new Date(validityTo) : undefined}
      onChange={(value) => setValidityFrom(value)}
      value={validityFrom}
    />
  );
  const renderValidityTo = (
    <DatepickerFilter
      id={`kn-datumDo`}
      label={texts.validityTo}
      minDate={validityFrom ? new Date(validityFrom) : undefined}
      onChange={(value) => setValidityTo(value)}
      value={validityTo}
    />
  );
  const renderShowMoreLess = (
    <ButtonLink className="no-pad no-mrg" onClick={() => setShowAll(!showAll)}>
      {showAll ? (
        <>
          <Icon className="text-space-half-right" name="16-minus" />
          {texts.less}
        </>
      ) : (
        <>
          <Icon className="text-space-half-right" name="16-plus" />
          {texts.more}
        </>
      )}
    </ButtonLink>
  );
  return (
    <div className={cx(classes.filterContainer)}>
      {renderProposalNumber}
      {renderInsuree}
      {renderProposalState}
      {showAll && (
        <>
          {renderDoctorCode}
          {renderAmbulance}
          {renderValidityFrom}
          {renderValidityTo}
        </>
      )}
      {renderShowMoreLess}
    </div>
  );
};

export default Filter;
