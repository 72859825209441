import { useEffect } from 'react';

const useOutsideMouseover = (ref: any, callback: (e: Event) => void) => {
  const handleClick = (e: Event) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback(e);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseover', handleClick);

    return () => {
      document.removeEventListener('mouseover', handleClick);
    };
  });
};

export default useOutsideMouseover;
