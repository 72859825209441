import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';
import { color } from '../../../constants';

interface StylesProps {
  read?: boolean;
}

export default createUseStyles({
  row: ({ read }: StylesProps) => ({
    display: 'flex',
    width: '100%',
    color: read ? '#6D7175' : '#000000',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 16,
      '& p': {
        fontSize: 16,
        fontWeight: '100',
      },
      '&:nth-child(2)': {
        marginLeft: 16,
      },
      '&:last-child': {
        position: 'absolute',
        right: 24,
        marginTop: 2,
      },
      '& button': {
        minHeight: 'auto',
        marginTop: '-3px',
        position: 'relative',
        '& span': {
          position: 'absolute',
        },
      },
    },
  }),
  title: ({ read }: StylesProps) => ({
    color: read ? '#6D7175' : '',
    fontWeight: read ? '100' : 'bold',
    fontSize: 18,
  }),
  link: {
    transition: '0.2s ease',
    padding: '16px 24px 16px 32px',
    display: 'block',
    '&:hover': {
      textDecoration: 'none',
      background: '#F2F5FA',
    },
    '&:not(:last-child)': {
      boxShadow: '0px 1px 0px #E5E5E5',
    },
  },
  dlekPoiCard: {
    position: 'relative',
    margin: '10px 0 0',
    padding: '16px 56px 16px 24px',
    borderRadius: 8,
    fontSize: '16px !important',
    lineHeight: '24px',
    color: '#000',
    background: '#FFEACC',
    '& b': {
      fontWeight: 700,
      color: color('primary'),
    },
    '& svg': {
      position: 'absolute',
      right: 16,
      top: 22,
    },
  },
  showMoreWrapper: {
    justifyContent: 'space-between',
  },
  showMore: {
    minHeight: 0,
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      position: 'relative !important',
    },
    '& svg': {
      marginLeft: getRem(12),
    },
    '&.less': {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  collapsableContent: {
    margin: `${getRem(32)} 0`,
  },
  collapsableItem: {
    marginLeft: getRem(48),
    alignItems: 'center',
    justifyContent: 'space-between',
    '& b': {
      maxWidth: getRem(240),
    },
    '& button': {
      minHeight: 0,
      padding: `${getRem(4)} ${getRem(12)}`,
      marginRight: `calc(100% - ${getRem(160)} - ${getRem(260)})`,
      marginBottom: 0,
      [`@media screen and (min-width: 1300px)`]: {
        marginRight: `calc(100% - ${getRem(160)} - ${getRem(320)})`,
      },
    },
    '&:not(:last-child)': {
      marginBottom: getRem(24),
    },
  },
  btnExchange: {
    minHeight: 0,
    padding: `${getRem(4)} ${getRem(12)}`,
    marginLeft: getRem(32),
    marginBottom: 0,
  },
  btnLink: {
    minHeight: 0,
    marginBottom: 0,
    padding: 0,
    marginLeft: getRem(4),
  },
});
