// @ts-nocheck
import { ReactNode, useEffect } from 'react';
import { Button, ButtonLayout, ButtonSecondary } from '@dovera/design-system';
import Illustration from '../../assets/illustrations/notFound.png';
import IllstrationBox from '../../assets/illustrations/notFound2.png';
import IllustrationQuestion from '../../assets/illustrations/global/error_mamografia.png';
import strings from '../../constants/strings';
import SafeHtml from '../SafeHtml/SafeHtml';
import useStyles from './NoDataIllustration.styles';
import routes from '../../routes';
import { cx } from '../../utils/exports';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { toggleMenu } from '../../slices/menu.slice';
import { useAppDispatch } from '../../hooks/useStore';
import { useNavigate } from 'react-router';
// @ts-check

interface Props {
  description?: string;
  dontOpenMenu?: boolean;
  illustration?: 'box' | 'woman' | 'woman-question';
  login?: boolean;
  text?: string;
  withoutButtons?: boolean;
}

const getIllustration = (illustration: string): ReactNode => {
  if (illustration === 'box') return IllstrationBox;
  if (illustration === 'woman-question') return IllustrationQuestion;
  return Illustration;
};

const NoData = ({
  description,
  dontOpenMenu,
  illustration,
  login,
  text,
  withoutButtons,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isOpen } = useSelector((state: RootState) => state.menu);
  useEffect(() => {
    if (!isOpen && !dontOpenMenu) dispatch(toggleMenu());
    /* eslint-disable-next-line */
  }, [dontOpenMenu]);
  return (
    <div className={cx(classes.root, classes.wider)}>
      <img alt="Illustration" src={getIllustration(illustration)} />
      <SafeHtml
        html={login ? strings.loginPublicChyba : text || strings.technickaChyba}
        wrapper="h4"
      />
      {description ? <SafeHtml html={description} wrapper="div" /> : ''}
      {login ? <p>{strings.loginPublicChybaPopis}</p> : <span />}
      <ButtonLayout className={classes.buttonLayout} preserveWidth>
        {login ? (
          <Button onClick={() => navigate('/')} size="s">
            Prihlásiť sa
          </Button>
        ) : (
          !withoutButtons && (
            <>
              <ButtonSecondary
                onClick={() => navigate(routes.dashboard)}
                size="s"
              >
                Domov
              </ButtonSecondary>
              <Button onClick={() => navigate.goBack()} size="s">
                Späť na predchádzajúcu stránku
              </Button>
            </>
          )
        )}
      </ButtonLayout>
    </div>
  );
};

export default NoData;
