import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { zmazVlakno } from '../../../api/schrankaSprav';
import PotvrdzujuciModal from '../../../components/PotvrdzujuciModal/PotvrdzujuciModal';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import strings from '../../../constants/strings';
import { useAppDispatch } from '../../../hooks/useStore';
import { RootState } from '../../../rootReducer';
import {
  zmenaOffsetuVlakien,
  zobrazitToast,
} from '../../../slices/spravy.slice';
import { callThreads } from '../../../utils/spravy.utils';

const texts = strings.schrankaSprav;

interface Props {
  isVisible: boolean;
  onHide: () => void;
  onRemoved: () => void;
}

const RemoveThreadModal = ({ isVisible, onHide, onRemoved }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const { detail, kategoria, vlakna } = useSelector(
    (state: RootState) => state.spravy,
  );
  useEffect(() => {
    setError('');
    setLoading(false);
  }, [isVisible]);
  const count = vlakna.data?.filter((v) => v.oznacenePreVymazanie)?.length || 0;
  const removeThreadsCallback = useCallback(() => {
    setLoading(true);
    const idVlakien: number[] =
      vlakna.data?.filter((v) => v.oznacenePreVymazanie).map((v) => v.id) || [];
    zmazVlakno({ idVlakno: idVlakien.length ? idVlakien : [detail.id] })
      .then(() => {
        dispatch(zobrazitToast({ typ: 'vymazanie' }));
        dispatch(zmenaOffsetuVlakien({ offset: 0 }));
        callThreads({
          category: kategoria,
        });
        onRemoved();
      })
      .catch(() => setError(strings.defaultError))
      .finally(() => {
        setLoading(false);
      });
  }, [detail.id, dispatch, kategoria, onRemoved, vlakna.data]);
  return (
    <PotvrdzujuciModal
      alignLeft
      buttonLinkText={texts.modals.confirmRemoveModal.secondaryBtn}
      errorText={error}
      isDestructive
      isVisible={isVisible}
      onHide={onHide}
      onPrimaryButtonClick={removeThreadsCallback}
      primaryButtonText={`${texts.modals.confirmRemoveModal.primaryBtn}${
        count ? ` (${count})` : ''
      }`}
      submitLoading={loading}
      text={
        <SafeHtml
          html={texts.modals.confirmRemoveModal.description(count || 1)}
        />
      }
      title={texts.modals.confirmRemoveModal.title(count || 1)}
    />
  );
};

export default RemoveThreadModal;
