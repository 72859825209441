import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@dovera/design-system';
import { replaceKeysInText } from './utils';
import { RootState } from '../../rootReducer';
import { Nullable } from '../../types';
import SafeHtml from '../SafeHtml/SafeHtml';

interface Props {
  params?: {
    [key: string]: string;
  };
  path: Nullable<string>;
}

const CMSText = ({ params, path }: Props) => {
  const { isLoading, texty: texts } = useSelector(
    (state: RootState) => state.cms,
  );
  const text = texts.find((t) => t.cesta === path);

  if (!path) return '[ TEXT SA NENAŠIEL ]';
  if (isLoading) return <Loader size={24} />;
  if (!text) return <div>[ TEXT SA NENAŠIEL ]</div>;
  if (!params)
    return (
      <SafeHtml className="fs-18 mb-large" html={text.obsah} wrapper="div" />
    );

  const textWithParams = replaceKeysInText(text.obsah, params);

  return (
    <SafeHtml className="fs-18 mb-large" html={textWithParams} wrapper="div" />
  );
};

export default CMSText;
