import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PzsAuthState, StatutarRPO } from '../types/poskytovatel.types';
import {
  checkAuthAvailability,
  fetchDataAutentifikacie,
  fetchDataOsobyLekaraPZS,
  fetchNajdiStatutarov,
  fetchSections,
  saveAuthData,
  saveAuthRejection,
  updateAuthData,
} from '../api/poskytovatel';
import {
  DajDataAutentifikacieResponse,
  DajDataLekaraResponse,
  DajSekciePristupuResponse,
  NajdiStatutarovResponse,
  OverDostupnostAutentifikacieResponse,
  UlozAutentifikaciueResponse,
  ZmenUdajeAutentifikacieResponse,
} from '../types/api/poskytovatel';
import strings from '../constants/strings';
import { VALIDATION } from '../types/validation.types';
import _ from 'lodash';

const initialState: PzsAuthState = {
  authAvailability: {
    data: null,
    error: null,
    isLoading: false,
  },
  authData: {
    data: null,
    error: null,
    isLoading: false,
  },
  doctorData: {
    data: null,
    error: null,
    isLoading: false,
  },
  isOpen: {
    banner: false,
    bannerVersion: null,
    dashboardCard: false,
    modal: false,
    patientCard: false,
  },
  sections: {
    data: [],
    error: null,
    isLoading: false,
  },
  statutories: {
    data: null,
    error: null,
    isLoading: false,
  },
  stepper: {
    step1: {
      createNewDoctorAccount: false,
      useMore: false,
    },
    step2: {
      different: null,
      positions: [],
    },
    step3: {
      doctorCode: null,
      email: '',
      firstName: '',
      lastName: '',
      mobile: null,
      phone: null,
      statutory: null,
      titleAfter: null,
      titleBefore: null,
    },
    stepLogin: {
      login: '',
      password: '',
    },
    stepResult: {
      emailHK: null,
      error: null,
      isLoading: false,
      processingState: false,
      result: null,
    },
  },
};

const pzsAuthSlice = createSlice({
  name: 'pzsAuth',
  initialState,
  reducers: {
    initVisibilityPzsAuth(
      state,
      action: PayloadAction<{
        pocetOdmietnutiAutentifikacie: number;
        vyzadovatAutentifikaciu: boolean;
      }>,
    ) {
      const { pocetOdmietnutiAutentifikacie, vyzadovatAutentifikaciu } =
        action.payload;
      return {
        ...state,
        isOpen: {
          banner: vyzadovatAutentifikaciu && pocetOdmietnutiAutentifikacie < 3,
          bannerVersion: ['A', 'B'][_.random(0, 1)],
          dashboardCard:
            vyzadovatAutentifikaciu && pocetOdmietnutiAutentifikacie > 3,
          modal: vyzadovatAutentifikaciu && pocetOdmietnutiAutentifikacie === 3,
          patientCard: vyzadovatAutentifikaciu,
        },
      };
    },
    toggleVisibilityPzsAuth(
      state,
      action: PayloadAction<{ type: 'banner' | 'modal' | 'patientCard' }>,
    ) {
      return {
        ...state,
        isOpen: {
          ...state.isOpen,
          [action.payload.type]: !state.isOpen?.[action.payload.type],
        },
      };
    },
    storeStep1(state, action: PayloadAction<{ useMore: boolean }>) {
      return {
        ...state,
        stepper: {
          ...state.stepper,
          step1: {
            ...state.stepper.step1,
            useMore: action.payload.useMore,
          },
        },
      };
    },
    storeStep2(
      state,
      action: PayloadAction<{
        differentPosition?: string;
        positions: string[];
      }>,
    ) {
      return {
        ...state,
        stepper: {
          ...state.stepper,
          step2: {
            different: action.payload.differentPosition || null,
            positions: action.payload.positions,
          },
          step3: {
            ...state.stepper.step3,
            statutory: !action.payload.positions.includes('S')
              ? null
              : state.stepper.step3.statutory,
            doctorCode: !state.stepper.step3.firstName
              ? null
              : state.stepper.step3.doctorCode,
          },
        },
        doctorData: {
          ...state.doctorData,
          error: null,
        },
      };
    },
    storeStatutory(state, action: PayloadAction<{ id: number }>) {
      return {
        ...state,
        stepper: {
          ...state.stepper,
          step3: {
            ...state.stepper.step3,
            statutory:
              state.statutories.data?.filter(
                (s) => s.id === action.payload.id,
              )?.[0] || null,
          },
        },
        doctorData: initialState.doctorData,
      };
    },
    storeStep3(
      state,
      action: PayloadAction<{
        email: string;
        firstName: string;
        lastName: string;
        mobile: string;
        phone: string;
        titleAfter: string;
        titleBefore: string;
      }>,
    ) {
      return {
        ...state,
        stepper: {
          ...state.stepper,
          step3: {
            ...state.stepper.step3,
            email: action.payload.email,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            mobile: action.payload.mobile,
            phone: action.payload.phone,
            titleAfter: action.payload.titleAfter,
            titleBefore: action.payload.titleBefore,
          },
        },
      };
    },
    setStatutoryPerson(
      state,
      action: PayloadAction<{ statutory: StatutarRPO }>,
    ) {
      return {
        ...state,
        stepper: {
          ...state.stepper,
          step3: {
            ...state.stepper.step3,
            statutory: action.payload.statutory,
          },
        },
      };
    },
    setLoginDetails(
      state,
      action: PayloadAction<{ login: string; password: string }>,
    ) {
      return {
        ...state,
        stepper: {
          ...state.stepper,
          stepLogin: {
            login: action.payload.login,
            password: action.payload.password,
          },
        },
      };
    },
    resetAuth(state) {
      return {
        ...initialState,
        authAvailability: state.authAvailability,
        isOpen: {
          ...state.isOpen,
        },
      };
    },
    resetStep(state, action: PayloadAction<{ step: number }>) {
      return {
        ...state,
        doctorData:
          action.payload.step === 3
            ? initialState.doctorData
            : state.doctorData,
        statutories:
          action.payload.step === 3
            ? initialState.statutories
            : state.statutories,
        stepper: {
          ...state.stepper,
          step1:
            action.payload.step === 1
              ? initialState.stepper.step1
              : state.stepper.step1,
          step2:
            action.payload.step === 2
              ? initialState.stepper.step2
              : state.stepper.step2,
          step3:
            action.payload.step === 3
              ? initialState.stepper.step3
              : state.stepper.step3,
          stepLogin:
            action.payload.step === 4
              ? initialState.stepper.stepLogin
              : state.stepper.stepLogin,
        },
      };
    },
    setCreateNewDoctorAccount(
      state,
      action: PayloadAction<{ createNew: boolean }>,
    ) {
      return {
        ...state,
        stepper: {
          ...state.stepper,
          step1: {
            ...state.stepper.step1,
            createNewDoctorAccount: action.payload.createNew,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataAutentifikacie.pending.toString(), (state) => ({
        ...state,
        authData: {
          ...initialState.authData,
          isLoading: true,
        },
        sections: {
          ...initialState.sections,
        },
      }))
      .addCase(
        fetchDataAutentifikacie.fulfilled.toString(),
        (state, action: PayloadAction<DajDataAutentifikacieResponse>) => ({
          ...state,
          authData: {
            data: action.payload.dataAutentifikacie,
            error: null,
            isLoading: false,
          },
        }),
      )
      .addCase(fetchDataAutentifikacie.rejected.toString(), (state) => ({
        ...state,
        authData: {
          data: null,
          error: strings.defaultError,
          isLoading: false,
        },
      }))
      .addCase(fetchDataOsobyLekaraPZS.pending.toString(), (state) => ({
        ...state,
        doctorData: {
          ...initialState.doctorData,
          isLoading: true,
        },
      }))
      .addCase(
        fetchDataOsobyLekaraPZS.fulfilled.toString(),
        (state, action: PayloadAction<DajDataLekaraResponse>) => ({
          ...state,
          doctorData: {
            ...state.doctorData,
            data: action.payload,
            error: action.payload.chyba || null,
            isLoading: false,
          },
          stepper: {
            ...state.stepper,
            step3: {
              ...state.stepper.step3,
              doctorCode: action.payload?.lekarOsoba?.lekarKod,
            },
          },
        }),
      )
      .addCase(fetchDataOsobyLekaraPZS.rejected.toString(), (state) => ({
        ...state,
        doctorData: {
          ...state.doctorData,
          data: null,
          error: VALIDATION.AuthDefaultError || strings.defaultError,
          isLoading: false,
        },
      }))
      .addCase(fetchNajdiStatutarov.pending.toString(), (state) => ({
        ...state,
        statutories: {
          ...initialState.statutories,
          isLoading: true,
        },
        stepper: {
          ...state.stepper,
          step3: {
            ...state.stepper.step3,
            statutory: initialState.stepper.step3.statutory,
          },
        },
      }))
      .addCase(
        fetchNajdiStatutarov.fulfilled.toString(),
        (state, action: PayloadAction<NajdiStatutarovResponse>) => ({
          ...state,
          statutories: {
            data: action.payload.hodnoty || [],
            error: action.payload.chyba,
            isLoading: false,
          },
        }),
      )
      .addCase(fetchNajdiStatutarov.rejected.toString(), (state) => ({
        ...state,
        statutories: {
          data: null,
          error: strings.defaultError,
          isLoading: false,
        },
      }))
      .addCase(saveAuthData.pending.toString(), (state) => ({
        ...state,
        stepper: {
          ...state.stepper,
          stepResult: {
            ...initialState.stepper.stepResult,
            isLoading: true,
          },
        },
      }))
      .addCase(
        saveAuthData.fulfilled.toString(),
        (
          state,
          action: PayloadAction<{
            config: any;
            data: UlozAutentifikaciueResponse;
          }>,
        ) => {
          const request = JSON?.parse(action.payload?.config?.data);
          return {
            ...state,
            isOpen:
              action.payload.data.stavSpracovania &&
              request?.typAutentifikacie !== 'V'
                ? initialState.isOpen
                : state.isOpen,
            stepper: {
              ...state.stepper,
              stepResult: {
                emailHK: action.payload.data.kontaktHK,
                error: action.payload.data.chyba,
                isLoading: false,
                processingState: action.payload.data.stavSpracovania,
                result: !action.payload.data.stavSpracovania
                  ? 'error'
                  : /** if there was new account created */
                    (state.stepper.stepLogin.login &&
                        state.stepper.stepLogin.password) ||
                      !request?.jeHlavnaKarta
                    ? 'warning'
                    : 'success',
              },
            },
          };
        },
      )
      .addCase(saveAuthData.rejected.toString(), (state) => ({
        ...state,
        stepper: {
          ...state.stepper,
          stepResult: {
            ...initialState.stepper.stepResult,
            error: strings.defaultError,
            result: 'error',
          },
        },
      }))
      .addCase(updateAuthData.pending.toString(), (state) => ({
        ...state,
        stepper: {
          ...state.stepper,
          stepResult: {
            ...initialState.stepper.stepResult,
            isLoading: true,
          },
        },
      }))
      .addCase(
        updateAuthData.fulfilled.toString(),
        (state, action: PayloadAction<ZmenUdajeAutentifikacieResponse>) => ({
          ...initialState,
          stepper: {
            ...initialState.stepper,
            stepResult: {
              ...state.stepper.stepResult,
              isLoading: false,
              error: action.payload.chyba,
              processingState: action.payload.stavSpracovania,
              result: !action.payload.stavSpracovania ? 'error' : 'success',
            },
          },
        }),
      )
      .addCase(updateAuthData.rejected.toString(), () => ({
        ...initialState,
        stepper: {
          ...initialState.stepper,
          stepResult: {
            ...initialState.stepper.stepResult,
            error: strings.defaultError,
            result: 'error',
          },
        },
      }))
      .addCase(fetchSections.pending.toString(), (state) => ({
        ...state,
        sections: {
          ...initialState.sections,
          isLoading: true,
        },
      }))
      .addCase(
        fetchSections.fulfilled.toString(),
        (state, action: PayloadAction<DajSekciePristupuResponse>) => {
          const sections = state.authData.data?.some((d) => d.typRole === 'S')
            ? action.payload.sekciePristupu.map((s) => ({
                ...s,
                zapnuta: s.kodSekcie === 'STAT' || s.zapnuta,
              }))
            : action.payload.sekciePristupu;
          return {
            ...state,
            sections: {
              ...state.sections,
              data: sections,
              isLoading: false,
            },
          };
        },
      )
      .addCase(fetchSections.rejected.toString(), (state) => ({
        ...state,
        sections: {
          ...initialState.sections,
          error: strings.defaultError,
        },
      }))
      .addCase(saveAuthRejection.fulfilled.toString(), (state) => ({
        ...state,
        // authAvailability: {
        //   ...state.authAvailability,
        //   data: state.authAvailability.data
        //     ? {
        //         ...state.authAvailability.data,
        //         pocetOdmietnutiAutentifikacie:
        //           action.payload.data.pocetOdmietnutiAutentifikacie,
        //       }
        //     : null,
        // },
      }))
      .addCase(checkAuthAvailability.pending.toString(), (state) => ({
        ...state,
        authAvailability: {
          ...initialState.authAvailability,
          isLoading: true,
        },
      }))
      .addCase(
        checkAuthAvailability.fulfilled.toString(),
        (
          state,
          action: PayloadAction<OverDostupnostAutentifikacieResponse>,
        ) => ({
          ...state,
          authAvailability: {
            ...initialState.authAvailability,
            data: action.payload,
            error: action.payload?.chyba || null,
          },
        }),
      )
      .addCase(checkAuthAvailability.rejected.toString(), (state) => ({
        ...state,
        authAvailability: {
          ...initialState.authAvailability,
          error: strings.technickaChyba,
        },
      }));
  },
});

export const {
  initVisibilityPzsAuth,
  resetAuth,
  resetStep,
  setCreateNewDoctorAccount,
  setLoginDetails,
  setStatutoryPerson,
  storeStatutory,
  storeStep1,
  storeStep2,
  storeStep3,
  toggleVisibilityPzsAuth,
} = pzsAuthSlice.actions;

export default pzsAuthSlice.reducer;
