import IconBlueDivision from '../../CustomIcons/IconBlueDivision';
import IconBlueMinus from '../../CustomIcons/IconBlueMinus';
import IconBlueMultiplication from '../../CustomIcons/IconBlueMultiplication';
import IconBluePlus from '../../CustomIcons/IconBluePlus';
import { Sign } from '../types';

interface SignProps {
  isPrimary: boolean;
  sign: Sign;
}

const SignIcon = ({ isPrimary, sign }: SignProps) => {
  switch (sign) {
    case '+':
      return <IconBluePlus id={`plus--${sign}`} primaryColor={isPrimary} />;
    case '-':
      return <IconBlueMinus id={`minus--${sign}`} primaryColor={isPrimary} />;
    case '*':
      return <IconBlueMultiplication id={`multiplication--${sign}`} />;
    case '/':
      return <IconBlueDivision id={`division--${sign}`} />;
    default:
      return null;
  }
};

export default SignIcon;
