interface Props {
  color?: string;
  height?: number;
  id?: string;
  width?: number;
}

const IconAngleRight = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 24}
    id={id}
    viewBox="0 0 24 24"
    width={width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 18L15 12L9 6"
      stroke={color || '#1F57A5'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconAngleRight;
