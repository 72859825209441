import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import strings from '../../../../../constants/strings';
import { firstCharToUpper } from '../../../../../utils/strings.utils';
import {
  getEventTypeLabel,
  getRadioResult,
} from '../../../../../utils/spaProposals.utils';
import { formatDate } from '../../../../../utils/date.utils';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';

const texts = strings.proposals.new;

const Summary = () => {
  const {
    data: {
      formData: { data },
    },
    stepper: { step3 },
  } = useSelector((state: RootState) => state.spaProposals.new);
  const eventLabel = getEventTypeLabel(
    data?.udajParUdalIndSkup?.udalost || null,
    true,
  );
  return (
    <>
      <div className="mb-small">
        <b>{`${texts.summary.indicatorGroup}: `}</b>
        {`${step3.indicatorGroup?.kod} ${firstCharToUpper(
          step3.indicatorGroup?.nazov || '',
        )}`}
      </div>
      <div className="mb-small">
        <b>{`${texts.summary.diagnose}: `}</b>
        {`${step3.diagnose?.kod} - ${step3.diagnose?.nazov}`}
      </div>
      {step3.spaConfirmation && (
        <div className="mb-small">
          <b>{`${texts.summary.doctorConfirmation}: `}</b>
          {getRadioResult(step3.spaConfirmation)}
        </div>
      )}
      {eventLabel && (
        <div className="mb-small">
          <b>{`${eventLabel}: `}</b>
          {formatDate(step3.eventDate)}
        </div>
      )}
      <div className="mb-small">
        <b>{`${texts.summary.contraindications}: `}</b>
        {getRadioResult(step3.spaContraindications, true)}
      </div>
      <div className="mb-small">
        <b>{`${texts.summary.examinations}: `}</b>
        {getRadioResult(step3.spaExaminations)}
      </div>
      <div className="mb-small">
        <b>{`${texts.summary.svlz}: `}</b>
        {getRadioResult(step3.spaSVLZ)}
      </div>
      <div className="no-mrg">
        <b>{`${texts.summary.epicrisis}: `}</b>
        <SafeHtml html={step3.reasonDescription} wrapper="span" />
      </div>
    </>
  );
};

export default Summary;
