import { useContext, useEffect, useRef } from 'react';
import { useQuery } from '../../../hooks/useQuery';
import { FilterContext } from '../../../providers/FilterProvider';
import { FILTER_PREFIX } from '../constants';
import { Autocomplete } from '@dovera/design-system';
import { AutocompleteType } from '../types';

const AutocompleteFilter = ({ ...props }: AutocompleteType) => {
  const ref = useRef(null);
  const {
    id,
    isPrivate = true,
    label,
    onChange,
    options,
    ...restProps
  } = props;

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    if (!isPrivate) {
      const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
      if (queryValue) {
        handleChange(queryValue);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (value) => {
    if (value) {
      searchParams.set(`${FILTER_PREFIX}_${id}`, isPrivate ? true : value);
    } else if (searchParams.get(`${FILTER_PREFIX}_${id}`)) {
      searchParams.delete(`${FILTER_PREFIX}_${id}`);
    }
    setSearchParams(searchParams);
    if (onChange) onChange(value);
  };

  return (
    <Autocomplete
      ref={ref}
      addonsInside
      id={id || ''}
      inputType="input"
      label={label}
      onChange={(value) => handleChange(value)}
      options={options}
      {...restProps}
    />
  );
};

export default AutocompleteFilter;
