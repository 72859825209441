import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  epNotifications: {
    '& > div': {
      marginBottom: getRem(32),
    },
  },
  pageContent: {
    '& > div:last-child': {
      marginBottom: 0,
      width: 'fit-content',
      maxWidth: getRem(700),
    },
  },
  blueSection: {
    backgroundColor: 'rgb(232, 242, 254)',
    paddingTop: `0 !important`,
    paddingBottom: `${getRem(32)} !important`,
  },
});
