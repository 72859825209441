import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DajProfilPayload,
  DajProfilResponse,
  ZmenPrihlasovacieHesloPayload,
  ZmenPrihlasovacieHesloResponse,
  ZmenPrihlasovacieMenoPayload,
  ZmenPrihlasovacieMenoResponse,
} from '../types/api/profil';
import { createUrl } from '../utils/api.utils';
import strings from '../constants/strings';

const API_CONTROLLER = `Profil`;

const API_URL = `${window.env?.config?.apiPath}/${API_CONTROLLER}/`;

export async function dajKonaktneOsobyApi(
  idPouzivatel: number,
  idHz: number,
  idVz: number,
) {
  const url = `${API_URL}DajKontaktneOsoby`;
  const { data } = await axios.post(url, { idPouzivatel, idHz, idVz });
  return data;
}

export async function dajBankoveSpojeniaApi(
  idPouzivatel: number,
  idHz: number,
  idVz: number,
) {
  const url = `${API_URL}DajBankoveSpojenia`;
  const { data } = await axios.post(url, { idPouzivatel, idHz, idVz });
  return data;
}

export async function dajZmluvnePristrojeApi(
  idPouzivatel: number,
  idHz: number,
  idVz: number,
) {
  const url = `${API_URL}DajZmluvnePristroje`;
  const { data } = await axios.post(url, { idPouzivatel, idHz, idVz });
  return data;
}

export const fetchProfile = createAsyncThunk(
  'profil/fetchProfile',
  async (req: DajProfilPayload) => {
    const url = `${API_URL}DajProfil`;
    const { data }: AxiosResponse<DajProfilResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const changeLogin = async (
  data: ZmenPrihlasovacieMenoPayload,
): Promise<ZmenPrihlasovacieMenoResponse> => {
  try {
    const response: AxiosResponse<ZmenPrihlasovacieMenoResponse> =
      await axios.post(
        createUrl(`${API_CONTROLLER}/ZmenPrihlasovacieMeno`),
        data,
      );
    return response.data;
  } catch (err) {
    return {
      chyba: strings.defaultError,
      vysledok: null,
    };
  }
};

/** TODO: vieme nejakym sposobom vracat druh chyby? Teda rozlisit ak je nespravne aktualne heslo vs ina technicka chyba - kvoli zobrazeniu chyby */
export const changePassword = async (
  data: ZmenPrihlasovacieHesloPayload,
): Promise<ZmenPrihlasovacieHesloResponse> => {
  try {
    const response: AxiosResponse<ZmenPrihlasovacieHesloResponse> =
      await axios.post(
        createUrl(`${API_CONTROLLER}/ZmenPrihlasovacieHeslo`),
        data,
      );
    return response.data;
  } catch (err) {
    return {
      chyba: strings.defaultError,
      vysledok: null,
    };
  }
};
