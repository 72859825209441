import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { createQueryParams } from '../utils/api.utils';
import {
  DajDavkuCLQueries,
  DajDavkuCLResponse,
  DajStavKontrolCLQueries,
  DajStavKontrolCLResponse,
  DajStavSpracovaniaCLQueries,
  DajStavSpracovaniaCLResponse,
  DajZoznamDavokCLRequest,
  DajZoznamDavokCLResponse,
  OdosliDavkyCLRequest,
  OdosliDavkyCLResponse,
  SkontrolujDavkyCLRequest,
  SkontrolujDavkyCLResponse,
  VymazDavkuCLQueries,
  VymazDavkuCLResponse,
} from '../types/cakacky.types';

const API_CONTROLLER = `CakacieListiny`;

const API_URL = `${window.env?.config?.apiPath}/${API_CONTROLLER}/`;

export const skontrolujCLDavky = createAsyncThunk(
  'cakacie-listiny/skontrolujCLDavky',
  async (request: SkontrolujDavkyCLRequest) => {
    const { data }: AxiosResponse<SkontrolujDavkyCLResponse> = await axios.post(
      `${API_URL}SkontrolujDavkyCL`,
      request,
    );
    return data;
  },
);

export const dajStavKontrolCL = createAsyncThunk(
  'cakacie-listiny/stavKontrolCL',
  async (queries: DajStavKontrolCLQueries) => {
    const { data }: AxiosResponse<DajStavKontrolCLResponse> = await axios.get(
      `${API_URL}DajStavKontrolCL${createQueryParams({
        withoutEmpty: false,
        queryParams: {
          ...queries,
        },
      })}`,
    );
    return data;
  },
);

/**
 *
 * pri zmene datumOd / datumDo - stale prevolavat sluzbu
 * kaskaday naparovat na zaklade riadok + poradieVRiadku
 * konverzia stavov - ak je davka N a nema odpovednu, stav ==> Spracovava sa, stavPrevzatia = false ==> Na stiahnutie, stavPrevzatia = true ==> Vybavena
 * @param queries
 * @returns
 */
export const dajZoznamCakaciek = createAsyncThunk(
  'cakacie-listiny/dajZoznamCakaciek',
  async (queries: DajZoznamDavokCLRequest) => {
    const { data }: AxiosResponse<DajZoznamDavokCLResponse> = await axios.get(
      `${API_URL}DajZoznamDavokCL${createQueryParams({
        withoutEmpty: false,
        queryParams: {
          ...queries,
        },
      })}`,
    );
    return data;
  },
);

export const getLastBatch = async () => {
  const queries: DajZoznamDavokCLRequest = { dajPoslednu: true };
  const { data }: AxiosResponse<DajZoznamDavokCLResponse> = await axios.get(
    `${API_URL}DajZoznamDavokCL${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};

export const odosliDavkyCL = async (request: OdosliDavkyCLRequest) => {
  const { data }: AxiosResponse<OdosliDavkyCLResponse> = await axios.post(
    `${API_URL}OdosliDavkyCL`,
    request,
  );
  return data;
};

export const dajDavkuCL = async (queries: DajDavkuCLQueries) => {
  const { data }: AxiosResponse<DajDavkuCLResponse> = await axios.get(
    `${API_URL}DajDavkuCL${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};

export const dajStavSpracovaniaCL = async (
  queries: DajStavSpracovaniaCLQueries,
) => {
  const { data }: AxiosResponse<DajStavSpracovaniaCLResponse> = await axios.get(
    `${API_URL}DajStavSpracovaniaCL${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};

export const vymazDavkuCL = async (queries: VymazDavkuCLQueries) => {
  const { data }: AxiosResponse<VymazDavkuCLResponse> = await axios.delete(
    `${API_URL}VymazDavkuCL${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};
