import { createUseStyles } from 'react-jss';
import { color } from '../../../constants';
import { breakpoints, getRem } from '@dovera/design-system';

interface StylesProps {
  active?: boolean;
  completed?: boolean;
  error?: boolean;
  withoutEdit?: boolean;
}

export default createUseStyles({
  root: ({ withoutEdit }: StylesProps) => ({
    position: 'relative',
    display: 'flex',
    cursor: withoutEdit ? 'initial' : 'pointer',
    alignItems: 'center',
    '&$active *': {
      color: color('secondary'),
    },
    '&$completed *': {
      color: color('secondary'),
    },
    '&$warning *': {
      color: color('warning'),
    },
    '&$disabled *': {
      color: color('secondary', 500),
      cursor: 'default',
    },
    '&$error *': {
      color: color('error'),
    },
  }),
  header: ({ active, completed, error }: StylesProps) => ({
    marginLeft: getRem(16),
    marginBottom: 0,
    marginRight: getRem(20),
    fontWeight: !active && !completed && !error && 100,
    fontSize: getRem(20),
  }),
  headerContainer: {
    width: '100%',
  },
  iconWrapper: {
    marginRight: 16,
  },
  active: {
    color: color('secondary', 500),
  },
  completed: {
    color: color('primary', 500),
  },
  warning: {
    color: color('warning'),
  },
  error: {},
  disabled: {
    color: color('secondary', 500),
  },
  editBtn: {
    marginLeft: getRem(32),
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    left: getRem(453),
    top: getRem(12),
    '& svg': {
      marginRight: getRem(12),
    },
    '& span': {
      textDecoration: 'underline',
      color: `${color('primary', 600)} !important`,
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      position: 'relative',
      left: 0,
      top: 0,
      '& span': {
        display: 'none',
      },
    },
  },
});
