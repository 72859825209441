interface Props {
  id: string;
}

const IconMenuAngle = ({ id }: Props) => (
  <svg
    fill="none"
    height="16"
    id={id}
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1767 10.7071C12.5672 11.0976 13.2004 11.0976 13.5909 10.7071C13.9814 10.3166 13.9814 9.68342 13.5909 9.29289L9.5909 5.29289C9.20037 4.90237 8.56721 4.90237 8.17668 5.29289L4.17668 9.29289C3.78616 9.68342 3.78616 10.3166 4.17668 10.7071C4.56721 11.0976 5.20037 11.0976 5.5909 10.7071L8.88379 7.41421L12.1767 10.7071Z"
      fill="white"
    />
  </svg>
);

export default IconMenuAngle;
