import { ReactNode } from 'react';
import useStyles from './Navigation.styles';
import { cx } from '../../utils/exports';
import IconChevronDown from '../CustomIcons/IconChevronDown';
import { useLocation } from 'react-router';
import { useWindowSize } from '../../hooks/useWindowSize';
import { IconCloseMenu } from '../CustomIcons';
import { Icon, breakpoints } from '@dovera/design-system';
import { Link } from 'react-router-dom';
import routes, { diagnozyRoutes } from '../../routes';
import { useAuthContext } from '../../hooks/useAuthContext';
import NavigationPZSPanel from '../NavigationPZSPanel/NavigationPZSPanel';
import { ExpertisesCombo } from '../SelectOdbornosti';
import {
  inlineCondition,
  inlineStrCondition,
  safeNode,
} from '../../utils/app.utils';

export interface NavigationProps {
  hasChildren?: boolean;
  isNotVisible?: boolean;
  isOpen?: boolean;
  items?: NavigationProps[];
  onClick?: () => void;
  route?: string;
  title: string | ReactNode;
  withoutAngle?: boolean;
}

interface Props {
  items: NavigationProps[];
  mobileOpen: boolean;
  onClose: () => void;
}

const Navigation = ({ items, mobileOpen, onClose }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const windowSize = useWindowSize();
  const authContext = useAuthContext();
  const renderItem = (data: NavigationProps, treeOrder: number) => {
    const keyTitle =
      typeof data.title !== 'string'
        ? // @ts-ignore
          data.title?.props?.children[
            // @ts-ignore
            Number(data.title?.props?.children?.length) - 1
          ]
        : data.title;
    const iconSize: number = Number(inlineCondition(treeOrder === 1, 24, 20));

    return (
      <ul
        key={`${data.route}--${keyTitle}--${treeOrder}-aaa`}
        className={cx(
          classes.item,
          `navigation_order__order${treeOrder}`,
          data.isOpen && 'navigation_order__open',
        )}
      >
        <li>
          {data.route ? (
            <Link
              className={cx(
                typeof data.title !== 'string' && 'hasIcon',
                ((location.pathname === data.route &&
                  data.route === routes.dashboard) ||
                  (location.pathname !== routes.dashboard &&
                    data.route !== routes.dashboard &&
                    location.pathname === data.route) ||
                  (data.route.includes('?') &&
                    location.search.includes(data.route.split('?')[1])) ||
                  (data.route.includes(diagnozyRoutes.index) &&
                    location.pathname.includes(diagnozyRoutes.index)) ||
                  (data.route.includes(routes.zmluvyDodatky) &&
                    location.pathname.includes(routes.zmluvyDodatky)) ||
                  (data.route.includes(routes.profil) &&
                    location.pathname.includes(routes.profil)) ||
                  (location.pathname.includes(
                    routes.poistenci.split(':page')[0],
                  ) &&
                    data.route.includes(routes.sporniPoistenci)) ||
                  (location.pathname.includes(routes.prehladDavok) &&
                    data.route === routes.prehladDavok) ||
                  ((location.pathname.includes(routes.odoslanieDavok) ||
                    location.pathname.includes(routes.rozdelenieDavok) ||
                    location.pathname.includes(
                      routes.vysledokOdoslaniaDavok,
                    )) &&
                    data.route === routes.odoslanieDavok) ||
                  (location.pathname.includes(routes.faktury) &&
                    data.route === routes.faktury)) &&
                  'active',
                !data.hasChildren && 'no-children',
              )}
              onClick={() => {
                if (data.onClick) data.onClick();
              }}
              to={data.route}
            >
              {data.title}
              {data.hasChildren && !data.withoutAngle && (
                <span
                  className={cx(
                    classes.angle,
                    treeOrder !== 1 && 'angle_order',
                    data.isOpen && 'active',
                  )}
                >
                  <IconChevronDown
                    height={iconSize}
                    id={`icon--menuAngle-${treeOrder}`}
                    width={iconSize}
                  />
                </span>
              )}
            </Link>
          ) : (
            <button
              className={cx(
                typeof data.title !== 'string' && 'hasIcon',
                data.withoutAngle && 'inactive',
              )}
              onClick={() => {
                if (data.onClick) data.onClick();
              }}
              type="button"
            >
              {data.title}
              {data.hasChildren && !data.withoutAngle && (
                <span
                  className={cx(
                    classes.angle,
                    treeOrder !== 1 && 'angle_order',
                    data.isOpen && 'active',
                  )}
                >
                  <IconChevronDown
                    height={iconSize}
                    id={`icon--menuAngle-${treeOrder}`}
                    width={iconSize}
                  />
                </span>
              )}
            </button>
          )}

          {data.items && data.items.length ? (
            data.items.map(
              (i) => !i.isNotVisible && renderItem(i, treeOrder + 1),
            )
          ) : (
            <span />
          )}
        </li>
      </ul>
    );
  };
  const renderLogout = safeNode(
    <button
      className={classes.logout}
      onClick={() => authContext.logout(routes.logout)}
      type="button"
    >
      <Icon color="#BED2F4" name="log-out" size="medium" />
      Odhlásiť sa
    </button>,
    windowSize.width < breakpoints.l,
  );
  return (
    <div
      className={classes.navigationWrapper}
      style={{
        right: 0,
        transform:
          windowSize.width <= breakpoints.l
            ? inlineStrCondition(
                mobileOpen,
                'translateX(0px)',
                'translateX(340px)',
              )
            : 'none',
      }}
    >
      <div className={classes.navigation}>
        {windowSize.width <= breakpoints.l && (
          <button
            className={classes.closeMenu}
            id="menu-close"
            onClick={onClose}
            type="button"
          >
            <IconCloseMenu id="icon-close-menu" />
          </button>
        )}
        {windowSize.width <= breakpoints.l && <NavigationPZSPanel />}
        {windowSize.width <= breakpoints.l && <ExpertisesCombo />}
        {!!items?.length &&
          items.map((i) => !i.isNotVisible && renderItem(i, 1))}
        {renderLogout}
      </div>
    </div>
  );
};

export default Navigation;
