import { breakpoints, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  isRelative?: boolean;
}

export default createUseStyles({
  exportReport: ({ isRelative }: StylesProps) => ({
    position: isRelative ? 'relative' : 'absolute',
    top: isRelative ? 'auto' : getRem(60),
    right: isRelative ? 'auto' : getRem(4),
  }),
  common: {
    '& button': {
      '& svg': {
        marginLeft: `${getRem(12)} !important`,
      },
      '& > div': {
        margin: `0 !important`,
        '& > div': {
          marginLeft: `${getRem(14)} !important`,
          marginRight: `${getRem(2)} !important`,
        },
      },
    },
  },
  responsive: {
    '&': {
      '& button': {
        marginBottom: 0,
      },
      [`@media screen and (max-width: 1400px)`]: {
        top: 'auto',
        bottom: getRem(-13),
      },
      [`@media screen and (max-width: ${breakpoints.m}px)`]: {
        top: 'auto',
        bottom: getRem(-25),
        right: 'auto',
      },
      [`@media screen and (min-width: 1400px)`]: {
        '& button': {
          marginBottom: 0,
        },
      },
    },
  },
  responsiveRelative: {
    '&': {
      '& button': {
        marginBottom: getRem(12),
      },
      [`@media screen and (max-width: 1400px)`]: {
        top: 'auto',
        bottom: 0,
      },
      [`@media screen and (max-width: ${breakpoints.m}px)`]: {
        top: 'auto',
        bottom: 0,
        right: 'auto',
      },
      [`@media screen and (min-width: 1400px)`]: {
        '& button': {
          marginBottom: getRem(16),
        },
      },
    },
  },
});
