import { createUseStyles } from 'react-jss';
import { color } from '../../constants';
import { breakpoints, getRem } from '@dovera/design-system';

export default createUseStyles({
  root: {
    margin: '64px auto',
    '&.fullHeight': {
      marginBottom: 0,
      paddingBottom: 64,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2rem 0 0',
    '& > div': {
      width: '100%',
      '& h3, & p.text-center': {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  stepper: {
    '& .step': {
      '& .step__number': {
        position: 'relative',
        '&:after': {
          content: '"."',
          position: 'absolute',
          right: 15,
        },
      },
    },
    '& ol': {
      listStyle: 'none',
    },
  },
  step1: {
    width: 'auto !important',
  },
  mainCheckbox: {
    '& input, & label.radiocheck__control': {
      position: 'absolute',
      zIndex: 2,
      right: 30,
      top: 30,
    },
    '& label': {
      margin: 0,
    },
    '& .card__section': {
      display: 'flex',
      '& img': {
        width: 63,
        height: 63,
      },
      '& div:last-child': {
        paddingLeft: 24,
      },
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      '& input, & label.radiocheck__control': {
        right: 15,
        top: 15,
      },
      '& .card__section': {
        '& img': {
          width: '30px !important',
          height: '30px !important',
        },
      },
    },
  },
  authDifferentPosition: {
    maxWidth: 382,
  },
  buttonLayout: {
    margin: '3rem 0 2rem',
    '&.mt-small': {
      marginTop: '1rem',
    },
  },
  authNotification: {
    display: 'flex',
    width: 'fit-content',
    margin: '48px auto -16px',
    '& > div': {
      marginBottom: 0,
    },
    '&.classic': {
      margin: '0 auto 32px',
    },
  },
  defaultNotification: {
    display: 'flex',
    width: 'fit-content',
    '& > div': {
      width: 'fit-content',
      marginBottom: 0,
      '& a': {
        textDecoration: 'underline',
      },
    },
  },
  warningCheckbox: {
    '& input:checked ~ label.radiocheck__control': {
      background: color('warning'),
      borderColor: color('warning'),
    },
    '&:hover': {
      '& input:checked ~ label.radiocheck__control': {
        borderColor: `${color('warning')} !important`,
      },
    },
  },
  step3Wrapper: {
    paddingLeft: '8rem',
    paddingRight: '8rem',
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  stepResultWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    '& p': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& img': {
      display: 'block',
      marginBottom: 32,
      maxWidth: 313,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  expertise: {
    display: 'inline-flex',
    border: `1px solid ${color('grey', 100)}`,
    color: `${color('grey', 500)}`,
    borderRadius: 8,
    padding: `${getRem(3)} ${getRem(7)}`,
    marginRight: getRem(16),
    marginBottom: getRem(12),
  },
  expertisesWrapper: {
    '& > div': {
      '& > span': {
        marginRight: getRem(12),
      },
      '& svg': {
        verticalAlign: 'middle',
        marginTop: getRem(-5),
      },
    },
    '& + button': {
      minHeight: 0,
    },
  },
  stepLoginWrapper: {
    maxWidth: '80%',
    alignItems: 'center',
    margin: '0 auto',
    '& .form-control--input': {
      width: getRem(411),
      maxWidth: '100%',
    },
    [`@media screen and (max-width: 1270px)`]: {
      maxWidth: '100%',
    },
  },
  notifiactionMailHk: {
    flexDirection: 'row !important',
    display: 'flex',
    '& svg': {
      width: 40,
      marginRight: 8,
    },
  },
  mailToHK: {
    width: 250,
    display: 'inline-flex',
  },
  passwordWithWarning: {
    display: 'flex',
    alignItems: 'flex-end',
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  passwordWarningNotif: {
    marginLeft: getRem(32),
    maxWidth: getRem(411),
    '& > div': {
      marginBottom: 0,
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      marginLeft: 0,
      '& > div': {
        marginBottom: getRem(32),
      },
    },
  },
});
