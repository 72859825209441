import { color, colors, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  inactiveTab: {
    color: colors.error[500],
  },
  documentsInvoiceWrapper: {
    paddingBottom: getRem(32),
    '& button': {
      display: 'block',
    },
  },
  invoiceDispBtn: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: getRem(24),
    border: `1px solid ${color('grey', 100)}`,
    borderRadius: 8,
    boxShadow: `0px 4px 12px 0px #EDEEEECC`,
    textAlign: 'left',
    marginBottom: `${getRem(32)} !important`,
  },
  zucTableWrapper: {
    maxWidth: getRem(830),
  },
});
