import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PomocnikState } from '../types/pomocnik.types';

export const initialState: PomocnikState = {
  open: false,
  openedMenu: false,
  openedItem: '',
  expanded: false,
  scrollingDown: false,
  feedback: {
    email: '',
    rating: 0,
    text: '',
    // attachments: [],
  },
};

const pomocnikSlice = createSlice({
  name: 'pomocnik',
  initialState,
  reducers: {
    openPomocnik(state) {
      const isOpen = !state.open;
      return {
        ...state,
        open: isOpen,
        openedItem: '',
        openedMenu: !isOpen ? false : state.openedMenu,
        scrollingDown: false,
      };
    },
    openPomocnikMenu(state) {
      return {
        ...state,
        openedMenu: !state.openedMenu,
      };
    },
    openPomocnikMenuItem(state, action: PayloadAction<string>) {
      return {
        ...state,
        openedItem: action.payload,
      };
    },
    resetPomocnikState() {
      return initialState;
    },
    scrollingDown(
      state,
      action: PayloadAction<{
        scrollingDown: boolean;
      }>,
    ) {
      return {
        ...state,
        scrollingDown: action.payload.scrollingDown,
      };
    },
  },
});

export const {
  openPomocnik,
  openPomocnikMenu,
  openPomocnikMenuItem,
  resetPomocnikState,
  scrollingDown,
} = pomocnikSlice.actions;

export default pomocnikSlice.reducer;
