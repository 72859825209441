/* eslint-disable */
const regex = {
  EMAIL:
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,100})$/i,
  PHONE: /^((\+|00)420|(\+|00)421|0)? ?[6|7|9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/i,
  PHONE_POZ:
    /^((\+|00)[0-9]{2,3}|[0-9])? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3,}$/i,
  PEVNA_LINKA:
    /^((\+|00)420|(\+|00)421|0)? ?[0-9]([0-9])? ?[0-9]{3} ?[0-9]{4}$/i,
  PEVNA_LINKA_POZ: /^((\+|00)[0-9]{2,3}|[0-9]) ?[0-9]{1,2} ?[0-9]{7,}$/i,
  RECALCULATE_MV:
    /sumaPrijem|sumaPrijemZoZamestnania|sumaOdpocitatelnejPolozky|sumaVymeriavaciZaklad|sadzbaPoistenec|sadzbaPlatitel|sumaKurzarbeit/,
  RECALCULATE_OP: /sumaPrijemZoZamestnania|pocetDni|sumaPrijem/,
  CURRENCY:
    /^[\-]?(\s{0,500}\d{1,150}([\.]\d{0,500})?\s{0,500}[\/]?\s{0,500}){1,500}$/,
  LETTERS_ONLY: /^[A-Za-zÀ-ȕ.,\- \\]*$/,
  KOD_ID: /^[a-zA-Z0-9]*$/,
  LOGIN_CHARACTER: /^[A-Za-z0-9@._,*]+$/,
  LOGIN_LENGTH: /^[A-Za-z0-9@._,*]{2,100}$/,
  PASSWORD: /^(?=[.\S]*\d)(?=[.\S]*[a-z])(?=[.\S]*[A-Z])[.\S]{6,128}$/,
  RODNE_CISLO: /[0-9]{2}[0-9][0-9][0-9]{2}?[0-9]{3,4}/,
  TEXTAREA: /[0-9a-zA-Z]{1,}/,
  POPISNE_CISLO: /^[0-9a-zA-Z\-\/]*$/,
  DOCTOR_CODE: /^[A-Z][0-9]{5}$/,
  NUMBERS_ONLY: /^\d+$/,
  SQL_INJECTION:
    /\b(ALTER|CREATE|DELETE|DROP|EXEC(UTE){0,1}|INSERT( +INTO){0,1}|MERGE|SELECT|UPDATE|UNION( +ALL){0,1}|CHAR|NCHAR|VARCHAR|NVARCHAR|BEGIN|CURSOR|CAST|DECLARE|SELECT|FETCH|KILL|SYSOBJECTS|SYSCOLUMNS|TABLE)\s/,
};

export default regex;
