import {
  Container,
  Grid,
  GridCol,
  PageSection,
  Skeleton,
} from '@dovera/design-system';
import StaticMenuLayout from '../../layouts/StaticMenuLayout';

interface Props {
  withoutLayout?: boolean;
}

const AppPreloaderSkeleton = ({ withoutLayout }: Props) => {
  const renderContent = (
    <Container>
      <h1 className="h1 mb-small">
        <Skeleton width="50%" />
      </h1>
      <p className="mb-small">
        <Skeleton width="100%" />
      </p>
      <p className="mb-small">
        <Skeleton width="100%" />
      </p>
      <Grid>
        <GridCol className="mb-small" size={12}>
          <Skeleton width="80%" />
        </GridCol>
        <GridCol className="mb-small" size={12}>
          <Skeleton width="80%" />
        </GridCol>
        <GridCol size={12}>
          <Skeleton width="80%" />
        </GridCol>
      </Grid>
    </Container>
  );
  if (withoutLayout) return renderContent;
  return (
    <StaticMenuLayout>
      <PageSection space="small">{renderContent}</PageSection>
    </StaticMenuLayout>
  );
};

export default AppPreloaderSkeleton;
