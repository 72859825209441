/* eslint-disable */
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import '../node_modules/@dovera/design-system/dist/style.css';
import '../node_modules/@dovera/design-system/dist/polyfills';
import 'core-js/es';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as serviceWorker from './serviceWorker';
import './globalStyles';
import store from './store';

const render = () => {
  const App = require('./App').default;
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root') as HTMLElement,
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
