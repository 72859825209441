import SafeHtml from '../SafeHtml/SafeHtml';
import Structure from './objects/Structure';
import { StructureType } from './types';

interface Props {
  html?: string;
  structure?: StructureType;
}

const Parametrization = ({ html, structure }: Props) => {
  if (html) return <SafeHtml html={html} wrapper="div" />;
  if (structure) return <Structure props={structure} />;
  return null;
};

export default Parametrization;
