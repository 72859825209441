import useStyles from './AppError.styles';
import { Button } from '@dovera/design-system';

const AppErrorIE = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.root} id="notSupportedBrowser">
          <img
            alt="Warning"
            className="notSupportedBrowsers--body--warning"
            src="unsupportedBrowsers/img-warning.png"
          />
          <h4 className="mb-xsmall text-color-warning">
            Požadovaná stránka nie je dostupná v prehliadači Internet Explorer
          </h4>
          <p>
            Táto sekcia nie je dostupná v prehliadači Internet Explorer. Pre
            zobrazenie tejto sekcie, použite alebo stiahnite si aktuálnu verziu
            niektorého z podporovaných prehliadačov.
          </p>
          <div className={classes.browsers}>
            <a
              href="https://www.google.com/intl/sk_sk/chrome/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="Chrome" src="unsupportedBrowsers/icons/chrome.png" />
              <p>Chrome</p>
            </a>
            <a
              href="https://www.mozilla.org/sk/firefox/new/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="Firefox" src="unsupportedBrowsers/icons/firefox.png" />
              <p>Firefox</p>
            </a>
            <a
              href="https://support.microsoft.com/sk-sk/help/17621/internet-explorer-downloads"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="Edge" src="unsupportedBrowsers/icons/ie.png" />
              <p>Edge</p>
            </a>
            <a
              href="https://support.apple.com/downloads/safari"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="Safari" src="unsupportedBrowsers/icons/safari.png" />
              <p>Safari</p>
            </a>
          </div>
        </div>
      </div>
      <Button
        className={classes.button}
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Späť na úvodnú stránku
      </Button>
    </>
  );
};

export default AppErrorIE;
