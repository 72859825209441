import { ButtonLink, color } from '@dovera/design-system';
import SolveRow from '../../../../components/SolveRow/SolveRow';
import routes from '../../../../routes';
import { getSolveWidgetData } from '../../../../utils/dashboard.utils';
import { cx } from '../../../../utils/exports';
import { formatNameStr, hotjarMasking } from '../../../../utils/strings.utils';
import { createSearchParams, useNavigate } from 'react-router-dom';
import IconAngleRight from '../../../../components/CustomIcons/IconAngleRight';
import { canShowSection, createViewUrl } from '../../../../utils/app.utils';
import IconAttachment from '../../../../components/CustomIcons/IconAttachment';
import useStyles from '../NaRiesenieWidget.styles';

const Poistenci = () => {
  const data = getSolveWidgetData('poistenci');
  const navigate = useNavigate();
  const classes = useStyles();
  if (!data || !canShowSection('KAP')) return <span />;
  return (
    <SolveRow
      countToSolve={data.pocetNaRiesenie}
      description={data.popis}
      showAll={
        data.polozkyNaRiesenie.length > 3
          ? {
              route: routes.sporniPoistenci,
              title: 'Zobraziť všetky',
            }
          : null
      }
      state={data.typIkona}
      title={data.nadpis}
    >
      {data.polozkyNaRiesenie.map(
        (p, key) =>
          key < 3 && (
            <div
              key={`naRiesenie-${p.nadpis}-${p.naRiesenieId}`}
              className={cx(classes.kapitacieRow, 'child-row')}
            >
              <div>
                <div>{hotjarMasking(formatNameStr(p.nadpis))}</div>
              </div>
              <div>
                {Number(p.hodnota3) !== 0 && (
                  <ButtonLink
                    onClick={() =>
                      navigate({
                        pathname: routes.sporniPoistenci,
                        search: `${createSearchParams({
                          akcia: 'V',
                          idDohody: p.hodnota1 || '',
                          idDohodyCudzi: p.hodnota2 || '',
                        })}`,
                      })
                    }
                  >
                    Vyradiť
                    <IconAngleRight
                      color={color('primary', 600)}
                      height={16}
                      id={`icon-angle-right--${p.hodnota1}`}
                      width={16}
                    />
                  </ButtonLink>
                )}
                {Number(p.hodnota4) !== 0 && (
                  <ButtonLink
                    onClick={() =>
                      navigate(
                        createViewUrl(routes.sporniPoistenci, {
                          akcia: 'P',
                          idDohody: p.hodnota1 || '',
                          idDohodyCudzi: p.hodnota2 || '',
                        }),
                      )
                    }
                  >
                    Ponechať
                    <IconAttachment id={`icon-attachment--${p.hodnota1}`} />
                  </ButtonLink>
                )}
              </div>
            </div>
          ),
      )}
    </SolveRow>
  );
};

export default Poistenci;
