const IconWarningCircleStepper = ({ id }) => (
  <svg
    fill="none"
    height="50"
    id={id}
    viewBox="0 0 50 50"
    width="50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#FF9800" height="50" rx="25" width="50" />
    <path
      d="M22.5 11V21"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
    />
    <circle cx="22.5" cy="31" fill="white" r="2.5" />
  </svg>
);

export default IconWarningCircleStepper;
