interface Props {
  active?: boolean;
  id: string;
}

const IconDashboardDiagnose = ({ active, id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="16"
      cy="16"
      fill={active ? '#B924FF' : '#C4C4C4'}
      rx="16"
      ry="16"
    />
    <path
      d="M12.6667 8.5V10.1667H11V13.5C11 15.3417 12.4917 16.8333 14.3333 16.8333C16.175 16.8333 17.6667 15.3417 17.6667 13.5V10.1667H16V8.5H18.5C18.721 8.5 18.933 8.5878 19.0893 8.74408C19.2455 8.90036 19.3333 9.11232 19.3333 9.33333V13.5C19.3332 14.6816 18.9147 15.8251 18.152 16.7276C17.3893 17.6302 16.3317 18.2336 15.1667 18.4308V19.75C15.1662 20.433 15.4055 21.0945 15.8427 21.6192C16.28 22.1439 16.8876 22.4985 17.5595 22.6212C18.2314 22.7439 18.925 22.6268 19.5195 22.2905C20.114 21.9542 20.5716 21.4199 20.8125 20.7808C20.2763 20.5404 19.8419 20.1187 19.5857 19.5898C19.3294 19.061 19.2677 18.4587 19.4113 17.8889C19.5548 17.319 19.8946 16.8179 20.3709 16.4737C20.8471 16.1294 21.4294 15.9639 22.0156 16.0062C22.6017 16.0485 23.1542 16.296 23.5761 16.7052C23.9979 17.1143 24.2621 17.659 24.3223 18.2436C24.3825 18.8282 24.2349 19.4153 23.9053 19.9018C23.5757 20.3884 23.0852 20.7433 22.52 20.9042C22.2401 21.984 21.5759 22.9246 20.6521 23.5498C19.7282 24.175 18.6081 24.4418 17.5016 24.3003C16.3951 24.1587 15.3783 23.6184 14.6416 22.7807C13.9049 21.9431 13.499 20.8655 13.5 19.75V18.4308C12.3349 18.2337 11.2772 17.6304 10.5145 16.7278C9.75177 15.8252 9.33333 14.6817 9.33334 13.5V9.33333C9.33334 9.11232 9.42114 8.90036 9.57742 8.74408C9.7337 8.5878 9.94566 8.5 10.1667 8.5H12.6667ZM21.8333 17.6667C21.6123 17.6667 21.4004 17.7545 21.2441 17.9107C21.0878 18.067 21 18.279 21 18.5C21 18.721 21.0878 18.933 21.2441 19.0893C21.4004 19.2455 21.6123 19.3333 21.8333 19.3333C22.0544 19.3333 22.2663 19.2455 22.4226 19.0893C22.5789 18.933 22.6667 18.721 22.6667 18.5C22.6667 18.279 22.5789 18.067 22.4226 17.9107C22.2663 17.7545 22.0544 17.6667 21.8333 17.6667Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default IconDashboardDiagnose;
