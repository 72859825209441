import {
  GetActualPricesResponse,
  GetExchangeParametersResponse,
  GetExperisePeriods,
  GetParameterDetailResponse,
  GetParametersResponse,
  SaveSelectedParametersResponse,
  UlozParameterHodnoteniaResponse,
} from '../../types/parameters.types';
import { convertGroupShortcut } from '../../utils/parameters.utils';
import {
  GetActualPricesSK,
  GetExchangeParametersSK,
  GetExpertisePeriodsSK,
  GetParameterDetailSK,
  GetParametersSK,
} from '../apiTypes/parameters.types';

export const convertGetExpertisePeriods = (
  data: GetExpertisePeriodsSK,
): GetExperisePeriods => ({
  periods: data.obdobiaPreOdbornostAmb.map((period) => ({
    codePeriodType: period.kodTypObdobie,
    cycleId: period.idPZSOdbCyklus,
    isActiveAction: period.jeAktivnaAkcia,
    order: period.poradie,
    periodDescription: period.popisObdobie,
    periodTitle: period.nadpisObdobie,
  })),
});

export const convertGetActualPrices = (
  data: GetActualPricesSK,
): GetActualPricesResponse => ({
  id: data.id,
  isOutlier: data.jeOutlier,
  periodFrom: data.obdobieOd,
  periodTo: data.obdobieDo,
  values: data.poleHodnot.map((value) => ({
    value: value.hodnota,
    valueName: value.nazovHodnoty,
  })),
});

export const convertGetParameters = (
  data: GetParametersSK,
): GetParametersResponse => ({
  banners:
    data.vysledokParametra.banery?.map((b) => ({
      image: b.obrazok,
      link: b.link,
      linkText: b.linkText,
      text: b.text,
      title: b.nadpis,
      type: b.typ,
    })) || [],
  chartContent: data.vysledokParametra.obsahGraf?.map((c) => ({
    data: c.data.map((d) => {
      const { color, hoveredColor, title } = convertGroupShortcut(d?.type);
      return {
        color,
        hoveredColor,
        title,
        value: d.value,
      };
    }),
    name: c.title,
    value: c.value,
  })),
  contentTitleParameter: {
    description: data.vysledokParametra.obsahNadpisParam?.text,
    subTitle: data.vysledokParametra.obsahNadpisParam?.subtitle,
    title: data.vysledokParametra.obsahNadpisParam?.title,
    tooltip: data.vysledokParametra.obsahNadpisParam?.tooltip,
  },
  cycleId: data.vysledokParametra.idCyklus,
  evaluationDetail: data.vysledokParametra.detailVyhodnotenia?.map((d) => ({
    attribute: {
      formula: d.attributes.formula,
      formulaType: d.attributes.formulaType,
      value: d.attributes.value,
      formulaValues: d.attributes.formulaValues?.map((v) => ({
        value: v.value,
        variable: v.variable,
        text: v.text,
      })),
      text: d.attributes.text,
      text1: d.attributes.text1,
      title: d.attributes.title,
    },
    order: d.order,
    type: d.type,
  })),
  id: data.vysledokParametra.id,
  parameterGroups: data.vysledokParametra.skupinyParametrov?.map((group) => ({
    description: group.popis,
    groupType: group.typSkupina,
    groupId: group.idSkupina,
    order: group.poradie,
    parameters: group.parametre?.map((p) => ({
      averageValue: p.priemernaHodnota,
      maxValue: p.hranicnaHodnotaMax,
      minValue: p.hranicnaHodnotaMin,
      cardContent: {
        description: p.obsahKartaParam.text,
        name: p.obsahKartaParam.name,
        parameterCode: p.obsahKartaParam.code,
        state: p.obsahKartaParam.status,
        value: p.obsahKartaParam.value,
      },
      positionHCP: p.poziciaPzs,
      valueType: p.typHodnoty,
      resultParamId: p.idVysledokParam,
      order: p.poradie,
      value: p.hodnota,
      visibleInMenu: p.priznakMenu,
      weight: p.vaha,
    })),
    value: group.hodnota,
  })),
  periodCalculationFrom: data.vysledokParametra.obdobieVypocetOd,
  periodCalculationTo: data.vysledokParametra.obdobieVypocetDo,
  priceContent: {
    data: data.vysledokParametra.obsahCeny?.map((d) => ({
      title: d.text,
      value: d.title,
    })),
    tips: {
      text: data.vysledokParametra.tipy?.text,
      title: data.vysledokParametra.tipy?.title,
    },
  },
});

export const convertGetExchangeParameters = (
  data: GetExchangeParametersSK,
): GetExchangeParametersResponse => ({
  contentTitleParameter: {
    description: data.obsahNadpisParam?.text,
    subTitle: data.obsahNadpisParam?.subtitle,
    title: data.obsahNadpisParam?.title,
  },
  endAction: data.koniecAkcie,
  hasSelected: data.maVybraneParametre,
  parameterGroups: data.skupinyParametra?.map((sp) => ({
    amountAll: sp.mnozstvoCelkove,
    amountMax: sp.mnozstvoMax,
    amountMin: sp.mnozstvoMin,
    groupId: sp.idSkupina,
    groupType: sp.typSkupina,
    order: sp.poradie,
    parameters: sp.parametre?.map((p) => ({
      parameterId: p.idParametra,
      order: p.poradie,
      cardContent: {
        description: p.obsahKartaParam.text,
        name: p.obsahKartaParam.name,
        parameterCode: p.obsahKartaParam.code,
        state: '',
        tags: [...(p.obsahKartaParam?.tags || [])],
        value: '',
      },
      resultParamId: 0,
      required: p.povinny,
      dataForExchange: {
        default: p.dataVyberuParametrov.predvolene,
        exchangeGroupId: p.dataVyberuParametrov.idSkupinyVymeny,
        exchangeType: p.dataVyberuParametrov.typVymena,
        originalSelectedValue: p.dataVyberuParametrov.vybrane,
        selectionType: p.dataVyberuParametrov.typVyberu,
        selected: p.dataVyberuParametrov.vybrane,
      },
    })),
  })),
});

export const convertGetParameterDetail = (
  data: GetParameterDetailSK,
): GetParameterDetailResponse => ({
  detail: data.obsahStrankaParam,
});

export const convertSaveParametersSelection = (
  data: UlozParameterHodnoteniaResponse,
): SaveSelectedParametersResponse => ({
  error: data.chyba,
  result: data.vysledok,
});
