import { color as ddsColor } from '@dovera/design-system';

interface Props {
  color?: string;
  height?: number;
  id: string;
  width?: number;
}

const IconHelp = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 24}
    id={id}
    viewBox={`0 0 ${width || 24} ${height || 24}`}
    width={width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.4286 5.35714C8.52313 5.35714 5.35714 8.52313 5.35714 12.4286C5.35714 16.334 8.52313 19.5 12.4286 19.5C16.334 19.5 19.5 16.334 19.5 12.4286C19.5 8.52313 16.334 5.35714 12.4286 5.35714ZM3 12.4286C3 7.22132 7.22132 3 12.4286 3C17.6358 3 21.8571 7.22132 21.8571 12.4286C21.8571 17.6358 17.6358 21.8571 12.4286 21.8571C7.22132 21.8571 3 17.6358 3 12.4286ZM12.5633 9.69247C12.289 9.64542 12.0069 9.69697 11.7669 9.83799C11.5269 9.97902 11.3446 10.2004 11.2523 10.463C11.0363 11.077 10.3634 11.3997 9.74938 11.1837C9.13536 10.9677 8.8127 10.2948 9.0287 9.68077C9.30579 8.8931 9.8527 8.2289 10.5726 7.80582C11.2924 7.38275 12.1388 7.2281 12.9618 7.36926C13.7848 7.51042 14.5312 7.93829 15.069 8.57708C15.6065 9.21562 15.9008 10.0237 15.8998 10.8584C15.8994 12.2693 14.8567 13.1658 14.1964 13.6061C13.8303 13.8501 13.4726 14.0282 13.211 14.1444C13.0786 14.2033 12.9666 14.2482 12.8849 14.2793C12.844 14.2949 12.8104 14.3071 12.7854 14.316L12.7543 14.3269L12.7438 14.3305L12.7398 14.3318L12.7382 14.3323L12.7374 14.3326C12.7371 14.3327 12.7368 14.3328 12.3641 13.2147L12.7368 14.3328C12.1192 14.5387 11.4518 14.2049 11.246 13.5874C11.0406 12.9712 11.3725 12.3053 11.9875 12.0979L11.9957 12.0951C12.0052 12.0917 12.0222 12.0855 12.0458 12.0765C12.093 12.0585 12.1652 12.0298 12.2537 11.9904C12.4341 11.9102 12.6657 11.7936 12.8889 11.6448C13.407 11.2994 13.5426 11.0176 13.5426 10.8576L13.5426 10.8558C13.543 10.5775 13.4449 10.308 13.2657 10.0951C13.0864 9.88215 12.8376 9.73952 12.5633 9.69247ZM15.8998 10.8584V10.8576H14.7212L15.8998 10.8593L15.8998 10.8584ZM12.4286 15.1768C11.7777 15.1768 11.25 15.7044 11.25 16.3553C11.25 17.0062 11.7777 17.5339 12.4286 17.5339H12.4377C13.0886 17.5339 13.6162 17.0062 13.6162 16.3553C13.6162 15.7044 13.0886 15.1768 12.4377 15.1768H12.4286Z"
      fill={color || ddsColor('primary', 600)}
      fillRule="evenodd"
    />
  </svg>
);

export default IconHelp;
