// @ts-nocheck
import useStyles from '../styles/banner.styles';
import Illustration from '../../../assets/illustrations/HP-change-dashboard-illustration.png';
import { ButtonSecondary } from '@dovera/design-system';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { cx } from '../../../utils/exports';
import strings from '../../../constants/strings';
// @ts-check

interface Props {
  isLarge: boolean;
  path: string;
}

const { button, description, title } = strings.parameters.index.banner;

const ParametersIndexBanner = ({ isLarge, path }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const clickCallback = useCallback(() => {
    navigate(path);
  }, [navigate, path]);
  const renderContent = (
    <div>
      <h3 className="mb-small">{title}</h3>
      <p className={cx(isLarge ? 'mb-large' : 'mb-small')}>{description}</p>
      <ButtonSecondary className="no-mrg" onClick={clickCallback}>
        {button}
      </ButtonSecondary>
    </div>
  );
  if (isLarge)
    return (
      <div
        className={cx(classes.parametersIndexBanner, isLarge && classes.large)}
      >
        <img alt="Ilustrácia" src={Illustration} />
        {renderContent}
      </div>
    );
  return <div className={classes.parametersIndexBanner}>{renderContent}</div>;
};

export default ParametersIndexBanner;
