import { breakpoints, color } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: ({ theme }) => ({
    position: 'relative',
    width: '100%',
    backgroundColor: theme === 'Autentifikacia' ? '#E8F2FE' : '#DFEDED',
    borderRadius: 8,
    zIndex: 1,
  }),
  modal: {
    width: 1171,
    backgroundColor: '#E8F2FE',
    '& .modal__content': {
      padding: 0,
    },
    '& .modal__header': {
      padding: 0,
      '& .modal__close-button': {
        position: 'absolute',
        right: 24,
        top: 24,
        zIndex: 5,
      },
    },
    [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
      width: 900,
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      width: 700,
    },
  },
  dashboardBanner: {},
  close: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: 'absolute',
    top: 24,
    right: 24,
    borderRadius: 100,
    width: 32,
    height: 32,
    '&:not(.--withoutHover)': {
      '&:hover': {
        backgroundColor: color('primary', 100),
      },
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      top: 12,
      right: 12,
    },
  },
});
