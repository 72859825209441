export default {
  batchUpload: {
    notifications: {
      error: {
        btn: 'Nahrať znova',
        text: '<b>V dávkach sme objavili chyby.</b> Prosím, opravte nižšie uvedené dávky v ambulantnom softvéri.',
      },
      success: {
        btn: {
          continue: 'Pokračovať bez overenia',
          check: 'Overiť dávky',
        },
        text: 'Dávky môžete odoslať alebo si ich overiť.',
        tooltip:
          'Overenie dávok môže predísť<br />chybám a zamietnutým výkonom.<br />Trvá niekoľko minút a o jeho<br />výsledku vás budeme informovať<br />mailom.',
      },
    },
    errorCheckingPage: {
      btn: 'Späť na zoznam chybných dávok',
      btnChecked: 'Späť na zoznam overených dávok',
      title: 'Prehľad chýb',
      notification: {
        btn: 'Stiahnuť prehľad',
        text: 'Prosím, opravte nižšie uvedené chyby<br />v ambulantnom softvéri.',
      },
    },
    cancelModal: {
      title: 'Naozaj chcete ukončiť odosielanie dávok?',
      description: 'Dávky a nahraté prílohy nebudú uložené.',
      btnSecondary: 'Pokračovať v odosielaní',
      btn: 'Ukončiť',
    },
    checkError: {
      description:
        'Počas kontroly dávok nastala chyba. Skúste neskôr, prosím. Ak bude chyba pretrvávať, <a href="poskytovatel@dovera.sk">kontaktujte zákaznícke centrum</a>.',
      button: 'Skúsiť znovu',
    },
  },
  batchPreview: {
    batchUploadBtn: 'Odoslanie dávok',
    filter: {
      buttons: {
        delete: 'Vymazať označené',
        deleteAll: 'Vymazať všetky',
        processing: 'Dávky sa spracovávajú',
        reset: 'Zrušiť filter',
        submit: 'Odoslať označené',
        submitAll: 'Odoslať všetky',
      },
      labels: {
        batchName: 'Názov dávky',
        period: 'Obdobie',
        dateFrom: 'Dátum od',
        dateTo: 'Dátum do',
        state: 'Stav',
        type: 'Typ',
      },
      tooltips: {
        processing: 'Dávky môžete odoslať po spracovaní.',
      },
    },
    notifications: {
      infoLoading: 'Dávky môžete odoslať po spracovaní.',
      warning:
        'Môžete odoslať chybné dávky, no niektoré výkony môžu byť zamietnuté.',
    },
    tabs: {
      checked: 'Overené dávky',
      sent: 'Odoslané dávky',
    },
    tableCols: {
      checked: {
        batch: 'Dávka',
        date: 'Dátum prijatia',
        state: 'Stav',
        type: 'Typ',
      },
      sent: {
        id: 'Identifikátor',
        coverLetter: 'Sprievodný list',
        type: 'Typ',
        date: 'Dátum odoslania',
        period: 'Obdobie',
        state: 'Stav',
      },
    },
  },
  invoices: {
    detail: {
      buttons: {
        confirmation: 'Prejsť na potvrdenie',
        download: 'Stiahnuť chybové dávky',
        dispZaz: 'Prehľad dispenzačných záznamov',
      },
      extraDocuments: {
        title: 'Nahratie doplňujúcich dokumentov<br />(nepovinné)',
        description:
          'Môžete nahrať napr. sprievodné listy k dávkam, lekárske správy, prepúšťacie správy, zdôvodnenie reklamácie.',
        button: 'Uložiť dokumenty',
        uploadLabel: (
          <>
            {'Dokumenty môžete  '} <span className="link">{'nahrať'}</span>
            {'  alebo presunúť myšou sem.'}
          </>
        ),
      },
      subtitle: 'Zoznam chybových dávok',
      spatneDavky: {
        tableCols: {
          name: 'Názov',
          type: 'Typ',
          period: 'Obdobie',
          date: 'Dátum vygenerovania',
        },
      },
    },
    filter: {
      buttons: {
        reset: 'Zrušiť filter',
      },
      labels: {
        period: 'Obdobie',
        vs: 'Variabilný symbol',
        state: 'Stav',
        zuc20: 'Zúčtovanie 2.0',
        paperInvoice: 'Papierová faktúra',
        invoiceType: 'Typ faktúry',
        code: 'Kód pracoviska',
      },
    },
    columns: {
      vs: 'Variabilný symbol',
      internalNumber: 'Interné číslo',
      period: 'Obdobie',
      state: 'Stav',
      sum: 'Suma',
      delivery: 'Doručenie podľa VZP',
    },
  },
  batchSplit: {
    done: 'Dokončiť',
    cancel: 'Ukončiť',
    continueSending: 'Pokračovať v odosielaní',
    notification: 'Dávky sú úspešne pripravené na odoslanie.',
    cancelModal: {
      title: 'Naozaj chcete ukončiť odosielanie dávok?',
      description: 'Dávky a nahraté prílohy nebudú uložené.',
    },
    title: (characters: string[], innovice: string, period: number) =>
      `Zmluva č. ${innovice} za obdobie ${period}${
        characters.some((c) => ['E', 'F', 'G'].includes(c.trim())) ? ' EÚ' : ''
      }`,
    batchTable: {
      header: {
        type: 'Typ dávky',
        provider: 'Ambulancia/Oddelenie',
        file: 'Názov súboru',
      },
    },
    documents: {
      invoices: {
        title: (mandatory: boolean) =>
          `Nahratie faktúry ${mandatory ? '(povinné)' : '(nepovinné)'}`,
        form: {
          vs: 'Variabilný symbol',
          suma: 'Suma faktúry',
          uploadLabel: (
            <>
              {'Faktúru môžete '} <span className="link"> {'nahrať'} </span>
              {' alebo presunúť myšou sem.'}
            </>
          ),
        },
      },
      additionalDocuments: {
        title: 'Nahratie doplňujúcich dokumentov (nepovinné)',
        subtitle:
          'Môžete nahrať napr. sprievodné listy k dávkam, lekárske správy, prepúšťacie správy, zdôvodnenie reklamácie.',
        form: {
          uploadLabel: (
            <>
              {'Dokumenty môžete '} <span className="link"> {'nahrať'} </span>
              {' alebo presunúť myšou sem.'}
            </>
          ),
        },
      },
      justidicationDocuments: {
        title: 'Nahratie zdôvodnenia opravnej dávky',
        subtitle: 'Môžete nahrať zdôvodnenie opravnej dávky.',
        form: {
          uploadLabel: (
            <>
              {'Dokumenty môžete '} <span className="link"> {'nahrať'} </span>
              {' alebo presunúť myšou sem.'}
            </>
          ),
        },
      },
    },
    joiningModal: {
      title: 'Chcete dávky spojiť do jednej faktúry?',
      description:
        'Vaše dávky pokrývajú rôzne obdobia. Môžete vytvoriť jednu faktúru, ktorú zúčtujeme pod najaktuálnejším mesiacom.',
      btn: {
        leave: 'Ponechať rozdelené',
        join: 'Spojiť dávky',
      },
    },
  },
  modals: {
    acceptedInvoice: {
      form: {
        title: 'Potvrdenie FA',
        titleAdd: 'Doloženie faktúry',
        labels: {
          date: 'Dátum a čas',
          name: 'Meno',
          sum: 'Suma',
          vs: 'Variabilný symbol',
        },
        errors: {
          uploadInvoice: 'Nahrajte faktúru.',
        },
        buttons: {
          cancel: 'Zrušiť',
          submit: 'Potvrdiť',
        },
        uploadInvoice: {
          title: (necessary: boolean) =>
            `Nahratie faktúry (${!necessary ? 'ne' : ''}povinné)`,
          label:
            'Faktúru môžete <span class="link">nahrať</span> alebo presunúť myšou sem.',
        },
        uploadExtraDocuments: {
          title: 'Nahratie doplňujúcich<br />dokumentov (nepovinné)',
          label:
            'Dokumenty môžete <span class="link">nahrať</span> alebo presunúť myšou sem.',
        },
      },
      title: 'Faktúru sa podarilo potvrdiť',
      titleErr: 'Faktúru sa nepodarilo potvrdiť',
      description: 'Peniaze Vám pošleme <b>do 5 - 10 dní</b> (podľa zmluvy).',
      buttons: {
        cancel: 'Späť na detail faktúry',
        submit: 'Prehľad faktúr',
      },
    },
    deleteBatches: {
      title: 'Naozaj chcete vymazať dávky?',
      description: 'Po vymazaní bude potrebné dávky znova nahrať.',
      buttons: {
        cancel: 'Ponechať dávky',
        submit: 'Vymazať',
      },
    },
    sendWrongBatches: {
      title: 'Naozaj chcete odoslať chybné dávky?',
      description: 'Niektoré výkony môžu byť zamietnuté.',
      buttons: {
        cancel: 'Opraviť chybné dávky',
        submit: 'Odoslať',
      },
    },
    mergeBatches: {
      title: 'Chcete dávky spojiť do jednej faktúry?',
      description:
        'Vaše dávky pokrývajú rôzne obdobia. Môžete vytvoriť jednu faktúru, ktorú zúčtujeme pod najaktuálnejším mesiacom.',
      buttons: {
        cancel: 'Ponechať rozdelené',
        submit: 'Spojiť dávky',
      },
    },
    capBatches: {
      title:
        'Ak ste poskytli aj zdravotnú starostlivosť,<br />nezabudnite doplniť ambulantné dávky',
      description: '',
      buttons: {
        cancel: 'Doplniť ambulantné dávky',
        submit: 'Odoslať dávky',
      },
    },
  },
  resultPage: {
    pending: {
      title: 'Zúčtovanie sa spracováva',
      description:
        'Na výsledok môžete počkať, no môže to trvať niekoľko minút.<br />Keď bude spracované, nájdete ho v schránke správ.',
    },
    success: {
      title: 'Vaše zúčtovanie sme prijali',
      description:
        'O výsledku Vás budeme informovať <strong>mailom najneskôr do 20 dní.</strong><br />Interné číslo faktúry sa vám v záložke Faktúry zobrazí v priebehu niekoľkých sekúnd.',
    },
    warning: {
      title: 'Mrzí nás to, prijali sme iba časť zúčtovania',
      description:
        'Neviete si s chybou poradiť? Kontaktujte nás na linke pre poskytovateľov <strong>0800 150 155.</strong><br />Interné číslo faktúry sa vám v záložke Faktúry zobrazí v priebehu niekoľkých sekúnd',
    },
    error: {
      title: 'Mrzí nás to, odoslanie nebolo úspešné',
      description:
        'Neviete si s chybou poradiť? Kontaktujte nás na linke pre poskytovateľov <strong>0800 150 155.</strong>',
    },
  },
};
