import * as React from 'react';
import CobrowseMenu from '../components/FloatingButton/CobrowseMenu/CobrowseMenu';
import { Icon } from '@dovera/design-system';

export const menuItems = {
  S: {
    icon: <Icon id="sprievodca-menu-item" name="sign" size="medium" />,
    title: 'Sprievodca',
    id: 'pomocnik--sprievodca',
    key: 'S',
  },
  K: {
    icon: <Icon id="cobrowse-menu-item" name="key" size="medium" />,
    title: 'Kód na zdieľanie',
    component: <CobrowseMenu />,
    key: 'K',
  },
};
