import { createUseStyles } from 'react-jss';
import { breakpoints, color, getRem } from '@dovera/design-system';

export default createUseStyles({
  root: {},
  checkboxGroup: {
    display: 'flex',
    '& > div': {
      marginRight: 24,
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      display: 'block',
    },
  },
  buttons: {
    '& button': {
      marginRight: 32,
    },
    '& .btn--secondary': {
      color: color('error', 600),
      borderColor: color('error', 600),
      '&:hover, &:focus': {
        '&:not([disabled])': {
          color: color('error', 600),
          borderColor: color('error', 600),
          backgroundColor: color('error', 100),
        },
      },
    },
  },
  notification: {
    maxWidth: getRem(640),
  },
});
