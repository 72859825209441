import { cx } from '../../../utils/exports';
import useStyles from '../CompareChart.styles';
import { State } from '../types';

interface Props {
  countPoints: number;
  index: number;
  secondBorderInRow: boolean;
  status: State;
  text?: string;
}

const MarkLabel = ({
  countPoints,
  index,
  secondBorderInRow,
  status,
  text,
}: Props) => {
  const classes = useStyles({});
  const leftAligment: boolean = index < 5;
  const rightAligment: boolean = index === countPoints - 1;
  return (
    <span
      className={cx(
        classes.label,
        status,
        leftAligment && 'align-left',
        rightAligment && 'align-right',
        !status && secondBorderInRow && 'label-top',
      )}
    >
      {text}
    </span>
  );
};

export default MarkLabel;
