import { Path, Svg } from '@react-pdf/renderer';
import { color } from '@dovera/design-system';

interface Props {
  height?: number;
  width?: number;
}

const SuccessIcon = ({ height, width }: Props) => (
  // @ts-ignore
  <Svg style={{ width: width || 12, height: height || 12, marginTop: 1 }}>
    <Path
      d="M0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6Z"
      fill={color('primary', 600)}
    />
    <Path
      d="M3 6L5.25 8.25L9 3.75"
      stroke="white"
      // @ts-ignore
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </Svg>
);

export default SuccessIcon;
