import { createUseStyles } from 'react-jss';

import { borderRadius, color, contentSpacing } from '../../constants';
import { isIE } from '../../utils/app.utils';

export default createUseStyles({
  notificationbox: {
    padding: '1rem',
    borderRadius: `${borderRadius}px`,
    marginBottom: contentSpacing,
    fontSize: '1rem',
    '& span': {
      display: isIE && 'inline-block',
    },
    '& button': {
      minHeight: 'auto',
    },
  },
  info: {
    backgroundColor: color('success', 100),
    color: color('black'),
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  infoBlue: {
    backgroundColor: '#D2E5FF',
    color: color('black'),
    '& svg': {
      width: 24,
      height: 24,
    },
    '& a': {
      color: '#1B4888',
      textDecoration: 'underline',
      marginLeft: 30,
    },
  },
  warning: {
    backgroundColor: color('warning', 100),
    color: color('black'),
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  error: {
    backgroundColor: color('error', 100),
    color: color('black'),
  },
  transparent: {
    backgroundColor: 'transparent',
    color: color('black'),
    '& svg': {
      width: 16,
      height: 16,
      verticalAlign: 'middle',
      marginTop: 3,
    },
  },
  default: {
    backgroundColor: color('secondary', 100),
    color: color('black'),
  },
  iconTop: {
    '& > div': {
      alignItems: 'flex-start',
    },
  },
  grey: {
    backgroundColor: color('grey', 100),
  },
});
