import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { createQueryParams } from '../utils/api.utils';
import {
  DajChybuOvereniaQueries,
  DajChybuOvereniaResponse,
  DajMojeFakturyQueries,
  DajMojeFakturyResponse,
  DajNepovoleneVSQueries,
  DajNepovoleneVSResponse,
  DajOvereneZuctovacieDavkyQueries,
  DajOvereneZuctovacieDavkyResponse,
  DajProtokolQueries,
  DajProtokolResponse,
  DajSpatneDavkyFakturyQueries,
  DajSpatneDavkyFakturyResponse,
  DajStavKontrolyZuctovacichDavokQueries,
  DajStavKontrolyZuctovacichDavokResponse,
  DajStavPripravySuborovSpatnychDavokQueries,
  DajStavPripravySuborovSpatnychDavokResponse,
  DajSuborDavkyQueries,
  DajSuborDavkyResponse,
  DajSuborSpatnejDavkyQueries,
  DajSuborSpatnejDavkyResponse,
  DajZoznamDokumentovFakturyQueries,
  DajZoznamDokumentovFakturyResponse,
  DajZoznamZuctovacichDavokQueries,
  DajZoznamZuctovacichDavokResponse,
  OdosliLekarenskuFakturuRequest,
  OdosliPrilohyFakturyRequest,
  PotvrdFakturuRequest,
  SkontrolujZuctovacieDavkyRequest,
  SkontrolujZuctovacieDavkyResponse,
  VymazDavkyQueries,
} from '../types/zuctovanie.types';

import mockRozdelenieDavokReal from '../pages/zuctovanie/RozdelenieDavok/mocks/mockRozdelenieDavokReal.json';

import {
  DajStavOdoslaniaDavokQueries,
  DajStavOdoslaniaDavokResponse,
  OdosliZuctovanieDavkyQueries,
  OdosliZuctovanieDavkyResponse,
  RozdelDavkyNaOdoslanieQueries,
  RozdelDavkyNaOdoslanieResponse,
} from '../types/api/zuctovanie';
import { timeout } from '../utils/app.utils';
import { WriteResponse } from '../types/api';

const API_CONTROLLER = `Zuctovanie`;

const API_URL = `${window.env?.config?.apiPath}/${API_CONTROLLER}/`;

export const dajZoznamZuctovacichDavok = async (
  queries: DajZoznamZuctovacichDavokQueries,
) => {
  const { data }: AxiosResponse<DajZoznamZuctovacichDavokResponse> =
    await axios.get(
      `${API_URL}DajZoznamZuctovacichDavok${createQueryParams({
        withoutEmpty: false,
        queryParams: {
          ...queries,
        },
      })}`,
    );
  return data;
};

export const dajOvereneZuctovacieDavky = createAsyncThunk(
  'zuctovanie/dajOvereneZuctovacieDavky',
  async (queries: DajOvereneZuctovacieDavkyQueries) => {
    const { data }: AxiosResponse<DajOvereneZuctovacieDavkyResponse> =
      await axios.get(
        `${API_URL}DajOvereneZuctovacieDavky${createQueryParams({
          withoutEmpty: false,
          queryParams: {
            ...queries,
          },
        })}`,
      );
    return data;
  },
);

export const dajOvereneZuctovacieDavkyWithoutRedux = async (
  queries: DajOvereneZuctovacieDavkyQueries,
) => {
  const { data }: AxiosResponse<DajOvereneZuctovacieDavkyResponse> =
    await axios.get(
      `${API_URL}DajOvereneZuctovacieDavky${createQueryParams({
        withoutEmpty: false,
        queryParams: {
          ...queries,
        },
      })}`,
    );
  return data;
};

export const overStavOverenychZuctovacichDavok = createAsyncThunk(
  'zuctovanie/overStavOverenychZuctovacichDavok',
  async (queries: DajOvereneZuctovacieDavkyQueries) => {
    const { data }: AxiosResponse<DajOvereneZuctovacieDavkyResponse> =
      await axios.get(
        `${API_URL}DajOvereneZuctovacieDavky${createQueryParams({
          withoutEmpty: false,
          queryParams: {
            ...queries,
          },
        })}`,
      );
    return data;
  },
);

export const dajSpatneDavkyFaktury = async (
  queries: DajSpatneDavkyFakturyQueries,
) => {
  const { data }: AxiosResponse<DajSpatneDavkyFakturyResponse> =
    await axios.get(
      `${API_URL}DajSpatneDavkyFaktury${createQueryParams({
        withoutEmpty: false,
        queryParams: {
          ...queries,
        },
      })}`,
    );
  return data;
};

export const dajSuborDavky = async (
  queries: DajSuborDavkyQueries,
): Promise<DajSuborDavkyResponse> => {
  const { data } = await axios.get(
    `${API_URL}DajSuborDavky${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};

export const rozdelDavkyNaOdoslanie = async (
  queries: RozdelDavkyNaOdoslanieQueries,
): Promise<any> => {
  const mocked = false;
  if (mocked && window.env.ENV !== 'PROD') {
    return mockRozdelenieDavokReal;
  }
  const { data }: AxiosResponse<RozdelDavkyNaOdoslanieResponse> =
    await axios.post(`${API_URL}RozdelDavkyNaOdoslanie`, queries);
  return data;
};
export const odosliZuctovanieDavky = createAsyncThunk(
  'zuctovanie/odosliDavky',
  async (queries: OdosliZuctovanieDavkyQueries) => {
    const mocked = false;
    if (mocked && window.env.ENV !== 'PROD') {
      await timeout(5000);
      return {
        groupId: 0,
        udajeDavok: [],
      };
    }
    const { data }: AxiosResponse<OdosliZuctovanieDavkyResponse> =
      await axios.post(`${API_URL}OdosliDavky`, queries);
    return data;
  },
);

export const dajMojeFaktury = async (
  queries: DajMojeFakturyQueries,
  signal?: any,
) => {
  const { data }: AxiosResponse<DajMojeFakturyResponse> = await axios.get(
    `${API_URL}DajMojeFaktury${createQueryParams({
      withoutEmpty: true,
      queryParams: {
        ...queries,
      },
    })}`,
    { signal },
  );
  return data;
};

export const dajZoznamKodovPracovisk = async () => {
  const { data }: AxiosResponse<{ zoznamKodov: string[] }> = await axios.get(
    `${API_URL}DajZoznamKodovPracovisk`,
  );
  return data;
};

export const dajChybuOverenia = async (queries: DajChybuOvereniaQueries) => {
  const { data }: AxiosResponse<DajChybuOvereniaResponse> = await axios.get(
    `${API_URL}DajChybuOverenia${createQueryParams({
      withoutEmpty: true,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};

export const dajProtokol = async (queries: DajProtokolQueries) => {
  const { data }: AxiosResponse<DajProtokolResponse> = await axios.get(
    `${API_URL}DajProtokol${createQueryParams({
      withoutEmpty: true,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};

export const vymazDavky = async (queries: VymazDavkyQueries) => {
  const { data }: AxiosResponse<any> = await axios.delete(
    `${API_URL}VymazDavky${createQueryParams({
      withoutEmpty: true,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};

export const dajStavKontrolyZuctovacichDavok = createAsyncThunk(
  'zuctovanie/dajStavKontrolyZuctoacichDavok',
  async (queries: DajStavKontrolyZuctovacichDavokQueries) => {
    const { data }: AxiosResponse<DajStavKontrolyZuctovacichDavokResponse> =
      await axios.get(
        `${API_URL}DajStavKontrolyZuctovacichDavok${createQueryParams({
          withoutEmpty: true,
          queryParams: {
            ...queries,
          },
        })}`,
      );
    return data;
  },
);

export const dajSuborSpatnejDavky = async (
  queries: DajSuborSpatnejDavkyQueries,
): Promise<DajSuborSpatnejDavkyResponse> => {
  const { data } = await axios.get(
    `${API_URL}DajSuborSpatnejDavky${createQueryParams({
      withoutEmpty: true,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};

export const dajStavPripravySuborovSpatnychDavok = async (
  queries: DajStavPripravySuborovSpatnychDavokQueries,
): Promise<DajStavPripravySuborovSpatnychDavokResponse> => {
  const { data } = await axios.get(
    `${API_URL}DajStavPripravySuborovSpatnychDavok${createQueryParams({
      withoutEmpty: true,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};

export const skontrolujSuboryDavky = async (
  request: SkontrolujZuctovacieDavkyRequest,
) => {
  const { data }: AxiosResponse<SkontrolujZuctovacieDavkyResponse> =
    await axios.post(`${API_URL}SkontrolujZuctovacieDavky`, request);
  return data;
};

export const skontrolujZuctovacieDavky = createAsyncThunk(
  'zuctovanie/skontrolujZuctovacieDavky',
  async (request: SkontrolujZuctovacieDavkyRequest) => {
    const { data }: AxiosResponse<SkontrolujZuctovacieDavkyResponse> =
      await axios.post(`${API_URL}SkontrolujZuctovacieDavky`, request);
    return data;
  },
);

export const dajZoznamDokumentovFaktury = async (
  queries: DajZoznamDokumentovFakturyQueries,
) => {
  const { data }: AxiosResponse<DajZoznamDokumentovFakturyResponse> =
    await axios.get(
      `${API_URL}DajZoznamDokumentovFaktury${createQueryParams({
        withoutEmpty: true,
        queryParams: {
          ...queries,
        },
      })}`,
    );
  return data;
};

export const potvrdFakturu = async (request: PotvrdFakturuRequest) => {
  const { data }: AxiosResponse<any> = await axios.post(
    `${API_URL}PotvrdFakturu`,
    request,
  );
  return data;
};

export const odosliLekarenskuFakturu = async (
  request: OdosliLekarenskuFakturuRequest,
) => {
  const { data }: AxiosResponse<any> = await axios.post(
    `${API_URL}OdosliLekarenskuFakturu`,
    request,
  );
  return data;
};

export const dajNepovoleneVS = async (queries: DajNepovoleneVSQueries) => {
  const { data }: AxiosResponse<DajNepovoleneVSResponse> = await axios.get(
    `${API_URL}DajNepovoleneVariabilneSymboly${createQueryParams({
      withoutEmpty: true,
      queryParams: {
        ...queries,
      },
    })}`,
  );
  return data;
};

export const odosliPrilohyFaktury = async (
  request: OdosliPrilohyFakturyRequest,
) => {
  const { data }: AxiosResponse<WriteResponse> = await axios.post(
    `${API_URL}OdosliPrilohyFaktury`,
    request,
  );
  return data;
};

export const dajStavOdoslaniaDavok = async (
  queries: DajStavOdoslaniaDavokQueries,
) => {
  const { data }: AxiosResponse<DajStavOdoslaniaDavokResponse> =
    await axios.get(
      `${API_URL}DajStavOdoslaniaDavok${createQueryParams({
        withoutEmpty: true,
        queryParams: {
          ...queries,
        },
      })}`,
    );
  return data;
};
