interface Props {
  color?: string;
  height?: number;
  id: string;
  width?: number;
}

const IconRestart = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 24}
    id={id}
    viewBox={`0 0 ${width || 24} ${height || 24}`}
    width={width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4 10.5C4 6.35786 7.35786 3 11.5 3C15.6421 3 19 6.35786 19 10.5C19 12.4935 18.2236 14.3034 16.9547 15.6475C16.5755 16.0491 16.5937 16.682 16.9953 17.0611C17.3969 17.4403 18.0298 17.4221 18.409 17.0205C20.0144 15.32 21 13.024 21 10.5C21 5.25329 16.7467 1 11.5 1C6.25329 1 2 5.25329 2 10.5C2 15.5389 5.92305 19.6615 10.8815 19.9802L9.73178 21.3598C9.37821 21.7841 9.43554 22.4147 9.85982 22.7682C10.2841 23.1218 10.9147 23.0645 11.2682 22.6402L13.7682 19.6402C14.0773 19.2693 14.0773 18.7307 13.7682 18.3598L11.2682 15.3598C10.9147 14.9355 10.2841 14.8782 9.85982 15.2318C9.43554 15.5853 9.37821 16.2159 9.73178 16.6402L10.8412 17.9715C7.00759 17.6378 4 14.4201 4 10.5Z"
      fill={color || 'white'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconRestart;
