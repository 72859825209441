import React from 'react';
import { Icon } from '@dovera/design-system';
import { CustomTooltip } from '../../CustomTooltip';

interface Props {
  text: string;
  tooltip: string;
}

const TextWithTooltip = ({ text, tooltip }: Props) => (
  <>
    <span>{text} </span>
    <CustomTooltip dialog={tooltip} id={`tooltip-formula-${tooltip}`}>
      <Icon className="text-color-grey" name="info" />
    </CustomTooltip>
  </>
);

export default TextWithTooltip;
