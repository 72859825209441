import { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import { useQuery } from '../../../hooks/useQuery';
import { FILTER_PREFIX } from '../constants';
import { MultiSelectType } from '../types';
import { MultiSelect } from '../../MultiSelect';

const MultiSelectFilter = ({ ...props }: MultiSelectType) => {
  const { id, onSelected, ...restProps } = props;

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const [initValue, setInitValue] = useState('');
  const query = useQuery();

  useEffect(() => {
    const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
    if (queryValue) {
      const value = queryValue.split('-').join(';');
      handleSelect(value);
      setInitValue(value);
    }
    // eslint-disable-next-line
  }, []);

  const handleSelect = (values) => {
    if (values.length === 0) {
      searchParams.delete(`${FILTER_PREFIX}_${id}`);
    } else {
      searchParams.set(`${FILTER_PREFIX}_${id}`, values.replace(/;/g, '-'));
    }
    setSearchParams(searchParams);
    onSelected(values);
  };

  return (
    <MultiSelect
      id={id}
      initialValue={initValue}
      onSelected={(values) => handleSelect(values)}
      {...restProps}
    />
  );
};

export default MultiSelectFilter;
