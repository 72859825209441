import { useContext, useEffect } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import { RadioGroup } from '@dovera/design-system';
import { FILTER_PREFIX } from '../constants';
import { useQuery } from '../../../hooks/useQuery';

const RadioGroupFilter = ({ ...props }: any) => {
  const { children, id, onChange, ...restProps } = props;
  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
    if (queryValue) {
      onChange(queryValue);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    searchParams.set(`${FILTER_PREFIX}_${id}`, value);
    setSearchParams(searchParams);
    onChange(value);
  };

  return (
    <RadioGroup id={id} onChange={(e) => handleChange(e)} {...restProps}>
      {children}
    </RadioGroup>
  );
};

export default RadioGroupFilter;
