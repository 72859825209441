export default {
  parametersOverview: 'Prehľad hodnotiacich parametrov',
  helperText: 'Údaje sú prepočítané za všetkých lekárov v odbornosti.',
  parametersSelection: 'Výber parametrov',
  select: 'Vybrať',
  unselect: 'Odobrať',
  switch: 'Prepnúť',
  parametersCount: 'Počet parametrov',
  periods: {
    A: 'Priebežné vyhodnotenie',
    Z: 'Aktuálne vyhodnotenie',
    U: 'Minulé vyhodnotenie',
    labelPast: 'Platné ceny pre obdobie',
  },
  index: {
    banner: {
      title: 'Prispôsobte si hodnotiace parametre',
      description:
        'Odteraz Vám ponúkame možnosť výberu parameterov, vďaka čomu môžete dosiahnuť lepšie hodnotenie a získať vyššiu cenu bodu.',
      button: 'Vybrať parametre',
    },
    currentPrices: {
      title: (priceType?: string) => {
        const capitationOrPoint = (priceType: string) =>
          priceType.toLowerCase().startsWith('dod')
            ? 'dodatková kapitácia'
            : 'cena bodu';
        return !priceType
          ? 'Vaše aktuálne platné ceny'
          : `Vaša aktuálne platná ${capitationOrPoint(priceType)}`;
      },
      description: (obdobieOd: string, obdobieDo: string) =>
        `pre obdobie:<br /><b>${obdobieOd} - ${obdobieDo}</b>`,
      button: 'Vyhodnotené parametre',
      buttonSmall: 'Vyhodn. parametre',
      downloadBtn: 'Ceny za všetky odbornosti',
      downloadBtnSmall: 'Všetky odbornosti',
      prices: {
        Vykon1: 'Cena výkonu&nbsp;1',
        Vykon250: 'Cena výkonu&nbsp;250',
        Vykon4: 'Cena výkonu&nbsp;4',
        Vykon8: 'Cena výkonu&nbsp;8',
        DodKap: 'Dodatková kapitácia',
        CenaBodu: 'Cena bodu',
      },
      expertise: (nazovOdbornosti: string) =>
        `pre odbornosť:<br /><b>${nazovOdbornosti}</b>`,
      outlierNtf: {
        text: 'Momentálne ste outlier.',
        link: 'Čo to znamená?',
      },
    },
  },
  dashboard: {
    chart: {
      detailLink: 'Ako počítame hodnoty?',
    },
    labelPeriod: (code: string) =>
      code === 'A'
        ? 'Priebežné vyhodnotenie cien pre obdobie'
        : 'Platné ceny pre obdobie',
    export: {
      fileTitle: {
        A: 'priebezne-vyhodnotenie',
        Z: 'aktualne-vyhodnotenie',
        U: 'minule-vyhodnotenie',
      },
    },
  },
  detail: {
    title: 'Detail',
    weight: 'Váha parametra',
    subtitleEvaluationDetail: 'Detail výpočtu',
    'dostup-gyn': {
      subtitleChart1: 'Úväzok na jedného lekára',
      subtitleChart2: 'Týždenný ordinačný čas',
    },
    charts: {
      classic: {
        value: 'Vaša hodnota: ',
      },
      compare: {
        bestLabel: 'Lekár s najlepším hodnotením: ',
        borderLabelTop: (value: string | number) => `Horná hranica: ${value}`,
        valueLabel: (value: string | number) => `Vaša hodnota: ${value}`,
        worstLabel: 'Lekár s najhorším hodnotením: ',
      },
    },
    button: 'Detail',
  },
  selection: {
    header: {
      buttons: {
        edit: 'Upraviť',
        save: 'Uložiť',
        cancel: 'Zahodiť zmeny',
      },
      notSTATntfMessage: 'Parametre môže upravovať iba štatutár.',
    },
    leaveModal: {
      title: 'Naozaj chcete odísť?',
      description: 'Zvolené paramatre sa vám po odchode neuložia',
      btnContinue: 'Pokračovať v úprave',
      btnCancel: 'Odísť',
    },
    detailModal: {
      select: 'Vybrať',
      unselect: 'Odobrať',
      cancel: 'Zrušiť',
    },
    notificationMessage: (count: number) => {
      if (count === 1) {
        return `Vybrať ešte <b>1 parameter</b>`;
      }
      return `Vybrať ešte <b>${count} ${count > 4 ? 'parametrov' : 'parametre'}</b>`;
    },
    toast:
      'Máte zvolený maximálny počet parametrov. Najprv odoberte vybraný parameter.',
    result: {
      success: {
        button: 'Späť na domov',
        title: 'Parametre boli uložené',
        description: (periodValidity: string, endAction: string) =>
          `Vybrané parametre sú platné pre obdobie <b>${periodValidity}</b>.<br />Zmeniť ich môžete <b>do ${endAction}</b>.`,
      },
      error: {
        button: 'Skúsiť znovu',
        title: 'Prepáčte, parametre sa nepodarilo uložiť',
        description: () =>
          'Prosím, skúste ich vybrať neskôr.<br />Ak bude problém pretrvávať, <a href="mailto:poskytovatel@dovera.sk">napíšte nám</a>.',
      },
    },
  },
  emptyStates: {
    dashboard: {
      button: '',
      description: 'Prosím, zmeňte odbornosť v hornej časti.',
      title: 'Parametre nie sú pre vybranú odbornosť dostupné',
    },
    detail: {
      button: 'Prehľad parametrov',
      description:
        'Prosím, prejdite na prehľad parametrov pre zvolenú odbornosť.',
      title: 'Parameter nie je pre vybranú odbornosť dostupný',
    },
    selection: {
      button: '',
      description: 'Pre zvolenú odbornosť nie je dostupný výber parametrov.',
      title: 'Prosím, zmeňte odbornosť v hornej časti.',
    },
    selectionError: {
      buttonHome: 'Domov',
      button: 'Skúsiť znovu',
      title: 'Prepáčte, údaje sa nepodarilo načítať',
      description: 'Prosím, skúste to neskôr.',
    },
  },
  pdf: {
    footer: (period: string) =>
      `Platnosť vypočítanej ceny je od ${period?.toLowerCase()}.`,
    title: 'Prehľad hodnotiacich parametrov',
    quality: 'Kvalita',
    effectivity: 'Efektívnosť',
    inovations: 'Inovácie',
    minValue: 'Dolná hranica',
    maxValue: 'Horná hranica',
    averageValue: 'Priemerná hodnota:',
    yourPosition: 'Vaša pozícia:',
    filling: 'Plnenie:',
    yourValue: 'Vaša hodnota:',
    doctor: 'Úväzok na lekára',
    weeklyOfficeHours: 'Týždenný ordinačný čas',
    parameterWeight: (weight: number) => `Váha parametra: ${weight} %`,
  },
};
