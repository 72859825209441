import { createUseStyles } from 'react-jss';
import { breakpoints, color, getRem } from '@dovera/design-system';

export default createUseStyles({
  breadcrumbs: {
    '& ol': {
      listStyleType: 'none',
      paddingLeft: 0,
      display: 'flex',
      '& li': {
        '& svg': {
          verticalAlign: 'middle',
          marginTop: getRem(-3),
        },
        '& a': {
          fontSize: getRem(14),
          fontWeight: 500,
          textDecoration: 'underline',
          color: 'black',
          margin: `0 ${getRem(8)}`,
        },
        '& span': {
          fontSize: getRem(14),
          fontWeight: 400,
          marginLeft: getRem(8),
          color: color('grey', 500),
        },
      },
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      '& ol': {
        flexDirection: 'column',
      },
    },
  },
  home: {
    '& a': {
      fontSize: 'unset !important',
      margin: '0 !important',
    },
    marginRight: getRem(12),
  },
});
