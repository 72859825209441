// @ts-nocheck
import { cx } from '../../../../utils/exports';
import useStyles from '../Auth.styles';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import { Button, color } from '@dovera/design-system';
import IconClose from '../../../../components/CustomIcons/IconClose';
import { IconMenuAngle } from '../../../../components/CustomIcons';
import Illustration from '../../../../assets/illustrations/dlek-auth-card.png';
import { getCMSText } from '../../../../utils/cms.utils';
import { cmsPath } from '../../../../constants/cmsPath';
// @ts-check

interface Props {
  isClosable?: boolean;
  isPatientsPage?: boolean;
  onClose?: () => void;
  onContinueClick: () => void;
  show: boolean;
}

const AuthCard = ({
  isClosable,
  isPatientsPage,
  onClose,
  onContinueClick,
  show,
}: Props) => {
  const classes = useStyles();
  const title = isPatientsPage
    ? getCMSText(cmsPath.autentifikacia.banery.banerPacienti.nadpis, '', true)
    : getCMSText(cmsPath.autentifikacia.banery.dashboardKarta.nadpis, '', true);
  const description = isPatientsPage
    ? getCMSText(cmsPath.autentifikacia.banery.banerPacienti.popis, '', true)
    : getCMSText(cmsPath.autentifikacia.banery.dashboardKarta.popis, '', true);
  const button = isPatientsPage
    ? getCMSText(cmsPath.autentifikacia.banery.banerPacienti.tlacidlo, '', true)
    : getCMSText(
        cmsPath.autentifikacia.banery.dashboardKarta.tlacidlo,
        '',
        true,
      );
  if (!show) return <span />;
  return (
    <div className={cx(classes.card, 'mb-large')} data-testid="pzs-authCard">
      {isClosable && onClose && (
        <button
          className={cx(classes.close, '--withoutHover')}
          data-testid="pzs-authCard--close"
          onClick={() => onClose()}
          type="button"
        >
          <IconClose color={color('black')} id="auth-card--close-icon" />
        </button>
      )}
      <SafeHtml
        className="mb-xxsmall"
        html={title}
        style={{ width: isClosable ? '85%' : '100%' }}
        wrapper="h4"
      />
      <SafeHtml className="mb-large" html={description} wrapper="div" />
      <Button
        className={cx(classes.button, 'no-mrg', '--angle')}
        onClick={() => onContinueClick()}
      >
        {button}
        <IconMenuAngle id="auth-card--angle-icon" />
      </Button>
      <img alt="Illustration" src={Illustration} />
    </div>
  );
};

export default AuthCard;
