interface Props {
  color?: string;
  id: string;
}

const IconQualityMenu = ({ color, id }: Props) => (
  <svg
    fill="none"
    height="16"
    id={id}
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.78935 13.9851C8.38899 14.4999 7.61101 14.4999 7.21065 13.9851L1.47751 6.61394C1.19665 6.25283 1.19665 5.74717 1.47751 5.38606L3.03306 3.38606C3.22252 3.14247 3.51383 3 3.82242 3L12.1776 3C12.4862 3 12.7775 3.14247 12.9669 3.38606L14.5225 5.38606C14.8034 5.74717 14.8034 6.25283 14.5225 6.61394L8.78935 13.9851Z"
      stroke={color || 'white'}
      strokeWidth="1.5"
    />
  </svg>
);

export default IconQualityMenu;
