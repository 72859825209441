interface Props {
  color?: string;
  id: string;
}

const IconEfectivityMenu = ({ color, id }: Props) => (
  <svg
    fill="none"
    height="16"
    id={id}
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8.88182 1.30361C7.37485 1.10522 5.84714 1.46143 4.58332 2.30588C4.23892 2.53601 4.14627 3.00176 4.3764 3.34616C4.60652 3.69057 5.07227 3.78321 5.41668 3.55309C6.37791 2.91081 7.53986 2.63988 8.68603 2.79078C9.83221 2.94168 10.8844 3.50411 11.6467 4.37328C12.1813 4.98293 12.5477 5.71433 12.7189 6.49568L12.047 6.49568C11.5033 6.49568 11.1882 7.11158 11.5064 7.55249L12.047 8.30155L12.1824 8.4892L12.1871 8.49568L12.983 9.59847C13.2491 9.96718 13.7981 9.96718 14.0641 9.59847L14.86 8.49568L14.8647 8.4892L15.0001 8.30155L15.5407 7.55249C15.8589 7.11158 15.5439 6.49568 15.0001 6.49568H14.2457C14.0565 5.34818 13.5501 4.26877 12.7744 3.38427C11.7723 2.24149 10.3888 1.50201 8.88182 1.30361ZM11.4167 13.6941C10.1529 14.5386 8.62515 14.8948 7.11818 14.6964C5.61121 14.498 4.22775 13.7585 3.22556 12.6157C2.45075 11.7322 1.94468 10.6543 1.75493 9.50823H0.999881C0.456142 9.50823 0.141092 8.89233 0.459291 8.45142L0.999881 7.70236L1.1353 7.51471L1.13998 7.50823L1.93585 6.40544C2.20195 6.03673 2.75094 6.03673 3.01703 6.40544L3.8129 7.50823L3.81759 7.51471L3.95301 7.70236L4.4936 8.45142C4.8118 8.89233 4.49675 9.50823 3.95301 9.50823L3.28198 9.50823C3.45348 10.2881 3.81956 11.0181 4.35332 11.6267C5.11556 12.4959 6.16779 13.0583 7.31397 13.2092C8.46014 13.3601 9.62209 13.0892 10.5833 12.4469C10.9277 12.2168 11.3935 12.3094 11.6236 12.6538C11.8537 12.9982 11.7611 13.464 11.4167 13.6941Z"
      fill={color || 'white'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconEfectivityMenu;
