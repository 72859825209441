import { useCallback, useEffect, useState } from 'react';
import { Button, Container, Icon, Notification } from '@dovera/design-system';
import useStyles from '../Proposals.styles';
import useStylesApp from '../../../App.styles';
import {
  createViewUrl,
  isTechnicalError,
  scrollToTop,
  userHasSection,
} from '../../../utils/app.utils';
import routes, { navrhyRoutes } from '../../../routes';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import TableList from './TableList/TableList';
import { cx } from '../../../utils/exports';
import { useSelector } from 'react-redux';
import { getSubmittedProposals } from '../../../api/spaProposals';
import { RootState } from '../../../rootReducer';
import Filter from './Filter/Filter';
import {
  ProposalDetailQueries,
  SubmittedProposalsFilter,
} from '../../../types/spaProposals.types';
import {
  reset,
  setListFilter,
  storeQueryParams,
} from '../../../slices/spaProposals.slice';
import { NoDataIllustration } from '../../../components/EmptyState';
import strings from '../../../constants/strings';
import TablePreloaderSkeleton from '../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import { useQuery } from '../../../hooks/useQuery';
import ProposalDetail from '../ProposalDetail/ProposalDetail';
import { useAppDispatch } from '../../../hooks/useStore';
import { useNavigate } from 'react-router';
import { resetTableOrder } from '../../../slices/table.slice';
import { getMoment } from '../../../utils/date.utils';

const texts = strings.proposals.list.submittedProposals;
const { emptyState } = strings.proposals.list;

const SubmittedProposals = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const classesApp = useStylesApp();
  const query = useQuery();
  const navigate = useNavigate();
  const [propNumber, setPropNumber] = useState('');
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const {
    isLoading,
    queryParams,
    submittedProposals: { data, error },
  } = useSelector((state: RootState) => state.spaProposals.list);
  const [filtered, setFiltered] = useState(data);
  const onFiltered = useCallback(
    (values: SubmittedProposalsFilter, showAll: boolean) => {
      if (!isLoading && data.length) {
        setFiltered(
          data.filter(
            (d) =>
              (!values.ambulance ||
                `${d.lekar.kodAmbulancia?.toLowerCase()} - ${d.lekar.nazovAmbulancia?.toLowerCase()}`.includes(
                  values.ambulance.toLowerCase(),
                )) &&
              (!values.doctorCode ||
                `${d.lekar.kod.toLowerCase()} ${d.lekar.meno.toLowerCase()} ${d.lekar.priezvisko.toLowerCase()}`.includes(
                  values.doctorCode.toLowerCase(),
                )) &&
              (!values.insuree ||
                `${d.poistenec.meno.toLowerCase()} ${d.poistenec.priezvisko.toLowerCase()} ${
                  d.poistenec.rodneCislo
                }`.includes(values.insuree.toLowerCase())) &&
              (!values.proposalNumber ||
                d.cisloNavrhu
                  .toLowerCase()
                  .includes(values.proposalNumber.toLowerCase())) &&
              (!values.state ||
                values.state.includes('all') ||
                values.state.includes(d.stav)) &&
              (!values.validityFrom ||
                getMoment(values.validityFrom).isSameOrBefore(
                  getMoment(d.platnostNavrhuOd),
                )) &&
              (!values.validityTo ||
                getMoment(values.validityTo).isSameOrAfter(
                  getMoment(d.platnostNavrhuDo),
                )),
          ),
        );
        setIsFilterChanged(true);
        dispatch(
          setListFilter({ values: { ...values, showAllFilters: showAll } }),
        );
        dispatch(resetTableOrder());
      }
    },
    [isLoading, data, dispatch],
  );
  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);
  useEffect(() => {
    if (!userHasSection('NAVZS')) {
      navigate(routes.dashboard);
    } else if (!isLoading && !data.length) dispatch(getSubmittedProposals());
    // eslint-disable-next-line
  }, [dispatch, data?.length]);
  useEffect(() => {
    if (data && data.length > 0) {
      setFiltered(data);
      scrollToTop();
    }
  }, [data]);
  useEffect(() => {
    /**
     * Redirect z BLO
     */
    if (query.get('refnum') && data && data.length) {
      const refNum = query.get('refnum');
      if (data.some((d) => d.cisloNavrhu === refNum)) {
        const proposal = data.filter((d) => d.cisloNavrhu === refNum)[0];
        // redirect na detail
        const queries: ProposalDetailQueries = {
          cisloNavrhu: proposal?.cisloNavrhu,
          id: proposal?.idNavrhNaZS,
        };
        navigate(createViewUrl(navrhyRoutes.zoznamPodanychNavrhov, queries));
      } else {
        dispatch(storeQueryParams({ queries: { refNum } }));
        navigate(navrhyRoutes.zoznamPodanychNavrhov);
      }
    }
    // eslint-disable-next-line
  }, [query, data]);
  useEffect(() => {
    if (queryParams && !query.get('refnum') && data) {
      const refnum = queryParams.refNum;
      if (refnum) {
        setPropNumber(refnum);
      }
      dispatch(storeQueryParams({ queries: null }));
    }
    // eslint-disable-next-line
  }, [data, queryParams, query]);
  const renderHeader = (
    <ContentHeader padBottom={false}>
      <Container>
        <Breadcrumbs
          items={[
            {
              name: 'Návrhy',
              route: '#',
            },
            {
              name: texts.title,
            },
          ]}
        />
      </Container>
      <h2 className="mb">{texts.title}</h2>
      <Button onClick={() => navigate(navrhyRoutes.novyNavrh)}>
        <Icon color="white" name="16-plus" />
        <span className="text-space-half-left">{texts.labels.newProposal}</span>
      </Button>
    </ContentHeader>
  );
  const renderTable = !isLoading && filtered.length > 0 && !error && (
    <TableList
      data={filtered.map((d) => ({
        doctor: {
          code: d.lekar.kod,
          firstName: d.lekar.meno,
          lastName: d.lekar.priezvisko,
          titleAfter: d.lekar.titulZa,
          titleBefore: d.lekar.titulPred,
        },
        id: d.idNavrhNaZS,
        insuree: {
          firstName: d.poistenec.meno,
          insureeId: d.poistenec.rodneCislo,
          lastName: d.poistenec.priezvisko,
        },
        proposalNumber: d.cisloNavrhu,
        validity: {
          from: d.platnostNavrhuOd,
          to: d.platnostNavrhuDo,
        },
        state: d.stav,
      }))}
    />
  );
  const renderEmptyState = (!data?.length ||
    (data && filtered.length === 0 && isFilterChanged)) && (
    <NoDataIllustration title={emptyState} />
  );
  if (query.get('id') && query.get('cisloNavrhu'))
    return (
      <ProposalDetail
        id={Number(query.get('id'))}
        proposalNumber={query.get('cisloNavrhu') || ''}
      />
    );
  return (
    <StaticMenuLayout
      backgroundWhite
      contentHeader={renderHeader}
      withoutPadding
    >
      <div className={cx(classesApp.pageLayout, classes.section)}>
        {isLoading && <TablePreloaderSkeleton columns={6} hasFilter />}
        {error && isTechnicalError() && (
          <Notification message={error} variant="error" />
        )}
        {!isLoading && !isTechnicalError() && (
          <>
            {!error && (
              <Filter onFiltered={onFiltered} propNumber={propNumber} />
            )}
            {renderTable}
            {renderEmptyState}
          </>
        )}
      </div>
    </StaticMenuLayout>
  );
};

export default SubmittedProposals;
