import { createUseStyles } from 'react-jss';

import { color } from '../../constants';

function getBgColor(colorName: string): string {
  if (colorName === 'grey') return color('grey', 50);
  return color('white');
}

interface StylesProps {
  bgColor: string;
}

export default createUseStyles({
  page: ({ bgColor }: StylesProps) => ({
    backgroundColor: getBgColor(bgColor),
    overflowX: 'hidden',
  }),
});
