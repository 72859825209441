import { Button, ButtonLayout, ButtonLink, color } from '@dovera/design-system';
import { useCallback } from 'react';
import IconClose from '../../../components/CustomIcons/IconClose';
import IconSend from '../../../components/CustomIcons/IconSend';
import strings from '../../../constants/strings';
import { cx } from '../../../utils/exports';
import useStyles from '../Spravy.styles';

const texts = strings.schrankaSprav;

interface Props {
  isFilledField: boolean;
  isReply?: boolean;
  loading: boolean;
  onCancel: (isCancelVisible?: boolean) => void;
  onContinueModal?: () => void;
  updateCancelModalVisible: (visible: boolean) => void;
  uploadError: boolean;
}

const FormButtons = ({
  isFilledField,
  isReply,
  loading,
  onCancel,
  onContinueModal,
  updateCancelModalVisible,
  uploadError,
}: Props) => {
  const classes = useStyles({});

  const onCancelClick = useCallback(() => {
    if (isFilledField) {
      updateCancelModalVisible(true);
      if (onContinueModal) onCancel(true);
    } else {
      onCancel();
    }
  }, [isFilledField, onCancel, onContinueModal, updateCancelModalVisible]);

  return (
    <ButtonLayout
      className={!isReply ? classes.newMessageButtons : classes.buttons}
      direction="horizontal"
    >
      <Button
        className={cx('no-mrg', classes.button)}
        isDisabled={uploadError || loading}
        isLoading={loading}
        submit
      >
        {!loading && <IconSend id={`message-reply-form-send-icon`} />}
        {texts.buttons.submit}
      </Button>
      <ButtonLink
        className={cx(
          classes.button,
          'text-color-error no-mrg',
          isReply && 'no-pad-left no-pad-right',
        )}
        onClick={onCancelClick}
      >
        <IconClose color={color('error', 600)} id="cancel-message-reply" />{' '}
        {texts.buttons.cancel}
      </ButtonLink>
    </ButtonLayout>
  );
};

export default FormButtons;
