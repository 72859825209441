import { createUseStyles } from 'react-jss';

import { borderRadius } from '../../constants';

interface StylesProps {
  height?: string | number;
  isCircle?: boolean;
  width?: string | number;
}

export default createUseStyles({
  '@keyframes skeletonKeyframes': {
    '0%': {
      backgroundPosition: '-300px 0',
    },
    '100%': {
      backgroundPosition: 'calc(300px + 100%) 0',
    },
  },
  skeleton: ({ height, isCircle, width }: StylesProps) => ({
    maxWidth: width,
    width: '100%',
    height,
    display: 'inline-flex',
    lineHeight: 1,
    borderRadius: isCircle ? '50%' : `${borderRadius}px`,
    animation: '1s $skeletonKeyframes ease-in infinite',
    backgroundColor: '#F3F3F3',
    background: 'linear-gradient(90deg, #F3F3F3, #efefef, #F3F3F3)',
    backgroundSize: '300px 100%',
    backgroundRepeat: 'no-repeat',
    ...(isCircle && {
      '&::before': {
        paddingBottom: '100%',
        content: '""',
        display: 'inline-block',
        verticalAlign: 'top',
      },
    }),
  }),
});
