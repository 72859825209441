import { createUseStyles } from 'react-jss';
import { color } from '../../constants';
import { getRem } from '@dovera/design-system';

interface StylesProps {
  optionsTop: boolean;
}

export default createUseStyles({
  selectedContainer: {
    '& .Tag': {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: getRem(8),
      '&__closeButton': {
        color: color('black'),
        opacity: 1,
      },
    },
  },
  multiAutocomplete: {
    position: 'relative',
    marginBottom: getRem(32),
    '& > .form-control': {
      margin: 0,
    },
  },
  optionsWrapper: ({ optionsTop }: StylesProps) => ({
    position: 'absolute',
    boxShadow: '0 10px 15px 0 rgb(0 0 0 / 4%)',
    background: color('white'),
    zIndex: 9,
    padding: getRem(12),
    width: '100%',
    maxWidth: getRem(382),
    top: optionsTop ? getRem(-240) : 'auto',
    '& .form-control': {
      marginBottom: `${getRem(16)} !important`,
    },
  }),
  options: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: getRem(225),
  },
  buttons: {
    position: 'relative',
    width: '100%',
    padding: `${getRem(15)} 0 0`,
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      margin: 0,
      width: `calc(50% - ${getRem(6)})`,
    },
  },
});
