import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Container, Notification, PageSection } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import strings from '../constants/strings';
import { RootState } from '../rootReducer';
import { checkInternetConnection, setOznamyEP } from '../slices/app.slice';
import useStyles from './Layout.styles';
import { cx } from '../utils/exports';
import { getPageTitle, inlineCondition } from '../utils/app.utils';
import { sendDataLayer } from '../utils/gtm.utils';
import { RouterContext } from '../providers/RouterProvider';
import { checkSystemsStatus } from '../api';
import OfflineSystem from '../components/OfflineSystem/OfflineSystem';
import routes, { kapRoute, navrhyRoutes } from '../routes';
import { resetWidgetNaRiesenie } from '../slices/widgetNaRiesenie.slice';
import { resetKAPAction } from '../slices/kapitacie.slice';
import SystemStatusLoader from './SystemStatusLoader';
import { useAppDispatch } from '../hooks/useStore';
import { resetActuality } from '../slices/poskytovatel.slice';
import { resetBanners } from '../slices/banners.slice';
import { resetListFilter } from '../slices/spaProposals.slice';
import AppPreloaderSkeleton from '../components/AppPreloaderSkeleton/AppPreloaderSkeleton';
import useInterval from '../hooks/useInterval';
import EPNotifications from '../components/EPNotifications/EPNotifications';
import { history } from '../helpers/history';
import { useLocation, useNavigate } from 'react-router';
import EmptyStateExpertises from '../components/EmptyState/EmptyStateExpertises';
import { FilterProvider } from '../providers/FilterProvider';
import { getCMSPopUp, getPopupKey } from '../utils/cms.utils';
import PopUp from '../components/PopUp/PopUp';
import { savePopUpSeenApi } from '../api/cms';
import { fetchCMSContent } from '../slices/cms.slice';
import { resetParameters } from '../slices/parameters.slice';

interface Props {
  backgroundWhite?: boolean;
  children: ReactNode;
  contentHeader?: ReactNode;
  contentInsideHeader?: boolean;
  hideEPNotifications?: boolean;
  mustCheckSystems?: {
    infinityCalls?: boolean;
    systems: ('TXS' | 'MD' | 'EP' | 'DMS')[];
  };
  withoutPadding?: boolean;
}

const StaticMenuLayout = ({
  backgroundWhite,
  children,
  contentHeader,
  contentInsideHeader,
  hideEPNotifications,
  mustCheckSystems,
  withoutPadding,
}: Props) => {
  /**
   * Init custom history object to allow navigation outside React component
   * @date 2/21/2024 - 9:32:14 AM
   *
   * @type {*}
   */
  history.navigate = useNavigate();
  history.location = useLocation();

  const route = useContext(RouterContext);
  const dispatch = useAppDispatch();
  const {
    isExternalSystemOffline,
    isLoadingSystemStatus,
    oznamyEP,
    stavPripojenia,
  } = useSelector((state: RootState) => state.app);
  const { guid, pouzivatelId } = useSelector((state: RootState) => state.auth);

  const resetKAP = useSelector((state: RootState) => state.kapitacie.resetKAP);
  const widgetNaRiesenie = useSelector(
    (state: RootState) => state.widgetNaRiesenie,
  );
  const {
    isLoaded,
    isLoading,
    kodTypZs,
    odbornostiEmptyState,
    poslednaZmenaOdbornosti,
  } = useSelector((state: RootState) => state.poskytovatel);
  const cmsLoaded = useSelector((state: RootState) => state.cms.isLoaded);
  const popupClosed = useSelector((state: RootState) => state.cms.popupClosed);
  const [showPopUp, setShowPopUp] = useState(false);

  const popUpData = useMemo(() => {
    if (cmsLoaded && !popupClosed) {
      const popupKey = getPopupKey();
      if (popupKey)
        return {
          idPopUp: `/popup/${popupKey}`,
          nadpis: getCMSPopUp({
            path: `/popup/${popupKey}/nadpis`,
          }),
          obsah: getCMSPopUp({
            path: `/popup/${popupKey}/obsah`,
          }),
        };
    }
    return null;
  }, [cmsLoaded, popupClosed]);

  useEffect(() => {
    if (isLoaded && popUpData)
      setShowPopUp(!popUpData.nadpis?.zobrazene || !popUpData.obsah?.zobrazene);
  }, [isLoaded, popUpData]);

  const classes = useStyles();

  useInterval(() => {
    /* Get external systems statuses - every 30 seconds */
    if (mustCheckSystems?.infinityCalls)
      dispatch(
        checkSystemsStatus({
          systemy: [...mustCheckSystems.systems],
        }),
      );
  }, 30000);

  // Log GA Pageview
  useEffect(() => {
    // if (window.env.ENV === 'PROD' || window.env.ENV === 'TEST')
    setTimeout(() => {
      if (kodTypZs || guid || window.location.pathname === '/') {
        sendDataLayer('pageview', {
          title: getPageTitle(),
          location: window.location.href,
          page: window.location.pathname,
          provider: {
            id: pouzivatelId,
            typZS: kodTypZs,
            kodOdb: poslednaZmenaOdbornosti,
          },
        });
      }
    }, 1000);
    /** Check if TXS is online */
    if (mustCheckSystems && isLoaded)
      dispatch(
        checkSystemsStatus({
          systemy: [...mustCheckSystems.systems],
        }),
      );
    // eslint-disable-next-line
  }, [isLoaded]);

  useEffect(() => {
    const { to } = route;
    // reset states after change route
    if (to !== routes.dashboard && !widgetNaRiesenie.reset) {
      dispatch(resetWidgetNaRiesenie());
      dispatch(resetActuality());
    }
    if (!to.includes(kapRoute) && !resetKAP) dispatch(resetKAPAction());
    if (to === routes.dashboard) dispatch(resetBanners());
    if (
      !to.includes(navrhyRoutes.zoznamSchvalenychNavrhov) &&
      !to.includes(navrhyRoutes.zoznamPodanychNavrhov)
    )
      dispatch(resetListFilter());
    if (!to.includes(routes.parameters)) dispatch(resetParameters({}));
  }, [dispatch, resetKAP, route, widgetNaRiesenie.reset]);
  return (
    <div className={classes.staticMenuLayoutWrapper}>
      <FilterProvider>
        <div
          className={cx(
            classes.staticMenuLayout,
            backgroundWhite && 'white',
            withoutPadding && 'without-padding',
          )}
        >
          {!stavPripojenia && (
            <PageSection
              className={cx(
                classes.pageSection,
                classes.notifications,
                classes.responsiveSection,
                'with-menu',
              )}
            >
              <Container>
                <Notification
                  closeButton
                  closeButtonOnClick={() =>
                    dispatch(checkInternetConnection(false))
                  }
                  message={strings.nepripojene}
                  variant="error"
                />
              </Container>
            </PageSection>
          )}
          {oznamyEP.length > 0 && (
            <PageSection
              className={cx(
                classes.pageSection,
                classes.notifications,
                classes.responsiveSection,
                'with-menu',
              )}
            >
              <Container>
                <Notification
                  closeButton
                  closeButtonOnClick={() => dispatch(setOznamyEP([]))}
                  message={
                    <>
                      {oznamyEP.map((o) => (
                        <span key={o}>{o}</span>
                      ))}
                    </>
                  }
                  variant="warning"
                />
              </Container>
            </PageSection>
          )}
          <div
            className={cx(
              classes.pageSection,
              classes.responsiveSection,
              'with-menu',
            )}
          >
            {mustCheckSystems && isExternalSystemOffline ? (
              <OfflineSystem
                doIntervalCalls={!mustCheckSystems?.infinityCalls}
                systems={mustCheckSystems.systems}
              />
            ) : (
              inlineCondition(
                isLoadingSystemStatus && !mustCheckSystems?.infinityCalls,
                <SystemStatusLoader />,
                inlineCondition(
                  isLoading,
                  <AppPreloaderSkeleton withoutLayout />,
                  <>
                    {!odbornostiEmptyState && contentHeader}
                    {!hideEPNotifications && (
                      <EPNotifications
                        contentInsideHeader={contentInsideHeader}
                        inPageContent
                      />
                    )}
                    {odbornostiEmptyState ? <EmptyStateExpertises /> : children}
                  </>,
                ),
              )
            )}
          </div>
          {popUpData && (
            <PopUp
              data={popUpData}
              onClickHide={() => setShowPopUp(false)}
              onHide={() => {
                setShowPopUp(false);
                savePopUpSeenApi({
                  idPopUp: popUpData.idPopUp,
                }).then(() => dispatch(fetchCMSContent('/', true)));
              }}
              showPopUp={showPopUp}
            />
          )}
        </div>
      </FilterProvider>
    </div>
  );
};

export default StaticMenuLayout;
