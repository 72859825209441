import { EP_SEKCIE } from '../types/index';

const epLinks = {
  dev: {
    broker: 'https://master.dovera-ac.dovera.dev/',
    external: 'https://master.dovera-zamestnavatel.dovera.dev/externa-firma',
    insuree: 'https://master.dovera-mojadovera.dovera.dev',
    payer: 'https://master.dovera-zamestnavatel.dovera.dev/',
    provider: 'https://master.dovera-poskytovatel.dovera.dev',
    auditor: `${window.env?.epLinkSwitchRole}Auditor`,
    executor: `${window.env?.epLinkSwitchRole}Executor`,
    debtbuyer: `${window.env?.epLinkSwitchRole}Debtbuyer`,
  },
  test: {
    broker: 'https://test.via.dovera.sk/',
    external: 'https://test.zamestnavatel.mojadovera.sk/externa-firma',
    insuree: 'https://test.mojadovera.sk/poistenec/',
    payer: 'https://test.zamestnavatel.mojadovera.sk',
    provider: 'https://test.poskytovatel.dovera.sk',
    auditor: `${window.env?.epLinkSwitchRole}Auditor`,
    executor: `${window.env?.epLinkSwitchRole}Executor`,
    debtbuyer: `${window.env?.epLinkSwitchRole}Debtbuyer`,
  },
  prod: {
    broker: 'https://via.dovera.sk/',
    external: 'https://zamestnavatel.mojadovera.sk/externa-firma',
    insuree: 'https://mojadovera.sk/poistenec/',
    payer: 'https://zamestnavatel.mojadovera.sk',
    provider: 'https://lekarom.dovera.sk/',
    auditor: `${window.env?.epLinkSwitchRole}Auditor`,
    executor: `${window.env?.epLinkSwitchRole}Executor`,
    debtbuyer: `${window.env?.epLinkSwitchRole}Debtbuyer`,
  },
};

const oldDomain = window?.env?.epLinkPure;

export const epSekcie: Record<EP_SEKCIE, string> = {
  'overene-davky': `${oldDomain}&Redirect=Provider/BatchTest.aspx?List=1`,
  'overenie-davok': `${oldDomain}&Redirect=Provider/BatchTest.aspx`,
  'chybove-davky': `${oldDomain}&Redirect=Provider/BatchList.aspx?Typ=C`,
  'odoslanie-davok': `${oldDomain}&Redirect=Provider/BatchUpload2.aspx`,
  'prehlad-davok': `${oldDomain}&Redirect=Provider/BatchList.aspx`,
  faktury: `${oldDomain}&Redirect=Provider/Faktury.aspx`,
  'zdravotna-karta': `${oldDomain}&Redirect=Provider/PatientCard.aspx`,
  'kapitovani-poistenci': `${oldDomain}&Redirect=Provider/Kapitacie.aspx`,
  'odideni-kapitovani-poistenci': `${oldDomain}&Redirect=Provider/FinishedInsurees.aspx`,
  'overenie-poistneho-vztahu': `${oldDomain}&Redirect=Provider/InsureeCheck.aspx`,
  'prehlad-hromadnych-overeni': `${oldDomain}&Redirect=Provider/InsureeCheck.aspx?List=1`,
  'dispenzarizovani-poistenci': `${oldDomain}&Redirect=Provider/DispenzacneZaznamy.aspx`,
  'dispenzarizovane-zaznamy': `${oldDomain}&Redirect=Provider/DispenzacneZaznamy.aspx`,
  dlznici: `${oldDomain}&Redirect=Provider/Debtors.aspx`,
  'novy-navrh': `${oldDomain}&Redirect=Provider/ProposalSubmit2.aspx`,
  'zoznam-podanych-navrhov': `${oldDomain}&Redirect=Provider/ProposalList.aspx`,
  'prehlad-pripadov': `${oldDomain}&Redirect=Provider/CNP.aspx`,
  'novy-pripad': `${oldDomain}&Redirect=Provider/CNP.aspx?New=1`,
  'produkty-na-sklade': `${oldDomain}&Redirect=Provider/CNPLieky.aspx`,
  'ziadost-o-presun': `${oldDomain}&Redirect=Provider/CNPPresun.aspx`,
  'prehlad-objednavok': `${oldDomain}&Redirect=Provider/CNPObjednavky.aspx`,
  'overenie-lekara': `${oldDomain}&Redirect=Provider/KontrolyVztahov.aspx?Tab=OverLekara`,
  'overenie-ambulancie': `${oldDomain}&Redirect=Provider/KontrolyVztahov.aspx?Tab=OverOdd`,
  'overenie-uvazku': `${oldDomain}&Redirect=Provider/KontrolyVztahov.aspx?Tab=OverUvazok`,
  'prehlad-lekarov': `${oldDomain}&Redirect=Provider/KontrolyVztahov.aspx?Tab=Lekari`,
  'prehlad-ambulancii': `${oldDomain}&Redirect=Provider/KontrolyVztahov.aspx?Tab=Oddelenia`,
  'prehlad-uvazkov': `${oldDomain}&Redirect=Provider/KontrolyVztahov.aspx?Tab=Uvazkov`,
  'podpisane-lieky-a-pomocky': `${oldDomain}&Redirect=Provider/HealthCare.aspx?View=PLP`,
  'navratove-chyby': `${oldDomain}&Redirect=WSErrors.aspx`,
  'sprava-pristupov': `${oldDomain}&Redirect=AccessList.aspx?2`,
  profil: `${oldDomain}&Redirect=Provider/Profile.aspx`,
  spravy: `${oldDomain}&Redirect=MessageBox.aspx`,
};

export const AppLinksByEnv = (role) => {
  switch (window.env.BUILD_VARIANT) {
    case 'dev':
    case 'review':
      return epLinks.dev[role];
    case 'test':
      return epLinks.test[role];
    case 'production':
      return epLinks.prod[role];
    default:
      return epLinks.prod[role];
  }
};

export default epLinks;
