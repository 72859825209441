import { useEffect, useState } from 'react';
import useStyles from '../../Profil.styles';
import StaticMenuLayout from '../../../../layouts/StaticMenuLayout';
import {
  Container,
  Loader,
  PageSection,
  Pagination,
} from '@dovera/design-system';
import BackButton from '../../../../components/BackButton/BackButton';
import routes from '../../../../routes';
import { cx } from '../../../../utils/exports';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { useLocation } from 'react-router';
import { NoData } from '../../../../components/EmptyState';
import strings from '../../../../constants/strings';
import AmbulanceCard from './AmbulanceCard/AmbulanceCard';
import {
  DajDetailAmbulanciePayload,
  DajZoznamAmbulanciiPZSPayload,
} from '../../../../types/api/poskytovatel';
import {
  fetchAmbulanceDetail,
  fetchAmbulances,
} from '../../../../api/poskytovatel';
import TableAmbDoctors from './TableAmbDoctors/TableAmbDoctors';
import { LoadingState } from '../../../../types';
import AppError from '../../../app-error/AppError';
import { DATE_INTERNATIONAL, getMoment } from '../../../../utils/date.utils';
import { useAppDispatch } from '../../../../hooks/useStore';
import { getPZS } from '../../../../slices/auth.slice';

const texts = strings.profile.overeniaPrehlady.ambulanceDetail;

const DetailAmbulancie = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [actualPage, setActualPage] = useState(1);
  const kodAmb: string =
    location.pathname.split('/')[location.pathname.split('/').length - 1];
  const ambulance = useSelector((state: RootState) =>
    state.pzs.overeniaPrehlady.ambulances.data?.filter(
      (d) => d.kod.toLowerCase() === kodAmb.toLowerCase(),
    ),
  )?.[0];
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const { autentifikovaneOsoby, personalizedDataReady, preLekara } =
    useSelector((state: RootState) => state.poskytovatel);
  const {
    ambulances: { dataState, detail },
  } = useSelector((state: RootState) => state.pzs.overeniaPrehlady);
  useEffect(() => {
    if (ambulance && pzs.vzId && personalizedDataReady) {
      const payload: DajDetailAmbulanciePayload = {
        idLekarOsoba: preLekara
          ? autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId || null
          : null,
        idNZ: ambulance.id,
        idVZ: pzs.vzId,
      };
      dispatch(fetchAmbulanceDetail(payload));
    }
    if (!ambulance && pzs.vzId && personalizedDataReady) {
      const payload: DajZoznamAmbulanciiPZSPayload = {
        datumDo: getMoment().format(DATE_INTERNATIONAL),
        idLekarOsoba: preLekara
          ? autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId || null
          : null,
      };
      dispatch(fetchAmbulances(payload));
    }
  }, [
    ambulance,
    dispatch,
    preLekara,
    personalizedDataReady,
    autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId,
    pzs.vzId,
  ]);
  const renderTitle = (
    <h3 className={cx(classes.title, 'mb-large')}>
      {`${kodAmb}${ambulance ? ` - ${ambulance.nazov}` : ''}`}
    </h3>
  );
  const renderEmptyState = (!kodAmb ||
    (!ambulance && dataState === LoadingState.filled) ||
    ((!detail || (!detail.doctors.length && !detail.address.length)) &&
      detail?.detailState === LoadingState.filled)) && (
    <NoData
      illustration="woman-question"
      text={texts.emptyState}
      withoutButtons
    />
  );
  const renderData = detail &&
    (detail.address.length > 0 || detail.workingHours.length > 0) && (
      <AmbulanceCard
        addresses={detail.address}
        workingHours={detail.workingHours}
      />
    );
  const renderDoctorList = detail && detail.doctors.length > 0 && (
    <>
      <h5 className="mb-small">{texts.table.title}</h5>
      <TableAmbDoctors data={detail.doctors[actualPage - 1]} />
      <div className={classes.pagination}>
        <Pagination
          currentPage={actualPage}
          onChange={(pageNumber) => setActualPage(pageNumber)}
          totalPages={detail.doctors.length || 0}
        />
      </div>
    </>
  );
  const renderLoader = (detail?.detailState === LoadingState.fetching ||
    dataState === LoadingState.fetching) && <Loader size={24} />;
  const renderError = detail && detail.isError && <AppError />;
  return (
    <StaticMenuLayout backgroundWhite hideEPNotifications withoutPadding>
      <PageSection className={classes.section}>
        <Container>
          <BackButton
            route={`${routes.overeniaPrehlady}/ambulancie`}
            text="Späť"
          />
          {renderTitle}
          {renderLoader}
          {renderEmptyState}
          {renderData}
          {renderDoctorList}
          {renderError}
        </Container>
      </PageSection>
    </StaticMenuLayout>
  );
};

export default DetailAmbulancie;
