import { cx } from '../../utils/exports';
import useStyles from './Attachment.styles';

interface Props {
  children: any;
  className?: string;
}

const AttachmentsWrapper = ({ children, className }: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.attachmentWrapper, className)}>{children}</div>
  );
};

export default AttachmentsWrapper;
