interface Props {
  color?: string;
  height?: number;
  id: string;
  width?: number;
}

const IconEmptyFile = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 24}
    id={id}
    viewBox={`0 0 ${width || 24} ${height || 24}`}
    width={width || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M5.875 1C5.09091 1 4.35309 1.33283 3.81964 1.90185C3.28824 2.46867 3 3.22443 3 4V20C3 20.7756 3.28824 21.5313 3.81964 22.0982C4.35309 22.6672 5.09091 23 5.875 23H18.125C18.9091 23 19.6469 22.6672 20.1804 22.0982C20.7118 21.5313 21 20.7756 21 20V8C21 7.73474 20.8967 7.4936 20.7282 7.3146L15.1045 1.31606C14.9155 1.11441 14.6514 1 14.375 1H14H5.875ZM13 3H5.875C5.66452 3 5.44852 3.08859 5.27871 3.26973C5.10685 3.45305 5 3.71471 5 4V20C5 20.2853 5.10685 20.547 5.27871 20.7303C5.44853 20.9114 5.66452 21 5.875 21H18.125C18.3355 21 18.5515 20.9114 18.7213 20.7303C18.8931 20.547 19 20.2853 19 20V9H14C13.4477 9 13 8.55228 13 8V3ZM17.6918 7H15V4.12878L17.6918 7Z"
      fill={color || '#3F7C22'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconEmptyFile;
