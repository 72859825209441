import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DajKapitacneDohodyPayload,
  DajKapitacneDohodyResponse,
  DajKapitacneKonfliktyPayload,
  DajKapitacneKonfliktyResponse,
  UlozDokumentDohodyOZSPayload,
  UlozDokumentDohodyOZSResponse,
  UlozKapitacnyUbytokPayload,
  UlozKapitacnyUbytokResponse,
} from '../types/kapitacie.types';

const API_URL = `${window.env?.config?.apiPath}/Kapitacie/`;

export const fetchKapitacneDohody = createAsyncThunk(
  'kapitacie/fetchKapitacneDohody',
  async (req: DajKapitacneDohodyPayload) => {
    const url = `${API_URL}DajKapitacneDohody`;
    const { data }: AxiosResponse<DajKapitacneDohodyResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const fetchKapitacneKonflikty = createAsyncThunk(
  'kapitacie/fetchKapitacneKonflikty',
  async (req: DajKapitacneKonfliktyPayload) => {
    const url = `${API_URL}DajKapitacneKonflikty`;
    const { data }: AxiosResponse<DajKapitacneKonfliktyResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const saveKapitacnyUbytok = createAsyncThunk(
  'kapitacie/saveKapitacnyUbytok',
  async (req: UlozKapitacnyUbytokPayload) => {
    const url = `${API_URL}UlozKapitacnyUbytok`;
    const { data }: AxiosResponse<UlozKapitacnyUbytokResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const saveDocumentOZS = createAsyncThunk(
  'kapitacie/saveDocumentOZS',
  async (req: UlozDokumentDohodyOZSPayload) => {
    const url = `${API_URL}UlozDokumentDohodyOZS`;
    const { data }: AxiosResponse<UlozDokumentDohodyOZSResponse> =
      await axios.post(url, req);
    return data;
  },
);

export const getKapExcelData = async (
  req: DajKapitacneDohodyPayload,
): Promise<any> => {
  const { data } = await axios.post(`${API_URL}DajKapitacneDohody`, req);
  return data;
};
