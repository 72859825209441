import { useCallback, useEffect, useRef, useState } from 'react';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';
import { ButtonLink, Container, Icon } from '@dovera/design-system';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { navrhyRoutes } from '../../../routes';
import useStyles from '../Proposals.styles';
import useStylesApp from '../../../App.styles';
import Stepper from '../../../components/Stepper/Stepper';
import { cx } from '../../../utils/exports';
import Step1 from './Step1/Step1';
import {
  createViewUrl,
  hideFooter,
  scrollToTop,
} from '../../../utils/app.utils';
import { resetProposals } from '../../../utils/spaProposals.utils';
import { useSelector } from 'react-redux';
import { changeActiveStep } from '../../../slices/spaProposals.slice';
import Step2 from './Step2/Step2';
import { RootState } from '../../../rootReducer';
import strings from '../../../constants/strings';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';
import Step5 from './Step5/Step5';
import { SaveProposalPayload } from '../../../types/spaProposals.types';
import { saveProposal } from '../../../api/spaProposals';
import NotValidConditionsModal from '../Modals/NotValidConditionsModal';
import SaveProposalResult from '../SaveProposalResult/SaveProposalResult';
import usePreventAnchorClick from '../../../hooks/usePreventAnchorClick';
import { sendDataLayer } from '../../../utils/gtm.utils';
import { useAppDispatch } from '../../../hooks/useStore';
import { useNavigate } from 'react-router';
import { replaceNewLineChars } from '../../../utils/strings.utils';
import { CancelActionModal } from '../../../components/CancelActionModal';
import { feedbackTrigger } from '../../../slices/feedback.slices';
import { getUserId } from '../../../utils/auth.utils';
import { getPZS } from '../../../slices/auth.slice';
import { getCMSText } from '../../../utils/cms.utils';
import { cmsPath } from '../../../constants/cmsPath';
import { YoutubeModal } from '../../../components/YoutubeEmbed';

const texts = strings.proposals.new;

const NewProposal = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classesApp = useStylesApp();
  const newProposalRef = useRef(null);
  const [youtubeModalVisible, setYoutubeModalVisible] = useState(false);
  const [routeAfterCancel, setRouteAfterCancel] = useState('/');
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [conditionsModalVisible, setConditionsModalVisible] = useState(false);
  const {
    data: {
      formData: { data },
    },
    save,
    stepper: { activeStep, step1, step2, step3, step4, step5 },
  } = useSelector((state: RootState) => state.spaProposals.new);
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const idHz = useSelector((state: RootState) => state.poskytovatel.idHz);
  const onContinueCallback = useCallback(
    (step: number) => {
      hideFooter(true);
      dispatch(changeActiveStep({ step }));
      setTimeout(() => {
        hideFooter(false);
      }, 500);
    },
    [dispatch],
  );
  const onProposalCancel = useCallback(() => {
    setConditionsModalVisible(false);
    setCancelModalVisible(true);
  }, []);
  const onProposalEdit = useCallback(() => {
    setConditionsModalVisible(false);
    if (step3.stepStatus !== 'completed' && step2.stepStatus === 'completed')
      dispatch(changeActiveStep({ step: 2 }));
    if (step2.stepStatus !== 'completed')
      dispatch(changeActiveStep({ step: 1 }));
    scrollToTop(200);
  }, [dispatch, step2.stepStatus, step3.stepStatus]);
  const onSubmit = useCallback(() => {
    const payload: SaveProposalPayload = {
      datumUdalosti: step3.eventDate || null,
      dovod: '',
      dovodDoplnujuci: '',
      dovodSprievodu: step5.helpingEscort,
      epikriza: replaceNewLineChars(step3.reasonDescription),
      idPZSHlavnaZlozka: idHz,
      kodDiagnoza: step3.diagnose?.kod || '',
      kodIndikacnaSkupina: step3.indicatorGroup?.kod || '',
      kodTypKupelnejStarostlivosti: step5.spaType || '',
      kodVedlajsiaDiagnoza: step4.diagnoses.map((d) => d.kod) || [],
      navrhujucaNZ: {
        id: step1.ambulance.id,
        kod: step1.ambulance.code,
        odbornost: step1.ambulance.expertise,
      },
      navrhujuciLekar: {
        id: step1.doctor.id,
        kod: step1.doctor.code,
        odbornost: step1.doctor.expertise,
      },
      objektivnyNalez: replaceNewLineChars(step4.description) || '',
      pohyblivostPacienta: step5.mobility || '',
      popis: '',
      poznamka: '',
      priznakKlinALabVysetreniaNieViacAko3M: step3.spaSVLZ === 'Y',
      priznakKontraindikacie: step3.spaContraindications === 'Y',
      priznakVsetkyVysetrenia: step3.spaExaminations === 'Y',
      priznakVysetrenieNad70R: step3.spaConfirmation === 'Y',
      rodneCislo: step2.insureeId,
      skupinaKupelov: data?.udajDetIndSkup.kodProduktPZS || '',
      sprievod: step5.escort === 'Y',
    };
    sendDataLayer('kupele_stepper_submit');
    dispatch(saveProposal(payload));
    dispatch(feedbackTrigger({ delay: 10, userId: getUserId(pzs.userId) }));
  }, [data, dispatch, idHz, pzs.userId, step1, step2, step3, step4, step5]);
  useEffect(() => () => resetProposals(), []);
  useEffect(() => {
    // Send GTM events on active step
    sendDataLayer(`kupele_stepper_${activeStep + 1}`);
  }, [activeStep]);
  usePreventAnchorClick(
    !!step1.doctor.id && !save.data && !save.error,
    (e) => {
      const t = e?.target;
      // @ts-ignore
      const href = !t?.href ? t?.closest('a')?.href : t?.href || '/';
      setRouteAfterCancel(href);
      onProposalCancel();
    },
    true,
  );
  const renderHeader = (
    <ContentHeader padBottom>
      <Container>
        <Breadcrumbs
          items={[
            {
              name: texts.breadcrumbs.proposals,
              route: '#',
            },
            {
              name: texts.breadcrumbs.listOfProposals,
              route: navrhyRoutes.zoznamPodanychNavrhov,
            },
            {
              name: texts.title,
            },
          ]}
        />
      </Container>
      <h2 className="no-mrg">{texts.title}</h2>
      <div className={classes.headerDescription}>
        {getCMSText(cmsPath.proposals.videomanual.description, '', false)}
        <ButtonLink
          className="inline-btn"
          onClick={() => setYoutubeModalVisible(true)}
        >
          <Icon name="social-youtube" size="medium" />
          {getCMSText(cmsPath.proposals.videomanual.btn, '', true)}
        </ButtonLink>
      </div>
      <YoutubeModal
        embedId="SOoOV08YQQg"
        id="vytvorenie-kupelneho-navrhu"
        isVisible={youtubeModalVisible}
        onHide={() => setYoutubeModalVisible(false)}
        // @ts-ignore
        title={
          getCMSText(cmsPath.proposals.videomanual.videoTitle, '', false) || ''
        }
      />
    </ContentHeader>
  );
  const renderStepper = (
    <div>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        <Step1 onContinue={() => onContinueCallback(1)} />
        <Step2
          onCancel={onProposalCancel}
          onContinue={() => onContinueCallback(2)}
        />
        <Step3
          onCancel={onProposalCancel}
          onContinue={() => onContinueCallback(3)}
        />
        <Step4 onContinue={() => onContinueCallback(4)} />
        <Step5
          isVisibleConditionsModal={conditionsModalVisible}
          onContinue={() => {
            if (step2.stepStatus === 'error' || step3.stepStatus === 'error') {
              setConditionsModalVisible(true);
            } else {
              onSubmit();
            }
          }}
        />
      </Stepper>
    </div>
  );
  if (save.data || save.error)
    return (
      <SaveProposalResult proposalNumber={save?.data?.evidencneCislo || ''} />
    );
  return (
    <StaticMenuLayout
      backgroundWhite
      contentHeader={renderHeader}
      mustCheckSystems={{ systems: ['MD', 'TXS'] }}
      withoutPadding
    >
      <div ref={newProposalRef}>
        <div className={cx(classesApp.pageLayout, classes.section)}>
          {renderStepper}
        </div>
        <NotValidConditionsModal
          isVisible={conditionsModalVisible}
          onCancel={onProposalCancel}
          onEdit={onProposalEdit}
          onHide={() => setConditionsModalVisible(false)}
          onSubmit={onSubmit}
        />
        <CancelActionModal
          isVisible={cancelModalVisible}
          onCancel={() => {
            resetProposals();
            navigate(createViewUrl(routeAfterCancel));
            setCancelModalVisible(false);
          }}
          onContinue={() => setCancelModalVisible(false)}
          onHide={() => setCancelModalVisible(false)}
          texts={{
            btnCancel: texts.buttons.cancel,
            btnContinue: texts.buttons.continue,
            description: texts.modals.cancelProposal.description,
            title: texts.modals.cancelProposal.title,
          }}
        />
      </div>
    </StaticMenuLayout>
  );
};

export default NewProposal;
