import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/* eslint-disable */
export enum MenuItemDropdown {
  cakacky = 'cakacie-listiny',
  centralnyNakup = 'centralny-nakup',
  ePacienti = 'e-pacienti',
  eSluzby = 'e-sluzby',
  kartaPZS = 'karta-poskytovatela',
  kodyLekarov = 'kody-lekarov',
  navrhy = 'navrhy',
  pacienti = 'pacienti',
  parametre = 'parametre',
  zdravotnaStarostlivost = 'zdravotna-starostlivost',
  zuctovanie = 'zuctovanie',
  kvalita = 'kvalita',
  efektivnost = 'efektivnost',
  inovacie = 'inovacie',
  prehladPacientov = 'prehlad-pacientov',
  zmluvy = 'zmluvy',
}
/* eslint-enable */
interface MenuState {
  isOpen: boolean;
  isStatic: boolean;
  openedItems: Record<MenuItemDropdown, boolean>;
}

const initialState: MenuState = {
  isOpen: false,
  isStatic: true,
  openedItems: {
    'cakacie-listiny': true,
    'centralny-nakup': false,
    'e-pacienti': false,
    'e-sluzby': false,
    'karta-poskytovatela': false,
    'kody-lekarov': false,
    'zdravotna-starostlivost': false,
    navrhy: false,
    pacienti: false,
    parametre: false,
    zuctovanie: true,
    efektivnost: false,
    inovacie: false,
    kvalita: false,
    'prehlad-pacientov': false,
    zmluvy: false,
  },
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleItem(state, action: PayloadAction<MenuItemDropdown | string>) {
      if (
        action.payload === MenuItemDropdown.eSluzby ||
        action.payload === MenuItemDropdown.zdravotnaStarostlivost
      )
        return {
          ...state,
          openedItems: {
            ...state.openedItems,
            'zdravotna-starostlivost':
              action.payload === MenuItemDropdown.eSluzby
                ? false
                : !state.openedItems[action.payload],
            'e-sluzby':
              action.payload === MenuItemDropdown.zdravotnaStarostlivost
                ? false
                : !state.openedItems[action.payload],
          },
        };
      return {
        ...state,
        openedItems: {
          ...state.openedItems,
          [action.payload]: !state.openedItems[action.payload],
        },
      };
    },
    toggleMenu(state) {
      state.isOpen = !state.isOpen;
    },
    setStaticMenuPage(state) {
      state.isOpen = true;
      state.isStatic = true;
    },
    setStandardMenuPage(state) {
      state.isOpen = false;
      state.isStatic = false;
    },
    wrapAllMenuItems() {
      return {
        ...initialState,
        openedItems: {
          'cakacie-listiny': false,
          'centralny-nakup': false,
          'e-pacienti': false,
          'e-sluzby': false,
          'karta-poskytovatela': false,
          'kody-lekarov': false,
          'prehlad-pacientov': false,
          'zdravotna-starostlivost': false,
          efektivnost: false,
          inovacie: false,
          kvalita: false,
          navrhy: false,
          pacienti: false,
          parametre: false,
          zuctovanie: false,
          zmluvy: false,
        },
      };
    },
  },
});

export const {
  setStandardMenuPage,
  setStaticMenuPage,
  toggleItem,
  toggleMenu,
  wrapAllMenuItems,
} = menuSlice.actions;

export default menuSlice.reducer;
