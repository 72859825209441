import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  modal: {
    maxWidth: getRem(728),
    '& button.btn--small': {
      minWidth: 105,
    },
  },
});
