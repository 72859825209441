import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  FeedbackState,
  RATING_BAD_OPTIONS,
  RATING_GOOD_OPTIONS,
  RATING_TRIGGER_OPTIONS,
  RatingOption,
} from '../types/feedback.types';
import { LoadingState } from '../types';
import { sendFeedback } from '../api/poskytovatel';
import { UlozSpatnuVazbuResponse } from '../types/api/poskytovatel';
import strings from '../constants/strings';
import {
  isTriggerInQuarantine,
  setTriggerQuarantine,
} from '../utils/feedback.utils';

const initialState: FeedbackState = {
  dataState: LoadingState.none,
  error: null,
  open: false,
  rating: 0,
  ratingOptions: [],
  text: '',
  trigger: {
    delay: 0,
    show: false,
    url: '',
  },
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    feedbackTrigger(
      state,
      // delay in seconds
      action: PayloadAction<{ delay?: number; path?: string; userId: number }>,
    ) {
      const { delay, path, userId } = action.payload;
      if (state.open || state.trigger.show || isTriggerInQuarantine(userId))
        return state;
      return {
        ...state,
        trigger: {
          delay: delay || 0,
          show: true,
          url: path ? `${window.location.origin}${path}` : window.location.href,
        },
      };
    },
    setRating(state, action: PayloadAction<{ rating: number }>) {
      let ratingOptions = {};
      ratingOptions =
        action.payload.rating < 7 ? RATING_BAD_OPTIONS : RATING_GOOD_OPTIONS;
      if (state.trigger.show) ratingOptions = RATING_TRIGGER_OPTIONS;

      const options: RatingOption[] = Object.keys(ratingOptions).map(
        (r, key) => ({
          checked: false,
          index: key,
          key: `rating-option-${key}`,
          value: ratingOptions[r],
        }),
      );
      return {
        ...state,
        open: true,
        rating: action.payload.rating,
        ratingOptions:
          state.rating === action.payload.rating
            ? state.ratingOptions
            : options,
        text: '',
      };
    },
    setRatingOptions(state, action: PayloadAction<{ index: number }>) {
      return {
        ...state,
        ratingOptions: state.ratingOptions.map((r) => ({
          ...r,
          checked: r.index === action.payload.index ? !r.checked : r.checked,
        })),
      };
    },
    setText(state, action: PayloadAction<{ text: string }>) {
      return {
        ...state,
        text: action.payload.text,
      };
    },
    toggleFeedbackForm(
      state,
      action: PayloadAction<{ setQuarantine?: boolean; userId?: number }>,
    ) {
      if (action.payload.setQuarantine)
        setTriggerQuarantine(Number(action.payload.userId));
      return {
        ...initialState,
        open: !state.open,
        trigger: !state.open ? state.trigger : initialState.trigger,
      };
    },
    resetFeedbackForm(state) {
      return {
        ...initialState,
        open: true,
        trigger: state.trigger,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendFeedback.pending.toString(), (state) => {
        state.dataState = LoadingState.fetching;
        state.error = null;
      })
      .addCase(
        sendFeedback.fulfilled.toString(),
        (state, action: PayloadAction<UlozSpatnuVazbuResponse>) => {
          state.dataState = LoadingState.filled;
          state.error = !action.payload.ulozene ? strings.defaultError : null;
        },
      )
      .addCase(sendFeedback.rejected.toString(), (state) => {
        state.dataState = LoadingState.error;
        state.error = strings.defaultError;
      });
  },
});

export const {
  feedbackTrigger,
  resetFeedbackForm,
  setRating,
  setRatingOptions,
  setText,
  toggleFeedbackForm,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;
