import { Tag, color } from '@dovera/design-system';
import IconReply from '../../../components/CustomIcons/IconReply';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { KategoriaSprav } from '../../../types/spravy.types';
import { DATE_FORMAT_SLOVAK, formatDate } from '../../../utils/date.utils';
import { cx } from '../../../utils/exports';
import { calculateChars } from '../../../utils/spravy.utils';
import {
  getListFromString,
  splitText,
  stripHtml,
} from '../../../utils/strings.utils';
import useStyles from '../Spravy.styles';

interface Props {
  badge: 'A' | 'N' | string | null;
  date: string;
  description: string;
  id: number;
  isDeleted: boolean;
  sentByPZS: boolean;
  title: string;
  unread: boolean;
}

const MessagePreview = ({
  badge,
  date,
  description,
  id,
  isDeleted,
  sentByPZS,
  title,
  unread,
}: Props) => {
  const classes = useStyles({});
  const windowSize = useWindowSize();

  return (
    <>
      <b style={{ marginLeft: isDeleted && !unread ? 0 : '2rem' }}>
        {splitText(title, calculateChars('title', windowSize))}
      </b>
      <span className={classes.threadDate}>
        {formatDate(date, DATE_FORMAT_SLOVAK)}
      </span>
      <div
        className="d-flex"
        style={{ marginLeft: isDeleted && !unread ? 0 : '2rem' }}
      >
        {sentByPZS && (
          <span className="text-space-half-right">
            <IconReply color={color('black')} id={`icon-reply-thread--${id}`} />
          </span>
        )}
        {splitText(
          `${sentByPZS ? 'Vy:' : ''} ${stripHtml(
            getListFromString(description)
              .map((d) => d)
              .join(' '),
          )}`,
          calculateChars('description', windowSize) - (badge ? 10 : 0),
        )}
        {badge && (
          <span
            className={cx(
              badge === KategoriaSprav.Archiv && classes.badgeArchiv,
            )}
          >
            <Tag>
              {badge === KategoriaSprav.Archiv ? 'Archív' : 'Notifikácia'}
            </Tag>
          </span>
        )}
      </div>
    </>
  );
};

export default MessagePreview;
