import { Select } from '@dovera/design-system';
import { AppLinksByEnv } from '../../constants/epLinks';
import useStyles from './SwitchRole.styles';
import { jwtDecode } from 'jwt-decode';

interface Props {
  impersonalization: boolean;
  show: boolean;
  subjectAccess: boolean;
  token: string | null;
}

type AvailableRole = {
  name: string;
  role:
    | 'dzp_provider_id'
    | 'dzp_insuree_id'
    | 'dzp_payer_id'
    | 'dzp_external_id'
    | 'dzp_broker_id'
    | 'dzp_auditor_id'
    | 'dzp_executor_id'
    | 'dzp_debtbuyer_id';
  value: string;
};

const SwitchRole = ({
  impersonalization,
  show,
  subjectAccess,
  token,
}: Props) => {
  const classes = useStyles();
  const availableRoles: AvailableRole[] = [
    {
      name: 'Poskytovateľ',
      role: 'dzp_provider_id',
      value: 'provider',
    },
    {
      name: 'Poistenec',
      role: 'dzp_insuree_id',
      value: 'insuree',
    },
    {
      name: 'Zamestnávateľ',
      role: 'dzp_payer_id',
      value: 'payer',
    },
    {
      name: 'Externá firma',
      role: 'dzp_external_id',
      value: 'external',
    },
    {
      name: 'Maklér',
      role: 'dzp_broker_id',
      value: 'broker',
    },
    {
      name: 'Audítor',
      role: 'dzp_auditor_id',
      value: 'auditor',
    },
    {
      name: 'Exekútor',
      role: 'dzp_executor_id',
      value: 'executor',
    },
    {
      name: 'Dlžník',
      role: 'dzp_debtbuyer_id',
      value: 'debtbuyer',
    },
  ];
  if (!show || (!subjectAccess && !impersonalization)) return <span />;
  if (subjectAccess && !impersonalization)
    return (
      <Select
        className={classes.subjectAccess}
        id="subject-access-dropdown"
        onChange={(value) => {
          if (value) window.location.href = AppLinksByEnv('payer');
        }}
        options={[
          { value: '', label: 'Poskytovateľ' },
          {
            value: 'ZAM',
            label: 'Zamestnávateľ',
          },
        ]}
        type="inline"
      />
    );

  const roles: { label: string; value: string }[] = availableRoles
    // @ts-ignore
    .filter((a) => Number(jwtDecode(token)[a.role]) > 0)
    .map((r) => ({ label: r.name, value: r.value }));

  return (
    <Select
      className={classes.subjectAccess}
      id="subject-access-dropdown"
      onChange={(value) => {
        if (value) window.location.href = AppLinksByEnv(value);
      }}
      options={roles}
      type="inline"
    />
  );
};

export default SwitchRole;
