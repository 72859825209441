interface Props {
  color?: string;
  id: string;
}

const IconHospital = ({ color, id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      height="20"
      rx="3"
      stroke={color || '#9EA8A8'}
      strokeWidth="2"
      width="20"
      x="2"
      y="2"
    />
    <path
      d="M8 7V12M8 17V12M8 12H16M16 12V7M16 12V17"
      stroke={color || '#9EA8A8'}
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconHospital;
