import { Skeleton } from '@dovera/design-system';
import useStyles from './WidgetPreloader.styles';

const WidgetPreloader = () => {
  const classes = useStyles();
  return (
    <div className={classes.preloader}>
      <div>
        <Skeleton height={32} width={32} />
      </div>
      <div>
        <h6 className="mb-xxsmall">
          <Skeleton height={27} width="20%" />
        </h6>
        <span>
          <Skeleton height={17} width="30%" />
        </span>
      </div>
    </div>
  );
};

export default WidgetPreloader;
