import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAppDispatch } from '../hooks/useStore';
import { setPrevRoute } from '../slices/app.slice';
import { feedbackTrigger } from '../slices/feedback.slices';
import { getUserId } from '../utils/app.utils';

export const RouterContext = createContext<{ from: string; to: string }>({
  to: '',
  from: '',
});

const RouterProvider = ({ children }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  });

  useEffect(() => {
    setRoute((prev) => {
      if (prev.to !== location.pathname) {
        dispatch(setPrevRoute({ route: prev.to }));
        if (prev.to === '/prehlad-cenovych-podmienok')
          dispatch(feedbackTrigger({ delay: 10, userId: getUserId() }));
      }
      return { to: location.pathname, from: prev.to };
    });
  }, [dispatch, location]);

  return (
    <RouterContext.Provider value={route}>{children}</RouterContext.Provider>
  );
};

export default RouterProvider;
