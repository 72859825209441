import { breakpoints } from '@dovera/design-system';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { CustomTooltip } from '../../CustomTooltip';

interface Props {
  color: string;
  lineHeight: number;
  text: string;
  width: number;
}

const Pointer = ({ color, lineHeight, text, width }: Props) => {
  const windowSize = useWindowSize();
  const renderLine = (
    <svg
      fill="none"
      height={lineHeight + 19}
      viewBox={`0 0 ${width + 10} ${lineHeight + 19}`}
      width={width + 10}
    >
      <line
        stroke={color}
        strokeLinecap="round"
        strokeWidth={width}
        x1="7"
        x2="7"
        y1="2"
        y2={lineHeight}
      />
      <circle
        cx="7"
        cy={lineHeight + 8}
        r="5"
        stroke={color}
        strokeWidth={width}
      />
    </svg>
  );
  if (windowSize.width < breakpoints.xl)
    return (
      <CustomTooltip dialog={text} id={`pointer--${lineHeight}`}>
        {renderLine}
      </CustomTooltip>
    );
  return renderLine;
};

export default Pointer;
