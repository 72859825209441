interface Props {
  id: string;
  isBold?: boolean;
}

const IconUserBlue = ({ id, isBold }: Props) => {
  if (isBold)
    return (
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M6.33342 4.66667C6.33342 3.74619 7.07961 3 8.00008 3C8.92056 3 9.66675 3.74619 9.66675 4.66667C9.66675 5.58714 8.92056 6.33333 8.00008 6.33333C7.07961 6.33333 6.33342 5.58714 6.33342 4.66667ZM8.00008 1C5.97504 1 4.33341 2.64162 4.33341 4.66667C4.33341 6.69171 5.97504 8.33333 8.00008 8.33333C10.0251 8.33333 11.6667 6.69171 11.6667 4.66667C11.6667 2.64162 10.0251 1 8.00008 1ZM5.33341 9C4.36095 9 3.42832 9.38631 2.74069 10.0739C2.05306 10.7616 1.66675 11.6942 1.66675 12.6667V14C1.66675 14.5523 2.11446 15 2.66675 15C3.21903 15 3.66675 14.5523 3.66675 14V12.6667C3.66675 12.2246 3.84234 11.8007 4.1549 11.4882C4.46746 11.1756 4.89139 11 5.33341 11H10.6667C11.1088 11 11.5327 11.1756 11.8453 11.4882C12.1578 11.8007 12.3334 12.2246 12.3334 12.6667V14C12.3334 14.5523 12.7811 15 13.3334 15C13.8857 15 14.3334 14.5523 14.3334 14V12.6667C14.3334 11.6942 13.9471 10.7616 13.2595 10.0739C12.5718 9.38631 11.6392 9 10.6667 9H5.33341Z"
          fill="#1F57A5"
          fillRule="evenodd"
        />
      </svg>
    );
  return (
    <svg
      fill="none"
      height="24"
      id={id}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M8.5 7C8.5 5.34315 9.84315 4 11.5 4C13.1569 4 14.5 5.34315 14.5 7C14.5 8.65685 13.1569 10 11.5 10C9.84315 10 8.5 8.65685 8.5 7ZM11.5 2C8.73858 2 6.5 4.23858 6.5 7C6.5 9.76142 8.73858 12 11.5 12C14.2614 12 16.5 9.76142 16.5 7C16.5 4.23858 14.2614 2 11.5 2ZM8 14C6.67392 14 5.40215 14.5268 4.46447 15.4645C3.52678 16.4021 3 17.6739 3 19V21C3 21.5523 3.44772 22 4 22C4.55228 22 5 21.5523 5 21V19C5 18.2044 5.31607 17.4413 5.87868 16.8787C6.44129 16.3161 7.20435 16 8 16H15C15.7956 16 16.5587 16.3161 17.1213 16.8787C17.6839 17.4413 18 18.2043 18 19V21C18 21.5523 18.4477 22 19 22C19.5523 22 20 21.5523 20 21V19C20 17.6739 19.4732 16.4021 18.5355 15.4645C17.5979 14.5268 16.3261 14 15 14H8Z"
        fill="#1F57A5"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default IconUserBlue;
