import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import strings from '../../constants/strings';
import { RootState } from '../../rootReducer';
import { useAuthContext } from '../../hooks/useAuthContext';
import useCountdown from '../../hooks/useCountdown';
import { Button, ButtonLink, Modal } from '@dovera/design-system';
import useStyles from './AutoLogoutModal.styles';
import { hideLogoutWarning } from '../../slices/userActivity.slice';
import { cx } from '../../utils/exports';
import { useAppDispatch } from '../../hooks/useStore';
import routes from '../../routes';

const texts = strings.automatickeOdhlasenie;

const COUNTDOWN_TIME_SECONDS = 60 * 3;

/* eslint-disable */
const formatTime = (seconds) =>
  // @ts-ignore
  [parseInt((seconds / 60) % 60, 0), parseInt(seconds % 60, 0)]
    .join(':')
    .replace(/\b(\d)\b/g, '0$1');
/* eslint-enable */

const AutoLogoutModal = () => {
  const dispatch = useAppDispatch();
  const showLogoutModal = useSelector(
    (state: RootState) => state.userActivity.showLogoutWarningModal,
  );
  const [isVisible, setIsVisible] = useState(false);
  const authContext = useAuthContext();
  const classes = useStyles();

  const handleLogout = useCallback(() => {
    authContext.logout(routes.logout);
  }, [authContext]);

  const handleAutoLogout = useCallback(() => {
    authContext.logout(`${routes.logout}?auto=1`);
  }, [authContext]);

  const { cancelTimer, startTimer, timeLeft } = useCountdown(
    COUNTDOWN_TIME_SECONDS,
    handleAutoLogout,
  );

  const onContinueModalClick = useCallback(() => {
    // @ts-ignore
    cancelTimer();
    dispatch(hideLogoutWarning());
    setIsVisible(false);
  }, [dispatch, cancelTimer]);

  const formattedTime = formatTime(timeLeft);

  useEffect(() => {
    if (showLogoutModal) {
      startTimer();
      setIsVisible(true);
    } else {
      cancelTimer();
      setIsVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLogoutModal]);

  const renderFooter = (
    <div className="d-flex-row align-items-center no-pad-top">
      <div>
        <ButtonLink
          className="text-color-error no-mrg-bottom"
          onClick={handleLogout}
        >
          {texts.odhlasitSa}
        </ButtonLink>
        <Button
          className="no-mrg-bottom"
          onClick={onContinueModalClick}
          size="s"
        >
          {texts.pokracovat}
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      className={classes.modal}
      closeOnOverlayClick={false}
      data-modal-initial-focus
      footer={renderFooter}
      header={texts.nadpis}
      id="automaticke-odhlasenie-modal"
      isVisible={isVisible}
      onHide={onContinueModalClick}
    >
      <div className="d-flex-column align-items-middle">
        <div>
          <p className="no-mrg-bottom">{texts.zostavajuciCas}</p>
          <h1
            className={cx(
              classes.timeContainer,
              'text-color-black text-normal no-mrg-bottom',
            )}
          >
            {formattedTime}
          </h1>
        </div>
      </div>
    </Modal>
  );
};

export default AutoLogoutModal;
