import { color } from '@dovera/design-system';

interface Props {
  className?: any;
}

const Minus = ({ className }: Props) => (
  <svg
    className={className}
    fill="none"
    height="3"
    viewBox="0 0 8 3"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.632 0.0639999H7.016V2.728H0.632V0.0639999Z"
      fill={color('primary', 600)}
    />
    <path
      d="M0.632 0.064003V-0.935997H-0.368V0.064003H0.632ZM7.016 0.064003H8.016V-0.935997H7.016V0.064003ZM7.016 2.728V3.728H8.016V2.728H7.016ZM0.632 2.728H-0.368V3.728H0.632V2.728ZM0.632 1.064H7.016V-0.935997H0.632V1.064ZM6.016 0.064003V2.728H8.016V0.064003H6.016ZM7.016 1.728H0.632V3.728H7.016V1.728ZM1.632 2.728V0.064003H-0.368V2.728H1.632Z"
      fill={color('primary', 600)}
      mask="url(#path-1-outside-1_24107_72136)"
    />
  </svg>
);

export default Minus;
