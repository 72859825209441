import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DajPocetNeprecitanychVlakienResponse,
  DajZoznamSpravVlaknaPayload,
  DajZoznamSpravVlaknaResponse,
  DajZoznamTemPZSResponse,
  DajZoznamVlakienQueries,
  DajZoznamVlakienResponse,
  KategoriaSprav,
  UlozSpravuRequest,
  UlozSpravuResponse,
  ZmazVlaknoRequest,
  ZmazVlaknoResponse,
} from '../types/spravy.types';
import { createQueryParams } from '../utils/api.utils';
import store from '../store';
import {
  scrollDetail,
  vyberKategorieVlakien,
  zmenaOffsetuVlakien,
} from '../slices/spravy.slice';
import { WriteResponse } from '../types/api';

const API_CONTROLLER = `SchrankaSprav`;

const API_URL = `${window.env?.config?.apiPath}/${API_CONTROLLER}/`;

export const dajPocetNeprecitanychVlakien = createAsyncThunk(
  'schrankaSprav/dajPocetVlakien',
  async () => {
    const { data }: AxiosResponse<DajPocetNeprecitanychVlakienResponse> =
      await axios.get(`${API_URL}DajPocetNeprecitanychVlakien`);
    return data;
  },
);

export const dajZoznamVlakien = createAsyncThunk(
  'schrankaSprav/dajZoznamVlakien',
  async (queries: DajZoznamVlakienQueries) => {
    if (
      !queries.typVlakna &&
      store.getState().spravy.kategoria !== KategoriaSprav.Vsetky
    )
      store.dispatch(
        vyberKategorieVlakien({ kategoria: KategoriaSprav.Vsetky }),
      );
    if (queries.offset)
      store.dispatch(zmenaOffsetuVlakien({ offset: queries.offset || 0 }));
    const { data }: AxiosResponse<DajZoznamVlakienResponse> = await axios.get(
      `${API_URL}DajZoznamVlakien${createQueryParams({ withoutEmpty: true, queryParams: { ...queries } })}`,
    );
    return data;
  },
);

export const dajZoznamTemPZS = createAsyncThunk(
  'schrankaSprav/dajZoznamTemPZS',
  async () => {
    const { data }: AxiosResponse<DajZoznamTemPZSResponse> = await axios.get(
      `${API_URL}DajZoznamTemPZS`,
    );
    return data;
  },
);

export const dajZoznamSpravVlakna = createAsyncThunk(
  'schrankaSprav/dajZoznamSpravVlakna',
  async ({ id, scrollToBottom }: DajZoznamSpravVlaknaPayload) => {
    if (scrollToBottom) store.dispatch(scrollDetail({ to: 'bottom' }));
    const { data }: AxiosResponse<DajZoznamSpravVlaknaResponse> =
      await axios.get(`${API_URL}DajZoznamSpravVlakna/${id}`);
    return data;
  },
);

export const getRequestHeaderData = async (): Promise<any> => {
  const { data } = await axios.get(`${API_URL}GetRequestHeaderData`);
  return data;
};

export const ulozSpravu = async (
  payload: UlozSpravuRequest,
): Promise<UlozSpravuResponse> => {
  const { data } = await axios.post(`${API_URL}UlozSpravu`, payload);
  return data;
};

export const zmazVlakno = async (
  payload: ZmazVlaknoRequest,
): Promise<ZmazVlaknoResponse> => {
  const { data } = await axios.post(`${API_URL}ZmazVlakno`, payload);
  return data;
};

export const obnovVlakno = async (id: number): Promise<WriteResponse> => {
  const { data } = await axios.put(`${API_URL}ObnovVlakno/${id}`);
  return data;
};
