import { useEffect } from 'react';
import useStyles from './NaRiesenieWidget.styles';
import { Card, CardSection } from '@dovera/design-system';
import { cx } from '../../../utils/exports';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { fetchDashboardSolveWidget } from '../../../api/poskytovatel';
import strings from '../../../constants/strings';
import { useAppDispatch } from '../../../hooks/useStore';
import WidgetPreloader from '../WidgetPreloader/WidgetPreloader';
import { Faktury, Poistenci, SpravaPristupov, Zmluvy } from './sekcie';

const texts = strings.dashboard.solveWidget;

const NaRiesenieWidget = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isASOC, isSUPR, pouzivatelId, sekciaZmluvy, vztahId } = useSelector(
    (state: RootState) => state.auth,
  );
  const { autentifikovaneOsoby, idHz, preLekara } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  const { error, isLoading, widget } = useSelector(
    (state: RootState) => state.widgetNaRiesenie,
  );
  const pzsLoading = useSelector(
    (state: RootState) => state.poskytovatel.isLoading,
  );
  const widgetLoading = pzsLoading || isLoading;
  const idOsobaLekara: number | null = preLekara
    ? autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId || null
    : null;
  useEffect(() => {
    if (pouzivatelId && vztahId && idHz && !widgetLoading && !widget && !error)
      dispatch(
        fetchDashboardSolveWidget({
          idHz,
          idPouzivatel: pouzivatelId,
          idVZ: vztahId,
          isAsoc: isASOC,
        }),
      );
  }, [
    dispatch,
    error,
    idHz,
    idOsobaLekara,
    isASOC,
    widgetLoading,
    pouzivatelId,
    vztahId,
    widget,
  ]);

  return (
    <Card className={cx(classes.root, 'mb')} id="naRiesenie-card">
      <CardSection isCondensed>
        <h3 id="naRiesenie-title">
          {widget?.nadpis || texts.title}
          {!widgetLoading && widget && ` (${widget.pocetNaRiesenie})`}
          {error && <span className="text-color-error">{texts.error}</span>}
        </h3>
        {widgetLoading && <WidgetPreloader />}
        <Faktury />
        <Zmluvy sekciaZmluvy={sekciaZmluvy} />
        <SpravaPristupov
          idHz={idHz}
          isASOC={isASOC}
          isSUPR={isSUPR}
          pouzivatelId={pouzivatelId}
          vztahId={vztahId}
        />
        <Poistenci />
      </CardSection>
    </Card>
  );
};

export default NaRiesenieWidget;
