import axios from 'axios';
import {
  DajBenchmarkDiagnozPayload,
  DajDetailZoznamuPoistencovPayload,
  DajPrehladDiagnostikyPacientovPayload,
  DajPrehladPacientovPayload,
  DajZoznamPoistencovPayload,
} from '../types/pacienti.types';

const API_URL = `${window.env?.config?.apiPath}/PrehladPacientov/`;

export async function dajPrehladDiagnostikyPacientovApi(
  payload: DajPrehladDiagnostikyPacientovPayload,
) {
  const url = `${API_URL}DajPrehladDiagnostikyPacientov`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajPrehladPacientovApi(
  payload: DajPrehladPacientovPayload,
) {
  const url = `${API_URL}DajPrehladPacientov`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajZoznamPoistencovApi(
  payload: DajZoznamPoistencovPayload,
) {
  const url = `${API_URL}DajZoznamPoistencov`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajDetailZoznamuPoistencovApi(
  payload: DajDetailZoznamuPoistencovPayload,
) {
  const url = `${API_URL}DajDetailZoznamuPoistencov`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajBenchmarkDiagnozApi(
  payload: DajBenchmarkDiagnozPayload,
) {
  const url = `${API_URL}DajBenchmarkDiagnoz`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajVucOkresyPreBenchmarkyDiagnozApi(
  idVZ: number,
  kodTypZS: string,
  kodOdbNz: string,
  kodTypObdobie: string,
) {
  const url = `${API_URL}DajVucOkresyPreBenchmarkyDiagnoz`;
  const { data } = await axios.post(url, {
    idVZ,
    kodTypZS,
    kodOdbNz,
    kodTypObdobie,
  });
  return data;
}

export async function archivujPoistencaApi(
  idVZ: number,
  idPouzivatel: number,
  idPoistenca: number,
  kod: string,
  archiv: boolean,
) {
  const url = `${API_URL}ArchivujPoistenca`;
  const { data } = await axios.post(url, {
    idVZ,
    idPouzivatel,
    idPoistenca,
    kod,
    archiv,
  });
  return data;
}
