import { Skeleton } from '@dovera/design-system';
import React from 'react';

const DetailModalSkeleton = () => (
  <>
    <Skeleton className="d-block mb" height={32} width="100%" />
    <h4 className="mb-large">
      <Skeleton width={200} />
    </h4>
    <Skeleton className="d-block mb-large" height={16} width={50} />
    <h4>
      <Skeleton width={300} />
    </h4>
    <Skeleton className="d-block mb-large" height={150} />
  </>
);

export default DetailModalSkeleton;
