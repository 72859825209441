// @ts-nocheck
import { ReactNode, Ref } from 'react';
import IconErrorExcl from '../components/CustomIcons/IconErrorExcl';
import {
  FilterZoznamuPacientov,
  GetPatientListPayload,
  GroupPP,
  KartaPacientaRiadok,
  KodHodnoty,
  KodUdaj,
  KodUdajStr,
  ObdobiePP,
  PacientiFilterState,
  PacientiPageType,
  PoistenecUdaj,
  TypPacientov,
  UdajDetailuPoistenca,
  UdajPP,
  Zoznam,
} from '../types/pacienti.types';
import { RadioButtonType, SelectType, StringMap } from '../types/index';
import { toHashMap } from './array.utils';
import _ from 'lodash';
import routes from '../routes';
import IconDiagnose from '../components/CustomIcons/IconDiagnose';
import { color } from '../constants';
import IconPill from '../components/CustomIcons/IconPill';
import IconUserGray from '../components/CustomIcons/IconUserGray';
import IconCalendar from '../components/CustomIcons/IconCalendar';
import { IconInfo } from '../components';
import IconEuro from '../components/CustomIcons/IconEuro';
import { ns } from './object.utils';
import IconDoctor from '../components/CustomIcons/IconDoctor';
import IconWarning from '../components/CustomIcons/IconWarning';
import {
  getPriceFormat,
  getValueWithNDecimal,
  safeNumber,
} from './number.utils';
import { typyPacientovMoznosti } from '../pages/pacienti/DetailPacientov/Filter/constants';
import {
  calculateAge,
  formatDate,
  getMoment,
  getMonthsBefore,
} from './date.utils';
import IconDate from '../components/CustomIcons/IconDate';
import Man04 from '../assets/illustrations/patients/man0-4.png';
import Man59 from '../assets/illustrations/patients/man5-9.png';
import Man1019 from '../assets/illustrations/patients/man10-19.png';
import Man20 from '../assets/illustrations/patients/man20+.png';
import Woman04 from '../assets/illustrations/patients/woman0-4.png';
import Woman59 from '../assets/illustrations/patients/woman5-9.png';
import Woman1019 from '../assets/illustrations/patients/woman10-19.png';
import Woman20 from '../assets/illustrations/patients/woman20+.png';
import IconHospital from '../components/CustomIcons/IconHospital';
import { nbspStr } from './strings.utils';
import IconMedicament from '../components/CustomIcons/IconMedicament';
import IconFolderPlus from '../components/CustomIcons/IconFolderPlus';
import IconStethoscope from '../components/CustomIcons/IconStethoscope';
import IconCheck from '../components/CustomIcons/IconCheck';
// @ts-check

export const dajObdobiaPP = (
  obdobia: ObdobiePP[],
  typObdobie: 'A' | 'R' | 'Z' | string,
  kodTypZS: string,
  kodOdbNz: string | null,
): ObdobiePP[] =>
  obdobia?.filter(
    (o) =>
      o.kodTypObdobie === typObdobie &&
      o.kodTypZS === kodTypZS &&
      o.kodOdbNz === kodOdbNz,
  );

export const dajUdajPP = (
  udaje: UdajPP[],
  idObdobia: number,
  kodUdaj: KodUdaj,
): UdajPP =>
  udaje?.filter((u) => u.idObdobie === idObdobia && u.kodUdaj === kodUdaj)[0];

export const dajKategoriuUdajovPP = (
  key: GroupPP,
  udaje: UdajPP[],
): UdajPP[] => {
  const grouped = _.groupBy(udaje, 'nazovSkupinaUdaj');
  if (!grouped || !grouped[key]) return [];
  return grouped[key];
};
export const dajSkupinyUdajov = (
  idObdobie: number,
  udaje: UdajPP[],
): Record<KodUdajStr, UdajPP[]> =>
  _.groupBy(
    udaje?.filter((u) => u.idObdobie === idObdobie || !idObdobie),
    'kodUdaj',
  );

export const dajKodyUdajPacienti = (
  kodyHodnoty: KodHodnoty[],
): StringMap<KodHodnoty> | null => {
  if (!kodyHodnoty || !kodyHodnoty.length) return null;
  return toHashMap(kodyHodnoty, 'kodUdaj');
};

export const dajTypStranky = (route: string): PacientiPageType => {
  if (route.includes(routes.ppChronicki)) return 'CHR';
  if (route.includes(routes.ppCerpanieZS)) return 'ZS';
  return 'FARM';
};

const getFarmHypNespNevhRows = (pacient, detailyPacienta) => [
  {
    icon: (
      <IconDiagnose
        colorProp={color('grey', 300)}
        id={`icon-patient-diagnose-${pacient.id}`}
      />
    ),
    text: pacient.text1,
  },
  ...detailyPacienta.map((d) => ({
    icon: <IconPill id={`icon-patient-pill-${pacient.id}`} />,
    text: `<b>${d.text1}</b>`,
    tooltip: d.text2 || '',
  })),
  {
    color: 'red',
    icon: <IconErrorExcl id={`icon-patient-errorExcl-${pacient.id}`} />,
    text: pacient.text2,
  },
];

const getFarmPolyRows = (pacient) => [
  {
    color: 'red',
    icon: <IconErrorExcl id={`icon-patient-errorExcl-${pacient.id}`} />,
    text: pacient.text1,
    tooltip:
      'Obsahuje zoznam liekov, ktoré pacient dlhodobo užíva (ŠDL/dávkovanie pre danú ATC skupinu je dlhšie ako 180 dní). Vylučujeme krátkodobo užívané lieky (antibiotiká, antireumatiká, atď.)',
  },
  ...[
    pacient.datum1
      ? {
          icon: (
            <IconFolderPlus
              color={color('grey', 300)}
              id={`icon-folderplus-${pacient.id}`}
            />
          ),
          text: 'Pacient s klinickým posudkom',
        }
      : {
          icon: '',
          text: '',
        },
  ],
  {
    icon: <IconUserGray id={`icon-patient-userGrey-${pacient.id}`} />,
    text: pacient.text2,
    tooltip:
      'Počet lekárov, ktorí predpisovali pacientovi dlhodobo užívajúci liek, ktorý vstupuje do zoznamu',
  },
];

const getFarmNevybRows = (pacient) => [
  {
    icon: <IconPill id={`icon-patient-pill-${pacient.id}`} />,
    text: `<b>${pacient.text1}</b>`,
    tooltip: `Názov lieku alebo účinnej látky, ktorú evidujeme, že si naposledy pacient nevybral z lekárne. ${pacient.text2}`,
  },
  {
    color: 'red',
    icon: <IconErrorExcl id={`icon-patient-errorExcl-${pacient.id}`} />,
    text: pacient.text3,
  },
];

const getFarmZamRows = (pacient) => [
  {
    icon: <IconPill id={`icon-patient-pill-${pacient.id}`} />,
    text: `<b>${pacient.text1}</b>`,
    tooltip: `Názov lieku, ktorý evidujeme, že bol zamenený v lekárni aj napriek tomu, že liek sa zákona nepredpisuje na základe účinnej látky. ${pacient.text2}`,
  },
  {
    color: 'red',
    icon: <IconErrorExcl id={`icon-patient-errorExcl-${pacient.id}`} />,
    text: pacient.text3,
  },
];

const getFarmPosudRows = (pacient) => [
  {
    icon: (
      <IconStethoscope
        color={color('grey', 300)}
        id={`icon-patient-diagnose-${pacient.id}`}
      />
    ),
    text: `Klinický posudok vykonaný <b>${nbspStr(
      formatDate(pacient.datum1),
    )}</b>`,
  },
  {
    icon: (
      <IconHospital
        colorProp={color('grey', 300)}
        id={`icon-hospital-${pacient.id}`}
      />
    ),
    text: `Hospitalizovaný v ${pacient.text1}`,
  },
];

const getFarmProb01Rows = (pacient) => [
  {
    icon: (
      <IconStethoscope
        color={color('grey', 300)}
        id={`icon-patient-diagnose-${pacient.id}`}
      />
    ),
    text: pacient?.text1,
  },
  {
    icon: (
      <IconMedicament
        colorProp={color('grey', 300)}
        id={`icon-medicament-${pacient.id}`}
      />
    ),
    text: pacient?.text2,
  },
];

const getChrHypStarRows = (pacient) => [
  {
    icon: (
      <IconDiagnose
        colorProp={color('grey', 300)}
        id={`icon-patient-diagnose-${pacient.id}`}
      />
    ),
    text: pacient.text1,
  },
  {
    icon: <IconUserGray id={`icon-patient-userGrey-${pacient.id}`} />,
    text: `<b>${pacient.text3}</b>`,
  },
  {
    icon: <IconCalendar id={`icon-patient-calendar-${pacient.id}`} />,
    text: pacient.datum1 ? getMoment(pacient.datum1).format('D.M.YYYY') : null,
  },
  {
    icon: <IconUserGray id={`icon-patientSecond-userGrey-${pacient.id}`} />,
    text: `<b>${pacient.text4}</b>`,
  },
  {
    icon: <IconCalendar id={`icon-patientSecond-calendar-${pacient.id}`} />,
    text: pacient.datum2 ? getMoment(pacient.datum2).format('D.M.YYYY') : null,
  },
  {
    color: 'red',
    icon: <IconErrorExcl id={`icon-patient-errorExcl-${pacient.id}`} />,
    text: pacient.text2,
  },
];

const getChrDispRows = (pacient) => [
  {
    icon: (
      <IconDiagnose
        colorProp={color('grey', 300)}
        id={`icon-patient-diagnose-${pacient.id}`}
      />
    ),
    text: pacient.text1,
  },
  {
    icon: <IconCalendar id={`icon-patient-calendar-${pacient.id}`} />,
    text: `${pacient.text2}`,
  },
];

const getChrGdmRows = (pacient) => [
  {
    icon: <IconCalendar id={`icon-patientSecond-calendar-${pacient.id}`} />,
    text: `<b>Dátum pôrodu: ${formatDate(
      pacient.datum1 || '',
    )}</b><br />(${getMonthsBefore(pacient.datum1)})`,
  },
  {
    color:
      !pacient.datum2 && getMoment().diff(pacient.datum1, 'M') >= 36 && 'red',
    icon: pacient.datum2 ? (
      <IconCheck
        color={color('success')}
        id={`icon-patient-check-${pacient.id}`}
      />
    ) : (
      <IconErrorExcl
        color={getMoment().diff(pacient.datum1, 'M') < 36 && color('black')}
        id={`icon-patient-errorExcl-${pacient.id}`}
      />
    ),
    text: pacient.datum2
      ? `Pacientka bola vyšetrená:<br />${formatDate(pacient.datum2)}`
      : pacient.text2,
  },
];

const getPocNarocRows = (pacient, usePersonalizedData) => {
  const patientDetail = pacient.detailPoistenecUdaj?.filter(
    (d) => !d.text4 && d.kodTypDetail === 'NAKL_VS',
  )?.[0];
  return [
    {
      icon: <IconEuro id={`icon-patient-euro-${pacient.id}`} />,
      text: (
        <div>
          {!usePersonalizedData ? (
            <>
              <b className="d-block mb-small">
                Náklady na pacienta za celú odbornosť:{' '}
                {getPriceFormat(
                  getValueWithNDecimal(
                    safeNumber(Number(patientDetail?.hodnota1)),
                  ),
                  true,
                )}
              </b>
              <div className="d-block mb-xxsmall">
                Váš náklad na pacienta:{' '}
                {getPriceFormat(
                  getValueWithNDecimal(safeNumber(Number(pacient.hodnota2))),
                  true,
                )}
              </div>
            </>
          ) : (
            <b className="d-block mb-xxsmall">
              Váš náklad na pacienta:{' '}
              {getPriceFormat(
                getValueWithNDecimal(safeNumber(Number(pacient.hodnota2))),
                true,
              )}
            </b>
          )}
        </div>
      ),
    },
    {
      color: pacient.hodnota1 ? 'red' : 'orange',
      icon: pacient.hodnota1 ? (
        <IconErrorExcl id={`icon-patient-errorExcl-${pacient.id}`} />
      ) : (
        <IconWarning id={`icon-patient-errorExcl-${pacient.id}`} />
      ),
      text:
        pacient.hodnota1 === 1
          ? 'Náklad nevstupuje do hodnotiacich parametrov.'
          : pacient.text2
            ? `Vo výpočte sú vylúčené výkony v sume ${getPriceFormat(
                getValueWithNDecimal(
                  safeNumber(pacient.hodnota2) - safeNumber(pacient.hodnota3),
                ),
                true,
              )}`
            : '',
    },
    {
      icon: null,
      text: (
        <div>
          {pacient.hodnota1 === 1 ? (
            <div className="d-block mb-small">
              Pacient patrí medzi <b>2 % najdrahších pacientov v odbornosti</b>{' '}
              v rámci SR.
            </div>
          ) : (
            <div className="d-block mb-small">
              Do parametra náklad vstupuje{' '}
              {getPriceFormat(
                getValueWithNDecimal(safeNumber(pacient.hodnota3)),
                true,
              )}
              .
            </div>
          )}
        </div>
      ),
    },
  ];
};

const getZsHospRows = (pacient) => [
  {
    icon: <IconDoctor id={`icon-patient-doctor-${pacient.id}`} />,
    text: `<b>Hlavná diagnóza: ${pacient.text1?.toLowerCase()}</b><br />Pridružené diagnózy: ${
      pacient.text3?.toLowerCase() || 'žiadne'
    }`,
  },
  {
    icon: <IconInfo id={`icon-patient-info-${pacient.id}`} />,
    text: pacient.text4,
  },
  {
    color: 'red',
    icon: <IconErrorExcl id={`icon-patient-errorExcl-${pacient.id}`} />,
    text: pacient.text2,
  },
];

const getZsNeockRows = (pacient) => [
  {
    icon: <IconDoctor id={`icon-patient-doctor-${pacient.id}`} />,
    text: `<b>Hlavná diagnóza: </b>${
      pacient.text1
        ? `${pacient.text1?.toLowerCase()}<br /><br />Celkový počet chronických chorôb: ${
            pacient.hodnota2
          }`
        : 'Neevidujeme diagnózu, ktorá môže zvýšiť riziko závažného priebehu covidu-19.'
    }`,
  },
  {
    color: 'red',
    icon: <IconErrorExcl id={`icon-patient-errorExcl-${pacient.id}`} />,
    text: 'Pacient nie je zaočkovaný',
  },
];

const getZsPosCovRows = (pacient) => [
  {
    icon: <IconDoctor id={`icon-patient-doctor-${pacient.id}`} />,
    text: `<b>Hlavná diagnóza: </b>${
      pacient.text1
        ? `${pacient.text1?.toLowerCase()}<br /><br />Počet chronických chorôb: ${
            pacient.hodnota2
          }`
        : 'neevidujeme žiadnu diagnózu<br /><br />Počet chronických chorôb: 0'
    }`,
  },
  {
    icon: <IconDate id={`icon-patient-date-${pacient.id}`} />,
    text: `<b>PCR vyšetrenie:</b> ${formatDate(pacient.datum1 || '')}`,
  },
];

const getDefaultRows = (pacient) => [
  {
    icon: (
      <IconDiagnose
        colorProp={color('grey', 300)}
        id={`icon-patient-diagnose-${pacient.id}`}
      />
    ),
    text: pacient.text3
      ? `<b>${pacient.text1}</b><br />${pacient.text3}`
      : `<b>${pacient.text1}</b>`,
  },
  {
    icon: <IconInfo id={`icon-patient-info-${pacient.id}`} />,
    text: pacient.text4,
  },
  {
    color: 'red',
    icon: <IconErrorExcl id={`icon-patient-errorExcl-${pacient.id}`} />,
    text: pacient.text2,
  },
];

export const dajRiadkyKartyPacienta = (
  pacient: PoistenecUdaj,
  detaily: UdajDetailuPoistenca[],
  typPacientov: KodUdajStr | null,
  usePersonalizedData: boolean,
): KartaPacientaRiadok[] => {
  const detailyPacienta = detaily || [];
  switch (typPacientov) {
    case 'FARM_HYP_NESP':
    case 'FARM_HYP_NEVH':
      return getFarmHypNespNevhRows(pacient, detailyPacienta);
    case 'FARM_POLY':
      return getFarmPolyRows(pacient);
    case 'FARM_NEVYB':
      return getFarmNevybRows(pacient);
    case 'FARM_ZAM':
      return getFarmZamRows(pacient);
    case 'FARM_POSUD':
      return getFarmPosudRows(pacient);
    case 'FARM_PROB01':
      return getFarmProb01Rows(pacient);
    case 'CHR_HYP_STAR':
      return getChrHypStarRows(pacient);
    case 'CHR_DISP':
      return getChrDispRows(pacient);
    case 'CHR_GDM':
      return getChrGdmRows(pacient);
    case 'POC_NAROC':
      return getPocNarocRows(pacient, usePersonalizedData);
    case 'ZS_HOSP':
      return getZsHospRows(pacient);
    case 'ZS_NEOCK':
      return getZsNeockRows(pacient);
    case 'ZS_POS_COV':
      return getZsPosCovRows(pacient);
    default:
      return getDefaultRows(pacient);
  }
};

export const dajVyskuObsahu = (
  masonryRef: Ref<ReactNode | null>,
  typPacientov: KodUdajStr | null,
  pocetObjektov: number,
): string => {
  if (masonryRef) {
    const margin = 45;
    let counter = 0;

    ns(() =>
      // @ts-ignore
      masonryRef.current.childNodes.forEach((cur) => {
        counter += cur.clientHeight;
      }),
    );
    const n = counter / pocetObjektov > 300 ? 2.7 : 2.2;
    const zvysok = pocetObjektov % 3 !== 0 ? n * (counter / pocetObjektov) : 0;
    return `${
      Math.ceil((counter + zvysok) / 3) + margin * Math.ceil(pocetObjektov / 3)
    }px`;
  }
  switch (typPacientov) {
    case 'FARM_HYP_NESP':
      return `${400 * Math.ceil(pocetObjektov / 3)}px`;
    case 'FARM_HYP_NEVH':
      return `${500 * Math.ceil(pocetObjektov / 3)}px`;
    case 'FARM_POLY':
    case 'FARM_ZAM':
      return `${250 * Math.ceil(pocetObjektov / 3)}px`;
    default:
      return `${200 * Math.ceil(pocetObjektov / 3)}px`;
  }
};

export const detailKartyPacienta = (
  typPacientov: KodUdajStr | null,
  zranitelni?: boolean,
): boolean => {
  const povoleneTypy: KodUdaj[] = [
    'FARM_HYP_NESP',
    'FARM_HYP_NEVH',
    'FARM_NEVYB',
    'FARM_POLY',
    'FARM_ZAM',
    'CHR_DISP',
    'CHR_GDM',
    'ZS_HOSP',
    'POC_NAROC',
    'ZS_NEOCK',
    'ZS_POS_COV',
  ];
  if (zranitelni === false) return false;
  return povoleneTypy.some((p) => p === typPacientov);
};

export const moznaArchivaciaPacienta = (
  typPacientov: KodUdajStr | null,
): boolean => {
  const povoleneTypy: KodUdaj[] = ['FARM_POLY', 'POC_NAROC', 'ZS_NEOCK'];
  return povoleneTypy.some((p) => p === typPacientov);
};

const filterByAgeAndVulnerability = (
  insurees: PoistenecUdaj[],
  isVulnerable: boolean,
  age: string,
) =>
  insurees
    ? insurees.filter(
        (p) =>
          ((isVulnerable && p.text2 && p.text2.length > 1) ||
            (!isVulnerable && (!p.text2 || p.text2.length < 2))) &&
          p.text3 === age,
      ).length
    : 0;

export const moznostiPodlaVeku = (
  insurees: PoistenecUdaj[] | null,
  isVulnerable: boolean,
): RadioButtonType[] => [
  {
    count: filterByAgeAndVulnerability(insurees, isVulnerable, '80'),
    text: '80+ rokov',
    value: '80+',
  },
  {
    count: filterByAgeAndVulnerability(insurees, isVulnerable, '70 - 79'),
    text: '70 - 79 rokov',
    value: '70 - 79',
  },
  {
    count: filterByAgeAndVulnerability(insurees, isVulnerable, '60 - 69'),
    text: '60 - 69 rokov',
    value: '60 - 69',
  },
  {
    count: filterByAgeAndVulnerability(insurees, isVulnerable, '50 - 59'),
    text: '50 - 59 rokov',
    value: '50 - 59',
  },
  {
    count: filterByAgeAndVulnerability(insurees, isVulnerable, '40 - 49'),
    text: '40 - 49 rokov',
    value: '40 - 49',
  },
  {
    count: filterByAgeAndVulnerability(insurees, isVulnerable, '18 - 39'),
    text: '18 - 39 rokov',
    value: '18 - 39',
  },
];

const FARM_POLY_OPTIONS = [
  {
    text: 'Viac ako 10',
    value: '10',
  },
  {
    text: 'Viac ako 9',
    value: '9',
  },
  {
    text: 'Viac ako 8',
    value: '8',
  },
  {
    text: 'Viac ako 7',
    value: '7',
  },
];

const POC_NAROC_OPTIONS = [
  {
    text: 'Vstupujúce do parametrov',
    value: '010',
  },
  {
    text: 'Najdrahší pacienti',
    value: '100',
  },
  {
    text: 'S vylúčenými výkonmi',
    value: '001',
  },
  {
    text: 'Všetky',
    value: 'all',
  },
];

const createOptionsForChrDisp = (
  poi: PoistenecUdaj[],
  defaultOptions: SelectType[],
) => {
  const options = [...defaultOptions];
  poi.forEach((p) => {
    if (
      p.text1 &&
      !options.some((m) => m.text === p.text1) &&
      !options.some(
        (m) =>
          (m.text && p.text1 && m.text.toString().includes(p.text1)) ||
          (p.text1 && JSON.stringify(m.text).includes(p.text1)),
      )
    ) {
      const pocet = poi.reduce(
        (prev, cur) =>
          cur.text1 === p.text1 ? prev + safeNumber(cur.hodnota2) : prev + 0,
        0,
      );
      options.push({
        text: `${p.text1} (${pocet})`,
        value: p.text1,
      });
    }
  });
  return options;
};

const createOptionsForChrDia = (
  poi: PoistenecUdaj[],
  defaultOptions: SelectType[],
) => {
  const options = [...defaultOptions];
  poi.forEach((p) => {
    if (p.text1 && !options.some((m) => m.text === p.text1)) {
      options.push({ text: p.text1, value: p.text1 });
    }
  });
  return options;
};

const createOptionsForOthers = (
  poi: PoistenecUdaj[],
  defaultOptions: SelectType[],
) => {
  const options = [...defaultOptions];
  poi.forEach((p) => {
    if (p.text2 && !options.some((m) => m.text === p.text2))
      options.push({
        text: p.text2,
        value: p.text2,
      });
  });
  return options;
};

export const dajSpecifickyVyberPreFilterPacientov = (
  poi: PoistenecUdaj[],
  typPacientov: KodUdajStr | null,
): SelectType[] | null => {
  const options: SelectType[] = [
    {
      text: 'Všetci',
      value: 'all',
    },
  ];
  const povoleneTypy: KodUdaj[] | string[] = [
    'FARM_HYP_NESP',
    'FARM_HYP_NEVH',
    'FARM_POLY',
    'CHR_DIA',
    // 'CHR_DISP',
    'POC_NAROC',
  ];
  if (
    !poi ||
    !poi.length ||
    !typPacientov ||
    !povoleneTypy.some((p) => p === typPacientov)
  )
    return null;

  switch (typPacientov) {
    case 'FARM_POLY':
      return FARM_POLY_OPTIONS;
    case 'POC_NAROC':
      return POC_NAROC_OPTIONS;
    case 'CHR_DISP':
      return createOptionsForChrDisp(poi, options);
    case 'CHR_DIA':
      return createOptionsForChrDia(poi, options);
    default:
      return createOptionsForOthers(poi, options);
  }
};

export const dajFilterEdukaciePacientov = (
  typPacientov: KodUdajStr | null,
): RadioButtonType[] | null => {
  const povoleneTypy: KodUdaj[] | string[] = ['CHR_DIA'];
  if (!typPacientov || !povoleneTypy.some((p) => p === typPacientov))
    return null;
  return [
    {
      text: 'Všetci',
      value: '',
    },
    {
      text: 'Prebieha',
      value: 'Edukácia prebieha',
    },
    {
      text: 'Nezačala',
      value: 'Edukácia nezačala',
    },
    {
      text: 'Ukončená',
      value: 'Edukácia ukončená',
    },
  ];
};

export const dajFilterObdobiePacientov = (
  typPacientov: KodUdajStr | null,
): RadioButtonType[] | null => {
  const povoleneTypy: KodUdaj[] | string[] = ['POC_NAROC'];
  if (!typPacientov || !povoleneTypy.some((p) => p === typPacientov))
    return null;
  return [
    {
      text: 'Posledný rok',
      value: 'R',
    },
    {
      text: 'Posledné uzavreté obdobie',
      value: 'Z',
    },
    {
      text: 'Aktuálne obdobie',
      value: 'A',
    },
  ];
};

export const canShowPatientTypes = (
  type: 'ZS' | 'CHR' | 'FARM',
  udaje: KodHodnoty[],
): boolean =>
  udaje?.some((u) =>
    type === 'ZS'
      ? u.kodUdaj.startsWith('ZS') || u.kodUdaj === 'POC_NAROC'
      : u.kodUdaj.startsWith(type),
  );

export const dajTypyPacientov = (
  kodyHodnoty: KodHodnoty[],
  pathName: string,
): TypPacientov[] =>
  typyPacientovMoznosti
    ?.filter(
      (t) =>
        kodyHodnoty
          ?.filter(
            (k) =>
              k.kodUdaj.includes(dajTypStranky(pathName)) ||
              (dajTypStranky(pathName) === 'ZS' && k.kodUdaj === 'POC_NAROC'),
          )
          .map((h) => h.kodUdaj)
          .indexOf(t.hodnota) > -1,
    )
    .map((typ) => ({
      ...typ,
    }));

export const getObdobiePacientov = (
  typ: string,
  kody?: KodHodnoty[],
): string => {
  if (typ !== 'POC_NAROC' && kody?.some((k) => k.kodUdaj === typ))
    return kody?.find((k) => k.kodUdaj === typ)?.kodTypObdobie;
  let obdobie = '';
  switch (typ) {
    case 'FARM_NEVYB':
    case 'FARM_ZAM':
      obdobie = 'Q';
      break;
    case 'CHR_DISP':
      obdobie = 'D';
      break;
    case 'CHR_DIA':
    case 'CHR_HYP':
    case 'CHR_HYP_STAR':
    case 'FARM_HYP_NESP':
    case 'FARM_HYP_NEVH':
      obdobie = 'C';
      break;
    default:
      obdobie = 'R';
      break;
  }
  return obdobie;
};

const handleSearchCase = (vyhladavanie: string | null) => ({
  hodnota2: null,
  text1: null,
  text2: null,
  text3: null,
  vyhladavanie,
});

const handleCovidFilterCase = (
  filterCovid: { podlaVeku: string; zranitelni: boolean },
  vyhladavanie: string | null,
) => ({
  hodnota2: null,
  text1: null,
  text2: filterCovid.zranitelni ? 'Rizikoví pacienti' : null,
  text3: filterCovid.podlaVeku,
  vyhladavanie,
});

const handleSpecificPatientTypeCase = (
  filter: PacientiFilterState,
  vyhladavanie: string | null,
) => ({
  hodnota2: null,
  text1: null,
  text2: null,
  text3: filter.specifickyVyber !== 'all' ? filter.specifickyVyber : null,
  vyhladavanie,
});

const calculateValue2AndText1 = (
  typPacientov: string,
  filter: PacientiFilterState,
  povoleneTypyText1: string[],
) => {
  let hodnota2: number | null = null;
  if (typPacientov === 'FARM_POLY') {
    hodnota2 =
      filter.specifickyVyber?.length <= 2 &&
      !Number.isNaN(Number(filter.specifickyVyber)) &&
      Number(filter.specifickyVyber) > 0
        ? Number(filter.specifickyVyber)
        : 10;
  }

  let text1: string = '';
  if (povoleneTypyText1.indexOf(typPacientov) > -1) {
    text1 =
      filter.specifickyVyber &&
      filter.specifickyVyber !== 'all' &&
      typPacientov !== 'FARM_POLY' &&
      (Number.isNaN(Number(filter.specifickyVyber)) ||
        Number(filter.specifickyVyber) < 2)
        ? filter.specifickyVyber
        : '';
  }

  return { hodnota2, text1 };
};

export const dajPayloadFilterZoznamPacientov = (
  filter: PacientiFilterState,
  vyhladavanie: string | null,
  typPacientov: string,
  filterCovid?: { podlaVeku: string; zranitelni: boolean },
): FilterZoznamuPacientov | null => {
  if (!typPacientov) return null;
  if (vyhladavanie) return handleSearchCase(vyhladavanie);
  if (typPacientov === 'ZS_NEOCK' && filterCovid && !vyhladavanie)
    return handleCovidFilterCase(filterCovid, vyhladavanie);
  if (typPacientov === 'POC_NAROC')
    return handleSpecificPatientTypeCase(filter, vyhladavanie);
  const povoleneTypyText1: string[] = [
    'FARM_HYP_NESP',
    'FARM_HYP_NEVH',
    'FARM_POLY',
    'CHR_DIA',
    'CHR_DISP',
  ];
  const { hodnota2, text1 } = calculateValue2AndText1(
    typPacientov,
    filter,
    povoleneTypyText1,
  );

  return {
    hodnota2,
    text1: typPacientov === 'FARM_HYP_NEVH' ? null : text1,
    text2:
      filter.typyPacientov === 'FARM_HYP_NEVH'
        ? text1
        : filter.edukacia || null,
    text3: null,
    vyhladavanie,
  };
};

export const getActualPatientsPreviewType = (
  route: string,
): 'ZS' | 'CHR' | 'FARM' => {
  if (route.includes(routes.ppFarmakoterapia)) return 'FARM';
  if (route.includes(routes.ppChronicki)) return 'CHR';
  return 'ZS';
};

export const getPatientIcon = (
  sex: 'M' | 'Z' | string,
  dateOfBirth: string,
): ReactNode => {
  let imgSrc = Man04;
  const insureeAge = calculateAge(dateOfBirth);
  switch (true) {
    case insureeAge < 5:
      imgSrc = sex === 'Z' ? Woman04 : Man04;
      break;
    case insureeAge < 10:
      imgSrc = sex === 'Z' ? Woman59 : Man59;
      break;
    case insureeAge >= 20:
      imgSrc = sex === 'Z' ? Woman20 : Man20;
      break;
    default:
      imgSrc = sex === 'Z' ? Woman1019 : Man1019;
      break;
  }
  return <img alt="Pohlavie" src={imgSrc} />;
};

export const canPatientDiscard = ({
  poistenecUdaj,
  typPacientov,
}: {
  poistenecUdaj: PoistenecUdaj;
  typPacientov: string | KodUdaj | null;
}): boolean => {
  if (!typPacientov) return false;
  if (['FARM_POSUD', 'FARM_PROB01'].includes(typPacientov)) return true;
  if (typPacientov === 'CHR_GDM' && !poistenecUdaj.datum2) return true;
  return false;
};

export const getDetailExtraHeaderText = ({
  idPoi,
  poistenciUdaje,
  typPacientov,
}: {
  idPoi: number;
  poistenciUdaje?: PoistenecUdaj[];
  typPacientov: KodUdaj | string | null;
}): string => {
  if (typPacientov === 'CHR_GDM' && poistenciUdaje && idPoi) {
    const date = poistenciUdaje.find((u) => u.id === idPoi)?.datum1;
    return `<b>Dátum pôrodu: ${formatDate(date)}</b> (${getMonthsBefore(
      date,
    )})`;
  }

  return '';
};

export const getPatientListStoreValues = (
  list: Zoznam,
  payload: GetPatientListPayload,
) => {
  const { kod, offset, response } = payload;
  const insureeData = getInsureeData(
    kod,
    list.poistenciUdaje,
    offset,
    response.poistenciUdaje,
  );

  const filtersStates = getFiltersStates(list, payload);

  const patientOverviewType = getPatientOverviewType(kod);

  return {
    insureeData,
    filtersStates,
    patientOverviewType,
  };
};

const getPatientOverviewType = (code: string): 'ZS' | 'CHR' | 'FARM' => {
  if (code.startsWith('FARM')) {
    return 'FARM';
  }
  if (code.startsWith('CHR')) {
    return 'CHR';
  }
  return 'ZS';
};

const getFiltersStates = (list: Zoznam, payload: GetPatientListPayload) => {
  const specificChoice = getSpecificChoiceValue(
    list.stavyFiltrov.specifickyVyber,
    payload,
  );
  const education = getEducationValue(list.stavyFiltrov.edukacia, payload);
  const period = getPeriodValue(list.stavyFiltrov.obdobie, payload);

  return {
    ...list.stavyFiltrov,
    specifickyVyber: specificChoice,
    edukacia: education,
    obdobie: period,
  };
};

const getPeriodValue = (
  currentPeriod: RadioButtonType[] | null,
  payload: GetPatientListPayload,
) => {
  const { kod, obdobie, offset, response, specifickyVyber } = payload;
  if (
    response.poistenciUdaje &&
    response.poistenciUdaje.length > 0 &&
    !offset
  ) {
    return dajFilterObdobiePacientov(kod);
  }
  if (offset || specifickyVyber || obdobie) {
    return currentPeriod;
  }
  return null;
};

const getEducationValue = (
  currentEducation: RadioButtonType[] | null,
  payload: GetPatientListPayload,
) => {
  const { kod, obdobie, offset, response, specifickyVyber } = payload;
  if (
    response.poistenciUdaje &&
    response.poistenciUdaje.length > 0 &&
    !offset
  ) {
    return dajFilterEdukaciePacientov(kod);
  }
  if (offset || specifickyVyber || obdobie) {
    return currentEducation;
  }
  return null;
};

const getInsureeData = (
  code: string,
  currentInsureeData: PoistenecUdaj[],
  offset: number | undefined,
  responseInsureeData: PoistenecUdaj[],
): PoistenecUdaj[] => {
  if (!offset || (code === 'ZS_NEOCK' && offset === -1)) {
    return responseInsureeData;
  }
  if (responseInsureeData) {
    return [...currentInsureeData, ...responseInsureeData];
  }
  return currentInsureeData;
};

const getSpecificChoiceValue = (
  currentSpecifickyVyber: SelectType[] | null,
  payload: GetPatientListPayload,
) => {
  const { kod, obdobie, offset, response, specifickyVyber } = payload;
  if (
    response.poistenciUdaje &&
    response.poistenciUdaje.length > 0 &&
    !offset
  ) {
    return dajSpecifickyVyberPreFilterPacientov(response.poistenciUdaje, kod);
  }
  if (offset || specifickyVyber || obdobie) {
    return currentSpecifickyVyber;
  }
  return null;
};

export const getPatientListStartValues = (
  initialList: Zoznam,
  payload: any,
  list: Zoznam,
) => {
  const filterStates = getFilterStates(payload, list, initialList);
  const specificChoice = getSpecificChoiceStartValue(payload, list);

  return {
    stavyFiltrov: filterStates,
    specifickyVyber: specificChoice,
  };
};

const getSpecificChoiceStartValue = (payload: any, list: Zoznam) => {
  const { filterZoznamPacientov, kod } = payload;
  const { specifickyVyber } = list.filter;

  if (
    kod === 'FARM_POLY' &&
    !filterZoznamPacientov.hodnota2 &&
    Number.isNaN(Number(specifickyVyber))
  ) {
    return '10';
  }

  if (
    kod === 'FARM_POLY' ||
    (specifickyVyber !== 'all' &&
      !filterZoznamPacientov.hodnota2 &&
      Number.isNaN(Number(specifickyVyber)))
  ) {
    return filterZoznamPacientov.hodnota2
      ? filterZoznamPacientov.hodnota2.toString()
      : specifickyVyber;
  }

  if (!Number.isNaN(Number(specifickyVyber)) && Number(specifickyVyber) < 2) {
    return specifickyVyber;
  }

  return specifickyVyber || 'all';
};

const getFilterStates = (payload: any, list: Zoznam, initialList: Zoznam) => {
  const { filterZoznamPacientov, kod, kodObdobie } = payload;
  if (
    (filterZoznamPacientov &&
      (filterZoznamPacientov.text1 || filterZoznamPacientov.hodnota2)) ||
    (kodObdobie !== 'R' && kod !== 'CHR_HYP') ||
    (kodObdobie && kod === 'POC_NAROC')
  ) {
    return list.stavyFiltrov;
  }

  return initialList.stavyFiltrov;
};
