import { RatingOption } from '../types/feedback.types';

export const isFeedbackValid = (
  rating: number,
  ratingOptions: RatingOption[],
): boolean => rating > 0 && ratingOptions.some((r) => r.checked);

export const isCheckedDifferent = (ratingOptions: RatingOption[]): boolean =>
  ratingOptions.some((r) => r.checked && r.value.toLowerCase() === 'iné');

export const isTextMissed = (
  rating: number,
  ratingOptions: RatingOption[],
  text: string,
): boolean =>
  isFeedbackValid(rating, ratingOptions) &&
  !text &&
  isCheckedDifferent(ratingOptions);
import { getCookie, setCookie } from './cookies.utils';

const COOKIE_NAME: string = 'dlek-triggerFeedback';
const COOKIE_EXPIRES: number = 365;
const SENT_FEEDBACK_EXPIRES: number = 30;

export const setTriggerQuarantine = (
  userId: number,
  isFeedbackSent?: boolean,
) => {
  const expiresAtMs: number =
    Date.now() +
    1000 *
      60 *
      60 *
      24 *
      (isFeedbackSent ? SENT_FEEDBACK_EXPIRES : COOKIE_EXPIRES);
  const data: { expiresAt: number; id: number }[] = [
    {
      expiresAt: expiresAtMs,
      id: userId,
    },
  ];
  const cookieData = getCookie(COOKIE_NAME) || '[]';
  setCookie(
    COOKIE_NAME,
    JSON.stringify([...JSON.parse(cookieData), ...data]),
    COOKIE_EXPIRES,
  );
};

export const isTriggerInQuarantine = (userId: number): boolean => {
  const cookieData = getCookie(COOKIE_NAME);
  if (!cookieData) return false;
  const actualMs: number = Date.now();
  const item = JSON.parse(cookieData)?.find((c) => Number(c?.id) === userId);
  if (!item || Number(item?.expiresAt) <= actualMs) {
    setCookie(
      COOKIE_NAME,
      JSON.stringify(
        JSON.parse(cookieData)?.filter((c) => Number(c.id) !== userId),
      ),
    );
    return false;
  }
  return true;
};

export const isTextareaVisible = (
  optins: RatingOption[],
  isTrigger?: boolean,
): boolean =>
  !!(
    optins.some((r) => r.checked && r.value.toLowerCase() === 'iné') ||
    isTrigger
  );
