interface Props {
  color?: string;
}

const IconParameterQuality = ({ color }: Props) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" fill={color || '#EA5DD1'} r="16" />
    <path
      d="M16.7869 24.9968C16.3865 25.5072 15.6135 25.5072 15.2131 24.9968L6.48403 13.8671C6.19983 13.5048 6.19983 12.9952 6.48403 12.6329L9.03305 9.38286C9.22261 9.14117 9.51274 9 9.8199 9L22.1801 9C22.4873 9 22.7774 9.14117 22.9669 9.38286L25.516 12.6329C25.8002 12.9952 25.8002 13.5048 25.516 13.8671L16.7869 24.9968Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export default IconParameterQuality;
