import { createUseStyles } from 'react-jss';
import { getRem } from '@dovera/design-system';

export default createUseStyles({
  offline: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: getRem(600),
    textAlign: 'center',
    justifyContent: 'center',
    margin: `0 auto ${getRem(60)}`,
    '& img': {
      width: '100%',
      maxWidth: getRem(450),
      margin: `0 auto ${getRem(16)}`,
    },
  },
});
