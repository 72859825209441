/* eslint-disable */
import { ReactNode } from 'react';
import { Tooltip } from '@dovera/design-system';

interface Props {
  [key: string]: any;
  children: ReactNode;
  dialog: string | object | ReactNode;
  hideArrow?: boolean;
  id: string;
  width?: number | string;
}

const CustomTooltip = ({ dialog, children, id }: Props) => {
  // @ts-ignore
  return <Tooltip id={id} dialog={dialog} children={children} />;
};

export default CustomTooltip;
