// @ts-nocheck
import { ReactNode } from 'react';
import LogoDZP from '../assets/icons/logo.svg';
import {
  CHARAKTER_DAVKY_CAKACKY,
  DavkaCakacka,
  LogDavkyCL,
  STAV_DAVKY_CAKACKY,
  TYP_DAVKY_CAKACKY,
} from '../types/cakacky.types';
import cakacky from '../constants/strings/cakacky';
import { IconDoctorNew } from '../components/CustomIcons';
import IconArrowRightLong from '../components/CustomIcons/IconArrowRightLong';
import { Icon, breakpoints, color } from '@dovera/design-system';
import IconHourglasses from '../components/CustomIcons/IconHourglasses';
import IconCheck from '../components/CustomIcons/IconCheck';
import strings from '../constants/strings';
// @ts-check

const { typ } = cakacky.batchesPreview;

export const getBatchType = (
  character: CHARAKTER_DAVKY_CAKACKY,
  type: TYP_DAVKY_CAKACKY,
): string => {
  if (type !== TYP_DAVKY_CAKACKY.Nova) {
    if (character === CHARAKTER_DAVKY_CAKACKY.Aktualizacna) {
      return typ.aktualizacna;
    }
    return typ.odpovedna;
  }
  return typ.nova;
};

export const getBatchIcons = (isNew?: boolean): ReactNode => {
  const logo = <img alt="logo" src={LogoDZP} width={24} />;
  const from = isNew ? <IconDoctorNew /> : logo;
  const to = isNew ? logo : <IconDoctorNew />;
  return (
    <span className="content-center-icons">
      {from}
      <IconArrowRightLong color={color('grey', 300)} />
      {to}
    </span>
  );
};

/**
 * Filter davok pre obrazovku Prehladu davok - vybera iba davky s charakterom "N" (Nove) alebo "A" (Aktualizacne)
 * @date 4. 12. 2023 - 15:01:59
 */
export const getMainBatches = ({
  batches,
}: {
  batches: DavkaCakacka[];
}): DavkaCakacka[] =>
  batches.filter(
    (b) =>
      b.charakterDavky === CHARAKTER_DAVKY_CAKACKY.Nova ||
      b.charakterDavky === CHARAKTER_DAVKY_CAKACKY.Aktualizacna,
  );

export const getBatchState = ({
  batch,
  batches,
}: {
  batch: DavkaCakacka;
  batches?: DavkaCakacka[] | null;
}): STAV_DAVKY_CAKACKY => {
  if (
    !batch.charakterDavky ||
    batch.charakterDavky === CHARAKTER_DAVKY_CAKACKY.Nova
  )
    return batches?.some(
      (b) =>
        b.charakterDavky === CHARAKTER_DAVKY_CAKACKY.Odpovedna &&
        batch.riadok === b.riadok,
    )
      ? STAV_DAVKY_CAKACKY.Undefined
      : STAV_DAVKY_CAKACKY.Spracovanie;
  if (!batch.stavPrevzatia) return STAV_DAVKY_CAKACKY.NaStiahnutie;
  return STAV_DAVKY_CAKACKY.Vybavena;
};

export const getVisualStateCL = ({
  batch,
  batchState,
}: {
  batch?: DavkaCakacka;
  batchState?: STAV_DAVKY_CAKACKY;
}): ReactNode => {
  const state = batchState || getBatchState({ batch });
  let result: ReactNode = <span />;
  switch (state) {
    case STAV_DAVKY_CAKACKY.NaStiahnutie:
      result = (
        <span className="text-color-error">
          <Icon name="16-error" size="medium" /> Na stiahnutie
        </span>
      );
      break;
    case STAV_DAVKY_CAKACKY.Spracovanie:
      result = (
        <>
          <IconHourglasses /> Spracováva sa
        </>
      );
      break;
    default:
      result = (
        <>
          <IconCheck color={color('success')} />
          Vybavená
        </>
      );
      break;
  }
  return <span className="icon-with-text">{result}</span>;
};

/**
 * CL Batch State
 * @date 4. 12. 2023 - 14:53:39
 *
 * @type {*}
 */
export const getBatchRowData = ({
  batches,
  currentBatch,
}: {
  batches: DavkaCakacka[];
  currentBatch: DavkaCakacka;
}): { rowBatches: DavkaCakacka[]; state: ReactNode } => {
  if (currentBatch.charakterDavky === CHARAKTER_DAVKY_CAKACKY.Nova) {
    const rowBatches: DavkaCakacka[] = batches.filter(
      (b) => b.riadok === currentBatch.riadok,
    );
    const batch4State: DavkaCakacka = rowBatches[rowBatches.length - 1];
    return {
      rowBatches,
      state: getVisualStateCL({
        batch: batch4State,
      }),
    };
  }
  return {
    rowBatches: [currentBatch],
    state: getVisualStateCL({ batch: currentBatch }),
  };
};

export const getDownloadData = (batches: DavkaCakacka[]): LogDavkyCL[] => {
  const result: LogDavkyCL[] = [];
  batches.forEach((bd) => {
    bd.stiahnutiaDavok.forEach((b) => {
      result.push({
        datumStiahnutia: b.datumStiahnutia,
        typDavky: `${
          strings.cakacky.batchesPreview.typ[`${bd.charakterDavky}`]
        } - ${bd.typDavky}`,
        vykonal: b.vykonal,
      });
    });
  });
  return result;
};

export const getMaxBatchNameLength = ({
  hasActionCol,
  hasWorkplaceCol,
  windowWidth,
}: {
  hasActionCol: boolean;
  hasWorkplaceCol: boolean;
  windowWidth: number;
}): number => {
  let colLength: number = 8;
  const baseResolution: number = 1440;
  const minLength: number = 10;
  const baseLength: number = 24;
  const getColLength = (): number => {
    switch (true) {
      case windowWidth < baseResolution && windowWidth >= 1280:
        return 16;
      case windowWidth >= breakpoints.xl && windowWidth < 1280:
        return 12;
      case windowWidth >= breakpoints.l && windowWidth < breakpoints.xl: {
        colLength = 0;
        return 5;
      }
      case windowWidth < breakpoints.l:
        return minLength;
      default:
        return (
          baseLength +
          Number.parseInt(
            ((windowWidth - baseResolution) * 0.03).toString(),
            10,
          )
        );
    }
  };
  if (hasActionCol && hasWorkplaceCol) return getColLength();
  if (hasActionCol || hasWorkplaceCol) return getColLength() + colLength / 2;
  return getColLength() + colLength;
};

export const getLastNotTakenGuid = (
  batch: DavkaCakacka | null,
): string | null | undefined => {
  if (!batch || !!batch.stavPrevzatia) return '';
  if (batch.charakterDavky === CHARAKTER_DAVKY_CAKACKY.Aktualizacna)
    return batch.guidDavky;
  return batch.guidOdpovednejDavky;
};
