import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonLayout,
  ButtonSecondary,
  Grid,
  GridCol,
  Loader,
  Modal,
} from '@dovera/design-system';
import {
  DajDataAutentifikaciePayload,
  DajSekciePristupuPayload,
  ZmenUdajeAutentifikaciePayload,
} from '../../../../types/api/poskytovatel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import {
  fetchDataAutentifikacie,
  fetchSections,
  updateAuthData,
} from '../../../../api/poskytovatel';
import strings from '../../../../constants/strings';
import {
  GridColTable,
  GridRowTable,
  GridTable,
} from '../../../../components/GridTable';
import { RoleType } from '../../../../types/poskytovatel.types';
import { dlekSections } from '../../../../constants/systemConstants';
import routes from '../../../../routes';
import useStyles from './ModalApproval.styles';
import IconUserBlue from '../../../../components/CustomIcons/IconUserBlue';
import ModalDisapproval from '../ModalDisapproval/ModalDisapproval';
import { uniqueArray } from '../../../../utils/array.utils';
import StepResult from '../../../auth-pzs/Stepper/StepResult';
import { resetAuth } from '../../../../slices/pzsAuth.slice';
import { useAppDispatch } from '../../../../hooks/useStore';
import { useNavigate } from 'react-router';

const texts = strings.auth.page.approvalAccess.modals.accessApprove;
const textsResult = strings.auth.page.stepResult;

interface Props {
  isVisible: boolean;
  onHide: () => void;
  onSubmit: () => void;
  userId: number;
}

const ModalApproval = ({ isVisible, onHide, onSubmit, userId }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isVisibleModal, setIsVisibleModal] = useState(isVisible);
  const [isVisibleDisapprovalModal, setIsVisibleDisaprovalModal] =
    useState(false);
  const [isDisapproval, setIsDisapproval] = useState(false);
  const [resultTitle, setResultTitle] = useState('');
  const [resultDescription, setResultDescription] = useState('');
  const [resultState, setResultState] = useState('success');
  const [localUserId, setLocalUserId] = useState(0);
  const { isSUPR, pouzivatelId, vztahId } = useSelector(
    (state: RootState) => state.auth,
  );
  const {
    authData,
    sections,
    stepper: { stepResult },
  } = useSelector((state: RootState) => state.pzsAuth);
  const newUserName = `${
    authData.data?.[0]?.titulPred ? `${authData.data?.[0]?.titulPred} ` : ''
  }${authData.data?.[0]?.meno || '-'} ${authData.data?.[0]?.priezvisko || '-'}${
    authData.data?.[0]?.titulZa ? `, ${authData.data?.[0]?.titulZa}` : ''
  }`;
  useEffect(() => {
    if (userId) {
      const payload: DajDataAutentifikaciePayload = {
        idPouzivatel: userId,
        idVZ: vztahId || 0,
        jeHlavnaKarta: isSUPR,
      };
      dispatch(fetchDataAutentifikacie(payload));
      setTimeout(() => {
        setLocalUserId(userId);
      }, 200);
    }
  }, [dispatch, isSUPR, userId, vztahId]);
  useEffect(() => {
    if (authData.data && !authData.isLoading && userId === localUserId) {
      const payload: DajSekciePristupuPayload = {
        idPouzivatel: userId,
        idVZ: vztahId || 0,
        zoznamRoli: uniqueArray(authData.data?.map((d) => d.typRole)),
      };
      dispatch(fetchSections(payload));
    }
  }, [authData, dispatch, localUserId, userId, vztahId]);
  useEffect(() => {
    if (isDisapproval && (stepResult.processingState || stepResult.error)) {
      setIsVisibleDisaprovalModal(false);
      setTimeout(() => {
        setIsVisibleModal(true);
      }, 100);
    }
  }, [stepResult, isDisapproval]);
  const submitCallback = useCallback(
    (disapprovalText: string | null) => {
      if (disapprovalText) setIsDisapproval(true);
      setResultTitle(
        disapprovalText
          ? textsResult.warning.titleDisapproval
          : textsResult.success.titleApproval,
      );
      setResultDescription(
        disapprovalText
          ? textsResult.warning.descriptionDisapproval(newUserName)
          : textsResult.success.descriptionApproval(newUserName),
      );
      if (disapprovalText) setResultState('warning');
      if (authData.data) {
        const ad = authData.data[0];
        const payload: ZmenUdajeAutentifikaciePayload = {
          dovodZamietnutia: disapprovalText,
          email: ad.email,
          idPouzivatel: ad.idPouzivatel,
          ids: authData.data.map((d) => d.id) || [],
          jeAktivnyPristup: authData?.data[0]?.jeAktivnyPristup || false,
          kodLekar:
            authData.data.filter((d) => d.kodLekara)?.[0]?.kodLekara || null,
          pocetOdmietnuti: disapprovalText ? 3 : ad.pocetOdmietnuti,
          sekcie: sections.data,
          stav: disapprovalText ? 'O' : 'P',
          stavPouzivatela: ad.jeAktivnyPristup,
          zmenilPouzivatel: pouzivatelId || 0,
        };
        dispatch(updateAuthData(payload));
      }
    },
    [authData.data, dispatch, newUserName, pouzivatelId, sections.data],
  );
  useEffect(() => {
    if (stepResult.processingState || stepResult.error) {
      onSubmit();
      dispatch(resetAuth());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepResult]);
  const renderHeadInfo = (
    <GridTable className="mb-large">
      <GridRowTable>
        <GridColTable>
          <b>{texts.labels.role}</b>
        </GridColTable>
        <GridColTable spaceLeft={32}>
          {authData.data && authData.data.length
            ? authData.data.map(
                (d, key) =>
                  (d.typRole || d.kategoriaRole) && (
                    <span key={`modalApproval--role-${d.id}-${key}`}>{`${
                      d?.typRole !== 'I' ? RoleType[d.typRole] : d.kategoriaRole
                    }${
                      authData.data && authData.data.length > key + 1
                        ? ', '
                        : ''
                    }`}</span>
                  ),
              )
            : '-'}
        </GridColTable>
      </GridRowTable>
      <GridRowTable>
        <GridColTable>
          <b>{texts.labels.accessType}</b>
        </GridColTable>
        <GridColTable spaceLeft={32}>Štandardný prístup</GridColTable>
      </GridRowTable>
      <GridRowTable>
        <GridColTable>
          <b>{texts.labels.email}</b>
        </GridColTable>
        <GridColTable spaceLeft={32}>
          {authData.data?.[0]?.email || '-'}
        </GridColTable>
      </GridRowTable>
      {authData.data?.[0]?.pevnaLinka && (
        <GridRowTable>
          <GridColTable>
            <b>{texts.labels.phone}</b>
          </GridColTable>
          <GridColTable spaceLeft={32}>
            {authData.data?.[0]?.pevnaLinka}
          </GridColTable>
        </GridRowTable>
      )}
      {authData.data?.[0]?.mobil && (
        <GridRowTable>
          <GridColTable>
            <b>{texts.labels.mobile}</b>
          </GridColTable>
          <GridColTable spaceLeft={32}>
            {authData.data?.[0]?.mobil}
          </GridColTable>
        </GridRowTable>
      )}
    </GridTable>
  );
  const renderSections = (
    <Grid>
      <GridCol size={{ m: 12, l: 6 }}>
        <h6 className="mb-small">{texts.subtitles.ep}</h6>
        {sections.isLoading && <Loader size={22} />}
        {sections.data
          .filter(
            (s) =>
              !dlekSections.includes(s.kodSekcie) &&
              s.zapnuta &&
              /** Nezobrazujeme sekcie "Vseobecna funkcionalita" a "Vseobecna funkcionalita - rozsirena" */
              !['PZSP', 'PZSR'].includes(s.kodSekcie),
          )
          .map((s) => (
            <div key={s.kodSekcie} className="mb-small">
              {s.nazovSekcie}
            </div>
          ))}
      </GridCol>
      <GridCol size={{ m: 12, l: 6 }}>
        <h6 className="mb-small">{texts.subtitles.dLek}</h6>
        {sections.data
          .filter((s) => dlekSections.includes(s.kodSekcie) && s.zapnuta)
          .map((s) => (
            <div key={s.kodSekcie} className="mb-small">
              {s.nazovSekcie}
            </div>
          ))}
      </GridCol>
    </Grid>
  );
  return (
    <Modal
      className={classes.modal}
      closeOnOverlayClick={false}
      data-modal-initial-focus
      footer={
        !stepResult.processingState && !stepResult.error ? (
          <ButtonLayout
            className={classes.buttonLayout}
            data-testid="auth--approval-modal--bottom-buttons"
            direction="horizontal"
          >
            <div>
              <Button
                isDisabled={stepResult.isLoading}
                isLoading={stepResult.isLoading}
                onClick={() => submitCallback(null)}
              >
                {texts.buttons.primary}
              </Button>
              <ButtonSecondary
                onClick={() =>
                  navigate(`${routes.schvaleniePristupu}/${userId}`)
                }
              >
                {texts.buttons.secondary}
              </ButtonSecondary>
            </div>
            <ButtonSecondary
              className={classes.disapprovalBtn}
              onClick={() => {
                onHide();
                setTimeout(() => {
                  setIsVisibleDisaprovalModal(true);
                }, 100);
              }}
            >
              {texts.buttons.destructive}
            </ButtonSecondary>
          </ButtonLayout>
        ) : (
          <span />
        )
      }
      header={
        authData.isLoading ? (
          <Loader size={22} />
        ) : (
          !stepResult.processingState &&
          !stepResult.error && (
            <>
              <IconUserBlue id="schvalenie-pristupu-title" />
              {` ${newUserName}`}
            </>
          )
        )
      }
      id={`solveWidgets--modalApproval`}
      isVisible={isVisibleModal}
      onHide={() => onHide()}
    >
      <div data-testid="pzs-authApprovalModal">
        {stepResult.processingState || stepResult.error ? (
          <StepResult
            description={stepResult.error ? '' : resultDescription}
            result={stepResult.processingState ? resultState : 'error'}
            title={
              stepResult.error ? textsResult.error.titleApproval : resultTitle
            }
            withoutButtons
          />
        ) : (
          <>
            {renderHeadInfo}
            {renderSections}
          </>
        )}
        <ModalDisapproval
          isLoadingSubmit={stepResult.isLoading}
          isVisible={isVisibleDisapprovalModal}
          onHide={() => {
            setIsVisibleDisaprovalModal(true);
            onHide();
          }}
          onSubmit={(text) => submitCallback(text)}
        />
      </div>
    </Modal>
  );
};

export default ModalApproval;
