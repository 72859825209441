export default {
  title: 'Vitajte v DôveraLekárom',
  customTitle: (name: string) => `Dobrý deň, ${name}`,
  notifications: {
    title: 'Notifikácie',
    updatedHP: 'Aktualizovali sme Vaše hodnotiace parametre',
    codeHP: 'Hodnotiace parametre',
    codePP: 'Prehľad pacientov',
    codeDiag: 'Prehľad diagnóz',
  },
  solveWidget: {
    title: 'Na riešenie',
    error: 'Nepodarilo sa načítať widget na riešenie.',
  },
  news: {
    title: 'Aktuality',
    showAll: 'Pozrieť všetky',
    textDlekPoi:
      'Pozrite sa, ktorí pacienti možu mať <button class="btn btn--link no-mrg no-pad">neštandardne nastavenú liečbu<span class="text-color-black">.</span></button>',
    textDlekPoiDiag:
      'Porovnajte si <button class="btn btn--link no-mrg no-pad">náklady na diagnózy</button> s lekármi v rovnakej odbornosti.',
    textDlekZucChybProt:
      'Máte nové chybové protkoly v <button class="btn btn--link no-mrg no-pad">prehľade faktúr</button>.',
  },
};
