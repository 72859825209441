import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import StavyOdoslania, { BatchSaveState } from './components/StavyOdoslania';
import { useCallback, useEffect, useState } from 'react';
import { dajStavOdoslaniaDavok } from '../../../api/zuctovanie';
import {
  StavyOdoslaniaEnum,
  VysledokStavuOdoslaniaDavok,
} from '../../../types/zuctovanie.types';
import useInterval from '../../../hooks/useInterval';
import { useAppDispatch } from '../../../hooks/useStore';
import { setCountCallsSendingState } from '../../../slices/zuctovanie.slice';

const VysledokOdoslaniaDavok = () => {
  const dispatch = useAppDispatch();
  const { countCalls } = useSelector(
    (state: RootState) => state.zuctovanie.sendBatches.result,
  );
  const [sendingState, setSendingState] = useState<BatchSaveState>(
    BatchSaveState.isLoading,
  );
  // eslint-disable-next-line prefer-const
  const [results, setResults] = useState<VysledokStavuOdoslaniaDavok[]>([]);
  const { groupId, isError } = useSelector(
    (state: RootState) => state.zuctovanie.sendBatches.result,
  );

  useInterval(
    () => {
      if (groupId && sendingState === BatchSaveState.isLoading) {
        pingSendingStatus();
      }
      // after every 5 minutes (after second call), set interval Xtimes more
    },
    countCalls === 0 ? 30000 : 5000 * (countCalls > 60 ? countCalls % 60 : 1),
  );

  useEffect(() => {
    if (isError) setSendingState(BatchSaveState.isError);
  }, [isError]);

  const pingSendingStatus = useCallback(() => {
    // checking status
    if (groupId) {
      dispatch(setCountCallsSendingState(countCalls + 1));
      dajStavOdoslaniaDavok({ idRozdelenia: groupId }).then(
        ({ stavyOdoslania }) => {
          // eslint-disable-next-line no-console

          // some process finished
          if (
            !!stavyOdoslania?.length &&
            stavyOdoslania.every((s) => !!s.stav)
          ) {
            /**
             * all services finished
             */
            setResults(stavyOdoslania);
            setSendingState(BatchSaveState.isWarning);
            if (
              stavyOdoslania.every(
                (s) => Number(s.stav) === StavyOdoslaniaEnum.neuspesne,
              )
            )
              setSendingState(BatchSaveState.isError);

            if (
              stavyOdoslania.every(
                (s) => Number(s.stav) === StavyOdoslaniaEnum.uspesne,
              )
            ) {
              setSendingState(BatchSaveState.isSuccess);
            }
          }
        },
      );
    }
  }, [countCalls, dispatch, groupId]);

  return <StavyOdoslania results={results} state={sendingState} />;
};

export default VysledokOdoslaniaDavok;
