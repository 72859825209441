interface Props {
  id: string;
}

const IconNewMessage = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M19.2071 3.20733C19.4174 2.99705 19.7026 2.87891 20 2.87891C20.2974 2.87891 20.5826 2.99705 20.7929 3.20733C21.0032 3.41762 21.1213 3.70283 21.1213 4.00023C21.1213 4.29762 21.0032 4.58283 20.7929 4.79312L11.4888 14.0973L9.37437 14.6259L9.90296 12.5115L19.2071 3.20733ZM20 0.878906C19.1722 0.878906 18.3783 1.20776 17.7929 1.79312L8.29289 11.2931C8.16473 11.4213 8.07382 11.5819 8.02986 11.7577L7.02986 15.7577C6.94466 16.0985 7.04451 16.459 7.29289 16.7073C7.54127 16.9557 7.90176 17.0556 8.24254 16.9704L12.2425 15.9704C12.4184 15.9264 12.5789 15.8355 12.7071 15.7073L22.2071 6.20733C22.7925 5.62197 23.1213 4.82805 23.1213 4.00023C23.1213 3.1724 22.7925 2.37848 22.2071 1.79312C21.6217 1.20776 20.8278 0.878906 20 0.878906ZM4 3C3.20435 3 2.44129 3.31607 1.87868 3.87868C1.31607 4.44129 1 5.20435 1 6V20C1 20.7957 1.31607 21.5587 1.87868 22.1213C2.44129 22.6839 3.20435 23 4 23H18C18.7957 23 19.5587 22.6839 20.1213 22.1213C20.6839 21.5587 21 20.7957 21 20V13C21 12.4477 20.5523 12 20 12C19.4477 12 19 12.4477 19 13V20C19 20.2652 18.8946 20.5196 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5H11C11.5523 5 12 4.55228 12 4C12 3.44772 11.5523 3 11 3H4Z"
      fill="#BED2F4"
      fillRule="evenodd"
    />
  </svg>
);

export default IconNewMessage;
