// @ts-nocheck
import { useEffect } from 'react';

const usePreventAnchorClick = (
  _prevent: boolean,
  callback: (e: Event) => void,
  onlyAnchors?: boolean,
) => {
  const handleClick = (e: Event) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    return callback(e);
  };

  useEffect(() => {
    if (!_prevent) return () => null;
    // Get all anchors which are self targeted
    const elements = onlyAnchors
      ? document.querySelectorAll('a:not([target="_blank"])')
      : document.querySelectorAll(
          'a:not([target="_blank"]), .tab-list, div[role="button"], form[class^=searchBar], .dialog:not(#cancel-action--modal) button.modal__close-button, div#root button:not([type="submit"]):not([data-type="form"])',
        );
    elements.forEach((el) => {
      el.addEventListener('click', handleClick);
    });
    return () => {
      elements.forEach((el) => {
        el.removeEventListener('click', handleClick);
      });
    };
  });
};

export default usePreventAnchorClick;
