import axios, { AxiosResponse } from 'axios';
import {
  DajDetailPrehladuDiagnozPayload,
  DajPrehladDiagnozPayload,
  DajSkupinyDiagnozPreFilterPayload,
  PrehladDiagnozDetail,
} from '../types/diagnozy.types';
import { createAsyncThunk } from '@reduxjs/toolkit';

const API_URL = `${window.env?.config?.apiPath}/PrehladDiagnoz/`;

export async function dajSkupinyDiagnozPreFilterApi(
  payload: DajSkupinyDiagnozPreFilterPayload,
) {
  const url = `${API_URL}DajSkupinyDiagnozPreFilter`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajPrehladDiagnozApi(payload: DajPrehladDiagnozPayload) {
  const url = `${API_URL}DajPrehladDiagnoz`;
  const { data } = await axios.post(url, payload);
  return data;
}

export async function dajDetailPrehladuDiagnozApi(
  payload: DajDetailPrehladuDiagnozPayload,
) {
  const url = `${API_URL}DajDetailPrehladuDiagnoz`;
  const { data } = await axios.post(url, payload);
  return data;
}

export const fetchDiagnoses = createAsyncThunk(
  'PrehladDiagnoz/DajPrehladDiagnoz',
  async (req: DajPrehladDiagnozPayload) => {
    const url = `${API_URL}DajPrehladDiagnoz`;
    const { data }: AxiosResponse<{ prehladDiagnoz: PrehladDiagnozDetail[] }> =
      await axios.post(url, req);
    return data;
  },
);
