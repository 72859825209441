import { color } from '../../constants';

interface Props {
  active?: boolean;
  id: string;
}

const IconActuality = ({ active, id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="16"
      cy="16"
      fill={active ? color('secondary', 500) : '#C4C4C4'}
      rx="16"
      ry="16"
    />
    <path
      d="M18.0833 7.58325H11.75C11.3301 7.58325 10.9273 7.75007 10.6304 8.047C10.3335 8.34393 10.1667 8.74666 10.1667 9.16659V21.8333C10.1667 22.2532 10.3335 22.6559 10.6304 22.9528C10.9273 23.2498 11.3301 23.4166 11.75 23.4166H21.25C21.6699 23.4166 22.0726 23.2498 22.3696 22.9528C22.6665 22.6559 22.8333 22.2532 22.8333 21.8333V12.3333L18.0833 7.58325Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M17.2917 8.375V13.125H22.0417"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconActuality;
