import { ElementType, ReactNode } from 'react';
import sanitizeHtml from 'sanitize-html';

interface Props {
  [x: string]: any;
  html: string | null | undefined | ReactNode;
  removeAllBr?: boolean;
  rest?: any;
  withoutRemoveEmptyTd?: boolean;
  wrapper?: ElementType;
}

export const getHtmlString = (
  html?: ReactNode | string,
  removeAllBr?: boolean,
  withoutRemoveEmptyTd?: boolean,
): string => {
  if (typeof html === 'string' && !html.startsWith('<'))
    html = html.replace(/\n/g, '<br />');
  if (typeof html === 'string') {
    // Kontrola, ci sa vramci html nachadza element s id = "body". Ak ano, ide o novu sablonu a vyberieme cely obsah z tohto prvku - pouzite pre Schranku sprav.
    if (
      html.toLowerCase().includes('<table') &&
      !html.toLowerCase().includes('id="body"')
    ) {
      let htmlStr: string = html;
      // Split empty tables
      const fakeEl = document.createElement('div');
      fakeEl.innerHTML = htmlStr;

      fakeEl.querySelectorAll('table').forEach((t) => {
        if (t.innerHTML.includes('img') || !t.innerText.trim()) t.remove();
      });
      if (!withoutRemoveEmptyTd)
        fakeEl.querySelectorAll('td').forEach((t) => {
          if (!t.innerText.trim()) t.remove();
        });

      htmlStr = fakeEl.outerHTML.toString();

      fakeEl.remove();

      html = htmlStr.replace(
        /<table/gi,
        '<div class="table table-responsive table-responsive-sh"><table',
      );
      html = htmlStr.replace(
        /<TABLE/gi,
        '<div class="table table-responsive table-responsive-sh"><table',
      );
      html = html.toString().replace(/<\/table>/gi, '</table></div>');
    } else if (html.toLowerCase().includes('id="body"')) {
      const fakeEl = document.createElement('div');
      fakeEl.innerHTML = html;
      fakeEl.querySelectorAll('td').forEach((t) => {
        if (!t.innerText.trim()) t.remove();
      });
      html = fakeEl
        .querySelector('#body')
        ?.innerHTML.replace(
          /<table/gi,
          '<div class="table table-responsive table-responsive-sh"><table',
        );
      fakeEl.remove();
      html = html?.toString() || '';
    }

    // remove all font sizes
    html = html.toString().replace(/font-size/gi, 'undefined');
    html = html.toString().replace(/font-family/gi, 'undefined');
    html = html.toString().replace(/<br><br>/gi, '<br>');
    html = html.toString().replace(/<br><div/gi, '<div');
  }

  // Replace all links to safe shape and open in new tab
  html = html?.toString().replace(/&#58;/gi, ':');
  html = html?.toString().replace(/http:/gi, 'https:');
  html = html?.toString().replace(/<a href/gi, '<a target="_blank" href');

  html = html?.toString().replace(/='/gi, '="');
  html = html?.toString().replace(/'>/gi, '">');
  html = html?.toString().replace(/(')+/gi, '');
  html = html?.toString().replace(/"'/gi, '"');
  html = html?.toString().replace(/'"/gi, '"');

  // add margins to hr
  html = html
    ?.toString()
    .replace(/<hr>/, '<hr style="margin: 12px 0 12px -16px;">');

  // Replace full html
  if (html?.toString().includes('</HEAD>'))
    html = html?.toString().split('</HEAD>')[1];
  if (removeAllBr)
    html = html?.toString().replace(/(<br\s*\/><br\s*\/>)+/gi, '<br />');

  return html?.toString() || '';
};

const SafeHtml = ({
  html,
  removeAllBr,
  withoutRemoveEmptyTd,
  wrapper,
  ...rest
}: Props) => {
  const Wrapper = wrapper || 'span';

  html = getHtmlString(html, removeAllBr, withoutRemoveEmptyTd);

  const safeHtml = sanitizeHtml(html, {
    allowedTags: [
      'b',
      'i',
      'u',
      'p',
      'div',
      'ul',
      'li',
      'ol',
      'span',
      'strong',
      'em',
      'a',
      'img',
      'h2',
      'h5',
      'h3',
      'h4',
      'h6',
      'br',
      'svg',
      'path',
      'circle',
      'small',
      'button',
      'strike',
      'table',
      'tbody',
      'thead',
      'th',
      'tr',
      'td',
      'colgroup',
      'col',
      'hr',
    ],
    allowedAttributes: {
      b: ['class', 'data-hj-masked', 'style'],
      br: ['class'],
      span: ['class', 'data-hj-masked', 'style'],
      div: ['class', 'style'],
      ul: ['class'],
      li: ['style', 'value', 'class'],
      ol: ['type', 'class'],
      img: ['src'],
      h5: ['class'],
      p: ['class', 'style'],
      a: ['href', 'target', 'class'],
      h2: ['class'],
      h3: ['class'],
      h4: ['class'],
      h6: ['class'],
      hr: ['class', 'style'],
      svg: ['width', 'height', 'fill', 'viewbox', 'xmlns', 'style'],
      path: [
        'fillRule',
        'fill-rule',
        'clipRule',
        'clip-rule',
        'd',
        'fill',
        'style',
        'stroke',
        'stroke-width',
        'stroke-linecap',
        'stroke-linejoin',
      ],
      table: ['class'],
      circle: ['cx', 'cy', 'r', 'fill', 'stroke'],
      button: ['type', 'class', 'style'],
    },
    allowedSchemes: ['tel', 'https', 'mailto'],
    parseStyleAttributes: false,
  });
  return <Wrapper dangerouslySetInnerHTML={{ __html: safeHtml }} {...rest} />;
};

export default SafeHtml;
