import { StepperState } from '../Stepper';
import useStyles from './StepSummary.styles';

interface Props extends StepperState {}

const StepSummary = ({ active, children, completed, lastStep }: Props) => {
  const classes = useStyles({ lastStep });
  if (active || !children || !completed) {
    return null;
  }

  return <div className={classes.root}>{children}</div>;
};

export default StepSummary;
