import { color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  attachmentWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > button:nth-child(even)': {
      marginRight: `0 !important`,
      width: '50%',
    },
  },
  attachment: {
    margin: `${getRem(16)} ${getRem(16)} 0 0`,
    border: `1px solid ${color('grey', 100)}`,
    borderRadius: getRem(5),
    padding: `${getRem(10)} ${getRem(16)}`,
    display: 'flex',
    transition: '0.2s ease',
    width: `calc(50% - ${getRem(16)})`,
    '& > div': {
      textAlign: 'left',
      marginLeft: getRem(8),
      wordBreak: 'break-all',
      '& b': {
        fontWeight: 500,
      },
      '& > span': {
        display: 'block',
        color: color('grey', 500),
      },
    },
    '&:not(:disabled)': {
      cursor: 'pointer',
      '&:hover': {
        background: color('primary', 100),
        border: `1px solid ${color('primary', 100)}`,
      },
    },
  },
  attachmentBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
});
