import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getCMSContentApi } from '../api/cms';
import { AppThunk } from '../store';
import { CMSContent, CMSPopUpContent, CMSState } from '../types/cms.types';

export const initialState: CMSState = {
  error: null,
  isLoaded: false,
  isLoading: false,
  texty: [],
  popUps: [],
  popupClosed: false,
};

const cmsSlice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    getContentStart(state, action: PayloadAction<{ closed?: boolean }>) {
      return {
        ...state,
        error: null,
        isLoaded: false,
        isLoading: true,
        popupClosed: action?.payload?.closed || false,
      };
    },
    getContent(
      state,
      action: PayloadAction<{ CMS: CMSContent[]; PopUp: CMSPopUpContent[] }>,
    ) {
      if (action.payload?.CMS?.some((c) => c.cesta === state.texty[0]?.cesta))
        return {
          ...state,
          isLoading: false,
          texty: [...action.payload.CMS],
          popUps: [...action.payload.PopUp],
        };
      return {
        ...state,
        isLoading: false,
        texty: [...state.texty, ...action.payload.CMS],
        popUps: [...state.popUps, ...action.payload.PopUp],
      };
    },
    getContentError(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        error: action.payload,
      };
    },
    setIsLoaded(state) {
      return {
        ...state,
        isLoaded: true,
      };
    },
  },
});

export const { getContent, getContentError, getContentStart, setIsLoaded } =
  cmsSlice.actions;
export default cmsSlice.reducer;

export const fetchCMSContent =
  (payload: string, popupClosed?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getContentStart({ closed: popupClosed }));
      const response: { CMS: CMSContent[]; PopUp: CMSPopUpContent[] } =
        await getCMSContentApi(payload);
      dispatch(getContent(response));
      setTimeout(() => dispatch(setIsLoaded()));
    } catch (err: any) {
      dispatch(getContentError(err?.toString()));
    }
  };
