/* eslint-disable */
import { ZmluvyFilterStav } from '../types/zmluvy.types';

enum STAV {
  ChybaSpracovania = 'Chyba spracovania',
  NaPodpisanie = 'Na podpísanie',
  Podpisane = 'Podpísané',
  Stornovane = 'Stornované',
  UplynulCas = 'Uplynul čas na podpis',
}

export const StavyKampani = [
  {
    text: 'Na podpísanie',
    value: 'N',
  },
  {
    text: 'Podpísané',
    value: 'P',
  },
  {
    text: 'Podpísané elektronicky',
    value: 'E',
  },
  {
    text: 'Uplynul čas na podpis',
    value: 'K',
  },
  {
    text: 'Stornované',
    value: 'X',
  },
  {
    text: 'Chyba spracovania',
    value: 'C',
  },
  {
    text: 'Ukončené',
    value: 'U',
  },
];

export enum HODNOTA_STAV_KAMPANE {
  CiastocnePodpisane = 'P1',
  ChybaSpracovania = 'C',
  NaPodpisanie = 'N',
  Podpisane = 'P',
  PodpisaneElektronicky = 'E',
  Stornovane = 'X',
  Ukoncene = 'U',
  UplynulCas = 'K',
}

export enum STAV_DETAILU_ZMLUVY {
  K = 'UPLYNUL_CAS',
  P = 'PODPISANA',
  X = 'STORNOVANA',
}

export const StavyZmluv = [
  {
    text: 'Návrh (pre PZS)',
    value: 'N',
  },
  {
    text: 'Platná/ý (podpísaná/ý)',
    value: 'P',
  },
  {
    text: 'Rozpracovaná/ý (v ZP)',
    value: 'R',
  },
  {
    text: 'Rozpracovaná - odoslaná poštou',
    value: 'O',
  },
  {
    text: 'Nedefinované',
    value: 'X',
  },
  {
    text: 'Zamietnutá/ý, Storno',
    value: 'Z',
  },
  {
    text: 'Vyradená z kampane',
    value: 'V',
  },
];

const naPodpisanie: ZmluvyFilterStav = {
  id: 'na-podpisanie',
  text: STAV.NaPodpisanie,
  ikonaFilter: null,
  ikonaPrehlad: null,
};

const podpisane: ZmluvyFilterStav = {
  id: 'podpisane',
  text: STAV.Podpisane,
  ikonaFilter: null,
  ikonaPrehlad: null,
};

const ulpynulCas: ZmluvyFilterStav = {
  id: 'uplynul-cas',
  text: STAV.UplynulCas,
  ikonaFilter: null,
  ikonaPrehlad: null,
};

const stornovane: ZmluvyFilterStav = {
  id: 'stornovane',
  text: STAV.Stornovane,
  ikonaFilter: null,
  ikonaPrehlad: null,
};

const chybaSpracovania: ZmluvyFilterStav = {
  id: 'error',
  text: STAV.ChybaSpracovania,
  ikonaFilter: null,
  ikonaPrehlad: null,
};

const filter = {
  naPodpisanie,
  podpisane,
  ulpynulCas,
  stornovane,
  chybaSpracovania,
};

export { filter };
