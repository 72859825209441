import React from 'react';

import useStyles from '../styles';

type Props = React.JSX.IntrinsicElements['div'];

const FormulaWrapper = ({ ...other }: Props) => {
  const classes = useStyles({});

  return <div className={classes.wrapper} {...other} />;
};

export default FormulaWrapper;
