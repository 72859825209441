import { ReactNode } from 'react';
import { ButtonLink, Loader } from '@dovera/design-system';
import SafeHtml from '../components/SafeHtml/SafeHtml';
import store from '../store';
import { history } from '../helpers/history';
import { cx } from './exports';

export const getCMSTitle = (path: string, alternativeText?: string): string => {
  if (store?.getState()?.cms.texty.some((t) => t.cesta === path))
    return (
      store?.getState()?.cms.texty.filter((t) => t.cesta === path)[0].nadpis ||
      alternativeText ||
      '[ TEXT SA NENAŠIEL ]'
    );
  return '[ TEXT SA NENAŠIEL ]';
};

const transformToComponents = (content: string): ReactNode => {
  const parts = content.split(/<span data-json="/);

  const getResponse = (part, index) => {
    if (!part.includes('{')) {
      return (
        <SafeHtml
          key={`cms--${part.toString()?.[index]}${index}`}
          className="d-inline"
          html={part}
        />
      );
    }
    const obj = JSON.parse(part.split('"></span>')[0].replace(/'/g, '"'));
    if (typeof obj === 'object')
      switch (obj.type) {
        case 'btn-link':
          return (
            <span key={`cms--${part.toString()?.[index]}${index}`}>
              {' '}
              <ButtonLink
                className={cx(
                  'inline-btn text-space-half-right',
                  obj.color && `text-color-${obj.color}`,
                )}
                onClick={() => history.navigate(obj.href)}
                style={{ marginTop: -2 }}
              >
                {obj.text}
              </ButtonLink>
              <SafeHtml html={part.split('"></span>')[1]} />
            </span>
          );
        default:
          return <span key={`cms--${part.toString()?.[index]}${index}`} />;
      }
    return <span key={`cms--${part.toString()?.[index]}${index}`} />;
  };

  return <div>{parts.map((p, index) => getResponse(p, index))}</div>;
};

export const getCMSText = (
  path: string | null,
  alternativeText?: string | ReactNode,
  pureContent?: boolean,
  richToString?: boolean,
  className?: string,
  removeBr?: boolean,
): string | ReactNode => {
  if (!path) return '[ TEXT SA NENAŠIEL ]';
  if (
    store?.getState().cms &&
    store.getState().cms.texty?.length &&
    store.getState().cms.texty.some((t) => t.cesta === path) &&
    store.getState().cms.texty.filter((t) => t.cesta === path)[0].obsah
  ) {
    if (pureContent)
      return store.getState().cms.texty.filter((t) => t.cesta === path)[0]
        .cistyObsah;
    if (richToString)
      return store
        .getState()
        .cms.texty.filter((t) => t.cesta === path)[0]
        .obsah.replace(/\n/g, '');
    const staticContent = store
      .getState()
      .cms.texty.filter((t) => t.cesta === path)[0].obsah;
    if (staticContent.includes('<span data-json="'))
      return transformToComponents(staticContent);
    return (
      <SafeHtml
        className={className || ''}
        html={removeBr ? staticContent.replace(/\n/g, '') : staticContent}
        wrapper="div"
      />
    );
  }
  if (store?.getState().cms.isLoading) return <Loader size={24} />;
  if (alternativeText) return alternativeText.toString();
  return '[ TEXT SA NENAŠIEL ]';
};

export const getInlineCMSText = (
  path: string | null,
  alternativeText?: string | ReactNode,
  pureContent?: boolean,
  richToString?: boolean,
  className?: string,
): string | ReactNode =>
  getCMSText(path, alternativeText, pureContent, richToString, className, true);

export const getCMSTexts = ({
  path,
  withoutPath,
}: {
  path: string;
  withoutPath?: string;
}): ReactNode[] =>
  store
    ?.getState()
    ?.cms.texty?.filter(
      (t) =>
        t.cesta.includes(path) &&
        (!withoutPath || !t.cesta.includes(withoutPath)),
    )
    .map((t) => <SafeHtml html={t.obsah} wrapper="div" />);

export const getCMSPopUp = ({
  path,
  withoutPath,
}: {
  path: string;
  withoutPath?: string;
}): {
  content: ReactNode;
  id: string | null;
  title: string | null;
  zobrazene: boolean;
} | null => {
  if (
    store?.getState().cms &&
    store.getState().cms.popUps?.length &&
    store
      .getState()
      .cms.popUps?.filter(
        (t) =>
          t.cesta.includes(path) &&
          (!withoutPath || !t.cesta.includes(withoutPath)),
      )[0]
  ) {
    const popUp = store
      .getState()
      .cms.popUps?.filter(
        (t) =>
          t.cesta.includes(path) &&
          (!withoutPath || !t.cesta.includes(withoutPath)),
      )[0];
    return {
      content: <SafeHtml html={popUp.obsah} />,
      id: popUp.idPopUp,
      title: popUp.nadpis,
      zobrazene: popUp.zobrazene,
    };
  }
  return null;
};

export const getPopupKey = (): string => {
  const showKey = 'zobrazit-v-dlek';
  if (!store.getState().cms.popUps?.length) return '';
  const response = store
    .getState()
    .cms.popUps.filter((p) => !p.zobrazene)
    .map((p) => p.cesta.split('/popup/')?.[1]?.split('/')[0]);
  if (response.includes('ospravedlnenie'))
    return [
      ...['ospravedlnenie'],
      ...response.filter((r) => r !== 'ospravedlnenie' && r !== showKey),
    ]?.[0];
  /* If CMS switcher is set to FALSE, popup will not be shown */
  if (
    store
      .getState()
      .cms.popUps.find(
        (p) =>
          p.cesta.includes(showKey) && p.cistyObsah.toLowerCase() === 'false',
      )
  )
    return '';
  return response?.[0];
};
