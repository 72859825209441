interface Props {
  color: string;
  height: number;
  onMouseLeave?: (e) => void;
  onMouseOver?: (e) => void;
  width: number;
}

const Line = ({ color, height, onMouseLeave, onMouseOver, width }: Props) => (
  <svg
    fill="none"
    height={height}
    onMouseLeave={onMouseLeave}
    onMouseOver={onMouseOver}
    shapeRendering="crispEdges"
    viewBox={`0 0 ${width} ${height}`}
    width={width}
  >
    <line
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="bevel"
      strokeOpacity="1"
      strokeWidth={width}
      x1={width / 2}
      x2={width / 2}
      y1={width / 2}
      y2={height - width / 2}
    />
  </svg>
);

export default Line;
