import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { Modal, color } from '@dovera/design-system';
import useStyles from '../Spravy.styles';
import { IconInfo } from '../../../components';
import { CustomTooltip } from '../../../components/CustomTooltip';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import strings from '../../../constants/strings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import MessageForm from './MessageForm';
import { scrollToModalTop } from '../../../utils/app.utils';

interface Props {
  isVisible: boolean;
  onContinueForm: () => void;
  onHide: () => void;
}

const texts = strings.schrankaSprav.modals.newMessageModal;

const NewMessageModal = ({ isVisible, onContinueForm, onHide }: Props) => {
  const classes = useStyles({});
  const [escapeModal, setEscapeModal] = useState(false);
  const email = useSelector((state: RootState) => state.poskytovatel.email);
  const changeEscapeModalState = useCallback(() => {
    setEscapeModal(true);
    setTimeout(() => {
      setEscapeModal(false);
    }, 200);
  }, []);
  useEffect(() => {
    if (isVisible) scrollToModalTop();
  }, [isVisible]);
  const renderEmail = email && (
    <div className="mb">
      <div className="content-center-vert mb-xxsmall">
        <span className="text-space-half-right">{texts.labels.email}</span>
        <span>
          <CustomTooltip
            dialog={<SafeHtml html={texts.tooltips.email} />}
            id={`tooltip-new-message-email`}
          >
            <IconInfo color={color('black')} id="icon-info-new-message-email" />
          </CustomTooltip>
        </span>
      </div>
      <b>{email}</b>
    </div>
  );
  return (
    // eslint-disable-next-line
    <div
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Escape') {
          changeEscapeModalState();
        }
      }}
    >
      <Modal
        className={classes.newMessageModal}
        closeOnOverlayClick={false}
        data-modal-initial-focus
        header=""
        id="new-message-modal"
        isVisible={isVisible}
        onHide={() => {
          onHide();
          changeEscapeModalState();
        }}
      >
        {renderEmail}
        <MessageForm
          escapeModal={escapeModal}
          hasPzsEmail={!!email}
          onCancel={onHide}
          onContinueModal={onContinueForm}
          pzsEmail={email}
        />
      </Modal>
    </div>
  );
};

export default NewMessageModal;
