import { useCallback, useEffect, useState } from 'react';

function useCountdown(timeSeconds: number, onFinish?: () => void) {
  const [timeLeft, setTimeLeft] = useState<number>(-1);
  const [intervalId, setIntervalId] = useState<any>(null);

  const startTimer = useCallback(() => {
    setTimeLeft(timeSeconds);
  }, [timeSeconds]);

  const cancelTimer = useCallback(() => {
    clearInterval(intervalId);
  }, [intervalId]);

  useEffect(() => {
    if (timeLeft < 0) {
      return;
    }

    if (!timeLeft && onFinish) {
      onFinish();
      return;
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const idInterval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    setIntervalId(idInterval);

    // clear interval on re-render to avoid memory leaks
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, onFinish]);

  return { cancelTimer, startTimer, timeLeft };
}

export default useCountdown;
