import { createUseStyles } from 'react-jss';
import { color } from '../../../constants/index';

export default createUseStyles({
  item: {
    // `whiteSpace: "pre"` is override for ".dropdown__item > *" rule from DDL
    '& span': {
      whiteSpace: 'pre',
    },
    '& mark': {
      background: 'none',
      fontWeight: 'bold',
    },
  },
  isDisabled: {
    color: color('grey', 300),
    backgroundColor: color('grey', 50),
    '&:hover': {
      color: color('grey', 300),
      backgroundColor: color('grey', 50),
      cursor: 'not-allowed',
    },
  },
  hint: {
    fontWeight: 'light',
    color: color('grey', 500),
  },
});
