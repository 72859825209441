import { createUseStyles } from 'react-jss';
import { color } from '@dovera/design-system';

export default createUseStyles({
  modal: {
    maxWidth: 670,
  },
  buttons: {
    textAlign: 'center',
    '& button': {
      marginBottom: 0,
    },
  },
  disapprovalBtn: {
    marginLeft: 32,
    color: color('error', 600),
    borderColor: color('error', 600),
    '&:hover, &:focus': {
      '&:not([disabled])': {
        color: color('error', 600),
        borderColor: color('error', 600),
        backgroundColor: color('error', 100),
      },
    },
  },
  textarea: {
    minHeight: 96,
  },
});
