interface Props {
  id: string;
}

const IconDoctor = ({ id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4583 13.6444C12.5711 13.1673 10.369 10.6592 10.369 7.63679V2.22607H13.5515V4.22607H12.369V7.63679C12.369 9.89524 14.1999 11.7261 16.4583 11.7261C18.7168 11.7261 20.5476 9.89524 20.5476 7.63679V4.22607H19.3417V2.22607H22.5476V7.63679C22.5476 10.6592 20.3456 13.1673 17.4583 13.6444V15.8645C17.4583 19.2275 14.7321 21.9538 11.369 21.9538V19.9538L11.3702 21.9538L11.369 19.9538C13.6275 19.9538 15.4583 18.123 15.4583 15.8645V13.6444ZM11.3702 21.9538H11.0298C8.99859 21.9538 7.21765 20.5811 6.37202 18.5131C6.16298 18.0019 6.40792 17.4181 6.91912 17.209C7.43031 17 8.01418 17.2449 8.22322 17.7561C8.77883 19.1148 9.86728 19.9538 11.0298 19.9538H11.3702V21.9538ZM4.71429 16.4285C2.66294 16.4285 1 14.7655 1 12.7142C1 10.6628 2.66294 8.99988 4.71429 8.99988C6.76563 8.99988 8.42857 10.6628 8.42857 12.7142C8.42857 14.7655 6.76563 16.4285 4.71429 16.4285ZM4.71429 14.4285C5.66106 14.4285 6.42857 13.6609 6.42857 12.7142C6.42857 11.7674 5.66106 10.9999 4.71429 10.9999C3.76751 10.9999 3 11.7674 3 12.7142C3 13.6609 3.76751 14.4285 4.71429 14.4285ZM4.67857 13.357C4.30381 13.357 4 13.0532 4 12.6785C4 12.3037 4.30381 11.9999 4.67857 11.9999C5.05334 11.9999 5.35714 12.3037 5.35714 12.6785C5.35714 13.0532 5.05334 13.357 4.67857 13.357Z"
      fill="#BED2F4"
    />
  </svg>
);

export default IconDoctor;
