// @ts-nocheck
import useStyles from './AppError.styles';
import { Button } from '@dovera/design-system';
import SafeHtml from '../../components/SafeHtml/SafeHtml';
import strings from '../../constants/strings';
import Illustration from '../../assets/illustrations/notFound.png';
// @ts-check

const AppError = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.root} id="notSupportedBrowser">
          <img alt="Illustration" src={Illustration} />
          <SafeHtml
            className="mb-xsmall text-color-error"
            html={strings.technickaChyba}
            wrapper="h4"
          />
          <p>
            Ak bude chyba pretrvávať, napíšte nám na{' '}
            <a href="mailto:poskytovatel@dovera.sk">poskytovatel@dovera.sk</a>
          </p>
        </div>
      </div>
      <Button
        className={classes.button}
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Späť na úvodnú stránku
      </Button>
    </>
  );
};

export default AppError;
