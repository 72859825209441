import { HorizontalFormula, VerticalFormula } from '.';
import { DetailAttributesFormula } from './types';

interface Props {
  attributes: DetailAttributesFormula;
}

const FormulaVisualization = ({ attributes }: Props) => {
  if (attributes.formulaType === 'horizontal')
    return <HorizontalFormula attributes={attributes} />;
  if (attributes.formulaType === 'vertical')
    return <VerticalFormula attributes={attributes} />;
  return <span />;
};

export default FormulaVisualization;
