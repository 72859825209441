import { Tag } from '@dovera/design-system';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import strings from '../../../../constants/strings';
import { DATE_FORMAT_SLOVAK, getMoment } from '../../../../utils/date.utils';
import useStyles from '../../Proposals.styles';
import Section from './Section';
import { GetProposalDetailResponse } from '../../../../types/spaProposals.types';

const texts = strings.proposals.detail;

interface Props {
  informacie: GetProposalDetailResponse['informacie'];
}

const Information = ({ informacie }: Props) => {
  const classes = useStyles();

  return (
    <Section
      rows={[
        {
          label: texts.labels.diagnose,
          value: informacie.diagnoza,
        },
        {
          label: texts.labels.indicatorGroup,
          value: informacie.indikacnaSkupina,
        },
        {
          label: texts.labels.aproove,
          value: informacie.priznakVysetrenieNad70R ? 'Áno' : 'Nie',
        },
        ...(informacie.datumUdalosti
          ? [
              {
                label: texts.labels.event(informacie.typUdalosti),
                value: getMoment(informacie.datumUdalosti).format(
                  DATE_FORMAT_SLOVAK,
                ),
              },
            ]
          : []),
        {
          label: texts.labels.contraindications,
          value: informacie.priznakKontraindikacie ? 'Má' : 'Nemá',
        },
        {
          extraText: informacie.vsetkyVysetrenia ? (
            <div className={classes.sectionTags}>
              {informacie.vsetkyVysetrenia.split('\n').map((v, key) => (
                <Tag key={`proposal-examination--${key}`}>{v}</Tag>
              ))}
            </div>
          ) : (
            ''
          ),
          label: texts.labels.examinations,
          value: informacie.priznakVsetkyVysetrenia ? 'Áno' : 'Nie',
        },
        {
          label: texts.labels.svlz3Month,
          value: informacie.priznakKlinALabVysetreniaNieViacAko3M
            ? 'Áno'
            : 'Nie',
        },
        {
          label: texts.labels.epicrisis,
          value: <SafeHtml html={informacie.epikriza || strings.undefined} />,
        },
        {
          label: texts.labels.anannese,
          value: (
            <SafeHtml
              html={
                !informacie.anamneza && !informacie.objektivnyNalez
                  ? strings.undefined
                  : `${
                      informacie.objektivnyNalez
                        ? `${informacie.objektivnyNalez}<br />`
                        : ''
                    }${informacie.anamneza || ''}`
              }
            />
          ),
        },
        {
          label: texts.labels.additionalDiagnoses,
          value: (
            <SafeHtml
              html={informacie.vedlajsiaDiagnoza || strings.undefined}
            />
          ),
        },
      ]}
      title={texts.subtitles.information}
      withBorder
    />
  );
};

export default Information;
